import { useState, useEffect } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import useFormatPhoneNumber from '../../../hooks/useFormatPhoneNumber';

import { getAddressList, updateUseAddress, postAddressList } from '../../../api/orderPaymentsApi';
import DaumPostcode from 'react-daum-postcode';


// 받는사람 주소 정보
export function RecipientAddress({data}){
    return(
        <div className='m-0 p-2 border'>
            <Row className='m-0 p-0 border-top border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>수령인</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{data?.name}</Col>
            </Row>
            <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>지번</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{data?.postalCode}</Col>
            </Row>
            <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>주소</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{data?.address}</Col>
            </Row>
            <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>상세주소</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{data?.detail}</Col>
            </Row>
            <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>휴대폰번호</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{useFormatPhoneNumber(data?.phone)}</Col>
            </Row>
            {/* <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>배송요청사항</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>집앞에 두고가주세요</Col>
            </Row> */}
        </div>
    );
}


// 배송지 모달(배송지 선택 or 배송지 수정 or 배송지 추가)
export function SelectAddressModal({modalType, setModalType, modalState, setModalState, getOrderDetailPk, getUserPk}){
    const [editAddressData, setEditAddressData] = useState(); // 배송지 수정 시 수정할 데이터를 보관 (selectAddressModal -> selectAddressList -> AddressInput 경로애 사용)
    return (
        <>
            <Modal size={'lg'} show={modalState} onHide={()=>{setModalState(false); setModalType('배송지 선택')}} aria-labelledby="sm-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title id='sm-modal' className='fs-6'>
                        배송지를 선택해주세요.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalType === '배송지 선택' ? (
                            <SelectAddressList 
                                setModalType={setModalType} 
                                getOrderDetailPk={getOrderDetailPk} 
                                setModalState={setModalState}
                                setEditAddressData={setEditAddressData}
                            />
                        ) : modalType === '배송지 수정' ? (
                            <EditAddress
                                getUserPk={getUserPk} 
                                setModalType={setModalType}
                                editAddressData={editAddressData}
                            />
                        ) : (
                            <AppendAddress 
                                getUserPk={getUserPk} 
                                setModalType={setModalType}
                            />
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

// 배송지 모달 컴포넌트(배송지 선택)
function SelectAddressList({setModalType, getOrderDetailPk, setModalState, setEditAddressData}){ 
    // 배송지 선택 시 정보 변경을 위한 getOrderDetailPk를 props로 전달
    // 배송지 선택 시 모달창을 닫기위한 setModalState 전달
    const [addressListData, setAddressListData] = useState([]);
    useEffect(()=>{
        getAddressList()
            .then(res => {
                setAddressListData(res?.data?.data);
            })
            .catch(error => console.log("배송지 리스트 조회 에러. \n 에러코드 : "+error?.message))
    }, [])
    
    return (
        <>
            <Form>
                {
                    addressListData?.length > 0 ? (
                        addressListData.map(item => {
                            return(
                                <AddressInput 
                                    data={item} 
                                    getOrderDetailPk={getOrderDetailPk} 
                                    setModalState={setModalState}
                                    setModalType={setModalType}
                                    setEditAddressData={setEditAddressData}
                                />
                            )
                        })
                    ) : (
                        <p className='fw-grey text-center my-5'>등록된 배송지가 없습니다.<br/>새로운 배송지를 등록해주세요.</p>
                    )
                }
                <hr className='dashed'/>
                <p className='border-hw p-1 text-center text-hw fs-7 fw-bold' onClick={()=>setModalType('배송지 추가')}>+ 신규 배송지 추가</p>
            </Form>
        </>
    )
}

// 배송지 셀렉트버튼 박스
function AddressInput({data, getOrderDetailPk, setModalState, setModalType, setEditAddressData}){
    const pk = getOrderDetailPk();
    //배송지 선택 처리
    const updateAddress = () =>{
        updateUseAddress(pk, data)
            .then(res => {
                console.log(res?.data);
                setModalState(false);
            })
            .catch(error => error?.message)
    }

    return (
        <div className="border p-2 mb-2 fs-7">
            {/* 등록된 배송지정보 */}
            <p className='fw-bolder'>{data?.recipient}({data?.name})</p>
            <p className='text-dark'>{data?.address?.address}, {data?.address?.detail}</p>
            <p className='text-dark'>{useFormatPhoneNumber(data?.phone_number)}</p>
            {/* 배송지 선택 및 수정 버튼 */}
            <div className='d-flex justify-content-between mt-1'>
                <div type='submit' className='btn btn-light border w-25 text-hw' onClick={()=>{
                    setModalType('배송지 수정');
                    setEditAddressData(data);
                    console.log('editaddress',data);
                    }}> 수정 </div>
                <div type='submit' className='btn btn-primary w-25' onClick={()=>updateAddress()}> 선택 </div>
            </div> 
        </div>
    )
}

// 배송지 모달 컴포넌트(배송지 추가)
function AppendAddress({getUserPk, setModalType}){
    const [addressName, setAddressName] = useState(null);
    const [recipientName, setRecipientName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [addressDetail, setAddressDetail] = useState(null);
    const [postCodeModalState, setPostCodeModalState] = useState(false);
    const userPk = getUserPk();

    // 사용자 배송지 목록에 신규 배송지 추가
    const appendUserAddress = () =>{
        if(addressName && recipientName && phoneNumber && postalCode && address && addressDetail){
            const data = {
                user: userPk,
                name: addressName,
                recipient: recipientName,
                phone_number: phoneNumber,
                address: address,
                detail: addressDetail,
                postal_code: postalCode
            }

            postAddressList(data)
                .then(res => {
                    setModalType('배송지 선택');
                    alert('새로운 배송지' + data.name + "이(가) 추가되었습니다.")
                })
                .catch(error => alert(error.message))
        }else{
            alert('누락된 정보가 존재합니다.');
        }
    }

    return (
        <>
            {/* 주소입력 상태인 경우 배송지정보 입력 모달을 none으로 변경 */}
            <Form className={postCodeModalState ? 'd-none' : ''}>
                <Form.Group className="mb-3" controlId="AddressName">
                    <Form.Label>배송지 이름</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="배송지 이름을 입력해주세요. (ex : 집, 사무실)" value={addressName} onChange={(e) => setAddressName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="RecipientName">
                    <Form.Label>수령인</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="수령할 사람의 이름을 입력해주세요." value={recipientName} onChange={(e) => setRecipientName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PhoneNumber">
                    <Form.Label>휴대폰 번호</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="수령할 사람의 휴대폰 번호를 입력해주세요." value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>우편번호</Form.Label>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form.Control size="sm" type="text" placeholder="우편번호" value={postalCode} disabled/>
                        <p className='ms-2 p-1 fs-7 text-hw fw-bold border-hw w-50 text-center rounded-2 pointer' onClick={()=>setPostCodeModalState(true)}>우편번호 찾기</p>
                    </div>                    
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>주소</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="우편번호" value={address} onChange={(e) => setAddress(e.target.value)} disabled/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>상세주소</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="상세주소" value={addressDetail} onChange={(e) => setAddressDetail(e.target.value)}/>
                </Form.Group>
                
                <div className='d-grid gap-2 mt-4'>
                    <p type='submit' className='btn btn-primary' onClick={()=>{appendUserAddress()}}>적용</p>
                </div>
            </Form>
            {/* 주소입력 상태인 경우 postCodeModal 표시 */}
            <PostCodeModal modalState={postCodeModalState} setModalState={setPostCodeModalState} setPostalCode={setPostalCode} setAddress={setAddress}/>
        </>
    )
}

// 배송지 모달 컴포넌트(배송지 수정)
function EditAddress({getUserPk, setModalType, editAddressData}){
    const [addressName, setAddressName] = useState(editAddressData?.name);
    const [recipientName, setRecipientName] = useState(editAddressData?.recipient);
    const [phoneNumber, setPhoneNumber] = useState(editAddressData?.phone_number);
    const [postalCode, setPostalCode] = useState(editAddressData?.address?.postal_code);
    const [address, setAddress] = useState(editAddressData?.address?.address);
    const [addressDetail, setAddressDetail] = useState(editAddressData?.address?.detail);
    const [postCodeModalState, setPostCodeModalState] = useState(false);
    const userPk = getUserPk();

    // 사용자 배송지 목록에 신규 배송지 추가
    const appendUserAddress = () =>{
        if(addressName && recipientName && phoneNumber && postalCode && address && addressDetail){
            const data = {
                user: userPk,
                name: addressName,
                recipient: recipientName,
                phone_number: phoneNumber,
                address: address,
                detail: addressDetail,
                postal_code: postalCode
            }

            postAddressList(data)
                .then(res => {
                    setModalType('배송지 선택');
                    alert('새로운 배송지' + data.name + "이(가) 추가되었습니다.")
                })
                .catch(error => alert(error.message))
        }else{
            alert('누락된 정보가 존재합니다.');
        }
    }

    return (
        <>
            {/* 주소입력 상태인 경우 배송지정보 입력 모달을 none으로 변경 */}
            <Form className={postCodeModalState ? 'd-none' : ''}>
                <Form.Group className="mb-3" controlId="AddressName">
                    <Form.Label>배송지 이름</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="배송지 이름을 입력해주세요. (ex : 집, 사무실)" value={addressName} onChange={(e) => setAddressName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="RecipientName">
                    <Form.Label>수령인</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="수령할 사람의 이름을 입력해주세요." value={recipientName} onChange={(e) => setRecipientName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PhoneNumber">
                    <Form.Label>휴대폰 번호</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="수령할 사람의 휴대폰 번호를 입력해주세요." value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>우편번호</Form.Label>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form.Control size="sm" type="text" placeholder="우편번호" value={postalCode} disabled/>
                        <p className='ms-2 p-1 fs-7 text-hw fw-bold border-hw w-50 text-center rounded-2 pointer' onClick={()=>setPostCodeModalState(true)}>우편번호 찾기</p>
                    </div>                    
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>주소</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="우편번호" value={address} onChange={(e) => setAddress(e.target.value)} disabled/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>상세주소</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="상세주소" value={addressDetail} onChange={(e) => setAddressDetail(e.target.value)}/>
                </Form.Group>
                
                <div className='d-grid gap-2 mt-4'>
                    <p type='submit' className='btn btn-primary' onClick={()=>{appendUserAddress()}}>적용</p>
                </div>
            </Form>
            {/* 주소입력 상태인 경우 postCodeModal 표시 */}
            <PostCodeModal modalState={postCodeModalState} setModalState={setPostCodeModalState} setPostalCode={setPostalCode} setAddress={setAddress}/>
        </>
    )
}


function PostCodeModal({modalState, setModalState, setPostalCode, setAddress}){
    // 카카오 주소입력 스타일
    const postCodeStyle = {
        display: 'block',
        position: 'relative',
        top: '0%',
        width: '400px',
        height: '400px',
        padding: '7px',
        margin: '0 auto',
    };

    const onCompletePost = (data) => {
        console.table(data);
        //우편번호 업데이트
        setPostalCode(data?.zonecode);
        //주소 업데이트
        if(data?.addressType === 'R' && data?.jibunAddress !== ''){
            setAddress(data?.jibunAddress);
        }else if(data?.addressType === 'R' && data?.autoJibunAddress !== ''){
            setAddress(data?.autoJibunAddress);
        }else{
            setAddress(data?.address);
        }
        //모달창 닫기
        setModalState(false);
    };
    return(
        <div className={modalState ? "" : "d-none"}>
            <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompletePost} />
        </div>
    )
}