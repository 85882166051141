/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 셀러관리자 > 주문배송 > 구매확정내역 > OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from "react";
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils";

export const OrderRow = ({ order, index,}) => {
  const navigate = useNavigate();


  return(
    <tr key={order?.id}>
      <td className="blueLink">{order?.order_number}</td>
      <td>{order.paid_at ? extractYearMonthDayHourMinute2(order.paid_at) : "-"}</td>
      <td>{order?.confirmation_status}</td>
      <td>
        {order?.orderer_username} <br/>
        {order?.orderer_name}
      </td>
      <td>
        {order?.recipient_name} <br/>
        {order?.recipient_phone_number}
      </td>
      <td>배송구분</td>
      <td></td>
     
    </tr>
  )
};