import React, {useState, useEffect} from 'react';
// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import Editor from '../../../components/Editor';
import { getTerms, patchPolicyTerms } from '../../../../api/policyApi';

function TermsAndConditions(){
    const [content, setContent] = useState('');
    const [terms, setTerms] = useState('');
    useEffect(() => {
        getTerms().then((res) => {
            setTerms(res?.data?.content);
            console.log(res?.data?.content);
        })
    }, []);
    function onSubmit(content){
        patchPolicyTerms(content)
        .then((res) => {
            alert('저장되었습니다.');
            window.location.reload();
        })
        .catch((error) => {
            alert(error?.response?.data?.data);
        })
    }
    return (
    <>
        <section className="McontentBox mt30">
            <p className="settingSubTitle">이용약관</p>

            <Editor
                content={terms}
                setContent={setContent} 
            />
        </section>
       
       <div className="setting-btn-box">
            <button className="m-btn m-btn-search" onClick={()=>onSubmit(content)}>저장하기</button>
       </div>
    </>
    )
}

export default TermsAndConditions