/***************************************************************************************
* FileName      : OrderDetail.js
* Description   : 고객 > 마이페이지 > 주문배송조회 > 주문상세내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Container, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import MypageTabComponent from '../MypageTab.js';
import Sidemenu from '../Sidemenu.js';
import StatusBar from '../StatusBar.js';
import DatePicker from 'react-datepicker'; // 날짜 선택용 인풋박스
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko'; // 날짜 선택필드 한국어로 변경

import { fetchMyOrderDetail } from '../../../api/services/mypageApi.js';
import Pagination from '../../customer/common/Pagination.js';
import OrderRow from './OrderRow2.js';
import MOrderDetailRow from './MOrderDetailRow.js';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from '../Component/extractDateUtils.js';

function OrderInquiryLayout(){
  return(
    <>
    <Container>
        <StatusBar/>
        <Row className='m-0'>
            <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'order'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <OrderDetail />
                </Col>
            </div>
        </Row>
    </Container>
    </>
  )
}

function OrderDetail()
{
    const navigate = useNavigate();

    const { id } = useParams();

    const [orderDetail, setOrderDetail] = useState(null);


    // 주문일자 추출
    const[orderDate , setOrderDate] = useState('');

    // 총 상품 할인
    const [totalProductDiscountPrice, setTotalProductDiscountPrice] = useState(0);

    // 총 배송비 할인
    const [totalDeliveryFeeDiscountPrice, setTotalDeliveryFeeDiscountPrice] = useState(0);

    useEffect(() => {
        fetchMyOrderDetail(id)
          .then(details => {
            setOrderDetail(details);
            console.log("상세데이터")
            console.log(details)
          })
          .catch(error => {
            console.error('상품 상세 조회 오류:', error);
          });
    }, [id]);

  
    useEffect(()=>{
        const orderDate = orderDetail?.delivery_items?.[0]?.order_items?.[0]?.paid_at;
        if (orderDate) {
          setOrderDate(orderDate);
        }

        // 총 상품 할인 
        if (orderDetail && orderDetail.delivery_items.order_items) {
            const total = orderDetail.delivery_items.order_items.reduce((accumulator, deliveryItem) => {
              return accumulator + (deliveryItem.discount_price || 0);
            }, 0);
            setTotalProductDiscountPrice(total);
        } else {
            setTotalProductDiscountPrice(0);
        }

        // 총 배송비 할인 계산 
        if (orderDetail && orderDetail.delivery_items) {
            const total = orderDetail.delivery_items.reduce((accumulator, deliveryItem) => {
              return accumulator + (deliveryItem.discount_price || 0);
            }, 0);
            setTotalDeliveryFeeDiscountPrice(total);
        } else {
            setTotalDeliveryFeeDiscountPrice(0);
        }

    },[orderDetail])

    
    useEffect(() => {
     
      }, [orderDetail]);
    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <>
            <Row className='m-0 p-0 mb-4'>
                <Col xs={12} sm={12} className='m-0 p-0'>
                      <h3 class="m-0 p-0 fs-5 fw-bold">주문상세내역 </h3>
                </Col>
            </Row>
            
            <ul className='mypage-infor-data mb-4'>
                {orderDate && (
                    <li>
                        <span>주문일자:</span>
                        <strong>{extractYearMonthDayHourMinute2(orderDate)}</strong>
                    </li>
                )}
               
                <li>
                    <span>주문번호:</span>
                    <strong>{orderDetail?.order_number}</strong>
                </li>
                
            </ul>

            <div className='area-over mt-2'>
                <h3 className='mypage-sub-title mb-2'>배송상품</h3>
            </div>
        
            <table className='mypage-table' id="mypage-order-table">
                <colgroup>
                    <col style={{ width: '55%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                </colgroup>
                <thead >
                    <tr>
                        <th>상품명</th>
                        <th>구매가</th>
                        <th>배송비</th>
                        <th>진행현황</th>
                    </tr>
                </thead>
                <tbody className='history'>
                    { orderDetail && orderDetail?.delivery_items.map((deliveryItem) => 
                        deliveryItem.order_items.map((orderItem, index) => (
                            <tr key={orderItem.id}>
                                <OrderRow    
                                    index={index}
                                    deliveryItem={deliveryItem}
                                    orderItem={orderItem} 
                                    deliveryItem_length={deliveryItem.order_items.length}
                                    />
                            </tr>
                        ))
                    )}                   
                </tbody>
            </table>

            {/* 모바일 */}
            <div className='mOrderContents' id="mContents">
                <div>
                    {orderDetail && orderDetail?.delivery_items.map((deliveryItem) => 
                        deliveryItem.order_items.map((orderItem, index) => (
                            <MOrderDetailRow
                                key={orderItem.id}
                                orderItem={orderItem}
                                deliveryItem={deliveryItem}
                            />
                        ))
                    )}
                </div>
            </div>


            <div className='area-over mt-5'>
                <h3 className='mypage-sub-title mb-2'>구매자 정보</h3>
            </div>
            <table className='mypage-table view'>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>주문자</th>
                        <td colSpan={3}>
                            {orderDetail?.recipient_name}
                        </td>

                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td colSpan={3}>{orderDetail?.recipient_phone_number}</td>
                    </tr>
                    <tr>
                        <th>이메일주소</th>
                        <td colSpan={3}>
                            {orderDetail?.orderer_email}
                        </td>
                    </tr>
               
                </tbody>
            </table>


            <div className='area-over mt-5 '>
                <h3 className='mypage-sub-title mb-2'>배송지 정보</h3>
            </div>
            <table className='mypage-table view mb-4'>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>받는사람</th>
                        <td colSpan={3}>{orderDetail?.orderer_name}</td>
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td colSpan={3}>{orderDetail?.orderer_phone_number}</td>
                    </tr>
                    <tr>
                        <th>주소</th>
                        <td colSpan={3}>[{orderDetail?.postal_code}] {orderDetail?.address}</td>
                    </tr>
                </tbody>
            </table>

            <div className='area-over mt-5'>
                <h3 className='mypage-sub-title mb-2'>결제 정보</h3>
            </div>
            <table className='mypage-table view'>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>결제수단</th>
                        <td colSpan={3}>
                            {orderDetail?.pay_method}
                        </td>
                    </tr>
                    <tr>
                        <th>총 상품금액</th>
                        <td colSpan={3}>
                            {orderDetail?.original_total_price?.toLocaleString() || "0"}원
                            {/* ({orderDetail?.sale_price?.toLocaleString() || "0"}원) */}
                        </td>
                    </tr>
                    <tr>
                        <th>배송비</th>
                        <td colSpan={3}>
                            {orderDetail?.total_delivery_fee?.toLocaleString() || "0"}원
                        </td>
                    </tr>
                    <tr>
                        <th>쿠폰할인</th>
                        <td colSpan={3}>
                            <p>{orderDetail?.discount_price?.toLocaleString() || "0"}원</p>
                            <p className='total_dp'>상품 할인 {totalProductDiscountPrice.toLocaleString()}원</p>
                            <p className='total_dp'>배송비 할인  {totalDeliveryFeeDiscountPrice.toLocaleString()}원 </p>
                        </td>
                    </tr>
                    <tr>
                        <th>총 결제금액</th>
                        <td colSpan={3}>
                            {orderDetail?.total_price?.toLocaleString() || "0"}원
                        </td>
                    </tr>
                  
                    <tr>
                    
                    </tr>
                </tbody>
                
            </table>

      
                
        </>
        </div>  
        </>
    )
}


export default OrderInquiryLayout