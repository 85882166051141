/***************************************************************************************
* FileName      : MyOnetoOne.js
* Description   : 고객 > 마이페이지 > 1:1문의 내역 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import MypageTabComponent from './MypageTab.js';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';

import { useMyInquiryOnetooneListQuery } from '../../api/services/mypageApi.js';

import Pagination from '../customer/common/Pagination.js';
import MyInquiryRow from './MyOnetoOne/MyInquiryRow.js';

function MyInquiryLayout()
{

    return(
        <>
        <Container>
            <StatusBar/>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'otoList'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <MyOnetoOne/>
                    </Col>
                </div>
            </Row>

        </Container>
        
        </>
      )
}

function MyOnetoOne()
{
    const navigate = useNavigate();

    const { data:myInquiryOnetoone, error, isFetching, page, setPage } = useMyInquiryOnetooneListQuery();


    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    if (!myInquiryOnetoone) {
        return <div>Loading...</div>;
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
        
    
    const handleWriteBtnClick = () => {
        navigate(`/mypage/oto/write/`);
    };

    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>          
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0 mb-4'>
                    <h3 class="m-0 p-0 fs-5 fw-bold">1:1문의내역</h3>
                </Col>
            </Row>

            {/* <div className='mp-search-area mb-4'>
                검색
            </div> */}

            <table className='mypage-table'>
                <colgroup>
                    <col style={{ width: '50%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '5%' }} />

                </colgroup>
                <thead >
                    <tr>
                        <th>문의내용</th>
                        <th>등록일</th>
                        <th>답변상태</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='history'>
                    {searchConfirm && searchResults?.count === 0 ? (
                        <tr>
                            <td colSpan="17">검색 결과가 없습니다.</td>
                        </tr>
                    ) : searchResults?.count > 0 ? (
                        searchResults?.data.map((inquiry) => (
                            <MyInquiryRow
                                key={inquiry?.id}
                                inquiry={inquiry}
                        />
                        ))
                    ) : myInquiryOnetoone && myInquiryOnetoone?.data && myInquiryOnetoone?.data.length > 0 ? (
                        myInquiryOnetoone?.data.map((inquiry) => (
                            <MyInquiryRow
                                key={inquiry?.id}
                                inquiry={inquiry}
                            />
                        ))
                    ) : null}

                </tbody>
                
            </table>

          
            {/* 페이지내비게이션 */}
            <section className='mt-5'>
              <Pagination
                  count={myInquiryOnetoone?.totalCnt || 0}
                  page={page}
                  setPage={setPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
              />
          </section>

            <section className="d-flex justify-content-end pt-4">
                <button className='btnSubmitFix sizeS' onClick={handleWriteBtnClick}>1:1문의 쓰기</button>
            </section>

        </div>
        </>
    )
}


export default MyInquiryLayout