/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 셀러관리자 > 주문배송 > 배송현황 > 반품 리스트 OrderRow 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';
import { extractYearMonthDayHourMinute2 } from '../../../../mypage/Component/extractDateUtils';
import ReturnModal from './ReturnModal';
function OrderRow({ order, index ,
  selectedRows, setSelectedRows, selectedRowsData, setSelectedRowsData, setApproveProduct }){
 
    // 반품 승인 모달 상태
    const [ReturnApprovalModalOpen , setIsReturnApprovalModalOpen] = useState(false);

    // 반품 거절 모달 상태
    const [ReturnRejectionModalOpen , setIsReturnRejectionModalOpen] = useState(false);
  
    // 회수 요청 모달 상태
    const [PickupRequestModalOpen , setIsPickupRequestModalOpen] = useState(false);

    // 회수 완료 모달 상태
    const [PickupCompleteModalOpen , setIsPickupCompleteModalOpen] = useState(false);

    // 환불 승인 모달 상태 
    const [RefundApprovalModalOpen , setIsRefundApprovalModalOpen] = useState(false);

    // 환불 거절 모달 상태
    const [RefundRejectionModalOpen , setIsRefundRejectionModalOpen] = useState(false);

    
    // 반품승인 모달
    const openApprovalModalOpen = () => {
      setIsReturnApprovalModalOpen(true);
    };

    // 반품반려 모달   
    const openRejectionModalOpen = () => {
      setIsReturnRejectionModalOpen(true);
    };

    // 회수요청 모달
    const openPickupRequestModalOpen = () => {
      setIsPickupRequestModalOpen(true);
    };

    // 회수완료 모달
    const openPickupCompleteModalOpen = () => {
      setIsPickupCompleteModalOpen(true);
    };

    // 환불승인 모달
    const openRefundApprovalModalOpen = () => {
      setIsRefundApprovalModalOpen(true);
    };

    // 환불거절 모달
    const openRefundRejectionModalOpen = () => {
      setIsRefundRejectionModalOpen(true);
    };

    return (
      <>

      <tr key={order.id}>
        <td>{order?.order_number}</td>
        <td>{order?.abort_type}</td>

        <td>
          <span className={`statusBadge 
                    ${order?.returns?.status === "반품수락" ? "request" 
                    : order?.returns?.status === "반품요청" ? "finish" 
                    : order?.returns?.status === "반품완료" ? "request" 
                    : ""}`}>
                  {order?.returns?.status}
          </span>
         </td>
         <td>  
          {order?.returns?.status === "반품요청" && 
            <>
              <button className='t-s-btn me-2' onClick={openApprovalModalOpen}>반품승인</button> 
              <button className='t-s-btn' onClick={openRejectionModalOpen}>반품거절</button> 
            </>
          }

          {order?.returns?.status === "반품수락" &&  order?.returns?.return_status === "회수대기중" &&
            <button className='t-s-btn' onClick={openPickupRequestModalOpen}>회수요청</button> 
          }
          {order?.returns?.status === "반품수락" && order?.returns?.return_status === "회수요청" && 
            <button className='t-s-btn' onClick={openPickupCompleteModalOpen}>회수 완료</button> 
          }

          {order?.returns?.status === "반품완료" &&  order?.returns?.return_status === "회수완료" && 
            <> 
              <button className='t-s-btn me-2' onClick={openRefundApprovalModalOpen}>환불승인</button> 
              <button className='t-s-btn' onClick={openRefundRejectionModalOpen}>환불거절</button> 
            </>    
          }


          {order?.returns?.status === "거절" && 
            <> 
            
            </>
          }
        </td>
        <td>
          {order?.orderer_name} <br/>
          {order?.orderer_username}
        </td>
        <td>
          {order?.recipient_name}<br/>
          {order?.recipient_phone_number}
        </td>
        <td>
          { order?.returns?.created_at ? extractYearMonthDayHourMinute2(order?.returns?.created_at) : "-"}
        </td>
        <td>{order?.returns?.reception_number}</td>
        <td>
          {order?.product_id} <br/>
          {order?.product_name}
        </td>

        <td>
          {order?.option_rows.map((option,index) => (
              <p key={index}>{option.name} - {option.quantity}개</p>
          ))}
        </td>
        <td>{order?.returns?.return_reason}</td> 
        <td>
          {order?.returns?.delivery_cost_bearer}
        </td>      
        <td>{order.total_price.toLocaleString()}원</td>
        <td>
          {order?.returns?.return_check_at ? extractYearMonthDayHourMinute2(order.returns.return_check_at) : "-"}<br/>
          {order?.returns?.return_complete_at ? extractYearMonthDayHourMinute2(order.returns.return_complete_at) : "-"}<br/>
        </td>
        <td>{order?.is_escrow ? "O" : "-"}</td>
        <td>{order?.returns?.return_request_at ? 
              extractYearMonthDayHourMinute2(order?.returns?.return_request_at) : "-"}</td>
        <td>{order?.returns?.return_complete_at ? 
              extractYearMonthDayHourMinute2(order?.returns?.return_complete_at) : "-"}</td>
        <td>{order?.courier}</td>
        <td>{order?.tracking_number}</td>
        <td>{order?.returns?.reject_reason}</td>
        <td>{order?.returns?.manager_name}</td>
        <td>{order?.returns?.completed_at ?
            extractYearMonthDayHourMinute2(order?.returns?.completed_at) : "-"}</td>

      </tr>

      {/* 반품 승인 모달 */}
      <ReturnModal 
        show={ReturnApprovalModalOpen} 
        setShow={setIsReturnApprovalModalOpen} 
        order={order}
        modalType="approval"
      />

      {/* 반품 거절 모달 */}
      <ReturnModal 
        show={ReturnRejectionModalOpen} 
        setShow={setIsReturnRejectionModalOpen} 
        order={order}
        modalType="rejection"
      />

      {/* 회수요청 */}
      <ReturnModal 
        show={PickupRequestModalOpen} 
        setShow={setIsPickupRequestModalOpen} 
        order={order}
        modalType="pickup"
      />

      {/* 회수완료 */}
      <ReturnModal
        show={PickupCompleteModalOpen}
        setShow={setIsPickupCompleteModalOpen}
        order={order}
        modalType="pickup_complete"
      />

      
      {/* 환불 승인 모달 */}
      <ReturnModal
        show={RefundApprovalModalOpen}
        setShow={setIsRefundApprovalModalOpen}
        order={order}
        modalType="refund_approval"
      />

      {/* 환불 거절 모달 */}
      <ReturnModal
        show={RefundRejectionModalOpen}
        setShow={setIsRefundRejectionModalOpen}
        order={order}
        modalType="refund_rejection"
      />  


      {/* hm : 반려 모달 추가 */}
      {/* <RejectModal 
        rejectIsOpen={rejectIsOpen} 
        setRejectIsOpen={setRejectIsOpen}
        rejectMessage={rejectMessage}
        handleTextareaChange={handleTextareaChange} 
        handleRejectClick={handleRejectClick} 
        id={order?.id}/>
       */}
      </>

    )
}

export default OrderRow