import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Pagination from './common/Pagination.js'
import Card from './List/Card.js';

import { fetchProductCategoryData , fetchCurrentCategoryData  } from '../../api/product/product.js';
import { useProductListContent } from '../../api/services/productApi.js';
import { useSearchParams } from 'react-router-dom';

import { getSeasonalCategory } from '../../api/mainApi.js';


export default function List(){
    const giftList = ['수산물(생선류)', '해산물(생선류 외)', '건어물(건어물류)'];
    const [seasonalCategoryList, setSeasonalCategoryList] = useState([]);
    const [activeCategory, setActiveCategory] = useState('전체');
    useEffect(()=>{
        getSeasonalCategory()
            .then(res => {
                const seasonalCategories = res?.data?.data.map((x) => x?.category?.middle);
                setSeasonalCategoryList(seasonalCategories);
            })
    }, [])

    const [activeTab, setActiveTab] = useState('신상품');
    const [searchParams] = useSearchParams();

    const categoryValue  = searchParams.get('category');


    function getCategoryFromURL() {
        const categoryValue = searchParams.get("category");
        const categoryParts = categoryValue ? categoryValue.split("/") : [];
        const categoryId = categoryParts[1]; // categoryId를 추출
        const categoryParent = categoryParts[2] ? categoryParts[2] : categoryParts[1];

        return {
            category: categoryParts[0],
            categoryId: categoryId,
            categoryParent : categoryParent
        };
    }

    const { category, categoryId , categoryParent } = getCategoryFromURL();
        
    // 현재 선택된 카테고리의 이름(nowCategoryName)
    const [nowCategoryName, setNowCategoryName] = useState(activeCategory);
    useEffect(()=>{
        if(activeCategory == '전체'){
            const categoryParam = giftList.map((x, index) => {
                return index === 0 ? x : `&category=${x}`;
            }).join('');
            setNowCategoryName(categoryParam);
        }else{
            setNowCategoryName(activeCategory);
        }
    },[activeCategory])
    
    const { data:tabData, isLoading , page, setPage , totalPages } = useProductListContent(activeTab , nowCategoryName, categoryId );

    // 하위 카테고리 관리
    const [categoryData , setcategoryData] = useState(null);
 
    // 선택한 대분류 (전체) 카테고리 이름 
    const [allViewName , setAllViewName]  = useState();


    useEffect(()=>{
        fetchProductCategoryData(categoryParent)
        .then(data => {
          setcategoryData(data);
        })
        .catch(error => {
          console.error('카테고리 조회 오류:', error);
        });

        if(categoryId){
            fetchCurrentCategoryData(categoryId)
            .then(data => {
                setAllViewName(data);
            })
        }
     
    },[categoryId])

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
        

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    return(
        <>
        <Container className='p-3'>
            <div className='product-headcategory'>
                {/* <ol>
                    <li><a>홈</a></li>
                    <li><a>상품리스트</a></li>

                </ol> */}
            </div>
            <Row>
                <Col xs={12} md={12}>
                    <div className='page-tit text-center'>
                        <h2>수산선물세트</h2>
                    </div>
                </Col>

                <Col xs={12} md={12}>
                    <div className='productTitleCate'>
                        <ul className='menuCategory mainCate d-block'>
                            <Row>
                                <Col xs={6} sm={6} md={3} lg={3}>
                                    <li className={ activeCategory === '전체' ? "text-center w-100 pointer on" : "text-center w-100 pointer"} onClick={()=>setActiveCategory('전체')}>
                                        <a>전체</a>
                                    </li>
                                </Col>
                                {
                                    giftList?.map((name)=>{
                                        return(
                                            <Col xs={6} sm={6} md={3} lg={3}>
                                                <li className={ activeCategory === name ? "text-center w-100 pointer on" : "text-center w-100 pointer"} onClick={()=>setActiveCategory(name)}>
                                                    <a>{name}</a>
                                                </li>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </ul>
                    </div>
                </Col> 
                
                <Col xs={12} md={12} className='mt-2'>
                    <span className='pick_list_num'>
                        <strong>{tabData?.totalCnt}개</strong> 상품이 있습니다.
                    </span>
                    <div className='product_ListMenu'>
                        <ul className='product-orderby'>
                            {['신상품', '상품명', '낮은가격', '높은가격'].map((tab) => (
                            <li key={tab} onClick={() => handleTabChange(tab)}>
                                {tab}
                            </li>
                            ))}
                        </ul>
                    </div>
                </Col>
                
                { tabData?.data?.length === 0 ? (
                    <div className='goods_no_data'>
                        <strong>상품이 존재하지 않습니다</strong>
                    </div>
                    ) : (

                    <div>
                        <ul>
                            <Row className='prdList'>
                            {
                                tabData?.data?.length > 0 && (
                                    tabData?.data.map((product) => (
                                        <>
                                        <Card 
                                            product={product} 
                                            key={product?.id} 
                                        />
                                        {/* <CardFilter product={product} params={searchParams.get("page")}/> */}
                                        </>
                                    ))
                                )
                            } 
                            </Row>
                        </ul>
                       
                    </div>
                )}
            </Row>

            { tabData?.data?.length > 0 && (
                <section>
                    <Pagination
                        count={tabData?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />  
                </section>
            )}

        </Container>
        </>
    )
}