import React from 'react';

const GenderCheckboxGroup = ({ checkedState, onChange }) => (
    <div className="radio-wrap ml-2">
        <label>
        <input
            type="checkbox"
            name="male_user"
            checked={checkedState.male_user}
            onChange={onChange}
        />
        남성
        </label>
        <label>
            <input
                type="checkbox"
                name="female_user"
                checked={checkedState.female_user}
                onChange={onChange}
            />
            여성
        </label>
  </div>
);

export default GenderCheckboxGroup