// 상품 좋아요 

import { toggleProductLike } from "../../api/mainApi.js";
import { useMutation } from 'react-query';
import { getMe }  from '../../api/useLogin.js'; 
import { useEffect, useState } from "react";

function ProductLikeButton({productId , is_liked})
{
    const [userInfo, setUserInfo] = useState('');
    const [isWished, setIsWished] = useState(false);

    useEffect(()=>{
        getMe()
        .then(res => setUserInfo(res?.username))
        .catch(error => {
            console.log(error?.data?.data);
            setUserInfo('guest');
        })
    }, [])

    useEffect(()=>{
        setIsWished(is_liked);
    },[is_liked])


    const createLikeMutaion = useMutation(toggleProductLike , {
        onSuccess : (data) => {
            // console.log("좋아요성공",data)
        },
        onError : (data) => {
            alert(data.request.responseText);
        }
    });


    const toggleWish = () => {
        if(userInfo === 'guest'){
            const isConfirmed = window.confirm("로그인이 필요한 서비스입니다. 로그인 페이지로 이동하시겠습니까?");
            if (isConfirmed) {
                window.location.href = '/login';
            }
        }else{
            setIsWished(!isWished);

            const data = {
                product: productId,
            };
    
            createLikeMutaion.mutate(data);
        }
     
    };

    return(
        <>
        <button onClick={()=>{toggleWish()}} className="wish">
            <img
                src={isWished ? '/img/icon_detail_wish_on.svg' : '/img/icon_detail_wish_off.svg'}
                alt="하트 아이콘"
            />
        </button>
        </>
    
    )
}

export default ProductLikeButton