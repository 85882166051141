import React, { useState , useRef , useEffect} from 'react';

function DocumentUpload({onUploadFiles})
{
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
  
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };
      
  
    const handleFileAdd = () => {
      fileInputRef.current.click();
      handleDocumentUpload();
    };
  
    const handleDocumentUpload = () => {
      files.forEach((file) => {
        const fileSizeMB = file.size / (1024 * 1024); // 파일 크기 계산 (MB 단위)
  
        if (fileSizeMB <= 5) {
            const allowedExtensions = /\.(pdf|jpg|jpeg|png)$/i; // 허용된 파일 확장자 정규식
            const fileExtension = file.name.split('.').pop().toLowerCase(); // 파일 확장자 가져오기
    
            if (allowedExtensions.test(fileExtension)) {
                console.log(`${file.name}이 업로드되었습니다.`);
            } else {
                console.log(`${file.name}: PDF, JPG, PNG, JPEG 파일 형식만 업로드할 수 있습니다.`);
            }
        } else {
          console.log(`${file.name}: 5MB 이하의 파일만 업로드할 수 있습니다.`);
        }
      });
    };

    // 삭제
    const handleDeleteFile = (index) => {
      const updatedFiles = [...files];
      updatedFiles.splice(index, 1);
      setFiles(updatedFiles);
    };

    useEffect(() => {
        onUploadFiles(files);
    }, [files]);

    return (
        <>
        <section className="">
            <p className="notice-text">
                5MB 이하의 파일을 업로드해주세요.(PDF, JPG, PNG, JPEG 파일 형식만 업로드 가능)
            </p>
            <input
                type="file"
                multiple
                accept=".pdf, .jpg, .jpeg, .png"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <button className='m-btn m-btn-search' onClick={handleFileAdd}>파일 추가</button>
        </section>
        {files.length > 0 && (

          <div className='TagList'>
            {files.map((file, index) => (
              <>
                <span key={index} className="Tag">
                  {file.name}

                  <button onClick={() => handleDeleteFile(index)}>
                    <i className='xi-close-min'></i>
                  </button>
                </span>
            </>
            ))}


          </div>
     
      )}
        </>
    )
}

export default DocumentUpload