import React from 'react';

const SortComponent2 = ({ ordering, handleOrderingChange , newLabel, oldLabel }) => {
  return (
    <div className='selectWrap'>
        <select className='mSelectBox' id="ordering" value={ordering} onChange={handleOrderingChange}>
            <option value="-created_at">{newLabel}</option>
            <option value="created_at">{oldLabel}</option>
        </select>
    </div>
  );
}

export default SortComponent2;