import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Pagination from './common/Pagination.js'
import Card from './List/Card.js';

import { fetchProductCategoryData , fetchCurrentCategoryData  } from '../../api/product/product.js';
import { useProductListContent } from '../../api/services/productApi.js';
import { useSearchParams } from 'react-router-dom';

// 카테고리 필터
import { CategoryFilter, CategoryStateFilter } from './List/CategoryFilter.js';

export default function List(){

    const [activeTab, setActiveTab] = useState('신상품');
    const [searchParams] = useSearchParams();


    function getCategoryFromURL() {
        const categoryValue = searchParams.get("category");
        const categoryParts = categoryValue ? categoryValue.split("/") : [];
        const categoryId = categoryParts[1]; // categoryId를 추출
        const categoryParent = categoryParts[2] ? categoryParts[2] : categoryParts[1];

        return {
            category: categoryParts[0],
            categoryId: categoryId,
            categoryParent : categoryParent
        };
    }

    const { category, categoryId , categoryParent } = getCategoryFromURL();

    // 현재 선택된 카테고리의 이름(nowCategoryName)
    const [nowCategoryName, setNowCategoryName] = useState(category);
    
    // [hm] 카테고리 필터에서 선택한 ID값으로 조회 할 수 있도록 categoryId -> ModifiedCategoryId로 변경함.
    const [modifiedCategoryId, setModifiedCategoryId] = useState(categoryId);
    const { data:tabData, isLoading , page, setPage , totalPages } = useProductListContent(activeTab , nowCategoryName, modifiedCategoryId );

    // 하위 카테고리 관리
    const [categoryData , setcategoryData] = useState(null);
 
    // 선택한 대분류 (전체) 카테고리 이름 
    const [allViewName , setAllViewName]  = useState();


    // useEffect(()=>{
    //     fetchProductCategoryData(categoryParent)
    //         .then(data => {
    //             setcategoryData(data);
    //         })
    //         .catch(error => {
    //             console.error('카테고리 조회 오류:', error);
    //         });
    //     if(categoryId){
    //         fetchCurrentCategoryData(categoryId)
    //             .then(data => {
    //                 setAllViewName(data);
    //             })
    //     }
    // },[categoryId])

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
        

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    return(
        <>
        <Container className='p-3'>
            <Row>
                <Col xs={12} md={12}>
                    <div className='page-tit text-center'>
                        {/* 카테고리가 전체인 경우만 이름을 전체상품으로 바꿔서 표시 : [hm] 2023.12.21 */}
                        <h2>{category ==='전체' ? '전체상품' : category ? category : '상품리스트'}</h2>
                    </div>
                </Col>
                {category === '전체' && <CategoryFilter category={category} setNowCategoryName={setNowCategoryName} setModifiedCategoryId={setModifiedCategoryId}/>}
                {category === '생물' && <CategoryStateFilter category={category} setNowCategoryName={setNowCategoryName} setModifiedCategoryId={setModifiedCategoryId}/>}
                {category === '냉동' && <CategoryStateFilter category={category} setNowCategoryName={setNowCategoryName} setModifiedCategoryId={setModifiedCategoryId}/>}
                {category === '반건조' && <CategoryStateFilter category={category} setNowCategoryName={setNowCategoryName} setModifiedCategoryId={setModifiedCategoryId}/>}
                
                {/* 2023.12.21 : CHM : 해와 상품 필터 */}
                {/* <Col xs={12} md={12}>
                {categoryData && (
                    <div className='productTitleCate'>
                    <ul className='menuCategory mainCate'>
                        <li className={ categoryId === categoryParent ? "on" : ""}>
                            <a href={`/product/list?category=${allViewName}/${categoryParent}`}>전체</a>
                        </li>

                        {categoryData.data.map((item, index) => (
                            <li key={index} className={item.name === category ? "on" : ""}>
                                <a href={`/product/list?category=${item.name}/${item.id}/${item.parent}`} >
                                    {item.name} 
                                </a>
                            </li>
                        ))}
                    </ul>
                    </div>
                )}
                </Col>  */}
               

                
                <Col xs={12} md={12} className='mt-2'>
                    <span className='pick_list_num'>
                        <strong>{tabData?.totalCnt}개</strong> 상품이 있습니다.
                    </span>
                    <div className='product_ListMenu'>
                        <ul className='product-orderby'>
                            {['신상품', '상품명', '낮은가격', '높은가격'].map((tab) => (
                            <li key={tab} onClick={() => handleTabChange(tab)}>
                                {tab}
                            </li>
                            ))}
                        </ul>
                    </div>
                </Col>
                
                { tabData?.data?.length === 0 ? (
                    <div className='goods_no_data'>
                        <strong>상품이 존재하지 않습니다</strong>
                    </div>
                    ) : (

                    <div>
                        <ul>
                            <Row className='prdList'>
                            {
                                tabData?.data?.length > 0 && (
                                    tabData?.data.map((product) => (
                                        <>
                                        <Card 
                                            product={product} 
                                            key={product?.id} 
                                        />
                                        {/* <CardFilter product={product} params={searchParams.get("page")}/> */}
                                        </>
                                    ))
                                )
                            } 
                            </Row>
                        </ul>
                       
                    </div>
                )}
            </Row>

            { tabData?.data?.length > 0 && (
                <section>
                    <Pagination
                        count={tabData?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />  
                </section>
            )}

        </Container>
        </>
    )
}
