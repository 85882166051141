/***************************************************************************************
* FileName      : ProductStats.js
* Description   : 셀러관리자 > 통계 > 판매통계 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useEffect, useState } from "react";
import Chart from './Component/Chart.js'
import { getSellerSalesDaily , handleSellerSalesDaily } from "../../../api/selleradmin/statisticApi.js";
import { useQuery } from "react-query";

import monthColors from "./Component/monthColors.js";
import YearSelect from "../../admin/statistics/Component/YearSelect.js";
import MonthCheckboxGroup from "./Component/MonthCheckboxGroup.js";

function SalesStatistics()
{
    const [chartData,setChartData] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

 
    const [monthCheckedState, setMonthCheckedState] = useState({
        "1": true,
        "2": true,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setMonthCheckedState(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };


    const currentYear = new Date().getFullYear().toString();

    const [searchYear, setSearchYear] = useState(currentYear);

    const { isLoading, error, data } = useQuery(["SellerSalesDaily", searchYear], () => getSellerSalesDaily(searchYear));


    const [searchResults, setSearchResults] = useState([]);
  
    
    const generateChartData = (data) => {
        const filteredData = Object.keys(monthCheckedState)
            .filter(month => monthCheckedState[month])
            .map(month => ({
                label: `${month}월`,
                data: data[month], 
                backgroundColor: monthColors[month].backgroundColor,
                borderColor: monthColors[month].borderColor,
                pointBackgroundColor: '#7CCE76',
                pointBorderColor: '#2F9D27',
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
            }));
    
        return {
            labels: data?.labels, 
            datasets: filteredData,
        };
    };

    useEffect(() => {
        if (!isUpdated && data) {
            const newChartData = generateChartData(data);
            setChartData(newChartData);
        }
    }, [data, isUpdated]);

   
    // 초기화
    const handleResetClick = () => {
        window.location.href = '/selleradmin/salesstatistics/salesstatistics/'; 
    };
    

    // 검색버튼 클릭 시 
    const onSearch = async  () => {
        await handleSellerSalesDaily({
            setSearchResults,
            searchYear,
        });

    };

    useEffect(() => {
        if (searchResults && searchResults.labels && searchResults.labels.length > 0) {
            const newChartData = generateChartData(searchResults);
            setChartData(newChartData);
        }
    },[searchResults]);

    return (
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>판매통계</h2>
            </div>
            
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field date'>
                        <div> <b>날짜</b> </div>
                        <div>
                            <YearSelect value={searchYear} onChange={(e) => setSearchYear(e.target.value)} />
                        </div>   
                    </div>
                
                    <div className='search-field division'>
                        <div> 
                            <b>조회</b> 
                        </div>
                        <MonthCheckboxGroup checkedState={monthCheckedState} onChange={handleCheckboxChange} />
                    </div>

                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

        
        {/* 검색 결과 */}
        <section className='McontentBox'>
            <Chart data={chartData}/>
        </section>
    </div>
            
    </>
    )
}

export default SalesStatistics