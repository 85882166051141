/***************************************************************************************
* FileName      : Reviewable.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 작성가능한 리뷰 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import MypageTabComponent from './MypageTab_v2.js';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';

import ReviewBox from './Reviewable/ReviewBox.js';
import { getMyReview, getMyReviewable , useMyReviewableListQuery } from '../../api/services/mypageApi.js';

import Pagination from '../customer/common/Pagination.js';
// import OrderRow from './Review/OrderRow.js';
function ReviewLayout()
{
    const [reviewable, setReviewable] = useState(); //작성 가능한 리뷰 리스트
    useEffect(()=>{
        getMyReview()
            .then(res => console.log("gg",res?.data))
            .catch(error => alert('리뷰데이터 조회 에러'))

        getMyReviewable()
            .then(res => setReviewable(res?.data?.data));
    },[])

    return(
        <>
        <Container>
            <StatusBar/>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'review'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <MyReviewable/>
                      
                    </Col>
                </div>
            </Row>

        </Container>
        
        </>
      )
}

function MyReviewable()
{
    const { data:myReviewData, error, isFetching, page, setPage } = useMyReviewableListQuery();

    if (!myReviewData) {
        return <div>Loading...</div>;
    }

    const tabs = [
        {label: '작성 가능한 리뷰' , url : '/mypage/reviewable'},
        {label: '나의 리뷰' ,  url : '/mypage/review' }
    ];

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
          
    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>          
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-5 fw-bold">리뷰관리</h3>
                </Col>
            </Row>

            <MypageTabComponent tabs={tabs} />

       
            {myReviewData?.totalCnt === 0 ? (
                <tr>
                    <td colSpan="2">작성 가능한 리뷰가 없습니다.</td>
                </tr>
            ) : (    
                myReviewData?.data?.map((data, index) => (
                    <ReviewBox
                        orderItem={data}
                        index={index}
                    />
                ))
            )}

     
            {/* 페이지내비게이션 */}
            <section className='mt-5'>
              <Pagination
                  count={myReviewData?.totalCnt || 0}
                  page={page}
                  setPage={setPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
              />
          </section>
        </div>
        </>
    )
}



export default ReviewLayout