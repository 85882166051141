/***************************************************************************************
* FileName      : PenaltyNegative.js
* Description   : 해와관리자 > 셀러관리 > 활동기록 > 패널티 (정지)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.12
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useVendorPenaltyActionQuery } from "../../../../api/hwadmin/storeApi";
import SortComponent from "../../common/SortComponent2";
import PageSizeSelect from "../../common/PageSizeSelect";
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';
import Pagination from '../../common/Pagination';

function PenaltyNegative()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useVendorPenaltyActionQuery(ordering , pageSize);

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
  
    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value));
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    console.log(data);
    return(
        <>
          <section className='McontentBox'>
            <div className='subTitle'>
                <div className=''>
                    <span>결과</span>
                    <span className='result-wrap'>
                        (총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)
                    </span>
                </div>

                <div className='selectWrap'>
                    <SortComponent
                        ordering={ordering}
                        handleOrderingChange={handleOrderingChange}
                        newLabel="최근처리순"
                        oldLabel="오래된처리순"
                    />

                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                </div>
            </div>
            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                        {/* <col style="width:10%"/>
                        <col style="width:10%"/>
                        <col style="width:10%"/>
                        <col style="width:10%"/>
                        <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>상호</th>
                            <th>셀러ID</th>
                            <th>구분</th>
                            <th>처리사유</th>
                            <th>처리일시</th>
                            <th>처리자</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data?.data.map((penalty, index) => (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{penalty.vendor_name}</td>
                            <td>{penalty.vendor_owner}</td>
                            <td>{penalty.action}</td>
                            <td>{penalty.reason}</td>
                            <td>{extractYearMonthDayHourMinute2(penalty.created_at)}</td>
                            <td>{penalty.manager_name}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </section>


        {/* 페이지내비게이션 */}
        <section className='mt-3'>
            <Pagination
                count={data?.totalCnt || 0}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
            />
        </section>
        </>
    )
}

export default PenaltyNegative