/***************************************************************************************
* FileName      : InvoiceHistory.js
* Description   : 해와관리자 > 정산 > 세금계산서내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Button} from 'react-bootstrap';

import { useAdjustInvoiceDocumentQuery , handleAdjustInvoiceSearch } from '../../../api/hwadmin/adjustApi';
import { useApprovedVendors } from '../../../api/hwadmin/productApi';

import React, { useState , useEffect } from 'react';
import Pagination from '../common/Pagination'
import { AdjustRow } from './Invoice/AdjustRow';
import AddModal from './Invoice/AddModal';


import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';
import SelectVendor from '../product/Component/SelectVendor';

function InvoiceHistory()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useAdjustInvoiceDocumentQuery(ordering,pageSize);
    

    const [searchAdjust, setSearchAdjust] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    console.log("--")
    console.log(data)
    console.log("데이터")

    // 내역추가 모달
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });


    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
       
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleAdjustInvoiceSearch({
            searchAdjust, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate,
            selectedEndDate, 
            selectedVendor
        });

        setSearchConfirm(true)
    };
    
    const handleResetClick = () => {
        window.location.href = '/admin/settle/invoicehistory'; 
    };


    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>세금계산서내역</h2>
                <span className="nt">셀러에게 발행한 세금계산서 내역입니다.</span>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                            </select>
                            <input type="text"
                                    value={searchAdjust}
                                    onChange={(e) => setSearchAdjust(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="join">발행일</option>
                                <option value="last">마지막방문일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>   
                    </div>
                    <div className='search-field division'>
                        <div> 
                            <b>수산상회</b> 
                        </div>
                        <div>
                            <SelectVendor 
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                />  
                        </div>   
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="최근발행순"
                            oldLabel="오래된발행순"
                        />
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                    }
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                    <div className=''>
                        <Button className='startbtn' variant="primary" onClick={openModal}>
                            세금계산서 추가
                        </Button>
                    </div>
                    <div className='m-btn-wrap-sm'>
                        <button className='m-sm-btn'>이메일 발송</button>
                        <button className='m-sm-btn'>알림톡 발송</button>
                        <button className='m-sm-btn'>푸시 발송</button>
                        <button className='m-sm-btn'>다운로드</button>
                    </div>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                               <th><input type='checkbox'/></th>
                               <th>발행일</th>
                               <th>수산상회</th>
                               <th>사업자등록번호</th>
                               <th>발행내용</th>
                               <th>발행번호</th>
                               <th>공급가액</th>
                               <th>세액</th>
                               <th>합계</th>
                               <th>세금계산서</th>
                            </tr>
                        </thead>
                        <tbody>
                                
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : searchResults?.count > 0 ? (
                            searchResults?.data.map((Adjust , index) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))
                        ) : data && data?.data && data?.data.length > 0 ? (
                            data?.data.map((Adjust , index ) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))
                        ) : null}
                        
                        </tbody>
                    </table>
                </div>
            </section>

              {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                        <Pagination
                            count={searchResults?.count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>

            <AddModal show={isOpen} setShow={setIsOpen}/>

        </>
    )
}

export default InvoiceHistory