import React, { useState, useEffect } from 'react';
import { Col, Row, Breadcrumb } from 'react-bootstrap';

// 전체 카테고리
import { getAllCategory, getTargetCategory } from '../../../api/product/product.js';


export function CategoryFilter({category, setNowCategoryName, setModifiedCategoryId}){
    // [hm] 필터링 방식 테스트
    const [activeFilterDepth1, setActiveFilterDepth1] = useState();
    const [activeFilterDepth2, setActiveFilterDepth2] = useState();
    const [activeFilterDepth3, setActiveFilterDepth3] = useState();
    const [activeFilterName1, setActiveFilterName1] = useState();
    const [activeFilterName2, setActiveFilterName2] = useState();
    const [activeFilterName3, setActiveFilterName3] = useState();
    const [categoryDepth1, setCategoryDepth1] = useState([]); //대분류 카테고리 id, 이름 저장
    const [categoryDepth2, setCategoryDepth2] = useState([]); //중분류 카테고리 id, 이름 저장
    const [categoryDepth3, setCategoryDepth3] = useState([]); //소분류 카테고리 id, 이름 저장

    useEffect(()=>{
        // 전체 카테고리 조회
        getAllCategory()
            .then(res => {
                console.log('전체 카테고리 :',res?.data);

                // 일단 하위 카테고리 초기화
                setCategoryDepth2([]);
                setCategoryDepth3([]);
                setActiveFilterDepth2();
                setActiveFilterDepth3();
                setActiveFilterName2();
                setActiveFilterName3();

                // res?.data에서 id와 name을 추출하여 객체로 변환하고 배열에 추가
                const mappedData = res?.data?.map((item) => ({
                    id: item.id,
                    name: item.name,
                }));

                // categoryDepth1 상태 업데이트
                setCategoryDepth1(mappedData);

                if(activeFilterDepth1){ // 선택된 대분류가 있으면
                    const selectedCategory = res?.data.find((item) => item.id === activeFilterDepth1);
                    if (selectedCategory) {
                        const children = selectedCategory.children || [];
                        // categoryDepth2 상태 업데이트
                        setCategoryDepth2(children);
                    }
                }
            })
    },[activeFilterDepth1])

    useEffect(()=>{
        console.log('active:',activeFilterDepth2);
        if(activeFilterDepth2){
            getTargetCategory(activeFilterDepth2)
                .then(res => {
                    setCategoryDepth3(res?.data?.data)
                })
        }
    }, [activeFilterDepth2])
    // [hm] 필터링 방식 테스트 끝


    useEffect(()=>{
        activeFilterDepth3 ? setNowCategoryName(activeFilterName3) : (
        activeFilterDepth2 ? setNowCategoryName(activeFilterName2) : (
        activeFilterDepth1 ? setNowCategoryName(activeFilterName1) : setNowCategoryName(category)
        ));
    },[activeFilterDepth1, activeFilterDepth2, activeFilterDepth3]);

    return(
        <>
        {/* 테스트 카테고리 */}
            <Col xs={12}>
                <Row className='mb-0'>
                    <Col xs={3} className="border py-2 px-3">
                        <p className='fs-6 fw-bolder'>카테고리</p>
                    </Col>
                    <Col xs={9}>
                        <Row>
                            <Col xs={12} className="border py-2 px-3">
                                <Breadcrumb className='custom-breadcrumb'>
                                    <Breadcrumb.Item href='/'>홈</Breadcrumb.Item>
                                    {!activeFilterDepth1 ? 
                                        <Breadcrumb.Item href='/product/list?category=전체' active>전체상품</Breadcrumb.Item> :
                                        (
                                            <>
                                                <Breadcrumb.Item href='/product/list?category=전체'>전체상품</Breadcrumb.Item>
                                                <Breadcrumb.Item active>{activeFilterName1}</Breadcrumb.Item>
                                            </>
                                        )
                                    }
                                    
                                </Breadcrumb>
                            </Col>
                            <Col xs={12} className="border py-2 px-3">
                                {
                                    !activeFilterDepth1 ? ( 
                                        //대분류 카테고리 선택이 안된경우
                                        <ul className='d-flex flex-wrap'>
                                            {categoryDepth1?.map((x) => (
                                                <li className="me-3 pointer" onClick={()=>{
                                                    console.log("클릭한 id는 ", x?.id);
                                                    setActiveFilterDepth1(x?.id);
                                                    setActiveFilterName1(x?.name);
                                                    setModifiedCategoryId(x?.id);
                                                    // 2,3뎁스 카테고리 초기화
                                                    setActiveFilterDepth2();
                                                    setActiveFilterName2();
                                                    setActiveFilterDepth3();
                                                    setActiveFilterName3();
                                                }}><p className={activeFilterDepth1 === x?.id && 'text-hw fw-bold'}>{x?.name}</p></li>)
                                            )}
                                        </ul>
                                    ):(
                                        //대분류 카테고리 선택이 완료된경우 -> 중분류 선택
                                        <ul className='d-flex flex-wrap'>
                                            {categoryDepth2?.map((x) => (
                                                <li className="me-3 pointer" onClick={()=>{
                                                    setActiveFilterDepth2(x?.id);
                                                    setActiveFilterName2(x?.name);
                                                    setModifiedCategoryId(x?.id);
                                                    // 3뎁스 카테고리 초기화
                                                    setActiveFilterDepth3();
                                                    setActiveFilterName3();
                                                }}><p className={activeFilterDepth2 === x?.id && 'text-hw fw-bold'}>{x?.name}</p></li>)
                                            )}
                                        </ul>
                                    )
                                }
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col xs={3} className="border py-2 px-3">
                        <p className='fs-6 fw-bolder'>필터 <span className='text-secondary fs-7'>(생물상태)</span></p>
                    </Col>
                    <Col xs={9} className="border py-2 px-3">
                        <ul className='d-flex flex-wrap'>
                            {categoryDepth3?.map((x) => (
                                <li className="me-3 pointer" onClick={()=>{
                                    setActiveFilterDepth3(x?.id);
                                    setActiveFilterName3(x?.name);
                                    setModifiedCategoryId(x?.id);
                                }}><p className={activeFilterDepth3 === x?.id && 'text-hw fw-bold'}>{x?.name}</p></li>)
                            )}
                        </ul>
                    </Col>
                </Row>

            </Col>
        </>
    )
}

// 상태가 주어진 카테고리 필터(ex : 생물, 반건조, 냉동)
export function CategoryStateFilter({category, setModifiedCategoryId}){
    // [hm] 필터링 방식 테스트
    const [activeFilterDepth1, setActiveFilterDepth1] = useState();
    const [activeFilterDepth2, setActiveFilterDepth2] = useState();
    const [activeFilterName1, setActiveFilterName1] = useState();
    const [categoryDepth1, setCategoryDepth1] = useState([]); //대분류 카테고리 id, 이름 저장
    const [categoryDepth2, setCategoryDepth2] = useState([]); //중분류 카테고리 id, 이름 저장

    useEffect(()=>{
        // 전체 카테고리 조회
        getAllCategory()
            .then(res => {
                console.log('전체 카테고리 :',res?.data);

                // 일단 하위 카테고리 초기화
                setCategoryDepth2([]);
                setActiveFilterDepth2();

                // res?.data에서 id와 name을 추출하여 객체로 변환하고 배열에 추가
                const mappedData = res?.data?.map((item) => ({
                    id: item?.id,
                    name: item?.name,
                }));

                // categoryDepth1 상태 업데이트
                setCategoryDepth1(mappedData);

                if(activeFilterDepth1){ // 선택된 대분류가 있으면
                    const selectedCategory = res?.data.find((item) => item.id === activeFilterDepth1);
                    if (selectedCategory) {
                        const children = selectedCategory.children || [];
                        // categoryDepth2 상태 업데이트
                        setCategoryDepth2(children);
                    }
                }
            })
    },[activeFilterDepth1])

    // [hm] 필터링 방식 테스트 끝
    const categpryLink = `/product/list?category=${category}`;


    return(
        <>
        {/* 테스트 카테고리 */}
            <Col xs={12}>
                <Row className='mb-0'>
                    <Col xs={3} className="border py-2 px-3">
                        <p className='fs-6 fw-bolder'>카테고리</p>
                    </Col>
                    <Col xs={9}>
                        <Row>
                            <Col xs={12} className="border py-2 px-3">
                                <Breadcrumb className='custom-breadcrumb'>
                                    <Breadcrumb.Item href='/'>홈</Breadcrumb.Item>
                                    {!activeFilterDepth1 ? 
                                        <Breadcrumb.Item href={categpryLink} active>{category}</Breadcrumb.Item> :
                                        (
                                            <>
                                                <Breadcrumb.Item href={categpryLink}>{category}</Breadcrumb.Item>
                                                <Breadcrumb.Item active>{activeFilterName1}</Breadcrumb.Item>
                                            </>
                                        )
                                    }
                                    
                                </Breadcrumb>
                            </Col>
                            <Col xs={12} className="border py-2 px-3">
                                {
                                    !activeFilterDepth1 ? ( 
                                        //대분류 카테고리 선택이 안된경우
                                        <ul className='d-flex flex-wrap'>
                                            {categoryDepth1?.map((x) => (
                                                <li className="me-3 pointer" onClick={()=>{
                                                    console.log("클릭한 id는 ", x?.id);
                                                    setActiveFilterDepth1(x?.id);
                                                    setActiveFilterName1(x?.name);
                                                    setModifiedCategoryId(x?.id);
                                                    // 2,3뎁스 카테고리 초기화
                                                    setActiveFilterDepth2();
                                                }}><p className={activeFilterDepth1 === x?.id && 'text-hw fw-bold'}>{x?.name}</p></li>)
                                            )}
                                        </ul>
                                    ):(
                                        //대분류 카테고리 선택이 완료된경우 -> 중분류 선택
                                        <ul className='d-flex flex-wrap'>
                                            {categoryDepth2?.map((x) => (
                                                <li className="me-3 pointer" onClick={()=>{
                                                    setActiveFilterDepth2(x?.id);
                                                    setModifiedCategoryId(x?.id);
                                                }}><p className={activeFilterDepth2 === x?.id && 'text-hw fw-bold'}>{x?.name}</p></li>)
                                            )}
                                        </ul>
                                    )
                                }
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </>
)
}