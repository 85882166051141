
/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 해와관리자 > 정산 > 지급내역 > AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import EditModal from './EditModal';
import { fetchadjustGroupPayment } from '../../../../api/hwadmin/adjustApi';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

function AdjustRow({ Adjust })
{
    const [isOpen, setIsOpen] = useState(false);
    
    const openModal = () => {
      setIsOpen(true);
    };

    const onClickpayment = () =>{ 
        const isConfirmed = window.confirm('정산 지급을 완료 하시겠습니까?');
        if (isConfirmed) {
            fetchadjustGroupPayment(Adjust.id)
            .then(data => {
              // console.log('Fetched data:', data);
            })
            .catch(error => {
              // console.error('Error fetching data:', error);
            });
          }
    }

    return (
      <>
        <tr>
            <td>
              {Adjust.complete_at  ? 
              extractYearMonthDayHourMinute2(Adjust.complete_at ) : "대기"}
            </td>
            <td>{Adjust?.vendor}</td>
            <td>{Adjust.settlement_amount.toLocaleString()}원</td>
            <td>{Adjust.settlement_target_amount.toLocaleString()}원</td>
            <td>{Adjust.settlement_fee.toLocaleString()}원</td>
            <td>{Adjust.bank} {Adjust.account_number}</td>
            <td>   
                {Adjust.payment}
                {Adjust.payment != "지급완료" && 
                <>
                 <button className='m-sm-btn red'  onClick={()=>{openModal()}} >수정</button>
                  <button className='m-sm-btn blue' onClick={()=>{onClickpayment()}}>완료</button>  
                </>
                }
            </td>
        </tr>

        <EditModal id={Adjust.id} show={isOpen} setShow={setIsOpen}/>

      </>
    )
}

export default AdjustRow