import { Table } from 'react-bootstrap'; 

function ReturnExchangeInfo({ productDetails }) {

    return (
        <Table className="order-detail-table">
            <tbody className="fs-7">
                <tr>
                    <td colSpan={1}>반품주소지</td>
                    <td colSpan={3}>
                        {productDetails?.return_exchange_info?.return_address?.address}, 
                        {productDetails?.return_exchange_info?.return_address?.detail}
                    </td>
                </tr>
                <tr>
                    <td colSpan={1}>초도배송비</td>
                    <td colSpan={3}>{productDetails?.return_exchange_info?.initial_delivery_fee.toLocaleString()}원</td>
                </tr>
                <tr>
                    <td colSpan={1}>반품배송비</td>
                    <td colSpan={3}>{productDetails?.return_exchange_info?.return_delivery_fee.toLocaleString()}원</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default ReturnExchangeInfo