import React, { useState , useEffect } from "react";
import { addCommasToNumberString } from '../../../common/numberUtils';

function SingleProduct({ SingleProductData, setSingleProductData })
{
    const handleRegularPriceChange = (event) => {
        const value = event.target.value;
        
        setSingleProductData({
          ...SingleProductData,
          regularPrice: value
        });
    };

    const handleSalePriceChange = (event) => {
        const value = event.target.value;

        setSingleProductData({
            ...SingleProductData,
            salePrice: value,
        });
    };

    const handleStockQuantityChange = (event) => {
        const value = event.target.value;

        setSingleProductData({
          ...SingleProductData,
          stockQuantity: value
        });
    };

    return (
        <>
        <div className='fixTable_wrap'>
            <table className='sTable' id='scrollTable'>
                <colgroup>
                                
                </colgroup>
                <thead>
                    <tr>   
                        <th>정상가</th>
                        <th>판매가</th>
                        <th>재고수량</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" 
                                    className="form-input"
                                    value={addCommasToNumberString(SingleProductData.regularPrice)}
                                    onChange={handleRegularPriceChange} />
                        </td>
                        <td>
                            <input type="text" 
                                    className="form-input"
                                    value={addCommasToNumberString(SingleProductData.salePrice)}
                                    onChange={handleSalePriceChange} />
                        </td>
                        <td>
                            <input type="text" 
                                    className="form-input"
                                    value={addCommasToNumberString(SingleProductData.stockQuantity)}
                                    onChange={handleStockQuantityChange} />
                        </td>
                    </tr>       
                </tbody>

            </table>     
        </div>

    </>
    )
}

export default SingleProduct