
/***************************************************************************************
* FileName      : ReviewBoard.js
* Description   : 해와관리자 > 게시판 > 상품후기 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import React, { useState , useEffect } from 'react';


import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'

import { useProductReviewListQuery , handleProductReviewSearch } from '../../../api/hwadmin/boardApi';
import { ReviewRow } from './Review/ReviewRow';

import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';

function ReviewBoard()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage  } = useProductReviewListQuery(ordering , pageSize);


    const [searchReview, setSearchReview] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    
    const [selectedRows, setSelectedRows] = useState({});
    
    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');


    // 분류
    const [selectedCategory, setSelectedCategory] = useState('');

    // 이미지 등록 여부
    const [isImage, setIsImage] = useState('');

    // 상태 체크박스
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const checkBoxList = ['전체선택', '5', '4.5', '4','3.5','3','2.5','2','1.5','1'];

    useEffect(() => {
        // 체크박스들이 모두 선택되었는지 확인하여 전체 선택 상태 업데이트
        setIsAllChecked(checkedItems.length === checkBoxList.length);
        if(isAllChecked){
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((item) => item !== '전체선택')
          );
        }
    }, [checkedItems]);

    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체선택') {
            // "전체선택" 체크박스의 경우, 모든 체크박스를 선택 또는 해제
            if (checked) {
            setCheckedItems(checkBoxList);
            } else {
            setCheckedItems([]);
            }
        } else {
            // 다른 체크박스의 경우, 개별 체크박스 상태 업데이트
            if (checked) {
                setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
            } else {
                setCheckedItems((prevCheckedItems) =>
                    prevCheckedItems.filter((item) => item !== id)
                );
            }
        }
    };
      
    const handleStatusChange = (event) => {
        setIsImage(event.target.value);
    };

    // 분류 값 변화
    const handleCategoryTypeRadioChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

     // 검색버튼 클릭 시 
     const onSearch = () => {
        handleProductReviewSearch({
            searchReview, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedCategory, 
            isImage,
            ordering,
            checkedItems

        });

        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false)
        window.location.href = '/admin/board/review/'; // 페이지 리로드
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };

    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };


    console.log('data',data);
    
    return(
        <>
        <div className='mainContents'>
            {/* 상품후기 */}
            <div className='page-tit'>
                <h2>상품후기</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="title">상품번호</option>
                                <option value="name">상품명</option>
                                <option value="name">판매자</option>
                                <option value="name">작성자</option>
                            </select>
                            <input type="text"
                                    value={searchReview}
                                    onChange={(e) => setSearchReview(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div>
                            <b>날짜</b>
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="created">등록일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>
                    </div>

                    <div className='search-field division' style={{ margin: '20px 0px' }}>
                        <div> 
                            <b>평점</b> 
                        </div>
                        <div className='CheckboxWrap' style={{ width: '50%' }}>
                            {checkBoxList.map((item, idx) => (
                                <div className='checkbox' key={idx}>
                                <input
                                    type='checkbox'
                                    id={item}
                                    checked={checkedItems.includes(item)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={item}>{item}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='search-field division'>
                        <div>
                            <b>이미지 등록여부</b>
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='g1' name='group1' value='' checked={isImage === ''} onChange={handleStatusChange} />
                            <label htmlFor='g1'>전체</label>
                            <input type='radio' id='g2' name='group1' value='true' checked={isImage === 'true'} onChange={handleStatusChange} />
                            <label htmlFor='g2'>이미지 있음</label>
                            <input type='radio' id='g3' name='group1' value='false' checked={isImage === 'false'} onChange={handleStatusChange} />
                            <label htmlFor='g3'>이미지 없음</label>
                        </div>   
                    </div>

                </div>

                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="신규등록순"
                            oldLabel="오래된등록순"
                            />
    
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                    }  
                </div>
              


                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>번호</th>
                                <th>평점</th>
                                <th>상품번호 <br/>
                                    상품명
                                </th>
                                <th>판매자</th>
                                <th>이미지</th>
                                <th>상품후기</th>
                                <th>작성자</th>
                                <th>등록일시</th>
                                <th>관리</th>
                            </tr>
                            </thead>

                            <tbody>
                            {searchConfirm && searchResults?.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults.data.map((review, index) => (
                                    <ReviewRow
                                        review={review}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data.data.map((review, index) => (
                                    <ReviewRow
                                        review={review}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : null}

                            </tbody>
                        </table>
                    </div>
                </section>

                {/* 페이지내비게이션 */}
        
                <section>
                    {searchConfirm && searchResults?.count === 0 ? (
                        <>
                        </>
                    ): searchResults?.count ? (
                        <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                        
                    ) : (
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    )}
                </section>


            </div>

        </>
    )
}

export default ReviewBoard