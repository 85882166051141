import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFilteringOptions } from '../../../../../api/Category';

function Filtering({ categoryId, filterIdArr, setFilterIdArr , setFilteringCheck})
{
  const queryClient = useQueryClient();
  const { isLoading, isError, data: filteringData } = useFilteringOptions(categoryId);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setFilterIdArr(selectedCategory);
  }, [selectedCategory]);

  useEffect(()=>{
    console.log("Change... categoryId",categoryId)
  },[categoryId])
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching main categories.</div>;
  }

  const handleSubCategoryChange = (id) => {
    // 라디오 버튼을 클릭하면 해당 카테고리가 선택됩니다.
    setSelectedCategory(id);

  };

  return (
    <>
     {filteringData.data && filteringData.data.length === 0 ? (
        <div>상품 필터 데이터가 없습니다.</div>
      ) : (
        <ul>
          {filteringData.data && filteringData.data.map((category) => (
              <li className='fchecklist' key={category.id}>
                <label>
                    <input
                      type="radio"
                      name={`filterCategory-${categoryId}`} // 각각의 Filtering 컴포넌트에 고유한 이름을 제공
                      value={category.id}
                      checked={selectedCategory === category.id}
                      onChange={() => handleSubCategoryChange(category.id)}
                    />
                    <span style={{ color: selectedCategory === category.id ? '#0073e9' : 'rgb(0, 0, 0)' }}>
                        {category.name}
                    </span>
                </label>
            </li>
          ))}
        </ul>
      )}

     
    </>
  );
}

export default Filtering;
