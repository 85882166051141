import {  queryClient , useMutation , useQuery , prefetchQuery , useQueryClient } from 'react-query';
import { BASE_URL } from '../apiConfig';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';
import { getAccessFromCookie } from '../cookieUtils';

const PAGE_SIZE = 10;

// 해와 관리자 > 고객센터 > 고객센터 리스트 조회
const fetchCenterList = (page, pageSize, ordering) => {
    return instance.get(`inquiry/onetoone/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
      .then(res => res.data);
};
  
export const useCenterListQuery = (ordering , pageSize) => {
  
    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);

    useEffect(() => {
      setPage(1);
    }, [pageSize]);
    
    useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`center`, page , nextPage],
        () => fetchCenterList(nextPage, pageSize)
      );
    },[page , queryClient])


    const { data, error, isFetching } = useQuery(
        ['center', page , pageSize,ordering], () => fetchCenterList(page , pageSize, ordering), 
        {
          staleTime:5000,
          keepPreviousData:true
        },
    );

    return {
        data,
        error,
        isFetching,
        page,
        setPage,
    };
};


// 해와관리자 > 고객센터 > 고객센터 검색
export const handleCenterSearch  = async ({
    searchAuth, 
    setSearchResults,
    setSearchUrl,
    searchOption,
    selectedDateType,
    selectedStartDate,
    selectedEndDate, 
    selectedCategory, 
    answerStatus,
    ordering,
    checkedItems
    }) => {
    try {
      let url = `inquiry/onetoone/document/`;
  
      if (searchAuth) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchAuth}`;
            break;
          case 'title':
            url += `?title=${searchAuth}`;
            break;
          case 'user':
            url += `?user=${searchAuth}`;
            break;
        case 'manager':
            url += `?manager=${searchAuth}`;
            break;
            
          default:
            break;
        }
      }else{
        url += `?`;
      }
  
      if (selectedStartDate && selectedEndDate) {
        switch(selectedDateType) {
          case 'created':
            url += `&created_at__range=${selectedStartDate}__${selectedEndDate}`;
            break;

        case 'answered':
            url += `&answered_at__range=${selectedStartDate}__${selectedEndDate}`;
            break;
      
        default:
            break;
        }
      }
  
      if (checkedItems.length > 0) {
        for (let i = 0; i < checkedItems.length; i++) {
          url += `&type=${checkedItems[i]}`;
        }
      }
    
      if (answerStatus) url += `&status=${answerStatus}` 
   
  
      url += `&ordering=${ordering}` 
  
      console.log(url)
      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  

// 고객관리 > 고객센터 > 답변등록
// 고객관리 > 상품문의 > 답변등록

export const registerProductAnswer = (data) =>
  instance.post(`inquiry/product/answer/`, data).then(response => response.data);


// 해와관리자 > 1:1 문의 답변등록
export const registerOnetooneAnswer = (data) =>
  instance.post(`inquiry/onetoone/answer/`, data).then(response => response.data);

export function useAddAnswer() {
  const access = getAccessFromCookie();

  const post = async (url , data) => {
    try {
      const response = await fetch(`${BASE_URL}${url}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to post");
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw new Error("Failed to post");
    }
  };

  return {
    post,
  };
}



// 해와 관리자 > 상품문의 > 상품문의 리스트 조회
const fetchProductInquiryList = (page, pageSize,ordering) => {
  return instance.get(`inquiry/product/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useProductInquiryListQuery = (ordering , pageSize) => {
  
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    if (data && data.nextPage) {
      const nextPage = page + 1;
      queryClient.prefetchQuery([`productInquiry`, page , nextPage],
        () => fetchProductInquiryList(nextPage, pageSize)
      );
    }
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
    ['productInquiry',page, pageSize, ordering], () => fetchProductInquiryList(page , pageSize, ordering));

  return {
      data,
      error,
      isFetching,
      page,
      setPage,
  };
};

// 해와관리자 > 상품문의 > 상품문의 검색
export const handleProductInquirySearch  = async ({
  searchAuth, 
  setSearchResults,
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  selectedCategory, 
  answerStatus,
  ordering,
  checkedItems
  }) => {
  try {
    let url = `inquiry/product/document/`;

    if (searchAuth) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchAuth}`;
          break;
        case 'title':
          url += `?title=${searchAuth}`;
          break;
        case 'user':
          url += `?user=${searchAuth}`;
          break;
      case 'manager':
          url += `?manager=${searchAuth}`;
          break;
          
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'created':
          url += `&created_at__range=${selectedStartDate}__${selectedEndDate}`;
          break;

      case 'answered':
          url += `&answered_at__range=${selectedStartDate}__${selectedEndDate}`;
          break;
    
      default:
          break;
      }
    }

  
    if (answerStatus) url += `&status=${answerStatus}` 
    
    if (checkedItems.length > 0) {
      for (let i = 0; i < checkedItems.length; i++) {
        url += `&type=${checkedItems[i]}`;
      }
    }
  

    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
