import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


function WishVendorBox({data}){    
    const navigate = useNavigate();
    return(
        <Col xs={6} sm={4} md={4} lg={3}>
            <div className='border p-2 rounded-1 pointer' onClick={()=>navigate(`/product/vendor/items?name=${data?.name}&id=${data?.id}`)}>
                <img src={data?.owner_image}/>
                <p className='vendor_name my-2'>{data?.name}</p>
            </div>
        </Col>
    )
}

export default WishVendorBox