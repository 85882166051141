/***************************************************************************************
* FileName      : Center.js
* Description   : 해와관리자 > 고객관리 > 고객센터 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import React, { useState , useEffect } from 'react';
import { useMutation } from 'react-query';
import { useCenterListQuery  , handleCenterSearch } from '../../../api/hwadmin/customerApi'
import { CenterRow } from "./Center/CenterRow";
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'

import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';

function CustomerCenter()
{

    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useCenterListQuery(ordering,pageSize);


    const [searchAuth, setSearchAuth] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    
    const [selectedRows, setSelectedRows] = useState({});

    // 분류 체크박스
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const checkBoxList = ['전체선택', '배송지연', '반품문의', '교환문의', '취소문의', '회원정보문의', '주문결제문의', '상품정보문의', '기타문의'];

    useEffect(() => {
        // 체크박스들이 모두 선택되었는지 확인하여 전체 선택 상태 업데이트
        setIsAllChecked(checkedItems.length === checkBoxList.length);
        if(isAllChecked){
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((item) => item !== '전체선택')
          );
        }
    }, [checkedItems]);
    
    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체선택') {
            // "전체선택" 체크박스의 경우, 모든 체크박스를 선택 또는 해제
            if (checked) {
                setCheckedItems(checkBoxList);
            } else {
                setCheckedItems([]);
            }
        } else {
            // 다른 체크박스의 경우, 개별 체크박스 상태 업데이트
            if (checked) {
                setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
            } else {
                setCheckedItems((prevCheckedItems) =>
                    prevCheckedItems.filter((item) => item !== id)
                );
            }
        }
    };

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');


    // 분류
    const [selectedCategory, setSelectedCategory] = useState('');

    //답변 상태 
    const [answerStatus, setAnswerStatus] = useState('');

    // 함수명 변경
    const handleAnswerStatusChange = (event) => {
        setAnswerStatus(event.target.value);
    };
    
    // 분류 값 변화
    const handleCategoryTypeRadioChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };


    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleCenterSearch({
            searchAuth, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedCategory, 
            answerStatus,
            ordering,
            checkedItems,
        });

        setSearchConfirm(true);
    };
  

    // 초기화
    const handleResetClick = () => {
        // 초기 데이터로 되돌립니다.
        setSearchConfirm(false)
        window.location.href = '/admin/customer/center'; // 페이지 리로드
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    
    return(
        <>
        <div className='mainContents'>
            {/* 고객센터 */}
            <div className='page-tit'>
                <h2>고객센터</h2>
            </div>

            
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="title">제목</option>
                                <option value="user">작성자</option>
                                <option value="manager">처리자</option>

                            </select>
                            <input type="text"
                                    value={searchAuth}
                                    onChange={(e) => setSearchAuth(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div>
                            <b>날짜</b>
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="created">등록일</option>
                                <option value="answered">답변일</option>

                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>
                    </div>

                    <div className='search-field checkbox-wrap' >
                        <div> 
                            <b>분류</b> 
                        </div>
                        <div className='CheckboxWrap'>
                            {checkBoxList.map((item, idx) => (
                                <div className='checkbox' key={idx}>
                                    <input
                                        type='checkbox'
                                        id={item}
                                        checked={checkedItems.includes(item)}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={item}>{item}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='search-field division'>
                        <div>
                            <b>답변상태</b>
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='g1' name='group1' value='' checked={answerStatus === ''} onChange={handleAnswerStatusChange} />
                            <label htmlFor='g1'>전체</label>
                            <input type='radio' id='g2' name='group1' value='답변대기' checked={answerStatus === '답변대기'} onChange={handleAnswerStatusChange} />
                            <label htmlFor='g2'>답변대기</label>
                            <input type='radio' id='g3' name='group1' value='답변완료' checked={answerStatus === '답변완료'} onChange={handleAnswerStatusChange} />
                            <label htmlFor='g3'>답변완료</label>
                        </div>   
                    </div>
                </div>

                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="신규등록순"
                            oldLabel="오래된등록순"
                        />
    
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>

                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>번호</th>
                                <th>분류</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>등록일시</th>
                                <th>답변상태</th>
                                <th>답변내용</th>
                                <th>답변일시</th>
                                <th>처리자</th>
                            </tr>
                        </thead>
                        <tbody>
                       
                            {searchResults.count > 0 ? (
                                // 검색 결과를 렌더링하는 코드
                                searchResults.data.map((center, index) => (
                                    <CenterRow
                                        center={center}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : data && data.data && data.data.length > 0 ? (
                                // 기본 데이터를 렌더링하는 코드
                                data.data.map((center, index) => (
                                    <CenterRow
                                        center={center}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="23">검색 결과가 없습니다.</td>
                                </tr>
                            )}
                          
                        </tbody>  
                          
                          
                        </table>
                    </div>
                </section>

                {/* 페이지내비게이션 */}
                <section>
                    {searchConfirm ? (
                        <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        /> 
                    ) : (
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    )}
                </section>
            </div>

        </>
    )
}

export default CustomerCenter