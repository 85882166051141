import {  queryClient , useMutation , useQuery , prefetchQuery , useQueryClient } from 'react-query';
import { BASE_URL } from './../apiConfig';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;
const boundary = Math.random().toString().substr(2);


// 상품 상세 조회
export const fetchProductDetails = (productId) => {
  return instance.get(`/product/${productId}`)
    .then(res => res.data);
};

// 상품 조회
const fetchProducts = (page , sortBy, pageSize) => {
  return fetch(`${BASE_URL}/product/approved/?ordering=${sortBy}&page=${page}`)
    .then(res => res.json());
};

export const useProductsQuery = (sortBy) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  console.log("Dddd",sortBy)
  const { data, error, isFetching } = useQuery(['products', page], async () => {
    const response = await fetchProducts(page, sortBy);
    return response;
  }, {
    staleTime: 0,
    onSuccess: (data) => {
      // setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));

      // if (page < totalPages) {
      //   prefetchQuery(['products', page + 1], () => fetchProducts(page + 1));
      // }
    }
  });

  const fetchProductDetailsById = (productId) => {
    return fetchProductDetails(productId);
  };

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
    fetchProductDetailsById
  };
};

// 검색

// 상품 검색 
export const handleSearch = async ({
    searchProduct,
  }) => {
  try {
    console.log("검색",searchProduct)
    // [hm] 수정 (customer 추가함) : 2023.09.14
    let url = `/product/customer/document/?search=${searchProduct}`;

    
    const response = await instance.get(url);

    return { data: response.data, url };
  } catch (error) {
    console.error(error);
  }
};

// q&a 상품 검색 

export const handleQASearch = async ({
    searchProduct,
    setSearchResults,
    setSearchUrl,
}) => {

  try {
      let url = `/product/customer/document/?search=${searchProduct}&page_size=5`;

      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data.data);
      console.log(response.data.data);
      return response.data.data;

  } catch (error) {
      console.error(error);
  }
};


const fetchTabData = async (sortBy, page , category , categoryId) => {
    let url;
    // console.log("categorycategory",category)
    if(category==="전체") {
        url = `/product/customer/document/?ordering=${sortBy}&page=${page}`;
    }else{
        if (categoryId) {
            // categoryId가 있을 경우
            url = `/product/customer/document/?category=${category}&category_id=${categoryId}&ordering=${sortBy}&page=${page}`;
        } else {
            // categoryId가 없을 경우 category를 사용
            url = `/product/customer/document/?category=${category}&ordering=${sortBy}&page=${page}`;
        }
    }

  
    // console.log(url);

    return instance.get(url)
    .then(res => res.data);
};


// 고객 ) 상품 리스트 조회 
export const useProductListContent = (activeTab , category , categoryId ) => {
  
  // 상품 갯수
  const [productCount, setProductCount] = useState(0);


  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(()=>{
    window.scrollTo(0, 0);
    if (data && data.nextPage) {
      const nextPage = page + 1;
      queryClient.prefetchQuery([activeTab , nextPage],
        () => fetchTabData(getSortOrder(activeTab), nextPage , category , categoryId)
      );
    }
  },[page , queryClient])

  useEffect(() => {
    setPage(1);
  }, [categoryId , activeTab]);  

  const { data, isLoading, refetch } = useQuery(
    [activeTab, category, page], () => fetchTabData(getSortOrder(activeTab), page , category , categoryId ),
    { 
      keepPreviousData:true
    },
    {
      onSuccess: (data) => {
        setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
    
        if (page < totalPages) {
          prefetchQuery([activeTab, page + 1], () => fetchTabData(getSortOrder(activeTab), page+1 , category , categoryId ));
        }
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [categoryId]);

  console.log(data);

  const getSortOrder = (tab) => {
    switch (tab) {
      case '신상품':
        return '-created_at';
      case '상품명':
        return 'name';
      case '낮은가격':
        return 'price';
      case '높은가격':
        return '-price';
      default:
        return '';
    }
  };

  return {
    data,
    isLoading,
    page,
    setPage,
    totalPages,
  };
};

//
export const statisticSearch = (data) =>
  instance.post(`/search/`, data).then(response => response.data);
