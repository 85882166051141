import { useState } from 'react';

function AccordionMenuTest()
{
    const [openIndex, setOpenIndex] = useState(-1);
    const menuItems = [
      { title: '회원관리', contents: ['Content 1-1', 'Content 1-2', 'Content 1-3'] },
      { title: 'Menu Item 2', contents: ['Content 2-1', 'Content 2-2'] },
      { title: 'Menu Item 3', contents: ['Content 3-1', 'Content 3-2', 'Content 3-3', 'Content 3-4'] }
    ];
  
    const handleClick = (index) => {
      if (openIndex === index) {
        setOpenIndex(-1);
      } else {
        setOpenIndex(index);
      }
    };
  
    return (
      <div>
        <div className='mainContents'>
            {menuItems.map((item, index) => (
            <div key={index}>
                <div onClick={() => handleClick(index)}>
                <h2>{item.title}</h2>
                {openIndex === index ? '-' : '+'}
                </div>
                {openIndex === index && (
                <ul>
                    {item.contents.map((content, subIndex) => (
                    <li key={subIndex}>{content}</li>
                    ))}
                </ul>
                )}
            </div>
            ))}
      </div>
      </div>
    );
}

export default AccordionMenuTest