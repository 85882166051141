/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 고객 > 마이페이지 > 주문배송조회 > OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.15
* Modifide Date : 2024.01.22 : CHM : 배송조회 모달 추가
*               : 2024.01.29 : CHM : 배송조회 모달 API 연동
* Reference     : 
***************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';

import DeliveryModal from '../Component/DeliveryModal.js';
import { OrderPurchaseConfirm } from '../../../api/services/mypageApi.js';

function OrderRow({orderItem , deliveryItem})
{

    const navigate = useNavigate();

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        const total = orderItem.orderitem_option_rows.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(total);
    }, [orderItem]);

    // 2024.01.22 : CHM : 배송조회 모달 추가
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    console.log("--orderItem")
    console.log(orderItem);

    // 구매확정
    const createMutaion = useMutation(OrderPurchaseConfirm , {
        onSuccess : (data) => {
            alert("구매확정이 완료 되었습니다");
        },
        onError : (data) => {
            console.log("error", data);
        }
    });


    // 구매확정 버튼 클릭시
    const handlePurchaseConfirmClick = (orderId) => {
        const userConfirmed = window.confirm('상품을 받으셨나요?\n구매확정하신 뒤에는 반품/교환 신청하실 수 없습니다.');

        if (userConfirmed) {
            const data = {
                order_item: orderId || "",
            };
            createMutaion.mutate(data);
        } 
    }

    return (
        <>
        <td className='lbe6'>
            <div className='myorder_piwrap cursor-pointer'
                 onClick={()=>window.location.href=`/product/detail/${orderItem.product}`}>
                <span className='thum' >
                    <img
                        src={orderItem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </span>
                <div className='pn'>
                    <a>
                        <span className='vendortxt'>{orderItem.vendor_name}</span>
                        <span className='pdtxt'>{orderItem.product_name}</span>
            
                        {/* 옵션 */}
                        {orderItem.orderitem_option_rows && orderItem.orderitem_option_rows.length > 0 && (
                        <>
                            <span className='option_list2'>
                                <i className='optiontxt'>옵션</i>
                                <div>
                                    {orderItem.orderitem_option_rows.map((option, index) => (
                                        <p key={index}>
                                            {option.name}
                                        </p>
                                    ))}
                                </div>
                            </span>
                        </>
                        )}

                        {/* 추가상품 */}
                        {orderItem.orderitem_extra_options && orderItem.orderitem_extra_options.length > 0 && (
                        <>
                            <span className='option_list2'> 
                                <i className='optiontxt'>추가상품</i>
                                <div>
                                {orderItem.orderitem_extra_options.map((extra_option, index) => (
                                    <p key={index}>
                                        {extra_option.name}
                                    </p>
                                ))}
                                 </div>
                            </span>
                        </>
                        )}


                    </a>
                </div>
           
            </div>

          </td>
        <td className='lbe6' >
            {totalQuantity}
        </td>
        <td className='lbe6' >
            {orderItem && orderItem.original_total_price ? 
                orderItem?.original_total_price.toLocaleString(): "0"}원
        </td>
        <td className='status_td lbe6'>
            <p>
            {
                orderItem?.cancels.status ? "취소요청" 
                :
                    orderItem?.confirmation_status === "취소" ||  orderItem?.confirmation_status === "반품"
                    ?
                        orderItem?.confirmation_status
                    :
                        orderItem?.status === "배송완료" 
                        ? orderItem?.confirmation_status
                        : orderItem?.status
            }
            </p>

            {orderItem?.is_confirmable &&  
                <button 
                    className='mpbtn1 mt-2'  
                    onClick={()=>{handlePurchaseConfirmClick(orderItem.id)}}>구매확정</button>
            }
          
            {orderItem?.is_trackable &&
                <button className='mpbtn1 mt-2' onClick={()=>setModalIsOpen(true)}>배송조회</button>
            }

            {orderItem?.is_direct_delivered  && 
                <button className='mpbtn1 mt-2'>직접전달</button>
            }
    
        </td>
        
        {/* 2024.01.22 : CHM : 배송조회 모달 추가 */}
        <DeliveryModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} id={orderItem?.id}/>

        
        </>
    )
}

export default OrderRow