import React, {useState, useEffect} from 'react';
// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import Editor from '../../../components/Editor';
import { getPrivacy, patchPrivacy } from '../../../../api/policyApi';

function PrivacyPolicy()
{
    const [content, setContent] = useState('');
    const [privacy, setPrivacy] = useState('');
    useEffect(() => {
        getPrivacy().then((res) => {
            setPrivacy(res?.data?.content);
            console.log(res?.data?.content);
        })
    }, []);
    function onSubmit(content){
        patchPrivacy(content)
        .then((res) => {
            alert('저장되었습니다.');
            window.location.reload();
        })
        .catch((error) => {
            alert(error?.response?.data?.data);
        })
    }
    return (
        <>
            <section className="McontentBox mt30">
                <p className="settingSubTitle">개인정보처리방침</p>

                <Editor
                    content={privacy}
                    setContent={setContent} 
                />
            </section>

            <div className="setting-btn-box">
                <button className="m-btn m-btn-search" onClick={()=> onSubmit(content)}>저장하기</button>
            </div>
        </>
    )
}

export default PrivacyPolicy