import React from "react";

const Accordion = ( {tab , title, content, active, setActive}) =>{
    return (
        <div className="accordion">
            <div className="accordionHeading">
                <div className="container" onClick={() => setActive(title)}>
                    <span className="cate">
                        <img src={process.env.PUBLIC_URL + '/img/searchfaq.svg'}/>
                        {tab}
                    </span>
                    <p>{title}</p>
                    {/* <span >
                        {active === title ? "X" : "lll"}
                    </span> */}
                </div>
            </div>

            <div className={(active === title ? "show" : "")+" accordionContent"}>
                <div className="container">
                    <p>
                        <img src={process.env.PUBLIC_URL + '/img/an.svg'}/>
                       {content}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Accordion;