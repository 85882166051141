import { instance } from '../../axiosInstance/axiosConfig';

const updateProductData = async (id, newData) => {
  try {
    await instance.put(`product/${id}/update/`, newData);
    alert('데이터 수정이 완료되었습니다.');
  } catch (error) {
    throw new Error('데이터 수정 중 오류가 발생했습니다.');
  }
};

// [hm] 관리자가 상품 판매상태 변경 : 2023.09.19
export const updateProductState = (pk, sale_status) => {
  return instance.patch(`/product/${pk}/update/`,
  {
    sale_status: sale_status
  })
}

// [hm] 관리자가 상품 정상가 변경 : 2023.09.19
export const updateProductOriginalPrice = (pk, original_price) => {
  return instance.patch(`/product/${pk}/update/`,
  {
    original_price: original_price
  })
}

// [hm] 관리자가 상품 판매가 변경 : 2023.09.19
export const updateProductPrice = (pk, price) => {
  return instance.patch(`/product/${pk}/update/`,
  {
    price: price
  })
}

// [hm] 관리자가 상품 재고수량 변경 : 2023.09.19
export const updateProductStock = (pk, stock) => {
  return instance.patch(`/product/${pk}/update/`,
  {
    stock: stock
  })
}


// [hm] 셀러가 상품 판매상태 변경 : 2023.09.19
export const updateSellerProductState = (pk, sale_status) => {
  return instance.patch(`/product/update/direct/${pk}/`,
  {
    sale_status: sale_status
  })
}

export default updateProductData;
