import { Container } from "react-bootstrap";
import { getCancellation } from "../../api/policyApi";
import { useState } from "react";

const Term = () => {
    const [terms, setTerms] = useState('');
    useState(() => {
        getCancellation().then((res) => {
            setTerms(res?.data?.content);
            console.log(res?.data?.content);
        })
    }, []);
    return(
        <>
            <Container className="p-3">
                <div dangerouslySetInnerHTML={{ __html: terms }} />
            </Container>
        </>
    );
}

export default Term;