import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideCategory from './SideCategory.js';
import SideCommunity from './SideCommunity.js';
import { logOut } from '../../api/useLogin.js';
import Cookies from 'js-cookie';
import { SlHome, SlCreditCard, SlBasketLoaded, SlHeart } from "react-icons/sl";


export function MobileSideBar({state, setState, userId, category}){
	const navigate = useNavigate();
	const [tab, setTab] = useState(0);
	return(
		<>
			<div id="" className={state ? 'layout_side aside-open' : 'layout_side'}>
				<div className='aside_userinformation'>
                    {userId === 'guest' ? <GuestHeader setState={setState} /> : userId !== '' && <UserHeader userId={userId}/>}
				</div>
				<div className='aside_main_menu'>
                    <ul>
						<li onClick={()=>{navigate('/'); setState(false)}}>
                            <SlHome className='icon'/>
							<a>메인</a>
						</li>
						<li onClick={()=>{navigate('/mypage/order'); setState(false)}}>
                            <SlCreditCard className='icon'/>
							<a>주문조회</a>
						</li>
						<li onClick={()=>{navigate('/member/cart'); setState(false)}}>
                            <SlBasketLoaded className='icon'/>
							<a>장바구니</a>
						</li>
                        <li onClick={()=>{navigate('/mypage/wish'); setState(false)}}>
                            <SlHeart className='icon'/>
							<a>찜목록</a>
						</li>
					</ul>
				</div>
				<div className='aside_navigation_wrap'>
					<ul className='tab'>
						<li className={ tab===0 ? "current":""} onClick={()=>{setTab(0)}}>
							<span>카테고리{tab==0}</span>
						</li>
						<li className={ tab===1 ? "current":""} onClick={()=>{setTab(1)}}>
							<span>고객센터</span>
						</li>
					</ul>
					<TabContent tab={tab} categoryData={category}/>
					
				</div>
			</div>
			<div className='side_close'>
				<i className='xi-close'></i>
			</div>
			<div className={state ? 'layout_side_background aside-open' : 'layout_side_background'} onClick={()=>{setState(false)}}></div>
		</>
	);
}

function UserHeader({userId}){
	const navigate = useNavigate();
    return(
        <ul>
            <li>
                <span className='userinfo'><b>{userId}</b>님 어서오세요 </span>
            </li>
            <li>
                <a className='btn_side' 
                    onClick={()=>{
                        logOut()
							.then(res => {
								console.log(res?.data);
								Cookies.remove('access');
								Cookies.remove('refresh');
								// 메인페이지로 새로고침
								navigate('/');
								window.location.reload();
							})
                    }}>
                    로그아웃
                </a>
            </li>
        </ul>
    )
}

function GuestHeader({setState}){
    const navigate = useNavigate();
    return(
        <ul>
            <li>
                <span className='userinfo'>로그인이 필요합니다.</span>
            </li>
            <li>
                <a className='btn_side' 
                    onClick={()=>{navigate('/member/login'); setState(false)}}>로그인</a>
                <a className='btn_side'
                onClick={()=>{navigate('/member/register'); setState(false)}}>회원가입</a>
            </li>
        </ul>
    )
}

function TabContent({tab, categoryData})
{
	return (<ul id="categorySideMenu" className='menu'>
			{[<SideCategory categoryData={categoryData}/>, <SideCommunity/>][tab]}
	</ul>)
}