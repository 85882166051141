
import axios from 'axios';
import {  queryClient , useMutation , useQuery , prefetchQuery , useQueryClient } from 'react-query';
import { BASE_URL } from '../apiConfig';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';
import { getAccessFromCookie } from '../cookieUtils';


// 리뷰관리 > 해당 상품 후기 등록
export const RegisterReview = (data) =>
  instance.post(`product/review/`, data).then(response => response.data);
 
// 나의 리뷰 상세 조회
export const fetchMyReviewDetails = (reviewId) => {
  return instance.get(`/product/review/${reviewId}`)
    .then(res => res.data)
};

// 해당 상품 Q&A 리스트 조회 
const fetchProductInquiryList = (id , page, pageSize) => {
    return fetch(`${BASE_URL}/inquiry/product/${id}/list/?page=${page}&page_size=${pageSize}`)
      .then(res => res.json());
};
  
export const useProductInquiryListQuery = (id) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [toggle, setToggle] = useState(false);
    const [pageSize, setPageSize] = useState(10); 
  
    const { data, error, isFetching } = useQuery(['productInquiry', page , pageSize], () => fetchProductInquiryList(id , page , pageSize), {
        onSuccess: (data) => {
        setTotalPages(Math.ceil(data.totalCount / pageSize));
        
        // if (page < totalPages) {
        //   prefetchQuery(['newStore', page + 1], () => fetchNewStore(page + 1));
        // }
        }
    });
  
    return {
        data,
        error,
        isFetching,
        page,
        setPage,
        totalPages,
        toggle,
        setToggle,
        pageSize,
        setPageSize
    };
  };

  // 해당 상품 Q&A 리스트 조회 
const fetchProductReviewList = (id , page, pageSize) => {
    return fetch(`${BASE_URL}/product/review/?product_id=${id}`)
      .then(res => res.json());
};

export const useProductReviewListQuery = (id) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [toggle, setToggle] = useState(false);
    const [pageSize, setPageSize] = useState(10); 
  
    const { data, error, isFetching } = useQuery(
      ['productReview', page , pageSize], () => fetchProductReviewList(id , page , pageSize), {
        onSuccess: (data) => {
        setTotalPages(Math.ceil(data.totalCount / pageSize));
        
        // if (page < totalPages) {
        //   prefetchQuery(['newStore', page + 1], () => fetchNewStore(page + 1));
        // }
        }
    });
  
    return {
        data,
        error,
        isFetching,
        page,
        setPage,
        totalPages,
        toggle,
        setToggle,
        pageSize,
        setPageSize
    };
  };


// [hm] 마이페이지 > 할인쿠폰
export const getMyCoupon = () => {
  return instance.get(`/coupon/my/`)
}

export const getMyAvailableMileage = () => {
  return instance.get(`/mileage/available/`)
}

// [hm] 마이페이지 > 마일리지 > 내 마일리지 사용 내역 목록
export const getMyMileage = () => {
  return instance.get(`/mileage/my/`)
}

// [hm] 마이페이지 > 마일리지 > 적립 및 소멸예정
export const getMyMileagePlan = () => {
  return instance.get(`/mileage/plan/`)
}

// [hm] 마이페이지 > 리뷰
export const getMyReview = () => {
  return instance.get(`/product/myreview/`)
}

export const getMyReviewable = () => {
  return instance.get(`/product/reviewable/`)
}

// [hm] 마이페이지 > 배송지 목록
export const getMyAddress = () => {
  return instance.get(`/auth/address/`)
}

// [hm] 마이페이지 > 배송지 추가
export const createMyAddress = (data) => {
  return instance.post(`/auth/address/`, {
    user: data?.user,
    name: data?.name,
    recipient: data?.recipient,
    phone_number: data?.phone_number,
    address: {
      address: data?.address,
      detail: data?.detail,
      postal_code: data?.postal_code,
      address_type: data?.type
    }
  })
}

// [hm] 마이페이지 > 기본배송지 변경
export const changeDefaultAddress = (id, data) => {
  return instance.patch(`/auth/address/${id}/`, data)
}

// [hm] 마이페이지 > 배송지 수정
export const updateMyAddress = (id, data) => {
  return instance.patch(`/auth/address/${id}/`, data)
}

//[hm] 마이페이지 > 배송지 삭제
export const deleteMyAddress = (id) => {
  return instance.delete(`/auth/address/${id}`)
}

//[pkh]
// 마이페이지 > 주문배송조회 > 리스트 조회
const fetchMyOrderList = (page) => {
    const url = `order/my/?page=${page}`;
    return instance.get(url).then(res => res.data);
};

export const useMyOrderQuery = () => {
    const [page, setPage] = useState(1);
    const queryClient = useQueryClient();

    useEffect(()=>{
        window.scrollTo(0, 0);
        const nextPage = page + 1;
        queryClient.prefetchQuery([`myorder`, nextPage],
          () => fetchMyOrderList(nextPage)
        );
    },[page , queryClient])

    const { data, error, isFetching } = useQuery(
        [`myorder`, page ], () => fetchMyOrderList(page),
        {
          staleTime:5000,
          keepPreviousData:true
        },
    );

    return {
        data,
        error,
        isFetching,
        page,
        setPage
    };
};

// 마이페이지 > 주문배송조회 > 주문상세 
export const fetchMyOrderDetail = (Id) => {
  return instance.get(`order/my/${Id}`)
    .then(res => res.data);
};

// 마이페이지 > 주문배송조회 > 내 주문 아이템 정보 상세 조회 
export const fetchMyOrderItemDetail = (Id) => {
  return instance.get(`order/item/my/${Id}`)
    .then(res => res.data);
};

// 마이페이지 > 주문배송조회 > 나의 딜리버리 아이템 내역 상세 조회 
export const fetchMyOrderDeliveryitemDetail = (Id) => {
  return instance.get(`order/deliveryitem/my/${Id}`)
    .then(res => res.data);
};

// 마이페이지 > 주문배송조회 > 주문상태 조회(모달)
export const getMyOrderDeliveryTracking = (id) => {
  return instance.get(`/order/delivery/tracking/${id}`)
}


// 고객 > 마이페이지 > 구매확정
export const OrderPurchaseConfirm = (data) =>
  instance.post(`order/purchase/confirm/`, data).then(response => response.data);


// 마이페이지 > 반품/취소 > 취소 리스트 조회
const fetchMyOrderCancelList = (page) => {
  return instance.get(`order/abort/my/?status=취소&page=${page}`)
  .then(res => res.data);
};

export const useMyOrderCancelListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();


  useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`myorderCancel${nextPage}`, nextPage],
        () => fetchMyOrderCancelList(nextPage)
      );
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myorderCancel${page}`, page ], () => fetchMyOrderCancelList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 반품/취소내역 > 반품 리스트 
const fetchMyOrderReturnList = (page) => {
  return instance.get(`order/abort/my/?status=반품&page=${page}`)
  .then(res => res.data);
};

export const useMyOrderReturnListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();


  useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`myorderReturnList${nextPage}`, nextPage],
        () => fetchMyOrderReturnList(nextPage)
      );
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myorderReturnList${page}`, page ], () => fetchMyOrderReturnList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 반품교환취소 > 주문상세 
export const fetchMyOrderAbortDetail = (Id) => {
  return instance.get(`order/abort/my/${Id}`)
    .then(res => res.data);
};



// 마이페이지 > 쿠폰 > 리스트 조회
const fetchMyCouponList = (page, isUsed) => {
  let url = `coupon/my/?page=${page}`;
  isUsed && (url = `coupon/my/?is_used=true&page=${page}`);
  return instance.get(url).then(res => res.data);
};

export const useMyCouponListQuery = (isUsed) => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();


  // useEffect(()=>{
  //     window.scrollTo(0, 0);
  //     const nextPage = page + 1;
  //     queryClient.prefetchQuery([`myorderCancel${selectTab}`, nextPage],
  //       () => fetchMyOrderList(nextPage)
  //     );
  // },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myCoupon`, page ], () => fetchMyCouponList(page, isUsed),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 마일리지 > 마일리지 사용 및 적립 조회
const fetchMyMileageList = (page) => {
  const url = `mileage/myhistory/?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyMileageListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  // useEffect(()=>{
  //     window.scrollTo(0, 0);
  //     const nextPage = page + 1;
  //     queryClient.prefetchQuery([`myorderCancel${selectTab}`, nextPage],
  //       () => fetchMyMileageList(nextPage , selectTab)
  //     );
  // },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myMileage${page}`, page ], () => fetchMyMileageList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 리뷰관리 > 
const fetchMyReviewList = (page) => {
  const url = `product/myreview/?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyReviewListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  // useEffect(()=>{
  //     window.scrollTo(0, 0);
  //     const nextPage = page + 1;
  //     queryClient.prefetchQuery([`myorderCancel${selectTab}`, nextPage],
  //       () => fetchMyMileageList(nextPage , selectTab)
  //     );
  // },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myReview${page}`, page ], () => fetchMyReviewList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 리뷰관리 > 리뷰 가능한 상품
const fetchMyReviewableList = (page) => {
  const url = `product/reviewable/?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyReviewableListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  // useEffect(()=>{
  //     window.scrollTo(0, 0);
  //     const nextPage = page + 1;
  //     queryClient.prefetchQuery([`myorderCancel${selectTab}`, nextPage],
  //       () => fetchMyMileageList(nextPage , selectTab)
  //     );
  // },[page , queryClient])



  const { data, error, isFetching } = useQuery(
      [`myReviewable${page}`, page ], () => fetchMyReviewableList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );
  
  useEffect(() => {
    if (data?.totalCnt) {
        queryClient.invalidateQueries(`myReviewable${page}`);
    }
  }, [data?.totalCnt, queryClient]);

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};


// 마이페이지 > 문의내역조회 > 상품문의
const fetchMyInquiryProductList = (page) => {
  const url = `inquiry/product/my/?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyInquiryProductListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`myInquiryProduct${page}`, nextPage],
        () => fetchMyInquiryProductList(nextPage)
      );
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myInquiryProduct${page}`, page ], () => fetchMyInquiryProductList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 문의내역조회 > 1:1 문의
const fetchMyInquiryOnetooneList = (page) => {
  const url = `inquiry/onetoone/my?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyInquiryOnetooneListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`myInquiryOnetoone${page}`, nextPage],
        () => fetchMyInquiryOnetooneList(nextPage)
      );
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myInquiryOnetoone${page}`, page ], () => fetchMyInquiryOnetooneList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 마이페이지 > 찜목록 > 상품
const fetchMyLikeProductList = (page) => {
  const url = `like/product/?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyLikeProductListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  useEffect(()=>{
      window.scrollTo(0, 0);
      if (data && data.nextPage) {
        const nextPage = page + 1;
        queryClient.prefetchQuery([`myLikeProduct${page}`, nextPage],
          () => fetchMyLikeProductList(nextPage)
        );
      }
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myLikeProduct${page}`, page ], () => fetchMyLikeProductList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};


// 마이페이지 > 찜목록 > 상회
const fetchMyLikeVendorList = (page) => {
  const url = `like/vendor/?page=${page}`;
  return instance.get(url).then(res => res.data);
};

export const useMyLikeVendorListQuery = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  useEffect(()=>{
      window.scrollTo(0, 0);
      if (data && data.nextPage) {
        const nextPage = page + 1;
        queryClient.prefetchQuery([`myLikeVendor${page}`, nextPage],
          () => fetchMyLikeProductList(nextPage)
        );
      }
  },[page , queryClient])

  const { data, error, isFetching } = useQuery(
      [`myLikeVendor${page}`, page ], () => fetchMyLikeVendorList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
  );

  return {
      data,
      error,
      isFetching,
      page,
      setPage
  };
};

// 고객 > 마이페이지 > 주문취소
export const OrderCancel = (data) =>
  instance.post(`order/cancel/`, data).then(response => response.data);

// 고객 > 마이페이지 > 반품신청
export const OrderReturn = (data) =>
instance.post(`returns/reception/`, data).then(response => response.data);

// 고객 > 마이페이지 > 부분취소 (상태가 결제완료일때 )
export const OrderDeliveryitemCancel = (data) =>
  instance.post(`order/deliveryitem/cancel/`, data).then(response => response.data);

// 고객 > 마이페이지 > 부분취소 (상태가 상품준비중일땐 )
export const OrderDeliveryitemCancel2 = (data) =>
instance.post(`cancel/reception/`, data).then(response => response.data);

// 마이페이지 > 비밀번호 변경
export const updatePassword = (data) => instance.post('/auth/change-password/', data);
