import { useState , useRef ,useEffect } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap';
import { getMe, getVendorMe, getVendorSeller } from './SellerStoreApi';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { logOut } from '../../../api/useLogin';

function Waiting(){
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [step, setStep] = useState('2');
    useEffect(()=>{
        getMe()
            .then(res => {
                console.log(res.data);
                setUserName(res?.data?.username);
                // res?.data?.vendor && setStep('3');
                if(res.data.vendor){
                    getVendorSeller() // 입점신청 이력이 있는지 확인
                        .then(res => res.data.status === "거절" ? navigate("../regist") : setStep('3'))
                        .catch(error => console.log(error.response.data))
                }else{
                    setStep('2');
                }
            })
            .catch(error => console.log(error.response.data.data));
        
        getVendorMe()
            .then(res => console.log(res.data))
            .catch(error => console.log(error.response.data.data))
    },[])

    const logout = () => {
		logOut()
			.then(res => {
				console.log(res?.data);
				Cookies.remove('access');
				Cookies.remove('refresh');
				// 메인페이지로 새로고침
				navigate('/');
				window.location.reload();
			})
	}

    return(
        <>
            <Container className="my-3">
                <Row className="mt-3 mb-5">
                    <Col xs={10}></Col>
                    <Col xs={2} className="text-end"><span className="text-danger pointer" onClick={()=>logout()}>로그아웃</span></Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} className="text-start">
                                <h5 className="text-grey fw-bolder"><span className="fs-4 fw-bold">1</span> 회원가입</h5>
                                <p className="my-2 ps-3 fs-7">목포해와 판매자 등록을 위한 회원가입 <br/>절차를 완료했습니다.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col xs={12} className="text-start">
                                <h5 className={step === '2' ? "text-hw fw-bolder" : "text-grey fw-bolder"}><span className="fs-4 fw-bold">2</span> 입점신청</h5>
                                <p className="my-2 ps-3 fs-7">상품 판매를 위한 매장의 상세 정보를 <br/>등록합니다.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col xs={12} className="text-start">
                                <h5 className={step === '3' ? "text-hw fw-bolder" : "text-grey fw-bolder"}><span className="fs-4 fw-bold">3</span> 승인대기</h5>
                                <p className="my-2 ps-3 fs-7">목포해와의 관리자가 정보 확인 후 <br/>승인 결과를 이메일, 연락처로 통보합니다.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col xs={12} className="text-start">
                                <h5 className="text-grey fw-bolder"><span className="fs-4 fw-bold">4</span> 승인완료</h5>
                                <p className="my-2 ps-3 fs-7">판매자 등록이 완료되어 상품 등록 후 <br/>즉시 판매 가능합니다.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-5 d-flex justify-content-center align-items-center">
                    <Col xs="2"></Col>
                    <Col xs="8" className="text-center">
                        {step === '2' ? <Step2 userName={userName}/> : <Step3 userName={userName}/>}
                    </Col>
                    <Col xs="2"></Col>
                </Row>
                <Row className="fs-5 text-grey text-center">
                    <p className="fs-7 mt-1">(주)목포해와</p>
                    <p className="fs-5 mt-1 fw-bold">070-4249-0302</p>
                    <p className="fs-7 mt-1">오전 09:00 ~ 오후 06:00</p>
                    <p className="fs-7 mt-1">(토, 일, 공휴일 휴무)</p>
                </Row>
            </Container>
        </>
    )
}

function Step2({userName}){
    const navigate = useNavigate();
    return(
        <>
            <div className='border rounded-2 p-4'>
                <h2>{userName}님은 현재 <span className="fw-bold text-hw">2.입점신청</span> 단계입니다.</h2>
                <p className="my-4 fs-6 text-grey">
                    입점 신청 페이지로 이동하여
                    <br/>매장 정보를 등록해주세요.
                </p>
                <button className='m-btn m-btn-search' onClick={() => navigate('../regist')}>입점신청</button>
            </div>
        </>
    )
}


function Step3({userName}){
    const navigate = useNavigate();
    return(
        <>
            <div className='border rounded-2 p-4'>
                <h2>{userName}님은 현재 <span className="fw-bold text-hw">3.승인대기</span> 단계입니다.</h2>
                <p className="my-4 fs-6 text-grey">
                    관리자 승인을 기다리고있습니다.
                    <br/>평균 2일 이내 이메일, 카카오 알림톡으로 답변 받으실 수 있습니다.
                </p>
            </div>
        </>
    )
}


export default Waiting