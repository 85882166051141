/***************************************************************************************
* FileName      : OrderBox.js
* Description   : 고객 > 장바구니 > 장바구니 상품박스 컴포넌트(판매자별)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container, InputGroup, Form} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useCartItemDelete , fetchCartDetails , useCartItemExtraDelete , useCartItemRowDelete } from '../../../api/services/cartApi.js'
import ModifiedModal  from './ModifiedModal.js'

import { initializeSelectedItems } from './initializeSelectedItems'; 


// selectedItems : 사용자가 선택한 상품(화면에 보여주기 위한 정보 , 체크박스 기능)
// ->  cart_item_id , delivery_fee , total_price (체크 했을 때 배송비 , 상품 금액 등 계산을 위한 )

// selectedOrders : "주문하기" 할 때 보내야될 id들

// cartDeliveryItems :사용자가 (체크박스) 선택한 상품(상세 모든 정보 ,체크박스 기능)

export function OrderBox({ 
    selectedItems, setSelectedItems , selectedOrders , setSelectedOrders ,
    cartDeliveryItems , setCartDeliveryItems, selectAll}) 
{

    const { cartItemDelete , isDeleteLoading } = useCartItemDelete();
 
    const { cartItemExtraDelete } = useCartItemExtraDelete();
    const { cartItemRowDelete  } = useCartItemRowDelete();

    
    // 추가 옵션 수정 모달창
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCartItem, setSelectedCartItem] = useState([]);

 
    const [sizeCheckboxes, setSizeCheckboxes] = useState(Array(cartDeliveryItems.length).fill(0));
    
    const openModal = () => {
        setIsOpen(true);
    };
    
    useEffect(()=>{
        // cartDeliveryItems 데이터 이용하여 초기화
        initializeSelectedItems(cartDeliveryItems, setSelectedItems, setSizeCheckboxes, setSelectedOrders);
    },[cartDeliveryItems])



    // 전체해제, 전체선택
    useEffect(()=>{
        if(!selectAll){
            initializeSelectedItems(cartDeliveryItems, setSelectedItems, setSizeCheckboxes, setSelectedOrders);
        }else{
            setSelectedItems([]);
            setSelectedOrders([]);
        }
    },[selectAll])



    console.log(cartDeliveryItems);
    console.log("정보")
    const handleRemoveOption = async (id, type) => {
        console.log(type)
        try {
            if (type === 'option_rows') {
                await cartItemRowDelete(id);
            } else if (type === 'extra_options') {
                await cartItemExtraDelete(id);
            }
    
            fetchCartDetails()
            .then(details => {
              setCartDeliveryItems(details.cart.cart_delivery_items);
            })
            .catch(error => {
              console.error('상품 상세 조회 오류:', error);
          });
        } catch (error) {
    
        }
    };

    
    // 주어진 cartIndex와 일치하는 객체들의 price 값을 합산하여 총 가격을 계산하는 함수
    const calculateTotalPrice = (cartIndex) => {
        if (selectedItems[cartIndex]) {
          const totalPriceForIndex = selectedItems[cartIndex].reduce(
            (acc, currentItem) => acc + currentItem.total_price,
            0
          );
          return totalPriceForIndex;
        }
        return 0; 
    };
      
    // 주어진 cartIndex와 일치하는 객체들의 delivery_fee 데이터 중 최대값 배송비 계산하는 함수
    const calculateMaxDelivery = (cartIndex) => {
        const cartItems = selectedItems[cartIndex];
    
        if (Array.isArray(cartItems) && cartItems.length > 0) {
            const maxDeliveryFee = cartItems.reduce((max, item) => {
                const deliveryFee = parseFloat(item.delivery_fee);
                return !isNaN(deliveryFee) ? Math.max(max, deliveryFee) : max;
            }, 0);
    
            return maxDeliveryFee;
        } else {
            return 0;
        }
    };


    // 각 상품마다 체크박스
    const handleCheckboxChange = (item , cartItem, cartIndex) => {
        const cartItemId = cartItem.cart_item_id;
      
        if (selectedItems[cartIndex].some((item) => item.cart_item_id === cartItemId)) {
          // 이미 선택된 항목이면 선택 해제
          const updatedSelectAll = [...selectedItems];
          updatedSelectAll[cartIndex] = updatedSelectAll[cartIndex].filter(
            (item) => item.cart_item_id !== cartItemId
          );
          setSelectedItems(updatedSelectAll);
      
          // 선택 해제된 항목을 selectedOrders에서 제거
          setSelectedOrders(selectedOrders.filter((order) => order !== cartItemId));
      
        } else {
          // 선택되지 않은 항목이면
          const updatedSelectAll = [...selectedItems];
          updatedSelectAll[cartIndex] = [
            ...updatedSelectAll[cartIndex],
            {
              cart_item_id: cartItem.cart_item_id,
              total_price: cartItem.total_price,
              delivery_fee: cartItem.delivery_fee && cartItem.delivery_fee,
            },
          ];
          setSelectedItems(updatedSelectAll);
      
          const newSelectedOrders = [...selectedOrders, cartItemId];
          setSelectedOrders(newSelectedOrders);
      
        }
    
    };
      
    // 판매자별(vendor) 전체선택
    const handleSelectAllChange = (items, index) => {

        if (sizeCheckboxes[index] === selectedItems[index].length) {
            // sizeCheckboxes와 selectedItems의 길이가 같다면 selectedItems을 비웁니다.
            setSelectedItems((prevSelectAll) => {
                const updatedSelectAll = [...prevSelectAll];
                updatedSelectAll[index] = [];
                return updatedSelectAll;
            });
        
            const itemsCartIds = items.map((item) => item.cart_item_id);
                setSelectedOrders((prevSelectedOrders) =>
                prevSelectedOrders.filter((order) => !itemsCartIds.includes(order))
            );
        } else {
          // sizeCheckboxes와 selectedItems의 길이가 다르다면
          // items의 cart_item_id와 total_price를 selectedItems에 추가합니다.
          setSelectedItems((prevSelectAll) => {
            const updatedSelectAll = [...prevSelectAll];
            updatedSelectAll[index] = items.map((cartItem) => ({
              cart_item_id: cartItem.cart_item_id,
              total_price: cartItem.total_price,
              delivery_fee:  cartItem.delivery_fee && cartItem.delivery_fee ,
            }));
            return updatedSelectAll;
            });


            const newSelectedOrders = items.map((item) => item.cart_item_id).flat();
            setSelectedOrders((prevSelectedOrders) => [...prevSelectedOrders, ...newSelectedOrders]);
            
        }
    };
      
    
    // 추가 옵션 수정 모달

    const handleModifedExtraOption = (cartItem) =>{
        setSelectedCartItem(cartItem); 
        openModal();
    }

    const handleDeleteItem = (itemId) => {
        const data = {
            ids: [itemId],
        };

        cartItemDelete(data)
        .then(() => {
            fetchCartDetails()
            .then(details => {
              setCartDeliveryItems(details.cart.cart_delivery_items);
            })
            .catch(error => {
              console.error('상품 상세 조회 오류:', error);
          });
        })
        .catch(error => {
            console.log("삭제 실패:", error.message);
        });
    
    };

    return (     
        <div className='my-2'>
            {cartDeliveryItems.map((item, index) => (
            <div className='m-0 p-4 mb-4 rounded-2 bg-white shadow-box' key={index}>
                <div className='align-items-center cart-vendor'>
                    <Form.Check 
                        aria-label="option 1" 
                        className='m-0 me-2' 
                        inline 
                        checked={selectedItems[index] && sizeCheckboxes[index] === selectedItems[index].length}
                     
                        onChange={()=>handleSelectAllChange(item.cart_items, index)}
                        />
                    <Form.Label className='m-0 p-2 fw-800 vendor_name'>
                        {item.vendor_name}
                    </Form.Label>
                </div>
               

                {item.cart_items.map((cartItem, cartIndex) => (
                    <div key={cartIndex}>
                        {/* 주문박스 메인 블록 */}
                        <Row className='d-flex align-items-center'>
                            <Col xs={1} md={1} className='text-center'>
                                <Form.Check 
                                    aria-label="option 1" 
                                    checked={selectedItems[index] && 
                                            selectedItems[index].some(item => item.cart_item_id === cartItem.cart_item_id)}
                                    onChange={() => handleCheckboxChange(item,cartItem,index)}
                                    />    
                              
                            </Col>

                            <Col xs={4} md={1} onClick={()=>window.location.href=`/product/detail/${cartItem.cart_item_id}`}>
                                <img src={cartItem.main_image} width="100%" alt="Product" />
                              
                            </Col>
                        
                            <Col xs={7} md={2}>
                                <p className='fs-7 fw-bold'>{cartItem.name}</p>
                                <p className='fs-7 text-grey fw-bolder'>배송비 : {cartItem.delivery_fee.toLocaleString()}원</p>
                                <p className='fs-7 text-grey fw-bolder'>판매자 : {item.vendor_name}</p>
                            </Col>

                            <Col xs={12} md={4}>
                                {/* 상품 옵션 */}
                                {cartItem.option_rows.length > 0 && cartItem.option_rows.map((option, optionIndex) => (
                                    <OptionArea 
                                        prdOption={option}
                                        optionIndex={optionIndex}
                                        totalOptions={cartItem.option_rows.length} 
                                        handleRemoveOption={handleRemoveOption}
                                        />
                                ))}

                                {/* 추가옵션 */}
                                {cartItem.extra_options.map((option, optionIndex) => (
                                    <ExtraOptionArea 
                                        option={option} 
                                        optionIndex={optionIndex}
                                        handleRemoveOption={handleRemoveOption}
                                        />
                                ))}

                                <button className='btn_modify'
                                        onClick={()=>handleModifedExtraOption(cartItem)}>
                                    주문 수정
                                </button>
                            </Col>

                            {/* 추가옵션 수정 모달창 */}
                            {isOpen && (
                                 <ModifiedModal 
                                    setIsOpen={setIsOpen} 
                                    selectedCartItem={selectedCartItem} 
                                    setSelectedCartItem={setSelectedCartItem}
                                    cartDeliveryItems={cartDeliveryItems}
                                    setCartDeliveryItems={setCartDeliveryItems}
                                    maxPurchas={cartItem.max_purchase_per_person}
                                    />
                                
                                    //renderExtraOptionModal(selectedCartItem)
                            )}

              
                            <Col xs={9} md={3}  className='text-center'>
                                <p className='fs-7 fw-bold'>상품금액</p>
                                <p className='fs-6 fw-bold'>
                                    <span className='fs-5 fw-800'>
                                        {cartItem.total_price.toLocaleString()}
                                    </span>
                                원</p>
                            </Col>
                            
                            
                            <Col xs={3} md={1}  className='text-center'>
                                <i className='xi-close' 
                                    onClick={() => handleDeleteItem(cartItem.cart_item_id)}
                                ></i>        
                            </Col>
                        </Row>
                  
                        <hr />

                    </div>        
                ))}
        
                {/* 주문박스 하단 주문금액 영역 */}
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col xs={8} sm={6} className='text-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='text center'>
                                <p className='fs-7 fw-bolder'>상품금액</p>
                                <p className='fs-6 fw-bold'>{calculateTotalPrice(index).toLocaleString()}원</p>
                            </div>
                            <div className='text center px-4 py-3 fs-6 fw-800 text-grey'>+</div>
                            <div className='text center'>
                                <p className='fs-7 fw-bolder'>총 배송비</p>
                                <p className='fs-6 fw-bold'>{calculateMaxDelivery(index).toLocaleString()}원</p>
                            </div>
                            {/* <div className='text center px-4 py-3 fs-6 fw-800 text-grey'>-</div>
                            <div className='text center'>
                                <p className='fs-7 fw-bolder'>할인금액</p>
                                <p className='fs-6 fw-bold text-danger'>3,000원</p>
                            </div> */}
                        </div>
                    </Col>
                    <Col xs={1} sm={1} className='text-center fs-3 text-grey'>|</Col>
                    <Col xs={3} sm={5} className='text-center'>
                            <p className='fs-7 fw-bolder'>주문금액  
                            <p className='fw-800 text-hw ps-2 carttotalp'>
                               {(calculateTotalPrice(index)+calculateMaxDelivery(index)).toLocaleString()}원
                            </p>
                        </p>
                    </Col>
                </Row>
            </div>
        ))}
    </div>
    );
}

function OptionArea({ prdOption, optionIndex , totalOptions , handleRemoveOption})
{
    // 상품옵션 정보
    return(
        <>
        <Row key={optionIndex} className='d-flex align-items-center'>
            <Col className='extraOptionbox'>
                <p className='fs-7 fw-bolder'>
                    옵션 : {prdOption.name} ( {parseInt(prdOption.price).toLocaleString()}원 ) 
                    / {prdOption.quantity}개
                </p>
                {totalOptions >=2 ?
                    <span className='prd-remove' onClick={() =>  handleRemoveOption(prdOption.id, 'option_rows')}>x</span>
                    : null
                }
            </Col>
        </Row>
        </>
    )
}
function ExtraOptionArea({ option, optionIndex , handleRemoveOption })
{
    // 추가옵션 영역
    return(
        <>
        <Row key={optionIndex} className='d-flex align-items-center'>
            <Col className='extraOptionbox'>
                <p className='fs-7 fw-bolder'>
                    추가상품 : {option.name} ( + {parseInt(option.price).toLocaleString()}원 ) 
                    / {option.quantity}개
                </p>
                
                <span className='prd-remove' onClick={() =>  handleRemoveOption(option.id, 'extra_options')}>x</span>
            </Col>
        </Row>
        </>
    )
}