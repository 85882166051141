/***************************************************************************************
* FileName      : NoticeBoard.js
* Description   : 해와관리자 > 게시판 > 공지사항 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import React, { useState , useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNoticeListQuery , handleNoticeSearch , updateNoticeStatusData } from '../../../api/hwadmin/boardApi'
import { NoticeRow } from "./NoticeBoard/NoticeRow";
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'

import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';

function NoticeBoard()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useNoticeListQuery(ordering,pageSize);

    const stateValue = true;


    // 공개 비공개 수정
    const updateStateMutation = useMutation((data) => updateNoticeStatusData(data.id, data.state));

    const [searchAuth, setSearchAuth] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    
    const [selectedRows, setSelectedRows] = useState({});
    
    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');


    // 분류
    const [selectedCategory, setSelectedCategory] = useState('');

    // 공개여부
    const [visibility, setVisibility] = useState('');

    // 함수명 변경
    const handleVisibilityChange = (event) => {
        setVisibility(event.target.value);
    };


    // 분류 값 변화
    const handleCategoryTypeRadioChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const totalPages = 2;

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


     // 검색버튼 클릭 시 
     const onSearch = () => {
        handleNoticeSearch({
            searchAuth, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedCategory, 
            visibility,
            ordering,

        });

        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/board/notice/'; // 페이지 리로드
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
    
    const handleButtonClick = () => {
        window.location.href = '/admin/board/createNotice';
    };


    console.log(data)


    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>공지사항</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="title">제목</option>
                                <option value="name">작성자</option>
                            </select>
                            <input type="text"
                                    value={searchAuth}
                                    onChange={(e) => setSearchAuth(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div>
                            <b>날짜</b>
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="created">등록일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>
                    </div>

                    <div className='search-field division' style={{ margin: '20px 0px' }}>
                        <div> 
                            <b>분류</b> 
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='g1' name='group1' value='' checked={selectedCategory === ''} onChange={handleCategoryTypeRadioChange} />
                            <label htmlFor='g1'>전체</label>
                            <input type='radio' id='g2' name='group1' value='공지' checked={selectedCategory === '공지'} onChange={handleCategoryTypeRadioChange} />
                            <label htmlFor='g2'>공지</label>
                            <input type='radio' id='g3' name='group1' value='이벤트' checked={selectedCategory === '이벤트'} onChange={handleCategoryTypeRadioChange} />
                            <label htmlFor='g3'>이벤트</label>
                            <input type='radio' id='g4' name='group1' value='긴급공지' checked={selectedCategory === '긴급공지'} onChange={handleCategoryTypeRadioChange} />
                            <label htmlFor='g4'>긴급공지</label>
                        </div>   
                    </div>

                    <div className='search-field division'>
                        <div>
                            <b>공개여부</b>
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='v1' name='group2' value='' checked={visibility === ''} onChange={handleVisibilityChange} />
                            <label htmlFor='v1'>전체</label>
                            <input type='radio' id='v2' name='group2' value='true' checked={visibility === 'true'} onChange={handleVisibilityChange} />
                            <label htmlFor='v2'>공개</label>
                            <input type='radio' id='v3' name='group2' value='false' checked={visibility === 'false'} onChange={handleVisibilityChange} />
                            <label htmlFor='v3'>비공개</label>
                        </div>
                    </div>
                </div>

                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="신규등록순"
                            oldLabel="오래된등록순"
                            />
    
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                    }
                </div>

                <div className='m-btn-wrap-v2'>
                    <div>
                        <button className='m-sm-btn blue' onClick={handleButtonClick}>글쓰기</button>
                    </div>
                    <div>
                        {/*
                        <button className='m-sm-btn' onClick={handleTest}>이메일 발송</button>
                        <button className='m-sm-btn'>알림톡 발송</button>
                        <button className='m-sm-btn'>푸시 발송</button>
                        */}
                    </div>

                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>번호</th>
                                <th>분류</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>등록일시</th>
                                <th>조회수</th>
                                <th>공개여부</th>
                                <th>수정</th>
                                <th>삭제</th>
                            </tr>
                            </thead>

                            <tbody>
                                {searchResults.count > 0 ? (
                                    // 검색 결과를 렌더링하는 코드
                                    searchResults.data.map((notice, index) => (
                                        <NoticeRow
                                            notice={notice}
                                            index={index}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                        />
                                    ))
                                ) : data && data.data && data.data.length > 0 ? (
                                    // 기본 데이터를 렌더링하는 코드
                                    data.data.map((notice, index) => (
                                        <NoticeRow
                                            notice={notice}
                                            index={index}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="23">검색 결과가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>

                {/* 페이지내비게이션 */}
                <section>
                    {searchConfirm ? (
                        <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                        
                    ) : (
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    )}
                </section>
            </div>

        </>
    )
}

export default NoticeBoard