import { useQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';
const PAGE_SIZE = 10;


// 매장등록
export function useCreatePost() {
  const post = async (data) => {
    try {
      const response = await instance.post(`vendor/`, data );
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      throw new Error("Failed to post");
    }
  };

  return {
    post,
  };
}


// 매장 조회 
export const vendorgetData = async () => {
  const response = await instance.get(`vendor/`);
  return response.data;
}

// 셀러관리 > 매장조회 (승인된 매장)
const fetchVendorDate = (page, pageSize, ordering) => {
  return instance.get(`vendor/approved/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useVendorQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['vendor', page, pageSize, ordering], () => fetchVendorDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리 > 매장관리 >  매장이름 검색 조회 
export const fetchVendorName = (name) => {
  return instance.get(`vendor/document/?name=${name}`)
    .then(res => res.data);
};


// // 매장 신규 요청 
// export const newstoreData = async () => {
//   const response = await instance.get(`/vendor/requested/`);
//   return response.data;
// }

// 관리자 ID 확인
export function sendAdminNameToApi(adminName, adminId) {
  return instance
    .post(`auth/validate/`, {
      username: adminId,
      name: adminName,
    })
    .then((response) => {
      alert("확인완료");
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

// 상품 조회 
// export const productData = async () => {
//   const response = await axios.get(`${BASE_URL}/product/`);
//   return response.data;
// }

// 소분류 카테고리 조회
export const useProductData = () => {
  return useQuery(['productData'], async () => {
    const response = await instance.get(`product/`);
    return response.data;
  });
};

// 셀러 관리 > 신규 요청 조회
const fetchNewStore = (page, pageSize,ordering) => {
  return instance.get(`vendor/requested/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
}

export const useNewRequestStoreQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['newStore', page , pageSize,ordering], () => fetchNewStore(page , pageSize , ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};


// 매장 관리 > 신규 요청 > 검색 
export const handleSearch = async ({
  searchVendor, 
  setSearchResults, 
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  selectedRequestType,
  setSearchUrl,
  }) => {

  try {
    let url = `vendor/requested/`;

    if (searchVendor) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchVendor}`;
          break;
        case 'name':
          url += `?name__contains=${searchVendor}`;
          break;
        case 'owner':
          url += `?owner__contains=${searchVendor}`;
          break;
        case 'businessNumber':
          url += `?business_number__contains=${searchVendor}`;
          break;
        case 'address':
          url += `?address=${searchVendor}`;
          break;
          
        default:
          url += `?search=${searchVendor}`;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'apply':
          url += `&register_date__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }

    if(selectedRequestType){
      url += `&request_status=${selectedRequestType}`
    }

    const response = await instance.get(url);
    console.log(url)
    setSearchUrl(url)
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 매장 관리 > 매장조회 > 검색 
export const handleVendorSearch = async ({
  searchVendor, 
  setSearchResults,
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  selectedBusinessType,
  publicity,
  stopStatus,
  setSearchUrl,
  
  }) => {
  try {
    let url = `vendor/approved/`;

    if (searchVendor) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchVendor}`;
          break;
        case 'name':
          url += `?name__contains=${searchVendor}`;
          break;
        case 'owner':
          url += `?owner__contains=${searchVendor}`;
          break;
        case 'phone':
          url += `?phone_number__contains=${searchVendor}`;
          break;
        case 'businessNumber':
          url += `?business_number__contains=${searchVendor}`;
          break;
          
        default:
          url += `?search=${searchVendor}`;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'enter':
          url += `&register_date=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }

    if(selectedBusinessType){
      url += `&business_type=${selectedBusinessType}`
    }

    if (publicity) url += `&hidden=${publicity}` 

    if (stopStatus) url += `&restrict=${stopStatus}` 

    
    const response = await instance.get(url);
    console.log(url)
    setSearchUrl(url)
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 직원 ID 확인
export function verifyEmployeeId(adminId) {
  // console.log(adminId);

  return instance
    .post(`auth/employee/validate/`, {
      username: adminId,
    })
    .then((response) => {
      alert("확인완료");
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      
      throw error;
    });
}

// 관리자 > 셀러관리 > 신규요청 > 승인 , 거절 처리
export function updateVendorStatusData(newData , id) {
  console.log(id);

  return instance
    .patch(`/vendor/admin/${id}`, newData)
    .then((response) => {
      alert("완료");
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}


// [hm] 입점 승인/거절 API
export const setVendorState = (id, state, rejectReason, requestType) => {
  if(state === "approve"){
    if(requestType === "대기"){
      // 신규입점 승인
      return instance.patch(`/vendor/approve/${id}`)
    }else if(requestType === "승인"){
      // 정보수정 승인
      return instance.patch(`/vendor/update/approve/${id}`)
    }
    
  }else if(state === "reject"){
    if(requestType === "대기"){
      // 신규입점 거절
      return instance.patch(`/vendor/reject/${id}`,
      {
        reject_reason: rejectReason
      })
    }
    else if(requestType === "승인"){
      // 정보수정 거절
      return instance.patch(`/vendor/update/reject/${id}`,
      {
        reject_reason: rejectReason
      })
    }
      
  }
};

// 해와관리자 > 셀러관리 > vendor 메모 
export const fetchvendorMemo2 = (vendorId , page) => {
  return instance.get(`vendor/memo/?vendor_id=${vendorId}&page=${page}`)
    .then(res => res.data)
};

const fetchvendorMemo = (page , vendorId) => {
  return instance.get(`vendor/memo/?vendor_id=${vendorId}&page=${page}`)
    .then(res => res.data)
};

export const useVendorMemoQuery = (vendorId) => {
  const [page, setPage] = useState(1);

  const { data, error, isFetching } = useQuery(
    ['vendorMemo', page , vendorId], () => fetchvendorMemo(page , vendorId),
  );

  return {
    data,
    error,
    isFetching,
  };
};

// 해와관리자 > 셀러관리 > 메모 등록 
export const RegisterVendorMemo = (data) =>
  instance.post(`vendor/memo/`, data ).then(response => response.data);

// 해와관리자 > 셀러관리 > 메모 삭제 
export const deleteVendorMemo= (pk) =>{
  return instance.delete(`vendor/memo/${pk}/`);
}

// 해와관리자 > 셀러관리 > 메모 수정
export const updateVendorMemo = (pk, content) => {
  return instance.patch(`vendor/memo/${pk}/`,
  {
    content: content
  })
}


// 셀러관리 > 활동기록 > 매장 
const fetchVendorRecordDate = (page, pageSize, ordering) => {
  return instance.get(`record/vendor/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useVendorRecordQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['vendorRecord', page, pageSize, ordering], () => fetchVendorRecordDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리 > 활동기록 > 패널티(점수) 
const fetchVendorPenaltyDate = (page, pageSize, ordering) => {
  return instance.get(`penalty/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useVendorPenaltyQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['Penalty', page, pageSize, ordering], () => fetchVendorPenaltyDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리 > 활동기록 > 패널티(정지) 
const fetchPenaltyActionDate = (page, pageSize, ordering) => {
  return instance.get(`penalty/action/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useVendorPenaltyActionQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['penaltyAction', page, pageSize, ordering], () => fetchPenaltyActionDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리 > 활동기록 > 메모 
const fetchVendorMemoDate = (page, pageSize, ordering) => {
  return instance.get(`vendor/memo/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useVendorMemoListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['vendorMemoList', page, pageSize, ordering], () => fetchVendorMemoDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};