import { useProductsQuery , handleSearch } from '../../../api/hwadmin/productApi.js';
import axios from 'axios';

import { useQuery ,  prefetchQuery} from 'react-query';
import React, { useState } from 'react';



function AdminSettings()
{
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>관리자설정</h2>
            </div>
     
            <section className="McontentBox mt30">
                <p className="settingSubTitle">관리자 추가</p>

                <div className='admin-add-box'>
                    <input type='text' className='admin-add-input' placeholder='ID'/>
                    <button className='admin-add-button' onClick={()=>{alert('현재 등록 불가능한 ID입니다.')}}>추가</button>
                </div>
            </section>
              <section className='McontentBox'>
                    <div className='fixTable_wrap'>
                        <table className='sTable' id='scrollTable'>
                            <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>관리자ID</th>
                                    <th>이름</th>
                                    <th>권한</th>
                                    <th>삭제</th>
                                </tr>
                                <tr>
                                    {/* 2024.01.25: CHM : 하드코딩 찾기 */}
                                    <td>관리자ID 나와야함</td>
                                    <td>관리자 이름 나와야함</td>
                                    <td className='authorityTd'>
                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" />
                                            <label for="scales">회원관리</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">매장관리</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">상품관리</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">주문배송</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">고객관리</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">게시판</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">정산</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">마케팅</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">통계</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">알림</label>
                                        </div>

                                        <div className='stch'>
                                            <input type="checkbox" id="scales" name="scales" checked/>
                                            <label for="scales">설정</label>
                                        </div>
                                    </td>
                                    <td>
                                        <button className='smBtn' onClick={()=>alert('대표 관리자 계정은 삭제할 수 없습니다.')}>삭제</button>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </section>
        </div>

                        
        </>
    )
}

export default AdminSettings