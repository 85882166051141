/***************************************************************************************
* FileName      : Join2.js
* Description   : 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Container, Form, Modal} from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';
import {AiOutlineCheck} from 'react-icons/ai';
import {BiCheckboxChecked, BiCheckbox} from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';
import { BASE_URL } from '../../api/apiConfig';
import Conditions from '../customer/Conditions.js';
import Term from '../customer/Term.js';
import { useRegistration , getCheckedUsername } from '../../api/services/memberApi';


function Join()
{
    const { post, isPostLoading, error } = useRegistration();

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
            <div className="spinner" ></div>
            </div>
        );
    };

    // // ID
    // const [username, setUsername] = useState('');   
    


    // ID 중복확인 , 형식확인 했는지 상태
    const [idCheckState, setIdCheckState] = useState(false);   

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        password2: '',
        name: '',
        email:'',
        phone_number: '',
        birth_date : '',
        address: {
            address: '',
            detail: '',
            postal_code: '',
            address_type: '',
        },
        gender:''
    });
 
    const { username, password, password2, name, email, phone_number, birth_date, address , gender } = formData;
    
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false); //주소입력모달
    const [conditionsModalIsOpen, setConditionsModalIsOpen] = useState(false); //이용약관 모달
    const [termsModalIsOpen, setTermsModalIsOpen] = useState(false); //개인정보처리방침 모달


    // 전체 약관 동의
    const [agreeChkState, setAgreeChkState] = useState(false); // 약간동의 상태 체크 확인용
    const handleAgreeChk = () => setAgreeChkState(!agreeChkState); // 약간동의 상태 체크박스 핸들링


    // (필수) 이용약관 동의 
    const [agreeUseChkState, setAgreeUseChkState] = useState(false); // 이용약관 동의  상태 체크 확인용
    const handleUseAgreeChk = () => setAgreeUseChkState(!agreeUseChkState); // 약간동의 상태 체크박스 핸들링

    // (필수) 개인정보 수집 및 이용동의
    const [agreePrivacyChkState, setAgreePrivacyChkState] = useState(false); 
    const handlePrivacyAgreeChk = () => setAgreePrivacyChkState(!agreePrivacyChkState); 


    const [isOpenPost, setIsOpenPost] = useState(false);
    const postCodeStyle = {
        display: 'block',
        position: 'relative',
        top: '0%',
        width: '400px',
        height: '400px',
        padding: '7px',
    };
    

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };
    
    const onCompletePost = (data) => {
        console.table(data);

        setIsOpenPost(false);
        setModalIsOpen(false);

        // 변경된 주소 정보를 리턴
        const newData = { 
            address: data.address,
            detail: '',
            postal_code: data.zonecode,
            address_type:  data.addressType === 'R' ? '도로명' : '지번'
        };

        console.log(newData)

        setFormData({ ...formData, address : newData })
    };

    // id 확인 
    const handleIdCheck = (event) => {
        event.preventDefault();

        getCheckedUsername(username)
        .then((data) => {
           alert(data.data);
           setIdCheckState(true);
        })
        .catch((error) => {
            console.log(error);
        });
    
    };

    // 아이디 입력 변화 확인
    const handleInputUserNameChange = (e) => {
        const newData = e.target.value;

        setIdCheckState(false);
        setFormData({ ...formData, username : newData })

    };

     // 유효성 검사
     const validateForm = () => {
        let isValid = true;


        // 1. 아이디 입력 확인    
        if(username === ''){
            alert("아이디 항목은 필수입니다.");
            return ;
        }
        // id 
        if(!idCheckState){
            alert("ID 중복 확인은 필수입니다.");
            return;
        }      

        // 2. 비밀번호 , 비밀번호 입력 확인
        if(password === "") {
            alert("비밀번호는 항목은 필수입니다.");
            return ;
        }else{
            // 비밀번호 유효성검사(영문,숫자 혼합 6~20)
            const chkPwd = function(str) {
                var reg_pwd = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
                return !reg_pwd.test(str) ? false : true;
            };

            if (chkPwd(password) === false) {
                alert("비밀번호는 영문,숫자를 혼합하여 6~12자 이내 입력해 주세요.");
                return ;
            }else{
                if(password2 === "") {
                    alert("비밀번호확인 항목은 필수입니다.")
                }else if(password != password2) {
                    alert("비밀번호가 불일치합니다.");
                }
            }
        }

        // 3. 이름 입력 확인
        if(name === ''){
            alert("이름 항목은 필수입니다.");
            return ;
        }

        // 4. 주소 입력 확인
        if(address.address === '' ){
            alert("주소 항목은 필수입니다.");
            return ;
        }

        // 5. 휴대폰번호 입력 확인
        if(phone_number === '' ){
            alert("휴대폰 번호 항목은 필수입니다.");
            return ;
        }

        // 6. 이메일 입력 확인
        if(email === '' ){
            alert("이메일 항목은 필수입니다.");
            return ;
        }

        // 7. 생년월일 입력 확인
        if(birth_date === '' ){
            alert("생년월일 항목은 필수입니다.");
            return ;
        }

        // 8. 성별 선택 확인
        if(gender === ''){
            alert("성별 항목은 필수입니다.");
            return ;    
        }

        // 약관 동의 확인
        if(!agreeChkState){
            alert("전체 약관 동의는 필수입니다.");
            return ;
        }

        return isValid;
    }

    // 현재 날짜를 가져오는 함수
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // 월과 일을 두 자리 숫자로 맞추기
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    };


    const handleSubmit = (event) => {
        if (validateForm()) {
            setIsLoading(true); // 로딩 상태를 활성화

            event.preventDefault();
         
            const data = {
                username : username || "",
                password: password,
                password2: password2,
                name: name,
                email: email,
                phone_number: phone_number,
                birth_date : birth_date,
                address: address,    
                gender : gender,        
            };

            console.log("데이터");
            console.log(data);

            post(data)
            .then(() => {
                alert("회원가입을 축하합니다! 로그인 페이지로 이동합니다");
                setIsLoading(false); // 로딩 상태를 비활성화

                window.location.href = '/member/login/'; // 로그인 페이지 리로드
            })
            .catch(error => {
                console.log("등록 실패:");
                console.log(error)
                setIsLoading(false); // 로딩 상태를 비활성화
            });
               
                
        }
    }

    return(
        <>
        <Container style={{maxWidth: "640px"}} className="mt-3 mb-3">
            <Row className='text-center my-5'>
                <h2 className="fs-3 fw-bold">회원가입</h2>
            </Row>
            <Row>
                <Col className="fs-5 fw-bold text-start">
                    <p>기본정보</p>
                </Col>
                <Col className="fs-6 fw-bold text-grey text-end">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>필수입력사항</span>
                </Col>
            </Row>
            <hr/>

            <Row className='mb-3'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>아이디</span> 
                </Col>

                <Col md={9} className="fs-6 fw-bold text-start d-flex justify-content-between align-items-center gap-1">
                    <Form.Control className="mt-1 mb-1" 
                                size="sm" 
                                type="text" 
                                id="member_id" 
                                name="member_id" 
                                value={username}
                                onChange={handleInputUserNameChange}
                             
                                />

                    <span class="btn2 rounded-1" onClick={handleIdCheck}>ID중복확인</span>
                
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>비밀번호</span> 
                </Col>
                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="password" 
                        id="passwd" 
                        name="passwd" 
                        value={password}
                        onChange={e => setFormData({ ...formData, password : e.target.value })}
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>비밀번호 확인</span>
                </Col>
                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="password"
                        id="passwd" 
                        name="passwd" 
                        value={password2}
                        onChange={e => setFormData({ ...formData, password2 : e.target.value })}
                    />
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>이름</span>
                </Col>

                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={name}
                        onChange={e => setFormData({ ...formData, name : e.target.value })}
                    />
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>주소</span>
                </Col>

                <Col md={9} className="fs-6 fw-bold text-start">
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form.Control 
                            className="mt-1 mb-1" 
                            size="sm"
                            type="text"
                            id="postcode1"
                            name="postcode1" 
                            placeholder='우편번호'
                            maxlength="14"
                            value={formData.address.postal_code} // 주소의 우편번호
                            disabled/>
                        
                        <p className='ms-2 p-1 fs-7 text-hw fw-bold border-hw w-50 text-center rounded-2 pointer' 
                            onClick={()=>{setIsOpenPost(!isOpenPost); setModalIsOpen(!modalIsOpen)}}>
                                우편번호 찾기
                        </p>
                    </div>

                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="text" 
                        id="postcode2"
                        name="postcode2"
                        placeholder='지번'
                        value={formData.address.address} // 지번 주소

                        disabled />

                        <Form.Control 
                            className="mt-1 mb-1" 
                            size="sm" 
                            type="text" 
                            id="postcode3" 
                            name="postcode3" 
                            placeholder='상세주소' 
                            value={formData.address.detail} // 상세주소
                            onChange={e => setFormData({ ...formData, 
                                            address: { ...formData.address, detail: e.target.value } })}/>
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>휴대폰</span>
                </Col>
                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="text" 
                        id="call" 
                        name="call" 
                        placeholder='- 없이 입력하세요.' 
                        value={phone_number} 

                        onChange={e => setFormData({ ...formData, phone_number: e.target.value })}/>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/>  <span>이메일</span>
                </Col>

                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="email"
                        id="email" 
                        name="email"
                        value={email} 

                        onChange={e => setFormData({ ...formData, email: e.target.value })}/>
                </Col>
            </Row>
            
            {/* 추가정보 */}
            <Row>
                <Col className="fs-5 fw-bold text-start">
                    <p>추가정보</p>
                </Col>
                <Col className="fs-6 fw-bold text-grey text-end">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>필수입력사항</span>
                </Col>
            </Row>
            <hr/>

            <Row className='mb-5'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>생년월일</span> 
                </Col>
                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Control 
                        className="mt-1 mb-1" 
                        size="sm" 
                        type="date" 
                        id="birth_year" 
                        name="birth_year" 
                        value={birth_date} 
                        max={getCurrentDate()} // 최대 날짜를 현재 날짜로 설정
                        onChange={e => setFormData({ ...formData, birth_date: e.target.value })}
                    
                    />
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col md={3} className="fs-6 fw-bold text-start">
                    <AiOutlineCheck className='fs-8 text-hw'/> <span>성별</span> 
                </Col>
                <Col md={9} className="fs-6 fw-bold text-start">
                    <Form.Group>
                        <Form.Check
                            inline
                            label="남성"
                            type="radio"
                            id="male"
                            name="gender"
                            value="남"
                            checked={gender === "남"}
                            onChange={e => setFormData({ ...formData, gender: e.target.value })}
                        />
                        <Form.Check
                            inline
                            label="여성"
                            type="radio"
                            id="female"
                            name="gender"
                            value="여"
                            checked={gender === "여"}
                            onChange={e => setFormData({ ...formData, gender: e.target.value })}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="agree-chk">
                    <input type="checkbox" id="agree-chk" checked={agreeChkState} onChange={handleAgreeChk}/>
                    <label for="agree-chk">
                    { agreeUseChkState &&  agreePrivacyChkState || agreeChkState? 
                        <span className='text-hw fw-bolder pointer'>
                            <BiCheckboxChecked className="fs-2"/>전체 약관에 동의합니다.
                        </span> 
                        : 
                        <span className='text-grey fw-bolder pointer'>
                            <BiCheckbox className="fs-2"/>전체 약관에 동의합니다.
                        </span> 
                    }

                    </label>
                </Col>
                
                <Col xs={9} className="agree-chk">
                    <input type="checkbox" id="use-agree-chk" checked={agreeUseChkState} onChange={handleUseAgreeChk}/>
                    <label for="use-agree-chk">
                        { agreeChkState || agreeUseChkState ? 
                            <span className='text-hw fw-bolder pointer'>
                                <BiCheckboxChecked className="fs-2"/>
                                    (필수) 이용약관 동의
                            </span> : 
                            <span className='text-grey fw-bolder pointer'>
                                <BiCheckbox className="fs-2"/>
                                    (필수) 이용약관 동의
                            </span> 
                        }
                    </label>
                </Col>
                <Col xs={3} className="text-end fs-7 pointer">
                    <u className="fw-bolder" onClick={()=>setConditionsModalIsOpen(true)}>
                        내용보기
                    </u>
                </Col>

                <Col xs={9} className="agree-chk">
                    <input type="checkbox" id="privacy-agree-chk" checked={agreePrivacyChkState} onChange={handlePrivacyAgreeChk}/>
                    <label for="privacy-agree-chk">
                    { agreeChkState || agreePrivacyChkState ? 
                        <span className='text-hw fw-bolder pointer'>
                            <BiCheckboxChecked className="fs-2"/>
                                (필수) 개인정보 수집 및 이용동의
                        </span> 
                        : 
                        <span className='text-grey fw-bolder pointer'>
                            <BiCheckbox className="fs-2"/>
                                (필수) 개인정보 수집 및 이용동의
                        </span> 
                    }
                    </label>
                </Col>
                <Col xs={3} className="text-end fs-7 pointer">
                    <u  className="fw-bolder" onClick={()=>setTermsModalIsOpen(true)}>
                        내용보기
                    </u>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <div className='base-button my-2'>
                        <button type="submit" onClick={handleSubmit}>
                            {isLoading ?  <LoadingSpinner /> : '회원가입'} 
                        </button>
                    </div>
                </Col>
            </Row>
      

        {/* 주소 검색 모달 */}
        {
            modalIsOpen  ? (
                <AddressModal show={modalIsOpen} onHide={setModalIsOpen} style={postCodeStyle} autoClose onComplete={onCompletePost} />
            ) : null
        }

        {/* 이용약관 모달 */}
        {
            conditionsModalIsOpen ? (
                <ConditionsModal show={conditionsModalIsOpen} onHide={setConditionsModalIsOpen} content={'conditions'} autoClose />
            ) : null
        }

        {/* 이용약관 모달 */}
        {
            termsModalIsOpen ? (
                <ConditionsModal show={termsModalIsOpen} onHide={setTermsModalIsOpen} content={'terms'} autoClose />
            ) : null
        }

        </Container>
        </>
    )
}


function AddressModal({show, onHide, style, onComplete}){
    return(
        <Modal size={'md'} show={show} onHide={()=>onHide(false)} aria-labelledby="sm-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title id='sm-modal' className='fs-6'>
                    주소를 입력해주세요
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DaumPostcode style={style} autoClose onComplete={onComplete} />
            </Modal.Body>
        </Modal>
    );
}

function ConditionsModal({show, onHide, content}){
    return(
        <Modal size={'lg'} show={show} onHide={()=>onHide(false)} aria-labelledby="conditions-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title id='conditions-modal' className='fs-6'>
                    {content === 'terms' ? '개인정보 수집 및 이용 동의' : '이용약관 동의'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content === 'terms' ? <Term/> : <Conditions/>}
            </Modal.Body>
        </Modal>
    )
}

export default Join