/***************************************************************************************
* FileName      : NoticeRow.js
* Description   : 해와관리자 > 게시판 > 공지사항 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import { useState } from 'react';
import { updateNoticeHidden, deleteNotice } from '../../../../api/hwadmin/boardApi';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

export const NoticeRow  =  ({ notice, index, selectedRows, setSelectedRows }) => {
  const navigate = useNavigate();

  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/member/management/?search=${searchQuery}`;
    navigate(newUrl);
  };

  const deleteButtonClick = (pk) => {
    let deleteMessage = prompt('게시글을 삭제하시겠습니까? \n"게시글을 삭제합니다"를 입력해주세요.');
    if(deleteMessage == "게시글을 삭제합니다"){
      deleteNotice(pk)
      .then(res => {
        console.log(res.data);
        alert("게시글이 삭제되었습니다.");
      })
      .catch(error => alert(error.message))
    }else{
      alert('삭제가 취소되었습니다.');
    }
  }

  const handleEditClick = (id) => {
    const editUrl = `/admin/board/editNotice/${id}`;
    window.location.href = editUrl;
  };

  return(
    <tr
      key={notice?.id}
      style={{
        backgroundColor: selectedRows[notice.id] ? "#f7f7f6" : "transparent"
      }}
    >
      <td>
        <input
          type="checkbox"
          name=""
          value=""
          onClick={() =>
            setSelectedRows({
              ...selectedRows,
              [notice?.id]: !selectedRows[notice?.id]
            })
          }
        />
      </td>
      <td>{index + 1}</td>
      <td>{notice?.type}</td>
      <td>
        {notice?.title}
      </td>

      <td className='blueLink' onClick={() => handleCellClick(notice?.author)}>
        {notice?.author}
      </td>

      <td>
        {extractYearMonthDayHourMinute2(notice?.created_at)}
      </td>
        
      <td>{notice?.view_count}</td>
      <td><ToggleSwitch pk={notice?.id} checked={notice?.hidden}/></td>

      <td>
        <button className='t-s-btn'  onClick={() => handleEditClick(notice?.id)}>수정</button>
      </td>
      <td><button className='t-s-btn' onClick={()=>{
        deleteButtonClick(notice?.id);
      }}>삭제</button></td>
  
  </tr>
  )
}

export default function ToggleSwitch({pk, checked}) {
  const [isChecked, setIsChecked] = useState(!checked); // isChecked를 checked로 초기화

  const handleToggle = () => {
    setIsChecked(!isChecked);
    updateNoticeHidden(pk, isChecked); // 업데이트 함수에 isChecked 값을 전달
  }

  return (
    <>
      <label className={`toggle ${isChecked ? 'checked' : ''}`}>
        <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
        <span className="toggle-slider round"></span>
      </label>
      <br/>{isChecked ? '공개' : '비공개'}
    </>
  );
}