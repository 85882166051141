/***************************************************************************************
* FileName      : MyInquiryRow.js
* Description   : 고객 > 마이페이지 > 1:1문의 내역 > Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState } from 'react';
import { extractYearMonthDayHourMinute2 , extractYearMonthDay2 } from "../Component/extractDateUtils"

function MyInquiryRow({inquiry})
{
    console.log(inquiry);

    const [isAnswerVisible, setIsAnswerVisible] = useState(false);

    const toggleAnswerVisibility = () => {
      setIsAnswerVisible(!isAnswerVisible);
    };

    return (
        <>
        <tr className="question" onClick={toggleAnswerVisibility}>      
            <td>
                {inquiry?.title}
            </td>
            
            <td>
                {extractYearMonthDay2(inquiry?.created_at)}
            </td>

            <td className={`answerstit${inquiry?.status === '답변완료' ? ' e' : ''}`}>
                <strong>{inquiry?.status}</strong>
            </td>

            <td className={`openbtn ${isAnswerVisible ? 'up' : 'down'}`}>
                <i className={`xi-angle-${isAnswerVisible ? 'down' : 'up'}`}></i>
            </td>
        </tr>

        {isAnswerVisible && (
            <tr className="mylanswer">
                <td colSpan="4" className='abox'>
                    <div className='contenta'>
                        <strong>문의</strong>
                        <p>{inquiry?.title}</p>
                        <div className='board-detail editor-content'>
                            {inquiry && (
                                <div dangerouslySetInnerHTML={{ __html: inquiry.content }}></div>
                            )}
                        </div>
                      
                    </div>
                    
                    {inquiry?.status === "답변완료" && 
                        <div className='mmanswer'>
                            <div className='d-flex justify-content-between mb-3'>
                                <strong>{inquiry?.status}</strong>
                                <p>{extractYearMonthDay2(inquiry?.answered_at)}</p>
                            </div>
                
                            <p className='mb-2'>{inquiry?.answer_title}</p>
                            <p>{inquiry?.answer}</p>
                        </div>
                    }
                </td>
            </tr>
        )}
        </>
    )
}
export default MyInquiryRow