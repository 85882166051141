import SellerManagerheader from './SellerManagerheader.js'
import SellerManagerNav from './SellerManagerNav.js'


import { Outlet } from 'react-router-dom';

const SellerManagerLayout = () => {
  return (
    <>
      <SellerManagerheader />
      <SellerManagerNav/>
      <Outlet />
    </>
  );
};

export default SellerManagerLayout;