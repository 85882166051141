import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';

function Agreement()
{
    return(
        <>
            <Container>
            <div className='agreement_wrap'>
                <div className='tit_wrap'>
                    <h3>통합 회원 실명(본인) 확인</h3>
                    <p>회원가입을 위해 본인 확인을 진행 합니다.</p>
                </div>

                <div className='resp_login_wrap Mt0'>
                    <span>휴대폰번호와 이름을 사용하여 본인확인을 합니다.</span>
                    <button className='btn_resp size_c color2 Wmax'>
                        휴대폰 본인 인증
                    </button>

                    <ul className='list_dot_01 gray_06 Mt25'>
                        <li>
			            실명확인이 되지 않는 경우 아래의 실명인증기관에 실명등록을 요청할 수 있습니다.
                        <br/>
			            한국신용정보㈜ 
                        <a href='tel:1588-2486'>1588-2486</a>
                        </li>
                        <li>
			                타인의 정보 및 주민등록번호를 부정하게 사용하는 경우 3년 이하의 징역 또는 1천만원 이하의 벌금에 처해질 수 있습니다
                            <br/>
                            (주민등록법 제 37조)
		
                        </li>
                    </ul>
                </div>
            </div>
            </Container>
        </>
    )
}

export default Agreement