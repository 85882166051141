let data = [
    {
        id : 0,
        title : "[영수증발급] 카드명세표출력",
        content : "판매자 주소와 연락처를 알고 싶어요?",
        price : 1200000
    },
    {
        id : 1,
        title : "[영수증발급] 카드명세표출력",
        content : "물건을 카드로구매하신후 카드전표영수증을 인쇄하고자 하실때는 http://ecredit.lgtelecom.com (검색사이트에서 감사합니다.",
        price : 1100000
    },
    {
        id : 2,
        title : "[영수증발급] 카드명세표출력",
        content : "Born in the States",
        price : 13000000
    }
]
export default data;