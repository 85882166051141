/***************************************************************************************
* FileName      : History.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발송예약 관리 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.07
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import Target from "./ReservationForm/Target";
import Category from "./ReservationForm/Category";
import Template from "./ReservationForm/Template";

import React, { useEffect , useState } from 'react';
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useTalkTemplate , useNotificationTalkSend } from "../../../../api/hwadmin/notificationApi";
function History()
{
    
    const { post, isLoading: isPostLoading, error } = useNotificationTalkSend();
    
    const { data: TalkTemplate, isLoading: isTalkTemplateLoading } = useTalkTemplate();

    const [selectedTemplate, setSelectedTemplate] = useState({ name: "" , content: "" , templtCode : "" });

    useEffect(() => {
        if (TalkTemplate && TalkTemplate.list.length > 0) {
            const firstTemplate = TalkTemplate.list[0];
            setSelectedTemplate({ 
                name: firstTemplate.templtName,
                content: firstTemplate.templtContent,
                templtCode: firstTemplate.templtCode
            }); 

            setReservationData((prevData) => ({
                ...prevData,
                tpl_code: firstTemplate.templtCode
            }));

        }
    }, [TalkTemplate]);
      

    const handleSelectChange = (event) => {
        const selectedTemplateName = event.target.value;
        const selectedTemplateData = TalkTemplate.list.find(template => template.templtName === selectedTemplateName);
    
        if (selectedTemplateData) {
            setSelectedTemplate({
                name: selectedTemplateName,
                content: selectedTemplateData.templtContent,
                templtCode: selectedTemplateData.templtCode
            });
    
            setReservationData((prevData) => ({
                ...prevData,
                tpl_code: selectedTemplateData.templtCode
            }));
        }
    };


    const [isLoading, setIsLoading] = useState(false);

    const [reservationData , setReservationData] = useState({
        receiver_group : "전체" ,
        hour : '',
        minute : '',
        tpl_code : '',
    });
    
    useEffect(()=>{
        console.log(reservationData);
    },[reservationData])
    
    const validateForm = () => {
        let isValid = true;
        return isValid;
    }

    const handleSubmit = (event) => {
        if (validateForm()) {
            setIsLoading(true); 
            event.preventDefault();

            if (reservationData.hour === '' && reservationData.minute === '') {
                delete reservationData.hour;
                delete reservationData.minute;
            }else{    
                reservationData.hour = parseInt(reservationData.hour);
                reservationData.minute = parseInt(reservationData.minute);
            }

            console.log(reservationData);
            post(reservationData)
            .then(() => {
                alert("발송예약 성공적으로 완료하였습니다");
                // window.location.href = '/admin/alarm/alimTalkSendingHistory'; 
                setIsLoading(false); // 로딩 상태를 비활성화
            })
            .catch(error => {
                setIsLoading(false); // 로딩 상태를 비활성화
            });
        }
    }

    return (
        <>
         
         <section className='product-register-wrap'>
            <div className='product-reguster-inner'>
                <div className='accordion'>
                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>대상회원 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Target 
                                reservationData={reservationData}
                                setReservationData={setReservationData}/>
                        </div>
                    </div>
               

                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>발송유형 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Category 
                                reservationData={reservationData}
                                setReservationData={setReservationData}/>
                        </div>
                    </div>

                    
                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>템플릿 선택 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Template 
                                TalkTemplate={TalkTemplate}
                                selectedTemplate={selectedTemplate}
                                handleSelectChange={handleSelectChange}
                                reservationData={reservationData}
                                setReservationData={setReservationData}/>
                        </div>
                    </div>

                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>미리보기 </p>
                        </div>
                        <div className='content-show'>  
                            <div className="preview-wrap">
                               <p>{selectedTemplate.content}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <div className='m-btn-wrap'>
            <button onClick={handleSubmit} className='m-btn m-btn-search'>
                {isLoading ?  <LoadingSpinner /> : '등록'} 
            </button>
        </div> 
    
    </>
    )
}

export default History