/***************************************************************************************
* FileName      : ProductReviewRow.js
* Description   : 셀러관리자 > 고객관리 > 상품평 페이지 > ProductReviewRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewReviewModal from './ViewReviewModal';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';
export const ProductReviewRow  =  ({ productReview, index, selectedRows, setSelectedRows }) => {

   const navigate = useNavigate();
    
  // 후기 보기 모달 
  const [isOpen, setIsOpen] = useState(false);

  const handleReviewView = () => {
      setIsOpen(true);
  };


  const openModal = () => {
    console.log("cccc");
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return(
    <tr
      key={productReview.id}
      style={{
        backgroundColor: selectedRows[productReview.id] ? "#f7f7f6" : "transparent"
      }}
    >
      <td className="">
        <input
          type="checkbox"
          name=""
          value=""
          onClick={() =>
            setSelectedRows({
              ...selectedRows,
              [productReview.id]: !selectedRows[productReview.id]
            })
          }
        />
      </td>
      <td className="">{index + 1}</td>
      <td className="">{productReview.rating}</td>
      <td className="">
        {productReview.orderitem.id} <br/>
        {productReview.orderitem.product.name}
      </td>
      <td className="s-review-imgs">
        {productReview.review_images.map((imageUrl, index) => (
          <img key={index} src={imageUrl} alt={`Image ${index + 1}`} />
        ))}
      </td>
      <td>
        {productReview.content.length > 20
        ? `${productReview.content.slice(0, 20)}...`
        : productReview.content}
      </td>
 
      {/* <td>{productReview.content}</td> */}
   
      <td>{productReview.user.username}</td>
      <td>{extractYearMonthDayHourMinute2(productReview.created_at)}</td>
      <td> 
        <button className='t-s-btn' onClick={() => handleReviewView()}>리뷰 보기</button>
      </td>

                           
      {isOpen && (
            <ViewReviewModal
                reviewId={productReview.id}
                setIsOpen={setIsOpen}/>
        )}
            
  </tr>
  )
}
