/***************************************************************************************
* FileName      : MOrderRow.js
* Description   : 고객 > 마이페이지 > 반품/취소내역 > 취소 MOrderRow 컴포넌트 (모바일)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.12
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDay2  } from '../Component/extractDateUtils.js';


function MOrderRow({orderItem})
{

    const navigate = useNavigate();

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        const total = orderItem.orderitem_option_rows.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(total);
    }, [orderItem]);

    const handleOrderNumberClick = (id) => {
        navigate(`/mypage/return/${id}`);
    };

    return (
        <>
        
        <div className='m-order-wrap'>   
            <div className='orderData'>
                <p>{orderItem.paid_at ? extractYearMonthDay2(orderItem.paid_at) : '주문일자'}</p>
                <span onClick={() => handleOrderNumberClick(orderItem.id)}>
                상세보기 &gt;                     
                </span>
            </div>
            <p className='m-orderStatus'>{orderItem?.confirmation_status}</p>
            <div className='m-prod-wrap'>  
                <div className='m-prod-thum' >
                    <img
                        src={orderItem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </div>
                <div className='m-prod-info'>
                    <span className='vendortxt'>[{orderItem.vendor_name}] </span>
                    <span className='pdtxt'>{orderItem.product_name}</span>
            
                    {/* 옵션 */}
                    {orderItem.orderitem_option_rows && orderItem.orderitem_option_rows.length > 0 && (
                    <>
                        <span className='option_list2'>
                            {/* <i className='m-optiontxt'>옵션/수량 </i> */}
                            <div>
                                {orderItem.orderitem_option_rows.map((option, index) => (
                                    <>
                                        <p key={index}>
                                            {option.name} / {option.quantity}개
                                        </p>
                                        <p className='m-o-price'>
                                            {option.total_price.toLocaleString()}원
                                        </p>
                                    </>
                                ))}
                            </div>
                        </span>
                    </>
                    )}

                    {/* 추가상품 */}
                    {orderItem.orderitem_extra_options && orderItem.orderitem_extra_options.length > 0 && (
                    <>
                        <span className='option_list2'> 
                            <i className='m-optiontxt'>추가상품</i>
                            <div>
                            {orderItem.orderitem_extra_options.map((extra_option, index) => (
                                <p key={index}>
                                    {extra_option.name} <br/>
                                    {extra_option.total_price.toLocaleString()}원 / {extra_option.quantity}개
                                </p>
                            ))}
                                </div>
                        </span>
                    </>
                    )}


                    </div>
            
                </div>


        {/* <td className='lbe6' >
            {totalQuantity}
        </td>
        <td className='lbe6' >
            {orderItem && orderItem.original_total_price ? 
                orderItem?.original_total_price.toLocaleString(): "0"}원
        </td>
        <td className='status_td lbe6'>
            <p>{orderItem?.confirmation_status}</p>
            <button className='mpbtn1 mt-1'>배송조회</button>
      
        </td> */}
        </div>
        </>
    )
}

export default MOrderRow