/***************************************************************************************
* FileName      : MailSendingHistory.js
* Description   : 해와관리자 > 알림 > 메일발송관리 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import Manager from "./Mail/Manager";
import History from "./Mail/History";


import React, { useState } from 'react';
function MailSendingHistory()
{
    const [activeComponent, setActiveComponent] = useState("Manager");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            {/*  메일발송관리 */}
            <div className='page-tit'>
                <h2>메일발송관리</h2>
            </div>
            <div className='setting-tab-buttons'>
                <button className={activeComponent === "Manager" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Manager")}>자동발송 관리</button>

                <button className={activeComponent === "History" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("History")}>발송내역</button>
        
            </div>
            {/* 자동발송 관리 */}
            {activeComponent === "Manager" && <Manager />}
            
            {/* 발송내역 */}
            {activeComponent === "History" && <History />}

        </div>

        </>
    )
}

export default MailSendingHistory