/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 해와관리자 > 주문배송 > 주문조회 조회 OrderRow 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.00.00
* Modifide Date : 2024.02.27 : CHM : 송장번호 등록 모달창 띄우기
*               : 2024.03.07 : CHM : 송장번호 여러건 등록 체크박스 수정
* Reference     : 
***************************************************************************************/
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import useDateFormat from "../../../../hooks/useDateFormat";
import { updateOrderPackaging, updateOrderReject } from "../../../../api/hwadmin/orderAPI";
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils";
import { OrderStateChangerModal } from "./statusModal";

export const OrderRow = ({ order,  expandedIds, handleExpandClick, selectedRows, setSelectedRows, selectedRowsData, setSelectedRowsData }) => {
  
  const navigate = useNavigate();

  // 주문번호 선택 핸들링
  const handleOrderNumberClick = (orderNumber,orderId) => {
    navigate(`/admin/order/inquire/${orderNumber}/${orderId}`);
  };
  
  // 주문상태 변경(상품준비중 상태로 변경)
  const orderPackaging = (id) => {
    return updateOrderPackaging(id).then(() => alert("주문 상태가 변경되었습니다.")).catch((error)=>{alert("패키징 처리 에러 : ", error?.response?.data)})
  }

  // 주문상태 변경(주문 거절)
  const orderReject = (order_number, reject_reason, order_item) => {
    return(
      updateOrderReject(order_number, reject_reason, order_item)
        .then(res => console.log(res?.data))
        .catch(error => console.log(error?.response?.data))
    )
  }

  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/store/inquiry/?search=${searchQuery}`;
    navigate(newUrl);
  };

  console.log("order", order);

  // 상태변경 모달
  const [nextStateModal, setNextStateModal] = useState(false);
  
  //product ID 검색 페이지 이동
  const handleProductIdCellClick = (productId) => {
    const searchQuery = encodeURIComponent(productId);
    const newUrl = `/admin/product/check/?search=${searchQuery}`;
    navigate(newUrl);
  };

  // 전체체크박스 선택상태 관리
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAllChange = (isChecked) => {
    setSelectAll(isChecked); // 전체선택 체크박스 상태 업데이트
    const newSelectedRows = {...selectedRows};
    const newSelectedRowsData = {...selectedRowsData};

    order.order_items.forEach((orderItem) => {
      // 상위 체크박스의 상태에 따라 하위 체크박스 상태 업데이트
      newSelectedRows[orderItem?.id] = isChecked;

      if (isChecked) {
        newSelectedRowsData[orderItem?.id] = {
          order_number: orderItem?.order_number,
          status: orderItem?.status,
          name: orderItem?.product_name,
          option_rows: orderItem?.orderitem_option_rows,
          extra_options: orderItem?.orderitem_extra_options,
          pay_method: order?.is_escrow
        };
      }
    });
    setSelectedRows(newSelectedRows); // 업데이트된 상태를 적용
    setSelectedRowsData(newSelectedRowsData); // 선택된 항목 데이터 업데이트
  };

  // 체크박스 상태 변경 핸들러
  const handleCheckboxChange = (itemId, isChecked, itemData) => {
    setSelectedRows(prev => ({
      ...prev,
      [itemId]: isChecked
    }));

    // selectedRowsData 상태 업데이트
    setSelectedRowsData(prev => {
      // 체크가 되었다면, 정보 추가
      if (isChecked) {
        return {
          ...prev,
          [itemId]: itemData
        };
      } else {
        // 체크가 해제되었다면, 해당 정보 제거
        const updated = { ...prev };
        delete updated[itemId];
        return updated;
      }
    });
    console.log(selectedRows);
  };

  return(
    <React.Fragment key={order?.id}>
    <tr key={order.id}>
      <td>
        <button
          className='expand-btn'
          onClick={() => handleExpandClick(order?.id)}
          >
          <i
            className={
              expandedIds.includes(order?.id) ? 'xi-angle-up' : 'xi-angle-down'
            }
          ></i>
        </button>
      </td>
      {/* 주문번호 */}
      <td>{order?.id}</td>
      <td 
        className="blueLink"
        onClick={() => handleOrderNumberClick(order.order_number,order.order_id)}>
          {order.order_number && order.order_number}
      </td>
      <td className='blueLink' onClick={() => handleCellClick(order?.vendor_name)}>
        {order.vendor_name && order.vendor_name}
      </td>
      <td>{order?.paid_at ? extractYearMonthDayHourMinute2(order?.paid_at) : "-" }</td>
      <td>
        <span className={`statusBadge ${order?.order_status === "결제완료" ? 'request' : ''} ${order?.order_status === "주문완료" ? 'complete' : ''}`}>
          {order?.order_status}
        </span>
      </td>
      <td>{order?.ready_count}</td>
      <td>{order?.reject_count}</td>
      <td>{order?.unselected_count}</td>
      <td>{order?.complete_count}</td>
      <td>
        {
          //착불인 경우 original_delivery_fee 사용
          order?.type === '착불' ? (
          <>
            <span className="text-danger">{order?.type} </span>
            <> {order?.original_delivery_fee}원</>
          </>) :
          //선불인 경우 delivery_fee가 0이면 무료배송으로 표시
          order?.delivery_fee === 0 ? '무료배송' :
          `${order?.type} ${order?.delivery_fee}원`}
      </td>

      <td>{order?.discount_price}원</td>
      <td>{order?.original_total_price}원</td>
      <td>
        {order?.orderer_username}<br/>
        {order?.orderer_name }
      </td>
      <td>
        {order?.recipient_name}<br/>
        {order?.recipient_phone_number }
      </td>
      <td>
        [{order?.postal_code}]{order?.address }<br/>
        {order?.detail_address}
      </td>
      </tr>
      {expandedIds.includes(order?.id) && (
          <tr className='sub-table'>
            <td colSpan={13}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <input 
                        type="checkbox"
                        checked={selectAll}
                        onChange={e => handleSelectAllChange(e.target.checked)}
                      />
                    </th>
                    <th>상품번호</th>
                    <th>상품명</th>
                    <th>옵션정보</th>
                    <th>추가옵션</th>
                    <th>
                      주문금액<br/>
                      (상품금액)
                    </th>
                    <th>쿠폰금액</th>
                    <th>
                      결제금액<br/>
                      (상품금액-쿠폰할인금액)
                    </th>
                    <th>
                      주문확인(상품준비/주문거절)
                    </th>
                    <th>송장번호</th>
                  </tr>
                </thead>
                <tbody>
                {order.order_items.map((orderItem, orderIndex) => (
                  <React.Fragment key={orderIndex}>
                    {orderItem?.orderitem_option_rows?.map((optionRow, rowIndex) => (
                        <tr key={`${orderIndex}-${rowIndex}`}>
                            {rowIndex === 0 && (
                                <>
                                    <td rowSpan={orderItem.orderitem_option_rows.length}>
                                      <input
                                        type="checkbox"
                                        checked={selectedRows[orderItem?.id] || false}
                                        onChange={e => handleCheckboxChange(orderItem?.id, e.target.checked, {
                                          order_number: orderItem?.order_number,
                                          status: orderItem?.status,
                                          name: orderItem?.product_name,
                                          option_rows: orderItem?.orderitem_option_rows,
                                          extra_options: orderItem?.orderitem_extra_options,
                                          pay_method: order?.is_escrow
                                        })}
                                      />
                                    </td> 
                                    <td 
                                      rowSpan={orderItem?.orderitem_option_rows?.length}
                                      className='blueLink' onClick={() => handleProductIdCellClick(orderItem?.product_id)}>
                                    
                                      {orderItem?.product_id}
                                    </td>
                                    <td rowSpan={orderItem?.orderitem_option_rows?.length}>{orderItem?.product_name}</td>
                                </>
                            )}
                            <td>{optionRow?.name}</td> 
             

                            {rowIndex === 0 && ( 
                            <td rowSpan={orderItem?.orderitem_option_rows?.length}>
                                {orderItem?.orderitem_extra_options?.map((extraOption, extraIndex) => (
                                    <div key={extraIndex}>
                                      <span>
                                        {extraOption?.name} 
                                        {extraOption?.quantity}개 {extraOption?.total_price.toLocaleString()}원
                                      </span>
                                    </div>
                                ))}
                            </td>
                            )}  

                            { rowIndex === 0 && 
                            <>
                              <td rowSpan={orderItem.orderitem_option_rows.length}>
                                {optionRow?.total_price.toLocaleString()}원
                              </td> 
                              <td rowSpan={orderItem.orderitem_option_rows.length}>
                                {orderItem?.discount_price }원
                              </td>

                              <td rowSpan={orderItem.orderitem_option_rows.length}>
                                {orderItem?.total_price.toLocaleString()}원
                              </td>
                              </>
                            }
                            <td>
                              {orderItem?.status}
                              {
                                orderItem?.status === '결제완료' && 
                                <>
                                  <button className='t-s-btn mx-1'  onClick={() => {
                                    window.confirm("상품 준비중 상태로 변경하시겠습니까?") ?
                                    orderPackaging(orderItem?.id) : console.log('상품 준비중 상태 변경 취소')
                                  }}>상품 준비</button>

                                  <button className='t-s-btn mx-1 text-danger'  onClick={() => {
                                    window.confirm("현재 주문을 거절하시겠습니까? 취소시 판매자 귀책으로 인한 패널티가 부여됩니다.") ?
                                    (
                                      orderReject(order?.id, "재고 미확보")
                                    ) : console.log('주문 거절 취소')
                                  }}>주문 거절</button>
                                </>
                              }
                            </td>
                            <td>
                              {
                                orderItem?.status === '상품준비중' && !orderItem?.delivery?.tracking_number ? (
                                  <button className='t-s-btn mx-1'  onClick={() => {
                                    setNextStateModal(true);
                                    console.log("에스크로?", order?.is_escrow);
                                    // 2024.02.16 : CHM : 송장번호 등록 모달창 띄우기
                                    // order/deliveryitem/document에 에스크로 여부 필요(송장번호 등록)
                                  }}>송장번호 등록</button>
                                ):(
                                  <>
                                  <span className='fs-7'>{orderItem?.delivery?.courier} </span>
                                  <span className='fs-7 fw-bold'> {orderItem?.delivery?.tracking_number}</span>
                                  </>
                                )
                              }
                            </td>
                            {/* orderItem Row송장번호 등록 */}
                            <OrderStateChangerModal 
                              open={nextStateModal}
                              setPopup={setNextStateModal}
                              selectedRows={{[orderItem?.id]: true}}
                              selectedRowsData={{
                                [orderItem?.id]: {
                                  order_number: orderItem?.order_number,
                                  status: orderItem?.status,
                                  name: orderItem?.product_name,
                                  option_rows: orderItem?.orderitem_option_rows,
                                  extra_options: orderItem?.orderitem_extra_options,
                                  pay_method: order?.is_escrow
                                }
                              }}
                            />
                        </tr>
                    ))}
                    <tr>
                       
                    </tr>
                    </React.Fragment>
                ))}


                </tbody>
              </table>
            </td>
          </tr>
        )}
        </React.Fragment>
  )

};