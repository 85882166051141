
import { Modal } from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';

function AddressModal({show, onHide, onComplete, detailModalState, detailAddress, setDetailAddress, changeAddress}){
    return(
        <Modal size={'md'} show={show} onHide={()=>onHide(false)} aria-labelledby="sm-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title id='sm-modal' className='fs-6'>
                    우편번호 검색
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <DaumPostcode autoClose onComplete={onComplete} />
                {
                    !detailModalState ? '' : 
                    <>
                    <input
                        type="text"
                        value={detailAddress}
                        placeholder='상세주소'
                        onChange={(e)=>setDetailAddress(e.target.value)}
                        className="w-100 p-2"
                    />
                    <button className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold" 
                        onClick={()=>changeAddress()}>
                            확인
                    </button>
                    </>
                    
                }
            </Modal.Body>
        </Modal>
    );
}


export default AddressModal

