/***************************************************************************************
* FileName      : order.js
* Description   : 고객 > 상품 결제페이지
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2023.12.11
* Modifide Date : 2024.01.05 : CHM : 쿠폰 적용방식 변경, 쿠폰관련 컴포넌트 파일 분리(쿠폰블록, 쿠폰모달)
*               : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container, Tab, Modal, Form, InputGroup} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Pg from './Payment';
import { instance } from '../../axiosInstance/axiosConfig.js';
import { BASE_URL } from '../../api/apiConfig.js';
import { getAccessFromCookie } from '../../api/cookieUtils.js';
import { getOrderDetail, getMileage, sendUseMileage, getAvailableCoupon, updateOderUserInfo} from '../../api/orderPaymentsApi';
import { OrderInfo, UpdateOrderInfoModal } from './Detail/OrderInfo';
import { RecipientAddress, SelectAddressModal } from './Detail/AddressInfo';
import { DeliveryCouponBlock, ItemCouponBlock } from './Coupon/CouponBlock.js';

export default function OrderLayout(){
    const [orderDetailPk, setOrderDetailPk] = useState(); // 주문pk값
    const [userPk, setUserPk] = useState(); //주문회원 pk값
    const [orderData, setOrderData] = useState(null); // 주문페이지 기본정보
    const [mileage, setMileage] = useState(0); //보유마일리지
    const [useMileage, setUseMileage] = useState(0); //사용할 마일리지
    const [modalType, setModalType] = useState('배송지 선택'); // 모달 타입(배송지 선택 / 배송지 수정 / 배송지 추가)
    const [taxFreeAmount, setTaxFreeAmount] = useState(0); // 면세상품금액
    const [orderName, setOrderName] = useState(''); //주문명 (abcd...외2)

    // pk값을 읽어오는 함수 (pk값이 로드되기전에 컴포넌트가 먼저 불러와져서 발생하는 에러 막기 위함)
    const getOrderDetailPk = () => {
        return orderDetailPk;
    }
    const getUserPk = () => {
        return userPk;
    }

    // 주문자 정보 담을 공간 (userInfo에 담음)
    const [userInfo, setUserInfo] = useState({
        name: null,
        phone: null,
        email: null
    });
    // 수령인 정보 담을 공간 (addressInfo에 담음)
    const [addressInfo, setAddressInfo] = useState({
        name: null,
        address: null,
        addressType: null,
        detail: null,
        postalCode: null,
        phone: null
    })

    // 주소 모달 on/off
    const [addressModal, setAddressModal] = useState(false);

    // 주문자 정보 모달 on/off
    const [inputFieldModal, setInputFieldModal] = useState(false);

    useEffect(()=>{
        // 주문페이지 기본정보 전체 조회
        getOrderDetail()
            .then(res => {
                console.table(res?.data);
                // order detail api로 얻은 정보를 기본정보로 저장(orderData)
                setOrderData(res?.data);
                // order detail 의 pk값 저장(orderDetailPk)
                setOrderDetailPk(res?.data?.id);
                // 주문자 정보 update
                setUserInfo({
                    name: res?.data?.orderer_name,
                    phone: res?.data?.orderer_phone_number,
                    email: res?.data?.orderer_email
                });
                // 수령인 정보 update
                setAddressInfo({
                    name: res?.data?.recipient_name,
                    address: res?.data?.address,
                    addressType: '지번',
                    detail: res?.data?.detail_address,
                    postalCode: res?.data?.postal_code,
                    phone: res?.data?.recipient_phone_number
                })
            })
            .catch(error => alert(error.message))
    }, [addressModal, inputFieldModal])

    useEffect(()=>{
        // 마일리지 조회
        getMileage()
            .then(res => {
                // 사용가능한 보유 마일리지 표시
                setMileage(res.data.mileage);
            })
            .catch(error => alert(error.message));
    },[])

    const renderDeliveryItems = () => {
        if (!orderData || !orderData.delivery_items) {
            return null;
        }

        // DeliverItem 표시
        return orderData.delivery_items.map((deliverItem, index) => 
            // 주문정보 업데이트를 위한 setOrderData 함수를 props로 전달
            <DeliveryItem key={index} deliveryItem={deliverItem} setOrderData={setOrderData}/>
        );
    
    };

    useEffect(()=>{
        console.log("주문정보 표시 : ",orderData);
        setOrderName(orderData?.name);
    })

    useEffect(()=>{
        let taxFree = 0
        orderData?.delivery_items.map(di => di?.order_items.map(oi => oi?.tax === '면세' ? taxFree += oi?.total_price : null));
        console.log("면세금액",taxFree);
        setTaxFreeAmount(taxFree);
    }, [orderData])

    return(
        // 공통레이아웃
        <>
            <Container className='mt-3 mb-3 p-1'>
                <Row className='m-0 p-0'><h3 className='fw-bold text-hw'>주문/결제</h3></Row>
                {/* <ComingSoonPage/> */}
                
                <Row className='m-0 p-0'>
                    <Col lg={8}>
                        <p className='m-0 mt-4 mb-2 p-0 fs-6 text-grey fw-bold'>상품정보</p>
                        {renderDeliveryItems()}
                        
                        {/* 주문자 정보 */}
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='m-0 mt-4 mb-2 p-0 fs-6 text-grey fw-bold'>주문자 정보</p>
                            <button className='btn mt-4 mb-2 border-hw text-hw fs-7 rounded-0' onClick={()=>{setInputFieldModal(true)}}>정보 수정</button>
                        </div>
                        <OrderInfo userInfo={userInfo}/>
                        <UpdateOrderInfoModal modalState={inputFieldModal} setModalState={setInputFieldModal} userInfo={userInfo} setUserInfo={setUserInfo} getOrderDetailPk={getOrderDetailPk}/>

                        {/* 수령인 */}
                        <div className='mt-4 d-flex justify-content-between align-items-center'>
                            <p className='m-0 mt-4 mb-2 p-0 fs-6 text-grey fw-bold'>받는사람</p>
                            <button className='btn mt-4 mb-2 border-hw text-hw fs-7 rounded-0' onClick={()=>{setAddressModal(true)}}>배송지 변경</button>
                        </div>
                        <RecipientAddress data={addressInfo}/>
                        <SelectAddressModal modalType={modalType} setModalType={setModalType} modalState={addressModal} setModalState={setAddressModal} getOrderDetailPk={getOrderDetailPk} getUserPk={getUserPk}/>
                        
                        {/* 상품 할인쿠폰 */}
                        <p className='m-0 mt-5 mb-2 p-0 fs-6 text-grey fw-bold'>할인쿠폰(상품할인)</p>
                        <ItemCouponBlock data={orderData} setOrderData={setOrderData}/>

                        {/* 배송비 할인쿠폰 */}
                        <p className='m-0 mt-5 mb-2 p-0 fs-6 text-grey fw-bold'>할인쿠폰(배송비할인)</p>
                        <DeliveryCouponBlock data={orderData} setOrderData={setOrderData}/>
                       

                    </Col>
                    <Col lg={4}>
                        <p className='m-0 mt-4 mb-2 p-0 fs-6 text-grey fw-bold'>상품결제</p>
                        <Payment 
                            orderName={orderName}
                            payData={orderData} 
                            mileage={mileage} 
                            useMileage={useMileage} 
                            setUseMileage={setUseMileage} 
                            setOrderData={setOrderData} 
                            taxFreeAmount={taxFreeAmount}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

// 묶음배송박스(DeliveryItemBox)
function DeliveryItem({deliveryItem, setOrderData}){ 
    console.log("묶음배송박스!"+deliveryItem);
    const [deliveryModal, setDeliveryModal] = useState(false);

    return(
        <div className='m-0 mb-5 p-0'>
            <Row className='m-0 p-2 d-flex justify-content-end align-items-center bg-grey border'>
                <Col xs={9} sm={10} className="m-0 p-0 fs-7 fw-bolder">[{deliveryItem?.order_items[0].vendor_name}]택배({deliveryItem?.type}){deliveryItem?.type === '착불' ? null : " : "+deliveryItem?.delivery_fee.toLocaleString()+'원'}</Col>
                {/* original_delivery_fee가 있는 경우만 선불/착불 전환 가능 */}
                <Col xs={3} sm={2} className="m-0 p-0 border-hw bg-white text-hw text-center fs-7 fw-bold pointer" onClick={()=>{deliveryItem?.original_delivery_fee === 0 ? alert('배송비가 없는 경우 착불 전환이 불가능합니다.') : setDeliveryModal(true);}}>배송변경</Col>
            </Row>
            {
                deliveryItem?.order_items.map((orderItem, index) => <Item key={index} orderItems={orderItem}/>)
            }
            <Row className='m-0 p-3 border'>
                <Col xs={6} className='fs-7 fw-bold text-start'>배송비</Col>
                <Col xs={6} className='fs-7 fw-bold text-end'>{deliveryItem?.type === '선불' ? deliveryItem?.delivery_fee.toLocaleString()+'원' : (
                    <p>
                    <span className='text-grey text-decoration-line-through'>{deliveryItem?.original_delivery_fee.toLocaleString()+'원'}</span>
                    <span className="text-danger fw-bold"> 착불</span>
                    </p>
                    )}
                </Col>
                <Col xs={6} className='mt-1 fs-7 fw-bold text-start'>주문금액</Col>
                <Col xs={6} className='mt-1 fs-6 fw-bold text-end'>{deliveryItem?.type === '선불' ? (deliveryItem?.original_total_price + deliveryItem?.delivery_fee).toLocaleString() : (deliveryItem?.original_total_price).toLocaleString()}원</Col>
            </Row>
            <SelectModal type={'delivery'} modalState={deliveryModal} setModalState={setDeliveryModal} deliveryItemId={deliveryItem?.id} setOrderData={setOrderData}/>
        </div>
    );
}

// 상품 컴포넌트(orderItem)
function Item({orderItems}){
    console.log("오더아이템!"+orderItems);
    const optionRows = orderItems?.orderitem_option_rows.map(item => "["+item.name+" * "+item.quantity+"개] \n ");
    const extraOptionRows = orderItems?.orderitem_extra_options.map(item => "["+item.name+" * "+item.quantity+"개] | ");
    return(
        <>
            <Row className='m-0 p-1 border'>
                <Col xs={3} sm={2} className='pt-3'><img src={orderItems?.main_image} width='100%'/></Col>
                <Col xs={9} sm={10}>
                    <div><p className='fs-6 fw-bold pt-3'>상품명 : {orderItems?.product_name}</p></div>
                    <div className='p-3'>
                        <p className='fs-7 fw-bolder text-dark-grey'>옵션 : {orderItems?.orderitem_option_rows.length === 0 ? '옵션없음' : optionRows}</p>
                        <p className='fs-7 fw-bolder text-dark-grey'>추가옵션 : {orderItems?.orderitem_extra_options.length === 0 ? '추가옵션 없음' : extraOptionRows}</p>
                        {/* <p className='fs-8 fw-bolder text-dark-grey'>판매가 : {orderItems?.original_total_price.toLocaleString()}원</p> */}
                        <p className='fs-7 fw-bolder text-dark-grey'>상품금액 : <span className='fs-7 fw-bold'>{orderItems?.original_total_price.toLocaleString()}원</span></p>
                    </div>
                </Col>
            </Row>
        </>
    )
}




// 마일리지
function Mileage({payData, mileage, setUseMileage, setOrderData}){
    const access = getAccessFromCookie();
    
    const [inputMileage, setInputMileage] = useState(0); // 사용자 입력 마일리지
    const handleUseMileage = () => {
        // 마일리지 사용
        if(inputMileage >= 0 ){
            if(inputMileage > mileage){
                alert('사용가능한 마일리지를 초과하였습니다.');
                return;
            }else{
                sendUseMileage(payData?.id, inputMileage)
                    .then(res => {
                        // 사용가능한 마일리지 표시
                        console.log("마일리지 적용!!!!"+res.data);
                        setUseMileage(inputMileage);
                        setOrderData(res.data);
                    }).catch(error => {
                        // 에러 나온 경우 마일리지 사용 0원으로 변경
                        setUseMileage(0);
                        alert(error.response.data.data);
                    })
            }
        }else{
            alert('사용할 마일리지를 입력해주세요');
        }
        
    };

    return(
        <>
            <Row className='m-0 p-2'>
                <Col className='m-0 p-0 fs-7 fw-bolder text-start'>마일리지(보유 : {mileage.toLocaleString('ko-KR')})</Col>
                <Col className='m-0 p-0 fs-7 fw-bolder text-end'>(-){payData?.temp_mileage.toLocaleString('ko-KR')}원</Col>
            </Row>
            <Row className='m-0 p-0'>
                <InputGroup className="mb-3">
                    <Form.Control
                        size='sm'
                        type='number'
                        min='0'
                        // max='0' 보유 마일리지만큼만 올리도록
                        placeholder='0'
                        aria-describedby="basic-addon2"
                        onChange={(e) => setInputMileage(parseInt(e.target.value))}
                    />
                    <Button variant="outline-secondary" id="button-addon2" className='fs-7' onClick={handleUseMileage}>
                        사용
                    </Button>
                </InputGroup>
            </Row>
        </>
    )
}

// 결제
function Payment({orderName, payData, mileage, useMileage, setUseMileage, setOrderData, taxFreeAmount}){ 
    const [deliveryItems, setDeliveryItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [escrowProducts, setEscrowProducts] = useState([]);
    const [discountAmount, setDiscountAmount] = useState(0); 
    const [originalTotalDeliveryFee, setOriginalTotalDeliveryFee] = useState(0); // 총 배송비(쿠폰할인이전) 금액에 대한 정보가 API에 없어서 계산로직 추가함.

    // 1. 묶음배송 단위(deliveryItems) 설정
    useEffect(()=>{
        console.log("payData(escrowProducts 추출을 위한 로깅):",payData);
        payData && setDeliveryItems(payData?.delivery_items);
    }, [payData])

    // 2. 전체상품(allItems) 설정
    useEffect(()=>{
        setAllItems([]); //전체 아이템 초기화
        console.log('delivery items : (DI box event => data 로깅)', deliveryItems);
        deliveryItems != [] && deliveryItems.map(di => {
            di?.order_items.map(oi =>{
                // 2-1. 추가옵션 상품 추출 -> allItems에 저장
                oi?.orderitem_extra_options != [] && oi?.orderitem_extra_options.map(item => setAllItems(prevAllItems => [...prevAllItems, item]));
                // 2-2. 상품 옵션단위 추출 -> allItems에 저장
                oi?.orderitem_option_rows != [] && oi?.orderitem_option_rows.map(item => setAllItems(prevAllItems => [...prevAllItems, item]));
            })
        });
    }, [deliveryItems])

    // 3. 에스크로 상품(escrowProducts) 설정 (toossPayments api 최적화)
    // useEffect(()=>{
    //     console.log("전체 아이템 내역 로깅",allItems);
    //     setEscrowProducts([]); //escrowProducts 초기화
    //     allItems != [] && allItems.map(item => setEscrowProducts(prevEscrowItems => [...prevEscrowItems, {
    //         id: item?.id.toString(),
    //         name: item?.name,
    //         code: item?.order_item,
    //         unitPrice: item?.sale_price,
    //         quantity: item?.quantity
    //     }]))
    // },[allItems])

    // 4. 쿠폰 사용 시 할인금액 체크
    useEffect(()=>{
        let deliveryDiscountPrice = 0; // 배송비 할인금액
        let itemDiscountPrice = 0; // 상품 할인금액
        let deliveryFee = 0; //배송비 합산금액
        deliveryItems != [] && deliveryItems.map((di) => {
            deliveryDiscountPrice += di?.discount_price; // 배송비 할인금액 합산
            deliveryFee += di?.delivery_fee; //배송비 합산금액
            di?.order_items.map(oi => {
               itemDiscountPrice += oi?.discount_price; // 상품 할인금액 합산
            })
            console.log("ddp : ",deliveryDiscountPrice);
            console.log("idp : ",itemDiscountPrice);
        })
        setOriginalTotalDeliveryFee(deliveryFee); //배송비 합산금액
        setDiscountAmount(prevDiscountAmount => deliveryDiscountPrice + itemDiscountPrice); // 전체 할인금액 합산
    }, [payData, deliveryItems])

    useEffect(()=>{
        console.log('escrowProducts : ', escrowProducts)
    }, [escrowProducts])

    // 상품금액을 받아오는 전용 함수 별도로 사용
    const getPrice = () => {
        console.log('total price : ', payData?.total_price);
        return payData && parseInt(payData?.total_price)
    }

    // 토스페이먼츠에 결제를 위해 전송되는 데이터
    const data = {
        orderName: orderName,
        price: payData?.total_price,
        user: payData?.orderer_name,
        orderId: payData?.order_number,
        email: payData?.orderer_email,
        phone: payData?.orderer_phone_number,
        taxFreeAmount: taxFreeAmount,
        escrowProducts: escrowProducts
    }

    useEffect(()=>{
        console.log('주문자 정보', payData);
        const newEscrowProducts = new Set(); // 중복을 허용하지 않는 Set 생성

        payData?.delivery_items.forEach(di => {
            console.log('durl : ',di);
            di?.order_items.forEach(oi => newEscrowProducts.add({
                id: oi?.id.toString(),
                name: oi?.product_name,
                code: oi?.product.toString(),
                unitPrice: oi?.total_price,
                quantity: 1
            }))
        });

        // Set을 다시 배열로 변환하여 state 업데이트
        setEscrowProducts(Array.from(newEscrowProducts));
    },[payData]);

    useEffect(()=>{
        data.escrowProducts = escrowProducts;
        console.log("escrowProducts(ep_) : ", escrowProducts);
    },[escrowProducts])

    return(
        <div className='m-0 p-2 border'>
            {taxFreeAmount}
            <Row className='m-0 p-2'>
                <Col className='m-0 p-0 fs-6 fw-bold text-start'>상품금액</Col>
                <Col className='m-0 p-0 fs-6 fw-bold text-end text-hw'>{payData?.original_total_price.toLocaleString('ko-KR')}원</Col>
            </Row>
            <Row className='m-0 p-2'>
                <Col className='m-0 p-0 fs-7 fw-bolder text-start'>배송비</Col>
                <Col className='m-0 p-0 fs-7 fw-bolder text-end'>(+){originalTotalDeliveryFee.toLocaleString('ko-KR')}원</Col>
            </Row>
            <Row className='m-0 p-2'>
                <Col className='m-0 p-0 fs-7 fw-bolder text-start'>쿠폰 할인금액</Col>
                <Col className='m-0 p-0 fs-7 fw-bolder text-end'>(-){discountAmount.toLocaleString('ko-KR')}원</Col>
            </Row>
            <Row className='m-0 p-2'>
                {
                    // 배송 쿠폰 적용 내용 표시
                    payData?.delivery_items.map((item) => (
                        item?.discount_price !== 0 && <Col xs={12} className='m-0 p-0 fs-7 fw-bolder text-end text-grey'>{item?.coupon_name} (-){item?.discount_price.toLocaleString('ko-KR')}원</Col>
                    ))
                }
                {
                    // 상품 쿠폰 적용 내용 표시
                    payData?.delivery_items.map((item) => (
                        item?.order_items.map((i)=> (
                            i?.discount_price !== 0 && <Col xs={12} className='m-0 p-0 fs-7 fw-bolder text-end text-grey'>{i?.coupon_name} (-){i?.discount_price.toLocaleString('ko-KR')}원</Col>
                        )))
                    )
                }
            </Row>
            <hr className='m-0 p-0 mt-2 mb-2'/>
            <Mileage  payData={payData} mileage={mileage} setUseMileage={setUseMileage} setOrderData={setOrderData}/>
            <hr className='m-0 p-0 mt-2 mb-2'/>
            <Row className='m-0 p-2'>
                <Col className='m-0 p-0 fs-5 fw-bold text-start'>결제금액</Col>
                <Col className='m-0 p-0 fs-5 fw-bold text-end text-hw'>{payData?.total_price.toLocaleString('ko-KR')}원</Col>
            </Row>
           
            <hr className='m-0 p-0 mt-2 mb-2'/>
            <Row className='m-0 p-2'>
                <Pg data={data} getPrice={getPrice}/>
                {/* <Col className='m-0 p-0 fs-7 fw-bolder text-center'>결제버튼</Col> */}
            </Row>
        </div>
    );
}


// 모달창(배송방식 선택)
function SelectModal({modalState, setModalState, deliveryItemId, setOrderData}){ 
    const selectItem = [{value: '선불',name: '선불 : 주문 시점에 택배비를 결제합니다.'}, {value: '착불',name: '착불 : 상품을 받을 때 직접 결제합니다.'}];

    const [selectedItem, setSelectedItem] = useState(''); //라디오버튼 값 지정
    const handleRadioChange = (e) => {
        // 값 변경 시 selectedItem 변경
        setSelectedItem(e?.target?.value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // 선택된 항목에 대한 처리
        console.log('선택된 항목 : ', selectedItem);
        instance.patch(`${BASE_URL}/order/delivery/type/${deliveryItemId}/`,{
            type : selectedItem
        })
            .then(res => setOrderData(res?.data))
            .catch(error => alert(error?.message))
            .finally(setModalState(false))
    };

    return(
        <>
            <Modal size={'md'} show={modalState} onHide={()=>setModalState(false)} aria-labelledby="sm-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title id='sm-modal' className='fs-6'>
                        배송 방식을 선택해주세요.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        {selectItem.map((item, index) => (
                            <Form.Check
                                key={index}
                                type='radio'
                                id={`radio-${index}`}
                                label={<span className='m-0 p-0 fs-7 text-grey fw-bold'>{item.name}</span>}
                                value={item?.value}
                                checked={selectedItem === item?.value}
                                onChange={handleRadioChange}
                                className='mt-2 mb-2'
                            />
                        ))}
                        <div className='d-grid gap-2 mt-4'>
                            <button type='submit' className='btn btn-primary' onClick={(e) => handleSubmit(e)}>선택</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}


