/***************************************************************************************
* FileName      : SummaryStats.js
* Description   : 해와관리자 > 통계 > 요약통계 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import Chart from '../../components/Chart'

import { useQuery } from "react-query";

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';

import { getVisit, getSignup , getSales } from '../../../api/hwadmin/statisticApi';

function SummaryStats()
{
    // 요약통계 > 방문자
    const { isLoading:VisitisLoading, error:VisitisError, data:VisitData } = useQuery(["statisticVisit",], () => getVisit());
    const { isLoading:SignupisLoading, error:SignupisError, data:SignupData } = useQuery(["statisticSignup",], () => getSignup());
    const { isLoading:SalesisLoading, error:SalesisError, data:SalesData } = useQuery(["statisticSales",], () => getSales());
   
    const [chartVisitData,setChartVisitData] = useState([]);
    const [chartSignupData,setChartSignupData] = useState([]);
    const [chartSalesData,setChartSalesData] = useState([]);


    const [isUpdated, setIsUpdated] = useState(false);

    console.log('SalesData' , SalesData);

    useEffect(()=>{
        if(!isUpdated){
            const newChartVisitData = {
                labels: VisitData?.labels, 
                datasets: [
                  {
                    label: '모바일(앱+웹)',
                    data: VisitData?.mobile_visiter,
                    backgroundColor: '#FBE7B4',
                    borderColor: '#F7CF6B',
                    pointBackgroundColor: '#FBE7B4',
                    pointBorderColor: '#F7CF6B',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },  {
                    label: 'PC',
                    data: VisitData?.pc_visiter,
                    backgroundColor: '#AACFF2',
                    borderColor: '#57A0E5',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },
                ],
            };
    
            setChartVisitData(newChartVisitData);
        }
     
        
    },[VisitData])

    
    useEffect(()=>{
        if(!isUpdated){
            const newChartSignupData = {
                labels: SignupData?.labels, 
                datasets: [
                  {
                    label: '목포해와',
                    data: SignupData?.목포해와,
                    backgroundColor: '#AACFF3',
                    borderColor: '#5EA3E6',
                    pointBackgroundColor: '#FBE7B4',
                    pointBorderColor: '#F7CF6B',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },  {
                    label: '네이버',
                    data: SignupData?.네이버,
                    backgroundColor: '#B6DEDE',
                    borderColor: '#73C1C2',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  }, {
                    label: '카카오',
                    data: SignupData?.카카오,
                    backgroundColor: '#FCE7B5',
                    borderColor: '#F8D171',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  }, {
                    label: '구글',
                    data: SignupData?.구글,
                    backgroundColor: '#E4E4E7',
                    borderColor: '#CCCED0',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },
                  
                ],
            };
    
            setChartSignupData(newChartSignupData);
        }
     
        
    },[SignupData])

    useEffect(()=>{
      if(!isUpdated){
          const newChartSignupData = {
              labels: SalesData?.labels, 
              datasets: [
                {
                  label: SalesData?.labels[0]+"월",
                  data: SalesData?.last_month,
                  backgroundColor: '#E4E4E7',
                  borderColor: '#CCCED0',
                  pointBackgroundColor: '#E4E4E7',
                  pointBorderColor: '#CCCED0',
                  pointHoverBackgroundColor: 'red',
                  pointHoverBorderColor: 'red',
                },  {
                  label:  SalesData?.labels[1]+"월",
                  data: SalesData?.this_month,
                  backgroundColor: '#F6B6C2',
                  borderColor: '#ED6D85',
                  pointBackgroundColor: '#F6B6C2',
                  pointBorderColor: '#ED6D85',
                  pointHoverBackgroundColor: 'red',
                  pointHoverBorderColor: 'red',
                }
                
              ],
          };
  
          setChartSalesData(newChartSignupData);
      }
   
      
    },[SalesData])



    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>요약통계</h2>
            </div>

            <p>방문자(명)</p>
            <section className='McontentBox'>
                <Chart data={chartVisitData}/>
            </section>

            <p>가입통계(명)</p>
            <section className='McontentBox'>
                <Chart data={chartSignupData}/>
            </section>

            <p>판매통계(명)</p>
            <section className='McontentBox'>
                <Chart data={chartSalesData}/>
            </section>

            </div>

        </>
    )
}

export default SummaryStats