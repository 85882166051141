import React from 'react';

function PageSizeSelect({ value, onChange }) {
  return (
    <select className='mSelectBox' value={value} onChange={onChange}>
      <option value="10">10개씩</option>
      <option value="50">50개씩</option>
      <option value="100">100개씩</option>
    </select>
  );
}

export default PageSizeSelect;