import sellerLogin from '../../api/login/sellerLogin';
import { useEffect, useState } from 'react';
import Join from './Join';

function SellerAdminLogin()
{
    const [nowState, setNowState] = useState('login');

    return (
        <> 
        <body id="Adminlogin">
            <section className={nowState === 'signup' ? "Adminlogin signup" : "Adminlogin"}>
                {nowState === 'login' ? <LoginContents setNowState={setNowState}/> : <Join/>}
            </section>
        </body>
        </>
    )

}

function LoginContents({setNowState}){
    const [username , setUsername] = useState('')
    const [password, setPassword] = useState('')
 
    const { isLoading, error, data, refetch } = sellerLogin(username, password);
 
    const login = sellerLogin();

    const handleSubmit = (event) =>{
        console.log({username, password })
        event.preventDefault();
    }

    return(
        <>
            <img src={process.env.PUBLIC_URL + '/img/Logo_00.png'} onClick={()=>window.location.href="/"} className='pointer' />
            <h1 onDoubleClick={()=>window.location.href="/admin/login"}>판매자 로그인</h1>
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <input type="text"
                        placeholder="아이디를 입력해주세요"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                </div>
                
                <div className="input-field">
                    <input type="password" 
                            placeholder="비밀번호를 입력해주세요"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            />
                </div>

                <div className="button-field">
                    <button className='login-button' type="submit" onClick={()=>{login.mutate({ username, password })}}>로그인</button>
                </div>

                <div className="add-info">
                    <a href="#">아이디 찾기</a>
                    <a href="#">비밀번호 찾기</a>
                </div>

                <span className="or-text">판매자가 아니신가요?</span>

                <div className="button-field">
                    <button className='signup-button' onClick={()=>setNowState("signup")}>판매자 회원가입</button>
                </div>
            </form>
        </>
    )
}

export default SellerAdminLogin