/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 해와관리자 > 정산 > 미지급내역 > AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

export const AdjustRow = ({ Adjust }) => {
  
  const navigate = useNavigate();

  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/store/inquiry/?search=${searchQuery}`;
    navigate(newUrl);
  };

  return (
    <>
      <>
        <tr>
          <td> <input type="checkbox" /> </td>
          <td>{Adjust.order_number ? Adjust.order_number : "-"}</td>
      
          <td className='blueLink' onClick={() => handleCellClick(Adjust?.vendor)}>
            {Adjust?.vendor}
          </td>

          <td>{extractYearMonthDayHourMinute2(Adjust.purchase_confirmation_date)}</td>
          <td>{Adjust.product_name}</td>
          <td>{Adjust.pay_amount.toLocaleString()}원</td>
          <td>
            {Adjust.orderer_username}<br/>
            {Adjust.orderer_name}<br/>
          </td>
          <td>
            {Adjust.recipient_name}<br/>
            {Adjust.recipient_phone_number}<br/>
          </td>
          <td>{Adjust.address}</td>

         </tr>
      </>
    </>
  );
};
