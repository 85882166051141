import axios from 'axios';
import { BASE_URL } from '../api/apiConfig';
import { getAccessFromCookie, setCookie } from '../api/cookieUtils';

// 공통 axios 헤더 인증 토큰 설정
const access = getAccessFromCookie();

let headers = {};
const userHeaders = {
  'Authorization': `Bearer ${access}`,
  'Content-Type': 'application/json',
};
const guestHeaders = {
  'Content-Type': 'application/json',
}

access ? headers = userHeaders : headers = guestHeaders;

//공통 axios instance
export const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: headers
});

// 공통 Axios 헤더 인증 토큰 설정
const updateAxiosHeaders = (token) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } 
};

// 공통 axios instance 초기화
export const initializeAxiosInstance = () => {
  const token = getAccessFromCookie();
  updateAxiosHeaders(token);
};

initializeAxiosInstance();

// [jdu] 응답 인터셉터
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// [pkh] 요청 인터셉터
instance.interceptors.request.use(
  (config) => {
    if(!config.headers) return config;
    const accesstoken = getAccessFromCookie();

    if(accesstoken && config.headers) {
      config.headers["Authorization"] = `Bearer ${accesstoken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// [jdu] 응답 인터셉터
instance.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject});
        }).then(token => {
          updateAxiosHeaders(token);
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return instance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        instance.post('/auth/refresh/')
          .then(res => {
            if (res?.status === 200) {
              if (process.env.REACT_APP_ENV_NAME === 'dev') {
                setCookie('access', res.data.access);
                setCookie('refresh', res.data.refresh);
              }

              const newToken = getAccessFromCookie();

              updateAxiosHeaders(newToken);

              originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
              processQueue(null, newToken);
              resolve(instance(originalRequest));
            }
          })
          .catch(err => {
            processQueue(err, null);
            window.location.href = '/member/login';
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);


// [hm] 새로운 axios instance 설정 - 이미지 업로드용
export const imageUploadInstance = axios.create({
	baseURL: `${BASE_URL}/`, // 이미지 업로드 API의 URL
	headers: {
		'Authorization': `Bearer ${access}`,
		'Content-Type': 'multipart/form-data', // 이미지 업로드 시에는 multipart/form-data로 설정
	},
});