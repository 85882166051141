/***************************************************************************************
* FileName      : SearchField.js
* Description   : 해와관리자 > 통계 > 상품통계 > 조회기준 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from 'react';

const SearchField = ({ selectedPayment, handleCategoryTypeRadioChange }) => {
    return (
        <div className='search-field division'>
            <div>
                <b>조회기준</b>
            </div>
            <div className='SearchRadio'>
                <input
                    type='radio'
                    id='g1'
                    name='group1'
                    value='판매금액(원)'
                    checked={selectedPayment === '판매금액(원)'}
                    onChange={handleCategoryTypeRadioChange}
                />
                <label htmlFor='g1'>판매금액(원)</label>

                <input
                    type='radio'
                    id='g2'
                    name='group1'
                    value='판매건수(건)'
                    checked={selectedPayment === '판매건수(건)'}
                    onChange={handleCategoryTypeRadioChange}
                />
                <label htmlFor='g2'>판매건수(건)</label>

                <input
                    type='radio'
                    id='g3'
                    name='group1'
                    value='승인된 상품(건)'
                    checked={selectedPayment === '승인된 상품(건)'}
                    onChange={handleCategoryTypeRadioChange}
                />
                <label htmlFor='g3'>승인된 상품(건)</label>

                <input
                    type='radio'
                    id='g4'
                    name='group1'
                    value='판매중 상품(건)'
                    checked={selectedPayment === '판매중 상품(건)'}
                    onChange={handleCategoryTypeRadioChange}
                />
                <label htmlFor='g4'>판매중 상품(건)</label>
            </div>
        </div>
    );
};

export default SearchField;
