import { instance } from '../axiosInstance/axiosConfig.js';

export const getTerms = () => {
    return instance.get('/policy/이용약관/')
}

export const getPrivacy = () => {
    return instance.get('/policy/개인정보처리방침/')
}

export const getCancellation = () => {
    return instance.get('/policy/교환환불정책/')
}

export const patchPolicyTerms = (content) => {
    return instance.patch('/policy/이용약관/',{
        content: content
    })
}

export const patchPrivacy = (content) => {
    return instance.patch('/policy/개인정보처리방침/',{
        content: content
    })
}

export const patchCancellation = (content) => {
    return instance.patch('/policy/교환환불정책/',{
        content: content
    })
}