import { instance } from '../axiosInstance/axiosConfig.js';

// [hm] 마이페이지 내 주문정보 조회
export const getMyOrderList = () => {
    return instance.get(`/order/my/`)
}

// [hm] 마이페이지 내 반품교환취소 조회
export const getMyAbort = () => {
    return instance.get(`/order/abort/`)
}

// 로그인 여부확인하는 친구 (임시)
export const getLoginUser = () => {
    return instance.get(`/auth/me/`)
}

export const getClientKey = () => {
    return instance.get(`/auth/ck/`)
}