// 검색 > 기간 > 일
const DaySelect = ({ value, onChange }) => (
    <select className='mSelectBox mr6' value={value} onChange={onChange}>
        {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
            <option key={day} value={day.toString()}>
            {day}일
            </option>
        ))}
    </select>
);

export default DaySelect