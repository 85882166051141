import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';

import { useEffect, useState } from 'react';
// import Modal from 'react-modal';

function Join()
{

    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 상세주소
    const [isOpenPost, setIsOpenPost] = useState(false);
    const postCodeStyle = {
        display: 'block',
        position: 'relative',
        top: '0%',
        width: '400px',
        height: '400px',
        padding: '7px',
      };
    
    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
      };
    
      const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = '';
    
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddr += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
          }
          fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
    
        setAddress(data.zonecode);
        setAddressDetail(fullAddr);
        setIsOpenPost(false);
      };

      
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const ModalStyle = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.45)",
            zIndex: 10,
        },
        content: {
            display: "flex",
            justifyContent: "center",
            background: "#ffffe7",
            overflow: "auto",
            top: "42vh",
            left: "38vw",
            right: "38vw",
            bottom: "42vh",
            WebkitOverflowScrolling: "touch",
            borderRadius: "14px",
            outline: "none",
            zIndex: 10,
        },
    };

    return(
        <>
            <Container>
         
            {/* <button onClick={()=> setIsOpenPost(!isOpenPost)}>Modal Open</button> */}

{/*           
                     {isOpenPost  ? (
                        <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompletePost } />
                    ) : null}
 */}

                    <div className='sub_content'>
                        <div className="join_wrap">
                            <div className="titleArea">
                                <h2>회원가입</h2>
                                <p>
                                    기본정보
                                    <span>
                                        <i></i>
                                        필수입력사항
                                    </span>
                                </p>
                            </div>
                            <div className='join-table'>
                                <table> 
                                    <colgroup>
                                        <col width="25%" />
                                        <col width="*" />
                                    </colgroup>
                                    <tbody>
                                        <tr className="id">
                                            <th>아이디<i></i></th>
                                            <td>
                                                <input id="member_id" name="member_id" type="text"/>
                                                <span id="idMsg" className='error'>아이디를 입력해 주세요.</span>
                                            </td>
                                        </tr> 
                                        <tr className="pw">
                                            <th>비밀번호<i></i></th>
                                            <td>
                                                <input id="passwd"
                                                name="passwd"
                                                type="password"
                                           
                                                />
                                            </td>
                                        </tr> 
                                        <tr className="pwc1">
                                            <th>비밀번호 확인<i></i></th>
                                            <td>
                                                <input id="passwd" name="passwd" type="password"/>
                                                <span id="pwConfirmMsg" className='error'>
                                                비밀번호가 일치하지 않습니다.
                                                </span>
                                            </td>
                                        </tr> 
                                        <tr className="name">
                                            <th>이름<i></i></th>
                                            <td>
                                                <input id="name" name="name" type="text"/>
                                                
                                            </td>
                                        </tr>
                                        <tr className="name">
                                            <th>주소<i></i></th>
                                            <td>
                                                <ul className="ec-address">
                                                    <li className="join_zipcode_wrap">
                                                        <input id="postcode1" name="postcode1" type="text"
                                                        placeholder='우편번호' maxlength="14"
                                                        />
                                                        
                                                       
                                                    </li>
                                                </ul>
                                                <input id="name" name="name" type="text"/>   
                                                
                                            
                                            </td>
                                        </tr>
                                        <tr className="call">
                                            <th>일반전화</th>
                                            <td>
                                                <input id="call" name="call" type="text" placeholder='- 없이 입력하세요.'/>
                                            </td>
                                        </tr>
                                        <tr className="email">
                                            <th>이메일<i></i></th>
                                            <td>
                                                <input id="email" name="email" type="text"/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 class="jaddt">추가정보</h3>
                            <div className='plus join-table'>
                                <table> 
                                    <colgroup>
                                        <col width="25%" />
                                        <col width="*" />
                                    </colgroup>
           
                                    <tbody>
                                        <tr className="birth">
                                            <th>생년월일<i></i></th>
                                            <td>
                                                <input id="birth_year" name="birth_year" type="text"/>
                                                <span>년</span>
                                                <input id="birth_month" name="birth_month" type="text"/>
                                                <span>월</span>
                                                <input id="birth_day" name="birth_day" type="text"/>
                                                <span>일</span>
                                            </td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>
                            <div className='join-agreeArea chkall'>
                               <input type="checkbox" id="agree-chk" />
                                <label for="agree-chk">
                                    <em></em>전체 약관에 동의합니다.
                                </label>
                            </div>

                            <div className='join-agreeArea chk1'>
                               <input type="checkbox" id="agree-chk" />
                                <label for="agree-chk">
                                    <em></em>(필수) 이용약관 동의
                                </label>
                                <span class="morebtn">내용보기</span>
                            </div>

                            <div className='join-agreeArea chk2'>
                               <input type="checkbox" id="agree-chk2" />
                                <label for="agree-chk">
                                    <em></em>(필수) 개인정보 수집 및 이용동의
                                </label>
                                <span class="morebtn">내용보기</span>
                            </div>
                            <div className='base-button'>
                                <a>회원가입</a>
                            </div>
                        </div>
                      
                    </div>
            </Container>
        </>
    )


}

export default Join