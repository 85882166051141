import React from 'react';
import ReactDOM from 'react-dom/client';
import ScrollTop from './ScrollTop';
import './index.css';
import './css/modal.css';
import './css/variables.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';


import { BrowserRouter } from 'react-router-dom';

const client = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={client}>
      <BrowserRouter>
      <ScrollTop/>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
