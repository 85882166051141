import { useMainCategories } from '../../../../../api/Category'
import { useQueryClient } from 'react-query';
import { useQuery } from 'react-query';
import MainCategories from '../Category/MainCategories';
import { useEffect } from 'react';

function SelectCategory({categoryId_arr,setCategoryIdArr})
{
    // 카테고리 선택
    const queryClient = useQueryClient();
    
    // 카테고리 이름 상태 관리
    const { data: selectedMaincategory } = useQuery('selectedMaincategory');
    const { data: selectedSubcategory } = useQuery('selectedSubcategory');
    const { data: selectedSubSubcategory } = useQuery('selectedSubSubcategory');

    const { data: selectedMainIdcategory } = useQuery('selectedMainIdcategory');
    const { data: selectedSubIdcategory } = useQuery('selectedSubIdcategory');
    const { data: selectedSubSubIdcategory } = useQuery('selectedSubSubIdcategory');

    const selectedcategory = [];

    useEffect(()=>{
        // selectedcategory = []; 
        selectedcategory.push(selectedMainIdcategory)
        if(selectedSubIdcategory) selectedcategory.push(selectedSubIdcategory)
        if(selectedSubSubIdcategory) selectedcategory.push(selectedSubSubIdcategory)

        setCategoryIdArr(selectedcategory)

    },[selectedMainIdcategory,selectedSubIdcategory,selectedSubSubIdcategory])

    return (
        <>
        <p className="notice-text">
            상품 데이터를 복사할 시 카테고리 선택을 다시해야 합니다.
        </p>
        <div className='categbox'>
            <MainCategories />
        </div>

        {selectedSubSubcategory && (
            <div className='select-categorys'>
                <span className=''>선택한 카테고리:</span>
                <p className='selectCategoryText'>
                {selectedMaincategory}
                    {selectedSubcategory ? ' > ' :''}
                    {selectedSubcategory}
                    {selectedSubSubcategory ? ' > ' :''}
                    {selectedSubSubcategory}
                </p>
            </div>
        )}

    
  
     
    </>
    )
}

export default SelectCategory