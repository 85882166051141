/***************************************************************************************
* FileName      : Detail2.js
* Description   : 고객 > 상품 상세페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 2023.12.19 : CHM : 판매자 정보 클릭 시 판매자 상품리스트 페이지로 이동
*               : 2023.12.20 : CHM : 상품 like 버튼이 상품명과 겹치는 문제 수정
*               : 2024.01.07 : PKH : 배송사 , 용도 데이터 출력 추가
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container, Tab , InputGroup, Form, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { instance } from '../../axiosInstance/axiosConfig.js';
import { fetchProductDetails } from '../../api/services/productApi.js';

// 장바구니 
import { useCartProductRegistration } from '../../api/services/cartApi.js';

// 바로구매
import { postPurcahse } from '../../api/services/purchaseApi.js';

//Bootstrap Icon
import { AiOutlineHeart, AiOutlineShareAlt, AiFillHeart,  } from 'react-icons/ai';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';

// ProductItem
import ProductItem from './Detail/ProductItem.js';

//ProductOptionItem
import OptionItem from './Detail/OptionItem.js';

// 상품 Q&A
import ProductInquiry from './Detail/ProductInquiry.js'

// 상품 리뷰 리스트 조회
import ProductReview from './Detail/ProductReview.js'

import { DatasetController } from 'chart.js';
import SingleProduct from './Detail/SingleProduct.js';

// 상품 배송정보
import ProductDeliveryInfo from './Detail/ProductDeliveryInfo.js';
// 상품 교환환불정보 
import ReturnExchangeInfo from './Detail/ReturnExchangeInfo.js';

// 검수정보
import ProductInspectInfo from './Detail/ProductInspectInfo.js';
import ProductInfo from './Detail/ProductInfo.js';

// 찜
import ProductLikeButton from '../components/ProductLikeButton.js';
import VendorLikeButton from '../components/VendorLikeButton.js';

export default function Detail2()
{
    const navigate = useNavigate();

    // 옵션 총 합산 금액
    const [optionTotalPrice, setOptionTotalPrice] = useState(0);
    // 추가옵션 총 합산 금액
    const [extraOptionTotalPrice, setExtraOptionTotalPrice] = useState(0);

    // 매개변수(id)를 가져옴
    const { id } = useParams();

    const [cookies, setCookie] = useCookies(['cartId']);
    const { post, isPostLoading, registrationError } = useCartProductRegistration();

    const [productDetails, setProductDetails] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [options , setOptions ] = useState();

    const [extraOptions , setExtraOptions ] = useState();

    const [addExtraOptions , setAddExtraOptions ] = useState();

    // 단일상품 확인
    const [singleProduct , setSingleProduct ] = useState(false);

    // 단일상품 수량 , 가격
    const [singleProductItem, setSingleProductItem] = useState({
        quantity: 1,
        price: 0,
    });


    const postPurchase = ( data) => {
        // 임시 post 데이터
        instance.post('order/purchase/',data)
            .then(res => console.log(res.data))
            .then(navigate('/order/order'))
            .catch(error => console.log(error))
    }


    useEffect(() => {
        fetchProductDetails(id)
          .then(details => {
            setProductDetails(details);
            console.log("상세데이터")
            console.log(details)

            // const names = details.option_rows.map((row) => row.name);
            // 상품 옵션 
            setOptions(details.option_rows);
           
            // 추가상품 
            // const extra_option = details.extra_options.map((row) => row.name);
            setExtraOptions(details.extra_options);
 
            // console.log("추가상품")
            // console.log(extraOptions)

            // 단일 상품 인지 확인

            if(details.option_rows.length === 0)
            {
                console.log("단품")
                setSingleProductItem({
                    quantity: 1,
                    price: details.price
                })

                setSingleProduct(true)
            }
          })
          .catch(error => {
            console.error('상품 상세 조회 오류:', error);
          });
    }, [id]);
    
    // 선택한 상품 옵션
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [cart_Options, setCartOptions] = useState([]);


    // 선택한 추가 옵션 
    const [selectedExtraOptions, setSelectedExtraOptions] = useState([]);
    const [cart_ExtraOptions, setCartExtraOptions] = useState([]);


    let [userLike, setUserLike] = useState(false);
    let [optionCounter, setOptionCounter] = useState(1);
    let [tab, setTab] = useState(0);

    // 
    let dataset = {};

    if (productDetails) {
        console.log(productDetails)
        console.log("서브이미지")
        console.log(productDetails.sub_images)
        console.log(productDetails.vendor.is_liked)
        dataset = {
            id : productDetails.id || '',
            name: productDetails.name || '',
            product_detail:productDetails.detail || '',
            product_name: productDetails.name || '',
            is_liked : productDetails.is_liked || '',
            product_price: productDetails.price || '',
            product_origin_price: productDetails.original_price || '',
            product_discount_percent: productDetails.sale_percent || '',
            seller: productDetails.vendor?.name || '',
            seller_id: productDetails.vendor?.id || '',
            option_rows: productDetails.option_rows || '',
        //delivery_info: productDetails.delivery_info || '' ,

            
    
        product_shipping_cost: (productDetails && productDetails.delivery_info && 
                                productDetails.delivery_info.shipping_cost) || '',

          free_condition: (productDetails && productDetails.delivery_info && 
                            productDetails.delivery_info.free_condition ) || '' ,
          delivery_free : (productDetails && productDetails.return_exchange_info &&
                            productDetails.return_exchange_info.initial_delivery_fee ) || '',

          delivery_company: 'CJ대한통운',
          main_image: productDetails.main_image || '',
          sub_images: productDetails.sub_images || [],

          vendor_is_liked : productDetails.vendor.is_liked
        };
    }else{
        dataset = {};
    }
    
    // const [selectedExtraOptions, setSelectedExtraOptions] = useState([
    //     {
    //         id:"",
    //         name: "",
    //         price: "0",
    //         quantity: 1,
    //     },
    // ]);

    
    useEffect(() => {
        console.log("selectedExtraOptions changed:", selectedExtraOptions);
    // selectedExtraOptions의 데이터에서 id와 quantity를 추출하여 cart_ExtraOptions에 저장
        const extractedOptions = selectedExtraOptions.map(option => ({
            extra_option: option.id,
            quantity: option.quantity,
        }));
  
        setCartExtraOptions(extractedOptions);
        console.log("보낼",cart_ExtraOptions)

        setExtraOptionTotalPrice(selectedExtraOptions.reduce((acc, option) => acc + option.price, 0));
      }, [selectedExtraOptions]);

    // 사용자가 선택한 옵션상품 저장
    useEffect(()=>{
        console.log("selectedOptions....changed",selectedOptions)
        const prdOptions = selectedOptions.map(option => ({
            option_row: option.id,
            quantity: option.quantity,
        }));

        setCartOptions(prdOptions);
        console.log("보낼",cart_Options)

        setOptionTotalPrice(selectedOptions.reduce((acc, option) => acc + option.price, 0)); //hm 수정중

    },[selectedOptions])

    



    const handleOptionChange = (event) =>{
        const optionName = event.target.value;
        const selectedOption = options.find((option) => option.name === optionName);

        // 이미 선택한 옵션(name)인 경우 수량만 추가해줍니다.
        const isOptionSelected = selectedOptions.some(
                (option) => option.name === selectedOption.name
        );

        if (isOptionSelected) {
            alert(" 이미 선택한 추가 상품입니다.")
            // setSelectedExtraOptions((prevOptions) =>
            //   prevOptions.map((option) =>
            //     option.name === selectedOption.name
            //       ? { ...option, quantity: option.quantity + 1 }
            //       : option
            //   )
            // );
        } else {
            setSelectedOptions((prevOptions) => [
                ...prevOptions,
                {
                id : selectedOption.id,
                name: selectedOption.name,
                price: selectedOption.price,
                original_price: selectedOption.price,
                quantity: 1,
                },
            ]);
        }

        console.log("상품옵션클릭함")
        console.log("selected Options : "+selectedOptions);

    }

    // 추가옵션 선택 시 
    const handleExtraOptionChange = (event) => {
        const optionName = event.target.value;
        const selectedOption = extraOptions.find((option) => option.name === optionName);
    
        // 이미 선택한 옵션(name)인 경우 수량만 추가해줍니다.
        const isOptionSelected = selectedExtraOptions.some(
            (option) => option.name === selectedOption.name
        );
   
        if (isOptionSelected) {
            alert(" 이미 선택한 추가 상품입니다.")
            // setSelectedExtraOptions((prevOptions) =>
            //   prevOptions.map((option) =>
            //     option.name === selectedOption.name
            //       ? { ...option, quantity: option.quantity + 1 }
            //       : option
            //   )
            // );
          } else {
            // 새로운 옵션을 배열에 추가합니다.
            setSelectedExtraOptions((prevOptions) => [
              ...prevOptions,
              {
                id : selectedOption.id,
                name: selectedOption.name,
                price: selectedOption.price,
                original_price: selectedOption.price,
                quantity: 1,
              },
            ]);
        }


        console.log("추가옵션클릭함");
        console.log(selectedExtraOptions);
    };

    
    // 상품 메인이미지 
    const [mainImage, setMainImage] = useState(dataset.main_image);

    const handleSubImageHover = (subImagePath) => {
        setMainImage(subImagePath);
    };
    
    console.log("서브",dataset.main_image)

    // 장바구니 추가 api
    const addToCart = () => {
        // 선택된 상세 데이터
        // alert(id)
        console.log("장바구니ㅔ-------- ")
        console.log(cart_Options)
        // cart_Options가 비어있는지 확인하는 함수
        const isCartOptionsEmpty = cart_Options.length === 0;
        const isCartExtraOptionsEmpty = cart_ExtraOptions.length === 0;

        console.log("단품인가",singleProduct)
        if(singleProduct)
        if(!singleProduct && isCartOptionsEmpty){
            alert("옵션을 선택해 주세요.")
            return;
        }

        // if(isCartOptionsEmpty){
        //     alert("옵션을 선택해 주세요.")
        //     return;
        // }else if(!isCartExtraOptionsEmpty){
        //     alert("추가상품만 구매는 불가능합니다");
        //     return;
        // }

        // const detailData = {
        //   product: id,
        //   cartitem_option_rows:cart_Options,
        //   quantity: 1,
        //   cartitem_extra_options:cart_ExtraOptions,
      
        // };

        let detailData;

        if(singleProduct){
            detailData = {
                product: id,
                // quantity: 1,
                cartitem_option_rows:[
                    {
                        "quantity": singleProductItem.quantity
                    }
                ],
                cartitem_extra_options:cart_ExtraOptions,
                // force:true
              };
        }else{
            detailData = {
                product: id,
                cartitem_option_rows:cart_Options,
                quantity: 1,
                cartitem_extra_options:cart_ExtraOptions,
            };
        }
        console.log(detailData)
        post(detailData)
        .then((response) => {
            console.log(response)
            
            // alert("성공")
            const result = window.confirm('장바구니에 상품을 담았습니다. \n장바구니로 이동하시겠습니까?');
            if (result) {
                window.location.href = '/member/Cart/'; // 장바구니로 이동
            } 
            
        })
        .catch(error => {
            console.log("등록 실패:", error);
        });

    };


    // 바로구매 api
    const purchasing = () => {
        // 선택된 옵션이 있는지 확인함
        const isCartOptionsEmpty = cart_Options.length === 0;
        const isCartExtraOptionsEmpty = cart_ExtraOptions.length === 0;

        if(!singleProduct && isCartOptionsEmpty){
            // 선택된 옵션이 없으면 경고창
            alert("옵션을 선택해 주세요.")
            return;
        }else if(singleProduct){
            let cart_Options = [{quantity: singleProductItem.quantity}]
            postPurcahse(id, cart_Options, cart_ExtraOptions)
                .then((res) => {
                    console.log(res.data);
                    navigate('/order/order');
                })
                .catch(error => {
                    alert(error?.response?.data?.data);
                    if(error?.response?.status === 401){
                        window.location.href='/member/login';
                    };
                })
        }else{
            postPurcahse(id, cart_Options, cart_ExtraOptions)
                .then((res) => {
                    console.log(res.data);
                    navigate('/order/order');
                })
                .catch(error => {
                    alert(error.response.data.data);
                })
        }
    }


    return(
        <>
        <Container className='ps-3 pe-3'>
            <div className='product-headcategory pt-3 pb-3'>
                <ol>
                    <li><a className='pointer hover-hw' onClick={()=>navigate('/product/list?category=전체')}>전체상품</a></li>
                    <li><a className='pointer hover-hw' onClick={()=>navigate(`/product/list?category=${productDetails?.category[0]?.name}`)}>{productDetails?.category[0]?.name}</a></li>
                    <li><a className='pointer hover-hw' onClick={()=>navigate(`/product/list?category=${productDetails?.category[1]?.name}`)}>{productDetails?.category[1]?.name}</a></li>
                    <li><a className='not-allowed'>{productDetails?.category[2]?.name}</a></li>
                </ol>
            </div>
            <Row>
                <Col xs={12} md={6}>
                    <div className='product-image'>
                        {mainImage ? (
                            <img src={mainImage} alt="Product" />
                        ) : (
                            <img src={dataset?.main_image} alt="Product" />
                        )}
                    </div>

                    <div>
                        <ul className='product-addimage listImg mb-3'>
                            {dataset.sub_images && dataset.sub_images.map((subImage, index) => (
                                <li key={index} className="pointer" onMouseEnter={() => handleSubImageHover(subImage.path)}>
                                    <img src={subImage.path} alt="Sub Image" />
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>
    
                <Col xs={12} md={6}>
                    <div className='infoArea'>
                        <div className='title'>
                            {/* 
                            <div className='vendor-info-area'>
                                <p className="fs-6 text-hw fw-bold pointer"  onClick={()=>navigate(`/product/vendor/items?name=${productDetails?.vendor?.name}&id=${productDetails?.vendor?.id}`)}>{dataset.seller}</p>
                                <VendorLikeButton 
                                    vendorId={dataset.seller_id} 
                                    is_liked={dataset.is_liked}/>
                            </div>
                            */}
                         
                            <div className='d-flex justify-content-between align-center'>
                                <h3>
                                    <span>{dataset.name}</span>
                                </h3>
                        
                                <ProductLikeButton 
                                        productId={dataset.id}
                                        is_liked={dataset.is_liked}/>
                            </div>
                           
                        </div>

                        {/* 가격 및 판매량, 평점 영역 */}
                        <div>
                            <Row className='mt-3'>
                                <Col xs="4">
                                    <span className='fs-3 text-hw fw-bold me-1'>{dataset?.product_discount_percent || 0}%</span>
                                </Col>
                                <Col xs="8" className='text-end'>
                                    <span className='fs-6 fw-bold text-secondary text-decoration-line-through pe-2'>
                                        {dataset && dataset.product_origin_price && dataset.product_origin_price.toLocaleString()}원
                                    </span>
                                    <span className='fs-6 fw-bold'><span className="fs-4 fw-bold">
                                        {dataset && dataset.product_price && dataset.product_price.toLocaleString('ko-KR')} 
                                    </span>원</span>
                                </Col>
                            </Row>
                            <Row className='m-0 mt-3 p-3 bg-hw-light'>
                                <Col xs="8" className="fw-bold">최근 <span className='text-hw fw-bold'>{productDetails?.recent_sale_count}개</span> 이상 판매!</Col>
                                <Col xs="4" className='text-hw fw-bold text-end'><BsStarFill className='fs-7'/>{productDetails?.rating_average === 0 ? ' - ' : productDetails?.rating_average}</Col>
                            </Row>
                        </div>

                        {/* 상품 기본정보 영역 */}
                        <div className='product-detail-t fs-6'>
                            <hr/>
                            <Row className='mt-2'>
                                <Col md={3} className='fw-bold'>상품명</Col>
                                <Col md={9} className='fw-bold'>{dataset.name}</Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={3} className='fw-bold'>혜택</Col>
                                <Col md={9} className='fw-bold'>29 마일리지 적립</Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={3} className='fw-bold'>배송</Col>
                                <Col md={9} className=''>
                                    <p>
                                        <span className='fw-bold'>
                                            {dataset.product_shipping_cost !== undefined && parseInt(dataset.product_shipping_cost) === 0 || dataset.product_shipping_cost === '' ? 
                                            "무료배송" : `${parseInt(dataset.product_shipping_cost).toLocaleString()}원`}
                                        </span>
                    
                                        {/* 무료배송 조건 표시 */}
                                        {dataset.free_condition && (
                                        <span>
                                            ({parseInt(dataset.free_condition).toLocaleString()} 이상 구매 시 무료배송)
                                        </span>
                                        )}

                                    </p>
                                    <p className='text-grey fs-7'>제주/도서산간 지역 배송 불가</p>
                                    {/* <hr className='mt-1 mb-1'/> */}
                                    <p className='fs-7 text-hw fw-bold'>출고일 : {productDetails?.delivery_info?.days_required_for_shipping}</p>
                                    {/* <p className="fs-7">{productDetails?.delivery_info?.courier}</p> */}
                                </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={3} className='fw-bold'>배송사</Col>
                            <Col md={9} className='f-g-5'>
                                <p className="fs-7">{productDetails?.delivery_info?.courier}</p>

                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={3} className='fw-bold'>용도</Col>
                            <Col md={9} className='f-g-5'>
                                {productDetails?.product_filter.map((product, index) => (
                                <p className="fs-7">
                                        {product.name}
                                    </p>
                                ))}

                            </Col>
                        </Row>

                            {/* 판매자 정보 */}
                            <Row className="m-0 mt-2 p-3 border">
                                {/* <Col xs={3} className="m-0 p-1"><img src={process.env.PUBLIC_URL + '/img/p_1.jpg'} width="100%" /></Col> */}
                                <Col xs={3} className="d-flex m-0 p-1 align-items-center pointer">
                                    <img src={productDetails?.vendor?.owner_image} width="100%" onClick={()=>navigate(`/product/vendor/items?name=${productDetails?.vendor?.name}&id=${productDetails?.vendor?.id}`)}/>
                                </Col>
                                <Col xs={9} className='d-flex flex-column justify-content-center'>
                                    <div>
                                        <Row className='m-0 p-0'>
                                            <Col className='vendor-info-area p-0'>
                                                <p className="fs-6 text-hw fw-bold pointer p-0"  onClick={()=>navigate(`/product/vendor/items?name=${productDetails?.vendor?.name}&id=${productDetails?.vendor?.id}`)}>{dataset.seller}</p>
                                                <VendorLikeButton 
                                                    vendorId={dataset.seller_id} 
                                                    is_liked={dataset.vendor_is_liked}/>
                                            </Col>
                                            <Col xs="12" className='p-0 fs-8'>
                                                <span>
                                                    통신판매업 신고 : {productDetails?.vendor?.sales_registration_number}
                                                </span>
                                            </Col>
                                            <Col xs="12" className='p-0 fs-8'>
                                                <span>
                                                    사업자 등록번호 : {productDetails?.vendor?.business_number}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className='m-0 mb-2 p-0 fs-8'>
                                            <Col className='m-0 p-0 text-truncate'>
                                                <span>
                                                {productDetails?.maker}상품에 대한 문의는 {productDetails?.vendor?.tel} 번호로 연락주세요.
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className='m-0 p-0'>
                                            <Col className="fs-7 p-0">
                                                <span>
                                                    대표자 : {productDetails?.vendor?.owner?.name}
                                                </span>
                                        
                                            </Col>
                                            {/* <Col xs="auto" className="fs-8 border ps-2 pe-2 m-0 ">상품 보러가기 +</Col> */}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                           
                            <hr/>

                            {/* 셀렉트박스 */}
                            {/* <Row>
                                <Col>
                                    <select className="ps-3 pe-3 mt-1 pd-select opt-this-goods">
                                        <option selected>상품선택</option>
                                        <option value="">가리비 2kg (대) (+2,000원 x 개)</option>
                                        <option value="">가리비 2kg (중~소) (+0원 x 개)</option>
                                    </select>

                                    <select className="ps-3 pe-3 mt-1 pd-select opt-this-goods">
                                        <option selected>상품선택</option>
                                            {optionNames.map((name, index) => (
                                                <option key={index} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                    </select>
                                        
                                </Col>
                            </Row> */}

                            {/* 상품 옵션 */}
                            {options && options.length > 0 && (
                            <Row>
                                <Col>
                                    <select 
                                        className="ps-3 pe-3 mt-1 pd-select opt-this-goods"
                                        onChange={handleOptionChange}
                                    >
                                        <option value="" selected>상품 옵션 선택</option>
                                        {options.map((option)=> (
                                            <option key={option.id} value={option.name}>
                                                {option.name} (+{parseInt(option.diff).toLocaleString()})
                                            </option>
                                        ))}
                                        {/* {options.length == 0 ? <option key={{id:0}} value={'기본옵션'}>기본옵션상품</option> : null} */}
                                    </select>
                                    {/* {option.price} */}
                                        
                                </Col>
                            </Row>
                            )}

                            {/* 추가상품 */}
                            {extraOptions && extraOptions.length > 0 && (
                            <Row>
                                <Col>
                                    <select 
                                        className="ps-3 pe-3 mt-1 pd-select opt-this-goods"
                                        // value={selectedExtraOptions.name}
                                        onChange={handleExtraOptionChange}  
                                        >
                                        <option value="" selected>추가상품 선택</option>

                                        {extraOptions.map((extraOption) => (
                                            <option key={extraOption.id} value={extraOption.name}>
                                                {extraOption.name} (+{parseInt(extraOption.price).toLocaleString()})
                                            </option>
                                        ))}
                                    </select>
            
                                </Col>
                  
                            </Row>
                            )}

                        {/*  선택한 상품 옵션 들을 표시하고 관리합니다. */}
                        {selectedOptions.map((option, index) => {
                            return (
                                <div key={option.id}>
                                    <OptionItem
                                        option={option}
                                        setSelectedOptions={setSelectedOptions}
                                    />
                                </div>
                            );
                        })}

                        {/*  선택한 추가상품들을 표시하고 관리합니다. */}
                        {selectedExtraOptions.map((extraOption, index) => {
                            return (
                                <div key={extraOption.id}>
                                    <ProductItem
                                        extraOption={extraOption}
                                        setSelectedExtraOptions={setSelectedExtraOptions}
                                    />
                                </div>
                            );
                        })}


                        {/* 단독상품일 때 + -  */}
                        {singleProduct ? 
                            <SingleProduct
                                singleProductItem={singleProductItem}
                                setSingleProductItem={setSingleProductItem}
                            />
                            : null 
                        }
                            

                            {/* <hr/> */}

                            {/* 총 상품금액 및 구매버튼 */}
                            <Row className='mt-4 mb-3'>
                                {singleProduct ? 
                                <>
                                </>:null
                                }
                                <Col className='text-end fs-7'>
                                    총 상품금액
                                    <span className='fs-4 fw-bold'> 
                                        {singleProduct ? 
                                            (singleProductItem.price+extraOptionTotalPrice).toLocaleString() : 
                                            (optionTotalPrice+extraOptionTotalPrice).toLocaleString()}
                                    </span>원
                                </Col>
                            </Row>

                            <div className='btnWrap'>
                                <button type='button' 
                                        name='addCart'
                                        id="addCart"
                                        className='btn_resp'
                                        onClick={addToCart}>
                                        장바구니
                                </button>
                                <button type='button'
                                        name='buy' 
                                        id="buy" 
                                        className='btn_resp'
                                        onClick={()=>purchasing()}>
                                        구매하기
                                </button>
                            </div>
                       </div>
                    </div>
                </Col>
            </Row>

            {/* 상품정보 탭 */}

            <Row>
                <Col xs={12} md={12}>
                    <div className='articleBody detail-tab'>
                        <Nav justify variant="tabs" defaultActiveKey="link-0" >
                            <Nav.Item>
                                <Nav.Link eventKey="link-0" onClick={()=>{setTab(0)}}>상세정보</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-1" onClick={()=>{setTab(1)}}>상품후기({productDetails?.review_count})</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-2" onClick={()=>{setTab(2)}}>상품문의({productDetails?.inquiry_count})</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-3" onClick={()=>{setTab(3)}}>배송/교환/반품</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <TabContent tab={tab} dataset={dataset} id={productDetails?.id} productDetails={productDetails}/>

                </Col>
            </Row>
        </Container>
        </>
    )
}

function TabContent({tab , dataset, id, productDetails})
{

    return(
        <div>
        {
        [
            <div className='text-center'>
                {/* 상세페이지 */}
                <Row>
                    <Col className='pb-5'>
                        <div className='editor-content'>
                            <div dangerouslySetInnerHTML={{ __html: dataset.product_detail }} />
                            
                            <img src="https://imagedelivery.net/YH9_GnBEWq52U8rOuaomwQ/668e399f-29f5-428f-cfcf-1eed0af2f300/w=1200" width="80%"/>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col xs={12} className="text-start fs-6 fw-bold my-2 ">
                        <p className="text-start fs-6 fw-bold pb-3">상품정보 제공고시</p>
                        <ProductInfo productDetails={productDetails}/>
                    </Col>
                    <Col xs={12} className="text-start fs-6 fw-bold my-2 ">
                        <p className="text-start fs-6 fw-bold pb-3">검수정보</p>
                        <ProductInspectInfo productDetails={productDetails}/>
                    </Col>
                </Row>
            </div>,
            <div>
                {/* 상품 리뷰 start */}
                <ProductReview id={id}/> 
                
            </div>,

            <div>
                {/* 상품 Q&A start */}
                <ProductInquiry id={id} /> 
            </div>,
            
            <div>
                <Row>
                    <Col xs={12} className="text-start fs-6 fw-bold py-1">
                        <p className="text-start fs-6 fw-bold pb-3">배송정보</p>
                        <ProductDeliveryInfo productDetails={productDetails}/>
                    </Col>
                    <Col xs={12} className="text-start fs-6 fw-bold my-5">
                        <p className="text-start fs-6 fw-bold pb-3">교환환불정보</p>
                        <ReturnExchangeInfo productDetails={productDetails}/>
                    </Col>
                </Row>
            </div>
        ]
        [tab]
         }
        </div>
    )
}
