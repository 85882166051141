import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { BASE_URL } from '../apiConfig';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

// 해당 상품 Q&A 리스트 조회 
const fetchProductInquiryList = (id , page, pageSize) => {
    return fetch(`${BASE_URL}/inquiry/product/${id}/list/?page=${page}&page_size=${pageSize}`)
      .then(res => res.json());
};
  
export const useProductInquiryListQuery = (id) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [toggle, setToggle] = useState(false);
    const [pageSize, setPageSize] = useState(10); 
  
    const { data, error, isFetching } = useQuery(['productInquiry', page , pageSize], () => fetchProductInquiryList(id , page , pageSize), {
        onSuccess: (data) => {
        setTotalPages(Math.ceil(data.totalCount / pageSize));
        
        // if (page < totalPages) {
        //   prefetchQuery(['newStore', page + 1], () => fetchNewStore(page + 1));
        // }
        }
    });
  
    return {
        data,
        error,
        isFetching,
        page,
        setPage,
        totalPages,
        toggle,
        setToggle,
        pageSize,
        setPageSize
    };
  };

  // 고객 > 해당 상품 Q&A 리스트 조회 
const fetchProductReviewList = (id , page ) => {
    return instance.get(`product/review/?product_id=${id}&page=${page}`)
      .then(res => res.data);
};  

export const useProductReviewListQuery = (id) => {
    const [page, setPage] = useState(1);

  
    const { data, error, isFetching } = useQuery(
      ['productReview', page ], () => fetchProductReviewList(id , page ) 
    );
  
    return {
        data,
        error,
        isFetching,
        page,
        setPage,
    };
  };


// 해당 상품 하위 카테고리 조회
export const fetchProductCategoryData = async (productId) => {
  const response = await instance.get(`/category/?parent=${productId}`)
  return response.data;
}

// 현재 페이지 카테고리 이름 조회
export const fetchCurrentCategoryData = async (productId) =>{
  const response = await instance.get(`/category/${productId}`)
  return response.data.parent_name;
}

// [hm]전체 카테고리 조회
export const getAllCategory = () => {
  return instance.get('/category/menu/')
}
// [hm]전체 카테고리 조회
export const getTargetCategory = (id) => {
  return instance.get(`/category/?parent=${id}&page_size=all`)
}
// [hm]전체 필터 조회
export const getAllFilter = () => {
  return instance.get('/category/?depth=3')
}