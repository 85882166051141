/***************************************************************************************
* FileName      : OrderList.js
* Description   : 셀러관리자 > 주문배송 > 주문조회 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useOrderListQuery , handleSearch , handleSort } from '../../../api/selleradmin/orderAPI';
import React, { useState , useEffect } from 'react';
import { OrderRow } from "./OrderList/OrderRow";
import Pagination from '../common/Pagination'
import SearchPagination from '../../admin/common/SearchPagination';
import SortComponent from '../../admin/common/SortComponent2';
import PageSizeSelect from '../../admin/common/PageSizeSelect';
import { OrderStateChangerModal } from '../../admin/order/Inquire/statusModal';

function OrderList()
{    
    const [isChecked, setIsChecked] = useState(false);

    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage} = useOrderListQuery(ordering,pageSize);

    const [searchUrl, setSearchUrl] = useState('');

    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
     
    // 상태 체크박스
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const checkBoxList = ['전체선택', '주문완료', '결제완료', '주문취소'];

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const [toggle , setToggle] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [nextStateModal, setNextStateModal] = useState(false);

    const [expandedIds, setExpandedIds] = useState([]);


    // 선택한 주문 Row
    const [selectedRows, setSelectedRows] = useState({});
    // 선택한 주문 Row의 데이터값 (백엔드 요청 최소화를 위해 추가함)
    const [selectedRowsData, setSelectedRowsData] = useState({});
    useEffect(()=>{
        console.log(selectedRows);
        console.log(selectedRowsData);
    },[selectedRows]);

    // 송장번호 입력을 위한 상품출고 모달 오픈
    function deliveryStateUpdate(){
        // 선택된 주문 확인
        const trueKeys = Object.keys(selectedRows).filter(key => selectedRows[key] === true);
        const dataArray = trueKeys.map(key => selectedRowsData[key]?.status);

        console.log(dataArray);

        // "상품준비중"상태인지 확인
        if (dataArray.every(item => item === "상품준비중")) {
            setNextStateModal(true);
        } else {
            alert("[상품준비중]상태에서만 송장번호 입력이 가능합니다.\n 선택된 주문의 [주문상태]를 다시 확인해주세요.");
            window.location.reload();
        }
    }
    
    useEffect(() => {
        // 체크박스들이 모두 선택되었는지 확인하여 전체 선택 상태 업데이트
        setIsAllChecked(checkedItems.length === checkBoxList.length);
        if(isAllChecked){
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((item) => item !== '전체선택')
          );
        }
    }, [checkedItems]);
    
    const onSearch = () => {
        handleSearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
            checkedItems,
            ordering
        });
        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/selleradmin/orderDelivery/list';
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
      
    
    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체선택') {
          // "전체선택" 체크박스의 경우, 모든 체크박스를 선택 또는 해제
          if (checked) {
            setCheckedItems(checkBoxList);
          } else {
            setCheckedItems([]);
          }
        } else {
          // 다른 체크박스의 경우, 개별 체크박스 상태 업데이트
          if (checked) {
            setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
          } else {
            setCheckedItems((prevCheckedItems) =>
              prevCheckedItems.filter((item) => item !== id)
            );
          }
        }
    };


    console.log(data);
  
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const openModal = () => {
        console.log("cccc");
        setIsOpen(true);
    }
  
    const closeModal = () => {
      setIsOpen(false);
    }
    if (!data) return <div>Loading products...</div>;

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };
    
    const handleExpandClick = (id) => {
        if (expandedIds.includes(id)) {
          setExpandedIds(expandedIds.filter((prevId) => prevId !== id));
        } else {
          setExpandedIds([...expandedIds, id]);
        }
    };
    return(
        <>
        <div className='mainContents'> 
            <div className='page-tit'>
                <h2>주문조회</h2>
            </div>
           
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="OrderNumber">주문번호</option>
                                <option value="ProductName">상품명</option>
                                <option value="ProductNumber">상품번호</option>
                            </select>
                           
                            <input type="text"
                                value={searchOrder}
                                onChange={(e) => setSearchOrder(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>결제일</b> 
                        </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div> 
                    </div>
                    <div className='search-field checkbox-wrap'>
                        <div> 
                            <b>상태</b> 
                        </div>
                        <div className='CheckboxWrap w30'>
                            {checkBoxList.map((item, idx) => (
                                <p className='checkbox' key={idx}>
                                    <input
                                        type='checkbox'
                                        id={item}
                                        checked={checkedItems.includes(item)}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={item}>{item}</label>
                                </p>
                            ))}
                        </div>
                                                   
                    </div>
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>
        

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'> 
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규등록순"
                                oldLabel="오래된등록순"
                            />
        
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>

                {isOpen && (
                    <div className="modal ">
                        <div className="modal-content ProductDetailModal">
                            <span className="close" onClick={closeModal}>&times;</span>
                            <p>Modal Content Goes Here</p>
                        </div>
                    </div>
                )}

                <div className='d-flex justify-content-between align-items-center'>
                    <div className='m-btn-wrap-sm'>
                        <button className='m-sm-btn' onClick={()=>deliveryStateUpdate()}>송장번호 등록</button>
                    </div>
                    <div className='m-btn-wrap-sm'>
                        <button className='m-sm-btn'>이메일 발송</button>
                        <button className='m-sm-btn'>알림톡 발송</button>
                        <button className='m-sm-btn'>푸시 발송</button>
                        <button className='m-sm-btn'>다운로드</button>
                    </div>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                            {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>배송ID</th>
                                <th>주문번호</th>
                                <th>결제일시</th>
                                <th>주문상태</th>
                                <th>상품준비</th>
                                <th>주문거절</th>
                                <th>미확인</th>
                                <th>배송완료</th>
                                <th>배송비</th>

                                <th>
                                    주문금액 <br/>
                                    (전체상품금액)
                                </th>
                                <th>
                                    구매자 ID <br/>
                                    구매자 이름
                                </th>
                                <th>
                                    상품번호<br/>
                                    상품명
                                </th>
                                <th>
                                    수취인 이름<br/>
                                    수취인 연락처
                                </th>
                                <th>주소</th>
                            </tr>
                         
                        </thead>

                        <tbody>
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : searchResults?.count > 0 ? (
                            searchResults?.data.map((order, index ) => (
                                <OrderRow 
                                    key={order?.id} 
                                    order={order} 
                                    index={index}
                                    expandedIds={expandedIds}
                                    handleExpandClick={handleExpandClick}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    selectedRowsData={selectedRowsData}
                                    setSelectedRowsData={setSelectedRowsData}
                                />
                            ))

                        ) : data?.data && data?.data.length > 0 ? (
                            data?.data.map((order, index ) => (
                                <OrderRow 
                                    key={order?.id} 
                                    order={order} 
                                    index={index} 
                                    expandedIds={expandedIds}
                                    handleExpandClick={handleExpandClick}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    selectedRowsData={selectedRowsData}
                                    setSelectedRowsData={setSelectedRowsData}
                                />
                            ))
                        ) : null}

                        </tbody>
                    </table>
                </div>
            </section>

            {/* 페이지내비게이션 */}
            <section>
                {searchResults.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                    ) : (  
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>

  

            </div>
            <OrderStateChangerModal 
                open={nextStateModal} 
                setPopup={setNextStateModal} 
                selectedRows={selectedRows} 
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
            />
        </>
    )
}

export default OrderList