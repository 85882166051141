/***************************************************************************************
* FileName      : Check.js
* Description   : 해와관리자 > 상품관리 > 상품조회
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useProductsQuery , handleSearch , fetchProductId } from '../../../api/hwadmin/productApi.js';
import React, { useState, useEffect } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination.js';

import PageSizeSelect from '../common/PageSizeSelect';
import SortComponent from '../common/SortComponent2';
import { ProductRow } from "./Check/ProductRow.js";
import handleExcelDownload from '../common/ExcelDownload.js';

import SelectVendor from './Component/SelectVendor.js';

function ProductCheck()
{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentSearchQuery = searchParams.get('search');
    const [searchQuery, setSearchQuery] = useState(currentSearchQuery || '');

    const [ordering, setOrdering] = useState('created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useProductsQuery(ordering , pageSize);
    const [toggle , setToggle] = useState(false);

    const [searchProduct, setSearchProduct] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    const [expandedIds, setExpandedIds] = useState([]);

    // 다른페이지에서 상품Id 검색 
    const [fetchedData, setFetchedData] = useState(null);

    
    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
    

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
      
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
  
    // 상태
    const [status, setStatus] = useState('');

    // 판매가
    const [startPrice, setStartPrice] = useState('');
    const [endPrice, setEndPrice] = useState('');

    // 재고량
    const [startStock, setStartStock] = useState('');
    const [endStock, setEndStock] = useState('');
  
    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    
    // 승인상태
    const [selectedApprovalStatus, setSelectedApprovalStatus] = useState('');

    // 미성년자구매가능여부
    const [selectedMinorsPurchase, setSelectedMinorsPurchase] = useState('');

    // 인당 최대 구매수량
    const [selectedMaxPurchase, setSelectedMaxPurchase] = useState('');

    // 선택한 상품 Row
    const [selectedRows, setSelectedRows] = useState({});

    // [hm] 체크박스 선택된 항목 확인 : 2023.09.19
    useEffect(()=>{
        console.log(selectedRows);
    },[selectedRows])

    useEffect(() => {
        if (searchQuery) {
            console.log("오나?")
            fetchProductId(searchQuery)
              .then(data => {
                setFetchedData(data);
                console.log(fetchedData.data)
              })
              .catch(error => {
                // 오류 처리
                console.error(error);
              });
        }

        // 검색 쿼리 초기화
        setSearchQuery('');
    }, [searchQuery]);
    

    if (error) return <div>Error loading products</div>;
    if (!data) return <div>Loading products...</div>;

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

   


    const onSearch = () => {
        handleSearch({
            searchProduct, 
            setSearchResults,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            status,
            startPrice,
            endPrice,
            selectedVendor,
            setSearchUrl
        });
        setSearchConfirm(true);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const totalPages = Math.ceil(data.totalCnt / data.count) ;
    
    console.log(searchResults.data)



    // 상태 선택
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    // 판매가 
    const handleStartPriceChange = (e) => {
        setStartPrice(e.target.value);
    };
    
    const handleEndPriceChange = (e) => {
        setEndPrice(e.target.value);
    };

    // 재고량
    const handleStartStockChange = (e) => {
        setStartStock(e.target.value);
    };
    
    const handleEndStockChange = (e) => {
        setEndStock(e.target.value);
    };

    // 승인상태 
    const handleApprovalStatusRadioChange = (e) => {
        setSelectedApprovalStatus(e.target.value);
    };

    // 미성년자구매가능여부
    const handleMinorsPurchaseRadioChange = (e) => {
        setSelectedMinorsPurchase(e.target.value);
    };

    // 인당 최대 구매수량
    const handleMaxPurchaseRadioChange = (e) => {
        setSelectedMaxPurchase(e.target.value);
    };
      
    const handleExpandClick = (id) => {
      if (expandedIds.includes(id)) {
        setExpandedIds(expandedIds.filter((prevId) => prevId !== id));
      } else {
        setExpandedIds([...expandedIds, id]);
      }
    };

    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false)
        window.location.href = '/admin/product/check/'; 
    };
    

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };



    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>상품조회</h2>
            </div>
            
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">상품명</option>
                                <option value="id">상품ID</option>
                            </select>
                            <input type="text"
                              value={searchProduct}
                              onChange={(e) => setSearchProduct(e.target.value)}/> 
                        </div>   
                    </div>
                        
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="apply">신청일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>

                    <div className='search-field division'>
                        <div> 
                            <b>상태</b> 
                        </div>
                        <div>
                            <input
                                type='radio'
                                id='statusAll'
                                className='mrbtn'
                                name='status'
                                value=''
                                checked={status === ''}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusAll'>전체</label>
                            
                            <input
                                type='radio'
                                id='statusOnSale'
                                className='mrbtn'
                                name='status'
                                value='판매중'
                                checked={status === '판매중'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusOnSale'>판매중</label>

                            <input
                                type='radio'
                                id='statusSoldOut'
                                className='mrbtn'
                                name='status'
                                value='품절'
                                checked={status === '품절'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusSoldOut'>품절</label>

                            <input
                                type='radio'
                                id='statusStopSale'
                                className='mrbtn'
                                name='status'
                                value='판매중지'
                                checked={status === '판매중지'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusStopSale'>판매중지</label>

                            <input
                                type='radio'
                                id='statusEndSale'
                                className='mrbtn'
                                name='status'
                                value='판매종료'
                                checked={status === '판매종료'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusEndSale'>판매종료</label>
                        </div>   
                    </div>
                        
                    <div className='search-field date'>
                        <div> 
                            <b>수산상회</b> 
                        </div>
                        
                        <div>
                            <SelectVendor 
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                />     
                        </div>   
                    </div>
                
                    <div className={toggle ? 'Detailed-search-show' : 'Detailed-search'}>
                        <div className='search-field range'>
                            <div> 
                                <b>판매가</b> 
                            </div>
                            <div>
                                <input type="text" value={startPrice} onChange={handleStartPriceChange} />     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text" value={endPrice} onChange={handleEndPriceChange} />       
                                <span>원</span>
                            </div>   
                        </div>
                
                        <div className='search-field range'>
                            <div> 
                                <b>재고량</b> 
                            </div>
                            <div>
                                <input type="text" value={startStock} onChange={handleStartStockChange} />     
                                <span>개</span>
                                <span>~</span>  
                                <input type="text" value={endStock} onChange={handleEndStockChange} />       
                                <span>개</span>
                            </div>   
                        </div>
                   
                        <div className='search-field select'>
                            <div>
                                <b>승인상태</b>
                            </div>
                            <div>
                                <input type='radio' id='approvalStatus1' className='mrbtn' name='group4' value='' checked={selectedApprovalStatus === ''} onChange={handleApprovalStatusRadioChange} />
                                <label htmlFor='approvalStatus1'>전체</label>
                                <input type='radio' id='approvalStatus2' className='mrbtn' name='group4' value='승인완료' checked={selectedApprovalStatus === '승인완료'} onChange={handleApprovalStatusRadioChange} />
                                <label htmlFor='approvalStatus2'>승인완료</label>
                                <input type='radio' id='approvalStatus3' className='mrbtn' name='group4' value='승인대기' checked={selectedApprovalStatus === '승인대기'} onChange={handleApprovalStatusRadioChange} />
                                <label htmlFor='approvalStatus3'>승인대기</label>
                                <input type='radio' id='approvalStatus4' className='mrbtn' name='group4' value='승인거절' checked={selectedApprovalStatus === '승인거절'} onChange={handleApprovalStatusRadioChange} />
                                <label htmlFor='approvalStatus4'>승인거절</label>
                            </div>
                        </div>

                        <div className='search-field select'>
                            <div>
                                <b>미성년자구매가능여부</b>
                            </div>
                            <div>
                                <input type='radio' id='minorsPurchase1' className='mrbtn' name='group1' value='' checked={selectedMinorsPurchase === ''} onChange={handleMinorsPurchaseRadioChange} />
                                <label htmlFor='minorsPurchase1'>전체</label>
                                <input type='radio' id='minorsPurchase2' className='mrbtn' name='group1' value='가능' checked={selectedMinorsPurchase === '가능'} onChange={handleMinorsPurchaseRadioChange} />
                                <label htmlFor='minorsPurchase2'>가능</label>
                                <input type='radio' id='minorsPurchase3' className='mrbtn' name='group1' value='불가능' checked={selectedMinorsPurchase === '불가능'} onChange={handleMinorsPurchaseRadioChange} />
                                <label htmlFor='minorsPurchase3'>불가능</label>
                            </div>
                        </div>
                        <div className='search-field select'>
                            <div>
                                <b>인당 최대 구매수량</b>
                            </div>
                            <div>
                                <input type='radio' id='maxPurchase1' className='mrbtn' name='group1' value='' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label htmlFor='maxPurchase1'>전체</label>
                                <input type='radio' id='maxPurchase2' className='mrbtn' name='group1' value='제한없음' checked={selectedMaxPurchase === '제한없음'} onChange={handleMaxPurchaseRadioChange} />
                                <label htmlFor='maxPurchase2'>제한없음</label>
                                <input type='radio' id='maxPurchase3' className='mrbtn' name='group1' value='제한있음' checked={selectedMaxPurchase === '제한있음'} onChange={handleMaxPurchaseRadioChange} />
                                <label htmlFor='maxPurchase3'>제한있음</label>
                            </div>
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>출고일</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange}/>
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='당일출고' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label for='sms2'>당일출고</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='익일출고' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label for='sms3'>익일출고</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='기타' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label for='sms3'>기타</label>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>배송방법</b> 
                            </div>
                            <div className='w50'>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange}/>
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='당일출고' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label for='sms2'>당일출고</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='익일출고' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label for='sms3'>익일출고</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='기타' checked={selectedMaxPurchase === ''} onChange={handleMaxPurchaseRadioChange} />
                                <label for='sms3'>기타</label>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>택배사</b> 
                            </div>
                            <div>
                        
                            </div>   
                        </div>
                        <div className='search-field select'>
                            <div> 
                                <b>배송</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>무료배송</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>유료배송</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>조건부 무료배송</label>                               
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>묶음배송</label>
                            </div>   
                        </div>
                        <div className='search-field select'>
                            <div> 
                                <b>면세여부</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>과세</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>면세</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>간이과세</label>                                                   
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>형태</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>생물</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>냉동</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>반건조</label>     
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>완전건조</label>                                                   
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>소금간</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>유</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>무</label>              
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>손질</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>유</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>무</label>              
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>내장제거</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>유</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>무</label>              
                            </div>   
                        </div>

                        
                        <div className='search-field select'>
                            <div> 
                                <b>회슬라이스</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>유</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>무</label>              
                            </div>   
                        </div>

                               
                        <div className='search-field select'>
                            <div> 
                                <b>마리수</b> 
                            </div>
                            <div  className='w80'>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>1~5미</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>5~10미</label>      
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>11~15미</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>16~20미</label>            
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>21~30미</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>31~40미</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>40미 이상</label>   
                            </div>   
                        </div>

             
                        <div className='search-field select'>
                            <div> 
                                <b>용도</b> 
                            </div>
                            <div className='w80'>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>회</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>볶음</label>    
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>조림</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>찜</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>탕</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>국</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>찌개</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>튀김</label>   
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>부침</label>    
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>무침</label>      
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>기타</label>      
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>인증정보</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>유</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>무</label>              
                            </div>   
                        </div>


                    </div> 
      
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={()=>handleResetClick()}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                    <button className='more-btn' onClick={()=>{setToggle((e)=>!e)}}>
                    {
                        toggle
                        ? <i className='xi-angle-up'></i> 
                        : <i className='xi-angle-down'></i>
                    }    
                    </button>
                </div>
            </section>

        

            {/* 검색 결과 */}
            <section className='McontentBox'>   
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규등록순"
                                oldLabel="오래된등록순"
                                />
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    {/* 
                    <button className='m-sm-btn'>상태변경(판매중)</button>
                    <button className='m-sm-btn'>상태변경(판매중지)</button>
                    */}
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>알림톡 발송</button>
                    <button className='m-sm-btn'>푸시 발송</button>
                    <button className='m-sm-btn' onClick={()=>{handleExcelDownload({ type: 'product' })}}>
                        다운로드
                    </button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <button className='expand-btn'>
                                        <i className='xi-angle-down'></i>
                                    </button>
                                </th> 
                                <th><input type="checkbox" name="Allcheck" value=""/></th>
                                <th>승인여부</th>
                                {/* <th>상태</th> */}
                                <th>판매상태</th>
                                <th>수정/복사</th>
                                <th>대표이미지</th>
                                <th>상품ID <br/>
                                    상품명</th>
                                <th>판매자</th>
                                <th>정상가</th>
                                <th>판매가</th>
                                <th>재고수량</th>
                                <th>상세설명</th>
                                <th>제조사</th>
                                <th>카테고리</th>
                                <th>미성년자구매</th>
                                <th>인당최대구매수량</th>
                                <th>출고소요일</th>
                                <th>배송방법</th>
                                <th>묶음배송</th>
                                <th>제주/도서산간</th>
                                <th>상품정보제공고시</th>
                                <th>택배사</th>
                                <th>배송비종류</th>
                                <th>기본배송비</th>
                                <th>반품배송비</th>
                                <th>판매시작일</th>
                                <th>판매종료일</th>
                                <th>등록일시</th>
                                <th>최종수정일시</th>
                                <th>수정이력</th>
                            </tr>
                        </thead>
                        <tbody>
                        {searchResults?.data?.length > 0 ? (
                                searchResults.data.map((product, index) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : (
                            searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="23">검색 결과가 없습니다.</td>
                                </tr>
                            ) : (
                            fetchedData && fetchedData.data.length > 0 ? (
                                fetchedData.data.map((product, index) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : (
                            data && data.data && data.data.length > 0 ? (
                                data.data.map((product, index) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : (
                            <tr>
                                <td colSpan="23">데이터가 없습니다.</td>
                            </tr>
                            )
                        )
                        )
                        )}
                        </tbody>


                        {/* <tbody>
                            {searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults.count > 0 ? (
                                searchResults?.data.map((product) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                    ))
                            ) : data && data.data && data.data.length > 0 ? (
                                data.data.map((product) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : null}

                                </tbody> */}
                    </table>
                </div>
             
                  
            </section>
            

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                    ) :  fetchedData && fetchedData.totalCnt > 0 ? (
                        <Pagination
                            count={fetchedData.totalCnt}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    ) : (
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                )}
            </section>
             

        </div>

                        
        </>
    )
}

export default ProductCheck