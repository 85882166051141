import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFilteringOptions } from '../../../../../api/Category';

function Filtering({ categoryId, filterIdArr , viewfilteringCheckIds , setFilterIdArr , setFilteringCheck}) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data: filteringData } = useFilteringOptions(categoryId);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setFilterIdArr(selectedCategory);
  }, [selectedCategory]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching main categories.</div>;
  }

  const handleSubCategoryChange = (id) => {
    // 라디오 버튼을 클릭하면 해당 카테고리가 선택됩니다.
    setSelectedCategory(id);

  };

  return (
    <>
      {filteringData.data.map((category) => (
          <li className='fchecklist' key={category.id}>
            <label>
                <input
                    type="radio"
                    name={`filterCategory-${categoryId}`}
                    value={category.id}
                    checked={viewfilteringCheckIds.includes(category.id)}
                    disabled
                    // checked={selectedCategory === category.id}
                    onChange={() => handleSubCategoryChange(category.id)}
                />
                <span style={{ color: selectedCategory === category.id ? '#0073e9' : 'rgb(0, 0, 0)' }}>
                    {category.name} 
                </span>
            </label>
          </li>
      ))}
    </>
  );
}

export default Filtering;
