import {Row, Col, Button , Navbar, Nav, Container, Tab} from 'react-bootstrap';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import data from '../../data.js';
import Card from './List/Card.js';

import { useLocation } from 'react-router-dom';

import SearchPagination from './common/SearchPagination.js'

import { handleSearch } from '../../api/services/productApi.js'

function SearchResults(){

    const location = useLocation();
    const [searchResultsFromLocation, setSearchResultsFromLocation] = useState(location.state.searchResults);
    const [searchProduct, setSearchProduct] = useState(location.state.searchProduct);
    const [searchResultsUrl, setSearchResultsUrl] = useState(location.state.searchUrl);
  
    const [searchUrl, setSearchUrl] = useState(searchResultsUrl || []);

    const [searchResults, setSearchResults] = useState(searchResultsFromLocation || []);


    useEffect(() => {
        setSearchResultsFromLocation(location.state.searchResults);
        setSearchProduct(location.state.searchProduct)
        setSearchResultsUrl(location.state.searchUrl)
  
        const fetchData = async () => {
          try {
            if (searchResults) {
                setSearchResults(location.state.searchResults.data);
            }else{
                console.log("searchResults 또는 data 속성이 존재하지 않습니다.");
            }
          } catch (error) {
            console.error('검색 결과를 가져오는 동안 오류가 발생했습니다:', error);
          }
        };

        fetchData();
      }, [location.state]); 
      

    // if (!searchResults) return <div>Loading products...</div>;
  

    // // 페이지내비게이션 
    const handlePrevPage = () => {
        // setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        // setPage(prevPage => prevPage + 1);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
   
    console.log("---------검색결과페이지-------------")
    console.log(searchResults)

    
    return(
        <>
        <Container className='p-3'>
            <div className='product-headcategory'>
                <ol>
                    <li><a>홈</a></li>
                    <li><a>전체 상품</a></li>
                    <li><a>검색</a></li>

                </ol>
            </div>
            <Row>
                <Col xs={12} md={12}>
                    <div className='page-tit'>
                        <h2>{searchResults && searchProduct}</h2>
                    </div>
                </Col>
                <div className='product-list-wrap'>
                    <p className='prdCount'>
                        상품
                        <strong>
                            {searchResults && searchResults.totalCnt ? searchResults.totalCnt : 0}
                        </strong>
                        개
                    </p>

                    <div className='product_ListMenu'>
                        <ul className='product-orderby'>
                            <li>
                                <a>신상품</a>
                            </li>
                            <li>
                                <a>상품명</a>
                            </li>
                            <li>
                                <a>낮은가격</a>
                            </li>
                            <li>
                                <a>높은가격</a>
                            </li>
                            <li>
                                <a>인기상품</a>
                            </li>
                            
                        </ul>
                    </div>  
               

            
                    <Row className='prdList'>
                        {searchResults && searchResults.data && searchResults.data.length > 0 ? (
                            searchResults.data.map((product) => (
                                <Card 
                                    product={product} 
                                    key={product.id} 
                                />
                            ))
                        ) : (
                            <p>검색된 상품이 없습니다.</p>
                        )}
                    </Row>
                </div>   
            </Row>

            
            {/* 페이지내비게이션 */}
            <section>
                <SearchPagination
                
                    count={searchResults && searchResults.totalCnt ? searchResults.totalCnt : 0}
                    url={searchUrl}
                    updateSearchResults={updateSearchResults}
                />
            </section>

        </Container>
        </>
    )
}


export default SearchResults