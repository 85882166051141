
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { BASE_URL } from './api/apiConfig';
import { Row, Col, DropdownButton, Dropdown, Badge } from "react-bootstrap";
import { getMe, logOut } from './api/useLogin.js';
import Cookies from 'js-cookie';

function MHeader()
{
	const [userName, setUserName] = useState('');

	useEffect(()=>{
		getMe()
			.then(res => setUserName(res?.data?.data))
			.catch(error => alert('로그인 정보 에러'))
	}, [])

	let navigate = useNavigate();

	const logout = () => {
		// 토큰 삭제
		logOut()
			.then(res => {
				console.log(res?.data);
				Cookies.remove('access');
				Cookies.remove('refresh');
				// 메인페이지로 새로고침
				navigate('/');
				window.location.reload();
			})
	} 
    return (
        <> 
        <header className="m_header">
			<div className="lnb">
				<div className="logo">
					<a href="/admin/main">
						<img src={process.env.PUBLIC_URL + '/img/Logo_00.png'} /> 
					</a>
				</div>
				<div className="search-content">
					<div className='search-field'>
						<input type="text" placeholder='검색어를 입력해주세요'/>
						<i className='xi-search'></i>
					</div>
                </div>
				<div className="user-content ms-2">
					<div>
						<DropdownButton variant="white" drop="down" alignRight title={userName}>
							{/* <Dropdown.Item eventKey="1" onClick={() => navigate('/selleradmin/sellerInfo/editVendorInfo')}>설정</Dropdown.Item> */}
							{/* <Dropdown.Divider /> */}
							<Dropdown.Item eventKey="2" onClick={() => logout()} className="text-danger">로그아웃</Dropdown.Item>
						</DropdownButton>
					</div>
				</div>
			</div>
		
        </header>    
        </>
    )

}

export default MHeader