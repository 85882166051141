import { Row, Col } from 'react-bootstrap';
import { extractYearMonthDayHourMinute2 } from '../Component/extractDateUtils';
function CouponBlock({data}){
    return(
        <Row className='text-center mycoupon_row'>
            <Col className={data?.is_used ? 'mycoupon_td text-decoration-line-through' :'mycoupon_td'}>{data?.coupon?.name}</Col>
            <Col className='mycoupon_td'>{data?.coupon?.type} 할인</Col>
            <Col className='mycoupon_td'>{data?.coupon?.discount_amount !== 0 ? data?.coupon?.discount_amount+"원" : data?.coupon?.discount_rate+"%"}</Col>
            <Col className='mycoupon_td'>{data?.coupon?.minimum_order_amount}원 이상 구매 시</Col>
            <Col className='mycoupon_td'>
                {extractYearMonthDayHourMinute2(data?.coupon?.validity_period_end)} 
            </Col>
        </Row>
    )
}

export default CouponBlock