/***************************************************************************************
* FileName      : AbortReturnView.js
* Description   : 해와관리자 > 주문배송 > 반품교환취소 > 반품/취소 > 취소 리스트 조회 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';

import { useOrderCancelListQuery , handleOrderCancelSearch } from '../../../../api/hwadmin/orderAPI';
import { OrderRow } from './AbortCancelView/OrderRow';

import Pagination from '../../common/Pagination'
import SearchPagination from '../../common/SearchPagination'

import SortComponent from '../../common/SortComponent2';
import PageSizeSelect from '../../common/PageSizeSelect';

import { useApprovedVendors } from '../../../../api/hwadmin/productApi';

function AbortCancelView({ selectedStatus })
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 
    const [searchUrl, setSearchUrl] = useState('');

    const { data, error, isFetching, page, setPage } =  useOrderCancelListQuery(ordering,pageSize);
    
    // 상태 체크박스
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const checkBoxList = ['전체','취소요청', '취소거절', '취소완료'];

    
    console.log(data);
    console.log("===취소")

    // 수산상회 조회
    const { data: approvedVendors} = useApprovedVendors();

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
    
    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

 

    const [expandedIds, setExpandedIds] = useState([]);


    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    

    useEffect(() => {
        // 체크박스들이 모두 선택되었는지 확인하여 전체 선택 상태 업데이트
        setIsAllChecked(checkedItems.length === checkBoxList.length);
        if(isAllChecked){
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((item) => item !== '전체선택')
          );
        }
    }, [checkedItems]);

    const handleExpandClick = (id) => {
        if (expandedIds.includes(id)) {
          setExpandedIds(expandedIds.filter((prevId) => prevId !== id));
        } else {
          setExpandedIds([...expandedIds, id]);
        }
    };
      
    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체선택') {
          // "전체선택" 체크박스의 경우, 모든 체크박스를 선택 또는 해제
          if (checked) {
            setCheckedItems(checkBoxList);
          } else {
            setCheckedItems([]);
          }
        } else {
          // 다른 체크박스의 경우, 개별 체크박스 상태 업데이트
          if (checked) {
            setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
          } else {
            setCheckedItems((prevCheckedItems) =>
              prevCheckedItems.filter((item) => item !== id)
            );
          }
        }
    };
            

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };


    const onSearch = () => {
       
        handleOrderCancelSearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate, 
            selectedEndDate,
            selectedStatus,
            checkedItems,
            selectedVendor
        });
        
        setSearchConfirm(true);

    };

    // 선택한 수산상회 저장
    const handleSelectChange = (event) => {
        const selectedOption = approvedVendors.data.find(
              (vendor) => vendor.name === event.target.value
        );
        setSelectedVendor({ id: selectedOption.id, name: selectedOption.name });
    };
         
    useEffect(() => {
        // approvedVendors.data가 존재하고 첫 번째 데이터가 있는지 확인
        if (approvedVendors && approvedVendors.data.length > 0) {
          const firstVendor = approvedVendors.data[0];
          setSelectedVendor({ id: firstVendor.id }); // 첫 번째 데이터의 id를 선택된 값으로 설정
        }
    }, [approvedVendors]);

    const handleResetClick = () => {
        window.location.href = '/admin/order/cancel'; 
    };
    
    console.log("data", data);

    return (
        <>
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field word'>
                    <div> 
                        <b>검색어</b> 
                    </div>
                    <div>
                    <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                            <option value="all" selected="selected">전체</option>
                            <option value="OrderNumber">주문번호</option>
                            <option value="ProductName">상품명</option>
                            <option value="ProductNumber">상품번호</option>
                    </select>

                    <input type="text"
                            value={searchOrder}
                            onChange={(e) => setSearchOrder(e.target.value)}/> 

                    </div>   
                </div>
                <div className='search-field date'>
                    <div> 
                        <b>날짜</b> 
                    </div>
                    <div>
                        <select value={selectedDateType} onChange={handleDateTypeChange}>
                            <option value="reception_date__range">접수일</option>
                            <option value="completed_at">처리완료일</option>
                        </select>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                    </div>   
                </div>
                
                <div className='search-field word'>
                    <div> 
                        <b>수산상회</b> 
                    </div>
                    <div>
                        <select className='mSelectBox' name="vendor" value={selectedVendor.name} onChange={handleSelectChange}>
                            <option value="">전체</option>
                            {approvedVendors && approvedVendors.data.map(vendor => (
                                <option key={vendor.id} value={vendor.name}>{vendor.name}</option>
                            ))}
                        </select>  
                    </div>   
                </div>

                <div className='search-field word mt-4'>
                    <div> 
                        <b>요청상태</b> 
                    </div>
                    <div className='CheckboxWrap w30'>
                        {checkBoxList.map((item, idx) => (
                            <div className='checkbox' key={idx}>
                                <input
                                    type='checkbox'
                                    id={item}
                                    checked={checkedItems.includes(item)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={item}>{item}</label>
                            </div>
                        ))}
                    </div> 
                </div>
            </div>
        
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}} >검색</button>
            </div>
        </section>    

        <section className='McontentBox'>
            <div className='subTitle'>
                <span className='resultsCount-wrap'>
                    <span>검색결과</span>
                    {searchResults.totalCnt > 0 ? (
                        <>
                            <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                        </>
                        ) : (
                        <>
                            <p>(총 <b className='sum-s-b'>{data?.totalCnt && data?.totalCnt}</b>개)</p>
                        </>
                    )}
                </span>

                { !searchConfirm && 
                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="신규주문순"
                            oldLabel="오래된주문순"
                            />
    
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />        
                    </div>
                 }
            </div>
                
            <div className='m-btn-wrap-sm'>
                <button className='m-sm-btn'>이메일 발송</button>
                <button className='m-sm-btn'>SMS 발송</button>
                <button className='m-sm-btn'>다운로드</button>
            </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type='checkbox'/></th>
                                <th>배송ID</th>
                                <th>주문번호</th>
                                <th>요청구분</th>
                                <th>요청상태</th>
                                <th>요청승인</th>
                                <th>구매자 ID<br/>
                                구매자 이름</th>
                                <th>수취인 이름<br/>
                                수취인 연락처</th>
                                <th>접수일시</th>
                                <th>접수번호</th>
                                <th>반품/취소 사유</th>
                                <th>주문금액</th>
                                <th>거절사유</th>
                                <th>처리담당자</th>
                                <th>처리완료일시(승인/거절)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ): searchResults.count > 0 ? (
                                searchResults?.data.map((order, index ) => (
                                    <OrderRow   
                                        key={order.id} 
                                        order={order} 
                                        index={index} 
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        />
                                ))
                            ) : data && data.data && data.data.length > 0 ? (
                                data.data.map((order, index ) => (
                                    <OrderRow   
                                        key={order.id} 
                                        order={order} 
                                        index={index} 
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                    />
                                ))
                            ) : null}
                        </tbody>                    
                        </table>
                    </div>
                </section>
                
              

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
    
    </>
    )
}

export default AbortCancelView