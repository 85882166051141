import { useState , useRef , useEffect } from 'react';

import { updateAdminVendorData } from '../../../api/vendor/updateVendorData';
import { verifyEmployeeId } from '../../../api/hwadmin/storeApi.js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useQuery , useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { fetchVendorData } from '../../../api/selleradmin/vendorApi';


// 이미지등록
import { uploadImage } from '../../../api/uploadImage/uploadApi';

// 주소 모달
import AddressModal from "./AddressModal.js"

// 셀러관리 > 매장정보
function VendorInfo()
{
    // id 추출
    const { vendorId } = useParams();
   
    // 데이터 조회
    const { data, isError } = useQuery(['vendorData', vendorId], () => fetchVendorData(vendorId));


    //상호
    const [name, setName] = useState('');

    const [isNameError, setIsNameError] = useState(false);
    const inputNameRef = useRef(null);

    //관리자ID
    const [owner, setOwner] = useState('')

    //관리자이름
    const [manager_name, setManagerName] = useState('')
 

    //구분
    const [business_type, setBusinesstype] = useState('')

    //사업자등록번호
    const [business_number, setBusinessnumber] = useState('')
    const [isBNumberError, setIsBNumberError] = useState(false);
    const inputBNumberRef = useRef(null);

    
    //통신판매등록번호 
    const [sales_registration_number , setSalesRegistrationNumber] = useState('');


    //사업장주소
    const [address, setAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

    // 출고지주소
    const [dispatchLocation, setDispatchLocation] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

 
    // 반품지 주소
    const [returnAddress, setrReturnAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });


 
    //은행 
    const [bank, setBank] = useState('');

    //계좌번호 
    const [account_number, setAccountNumber] = useState('');

    //예금주
    const [account_holder, setAccountHolder] = useState('');


    //공동대표여부
    const [ceo, setCeo] = useState('');

    //과세여부
    const [tax, setTax] = useState('')

    // 이름
    const handleNameChange = (value) => {
        setName(value);
    };

    //이미지 파일들 저장
    const [files, setFiles] = useState({
        business_license: null,
        owner_image: null,
        account_image: null,
        sales_registration: null
    });

    // 이미지 url 
    const [uploadedImages, setUploadedImages] = useState({
        business_license: null,
        owner_image: null,
        account_image: null,
        sales_registration:null
    });

    
    const handleFileChange = (event, fileKey) => {
        const newFiles = {...files};
        newFiles[fileKey] = event.target.files[0];
        setFiles(newFiles);
    }


    // 추가정보(선택)
    const [phone_number, setPhonenumber] = useState('');
    const [tel, setTel] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');


    //직원추가(선택)
    const [employee_list, setEmployeelist] = useState([])



    // 기존 데이터 조회
    useEffect(() => {
        // 1. 상호명
        setName(data?.name);
        
        // 2-1. 관리자 ID 
        setOwner(data?.owner?.username)
        
        // 2-2. 관리자 이름
        setManagerName(data?.owner?.name)

       
        // 3. 구분 
        setBusinesstype(data?.business_type)
        
        // 4. 사업자등록증 (대표자사진 , 통장사본 포함)
        if( data ) {
            setUploadedImages({
                business_license: data?.business_license,
                owner_image: data?.owner_image,
                account_image : data?.account_image ,
                sales_registration : data?.sales_registration
            });
        }
        
        // 5. 사업자등록번호
        if( data && data.business_number) setBusinessnumber(data?.business_number)

        // 6. 통신판매신고등 (4번에서 같이함)

        // 7. 통신판매신고번호
        if( data && data.sales_registration_number) setSalesRegistrationNumber(data?.sales_registration_number)


        // 8. 사업자 주소
        if( data && data.address) {
            setAddress({
                address: data?.address?.address,
                detail: data?.address?.detail,
                postal_code: data?.address?.postal_code, 
                address_type: data?.address?.address_type 
            });
        }   
        
        // 9. 대표자 사진 (4번에서 같이함)
        // 10. 통장사본 (4번에서 같이함)


        // 11-1. 은행
        setBank(data?.bank);

        // 11-2. 계좌번호
        setAccountNumber(data?.account_number);

        // 11-3. 예금주
        setAccountHolder(data?.account_holder);

        // 12. 공동대표여부
        if( data ) {
            const coRepresentativeString = data?.co_representative ? data?.co_representative.toString() : null;
            console.log("coRepresentativeStringcoRepresentativeString",coRepresentativeString)
            setCeo(coRepresentativeString);
        }
        
        // 13. 과세 여부 
        setTax(data?.tax);


        // 추가정보
        // 1. 매장전화
        setTel(data?.tel);

        // 2. 팩스번호(선택)
        setFax(data?.fax);

        // 3. 직원
        if (data && data.employee_list) {
            const usernames = data.employee_list.map((employee) => employee?.username);
            setEmployeelist(usernames);
        }

        // 4. 출고지 주소
        if( data && data.dispatch_location) {
            setDispatchLocation({
                address: data?.dispatch_location?.address,
                detail: data?.dispatch_location?.detail,
                postal_code: data?.dispatch_location?.postal_code, 
                address_type: data?.dispatch_location?.address_type 
            });
        }   
        
        // 5.반품지 주소
        if( data && data.return_address) {
            setrReturnAddress({
                address: data?.return_address?.address,
                detail: data?.return_address?.detail,
                postal_code: data?.return_address?.postal_code, 
                address_type: data?.return_address?.address_type 
            });
        } 
        
    }, [data]);    
    


    if (isError) {
        return <div>Error occurred while fetching data</div>;
    }

    console.log(data)
    
    
  


    return(
        <>
        <div className='mainContents'>
            {/* 매장정보 */}
            <div className='page-tit'>
                <h2>매장정보</h2>
            </div>

        
            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>기본정보</h3>                  
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    상호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text" 
                                        name="name" 
                                        placeholder="상호명"
                                        value={name}
                                        disabled
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    관리자ID / 이름
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <div> 
                                        <input type="text" 
                                            placeholder="관리자ID"
                                            className='mr10'
                                            value={owner}
                                            disabled 
                                        />
                                        <input type="text" 
                                            placeholder="이름"
                                            className='ml7'
                                            value={manager_name}
                                            disabled
                                        />
                                    </div>
                                 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    구분
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text" 
                                            placeholder=""
                                            className='mr10'
                                            value={business_type === '개인' ? '개인' : '법인'}
                                            disabled 
                                        />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    사업자등록증
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <a href={uploadedImages && uploadedImages?.business_license} target="_blank">
                                        사업자등록증 확인
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    사업자등록번호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="사업자등록번호"
                                        name="business_number" 
                                        value={business_number}
                                        disabled
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통신판매신고증
                                    <i className='essential'></i>
                                </td>

                                <td >                            
                                    <a href={uploadedImages && uploadedImages?.sales_registration} target="_blank">
                                        통신판매신고증 확인
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통신판매등록번호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="통신판매등록번호"
                                        value={sales_registration_number}
                                        disabled
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    사업장 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                <ul className='ec-address'>
                                    <li>
                                        <input type="text"
                                            placeholder="사업장 주소"
                                            value={address?.postal_code}
                                            disabled 
                                        />  
                                       
                                    </li>
                                    <li>
                                        <input type="text"
                                            placeholder="사업장 주소"
                                            value={address?.address}
                                            disabled 
                                        />   
                                    </li>
                                    <li>
                                  
                                    </li>
                                </ul>
                                
                            </td>
                            </tr>
                            <tr>
                                <td>
                                    대표자 사진
                                    <i className='essential'></i>
                                </td>
                                <td>                            
                                    <a href={uploadedImages && uploadedImages?.owner_image} target="_blank">
                                        대표자 사진 확인하기
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통장사본
                                    <i className='essential'></i>
                                </td>
                                <td>                            
                                    <a href={uploadedImages && uploadedImages?.account_image} target="_blank">
                                        통장사본 사진 확인
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    은행/계좌번호/예금주
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                            placeholder="은행"
                                            value={bank}
                                            disabled 
                                    /> 
                                  
                                    <input type="text"
                                            className='ml7'
                                            placeholder="계좌번호"
                                            value={account_number}
                                            disabled
                                    />       
                                    <input type="text"
                                            className='ml7'
                                            placeholder="예금주"
                                            value={account_holder}
                                            disabled
                                    />       
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    공동대표여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text" 
                                            placeholder=""
                                            className='mr10'
                                            value={ceo === 'false' ? '공동대표 없음' : '공동대표 있음'}
                                            disabled 
                                        />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    과세여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text" 
                                            placeholder=""
                                            className='mr10'
                                            value={tax}
                                            disabled 
                                        />
                                  
                                </td>
                            </tr>
                            </tbody>
                    
                    </table>
            
                    </div>
                    
            </section>

            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>추가정보</h3>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    매장전화
                                    <i className='essential'></i>   
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="매장전화"
                                        value={tel}
                                        disabled
                                        
                                    />      
                                    
                                </td>
                            </tr>
                            <tr>
                                <td>팩스번호(선택)</td>
                                <td>
                                    <input 
                                        type="text" 
                                        placeholder="팩스번호"
                                        value={fax}
                                        disabled/>
                                </td>
                            </tr>
                            <tr>
                                <td>직원</td>
                                <td className='ep10'>
                                    <ul className='employeeList'>
                                        {employee_list.map((item, index) => (
                                             <li key={index}>
                                                {item}
                                              
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    출고지 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={dispatchLocation?.postal_code}
                                                disabled 
                                            />  
                            
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="출고지 주소"
                                                value={dispatchLocation?.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="출고지 상세주소"
                                            value={dispatchLocation?.detail}
                                            disabled
                                            />
                                        </li>
                                    </ul>      
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    반품지 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={returnAddress?.postal_code}
                                                disabled 
                                            />  
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="반품지 주소"
                                                value={returnAddress?.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="반품지 상세주소"
                                            value={returnAddress?.detail}
                                            disabled
                                            />
                                        </li>
                                    </ul>      
                                </td>
                            </tr>
                
                        </tbody>
                    </table>
                </div>
            
            
            </section>

        </div>

        </>
    )
}

export default VendorInfo