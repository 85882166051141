/***************************************************************************************
* FileName      : orderApi.js
* Description   : 해와관리자 주문 관련 API
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.18
* Modifide Date : 2023.12.18 : CHM : 주문 상태 전환 (결제완료 -> 배송준비중 / 판매거절) API
*               : 2024.01.24 : CHM : 운송장 번호 등록 API 구분(일반배송/에스크로배송)
*               : 2024.02.14 : PKH : 주문조회 useOrderListQuery API 수정  
* Reference     : 
***************************************************************************************/

// 해와관리자 > 주문배송 api
import {  queryClient , prefetchQuery , useQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';
import { BASE_URL } from '../apiConfig';

export const useOrderList = async () => {
    const response = await instance.get(`order/document/`);
    return response.data;
}

// 해와관리자 > 주문배송 > 주문 조회
const fetchOrder = (page, pageSize ,ordering) => {
    return instance.get(`/order/deliveryitem/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};
  
export const useOrderListQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);

    useEffect(() => {
      setPage(1);
    }, [pageSize]);

    const { data, error, isFetching } = useQuery(
      ['orderList', page, pageSize, ordering], () => fetchOrder(page, pageSize , ordering));

    return {
      data,
      error,
      page,
      setPage,
      isFetching,
    };
  };

// 해와관리자 > 주문배송 > 주문 조회 > 검색 
export const handleSearch = async ({
    searchOrder,  
    setSearchResults, 
    setSearchUrl,
    searchOption,
    selectedStartDate, 
    selectedEndDate,
    checkedItems,
    selectedVendor,
    ordering 
  }) => {
  try {
    let url = `order/deliveryitem/document/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break;
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
          url += `?product_id=${searchOrder}`;
          break;
      
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&paid_at__range=${selectedStartDate}__${selectedEndDate}` 
    }


    if(checkedItems){
      const statusParams = checkedItems.map((status) => `status=${status}`).join('&');
      url += `${statusParams}`
    }
    
    if(selectedVendor.id) url += `&vendor=${selectedVendor.id}` 
      

    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);
    console.log(response.data);
    console.log("----")
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 > 주문배송조회 > 주문상세내역 조회
export const fetchOrderDetail = (orderId) => {
  return instance.get(`order/${orderId}`)
    .then(res => res.data);
};


// 주문배송 > 배송현황 
const fetchDelivery = (page, pageSize ,ordering, requestStatus) => {
    let url = `order/delivery/?page=${page}&page_size=${pageSize}&ordering=${ordering}`;

    if (requestStatus) {
      url += `&status=${requestStatus}`;
    }

    console.log("배송현황url")
    console.log(url)

    return instance.get(url)
      .then(res => res.data);

};

export const useDeliveryListQuery = (ordering,pageSize,selectedStatus) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize,selectedStatus]);


  const { data, error, isFetching } = useQuery(
    ['deliveryList', page, pageSize, ordering], 
    () => fetchDelivery(page, pageSize , ordering, selectedStatus));


  return {
    data,
    error,
    page,
    setPage,
    isFetching
  };
};

// 주문배송 > 배송현황 > 현황
const fetchDeliveryStatus = (page, pageSize ,ordering) => {
  let url = `order/delivery/status/?page=${page}&page_size=${pageSize}&ordering=${ordering}`;

  return instance.get(url)
    .then(res => res.data);
};

export const useDeliveryStatusQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);
    
  useEffect(() => {
    setPage(1);
  }, [pageSize]);


  const { data, error, isFetching } = useQuery(
    ['deliveryStatus', page, pageSize, ordering], () => fetchDeliveryStatus(page, pageSize ,ordering));

  return {
    data,
    error,
    isFetching,
  };
};

// 주문배송 > 배송 현황 > 검색 
export const handleDeliverySearch = async ({
    searchOrder,  
    setSearchResults, 
    setSearchUrl,
    searchOption,
    selectedDateType,
    selectedStartDate, 
    selectedEndDate,
    selectedStatus,
    selectedVendor
     }
    ) => {
    try {
      let url = `order/delivery/`;

      if (searchOrder) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchOrder}`;
            break;
          case 'OrderNumber':
            url += `?order_number=${searchOrder}`;
            break;
          case 'ProductName':
            url += `?product_name=${searchOrder}`;
            break;
          case 'ProductNumber':
              url += `?product_id=${searchOrder}`;
              break;
          default:
            break;
        }
      }else{
        url += `?`;
      }

      if (selectedStartDate && selectedEndDate) {
        url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}` 
      }

      if(selectedStartDate && selectedEndDate){
        switch(selectedDateType) {
          case 'paid_at':
            url += `&paid_at=${selectedStartDate}__${selectedEndDate}`;
            break;
          case 'shipped_at':
            url += `&shipped_at=${selectedStartDate}__${selectedEndDate}`;
            break;    
          default:
            break;
        }
      }
      
      if(selectedVendor.name){
        url += `&vendor_name=${selectedVendor.name}`;
      }

      
      if(selectedStatus){
        url += `&status=${selectedStatus}` 
      }

      setSearchUrl(url);

      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
  } catch (error) {
    console.error(error);
  }
};


// [hm] 주문배송 > 주문 상태 변경(일반)
export const updateOrderState = (pk, dlvcompcode, dlvno) =>{
  return instance.post(`/order/delivery/register/`, 
  {
    order_item : pk,
    dlvcompcode : dlvcompcode,
    dlvno : dlvno,
  })
}
// [hm] 주문배송 > 주문 상태 변경(에스크로)
export const updateEscrowOrderState = (pk, dlvcompcode, dlvdate, dlvno) =>{
  return instance.post(`/order/escrow/register/`, 
  {
    order_item : pk,
    dlvcompcode : dlvcompcode,
    dlvdate : dlvdate,
    dlvno : dlvno,
  })
}
// [hm] 주문배송 > 주문 상태 변경(직접배송-일반)
export const updateDirectOrderState = (pk, rcvdate, rcvname, rcvrelation) => {
  return instance.post('/order/delivery/complete/', 
  {
    order_item : pk,
    rcvdate : rcvdate,
    rcvname : rcvname,
    rcvrelation : rcvrelation,
  })
}
// [hm] 주문배송 > 주문 상태 변경(직접배송-에스크로)
export const updateEscrowDirectOrderState = (pk, rcvdate, rcvname, rcvrelation) => {
  return instance.post('/order/escrow/complete/', 
  {
    order_item : pk,
    rcvdate : rcvdate,
    rcvname : rcvname,
    rcvrelation : rcvrelation,
  })
}

// [hm] 주문배송 > 주문 상태 변경
export const updateOrderPackaging = (pk) =>{
  return instance.post('/order/packaging/', {order_item : pk})
}
// [hm] 주문배송 > 판매거절(가상계좌 결제가 아닌 경우만)
export const updateOrderReject = (order_item, reject_reason) =>{
  return instance.post('/order/reject/', {
    order_item: order_item,
    reject_reason : reject_reason
  })
}

// 주문배송 > 구매확정내역
const fetchOrderConfirm = (page, pageSize ,ordering) => {
  return instance.get(`order/confirm/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderConfirmListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['orderConfirmList', page, pageSize, ordering], 
    () => fetchOrderConfirm(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};


// 주문배송 > 구매확정내역 조회 > 검색 
export const handleOrderConfirmSearch = async ({
    searchOrder,  
    setSearchResults, 
    setSearchUrl,
    searchOption,
    selectedStartDate, 
    selectedEndDate,
    checkedItems,
    selectedVendor,
    ordering 
}) => {
try {
    let url = `order/confirm/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break;
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
          url += `?product_id=${searchOrder}`;
          break;
      
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&created_at__range=${selectedStartDate}__${selectedEndDate}` 
    }

    if(checkedItems){
      const statusParams = checkedItems.map((status) => `request_status=${encodeURIComponent(status)}`).join('&');
      url += `${statusParams}`
    }
    
    if(selectedVendor) url += `&vendor_name=${selectedVendor.name}` 
      
    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);
    console.log(response.data);
    console.log("----")
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 주문배송 > 결제내역 
const fetchOrderUnpaid = (page, pageSize ,ordering) => {
  return instance.get(`order/pay/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderUnpaidListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['orderUnpaidList', page, pageSize, ordering], () => fetchOrderUnpaid(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 주문배송 > 결제내역 조회 > 검색 
export const handleOrderUnpaidSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  checkedItems,
  ordering 
}) => {
  try {
   let url = `order/pay/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;

        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&paid_at__range=${selectedStartDate}__${selectedEndDate}` 
    }

    if(checkedItems){
      const statusParams = checkedItems.map((status) => `status=${status}`).join('&');
      url += `${statusParams}`
    }

    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);
    console.log(response.data);
    console.log("----")
  return response.data;
} catch (error) {
  console.error(error);
}
};

// 주문배송 > 반품/취소 > 현황
export const fetchOrderAbortStatus = () => {
  return instance.get(`order/abort/status/`)
    .then(res => res.data);
};


// 주문배송 > 반품/취소 전체 조회
const fetchOrderAbort = (page, pageSize ,ordering) => {
  return instance.get(`order/abort/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderAbortListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['orderAbortList', page, pageSize, ordering], () => fetchOrderAbort(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 주문배송 > 반품/취소 조회 > 검색 
export const handleOrderAbortSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  selectedVendor,
  status,
}) => {
  try {
   let url = `order/abort/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break; 
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break; 
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate && selectedDateType) {
      url += `&${selectedDateType}=${selectedStartDate}__${selectedEndDate}`;
    }


    if(selectedVendor.name) url += `&vendor_name=${selectedVendor.name}` 
      

    if(status!='전체'){
      if(status === '처리완료') url += `&is_abort=true` 
      else  url += `&is_abort=false` 
    }


    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);
    console.log(response.data);
    console.log("----")
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 주문배송 > 반품교환취소 반품 조회
const fetchOrderReturn = (page, pageSize ,ordering) => {
  return instance.get(`order/abort/return/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderReturnListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['orderReturnList', page, pageSize, ordering], () => fetchOrderReturn(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 주문배송 > 반품/취소 조회 > 반품 검색 
export const handleOrderReturnSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  checkedItems,
  selectedVendor,
}) => {
  try {
   let url = `order/abort/return/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break; 
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break; 
        default:
          break;
      }
    }else{
      url += `?`;
    }
    
    if (selectedStartDate && selectedEndDate && selectedDateType) {
      url += `&${selectedDateType}=${selectedStartDate}__${selectedEndDate}`;
    }

    
    if(selectedVendor.name) url += `&vendor_name=${selectedVendor.name}` 
      
    if(checkedItems){
      const statusParams = checkedItems.map((status) => `return_status=${status}`).join('&');
      url += `${statusParams}`
    }


    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);
    console.log(response.data);
    console.log("----")
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 주문배송 > 반품교환취소 취소 조회
const fetchOrderCancel = (page, pageSize ,ordering) => {
  return instance.get(`order/deliveryitem/cancel/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderCancelListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['orderCancelList', page, pageSize, ordering], () => fetchOrderCancel(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 주문배송 > 반품/취소 > 취소 검색 
export const handleOrderCancelSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  checkedItems,
  selectedVendor,
}) => {
  try {
   let url = `order/deliveryitem/cancel/document/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break; 
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break; 
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&paid_at__range=${selectedStartDate}__${selectedEndDate}` 
    }

    if(selectedVendor) url += `&vendor_name=${selectedVendor.name}` 
      
    if(checkedItems){
      const statusParams = checkedItems.map((status) => `cancel_status=${status}`).join('&');
      url += `${statusParams}`
    }


    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 반품 수락 , 거절 , 회수요청 
export const setOrderReturnState = (reception_number, state, rejectReason) => {
  switch(state) {
    case "approval":
      return instance.post(`${BASE_URL}/returns/approve/`, {
        reception_number: reception_number,
        delivery_cost_bearer: rejectReason
      });
    case "rejection":
      return instance.post(`${BASE_URL}/returns/reject/`, {
        reception_number: reception_number,
        reject_reason: rejectReason
      });
    case "pickup":
      return instance.post(`${BASE_URL}/returns/delivery/reception/`, {
        reception_number: reception_number 
      });
    case "pickup_complete":
      return instance.post(`${BASE_URL}/returns/delivery/complete/`, {
        reception_number: reception_number
      });
    case "refund_approval":
        return instance.post(`${BASE_URL}/returns/refund/approve/`, {
          reception_number: reception_number
        });
    case "refund_rejection":
        return instance.post(`${BASE_URL}/returns/refund/reject/`, {
          reception_number: reception_number,
          reject_reason: rejectReason
        });
    default:
      break;
  }
};

// 주문관리 > 취소 수락 , 거절  
export const setOrderCancelState = (reception_number, state, rejectReason) => {
  switch(state) {
    case "approval":
      return instance.post(`${BASE_URL}/cancel/approve/`, {
        reception_number: reception_number,
      });
    case "rejection":
      return instance.post(`${BASE_URL}/cancel/reject/`, {
        reception_number: reception_number,
        reject_reason: rejectReason
      });

    default:
      break;
  }
};