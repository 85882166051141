/***************************************************************************************
* FileName      : VisitStats.js
* Description   : 해와관리자 > 통계 > 방문통계 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import Day from "./VisitStats/Day";
import Month from "./VisitStats/Month";
import Time from "./VisitStats/Time";

import React, { useState } from 'react';

function VisitStats()
{
    const [activeComponent, setActiveComponent] = useState("Month");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            {/*  방문통계  */}
            <div className='page-tit'>
                <h2>방문통계</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "Month" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Month")}>월별</button>

                <button className={activeComponent === "Day" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Day")}>일별</button>
          
                <button className={activeComponent === "Time" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Time")}>시간대별</button>
          
           
            </div>
            {/* 월별 */}
            {activeComponent === "Month" && <Month />}
            
            {/* 일별 */}
            {activeComponent === "Day" && <Day />}

            {/* 시간대별 */}
            {activeComponent === "Time" && <Time />}


        </div>

        </>
    )
}

export default VisitStats