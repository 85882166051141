/***************************************************************************************
* FileName      : StarRating.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 작성 가능한 리뷰 > 리뷰 쓰기 > 별점 선택 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.13
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState } from 'react';

const StarRating = ({rating,setRating}) => {

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  // 별점에 따른 설명을 반환하는 함수
  const getRatingDescription = (rating) => {
    switch (rating) {
      case 5:
        return '최고예요';
      case 4:
        return '좋아요';
      case 3:
        return '괜찮아요';
      case 2:
        return '그저그래요';
      case 1:
        return '별로예요';
      default:
          return '별점을 매겨주세요'; // 선택하지 않았을 때 기본 설명
    }
  };

  return (
    <div>
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            className={`star ${value <= rating ? 'selected' : ''}`}
            onClick={() => handleStarClick(value)}
          >
            &#9733;
          </span>
        ))}
      </div>
      
      <p className='pt-1'>
        {rating > 0 ? `${rating}점 (${getRatingDescription(rating)})` : getRatingDescription(rating)}
      </p>


    </div>
  );
};

export default StarRating;
