import React, { useState } from 'react';
// 검색
import { handleQASearch } from '../../../api/services/productApi.js';
import SearchPagination from '../common/SearchPagination.js';

function SearchProductModal({setIsOpen , setProductId })
{
  
  const [searchProduct, setSearchProduct] = useState('');

  const [selectedOption, setSelectedOption] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchConfirm, setSearchConfirm] = useState(false);
  const [searchUrl, setSearchUrl] = useState('');

  const updateSearchResults = (newResults) => {
    setSearchResults(newResults);
  };

  // 셀렉트 박스 변경 핸들러
  const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
  };
  const [fetchedData, setFetchedData] = useState(null);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

   // 검색버튼 클릭 시 

    const onSearch = () => {
        handleQASearch({
            searchProduct, 
            setSearchResults,
            setSearchUrl,
          
        });

        setSearchConfirm(true)
    };
    
    // 상품 선택
    const selectProduct = (Id) =>{
      setProductId(Id);
      setIsOpen(false);

    }

    const closeModal = () =>{
      setIsOpen(false);
    }

  // const handlePrevPage = () => {
  //     setPage(prevPage => prevPage - 1);
  // };
  
  // const handleNextPage = () => {
  //     setPage(prevPage => prevPage + 1);
  // };

  return (
        <>
         <div className="modal ">
            <div className="modal-content CenterModal" >
                <span className="close" onClick={closeModal}>&times;</span>

                <div className="popup-search-wrap">
                  <div className="popup-search-box">
                      <select onChange={handleSelectChange} value={selectedOption}>
                          <option value="상품명">상품명</option>
                      </select>

                      <input type="text" 
                              id="keyword"
                              value={searchProduct}
                              onKeyPress={handleKeyPress}
                              onChange={(e) => setSearchProduct(e.target.value)}
                              />
                    
                      <button className='btnSubmitFix2' onClick={onSearch}>검색</button>
                </div>
            </div>

            <p className='psmt'>총 {searchResults?.length}개 의 상품이 검색되었습니다.</p>
            <div className='s-p-table'>
                <table>
                    <colgroup>
                      <col style={{ width: '10%'}} />
                      <col style={{ width: '70%'}} />
                      <col style={{ width: '20%'}} />
                    </colgroup>
                  <thead>
                      <tr>
                        <th></th>
                        <th>상품 정보</th>
                        <th>선택</th>
                      </tr>
                  </thead>
                  <tbody>
                  {/* 검색 결과 */}
                  {searchConfirm && searchResults && searchResults.length === 0 ? (
                    <tr>
                      <td colSpan="3">검색 결과가 없습니다.</td>
                    </tr>
                    ) : searchResults && searchResults.length > 0 ? (
                      searchResults.map((result, index) => (
                      <tr key={index}>
                          <td className='thumbmain'>
                            <img src={result.main_image} alt={`Product ${index + 1}`} />
                          </td>
                          <td className='left'>
                            <div>
                              <p>{result.name}</p>
                              <strong>{result.price.toLocaleString()}원</strong>
                            </div>
                          </td>
                          <td className='cbtn'>
                            <button type="button" className="btn btn-light" onClick={() => selectProduct(result.id)}>
                              선택
                            </button>
                          </td>
                      </tr>
                    ))
                  ) : null}
                </tbody>
              </table>
            </div>

            <section className='mt-1'>
              {/* 페이지내비게이션 */}
              <SearchPagination
                  count={searchResults.length}
                  url={searchUrl}
                  updateSearchResults={updateSearchResults}
                />
    
            </section>
              

          
                

            </div>
          </div>
   
        </>
    )
}

export default SearchProductModal