/***************************************************************************************
* FileName      : header.js
* Description   : 고객페이지 > 헤더
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 2024.02.22 : PKH : 사용자가 검색할 때 검색 키워드 저장하는 api 호출 추가
* Reference     : 
***************************************************************************************/
import { useEffect, useState, memo } from 'react';
import { Routes, Route, Link , useNavigate, Outlet} from 'react-router-dom'
import { MobileSideBar } from './routes/SideMenu/MobileSideBar.js';
import { Container, Row, Col } from "react-bootstrap";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useCategoryMenuQuery } from './api/services/categoryMemuApi.js'

// 검색
import { handleSearch , statisticSearch } from './api/services/productApi.js'


// bootstrap icon
import { BiMenu, BiBookmark, BiLogIn, BiSmile, BiGift, BiLogOut, BiUser } from "react-icons/bi";
import { RiCustomerService2Line, RiShoppingCart2Line } from "react-icons/ri";

// 로그인 여부 확인
import { getMe, logOut } from './api/useLogin';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';


function Header(){
	const navigate = useNavigate();

	// 사용자 페이지 > 카테고리 메뉴
	const { data , error, isFetching } =  useCategoryMenuQuery();

	
	// 검색
	const [searchProduct, setSearchProduct] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [searchConfirm, setSearchConfirm] = useState(false);
	const [searchUrl, setSearchUrl] = useState('');


	// 로그인 상태 확인
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userId, setUserId] = useState('');

	// 회원여부 확인용 query
	let userLoginInfo = useQuery('loginChecker', ()=>{
		return getMe().then(res => {
			if (res?.data?.data === 'guest') {
				setUserId('guest');
				return 'guest'
			} else {
				setUserId(res?.data?.username);
				return res?.data?.username;
			}
		})
		.catch(error => {
			alert("회원정보 조회 오류",error?.response?.data);
			return 'guest'
		})
	})

	const [sitemap, setSitemap] = useState(false);

	const handleHover = () => {
		setSitemap(true);
		console.log("hover")
	};
	
	const handleMouseLeave = () => {
		setSitemap(false);
	};

	// 모바일 사이즈 체크 함수
	const isMobileSize = () => window.innerWidth < 760;

	// 모바일 메뉴
	const settings = {
		dots: false,
		arrows: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: true,
		variableWidth: true
	};

	// 모바일 사이드바
	const [sideBarState, setSideBarState] = useState(false);


	// enter key 감지
	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && searchProduct) {
		  onSearch(searchProduct);
		}
	};
	  
	// 검색버튼 클릭 시 

	const onSearch = async (searchProduct) => {

		try {
			const searchData = {
				keyword: searchProduct
			};
	
			const [searchResults, statistics] = await Promise.all([
				handleSearch({ searchProduct }),
				statisticSearch(searchData)
			]);

			navigate(`/product/search`, {
				state: {
					searchResults,
					searchProduct,
					searchUrl: searchResults.url,
				}
			});

			setSearchConfirm(true);
		} catch (error) {
			console.error('검색 결과를 가져오는 동안 오류가 발생했습니다:', error);
		}
	};

	// 스크롤 시 모바일 헤더 숨김
	const [hideHeader, setHideHeader] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	useEffect(() => {
		if(window?.innerWidth < 760){
			const handleScroll = () => {
				const currentScrollPos = window.scrollY; // 현재 스크롤 위치
				const isScrollingUp = currentScrollPos < prevScrollPos; // 스크롤 방향 체크
				if (isScrollingUp || currentScrollPos < 400) {
					setHideHeader(false);
				}else {
					setHideHeader(true);
				}
				setPrevScrollPos(currentScrollPos);
			};
			// 스크롤 이벤트를 추가
			window.addEventListener('scroll', handleScroll);
			// 컴포넌트가 언마운트시 스크롤 이벤트를 제거
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [window?.innerWidth, window?.scrollY]);

    return (
        <>
        <header className={hideHeader? "change_header hidden" : "change_header"}>
        <div className="lnb-outer ps-3 pe-3">
		    <div className="lnb">
			    <div className="lnb-navi">
					{/* <a onClick={()=>{alert('즐겨찾기 기능 준비중입니다')}} className='fw-light fs-8'><BiBookmark className='fw-light fs-8'/> 즐겨찾기</a> */}
			    </div>
			    <div className="lnb-navi">
					{
						// 로그인 여부 확인
						<div className="lnb-navi">
							{/* 로그인 확인 방식 query로 변경 */}
							{userLoginInfo?.data === 'guest' ? <GuestMenu/> : <UserMenu/>}
						</div>
					}
			    </div>
		    </div>
	    </div>
		
		<Container className="p-2">
			<Row className="align-items-center justify-content-evenly">
				{/* 모바일 사이드 메뉴 버튼 영역 */}
				<Col  sm={4} xs={5} className="d-md-none text-start">
					<BiMenu className='fs-1 text-hw' onClick={()=>{setSideBarState(true)}}/>
				</Col>
				{/* 로고 영역 */}
				<Col lg={2} md={3} sm={4} xs={2} className="d-flex justify-content-center justify-content-md-start">
					<div className="logo">
						<a onClick={()=>{window.location.href = '/'}}>
							<img src={process.env.PUBLIC_URL + '/img/Logo_00.png'} /> 
						</a>
					</div>
				</Col>

				{/* 검색영역(PC, Tablet) */}
				<Col lg={8} md={6} className="d-none d-md-block">
					<div className="search">
						<div className='search-area align-items-center'>
							<label className='search_box'>
								<input type="text" 
										name="search_text" 
										placeholder='검색어를 입력해 주세요.'
										value={searchProduct}
                                    	onChange={(e) => setSearchProduct(e.target.value)}
										onKeyDown={handleKeyDown} /> 
							</label>
							<button onClick={() => onSearch(searchProduct)} >
								<i className='xi-search text-hw'></i>
							</button>
						</div>
					</div>
				</Col>

				{/* 우측 마이페이지, 장바구니 영역 */}
				<Col lg={2} md={3} sm={4} xs={5} className="text-end">
					<div className="d-inline-block text-center ps-2 pe-2 header-icon" onClick={()=>isMobileSize() ? navigate('/mypage/mobile') : navigate('/mypage/order')}>
						<BiUser className="fs-3 header-icon"/>
						<p className="fs-8 fw-bold d-none d-md-block">마이페이지</p>
					</div>
					<div className="d-inline-block text-center ps-2 pe-2 header-icon cart-icon" onClick={()=>navigate('/member/cart')}>
						<RiShoppingCart2Line className="fs-3"/>
						{/* <span className='cart-badge'>1</span> */}
						<p className="fs-8 fw-bold d-none d-md-block">장바구니</p>
					</div>
				</Col>
			</Row>

			{/* 모바일 검색 영역 */}
			<Row className="mt-3 d-md-none">
				<Col xs={12} className=''>
					<div className="search">
						<div className='search-area align-items-center'>
							<label className='search_box'>
								<input type="text" 
										name="search_text" 
										placeholder='검색어를 입력해 주세요.'
										value={searchProduct}
                                    	onChange={(e) => setSearchProduct(e.target.value)}
										onKeyDown={handleKeyDown} /> 
							</label>
							<button onClick={() => onSearch(searchProduct)} >
								<i className='xi-search text-hw'></i>
							</button>
						</div>
					</div>
				</Col>
			</Row>
		</Container>		

		{/* 모바일 사이드 메뉴 */}
		<MobileSideBar state={sideBarState} setState={setSideBarState} userId={userLoginInfo?.data} category={data}/>

		{/* 상단메뉴gnb */}
        <div className="gnb-outer">
		    <nav>
				<Container>
					<div className="gnb">
						<div className="nav_category_area" 
							 onMouseEnter={handleHover}
							 onMouseLeave={handleMouseLeave}>
							<span className="fs-4 text-hw me-2"><BiMenu/></span>
							카테고리
						</div>
						<div>
							<a href={`/product/list?category=전체`}>전체상품</a>
						</div>
						<div>
							<a href={'/product/list?category=생물'}>생물</a>
						</div>
						<div>
							<a href={'/product/list?category=반건조'}>반건조</a>
						</div>
						<div>
							<a href={'/product/list?category=냉동'}>냉동</a>
						</div>
						<div>
							<a href={'/product/seasonals'}>제철수산물</a>
						</div>				
						<div>
							<a href={'/product/mokpobest'}>목포9미</a>
						</div>
						<div>
							<a href={'/product/vendors'}>수산상회</a>
						</div>
						<div >
							<a href={'/product/giftset'}>수산선물세트</a>
						</div>
	
					</div>
				</Container>

			
				{
					sitemap == true 
					?
					<Category data={data} handleHover={handleHover} handleMouseLeave={handleMouseLeave} />
					:
					null
				}
			
		    </nav>	
	    </div>

		{/* 모바일용 gnb */}
		<Container className="mobile-gnb">
			<Slider {...settings}>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/list?category=전체')}>전체상품</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/list?category=생물')}>생물</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/list?category=반건조')}>반건조</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/list?category=냉동')}>냉동</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/seasonals')}>제철수산물</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/mokpobest')}>목포9미</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/vendors')}>수산상회</div>
				<div className="menu-item fs-7 ps-2 pe-2 fw-bold" onClick={()=>navigate('/product/giftset')}>수산선물세트</div>

			</Slider>

			
		</Container>
        </header>    
        </>
    )

}



function Category({ data ,handleHover, handleMouseLeave })
{

	const [menuData, setMenuData] = useState([]);

	useEffect(() => {
		if (data) {
		  setMenuData(data);
		}
	}, [data]);
	
	const [activeCategory, setActiveCategory] = useState('0');

	return (
	<>
		{menuData.length === 0 ? (
        	<div></div>
      	) : (
		<div className="sitemap-outer"  
			onMouseEnter={handleHover}
			onMouseLeave={handleMouseLeave}
			>

			<ul className="CategoryList">
				{menuData.map((item, index) => (
					<li className={activeCategory === index ? "categoryDepth1 active-category" : "categoryDepth1" }
						key={item.id} 
						onMouseEnter={()=>{setActiveCategory(index)}}
					>
						<a href={`/product/list?category=${item.name}/${item.id}/`} 
							key={item.id} className="c-tit">
							{item.name}
						</a>

						{item.children && (
							<div className='categorySub'>
								{item.children.map((childItem) => (
									<a href={`/product/list?category=${childItem.name}/${childItem.id}/${childItem.parent}`} 
										key={childItem.id}>
									{childItem.name}
							
									</a>
								))}
							</div>
						)}
					</li>
				))}
			</ul>
		</div>
      )}
	</>
		
	)
}

function GuestMenu(){
	const navigate = useNavigate();
	return (
		<>
			<a onClick={() => navigate('/member/login')} className='fw-light fs-8'><BiLogIn className='fw-light fs-8' /> 로그인</a>
			<a onClick={() => navigate('/member/join2')} className='fw-light fs-8'><BiSmile className='fw-light fs-8' /> 회원가입</a>
			<a onClick={() => navigate('/member/login')} className='fw-light fs-8'><BiGift className='fw-light fs-8' /> 주문조회</a>
			<a onClick={() => { navigate('/customer/notice') }} className='fw-light fs-8'><RiCustomerService2Line className='fw-light fs-8' /> 고객센터</a>
		</>
	);
}

function UserMenu(){

	const navigate = useNavigate();

	// 로그아웃 함수
	const logout = () => {
		// 토큰 삭제
		logOut()
			.then(res => {
				console.log(res?.data);
				Cookies.remove('access');
				Cookies.remove('refresh');
				// 메인페이지로 새로고침
				navigate('/');
				window.location.reload();
			})
		
	}
	return (
		<>
			<a onClick={()=>logout()} className='fw-light fs-8'><BiLogOut className='fw-light fs-8' /> 로그아웃</a>
			<a onClick={() => navigate('/mypage/order')} className='fw-light fs-8'><BiGift className='fw-light fs-8' /> 주문조회</a>
			<a onClick={() => { navigate('/customer/notice') }} className='fw-light fs-8'><RiCustomerService2Line className='fw-light fs-8' /> 고객센터</a>
      	</>
	);
}


export default Header