import { useState , useEffect } from 'react';

import NewRegistration from "./Category/NewRegistration";
import Edit from "./Category/Edit";
import { EditPriority } from './Category/EditPriority';

import MainCategories from './Category/MainCategories';
function CategoryManagement()
{
 
    const [activeComponent, setActiveComponent] = useState("NewRegistration");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };
 
    return(
        <>
        <div className='mainContents'>
            {/* 카테고리 관리 */}
            <div className='page-tit'>
                <h2>카테고리/필터링</h2>
            </div>

            {/* 신규등록/편집/순서변경 선택 */}
            <div className='setting-tab-buttons'>
                <button className={activeComponent === "NewRegistration" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("NewRegistration")}>신규등록</button>
                <button className={activeComponent === "Edit" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Edit")}>편집</button>
                        <button className={activeComponent === "EditPriority" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("EditPriority")}>순서변경</button>
            </div>

            {/* 카테고리 선택 */}
            <section className='McontentBox m-p0'>
                <div className='Mgray'>
                    {/* 대분류 */}
                    {activeComponent !== "EditPriority" && <MainCategories />}
                </div>        
            </section>

            

            {/* 신규등록 */}
            {activeComponent === "NewRegistration" && <NewRegistration />}
            
            {/* 편집 */}
            {activeComponent === "Edit" && <Edit />}

            {/* 편집 */}
            {activeComponent === "EditPriority" && <EditPriority />}
  
            </div>

        </>
    )
}


export default CategoryManagement