import { instance } from "../../axiosInstance/axiosConfig"

// 셀러관리자 > 통계 > 판매통계(일별)
export const getSellerSalesDaily = () =>
instance.get(`/statistic/seller-sales-daily/`).then(response => response.data)

// 셀러관리자 > 통계 > 판매통계(일별) 
export const handleSellerSalesDaily  = async ({
    setSearchResults,
    searchYear,
    }) => {
    try {
        let url = `statistic/seller-sales-daily/`;
      
        if(searchYear){
            url += `?year=${searchYear}`;
        }

        console.log(url);
        console.log('---url--');

        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 해와관리자 > 통계 > 검색통계
// export const handleSearchStatisticSearch   = async ({
// setSearchResults,
// selectedStartDate,
// selectedEndDate
// }) => {
// try {
//     let url = `/statistic/seller-sales-monthly/`;
  
//     if(selectedStartDate && selectedEndDate ){
//         url += `?created_at__range=${selectedStartDate}__${selectedEndDate}`;
//     }
//     console.log(url)

//     const response = await instance.get(url);
//     setSearchResults(response.data);

//     return response.data;
// } catch (error) {
//   console.error(error);
// }
// };
