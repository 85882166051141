import { Modal } from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';

function AddressModal({show, onHide, onComplete }){
    return(
        <Modal size={'md'} show={show} onHide={()=>onHide(false)} aria-labelledby="sm-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title id='sm-modal' className='fs-6'>
                    우편번호 검색
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <DaumPostcode autoClose onComplete={onComplete} />

            </Modal.Body>
        </Modal>
    );
}


export default AddressModal