import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import Sidemenu from './Sidemenu.js';
import MypageTabComponent from '../mypage/MypageTab';

import Pagination from './common/Pagination';
import SearchPagination from './common/SearchPagination.js'

import { handleOtoSearch } from '../../api/services/customerApi.js'

import { OnetooneRow } from './Onetoone/OnetooneRow.js';
import { useCenterListQuery  , handleCenterSearch  } from '../../api/hwadmin/customerApi.js';

function Onetoone()
{
    const { data, error, isFetching, page, setPage , pageSize , setPageSize} = useCenterListQuery();

    const [searchOto, setSearchOto] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');


    const navigate = useNavigate();

    const handleOrderNumberClick = (NoticeId) => {
        navigate(`./${NoticeId}`);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

 
    console.log(data)

    const onSearch = () => {
        handleOtoSearch({
            searchOto, 
            setSearchResults,
            setSearchUrl,
        }
        );
        setSearchConfirm('true')
    };

    const handleWriteBtnClick = () => {
        navigate(`/customer/oto/write/`);
    };


    return(
        <>
        <Container>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    {/* 좌측 사이드바 */}
                    <Col md={3} lg={3}><Sidemenu nowPage={'oto'}/></Col>

                    <Col xs={12} sm={12} md={9} lg={9}>
                        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                        <Row className='m-0 p-0'>
                            <Col className='m-0 p-0'>
                                <h3 class="m-0 p-0 fs-4 fw-bold">1:1 문의</h3>
                            </Col>
                        </Row>

                        {/* 검색 */}
                        <div className='mt-5 customer_search_box'>
                            <input id="search" 
                                    name="search" 
                                    type="text"
                                    value={searchOto}
                                    onChange={(e) => setSearchOto(e.target.value)}
                                    placeholder='제목,내용'/>

                            <a className='sBtn' onClick={onSearch}>검색</a>
                        </div> 

                        {/* 테이블 컬럼명 */}
                        <div className='mt-5 p-0'>
                            <Row className='border-top-dark border-bottom m-0 p-0 d-flex'>
                                <Col md={1} className='text-center py-4 fs-12 fw-bold d-none d-md-flex justify-content-center'>번호</Col>
                                <Col xs={5} md={5} className='text-center py-4 fs-12 fw-bold'>제목</Col>
                                <Col xs={2} md={1} className='text-center py-4 fs-12 fw-bold'>작성자</Col>
                                <Col xs={1} md={2} className='text-center py-4 fs-12 fw-bold'>작성일</Col>
                                <Col xs={1} md={3} className='text-center py-4 fs-12 fw-bold'>답변</Col>
                            </Row>
                        </div>

                        {/* 콘텐츠 내용 */}

                        <div className='m-0 p-0'>
                            {searchResults.count > 0 ? (
                                // 검색 결과를 렌더링하는 코드
                                searchResults.data.map((oto, index) => (
                                    <OnetooneRow 
                                        index={index} 
                                        oto={oto}/>
                                ))
                            ) : data && data.data && data.data.length > 0 ? (
                                // 기본 데이터를 렌더링하는 코드
                                data.data.map((oto, index) => (
                                    <OnetooneRow 
                                        index={index} 
                                        oto={oto}/>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="23">검색 결과가 없습니다.</td>
                                </tr>
                            )}
                        </div>

                     
                    {/* 페이지내비게이션 */}
                    <section className='mt-5'>
                        {searchConfirm ? (
                            <SearchPagination
                                count={searchResults.totalCnt}
                                url={searchUrl}
                                updateSearchResults={updateSearchResults}
                            />
                            
                        ) : (
                            <Pagination
                                count={data?.totalCnt || 0}
                                page={page}
                                setPage={setPage}
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                            />
                        )}
                    </section>

                </div>

                {/* <section className="d-flex justify-content-end pt-4">
                    <button className='btnSubmitFix sizeS' onClick={handleWriteBtnClick}>1:1문의 쓰기</button>
                </section> */}
            </Col>
          
            </div>
        </Row>  
    </Container>
        </>
    )
}



export default Onetoone