import {Row, Col} from 'react-bootstrap';
import { useNavigate , useLocation } from 'react-router-dom'

export default function MypageTabComponent2({tabs})
{
    const location = useLocation();
    const navigate = useNavigate();

    const currentPath = location.pathname;

    const handleTabClick = (Url) => {
        navigate(Url);
    };

    return (
        <>
        <Row className="text-center mt-3 mb-4 m-0 p-0">
            {tabs.map((tab) => (
                <Col
                    key={tab.label}
                    className={`border ${currentPath === tab.url ? 'bg-hw text-white' : 'bg-grey text-hw'} mypage_tabbtn`}
                    onClick={() => handleTabClick(tab.url)}
                    >
                    {tab.label}
                </Col>
            ))}
        </Row>

        </>
    );
}