import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useResultUsername, findIdVerifyEmail } from '../../../api/services/memberApi';
//사용자페이지 > 아이디 찾기 > 결과 페이지 
function VerifyEmail()
{
    // 현재 페이지의 URL을 가져오기
    const url = window.location.href;


    // URL을 파싱하여 검색 매개변수를 추출
    const searchParams = new URL(url).searchParams;
    const email = searchParams.get("email");
    const verification_code = searchParams.get("verification_code");

    const { post, isPostLoading, error } = useResultUsername();

    const [userId, setUserId] = useState('');

    useEffect(()=>{
        findIdVerifyEmail(email, verification_code)
            .then(res => setUserId(res?.data?.username))
            .catch(error => alert(error?.response?.data?.data))
    },[])

    const navigate = useNavigate();



    useEffect(()=>{
        const data = {
            email : email || "",  
            verification_code : verification_code || ""
        };

        console.log(data)


        // post(data)
        // .then((data) => {
        //     console.log("결과",data);

        //     // window.location.href = '/member/login/'; // 로그인 페이지 리로드
        // })
        // .catch(error => {
        //     console.log("등록 실패:");
        //     console.log(error)
        // });
    })
    
    return(
        <>
        <Container>
            <Row>
                <div className='find_wrap'>
                    <div id="contents">
                        <div className="find-titleArea">
                            <h2>아이디 찾기</h2>
                            <p>        
                            {email}로 가입된 아이디는
                            <br/>
                            <span className='text-hw fw-bold'>{userId}</span> 입니다.
                            </p>
                        </div>
                      

                        {/* 
                        <div className='findId'>
                            <div className='name_view'>
                                <strong id="name_label">아이디</strong>
                            </div>
                        </div>
                        */}
                        <div className='ec-base-button'>
                      
                            <ul>
                                <li className='pointer'>
                                    <a onClick={()=>{navigate('../login')}}>로그인하기</a>
                                </li>
                                <li className='pointer'>
                                    <a onClick={()=>{navigate('../findpasswd')}}>비밀번호 찾기</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Row>
            
        </Container>
        </>
    )
}

export default VerifyEmail