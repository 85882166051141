/***************************************************************************************
* FileName      : AddModal.js
* Description   : 해와관리자 > 정산 > 지급내역 > 지급내역추가 모달창
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect , useRef } from 'react';
import { Modal , Button } from 'react-bootstrap';
import { RegisterAdjustGroup } from '../../../../api/hwadmin/adjustApi';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import SelectVendor from '../../product/Component/SelectVendor';

import { useAdjustVendorScheduleQuery } from '../../../../api/hwadmin/adjustApi';
import VendorSchedule from './VendorSchedule';
import Pagination from '../../common/Pagination';

function AddModal({show, setShow})
{
    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();
    const [pageSize, setPageSize] = useState(10); 

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    const selectedVendorId = selectedVendor.id;

    const { data, error, isFetching, page, setPage } = useAdjustVendorScheduleQuery(selectedVendorId);


    // 전체체크 
    const [selectAll, setSelectAll] = useState(false);
    // 체크박스 체크 했을 때 vendors의 id 데이터 저장 
    const [selectedAdjust_vendors, setSelectedAdjust_vendors] = useState([]);
    
    // 전체 체크 
    const vendorschedule = data?.data; 
    const allVendorIds = vendorschedule?.map((data) => data.vendor); 

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);

        if (checked) {
            setSelectedAdjust_vendors(allVendorIds);
        } else {
            setSelectedAdjust_vendors([]);
        }
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };

    const createMutaion = useMutation(RegisterAdjustGroup , {
        onSuccess : (data) => {
            reset();
            alert("완료되었습니다.");
            setIsLoading(false);
            setShow(false);
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    }); 

    const onSubmit = () => {
        setIsLoading(true);

        const newData = {
            "adjust_vendors": selectedAdjust_vendors.join(',')
        };

        createMutaion.mutate(newData);
    };

    // 개별 체크 박스 
    const handleCheckboxChange = (Id, checked) => {
        console.log(checked);
        if (checked) {
            setSelectedAdjust_vendors((prevSelectedVendors) => [...prevSelectedVendors, Id]);
        } else {
            setSelectedAdjust_vendors((prevSelectedVendors) =>
            prevSelectedVendors.filter((id) => id !== Id)
        );
        }
    };

        
    return (
        <>
         <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
        
        <Modal.Header closeButton>
            <Modal.Title  style={{ fontSize: '18px' }}>
                지급내역 추가(0)
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>

        <div className="d-flex align-items-center mb-5" style={{ gap: '10px' }}>
            <p>수산상회</p>
            <SelectVendor 
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
            />   
        </div>

            
        <form onSubmit={handleSubmit(onSubmit)}>    
            <table className='sTable' id="addModalInput">
                <thead>
                    <tr>
                        <th>     
                            <input type="checkbox"
                                    name="Allcheck"
                                    value=""
                                    onChange={handleSelectAll}    
                            />
                        </th>
                        <th>주문번호</th>
                        <th>수산상회</th>
                        <th>구매확정일</th>
                        <th>상품명</th>
                        <th>정산대상금액</th>
                        <th>구매자 ID <br/>
                            구매자 이름
                        </th>
                        <th>수취인 이름 <br/>
                            수취인 연락처
                        </th>
                        <th>주소</th>
                    </tr>
                </thead>
                <tbody> 
                    {
                        data?.data?.map((adjustVendor) => (
                            <VendorSchedule 
                                VendorSchedule={adjustVendor}
                                onCheckboxChange={handleCheckboxChange}
                                isChecked={selectedAdjust_vendors.includes(adjustVendor.id)}
                            />
                        ))
                    }
                </tbody>
            </table>

            {/* 페이지내비게이션 */}
            <section className='mt-4'>
            {  
                data?.data ? (
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                ) : null
                    
            }
            </section>

            <div className='btn-wrap-center mt-4'>
                <Button type="submit" variant="primary"> 
                    {isLoading ?  <LoadingSpinner /> : '확인'} 
                </Button>
            </div>
        </form>
        </Modal.Body>
    </Modal>
    </>
    )
} 

export default AddModal