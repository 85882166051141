/***************************************************************************************
* FileName      : ProductStats.js
* Description   : 해와관리자 > 통계 > 상품통계 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import Seller from "./ProductStats/Seller";
import Category from "./ProductStats/Category";


import React, { useState } from 'react';

function ProductStats()
{
    const [activeComponent, setActiveComponent] = useState("Seller");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>상품통계</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "Seller" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Seller")}>판매자별</button>

                <button className={activeComponent === "Category" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Category")}>카테고리별</button>
          
            </div>

            {/* 판매자별 */}
            {activeComponent === "Seller" && <Seller />}
            
            {/* 카테고리별 */}
            {activeComponent === "Category" && <Category />}



        </div>

        </>
    )
}

export default ProductStats