function SideCommunity()
{
	return(
		<>
			<li>
				<a href='/customer/notice'>공지사항</a>
			</li>
			<li>
				<a href='/customer/faq'>자주묻는질문</a>
			</li>
			<li>
				<a href='/customer/qna'>상품 Q&A</a>
			</li>
			<li>
				<a href='/customer/oto'>1:1 문의</a>
			</li>
		</>
	)
}
export default SideCommunity