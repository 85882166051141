/***************************************************************************************
* FileName      : chartConfig.js
* Description   : 해와관리자 > 가입통계 > chartConfig
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
export const labelDataObject = {
    male_user: '남성회원',
    female_user: '여성회원',
};
  
export const chartBackgroundColor = {
    female_user: '#F6B6C1',
    male_user: '#AACFF2',
};

export const chartBorderColor = {
    female_user: '#ED6E85',
    male_user: '#57A0E5',
};