import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';
import { useMutation } from 'react-query';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../api/apiConfig';

function Register()
{

    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        password2: '',
        name: '',
        email:'',
        phone_number: '',
        gender: ''
    });


    const { username, password, password2, name, email,phone_number } = formData;

    const signupMutation = useMutation((data) => {
      return axios.post(`${BASE_URL}/auth/signup/`,data, {withCredentials: true})
        .then(function(response){
            console.log(response);
            console.log(response.data);
            alert("성공");
            navigate('/member/login/');

            if (response.status === 201) {
        
            }
        })
        .catch((err) => {
        console.log(err)
        });
    });

    const onSubmit = async (e) => {
        if(name === "") {
            alert("이름 항목은 필수입니다.")
            return ;
        }
        if(username === "") alert("아이디는 항목은 필수입니다.")
        if(password === "") {
            alert("비밀번호는 항목은 필수입니다.")
        }else{
            //비밀번호 유효성검사(영문,숫자 혼합 6~20)
            const chkPwd = function(str) {
                var reg_pwd = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
                return !reg_pwd.test(str) ? false : true;
            };

            if (chkPwd(password) === false) {
                alert("영문,숫자를 혼합하여 6~12자 이내");
            }else{
                if(password2 === "") {
                    alert("비밀번호확인 항목은 필수입니다.")
                }else if(password != password2) {
                    alert("비밀번호가 불일치합니다.");
                }
            }
        }
      

        e.preventDefault();
        try {
            console.log(formData)
          await signupMutation.mutateAsync(formData);
        
          // 회원가입이 성공했을 때 다음 페이지로 이동하는 등의 작업 수행
        } catch (error) {
          console.log(error);
          // 회원가입이 실패했을 때 에러 메시지를 출력하거나 폼 데이터를 초기화하는 등의 작업 수행
        }
    };

    return(
        <>
            <Container>
                <div className='customer_wrap'>
                    <div className='tit_wrap'>
                        <h3>회원가입</h3>
                    </div>
                    
                    <div className='tab_wrap'>
                        <ul>
                            <li>
                                <a className='on'>개인</a>
                            </li>
                            <li>
                                <a onClick={()=>alert('기업 가입 문의는 010-0000-0000으로 연락 부탁드립니다.')}>기업</a>
                            </li>
                        </ul>
                    </div>
                  
                    <h5 className='cont_tit'>개인회원 정보 입력</h5> 

                    {/* 개인 회원가입 */}
                    <div className='basic_wrap'>
                        <form onSubmit={onSubmit}>
                            <table width="100%" border="0" className='res_form_wrap'>
                                <tbody>
                                    <tr>
                                        <td className='tbt_tit'>
                                            이름
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                            <input type="text"
                                             name="name" 
                                             placeholder="이름"
                                             value={name}
                                             onChange={(e) =>
                                             setFormData({ ...formData, name: e.target.value })}
                                             />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            아이디
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                            <input type="text"
                                             name="username" 
                                             placeholder="아이디"
                                             value={username}
                                             onChange={(e) =>
                                                setFormData({ ...formData, username: e.target.value })
                                            }
                                             />
                                            <span className='desc'>공백 없는 영문, 숫자 포함 6-20자</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            비밀번호
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                            <input type="password"
                                            name="password"
                                            placeholder="비밀번호"
                                            value={password}
                                            onChange={(e) =>
                                                setFormData({ ...formData, password: e.target.value })
                                            }
                                            />
                                            <span className='desc'>
                                                공백 없는 영문 대소문자 또는 숫자, 특수문자 중 2가지 이상 조합 10-20자
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            비밀번호확인 
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                            <input type="password"
                                            name="password2"
                                            placeholder="비밀번호확인"
                                            value={password2}
                                            onChange={(e) =>
                                            setFormData({ ...formData, password2: e.target.value })}
                                            />
                                            <span className='desc'>
                                                비밀번호 확인을 위해 한번 더 입력하세요
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            성별 
                                        </td>
                                        <td>
                                            <div onChange={e=>setFormData({...formData, gender: e.target.value})}>
                                                <input type='radio' id="check_method1" name='gender' value='male'  />
                                                <label for="check_method1">남자</label>

                                                <input type='radio' id="check_method1" name='gender' value='female'   />
                                                <label for="check_method1">여자</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            이메일 
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                        <div className='pdtb9 email'>
                                        <input
                                            type="text"
                                            placeholder="이메일"
                                            value={email}
                                            onChange={(e) =>
                                            setFormData({ ...formData, email: e.target.value })
                                            }
                                        />
                                                {/* <input type='text' name="email[0]" size="10"/>
                                                @
                                                <input type='text' name="email[1]" size="10"/>
                                                <div className='select_wrap01'>
                                                    <select name="find_email" id="find_email">
                                                        <option value>직접선택</option>
                                                        <option value="naver.com">naver.com</option>
                                                        <option value="nate.com">nate.com</option>
                                                        <option value="dreamwiz.com">dreamwiz.com</option>
                                                        <option value="yahoo.co.kr">yahoo.co.kr</option>
                                                        <option value="empal.com">empal.com</option>
                                                        <option value="gmail.com">gmail.com</option>
                                                        <option value="korea.com">korea.com</option>
                                                    </select>
                                                </div> */}
                                                <p className='sub_text_line'>
                                                    정확한 E-mail 정보를 입력하지 않으시면, 주문,결제,이벤트,아이디찾기,비밀번호 찾기 등에 대한 정확한 정보를 받지 못하실 수 있습니다.
                                                </p>
                                                <p className='sub_text'>
                                                - E-mail을 통한 쇼핑몰 공지사항, 할인이벤트 정보, 각종 쿠폰등을 받아 보실 수 있습니다.
                                                </p>
                                                <p className='sub_text'>
                                                - 결제/교환/환불 등의 주문거래의 관련 E-mail은 수신동의 여부와 상관없이 발송됩니다.
                                                </p>
                                                <p className='addx'>
                                                    <label className='small'>
                                                        <input type="checkbox" name="mailing" value="Y"/>
                                                        정보메일을 수신하겠습니다.
                                                    </label>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            휴대폰번호 
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="휴대폰번호"
                                                value={phone_number}
                                                onChange={(e) =>
                                                setFormData({ ...formData, phone_number: e.target.value })
                                                }
                                            />
                                            <div className='pdtb9'>
                                                <p className='sub_text pdt9'>
                                                - 쇼핑몰에서 제공되는 다양한 이벤트/할인정보/공지사항등을 SMS로 받아 보실 수 있습니다.
                                                </p>
                                                <p className='sub_text pdt9'>
                                                - 결제/교환/환불 등의 주문거래의 관련 이메일은 수신동의 여부와 상관없이 발송됩니다.
                                                </p>
                                                <p className='addx'>
                                                    <label className='small'>
                                                        <input type="checkbox" name="mailing" value="Y"/>
                                                        정보메일을 수신하겠습니다.
                                                    </label>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            전화번호 
                                        </td>
                                        <td>
                                        <span className='inpt3'>
                                                <input type="text" name="phone[]" value="" size="6" maxLength="4"/>
                                                -
                                                <input type="text" name="phone[]" value="" size="6" maxLength="4"/>
                                                -
                                                <input type="text" name="phone[]" value="" size="6" maxLength="4"/>
                                        </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tbt_tit'>
                                            주소
                                            <span className='red_star'>*</span>
                                        </td>
                                        <td>
                                        
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='btn_wrap'>
                                <button type="submit" className='btn_submit' id="btn_register">확인</button>
                                <a className='btn_cancel' onClick={()=>console.table(formData)}>취소</a>
                            </div>
                        </form>
                    </div>

                    
                </div>
            </Container>
        </>
    )


}

export default Register