// initializeSelectedItems.js
// cartDeliveryItems 데이터로 새로운 배열 selectedItems , selectedOrders 만드는 함수 

// selectedItems : 사용자가 선택한 상품(화면에 보여주기 위한 정보 , 체크박스 기능)
// ->  cart_item_id , delivery_fee , total_price (체크 했을 때 배송비 , 상품 금액 등 계산을 위한 )

// selectedOrders : "주문하기" 할 때 보내야될 id들

// cartDeliveryItems : 사용자가 (체크박스) 선택한 상품(상세 모든 정보 ,체크박스 기능)


export const createCartItemArray = (cartDeliveryItems) => {
    return cartDeliveryItems.map((deliveryItem) =>
        deliveryItem.cart_items.map((cartItem) => {
            const newItem = {
                cart_item_id: cartItem.cart_item_id,
                total_price: cartItem.total_price,
            };

            if (cartItem.delivery_fee) {
                newItem.delivery_fee = cartItem.delivery_fee;
            }

            return newItem;
        })
    );
};

export const initializeSelectedItems = (cartDeliveryItems, setSelectedItems, setSizeCheckboxes, setSelectedOrders) => {
    const cartItemIdsArray = createCartItemArray(cartDeliveryItems);
    setSelectedItems([...cartItemIdsArray]);

    const cartItemCounts = cartDeliveryItems.map((deliveryItem) =>
        deliveryItem.cart_items.length
    );
    setSizeCheckboxes(cartItemCounts);

    const newSelectedOrders = cartItemIdsArray
        .map((cartItems) => cartItems.map((item) => item.cart_item_id))
        .flat();
    setSelectedOrders(newSelectedOrders);
};
