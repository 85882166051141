/***************************************************************************************
* FileName      : ProductReview.js
* Description   : 고객 > 상품 상세페이지 > 리뷰조회
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useProductReviewListQuery } from '../../../api/product/product';

import Pagination from '../common/Pagination'
import ViewStarRating from '../../mypage/Reviewable/ViewStarRating';

export default function ProductReview({id})
{
    const { data, error, isFetching, page, setPage } = useProductReviewListQuery(id);

    console.log(data);
    console.log("-리뷰")
    const navigate = useNavigate();

    const [expandedRows, setExpandedRows] = useState([]);


    const toggleRow = (index) => {
      const newExpandedRows = [...expandedRows];
      if (newExpandedRows.includes(index)) {
        newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
      } else {
        newExpandedRows.push(index);
      }
      setExpandedRows(newExpandedRows);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    console.log(data)

  
    return(
        <>
  
        <div className='ec-base-tab'>
            <h3>
                상품후기 
                <span>{data?.totalCnt}</span>
            </h3>
            <p className='notice-p-txt'>상품을 구매하신 분들이 작성하신 리뷰입니다.</p>
        </div>
 
   

        {data && data.data && data.data.length > 0 ? (
            <table className='ec-table'>
                 <colgroup>
                    <col style={{ width: '70%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '25%' }} />
                </colgroup>
                <thead>
                    <tr>  
                        <td>후기</td>
                        <td>작성자</td>
                        <td>작성일</td>
                    </tr>
                </thead>

                <tbody>
                {data.data.map((productInquiry, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td className=''>
                   
                            <ViewStarRating 
                                rating={parseFloat(productInquiry.rating)}
                            />

                            {productInquiry.content}

                            <div className='text-left mt-3'>
                            {productInquiry.review_images && productInquiry.review_images.length > 0 ? (
                                productInquiry.review_images.map((image, index) => (
                                    <img 
                                        key={image.id} 
                                        src={image.path} 
                                        alt={`Product ${index}`} 
                                        className="imagePreview" />
                                ))
                            ) : (
                                <></>
                            )}
                            </div>
                          

                      
                            </td>
                            
                            <td>
                                <span>{productInquiry.user.username}</span>
                            </td>
                                       
                            <td><span>{productInquiry.updated_at.split("T")[0]}</span></td> 
                        </tr>

                        {/* {expandedRows.includes(index) && productInquiry.content && (
                        <tr> 
                            <td className='p-0' colSpan="4" >    
                                <div className='review-view'>
                                    <p dangerouslySetInnerHTML={{ __html: productInquiry.content }}></p>
                                </div>
                               
                            </td>
                        </tr>
                        )} */}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        ) : (
            <p className="fs-6 fw-bold text-center mb-5">상품문의 데이터가 없습니다.</p>
        )}

        <section className='py-5'>
            <Pagination
                count={data?.totalCnt || 0}
                page={page}
                setPage={setPage}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                />
        </section>
        
     

        </>
    )
}