import { useMainCategories } from '../../../../../api/Category'
import { useQueryClient } from 'react-query';
import React, { useState, useEffect  , useMemo } from 'react';
import generateOptionCombinations from './generateOptionCombinations'
import { addCommasToNumberString } from '../../../common/numberUtils';

function UseOption({option_values, setOptionValues ,option_rows, setOptionRows})
{
    const MAX_OPTIONS = 3;

    // option_rows 저장
    // const [option_rows, setOptionRows] = useState([]);

    // option_values 저장
    // const [option_values, setOptionValues] = useState({});

    const [optionList, setOptionList] = useState([{ name: '', value: '' }]);
    const [optionCombinations, setOptionCombinations] = useState([]);

    const [displayTable, setDisplayTable] = useState(false);
    
    // const [regularPrice, setRegularPrice] = useState('');
    // const [salePrice, setSalePrice] = useState('');
    // const [stockQuantity, setStockQuantity] = useState('');
  
    // 정상가, 판매가, 재고량

    const [tableInputs, setTableInputs] = useState([]);

    // const handleRegularPriceChange = (event) => {
    //   setRegularPrice(event.target.value);
    // };
  
    // const handleSalePriceChange = (event) => {
    //   setSalePrice(event.target.value);
    // };
  
    // const handleStockQuantityChange = (event) => {
    //   setStockQuantity(event.target.value);
    // };

    const addOption = () => {
        if (optionList.length >= MAX_OPTIONS) {
            alert("옵션은 최대 3개까지만")
            return;
        }
        setOptionList([...optionList, { name: '', value: '' }]);
    };

      
    const [isOptionActive, setIsOptionActive] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    // 옵션 미리보기 확인 
    const [previewConfirm, setPreviewConfirm] = useState(false);


    const handleDeleteOption = (index) => {
        const newOptionList = [...optionList];
        newOptionList.splice(index, 1);
        setOptionList(newOptionList);
    };

    const handleOptionApply = () => {
        if (optionList.length === 0) {
            return;
        }

        const newSelectedOptions = optionList.map(option => ({
            name: option.name,
            value: option.value
        }));

        const option_value = newSelectedOptions.reduce((acc, option) => {
            acc[option.name] = option.value;
            return acc;
        }, {});

          
        setSelectedOptions([...selectedOptions, ...newSelectedOptions]);


        // console.log("변경"+JSON.stringify(option_value));

        setOptionValues(option_value)

        setDisplayTable(true);
        const optionCombinations = generateOptionCombinations(optionList);
        setOptionCombinations(optionCombinations);

        // optionCombinations 값에 기반하여 tableInputs를 초기화합니다.
        const initialTableInputs = optionCombinations.map((combination) => ["", "", ""]);
        setTableInputs(initialTableInputs);
        
        

        console.log("-----")
        console.log(option_values)
        console.log(optionCombinations)
        console.log("-----")
    
        setOptionList([])
        
    };

    // row 삭제
    function handleRowDelete(rowId) {
         // optionCombinations 배열에서 해당 row를 필터링하여 삭제합니다.
        const updatedOptionCombinations = optionCombinations.filter((combination, idx) => `row-${idx}` !== rowId);
        setOptionCombinations(updatedOptionCombinations);

        console.log("삭제후 optionCombinations")
        console.log(optionCombinations)
        console.log("--------")


        // 삭제된 row에 해당하는 tableInputs 배열의 값을 삭제합니다.
        const updatedTableInputs = tableInputs.filter((input, idx) => `row-${idx}` !== rowId);
        setTableInputs(updatedTableInputs);  
    }

    // 전체 초기화
    const handleReset = () => {
        setSelectedOptions([]);
        setOptionCombinations([]);
    };
    
    // 옵션 미리보기 
    const handlePreviewOptions = () => {
        setPreviewConfirm(true)
        console.log("미리보기", option_rows);
    };
    
    const renderOptionValue = (option_value) => {
        return Object.entries(option_value).map(([key, value]) => (
          <div key={key}>
            <span>{key}: {value}</span>
          </div>
        ));
      };
      
    // 옵션 미리보기 
    const renderPreviewOptions = () => {
        return option_rows.map((option) => (
          <div key={option.id}>
            {/* 필요한 다른 속성들을 JSX에 추가할 수 있습니다. */}
            {renderOptionValue(option.option_value)}
          </div>
        ));
    };

    //
    const handleTableInputChange = (event, idx, columnIndex) => {
        const value = event.target.value;
        const isKorean = /[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(value);
    
        if (isKorean) {
          event.preventDefault();
        } else {
          const updatedInputs = [...tableInputs];
          updatedInputs[idx][columnIndex] = value;
          setTableInputs(updatedInputs);
        }
    };
    
    
    useEffect(() => {
        console.log("option_values가 변했습니다:", option_values);

    }, [option_values]);

    
    useEffect(() => {
        // optionCombinations 값이 변할 때마다 해당 값을 console에 출력합니다.
        console.log('optionCombinations changed:', optionCombinations);

        const updatedOptionRows = optionCombinations.map((options,index) => {
            const option_value = {};
            options.forEach((option) => {
              option_value[option.name] = option.value;
            });
        
            return {
              option_value,
              price: parseInt( tableInputs[index][1].replace(/,/g, '')),
              original_price: parseInt( tableInputs[index][0].replace(/,/g, '')),
              stock: parseInt( tableInputs[index][2].replace(/,/g, '')),
            }
        });
        setOptionRows(updatedOptionRows);

        // console.log("option_rows::::")
        // console.log(option_rows);
        // console.log("tableInputs:::")
        // console.log(tableInputs)



    }, [tableInputs, optionCombinations]);

    useEffect(() => {
        console.log("option_rows 변했습니다:", option_rows);

   
    }, [option_rows]); 
      
    const handleOptionChange = (index, value) => {
         // value에서 공백 제거
        const trimmedValue = value.trim();

        const newOptionList = [...optionList];
        const optionValues = trimmedValue.split(',');
        newOptionList[index].value = optionValues;
        setOptionList(newOptionList);
    };


    return (
        <>
        <section>
            {/* <p className='notice-text'>옵션은 최대 3개까지 가능하다 어쩌고</p> */}
           
            {optionList.map((option, index) => (
                <div key={index} className="Addinfo">
                    <input
                        type="text"
                        placeholder="예시:사이즈"
                        value={option.name}
                        onChange={(e) => {
                            const newOptionList = [...optionList];
                            newOptionList[index].name = e.target.value;
                            setOptionList(newOptionList);
                        }}
                    />
                    <input
                        type="text"
                        placeholder="예시:특대,대,중,소(,로 구분)"
                        value={option.value}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                    />
                    <button className='btn-sm btn-danger' 
                            onClick={() => handleDeleteOption(index)}>
                            삭제
                    </button>
                </div>
            ))}

            <button class="addOption" onClick={addOption}>옵션 추가</button>
    

            {/* 옵션 추가 버튼 */}
            <button className="apply-option" onClick={handleOptionApply}>
                <i className='xi-check'></i>
                옵션목록으로 적용
            </button>
           
            <button className="reset-options" onClick={handleReset}>
                옵션 초기화
            </button>

            <p className="total-price">
                옵션목록 총<span>{optionCombinations.length}</span>개
            </p>

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                                    
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan={selectedOptions.length}>옵션명</th>
                            <th className=''  rowspan="2">
                                정상가
                            </th>
                            <th rowspan="2">
                                판매가            
                            </th>
                            <th rowspan="2">
                                재고수량           
                            </th>
                            <th rowspan="2">삭제</th>
                        </tr>
                        <tr>
                            {selectedOptions.map((option, index) => (
                                <th key={index}>
                                    {option.name}
                                </th>            
                            ))}
                        </tr> 
                    </thead>
         
                    <tbody>
                    {optionCombinations.map((combination, idx) => (
                        <tr key={idx} id={`row-${idx}`}>
                            {combination.map((option, idx) => (
                                //  {option.name}:
                                <td key={idx}>
                                   {option.value}
                                </td>
                            ))}
                            <td>
                                <input type="text" 
                                        className="form-input"
                                        value={addCommasToNumberString(tableInputs[idx][0])}
                                        onChange={(event) => handleTableInputChange(event, idx, 0)} />
                            </td>
                            <td>
                                <input type="text" 
                                        className="form-input"
                                        value={addCommasToNumberString(tableInputs[idx][1])}
                                        onChange={(event) => handleTableInputChange(event, idx, 1)} />
                            </td>
                            <td>
                                <input type="text" 
                                    className="form-input"
                                    value={addCommasToNumberString(tableInputs[idx][2])}
                                    onChange={(event) => handleTableInputChange(event, idx, 2)} />
                            </td>
                            <td>
                                <button className='btn-sm btn-danger' onClick={() => handleRowDelete(`row-${idx}`)}>삭제</button>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>     
            </div>

            {/* <p className="total-price">옵션 미리보기</p>
            <button className="reset-options" onClick={handlePreviewOptions}>
                옵션 미리보기
            </button> */}
            {previewConfirm && renderPreviewOptions()}

        </section>
        </>
    )
}

export default UseOption