import React, { useState, useEffect } from 'react';

function SearchWord({ viewMetaTags , onSearchWord })
{
    useEffect(()=>{
        setTags(viewMetaTags);
    },[viewMetaTags])

    const [searchTag, setSearchTag] = useState("");
    const [tags, setTags] = useState([]);


    // const convertTagsToString = () => {
    //     return tags.join(", "); // 배열 요소를 쉼표와 공백으로 구분하여 하나의 문자열로 결합
    // };
    // const tagsString = convertTagsToString();

    const handleSearchTagChange = (e) => {
        setSearchTag(e.target.value);
    };
    
    const handleAddTag = () => {
        const newTags = [...tags, searchTag];
        setTags(newTags);
        
        setSearchTag("");
        onSearchWord(newTags.join(","));
    };
    
    const handleRemoveTag = (index) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);

        onSearchWord(newTags);
    };

    return (
        <>
        <p className="notice-text">
            외부에서 상품을 검색할 때 사용 되는 검색어 태그 입니다. 판매할 상품과 관련성이 높을 수록 판매율이 높아집니다.
        </p>

        <section className="SearchTags">
            <p className="total-price">메타태그 총 {tags.length}/10개</p>

            <div className="Searchinfo">
                <input type="text"
                        placeholder="검색어 태그를 입력해주세요." 
                        value={searchTag}
                        onChange={handleSearchTagChange} />

                <button className={searchTag ? "option-active" : ""} 
                    onClick={handleAddTag}
                    disabled={tags.length >= 10}>
                    추가
                </button>
            </div>

            {tags.length > 0 && (
                <div className="TagList">
                    {tags.map((tag, index) => (
                    <span key={index} className="Tag">
                            {tag}
                            <button onClick={() => handleRemoveTag(index)}>
                                <i className='xi-close-min'></i>
                            </button>
                        </span>
                    ))}
                </div>
            )}
        </section>

        </>
    )
}

export default SearchWord