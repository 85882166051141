import {  queryClient , prefetchQuery , useQuery } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../apiConfig';
import { useState } from 'react';
import React from 'react';

// 
const fetchCategoryMenu = () => {
    let url = `${BASE_URL}/category/menu/`;
  
    return fetch(url)
        .then(res => res.json());
};
  
export const useCategoryMenuQuery = () => {
    const { data, error, isFetching } = useQuery(['categoryMenu'], () => fetchCategoryMenu(), {
      onSuccess: (data) => {
        return data;
      }
    });
  
    return {
      data,
      error,
      isFetching,
    };
  };
  