import React from 'react';

const SortComponent = ({ ordering, handleOrderingChange }) => {
  return (
    <div className='selectWrap'>
        <select className='mSelectBox' id="ordering" value={ordering} onChange={handleOrderingChange}>
            <option value="-date_joined">최근 가입일 순</option>
            <option value="date_joined">오래된 가입일 순</option>
        </select>
    </div>
  );
}

export default SortComponent;