/***************************************************************************************
* FileName      : ProductReturn.js
* Description   : 고객 > 마이페이지 > 반품신청 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Container, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';
import 'react-datepicker/dist/react-datepicker.css';

import { fetchMyOrderItemDetail } from '../../api/services/mypageApi.js';
import { useParams , useNavigate } from 'react-router-dom';
import Address from './Component/Address.js';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { OrderReturn } from '../../api/services/mypageApi.js';
import LoadingSpinner from '../components/LoadingSpinner.js';
import { getUploadURL , uploadImage } from '../../api/uploadImage/uploadImageApi.js';

function OrderInquiryLayout(){
  return(
    <>
    <Container>
        <StatusBar/>
        <Row className='m-0'>
            <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'order'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <ProductReturn />
                </Col>
            </div>
        </Row>
    </Container>
    </>
  )
}

function ProductReturn()
{
    const navigate = useNavigate();
    const { id } = useParams();

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [orderDetail, setOrderDetail] = useState(null);
    const [images, setImages] = useState([]);

    const [uploadedImages, setUploadedImages] = useState({
        image: [],
    });
    const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(3).fill(''));

    // 총 수량
    const [totalQuantity, setTotalQuantity] = useState(0);


        
    useEffect(() => {
        fetchMyOrderItemDetail(id)
          .then(details => {
            setOrderDetail(details);
            console.log("상세데이터")
            console.log(details)
          })
          .catch(error => {
            console.error('상품 상세 조회 오류:', error);
          });
    }, [id]);

    useEffect(() => {
        const total = orderDetail?.orderitem_option_rows?.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(total);
    }, [orderDetail]);

    // 배송정보
    const [deliveryInfoData , setDeliveryInfoData] = useState({
        dispatch_location: {
            address: "",
            detail: "",
            postal_code: "",
            address_type: ""
        }
    });

    const createMutaion = useMutation(OrderReturn , {
        onSuccess : (data) => {
            reset();
            alert("반품 신청이 완료 되었습니다.");
            setIsLoading(false);
            navigate('/mypage/return/'); 
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    });

    const onSubmit = async (data) => {
        setIsLoading(true);

        await uploadImages();

        if(id){
            const newData = {
                return_reason: data.reason || "",
                order_item: id || "",
                return_images : uploadedImages.image || "",
            };
    
            createMutaion.mutate(newData);
        }
  
    };



    const handleImageChange = (e, index) => {
        e.preventDefault();
    
        const file = e.target.files[0];
        
        const updatedImages = [...images];
        updatedImages.push(file);
        setImages(updatedImages);
        

        const reader = new FileReader();
        reader.onloadend = () => {
          const newImagePreviewUrls = [...imagePreviewUrls];
          newImagePreviewUrls[index] = reader.result;
          setImagePreviewUrls(newImagePreviewUrls);
        }

        reader.readAsDataURL(file);
    }



    // 이미지 파일 업로드
    const uploadImages = async () => {  
        try {
            if(images.length > 0){
                const updatedImages = [];
                for (const ImageFile of images) {
                    const ImageURL = await getUploadURL(ImageFile);
                    if (ImageURL) {
                        const imageURL = await uploadImage(ImageURL);
                        updatedImages.push(imageURL);
                    }
                }
                uploadedImages.image = updatedImages;  
            }
        } catch (error) {
            console.error("이미지 업로드 에러:", error);
        }
    };

    const removeImageAtIndex = (index) => {
        setImages(images.filter((_, idx) => idx !== index));
    };

    // 이미지 삭제 기능 함수 
    const handleImageDelete = (index) => {
        setImagePreviewUrls((prevImagePreviewUrls) => {
          const newImagePreviewUrls = [...prevImagePreviewUrls];
          newImagePreviewUrls[index] = '';
          return newImagePreviewUrls;
        });
        removeImageAtIndex(index);
    };
    
    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <>
            <Row className='m-0 p-0 mb-4'>
                <Col xs={12} sm={12} className='m-0 p-0'>
                      <h3 class="m-0 p-0 fs-5 fw-bold">반품신청</h3>
                </Col>
            </Row>
            
            <div className='area-over mt-2'>
                <h3 className='mypage-sub-title mb-2'>반품상품</h3>
            </div>
            <table className='mypage-table'>
                <colgroup>
                    <col style={{ width: '50%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>상품명</th>
                        <th>판매가</th>
                        <th>수량</th>
                        <th>배송비</th>
                    </tr>
                </thead>
                <tbody className='history'>
                    <tr>
                      <td className='subject'>
                        <div className='area'>
                            <a className='thum'>
                                <img src={orderDetail?.main_image} alt="Product" />
                            </a>
                            <div className='textus'>
                                <a>
                                    <span className='tit'>
                                        {orderDetail?.vendor_name}
                                    </span>
                                    <span className='txt'>
                                        {orderDetail?.product_name}
                                    </span>
                                    
                                    {/* 옵션 */}
                                    
                                    <span className='option_list2'>
                                        <i className='optiontxt'>옵션</i>
                                        <div>
                                            {orderDetail?.orderitem_option_rows?.map((option, index) => (
                                                <p key={index}>
                                                    {option.name} [{option.total_price.toLocaleString()}원 
                                                    / 수량: {option.quantity}]  
                                                </p>
                                            ))}
                                        </div>
                                    </span>
                                  
                                    {/* 추가상품 */}
                       
                                    <span className='option_list2'> 
                                        <i className='optiontxt'>추가상품</i>
                                        <div>
                                        {orderDetail?.orderitem_extra_options?.map((extra_option, index) => (
                                            <p key={index}>
                                                {extra_option.name} [{extra_option.total_price.toLocaleString()}원 / 수량 : {extra_option.quantity} ]  
                                            </p>
                                        ))}
                                        </div>
                                    </span>
                     

                                </a>
                            </div>
                        </div>
                      </td>
                      <td>{orderDetail?.total_price?.toLocaleString()}원</td>
                      <td>      {totalQuantity}</td>
                      <td></td>

                     
                    </tr>
                    
                </tbody>
                
            </table>
            <form onSubmit={handleSubmit(onSubmit)}>    
                <div className='area-over mt-2'>
                    <h3 className='mypage-sub-title mb-2'>반품사유 입력</h3>
                </div>
                <table className='mypage-table view mb-5'>
                    <colgroup>
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '20%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>사유</th>
                            <td colSpan={3}>
                                <input
                                    className={`mypage_input w100 ${errors.reason ? 'error-input' : ''}`}
                                    placeholder='반품사유를 입력해 주세요'
                                    {...register('reason', { required: '사유 입력은 필수입니다.' })} 
                                    type='text'  
                                />
                            </td>
                        </tr>
                    
                    </tbody>
                    
                </table>
                <div className='area-over mt-2'>
                    <h3 className='mypage-sub-title mb-2'>이미지 첨부</h3>
                </div>

                {/*  이미지 등록 */}              
                <div className="productImageUploader">
                    {[...Array(3)].map((_, index) => (
                        <div className="imageUploadContainer" key={index}>
                            <label htmlFor={`fileInput${index}`} className="fileInputLabel">
                                <div className="imagePreviewContainer">
                                    {imagePreviewUrls[index] ? (
                                        <img 
                                            src={imagePreviewUrls[index]} 
                                            alt={`Product ${index + 1}`} 
                                            className="imagePreview" />
                                        ) : (
                                            <div className="imagePlaceholder"></div>
                                        )}
                                        {imagePreviewUrls[index] && (
                                            <button 
                                                className="deleteImageButton" 
                                                onClick={() => handleImageDelete(index)}>
                                            X
                                            </button>
                                        )}
                                </div>
                                <input
                                id={`fileInput${index}`}
                                type="file"
                                className="fileInput"
                                accept=".jpg,.jpeg,.png"
                                key={imagePreviewUrls[index]}
                                onChange={(e) => handleImageChange(e, index)}
                                />
                            </label>
              
                        </div>
                    ))}
                </div>
                          
                <div class="mpe_btn_area  mt-5">
                    <button className='m-btn m-btn-search'>
                        {isLoading ?  <LoadingSpinner /> : '반품 신청하기'} 
                    </button>
                </div>

            </form>

  
    
                
        </>
        </div>  
        </>
    )
}


export default OrderInquiryLayout