import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useLogin from '../../api/useLogin';
import { useSuperHeroesData , useAddSuperHeroData } from "../../api/useSuperHeroesQuery";
import Cookies from 'js-cookie'; //아이디저장을 위한 쿠키 사용
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi';
import { BASE_URL } from '../../api/apiConfig';

function Login()
{
    const [loginType, setLoginType] = useState('login'); // 로그인 타입 : login or guest(주문조회)
    const [username , setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const url = `${BASE_URL}`;

    // [hm] 아이디 저장 기능
    const [saveIdState, setSaveIdState] = useState(true); // 아이디 저장 체크 확인용
    const handleSaveId = () => setSaveIdState(!saveIdState); // 아이디 저장 체크박스 핸들링
    const setCookie = (userId) => {
        Cookies.set('saveId', userId, { expires: 1 });
    }
    useEffect(()=>{
        const saveId = Cookies.get('saveId');
        console.log(saveId);
        saveId !== '' ? setUsername(saveId) : setUsername('');
    }, []);//최초 1회 저장된 쿠키값이 있는지 확인
    // [hm] 아이디 저장 기능 finish
  
    const { isLoading, error, data, refetch } = useLogin(username, password);
    
    const login = useLogin();

    const handleSubmit = (e) =>{
        e.preventDefault();
        const saveId = saveIdState ? username : ''; //아이디 저장 여부 확인 후 쿠키에 저장할 값 입력
        setCookie(saveId); //아이디 저장 체크된 경우 쿠키에 아이디값 저장

        // refetch();

    }
    
    

    return(
        <>
        <Container>
            <Row>
                <div className='sub_content'>
                    <div className="login_wrap">
                        <h2 className="member_tit">{loginType === 'login' ? '로그인' : '주문조회'}</h2>
                        <div className="login_tab">
                            <ul>
                                <li className={loginType === 'login' ? 'on' : ''}>
                                    <a onClick={() => setLoginType('login')}>로그인</a>  
                                </li>
                                <li className={loginType === 'guest' ? 'on' : ''}>
                                    <a onClick={() => setLoginType('guest')}>비회원 주문조회</a>  
                                </li>
                            </ul>
                        </div>

                        <form onSubmit={handleSubmit} className="login_input_sec">
                            <input type="text" 
                                placeholder={loginType === 'login' ? '아이디' : '주문번호'}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />

                            <input type="password"
                                placeholder={loginType === 'login' ? '비밀번호' : '주문자 이메일'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                             />

                            <p className={loginType === 'login' ? 'login-option' : 'd-none'}>
                                <div className='saveId'>
                                    <input type="checkbox" id="saveId-chk" checked={saveIdState} onChange={handleSaveId}/>
                                    <label for="saveId-chk">
                                        { saveIdState ? <span className='text-hw fw-bold'><BiCheckboxChecked className="fs-2"/>아이디 저장</span> : <span className='text-grey fw-bold'><BiCheckbox className="fs-2"/>아이디 저장</span> }
                                    </label>
                                </div>
                                <a onClick={()=>{navigate('../find')}} className="pointer">
                                    아이디/비밀번호 찾기
                                </a>
                            </p>
                            <button className="mt-2 mb-5 login_btn btn btn-primary btn-lg btn-block" type="submit" onClick={()=>{login.mutate({ username, password })}}>
                                {loginType === 'login' ? '로그인' : '주문조회'}
                            </button>
                        </form>
                        
                        {/* 
                        <div className="login-button">
                            <button type="submit" 
                            className="login_btn"
                            onClick={onClickLogin}
                            >로그인</button>   
                        </div>
                        */}
                        {/* 소셜로그인 임시 해제
                        <div className={loginType === 'login' ? 'member_sns_login' : 'd-none'}>
                            <p>SNS 로그인</p>
                            <a className='btn_kakao_login js_btn_kakao_login'>
                                <img src={process.env.PUBLIC_URL + '/img/kakaoicon.png'} /> 
                                카카오 로그인
                            </a>
                            <a className='btn_naver_login'>
                                <img src={process.env.PUBLIC_URL + '/img/login_naver.jpg'} /> 
                                네이버 로그인
                            </a>
                        </div>
                        */}
                    </div>
                 
                </div>
                
            </Row>
        </Container>
        </>
    )
}

export default Login