import { Container, Col, Row } from 'react-bootstrap'; 
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useProtect from '../../hooks/useProtect.js';
import { getLoginUser } from '../../api/myOrderListApi.js';
import StatusBar from './StatusBar.js';

export default function MypageMenu({}){
    const [isLoggedIn, setIsLoggedIn] = useState(true); 

    useEffect(()=>{
        getLoginUser()
        .then(res => console.log(res.data))
        .catch(error => {
            setIsLoggedIn(false);
        })
    },[])

    useProtect(isLoggedIn);

    const navigate = useNavigate();

    return (
        <div className="mypage_side m-0 p-0">
            <StatusBar mobile={true}/>
            <Container className='m-0 p-2'>
                <h3 className='fs-4 fw-bold pt-0 pb-3 ps-0 pe-0 pointer'>마이페이지</h3>
                <Row className='m-0 p-0'>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/order')}>주문배송조회</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/return')}>반품/취소내역</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/coupon')}>할인쿠폰</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/mileage')}>마일리지</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/otoList')}>1:1문의내역</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/qnaList')}>상품Q&A내역</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/reviewable')}>리뷰관리</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/wish')}>찜목록</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/info')}>비밀번호 변경</Col>
                    <Col xs={12} className='fs-6 fw-bolder m-0 p-1 pt-2 pb-2 text-grey d-flex justify-content-between align-items-center pointer' onClick={()=>navigate('/mypage/address')}>배송지 관리</Col>
                </Row>
                <Row className='m-0 mt-3 px-3 py-4 bg-grey rounded-2'>
                    <Col xs={12} className='fs-6 fw-bold'>목포해와<br/>고객센터입니다.</Col>
                    <Col xs={12} className='fs-5 text-hw fw-800 py-3 line-height-15'>070<span className='fs-6 fw-bold'>-</span>4249<span className='fs-6 fw-bold'>-</span>0302</Col>
                    <hr/>
                    <Col xs={12} className='fs-7 fw-bolder'>평일 09:00 ~ 18:00</Col>
                    <Col xs={12} className='fs-7 fw-bolder'>점심시간 11:50 ~ 13:00</Col>
                    <Col xs={12} className='fs-7 fw-bolder'>주말 및 공휴일 휴무입니다.</Col>
                </Row>
            </Container>
        </div>
        
    )
}