/***************************************************************************************
* FileName      : ReservationList.js
* Description   : 해와관리자 > 알림 > 푸시알림관리 > 예약목록 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import PageSizeSelect from "../../common/PageSizeSelect";
import { useNotificationPushRHistoryQuery , deleteNotificationpushCancel } from '../../../../api/hwadmin/notificationApi';
import Pagination from '../../common/Pagination';

function ReservationList()
{

    const [pageSize, setPageSize] = useState(10); 
    const { data, error, isFetching, page, setPage } = useNotificationPushRHistoryQuery(pageSize);
    
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    console.log(data);
    console.log("---")
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
  
    const handleCancelClick = (id) => {
        let deleteMessage = prompt('예약을 취소하시겠습니까?\n"예약을 취소합니다"를 입력해주세요.');
        if(deleteMessage == "예약을 취소합니다"){
            deleteNotificationpushCancel(id)
            .then(res => {
            console.log(res.data);
            alert("예약이 취소되었습니다.");
            // window.location.href = '/admin/alarm/alimTalkSendingHistory'; 
          })
          .catch(error => alert(error.message))
        }
    }

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
    }

    return (
        <>
        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className='resultsCount-wrap'>
                    <span>검색결과</span>
                    <span className='result-wrap'>
                        {searchResults.totalCnt > 0 ? (
                            <>
                                <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                            </>
                            ) : (
                            <>
                                <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                            </>
                        )}
                    </span>
                </div>

                { !searchConfirm && 
                    <div className='selectWrap'>
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                }
            </div>
  
            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th><input type='checkbox'/></th>
                            <th>id</th>
                            <th>상태</th>
                            <th>푸시제목</th>
                            <th>푸시내용</th>
                            <th>대상회원</th>
                            <th>예약취소</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <input type="checkbox"/>
                                </td>
                   
                                <td>{item.id}</td>
                                <td>{item.status}</td>   
                                <td>{item.title}</td>
                                <td>{item.body}</td>
                                <td>{item.receiver.length}</td>
                                <td>       
                                    {item.status === "전송대기중" && 
                                        <button 
                                            className='t-s-btn'  
                                            onClick={() => handleCancelClick(item.mid)}>
                                            예약취소
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))} 
                    </tbody>
                </table>
            </div>
        </section>

        <section>
            {data && data.data.length > 0 && (
                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    />
            )}
        </section>
        </>
    )
}

export default ReservationList