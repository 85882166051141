export const labelDataObject = {
    total_visiter: '전체 방문자 수',
    pc_visiter: 'PC',
    mobile_app_visiter: '모바일(웹+앱)',
    mobile_visiter: '모바일(웹)',
    app_visiter: '모바일(앱)',
};
  
export const chartBackgroundColor = {
    total_visiter: '#F6B6C1',
    pc_visiter: '#AACFF2',
    mobile_app_visiter: '#FBE7B4',
    mobile_visiter: '#98FB98',
    app_visiter: '#FFA07A',
};

export const chartBorderColor = {
    total_visiter: '#ED6E85',
    pc_visiter: '#57A0E5',
    mobile_app_visiter: '#F7CF6B',
    mobile_visiter: '#6B8E23',
    app_visiter: '#CD853F',
};