/***************************************************************************************
* FileName      : AdjustRow.js 
* Description   : 셀러관리자 > 정산 > 매출내역 > 구매 확정 기준 AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.05
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <>
        <tr>
          <td>{Adjust.confirm_date}</td>
          <td>{Adjust.total_settlement_target_amount.toLocaleString()}</td>
          <td>{Adjust.total_settlement_fee.toLocaleString()}</td>
          <td>{Adjust.total_settlement_amount.toLocaleString()}</td>
         </tr>
      </>
    </>
  );
};
