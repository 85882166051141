/***************************************************************************************
* FileName      : PenaltyModal.js
* Description   : 패널티 점수관리, 이용제한 모달
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.23
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useEffect, useState , useRef } from 'react';
import { getPenaltyList, createPenalty, removePenalty, getPenaltyActionList, createPenaltyAction } from '../../../../api/hwadmin/penaltyApi';
import { useQuery } from 'react-query';

function PenaltyModal({show, setShow, type, id}){
    return(
        <Modal size="xl" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {type}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === '패널티점수관리' && <PenaltyScore id={id}/>}
                {type === '이용제한패널티' && <ActivityPenalty id={id}/>}
            </Modal.Body>
        </Modal>
    )
}

// 패널티 점수 모달 공통
function PenaltyScore({id}){
    const [nowState, setNowState] = useState('기록');
    const [penaltyData, setPenaltyData] = useState([]);
    useEffect(()=>{
        getPenaltyList(id)
            .then(res => setPenaltyData(res?.data?.data))
    }, [nowState === '기록'])
    return(
        <>
            {/* state 선택 버튼 영역 */}
            <div className='mb-3'>
                <button className={nowState === '기록' ? 'm-sm-btn active' : 'm-sm-btn'}
                    onClick={()=>setNowState('기록')}>기록</button>
                <button className={nowState === '패널티지급' ? 'm-sm-btn active' : 'm-sm-btn'}
                    onClick={()=>setNowState('패널티지급')}>패널티 지급</button>
                <button className={nowState === '패널티차감' ? 'm-sm-btn active' : 'm-sm-btn'}
                    onClick={()=>setNowState('패널티차감')}>패널티 차감</button>
            </div>
            {
                nowState === '기록' ? <PenaltyLog penaltyData={penaltyData}/> : (
                nowState === '패널티지급' ? <PenaltyScoreGranding id={id} type={'지급'} setNowState={setNowState}/> : (
                nowState === '패널티차감' ? <PenaltyScoreGranding id={id} type={'차감'} setNowState={setNowState}/> : null
                ))
            }
        </>
    )
}

// 패널티 점수 기록 화면
function PenaltyLog({penaltyData}){
    return(
        <table className='sTable' id='scrollTable'>
            <colgroup>
                <col style={{ width: '10%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '50%' }} />
            </colgroup>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>일시</th>
                    <th>구분</th>
                    <th>점수</th>
                    <th>내용</th>
                </tr>
            </thead>
            <tbody>
                {penaltyData?.map((item, index) => (
                    <tr>
                        <td>{index+1}</td>
                        <td>{item?.created_at}</td>
                        <td>{item?.type}</td>
                        <td><p className={item?.format === "패널티 지급" ? 'text-danger fw-bold' : 'text-grey'}>{item?.score}</p></td>
                        <td>{item?.reason !== '' ? item?.reason : '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

// 패널티 지급/차감
function PenaltyScoreGranding({id, type, setNowState}){ // type : 지급 or 차감으로 들어오도록함
    const [scoreType, setScoreType] = useState(''); // 패널티 구분
    const [score, setScore] = useState(''); // 패널티 점수
    const [detail, setDetail] = useState(''); // 패널티 사유

    useEffect(()=>{
        console.log("패널티 지급 사유는 : ",detail);
    }, [])

    function onOkButton(){
        type === '지급' ? (
            createPenalty(id, score, scoreType, detail)
                .then(res => console.log(res?.data?.data))
                .catch(error => alert('처리중 에러가 발생했습니다.'))
                .finally(()=>setNowState('기록'))
        ) : type === '차감' ? (
            removePenalty(id, score, detail)
                .then(res => res?.data)
                .catch(error => alert('처리중 에러가 발생했습니다.'))
                .finally(()=>setNowState('기록'))
        ) : alert('잘못된 요청입니다.')
    }
    return(
        <div className='border rounded-2 p-3'>
            <Row className='align-items-center p-2'>
                <Col xs={2}><p className='fs-6 fw-bold'>패널티 구분</p></Col>
                <Col xs={10}>
                    {
                        type === '지급' ? (
                            <select className='mSelectBox rounded-1 w-100' value={scoreType} onChange={(e) => setScoreType(e.target.value)}>
                                <option value="" selected="selected" disabled>선택해주세요</option>
                                <option value="상품관리">상품관리</option>
                                <option value="재고관리">재고관리</option>
                                <option value="주문관리">주문관리</option>
                                <option value="반품/취소">반품/취소</option>
                                <option value="기타">기타</option>
                            </select>
                        ): type === '차감' ? (
                            <p className='fs-7 text-hw fw-bold'>관리자 수동 차감</p>
                        ) : <>잘못된 요청입니다.</>
                    }
                    
                </Col>
            </Row>
            <Row className='align-items-center p-2'>
                <Col xs={2}><p className='fs-6 fw-bold'>패널티 점수</p></Col>
                <Col xs={10}>
                    <select className='mSelectBox rounded-1 w-100' value={score} onChange={(e) => setScore(e.target.value)}>
                        <option value="" selected="selected" disabled>선택해주세요</option>
                        {
                            type === '지급' ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(x =>(
                                <option value={x}>{x}</option>
                            )) : type === '차감' ? ['-1', '-2', '-3', '-4', '-5', '-6', '-7', '-8', '-9', '-10'].map(x =>(
                                <option value={x}>{x}</option>
                            )) : null
                        }
                    </select>
                </Col>
            </Row>
            <Row className='align-items-center p-2'>
                <Col xs={2}><p className='fs-6 fw-bold'>지급사유</p></Col>
                <Col xs={10}><input type='text' className='w-100 border-default rounded-1 px-3 py-1 fs-7 fw-light' placeholder='패널티 적용 사유에 대해 입력해주세요.' onChange={(e) => setDetail(e.target.value)}/></Col>
            </Row>
            <Row className='align-items-center mt-4 p-2'>
                <Col xs={4}></Col>
                <Col xs={2}>
                    <Button variant="danger" onClick={()=>setNowState('기록')} className="text-center w-100 m-0 py-1 px-2">
                        취소
                    </Button>
                </Col>
                <Col xs={2}>
                    <Button variant="primary" onClick={()=>onOkButton()} className="text-center w-100 m-0 py-1 px-2">
                        확인
                    </Button>
                </Col>
                
                <Col xs={4}></Col>
            </Row>
        </div>
    )
}

// 이용제한 패널티(제재) 모달 공통
function ActivityPenalty({id}){
    const [nowState, setNowState] = useState('기록');

    const [penaltyData, setPenaltyData] = useState([]);
    useEffect(()=>{
        getPenaltyActionList(id)
            .then(res => setPenaltyData(res?.data?.data))
    }, [nowState === '기록'])
    return(
        <>
            {/* state 선택 버튼 영역 */}
            <div className='mb-3'>
                <button className={nowState === '기록' ? 'm-sm-btn active' : 'm-sm-btn'}
                    onClick={()=>setNowState('기록')}>기록</button>
                <button className={nowState === '상태변경' ? 'm-sm-btn active' : 'm-sm-btn'}
                    onClick={()=>setNowState('상태변경')}>상태변경</button>
            </div>
            {
                nowState === '기록' ? <ActivityLog penaltyData={penaltyData}/> : (
                nowState === '상태변경' ? <ActivityUpdate setNowState={setNowState} id={id}/> : null)
            }
        </>
    )
}

function ActivityLog({penaltyData}){
    const typeTextColor = {
        '정상': 'text-primary fw-bold',
        '경고': 'text-grey fw-bold',
        '이용정지': 'text-warning fw-bold',
        '판매정지': 'text-danger fw-bold',
        '경고해제': 'text-primary fw-bold',
        '판매정지해제': 'text-primary fw-bold',
        '이용정지해제': 'text-primary fw-bold',
    }
    return(
        <table className='sTable' id='scrollTable'>
            <colgroup>
                <col style={{ width: '10%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '50%' }} />
            </colgroup>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>일시</th>
                    <th>구분</th>
                    <th>내용</th>
                </tr>
            </thead>
            <tbody>
                {penaltyData?.map((item) => (
                    <tr>
                        <td>{item?.id}</td>
                        <td>{item?.created_at}</td>
                        <td><p className={typeTextColor[item?.action]}>{item?.action}</p></td>
                        <td>{item?.reason !== '' ? item?.reason : '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

function ActivityUpdate({setNowState, id}){
    const [activityType, setActivityType] = useState(''); // 패널티 구분
    const [detail, setDetail] = useState(''); // 패널티 사유

    useEffect(()=>{
        console.log("패널티 지급 사유는 : ",detail);
    }, [])

    function onOkButton(){
        createPenaltyAction(id, activityType, detail)
            .then((res => console.log(res?.data?.data)))
            .catch(error => alert('처리중 에러가 발생했습니다.'))
            .finally(()=>setNowState('기록'))
    }
    return(
        <div className='border rounded-2 p-3'>
            <Row className='align-items-center p-2'>
                <Col xs={2}><p className='fs-6 fw-bold'>패널티 상태</p></Col>
                <Col xs={10}>
                    <select className='mSelectBox rounded-1 w-100' value={activityType} onChange={(e) => setActivityType(e.target.value)}>
                        <option value="" selected="selected" disabled>선택해주세요</option>
                        <option value="정상">정상</option>
                        <option value="경고">경고</option>
                        <option value="이용정지">이용정지</option>
                        <option value="판매정지">판매정지</option>
                        <option value="이용정지해제">이용정지해제</option>
                        <option value="판매정지해제">판매정지해제</option>
                    </select>
                </Col>
            </Row>
            <Row className='align-items-center p-2'>
                <Col xs={2}><p className='fs-6 fw-bold'>변경사유</p></Col>
                <Col xs={10}><input type='text' className='w-100 border-default rounded-1 px-3 py-1 fs-7 fw-light' placeholder='패널티 상태 변경 사유에 대해 입력해주세요.' onChange={(e) => setDetail(e.target.value)}/></Col>
            </Row>
            <Row className='align-items-center mt-4 p-2'>
                <Col xs={4}></Col>
                <Col xs={2}>
                    <Button variant="danger" onClick={()=>setNowState('기록')} className="text-center w-100 m-0 py-1 px-2">
                        취소
                    </Button>
                </Col>
                <Col xs={2}>
                    <Button variant="primary" onClick={()=>onOkButton()} className="text-center w-100 m-0 py-1 px-2">
                        확인
                    </Button>
                </Col>
                <Col xs={4}></Col>
            </Row>
        </div>
    )
}

export default PenaltyModal