import React, { useState, useEffect } from 'react';

function AvailableProducts({ availableProductsData })
{
    console.log("추가상품")
    console.log(availableProductsData)
    const { additionalItems, handleOptionApply  } = availableProductsData;

    const [additionalItemName, setAdditionalItemName] = useState("");
    const [additionalItemPrice, setAdditionalItemPrice] = useState("");
    const [additionalItemStock, setAdditionalItemStock] = useState("");


    const isOptionActive = additionalItemName && additionalItemPrice; // 두 input 값이 모두 있을 때만 버튼 활성화

    console.log("--추가상품--")
    console.log(additionalItems)
    
    const handleApplyButtonClick  = () => {
        const newAdditionalItem = {
            name: additionalItemName,
            price: additionalItemPrice,
            stock: additionalItemStock
        };
        handleOptionApply(newAdditionalItem);

        setAdditionalItemName("");
        setAdditionalItemPrice("");
        setAdditionalItemStock("");
    };

    const handleDeleteItem = (index) => {
        const updatedItems = [...additionalItems];
        updatedItems.splice(index, 1);
        handleOptionApply({ additionalItems: updatedItems });
    };
    
    // 재고 수량 입력 데이터 저장
    const handleAdditionalItemInputChange = (index, value) => {
        const updatedItems = [...additionalItems];
        updatedItems[index].stock = value;
        // setAdditionalItems(updatedItems);
    };

    const handleAdditionalItemPriceChange = (e) => {
        const value = e.target.value;
        const isKorean = /[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(value);

        if (isKorean) {
            e.preventDefault();
        } else {
            const formattedValue = value.replace(/,/g, '');
            setAdditionalItemPrice(parseInt(formattedValue));
        }
    };

    return (
        <>
        {/* 추가상품 > 사용함 */}
        <section>
            <div className="Addinfo">
                <input type="text"
                        placeholder="추가상품명( 예시 : 매운탕 양념 )"  
                        value={additionalItemName}
                        onChange={(e) => setAdditionalItemName(e.target.value)} 
                        />
                <input type="text" 
                        placeholder="판매가( 예시 : 3000 )" 
                        value={additionalItemPrice.toLocaleString()}
                        onChange={handleAdditionalItemPriceChange}
                        />
                <button className={isOptionActive ? "option-active" : ""} 
                        onClick={handleApplyButtonClick } 
                        disabled={!additionalItemName || !additionalItemPrice}>
                            옵션 적용
                </button>
            </div>

            <div className='fixTable_wrap'>
                <p className="total-price">추가상품목록 총<span>{additionalItems.length}</span>개</p>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                                    
                    </colgroup>
                    <thead>
                        <tr>   
                            <th>추가상품명</th>
                            <th>상품판매가</th>
                            <th>재고수량</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                    {additionalItems.map((item, index) => (
                        <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.price.toLocaleString()}</td>
                        <td>
                            <input
                                type="number"
                                defaultValue={item.stock} 
                                className="form-input"
                                onChange={(e) => handleAdditionalItemInputChange(index, e.target.value)}
                            />
                        </td>
                        <td>
                            <button className='btn-sm btn-danger'
                                    onClick={() => handleDeleteItem(index)}>
                                    삭제
                            </button>
                        </td>
                        </tr>
                    ))}
                    </tbody>

                </table>     
            </div>

        </section>
    </>
    )
}

export default AvailableProducts