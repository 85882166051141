import React, { useState, Component , useEffect} from 'react';
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate , useLocation } from 'react-router-dom';
import Sidemenu from '../Sidemenu.js';

// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import Editor from '../../components/Editor.js';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import CustomEditor from 'ckeditor5-custom-hw';
// import { BASE_URL } from '../../../api/apiConfig.js';
// import { getAccessFromCookie } from '../../../api/cookieUtils.js';

import { fetchProductTiny } from '../../../api/services/customerApi.js';

import { useProductQnARegistration } from '../../../api/services/customerApi.js';

import SearchProductModal from './SearchProductModal.js';
import LoadingSpinner from '../../components/LoadingSpinner.js';



export default function Write()
{
    const navigate = useNavigate();

    const { post, isPostLoading, error } = useProductQnARegistration();

    const { id } = useParams();
    const [productId, setProductId] = useState(id);

    // 상품 설명
    const [productDetails, setProductDetails] = useState(null);


    useEffect(() => {

        console.log("productId",productId)
        const fetchData = async () => {
            if (productId !=0 && productId) {
                const data = await fetchProductTiny(productId);
                setProductDetails(data);
            }
        };
    
        fetchData();
    
    }, [productId]);



    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    const [title, setTitle] = useState('');
    const handleInputChange = (e) => {
        setTitle(e.target.value); 
    };

      
    const [selectedOption, setSelectedOption] = useState('');

    // 셀렉트 박스 변경 핸들러
    const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
    };



    // 상세설명 
    const [detailedDescription, setDetailedDescription] = useState('');

    
    
    // 상품 메인이미지 
    const [mainImage, setMainImage] = useState();


    useEffect(()=>{
        console.log("------에디터")
        console.log(detailedDescription)
    },[detailedDescription])

    // 유효성 검사
    const validateForm = () => {
        let isValid = true;
        alert(productId);
        if(productId === 0){
            alert("상품을 선택해주세요");
            isValid = false;
            return ; 

        }
        if(title === ''){
            alert("문의 제목 입력은 필수입니다.");
            return ;
        }

        return isValid;
    }
    
    // 상품 상세보기 버튼 클릭 시 
    const handleDetail = (event) => {
        navigate(`/product/detail/${productId}`);
    }

    // 상품 검색 모달 상태
    const [isOpen, setIsOpen] = useState(false);


    // 상품 검색 모달 팝업
    const openSelectProductModal = () => {
        setIsOpen(true);
    };
    
    const closeSelectProductModal = () => {
        setIsOpen(false);
    };

     // 작성완료 버튼 클릭 시 
     const handleSubmit = (event) => {

        if (validateForm()) {
            setIsLoading(true); // 로딩 상태를 활성화
            event.preventDefault();
    
            const data = {
                title: title || "" ,
                content: detailedDescription || "",
                type: selectedOption || "" ,
                product: productId || "",

            };

            console.log("데이터");
            console.log(data);

            post(data)
            .then(() => {
                alert("등록 성공");
                console.log("등록 성공!");
                setIsLoading(false);
                navigate('/customer/qna'); 
            })
            .catch(error => {
                console.log("등록 실패:", error.message);
                setIsLoading(false);
            });
        }

    }

    return (
        <>
        <Container>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'qna'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                            <Row className='m-0 p-0'>
                                <Col className='m-0 p-0'>
                                    <h3 class="m-0 p-0 fs-5 fw-bold">상품 Q&A</h3>
                                </Col>
                            </Row>
                    
                            {/* 상품정보 */}
                            <div className='ec-base-box typeProduct'>
                            <Row>
                                <Col xs={2} md={2}>
                                    <div className=''>
                                        {mainImage ? (
                                            <img src={mainImage} alt="Product" />
                                        ) : (
                                            <img src={productDetails?.main_image} alt="Product" />
                                        )}
                                    </div>
                                </Col>
                                <Col xs={10} md={10}>
                                    <p className='sPrdName'>{productDetails?.name}</p>
                                    <p className='sPrdPrice'>
                                        {productDetails?.price ? (
                                            parseInt(productDetails?.price).toLocaleString()): 0 }원    
                                        </p>
                                    <div className="information d-flex justify-content-start gap-1">
                                    
                                    {productId > 0 ? (
                                        <button className='btnSubmitFix sizeS' onClick={() => handleDetail()}>상품상세</button>
                                    ) : null}

                                        <button className='btnNormal2'
                                            onClick={openSelectProductModal}
                                        >상품정보선택</button>               
                                    </div>
                                </Col>
                            </Row>

                            </div>
                            
                            <div className='ec-base-table typeWrite pt-2'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>제목</th>
                                            <td>
                                                <input
                                                    type='text'
                                                    value={title} 
                                                    onChange={handleInputChange}
                                                    placeholder='제목을 입력하세요.'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>문의종류</th>
                                            <td>
                                                <select onChange={handleSelectChange} value={selectedOption}>
                                                    <option value="">- 문의 선택 -</option>
                                                    <option value="배송문의">배송문의</option>
                                                    <option value="재고문의">재고문의</option>
                                                    <option value="기타문의">기타문의</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td>
                                                <Editor  
                                                    content={detailedDescription}
                                                    setContent={setDetailedDescription} 
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <section className="d-flex justify-content-end pt-5 gap-1">
                            <button className='btnSubmitFix m'>취소</button>
                            <button onClick={handleSubmit} className='btnSubmitFix size'>
                                {isLoading ?  <LoadingSpinner /> : '등록'} 
                            </button>
                        </section>

                    </Col>
                </div>
                  
                
              
            </Row>
            
        {/* 상품 검색 모달 */}

        {isOpen && (
            <>
            <SearchProductModal 
                setIsOpen={setIsOpen} 
                setProductId={setProductId}
            />
            </>
        )}
   
    
        </Container>
        </>
    );
}

// function Edit({setContent})
// {
//     const [editorTemplate, setEditorTemplate] = useState('<p> <span style="font-family:나눔스퀘어 네오, Arial, sans-serif;">상세정보를 입력해주세요</span></p>');
    
//     const access = getAccessFromCookie();

//     return(
//         <>
//         <CKEditor
//             editor={ CustomEditor }
//             data = {editorTemplate}
//             config={{
//                 simpleUpload: {
//                     uploadUrl: `${BASE_URL}/image/directupload/`, // 이미지 업로드 URL
//                     withCredentials: true,
//                     headers: {
//                         'Authorization': `Bearer ${access}`,
//                         // 'Content-Type': 'application/json',
//                     },
//                 },
//                 fontFamily: {
//                     options: [
//                         'default',
//                         '나눔스퀘어 네오, Arial, sans-serif',
//                         '조선100년체, Arial, noto-sans',
//                         '함렛, Arial, noto-snas',
//                         '에스코어드림, Arial, sans-serif',
//                         '휴먼범석체, Arial, sans-serif',
//                     ]
//                 },
//                 toolbar: [
//                     'fontFamily',
//                     'fontSize',
//                     'fontColor',
//                     'fontBackgroundColor',
//                     'italic',
//                     '|',
//                     'alignment:left',
//                     'alignment:center',
//                     'alignment:right',
//                     '|',
//                     'insertTable',
//                     'mediaEmbed',
//                     'link',
//                     '|',
//                     'imageUpload',
//                     'imageStyle:alignLeft', // 이미지 좌측 정렬
//                     'imageStyle:alignCenter', // 이미지 우측 정렬
//                     'imageStyle:alignRight', // 이미지 우측 정렬
//                     '|',
//                     'bulletedList',
//                     'numberedList',
//                     'blockQuote',
//                     '|',
//                     'undo',
//                     'redo',
//                 ],
//                 image: {
//                     styles: ['alignLeft', 'alignCenter', 'alignRight'],
//                     toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', 'imageTextAlternative'],
//                 },
//             }}
//             onReady={ editor => {
//                 console.log('에디터 사용준비!', editor)
//             }}
//             onChange={(event, editor) => {
//                 const data = editor.getData();
//                 console.log( {event, editor, data });
//                 setContent(data);
//             }}
//             onBlur={(event, editor) => {
//                 console.log('Blur.', editor);
//             }}
//             onFocus={(event, editor) => {
//                 console.log('Focus.', editor);
//             }}
//             onError={ ( event, editor ) => {
//                 console.error( 'CKEditor error: ', event );
//             }}
//         />
//         </>
//     )
 
// }