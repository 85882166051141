/***************************************************************************************
* FileName      : Reviewable.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 작성가능한 리뷰 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { extractYearMonthDayHourMinute2 } from '../Component/extractDateUtils';
import WriteReviewModal from './WriteReviewModal'

function ReviewBox({orderItem})
{
   // 후기 작성 모달 
   const [isOpen, setIsOpen] = useState(false);

   const handleWriteBtnClick = (id) => {
        const isConfirmed = window.confirm('리뷰 작성 시 자동으로 구매확정 처리 됩니다. 리뷰 작성 하시겠습니까? ');

        if (isConfirmed) {
            openModal();
        }
   };


   const openModal = () => {
       console.log("cccc");
       setIsOpen(true);
   };

   console.log(orderItem);
   console.log("orderItem")
    return(
  
        <div className='m-0 p-0'>
            <Row className='border ms-0 me-0 mt-1 mb-1 p-2 d-flex align-items-center'>
                <Col xs={12} sm={3} className='p-2'>
                    <a>
                        <img src={orderItem?.product.main_image} width="100%"/>
                    </a>   
                </Col>
                
                <Col xs={12} sm={6} className='p-2 text-left'>
                    <p className='fs-7 text-grey fw-bolder'>
                        구매확정일 : 
                        {extractYearMonthDayHourMinute2(orderItem?.confirmed_at)} 
                    </p>
                    <a className='review-inf'>{orderItem?.product_name}</a>
                    <p className='fs-7 text-grey fw-bold'>
                    {/* [옵션] */}
                    {/* {
                        orderItem?.option_rows.map(item => 
                            <p className="pt-1 fs-7 fw-bolder text-grey"> {item.name}</p>
                        )
                    } */}
                    </p>
                    <p className='mt-2 text-grey fw-500'>{orderItem?.product.vendor_name}</p>
                </Col>
                <Col xs={12} sm={3} className='p-2'>
                    <button className='btnSubmitFix m  w-100'  onClick={() => handleWriteBtnClick()}>
                        리뷰쓰기
                    </button>   
                    <p className='fs-7'>작성기한 23.09.22(D-26)</p>
                
                </Col>
            </Row>
                        
            {isOpen && (
                <WriteReviewModal
                    orderItem={orderItem}
                    setIsOpen={setIsOpen}/>
            )}
        </div>
        
    

    )
}

export default ReviewBox