import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <>
        <tr>
          <td>{Adjust.sale_date}</td>
          <td>{Adjust.total_settlement_target_amount.toLocaleString()}</td>
          <td>{Adjust.total_settlement_fee.toLocaleString()}</td>
          <td>{Adjust.total_settlement_amount.toLocaleString()}</td>
         </tr>
      </>
    </>
  );
}
