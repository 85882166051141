import { instance } from '../../axiosInstance/axiosConfig';
import axios from 'axios';

export async function getUploadURL(ImageFile) {
  try {
    const response = await instance.post(`image/aws-upload/`);
    const data = response.data;

    return {
      uploadURL: data.uploadURL,
      imageURL: data.imageURL,
      file: ImageFile
    }

  } catch (error) {
    console.log('이미지 등록 오류가 발생했습니다.', error);
    return null;
  }
}


export const uploadImage = async ({ file, uploadURL, imageURL }) => {
  try {
    return axios.put(uploadURL, file).then(()=>imageURL)
  } catch (error) {
    console.log('이미지 업로드 중 오류가 발생했습니다.', error);
    return null;
  }
};
