/***************************************************************************************
* FileName      : PushRow.js
* Description   : 해와관리자 > 알림 > 푸시알림 > 발송내역 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.06
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { extractYearMonthDayHourMinute2 } from '../../../../mypage/Component/extractDateUtils';

export const PushRow  =  ({ push, index }) => {

  const navigate = useNavigate();

  return(
    <tr key={push?.id}>

      <td>{push?.id}</td>
      <td>{push?.title}</td>
      <td>{push.sent_at ? extractYearMonthDayHourMinute2(push?.sent_at) : "-"}</td>
      <td>{push?.body}</td>
      <td>  
        {push?.receiver.map((receiver, index) => (
            <span key={index}>
                {receiver.email}
                <br />
            </span>
        ))}
        </td>
{/* 
      <td>{extractYearMonthDayHourMinute2(push?.sent_at)}</td> */}


  </tr>
  
  )
}

