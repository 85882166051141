/***************************************************************************************
* FileName      : ProductImageUpload.js
* Description   : 해와관리자 > 상품관리 > 상품등록 페이지 > 상품이미지 등록 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState } from "react";
import axios from "axios";

const ProductImageUpload = ({ onImagesUpload , onImagesDelete }) => {
    const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(6).fill(''));
    const [imageFiles, setImageFiles] = useState([]);
    
    const handleImageChange = (e, index) => {
      e.preventDefault();
  
      // const newImageFiles = [...imageFiles];
      // newImageFiles[index] = e.target.files[0];
      // setImageFiles(newImageFiles);

      const file = e.target.files[0];
  
      onImagesUpload(file,index);
      
      const reader = new FileReader();
      
      //const file = e.target.files[0];
  
      reader.onloadend = () => {
        const newImagePreviewUrls = [...imagePreviewUrls];
        newImagePreviewUrls[index] = reader.result;
        setImagePreviewUrls(newImagePreviewUrls);
      }
  
      reader.readAsDataURL(file);
    }
  
    // 이미지 삭제 기능 함수 
    const handleImageDelete = (index) => {
      setImagePreviewUrls((prevImagePreviewUrls) => {
        const newImagePreviewUrls = [...prevImagePreviewUrls];
        newImagePreviewUrls[index] = '';
        return newImagePreviewUrls;
      });

      onImagesDelete(index , index === 0);
      
    };
    
    return (
        <>
        <p className="notice-text">
            크기 : 최소 100px이상(권장크기 500 x 500), 용량 : 100MB이하, 파일형식 : JPG, PNG <br/>
            추가이미지는 최대 5개까지 등록 가능합니다.
        </p>
        <div className="productImageUploader">
          {[...Array(6)].map((_, index) => (
            <div className="imageUploadContainer" key={index}>
              <label htmlFor={`fileInput${index}`} className="fileInputLabel">
                <div className="imagePreviewContainer">
                  {imagePreviewUrls[index] ? (
                    <img src={imagePreviewUrls[index]} alt={`Product ${index + 1}`} className="imagePreview" />
                  ) : (
                    <div className="imagePlaceholder">{`상품 이미지 ${index + 1}을 업로드하세요`}</div>
                  )}
                  {imagePreviewUrls[index] && (
                    <button className="deleteImageButton" onClick={() => handleImageDelete(index)}>
                      X
                    </button>
                  )}
                </div>
                <input
                  id={`fileInput${index}`}
                  type="file"
                  className="fileInput"
                  accept=".jpg,.jpeg,.png"
                  key={imagePreviewUrls[index]}
                  onChange={(e) => handleImageChange(e, index)}
                />
              </label>
              <p className="imageUploadDescription">{index === 0 ? "대표이미지(필수)" : `추가이미지(선택)`}</p>
            </div>
          ))}
        </div>

        </>
      );
      
    }
  
export default ProductImageUpload