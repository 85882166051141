import React, { useState , useEffect } from 'react';
import { useProductsQuery } from '../../../../api/selleradmin/productApi.js';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { updateSellerProductState } from '../../../../api/product/updateProductData.js';
import { updateSellerProductOriginalPrice, updateSellerProductPrice, updateSellerProductStock } from '../../../../api/product/sellerUpdateProductData.js';
import { AiOutlineEdit } from 'react-icons/ai';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils.js';

export const ProductRow = ({ product, expandedIds, handleExpandClick, selectedRows, setSelectedRows }) =>  {
  const navigate = useNavigate();
  
  const { fetchNewProductDetailsById } = useProductsQuery();

  const [productDetails, setProductDetails] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchProductDetails() {
      try {
        const details = await fetchNewProductDetailsById(product?.id);
        console.log("디텡ㄹ")
        console.log(details)

        setProductDetails(details);
      } catch (error) {
        console.error('제품 세부 정보를 가져오는 중 오류가 발생했습니다:', error);
      }
    }
    fetchProductDetails();
  }, [product.id]);

  // [hm] 상태변경(판매/판매중지)을 위한 체크박스 추가 : 2023.09.15
  useEffect(()=>{
    console.log(selectedRows);
  },[selectedRows])

  if (!productDetails) {
    return <div>Loading...</div>;
  }

  const openModal = () => {
    console.log("cccc");
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // 수정버튼
  const handleEditClick = (productId) => {
    navigate(`/selleradmin/product/edit/${productId}`);
  };

  const handleCopyClick = (productId) => {
    navigate(`/selleradmin/product/copy/${productId}`);
  };

  return (
    <React.Fragment key={product?.id}>
      <tr>
        <td>
          <button
            className='expand-btn'
            onClick={() => handleExpandClick(product?.id)}
          >
            <i
              className={
                expandedIds.includes(product?.id) ? 'xi-angle-up' : 'xi-angle-down'
              }
            ></i>
          </button>
        </td>
        <td>
          <input type="checkbox" 
            onClick={()=>
              setSelectedRows({
                ...selectedRows,
                [product.id]: !selectedRows[product.id]
              })
            } 
          />
        </td>

        <td>
          <span className={productDetails?.approve_status === "승인거절" ? "statusBadge finish" : (
              productDetails?.approve_status === "승인완료" ? "statusBadge request" :  "statusBadge")}>
                {productDetails?.approve_status}
            </span>
        </td>

        <td className="px-4">
          <ToggleSwitch pk={productDetails?.id} checked={productDetails?.sale_status}/>
        </td>

        <td className=''>
          {productDetails?.sale_status != "판매중" ?   
            <button className='t-s-btn me-2' onClick={() => handleEditClick(product?.id)}>수정</button>  
            : <button className='t-s-btn me-2 impossible'>수정</button>
          }
        
          <button className='t-s-btn' onClick={() => handleCopyClick(product?.id)}>
            복사
          </button> 
        </td>

        <td>{product?.product_id}</td>
        <td><img src={productDetails?.main_image} width="80px"/></td>
        <td>{product?.name}</td>

        {/* [hm] 정상가 변경(prompt 사용) : 2023.09.20 */}
        <td>
          <span className="fs-7 text-grey">
            {product?.original_price.toLocaleString()} 
              {productDetails?.option_rows?.length == 0 && 
                <AiOutlineEdit className="text-grey pointer" 
                  onClick={()=>{
                    let newOriginalPrice = prompt("정상가 수정(숫자만 입력)\n"+product?.name, product?.original_price);
                    if(Number(newOriginalPrice)){
                      updateSellerProductOriginalPrice(product?.id, newOriginalPrice);
                    }else{
                      alert('변경 실패 : 숫자만 입력해주세요.');
                    }
                  }}
                />
            }
          </span>
        </td>

        {/* [hm] 상품금액 변경(prompt 사용) : 2023.09.20 */}
        <td>
          {product?.price.toLocaleString()} 
          {productDetails?.option_rows?.length == 0 && 
            <AiOutlineEdit className="text-grey pointer" 
              onClick={()=>{
                let newPrice = prompt("판매금액 수정(숫자만 입력)\n"+product?.name, product?.price);
                if(Number(newPrice)){
                  if(newPrice <= product?.original_price){
                    updateSellerProductPrice(product?.id, newPrice);
                  }else{
                    alert('변경 실패 : 정상가보다 비싸게 판매할 수 없습니다.');
                  }
                }else{
                  alert('변경 실패 : 숫자만 입력해주세요.');
                }
              }}
          />}
        </td>

        {/* [hm] 재고수량 변경(prompt 사용) : 2023.09.20 */}
        <td>
          {product?.stock.toLocaleString()} 
            {productDetails?.option_rows?.length == 0 && <AiOutlineEdit className="text-grey pointer" 
              onClick={()=>{
                let newStock = prompt("재고 수정(숫자만 입력)\n"+product?.name, product?.stock);
                if(Number(newStock)){
                  updateSellerProductStock(product?.id, newStock);
                }else{
                  alert('변경 실패 : 숫자만 입력해주세요.');
                }
              }}
              />
            }
        </td>

        <td>{productDetails?.sub_images != [] && productDetails?.sub_images !== undefined && productDetails?.sub_images.map(image => (<img src={image.path} width="80px"/>) )}</td>
        
        <td>
          <button className='t-s-btn' onClick={openModal}>상세설명</button> 
        </td>
        <td>{productDetails?.inspect_info?.manufacturer}</td>
        {/* [hm] 카테고리 순서 섞이는 오류 수정 : 2023.09.18 */}
        <td>{product?.category.find(category => category.depth === 1)?.name || ""} / {product?.category.find(category => category.depth === 2)?.name || ""} / {product?.category.find(category => category.depth === 3)?.name || ""}</td>
        <td>{productDetails?.product_filter != [] && productDetails?.product_filter !== undefined && productDetails?.product_filter.map(name => name.name+"/")}</td>
        <td>{productDetails?.inspect_info?.minor_purchase ? "구매 가능" : "구매 불가"}</td>
        <td>{productDetails?.inspect_info?.max_purchase_per_person === 99999 ? "제한없음" : productDetails?.inspect_info?.max_purchase_per_person}</td>
        <td>{productDetails?.delivery_info?.days_required_for_shipping}</td>
        <td>{productDetails?.delivery_info?.shipping_method}</td>
        <td>{productDetails?.delivery_info?.bundle_delivery_available ? "묶음배송 가능" : "묶음배송 불가"}</td>
        <td>{productDetails?.delivery_info?.island_delivery_available ? "배송 가능" : "배송 불가"}</td>
        <td>{productDetails?.delivery_info?.courier}</td>
        <td>{productDetails?.delivery_info?.shipping_cost_type}</td>
        <td>{productDetails?.delivery_info?.free_condition === 0 ? "없음" : productDetails?.delivery_info?.free_condition}</td>
        <td>{productDetails?.delivery_info?.shipping_cost}</td>
        <td>{productDetails?.return_exchange_info?.initial_delivery_fee}</td>
        <td>{productDetails?.return_exchange_info?.return_delivery_fee}</td>
        <td>{extractYearMonthDayHourMinute2(productDetails?.inspect_info?.sales_start_date)}</td>
        <td>{extractYearMonthDayHourMinute2(productDetails?.inspect_info?.sales_end_date)}</td>
        <td>{extractYearMonthDayHourMinute2(product?.created_at)}</td>
        <td>{extractYearMonthDayHourMinute2(product?.updated_at)}</td>
        <td>수정이력</td>


        {/* 상세설명 모달창 */}
          {/* {isOpen && (
            <div className="modal ">
              <div className="modal-content ProductDetailModal">
                  <span className="close" onClick={closeModal}>&times;</span>
                  <p> {productDetails.detail}</p>
                
              </div>
            </div>
          )}
          */}
          <DetailModal show={isOpen} setShow={setIsOpen} tag={productDetails?.detail}/>
      </tr>
      
      {expandedIds.includes(product.id) && (
        <tr className='sub-table'>
          <td colSpan={13}>
            <table>
              <thead>
                <tr>
                  <th>
                    <input type='checkbox' />
                  </th>
                  <th>상태</th>
                  <th>옵션아이디</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>재고수량</th>
                </tr>
              </thead>
              <tbody>
            
                {/* sub-table 내용 */}
                {product?.option_rows.map((optionRow, index) => (
                  <tr key={index}>
                    <td></td>
                    <td></td>

                    <td>{optionRow?.id}</td>
                    <td>{optionRow?.name}</td>
                    {/* {optionRow.option_value.map((optionValue) => (
                      <td key={optionValue.id}>{optionValue.name}</td>
                    ))} */}
                    <td>{optionRow?.original_price}</td>
                    <td>{optionRow?.price}</td>
                    <td>{optionRow?.stock}</td>
                  </tr>
                ))}
            
              </tbody>
            </table>
          </td>
        </tr>
      )}
  </React.Fragment>

  );
};

function DetailModal({show, setShow, tag}){
  return(
    <>
    <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            상세페이지 미리보기
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div width="90%">
              <div className='editor-content'>
                <div dangerouslySetInnerHTML={{ __html: tag }}/>
              </div>
            </div>
        </Modal.Body>
    </Modal>
  </>
  )
}

// [hm] 판매중/판매중지 상태 변경을 토글방식으로 적용 : 2023.09.19
export default function ToggleSwitch({ pk, checked }) {
  // 초기값을 조건에 따라 설정
  const initialChecked = checked === '판매중' || checked === '품절' ? true : false;
  const [isChecked, setIsChecked] = useState(initialChecked);
  const [disabledToggle, setDisabledToggle] = useState(checked === '판매대기' || checked === '품절');

  const handleToggle = () => {
    // 토글이 가능한 경우에만 토글 상태 변경
    if (!disabledToggle) {
      setIsChecked(!isChecked);
      if(checked === '판매중'){
        updateSellerProductState(pk, "판매중지");
      }else if(checked === '판매중지'){
        updateSellerProductState(pk, "판매중");
      }else{
        alert('판매상태 변경이 불가능한 상품입니다.');
      }
      window.location.reload();
    }
  }

  return (
    <>
      <label className={`toggle ${isChecked ? 'checked' : ''}`}>
        <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
        <span className={disabledToggle ? 'toggle-slider disabled' : 'toggle-slider'}></span>
      </label>
      {/* <br/>{isChecked ? <span className="text-hw fw-bold">판매중</span> : <span className="text-grey">판매중지</span>} */}
      <br/><span className={checked === "판매중" ? "text-hw fs-8 fw-bold" : checked === "판매중지" ? "text-grey fs-8 fw-bold" : checked === "품절" ? "text-danger fs-8 fw-bold" : "text-secondary fs-8 fw-bold"}>{checked}</span>
    </>
  );
}