/***************************************************************************************
* FileName      : VendorRow.js
* Description   : 해와관리자 > 셀러관리 > 신규요청 > VendorRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { useNavigate } from 'react-router-dom';
// import { updateVendorStatusData } from '../../../../api/storeApi';
// import { useMutation } from 'react-query';
import React, { useState } from 'react';
import { setVendorState } from '../../../../api/hwadmin/storeApi';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

export const VendorRow  =  ({ vendor, index, selectedRows, setSelectedRows, selectedRowsData, setSelectedRowsData, setApproveVendor }) => {
  // 데이터 수정
  // const updateDataMutation = useMutation((newData) => updateVendorStatusData(newData, Id));

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // 거절사유 
  const [rejectMessage, setRejectMessage] = useState('');

  const handleTextareaChange = (event) => {
    setRejectMessage(event.target.value);
  };

  const Id = vendor?.id; 


  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/member/management/?search=${searchQuery}`;
    navigate(newUrl);
  };

  const handleStatusClick = (status) => {
    if(status==="거절"){
      // setIsOpen(true)
      // const updatedData = {
      //   status: '거절',
      //   reject_message: rejectMessage
      // };
      // setRefuseData(updatedData);

      // console.log(refuseData)
      
      // updateDataMutation.mutate(refuseData, {
      //   onSuccess: () => {
      //     closeModal();
      //   },
      // });
      // console.log(String(Id), "reject", rejectMessage);


      setVendorState(String(Id), "reject", rejectMessage, vendor?.status)
        .then(res => {
          console.log(res?.data);
          alert('입점이 거절되었습니다.');
        })
        .catch(error => alert(error?.response?.data?.product))
        .finally(()=>window.location.reload())
    }else{
      // updateDataMutation.mutate(approveData)
      // window.location.reload();
    }
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return(
    <tr
      key={vendor?.id}
      style={{
        backgroundColor: selectedRows[vendor.id] ? "#f7f7f6" : "transparent"
      }}
    >
      <td className="sticky-col-1">
        <input
          type="checkbox"
          name=""
          value=""
          onClick={() => 
            {
              setSelectedRows({
                ...selectedRows,
                [vendor.id]: !selectedRows[vendor.id]
              })
              setSelectedRowsData({
                ...selectedRowsData,
                [vendor.id]: {
                  vendor_name: vendor?.owner?.username,
                  vendor_owner: vendor?.owner?.username,
                  business_number: vendor?.business_number,
                  business_license: vendor?.business_license,
                }
              })
            }
          }
        />
      </td>
      <td className="sticky-col-2">{index + 1}</td>
      <td className="sticky-col-3">{vendor?.name}</td>
      <td>
        <span
          className={`statusBadge ${
            vendor?.status === "대기" ? "request" : "modify"
          }`}
        >
          {vendor?.status === "대기" ? "신규요청" : "정보수정"}
        </span>
      </td>
      <td className='blueLink' onClick={() => handleCellClick(vendor.owner?.username)}>
        {vendor?.owner && vendor?.owner?.username}
      </td>
      
      <td>{vendor?.business_type}</td>
      <td><img src={vendor?.business_license} width="80px" onClick={()=>window.open(vendor?.business_license)}/></td>
      <td><img src={vendor?.sales_registration} width="80px" onClick={()=>window.open(vendor?.sales_registration)}/></td>
      <td>{vendor?.business_number}<br/>{vendor?.sales_registration_number ? vendor?.sales_registration_number : "-"}</td>
      <td>{vendor?.update_status}</td>
      <td>({vendor?.dispatch_location?.postal_code}) {vendor?.dispatch_location?.address} {vendor?.dispatch_location?.detail}</td>
      <td>({vendor?.return_address?.postal_code}) {vendor?.return_address?.address} {vendor?.return_address?.detail}</td>
      <td>{vendor?.tel}<br/>{vendor?.fax}</td>
      <td>{vendor?.hidden ? '비공개' : '공개'}</td>
      <td>{vendor?.employee_list.map(employee => employee?.username+" ")}</td>
      <td>({vendor?.address?.postal_code}) {vendor?.address?.address} {vendor?.address?.detail}</td>
      <td><img src={vendor?.owner_image} width="80px" onClick={()=>window.open(vendor?.owner_image)}/></td>
      <td><img src={vendor?.account_image} width="80px" onClick={()=>window.open(vendor?.account_image)}/></td>
      <td>{vendor?.bank}<br/>{vendor?.account_number}</td>
      <td>{extractYearMonthDayHourMinute2(vendor?.created_at)}</td>
      <td>{vendor?.co_representative ? "공동대표 있음" : "-"}</td>
      <td>{vendor?.tax}</td>
      <td>
        <button className='m-sm-btn me-2'
          onClick={() => {
            setSelectedRows({[vendor.id]: true});
            setApproveVendor(true);
            }}
          >승인</button>
        <button className='m-sm-btn' onClick={openModal}>반려</button>

      </td>

    {/* 반려 모달창 */}
    {isOpen && (
        <div className="modal ">
          <div className="modal-content ProductDetailModal">
              <span className="close" onClick={closeModal}>&times;</span>
              <p className='modal-tit'>반려사유 입력</p>
              <textarea cols="30" rows="10" value={rejectMessage} onChange={handleTextareaChange}>

              </textarea>
              <div className='modal-button-wrap'>
                <button className='m-btn m-btn-search'
                      onClick={() => {
                        handleStatusClick("거절");
                      }}>
                      완료</button>
              </div>
            
          </div>
        </div>
    )}
  
  </tr>
  
  )
}
