import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <tr>
        <td rowSpan={2}><input type="checkbox"/></td>
        <td rowSpan={2}>{Adjust.date}</td>
        <td>매출</td>
        <td>{Adjust.sales.카드.toLocaleString()}원</td>
        <td>{Adjust.sales.가상계좌.toLocaleString()}원</td>
        <td>{Adjust.sales.간편결제.toLocaleString()}원</td>
        <td>{Adjust.sales.휴대폰.toLocaleString()}원</td>
        <td>{Adjust.sales.계좌이체.toLocaleString()}원</td>
        <td>{Adjust.sales.합계.toLocaleString()}원</td>
      </tr>
      <tr>    
        <td>정산금</td>
        <td>{Adjust.settlement_amount.카드.toLocaleString()}원</td>
        <td>{Adjust.settlement_amount.가상계좌.toLocaleString()}원</td>
        <td>{Adjust.settlement_amount.간편결제.toLocaleString()}원</td>
        <td>{Adjust.settlement_amount.휴대폰.toLocaleString()}원</td>
        <td>{Adjust.settlement_amount.계좌이체.toLocaleString()}원</td>
        <td>{Adjust.settlement_amount.합계.toLocaleString()}원</td>
      </tr>
       
    </>
  );
};
