import React, { useState , useEffect} from "react";
import axios from "axios";


const ProductImageUpload = ({ uploadedImages , productSubImageFiles , onImagesUpload , onImagesDelete }) => {
    const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(6).fill(''));
    const [imageFiles, setImageFiles] = useState([]);
    

    useEffect(() => {
        // 이미지 URL 데이터를 기반으로 이미지 프리뷰 초기화
        const newImagePreviewUrls = [...imagePreviewUrls];

        if (uploadedImages.main_image) {
            newImagePreviewUrls[0] = uploadedImages.main_image;
        }
  
        if (productSubImageFiles && productSubImageFiles.length > 0) {
            productSubImageFiles.forEach((subImage, index) => {
                newImagePreviewUrls[index + 1] = subImage.image;
            });
        }
  
        setImagePreviewUrls(newImagePreviewUrls);

  }, [uploadedImages]);
  


  const handleImageChange = (e, index) => {
      e.preventDefault();
  
      // const newImageFiles = [...imageFiles];
      // newImageFiles[index] = e.target.files[0];
      // setImageFiles(newImageFiles);

      const file = e.target.files[0];
  
      onImagesUpload(file,index);
      
      const reader = new FileReader();
      
      //const file = e.target.files[0];
  
      reader.onloadend = () => {
        const newImagePreviewUrls = [...imagePreviewUrls];
        newImagePreviewUrls[index] = reader.result;
        setImagePreviewUrls(newImagePreviewUrls);
      }
  
      reader.readAsDataURL(file);
  }
  
  // 이미지 삭제 기능 함수 
   const handleImageDelete = (index) => {
      setImagePreviewUrls((prevImagePreviewUrls) => {
        const newImagePreviewUrls = [...prevImagePreviewUrls];
        newImagePreviewUrls[index] = '';
        return newImagePreviewUrls;
      });

      onImagesDelete(index , index === 0);
  };


    return (
        <>
        <p className="notice-text">
            크기 : 최소 100px이상(권장크기 500 x 500), 용량 : 100MB이하, 파일형식 : JPG, PNG <br/>
            추가이미지는 최대 5개까지 등록 가능합니다.
        </p>

        <div className="productImageUploader">
          {[...Array(6)].map((_, index) => (
            <div className="imageUploadContainer" key={index}>
                <label htmlFor={`fileInput${index}`} className="fileInputLabel">
                    <div className="imagePreviewContainer">
                      {imagePreviewUrls[index] ? (
                        <img src={imagePreviewUrls[index]} alt={`Product ${index + 1}`} className="imagePreview" />
                      ) : (
                        <div className="imagePlaceholder">{`상품 이미지 ${index + 1}을 업로드하세요`}</div>
                      )}

                      {imagePreviewUrls[index] && (
                        <button className="deleteImageButton" onClick={() => handleImageDelete(index)}>
                          X
                        </button>
                      )}
                  </div>

                  <input
                    id={`fileInput${index}`}
                    type="file"
                    className="fileInput"
                    accept=".jpg,.jpeg,.png"
                    key={imagePreviewUrls[index]}
                    onChange={(e) => handleImageChange(e, index)}
                  />
              </label>

              <p className="imageUploadDescription">{index === 0 ? "대표이미지(필수)" : `추가이미지(선택)`}</p>
            </div>
          ))}
        </div>

        </>
      );
      
    }
  
export default ProductImageUpload