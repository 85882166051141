import { useNavigate, useSearchParams } from "react-router-dom"
import { Container, Col, Row } from 'react-bootstrap'; 
import { TbAlertOctagonFilled } from 'react-icons/tb';

export default function FailPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  // 고객에게 실패 사유 알려주고 다른 페이지로 이동

  return (
    <div className='m-0 p-0 bg-grey'>
      <Container className="px-3 py-5">
        <Row className='m-0 p-3 rounded-2 bg-white shadow-box text-center'>
          <h1 className='fs-6'>결제 실패</h1>
          <TbAlertOctagonFilled className="fs-1 my-3 text-hw"/>
          <p className="fs-7 fw-bold">결제가 실패되었습니다.</p>

          <hr className="my-3"/>
          <p className="fs-7 text-grey fw-bolder">확인 후 다시 시도하거나 고객센터로 연락주세요.</p>

          <p className="mt-1 fs-7">{`사유: ${searchParams.get("message")}`}</p>

          <button type='button' className='mt-5 p-2 bg-hw text-white border-0 rounded-2' onClick={()=>navigate(-1)}>
            결제 페이지로 돌아가기
          </button>
        </Row>
      </Container>
    </div>
  )
}