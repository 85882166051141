/***************************************************************************************
* FileName      : Month.js
* Description   : 해와관리자 > 통계 > 방문통계 > 월별 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import Chart from '../../../components/Chart'

import { getVisitMonthly } from "../../../../api/hwadmin/statisticApi";
import { useQuery } from "react-query";

import { labelDataObject , chartBackgroundColor , chartBorderColor } from './chartConfig.js';

import YearSelect from "../Component/YearSelect.js";
import CheckboxGroup from "../Component/CheckboxGroup.js";

function Month()
{
    const [chartData,setChartData] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    const [isChecked, setIsChecked] = useState({
        total_visiter: false,
        pc_visiter: false,
        mobile_app_visiter: false,
        mobile_visiter: false,
        app_visiter: false,
    });

    const currentYear = new Date().getFullYear().toString();

    const [year, setYear] = useState(currentYear);
    const [searchYear, setSearchYear] = useState(currentYear);

    const { isLoading, error, data } = useQuery(["month", year], () => getVisitMonthly(year));


    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.labels, 
                datasets: [
                  {
                    label: '모바일(앱+웹)',
                    data: data?.mobile_app_visiter,
                    backgroundColor: '#FBE7B4',
                    borderColor: '#F7CF6B',
                    pointBackgroundColor: '#FBE7B4',
                    pointBorderColor: '#F7CF6B',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },  {
                    label: 'PC',
                    data: data?.pc_visiter,
                    backgroundColor: '#AACFF2',
                    borderColor: '#57A0E5',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },
                ],
            };
    
            setChartData(newChartData);
        }
     
        
    },[data])
    
    const handleCheckboxChange = (event) => {
        setIsChecked({
            ...isChecked,
            [event.target.name]: event.target.checked,
        });
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/visitStats/'; // 페이지 리로드
    };
    

    // 검색버튼 클릭 시 
    const onSearch = () => {
        setYear(searchYear);
        updateChartData(isChecked);
    };

    function updateChartData(isChecked)
    {
        const labels = data?.labels;
        const datasets = [];
        for (const key in isChecked) {
            if (isChecked[key]) {
              const dataKey = data?.[key];
              const backgroundColor = chartBackgroundColor[key];
              const borderColor = chartBorderColor[key];
              const label = labelDataObject[key];

              console.log("label:",label);
                console.log("key:",key);
              datasets.push({
                label: label,
                data: dataKey,
                backgroundColor,
                borderColor,
                pointBackgroundColor: backgroundColor,
                pointBorderColor: borderColor,
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
              });
            }
        }
        
        const newChartData = {
            labels: labels,
            datasets: datasets,
        };
        
        // console.log("newChartData::",newChartData);;
        
    
        setChartData(newChartData);
        setIsUpdated(true);
    }
        
        
    
    return (
        <>
          {/* 검색 */}
          <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field date'>
                    <div> <b>날짜</b> </div>
                    <div>
                        <YearSelect value={searchYear} onChange={(e) => setSearchYear(e.target.value)} />
                    </div>   
                </div>
            
                <div className='search-field division'>
                    <div> 
                        <b>조회</b> 
                    </div>
                    <CheckboxGroup checkedItems={isChecked} onChange={handleCheckboxChange} />
                </div>

                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

        
        {/* 검색 결과 */}
        <section className='McontentBox'>
            <Chart data={chartData}/>
        </section>
            
    </>
    )
}

export default Month