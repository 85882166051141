/***************************************************************************************
* FileName      : VendorSchedule.js
* Description   : 해와관리자 > 정산 > 지급내역 > 지급내역추가 모달창 > Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.02
* Modifide Date : 
* Reference     : 
***************************************************************************************/

function VendorSchedule({VendorSchedule , onCheckboxChange , isChecked })
{
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        onCheckboxChange(VendorSchedule.id, checked);
    };
    
    console.log("VendorSchedule")
    console.log(VendorSchedule);
    
    return (
        <>
        <tr key={VendorSchedule.id}>
            <td>   
                <input
                    type="checkbox" 
                    name="" 
                    value=""
                    onChange={handleCheckboxChange}
                    checked={isChecked}/>
                   
            </td>
            <td>{VendorSchedule?.order_number}</td>
            <td></td>
            <td>{VendorSchedule?.purchase_confirmation_date}</td>
            <td>{VendorSchedule?.product_name}</td>
            <td>{VendorSchedule?.settlement_target_amount?.toLocaleString()}</td>
            <td>
                {VendorSchedule?.orderer_username}<br/>
                {VendorSchedule?.orderer_name}
            </td>
            <td>{VendorSchedule?.recipient_name}<br/>{VendorSchedule?.recipient_phone_number}</td>
            <td>주소</td>
        </tr>
        </>
    )
}

export default VendorSchedule