import React, { useState , useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSubSubCategories } from '../../../../../api/Category'


function SubSubCategories({ categoryId }) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data: subsubCategoriesData } = useSubSubCategories(categoryId);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching main categories.</div>;
  }
  function handleSubCategoryClick(id,name) 
  {
    console.log(id)
    queryClient.setQueryData('selectedId', id);
    queryClient.setQueryData('selectedName', name);

    queryClient.setQueryData('selectedSubSubcategory', name);

    queryClient.setQueryData('selectedSubSubIdcategory', id);


    setSelectedCategoryId(id);
  }
  return (
    <>
    <div className='categbox'>
      <p className='ctit'>소분류</p>
      <div type="소분류" className='kwutOX'>
        <ul>
          {subsubCategoriesData.data && subsubCategoriesData.data.map((category) => (
            <li className='fBSFej' key={category.id}>
              <button 
                onClick={() => handleSubCategoryClick(category.id, category.name)}
                style={{ color: selectedCategoryId === category.id ? '#0073e9' : 'rgb(0, 0, 0)' }}>
                  {category.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>

    </>
  );
}

export default SubSubCategories;