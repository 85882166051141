import { useAddCategory , useGetCategory } from '../../../../api/Category';
import { useQueryClient } from 'react-query';
import { useQuery } from 'react-query';
import { useState , useEffect } from 'react';


function NewRegistration()
{
    const queryClient = useQueryClient();

    //카테고리 조회 
    const { isLoading, isError, data } = useGetCategory();

    //카테고리 추가 
    const [newCategory, setNewCategory] = useState('')
    const addCategory = useAddCategory();
   
    // 카테고리 상태 관리
    const { data: selectedMaincategory } = useQuery('selectedMaincategory');
    const { data: selectedSubcategory } = useQuery('selectedSubcategory');
    const { data: selectedSubSubcategory } = useQuery('selectedSubSubcategory');
    const { data: selectedfilteringOptions } = useQuery('selectedfilteringOptions');
    const { data: selectedfiltering } = useQuery('selectedfiltering');
   

    // 선택한 카테고리 ID 관리 
    const selectedId = queryClient.getQueryData('selectedId');

    if (isLoading) {
        return <div>Loading...</div>;
    }
     
    if (isError) {
        return <div>Error</div>;
    }
   
    if(!data) return <div/>;
   
    const handleAddCategory = (e) => {
        e.preventDefault();
        if (!addCategory.isLoading) { // 카테고리 추가 요청 중이 아닐 때만 요청을 보냄
            const Category = { name: newCategory , parent: selectedId };
            // console.log("id="+selectedId)

            addCategory.mutate(Category, {
                onSuccess: () => {
                    // 카테고리 추가 요청이 성공하면 목록을 다시 불러옴
                   queryClient.invalidateQueries('Maincategory'); 
                   queryClient.invalidateQueries('Subcategory'); 
                   queryClient.invalidateQueries('SubSubcategory');
                   queryClient.invalidateQueries('filteringOptions');
                   queryClient.invalidateQueries('filtering');
                   
                   alert("새로운 카테고리가 추가 되었습니다")
                   setNewCategory(''); // 입력 폼 초기화
                },
            });
        }
    };
    return (
        <>
        {/* 대분류 카테고리 추가 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className=''>
                    <span>카테고리 추가</span>
                    <p className='selectCategoryText'>
                        {selectedMaincategory}
                        {selectedSubcategory ? ' > ' :''}
                        {selectedSubcategory}
                        {selectedSubSubcategory ? ' > ' :''}
                        {selectedSubSubcategory}
                        {selectedfilteringOptions ? ' > ' :''}
                        {selectedfilteringOptions}
                        {selectedfiltering ? ' > ' :''}
                        {selectedfiltering}
                    </p>
                </div>
            </div>
                    
            <div className='fixTable_wrap'>
            
                <form onSubmit={handleAddCategory}>
                    <input type="text"
                            className='categoryinput mr-5'
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                            placeholder='추가할 카테고리 이름을 입력해주세요'
                    />
                                            
                    <button type="submit" className='m-btn c-addbtn'>
                            {addCategory.isLoading ? 'Loading...' : '추가'}
                    </button>
                </form>
                
            </div>


            </section>
    </>
    )
}

export default NewRegistration