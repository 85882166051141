import {  queryClient , useMutation , useQuery , prefetchQuery} from 'react-query';
import { BASE_URL } from '../../../api/apiConfig';
import { getAccessFromCookie } from '../../../api/cookieUtils';
import { instance } from '../../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

// // [hm] 공통 axios 헤더 인증 토큰 설정
const access = getAccessFromCookie();

// 입점사 로그인 정보 확인
export const getMe = async () => {
    return instance.get('/auth/me')
};

// 입점사 로그인 정보 확인
export const getVendorMe = async () => {
  return instance.get('/vendor/my')
};

// 입점사 본인 매장 정보 확인
export const getVendorSeller = async () => {
  return instance.get('/vendor/seller')
};


// 매장등록
export function useCreatePost() {
  const post = async (data) => {
    try {
      const response = await instance.post(`vendor/seller/register/`, data);
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      throw new Error("Failed to post");
    } 
  }
  return {
    post,
  };
}



// 직원 ID 확인
export function verifyEmployeeId(adminId) {
  console.log(adminId);

  return instance.post(`${BASE_URL}/auth/employee/validate/`, {username: adminId})
    .then((response) => {
      alert("확인완료");
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

