/***************************************************************************************
* FileName      : PurchaseConfirmation.js
* Description   : 해와관리자 > 주문배송 > 구매확정 내역 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useOrderConfirmListQuery , handleOrderConfirmSearch ,  } from '../../../api/hwadmin/orderAPI';

import PageSizeSelect from '../common/PageSizeSelect';
import SortComponent from '../common/SortComponent2';

import { handleResetClick } from '../../components/Resetpage';
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'
import { OrderRow } from './Confirm/OrderRow';
import SelectVendor from '../product/Component/SelectVendor';

function PurchaseConfirmation()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage} = useOrderConfirmListQuery(ordering,pageSize);

    console.log(data);
    console.log("---data--")

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
           console.log("검색결과 있음")
        }
    };

    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const onSearch = () => {
        handleOrderConfirmSearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
            selectedVendor,
            ordering});
        
            setSearchConfirm(true);

    };

            
    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
      
    console.log(data);
    console.log("-data----")
    return(
        <>
        <div className='mainContents'>
            {/* 주문조회 */}
            <div className='page-tit'>
                <h2>구매확정 내역</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="OrderNumber">주문번호</option>
                                <option value="ProductName">상품명</option>
                                <option value="ProductNumber">상품번호</option>
                            </select>

                            <input type="text"
                                value={searchOrder}
                                onChange={(e) => setSearchOrder(e.target.value)}
                            /> 
                        </div>      
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="register">발송일</option>
                                <option value="register">배송완료일</option>
                                <option value="register">구매확정일</option>

                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>

              
                    <div className='search-field word'>
                        <div> 
                            <b>수산상회</b> 
                        </div>
                        <div>
                            <SelectVendor 
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                />  
                        </div>   
                    </div>

                </div>
          
                <div className='m-btn-wrap'>
                    <button className='m-btn' 
                        onClick={()=>handleResetClick('/admin/order/purchaseconfirmation' , setSearchConfirm)}>
                        초기화
                    </button>
                    <button className='m-btn m-btn-search' onClick={onSearch} >검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt && data?.totalCnt}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규주문순"
                                oldLabel="오래된주문순"
                            />
    
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        
                        </div>
                    }
                </div>

                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>주문번호</th>
                                <th>판매자</th>
                                <th>구매확정일</th>
                                <th>구매확정 방식</th>
                                <th>구매자 ID <br/>
                                    구매자 이름
                                </th>
                                <th>수취인 이름<br/>
                                    수취인 연락처
                                </th>
                                <th>배송구분</th>
                                <th>택배사</th>
                                <th>송장번호</th>
                                <th>발송일</th>
                                <th>상품번호 <br/>
                                    상품명
                                </th>
                                <th>옵션정보</th>
                                <th>주문금액</th>
                                <th>정산여부<br/>
                                    정산금액
                                </th>
                            </tr>
                        
                            </thead>
                            <tbody>
                            {searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults.count > 0 ? (
                                searchResults.data.map((order, index) => (
                                    <OrderRow 
                                        key={order.id} 
                                        order={order} 
                                        index={index}
                                      
                                    />
                                ))
                            ) : data?.data?.length > 0 ? (
                                data?.data.map((order, index) => (
                                    <OrderRow 
                                        key={order.id} 
                                        order={order} 
                                        index={index}
                                   
                                    />
                                ))
                            ) : null}

                        </tbody>

                        </table>
                    </div>
                </section>

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>

        </>
    )
}

export default PurchaseConfirmation