import { Modal } from 'react-bootstrap';

function BundleDeliveryModal({show, setShow, Details}){
    return(
      <>
      <Modal
          size="md"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
      >
          <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" style={{ fontSize: '1rem' }}>
          묶음배송 ?
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div width="90%">
               {Details}
              </div>
          </Modal.Body>
      </Modal>
    </>
  )
}
export default BundleDeliveryModal