/***************************************************************************************
* FileName      : useDateFormat.js
* Description   : 날짜 형식 변경 훅
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.01
* Modifide Date : 2024.01.01 : CHM : 날짜 형식 변환
* Reference     : 
***************************************************************************************/

const useDateFormat = (date) => {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const hours = formattedDate.getHours().toString().padStart(2, '0');
    const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export default useDateFormat;