/***************************************************************************************
* FileName      : SettlementStatus.js
* Description   : 셀러관리자 > 정산 > 정산현황
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useQuery } from 'react-query';
import React, { useState , useEffect } from 'react';
import { useAdjustDocumentQuery , handleAdjustDocumentSearch } from '../../../api/selleradmin/adjustApi';

import SortComponent from '../../admin/common/SortComponent4';
import { AdjustRow } from './SettlementStatus/AdjustRow';

import Pagination from '../common/Pagination';
import SearchPagination from '../../admin/common/SearchPagination';
function SettlementStatus()
{
    const [ordering, setOrdering] = useState('-settlement_date');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useAdjustDocumentQuery(ordering,pageSize);
    

    const [searchAdjust, setSearchAdjust] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

       
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const onSearch = () => {
        handleAdjustDocumentSearch({
            searchAdjust, 
            setSearchResults,
            setSearchUrl,
            selectedStartDate,
            selectedEndDate, 
        });

        setSearchConfirm(true);
    };


    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/selleradmin/settlement/settlementStatus'; 
    };
    

    console.log("data",data)
    console.log("searchResults",searchResults)
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>정산현황</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field date'>
                        <div> 
                            <b>정산일</b> 
                        </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>   
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
                </div>
            </section>


            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.length > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.length}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.data.length ? data?.data.length : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            />
                    </div>

                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>정산(예정)일</th>
                                <th>정산예정금액</th>
                                <th>정산완료금액</th>
                                <th>누적 정산액</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchConfirm && searchResults?.length === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchConfirm && searchResults?.length > 0 ? (
                                searchResults?.data.map((Adjust , index) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((Adjust , index ) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            ) : null} 
                    
                        </tbody>
                    </table>
                </div>
            </section>

          
            </div>

        </>
    )
}

export default SettlementStatus