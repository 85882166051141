
/***************************************************************************************
* FileName      : ReviewRow.js
* Description   : 해와관리자 > 게시판 > 상품후기 > ReviewRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import ViewReviewModal from './ViewReviewModal';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

export const ReviewRow  =  ({ review, index, selectedRows, setSelectedRows }) => {
  const navigate = useNavigate();

  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/member/management/?search=${searchQuery}`;
    navigate(newUrl);
  };


  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

 
  return(
    <tr
      key={review?.id}
      style={{
        backgroundColor: selectedRows[review.id] ? "#f7f7f6" : "transparent"
      }}
    >
       <td>
        <input
            type="checkbox"
            name=""
            value=""
            onClick={() =>
              setSelectedRows({
                ...selectedRows,
                [review?.id]: !selectedRows[review?.id]
              })
            }
        />
      </td>
      <td>{index + 1}</td>
      <td>{review?.rating}</td>
      <td>
        {review?.orderitem.id}<br/>
        {review?.orderitem.product_name}
      </td> 
      <td>
        {review?.vendor}
      </td>
      <td className='t-productReview-image'>
        {review?.review_images.map(image => (
          <div className='mrpi-wrap'>
              <img key={image.id} src={image} alt={`Review Image ${image.id}`} />
          </div>
        ))}
      </td>

      <td><button className='t-s-btn' onClick={openModal}>후기</button></td>  

      <td className='blueLink' onClick={() => handleCellClick(review?.user.name)}>
        {review?.user.username}
      </td> 

      <td>
        {extractYearMonthDayHourMinute2(review?.created_at)}
      </td>

      {isOpen && (
        <ViewReviewModal
            reviewId={review.id}
            setIsOpen={setIsOpen}/>
      )}
  
  </tr>
  
  )
}
