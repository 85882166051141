/***************************************************************************************
* FileName      : Category.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발송예약 > 발송유형 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.27
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect , useState } from 'react';
export default function Category({reservationData ,setReservationData}){

    const updateField = (fieldName, newValue) => {
        setReservationData((prevData) => ({
          ...prevData,
          [fieldName]: newValue
        }));    
    };

    // 발송유형
    const [category, setCategory] = useState('즉시발송');
    
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');

    
    const handleCategoryMethod = (event) => {
        const value = event.target.value;
        setCategory(value);
        setHour('');
        setMinute('');

        if(value === "즉시발송") {
            updateField("hour", '');
            updateField("minute", '');     
        }
    };


    const handleHourMethod = (e) => {
        const newData = e.target.value;
        setHour(newData);
        updateField("hour", newData);
    }

    const handleMinuteMethod = (e) => {
        const newData = e.target.value;
        setMinute(newData);
        updateField("minute",newData);
    }


    return(
       <>
        <div>
            <table>                            
                <tbody>
                    <tr>
                         <td className="radioBtn-wrap">
                         
                                <label>
                                    <input
                                        type="radio"
                                        value="즉시발송"
                                        checked={category === '즉시발송'}
                                        onChange={handleCategoryMethod}
                                    />
                                    즉시발송                              
                                </label> 
                            
                            
                                <label>
                                    <input
                                        type="radio"
                                        value="예약발송"
                                        checked={category === '예약발송'}
                                        onChange={handleCategoryMethod}
                                    />
                                    예약발송
                                </label> 
                            

                                <span>( </span>
                                <input 
                                    type="text" 
                                    value={hour} 
                                    className='bgray5'
                                    onChange={handleHourMethod}
                                    disabled={category !== '예약발송'}
                                    />  
                                <span>시 </span>

                                <input 
                                    type="text" 
                                    className='bgray5'
                                    value={minute}
                                    onChange={handleMinuteMethod}
                                    disabled={category !== '예약발송'}
                                    />  
                                <span>분) </span>
                       
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
       </>
    )
}