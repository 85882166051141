import React, { useState , useEffect } from 'react';
import { addCommasToNumberString } from '../../../common/numberUtils';
import AddressModal from '../../Register/AddressModal';

function ReturnExchange({ returnexChangeData,setReturnexChangeData })
{   
    useEffect(()=>{
        if(returnexChangeData.initial_delivery_fee) 
            setInitialDeliveryFee(returnexChangeData.initial_delivery_fee.toString());

        if(returnexChangeData.return_delivery_fee) 
            setReturnDeliveryFee(returnexChangeData.return_delivery_fee.toString());

        // console.table(returnexChangeData);
    }, [returnexChangeData])
    
    // 사업장주소 상세 주소 
    const handleInputDetailChange = (e) => {
        const newDetail = e.target.value;
        
        const updatedDeliveryInfoData = {
            ...returnexChangeData,
            return_address: {
            ...returnexChangeData.return_address,
            detail: newDetail,
            },
        };
        
        // 수정된 데이터로 상태 업데이트
        setReturnexChangeData(updatedDeliveryInfoData);
    };
    
    
    // 반품지 변경 모달
    const [modalIsOpen, setModalIsOpen] = useState(false); //주소입력모달

    const [detailAddress, setDetailAddress] = useState(''); //상세주소
    const [detailModalState, setDetailModalState] = useState(false); //주소 입력모달 false(주소입력화면) <-> true(상세주소)
   
    useEffect(()=>{
        const updatedReturnexChangeData = {
          ...returnexChangeData,
          return_address: {
            ...returnexChangeData.return_address,
            detail: detailAddress,
          },
        };
   
        setReturnexChangeData(updatedReturnexChangeData);
    },[detailAddress])


    const onCompletePost = (data) => {
        console.table(data);

        setReturnexChangeData({
            ...returnexChangeData, // 기존 데이터 유지
            return_address: {
              ...returnexChangeData.return_address, // 기존 return_address 내용 유지
              address: data.address,
              detail: detailAddress?detailAddress:"",
              postal_code: data.zonecode,
              address_type: data.addressType === "R" ? "도로명" : "지번",
            }
        });
        
        setDetailModalState(true);
    };

    // 반품지 상세주소를 입력하지 않고 모달을 닫으면 모달 상태 초기화
    useEffect(()=>{
        if(!modalIsOpen){
            setDetailModalState(false);
        }
    },[modalIsOpen]);

    // 반품지 상세 주소까지 변경한 경우 상태 변경
    const changeAddress = () =>{
        setModalIsOpen(false);
    }


    // 초도배송비 
    const [initialDeliveryFee, setInitialDeliveryFee] = useState('');

    const handleInitialDeliveryFeeChange = (e) => {
        const newInitialDeliveryFee = e.target.value;
        setInitialDeliveryFee(newInitialDeliveryFee);
        setReturnexChangeData((prevInitialDeliveryFee) => ({
              ...prevInitialDeliveryFee,
              initial_delivery_fee: newInitialDeliveryFee
        }));
    };
    
    // 반품배송비 
    const [returnDeliveryFee, setReturnDeliveryFee] = useState('');
    
    const handleReturnDeliveryFeeChange = (e) => {
        const newReturnDeliveryFee = e.target.value;
        setReturnDeliveryFee(newReturnDeliveryFee);
        setReturnexChangeData((preReturnDeliveryFee) => ({
                ...preReturnDeliveryFee,
                return_delivery_fee: newReturnDeliveryFee
        }));
    };
    
    useEffect(() => {
        if (typeof returnexChangeData.initial_delivery_fee === 'string') {
            returnexChangeData.initial_delivery_fee = parseInt(returnexChangeData.initial_delivery_fee.replace(/,/g, ''));
        }
        if (typeof returnexChangeData.return_delivery_fee === 'string') {
            returnexChangeData.return_delivery_fee = parseInt(returnexChangeData.return_delivery_fee.replace(/,/g, ''));
        }
    }, [returnexChangeData]);

    return (
        <>
        <section className="">
            <div className='product-info'>
                <table>
                    <colgroup>
                              
                    </colgroup>
                   
                    <tbody>
                        <tr>
                            <th>반품/교환지</th>
                            <td>
                            <ul className='ec-address w-50'>
                                    <li className='d-flex'>
                                        <input type="text"
                                            placeholder="우편번호"
                                            value={returnexChangeData.return_address.postal_code}
                                            disabled 
                                        />  
                                        <button className="ms-2" 
                                                onClick={()=>setModalIsOpen(true)}>
                                                    주소 검색
                                        </button> 
                                    </li>
                                    <li>
                                        <input type="text"
                                            placeholder="사업장 주소"
                                            value={returnexChangeData.return_address.address}
                                            disabled 
                                        />   
                                    </li>
                                    <li>
                                    <input
                                        type="text"
                                        placeholder="상세주소"
                                        value={returnexChangeData.return_address.detail}
                                        onChange={handleInputDetailChange}
                                  
                                        />
                                    </li>
                                </ul>  
                                
                            </td>
                        </tr>
                        <tr>
                            <th>초도배송비(편도)</th>  
                            <td className='sm-text-wrap'>
                                <input type='text'
                                        value={addCommasToNumberString(initialDeliveryFee)}
                                        onChange={handleInitialDeliveryFeeChange}
                                />
                                <span>원</span>
                            </td>
                        </tr>
                        <tr>
                            <th>반품배송비(편도)</th>  
                            <td className='sm-text-wrap'>
                                <input type='text'
                                       value={addCommasToNumberString(returnDeliveryFee)}
                                       onChange={handleReturnDeliveryFeeChange}
                                />
                                <span>원</span>
                            </td>
                           
                        </tr>
                    </tbody>

                </table>     
            </div>

        </section>

        {/* 반품지변경 모달 */}
        {
            modalIsOpen  ? (
                <AddressModal 
                    show={modalIsOpen} 
                    onHide={setModalIsOpen} 
                    autoClose 
                    onComplete={onCompletePost} 
                    detailModalState={detailModalState} 
                    detailAddress={detailAddress} 
                    setDetailAddress={setDetailAddress} 
                    changeAddress={changeAddress}/>
            ) : null
        }
        </>
    )
}


export default ReturnExchange