/***************************************************************************************
* FileName      : PushNotificationHistory.js
* Description   : 해와관리자 > 알림 > 푸시알림 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import ScheduledMessage from "./push/ScheduledMessage";
import ReservationList from "./push/ReservationList";
import SendingHistory from "./push/SendingHistory";

import React, { useState } from 'react';

function PushNotificationHistory()
{
    const [activeComponent, setActiveComponent] = useState("ScheduledMessage");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            {/* 푸시알림관리 */}
            <div className='page-tit'>
                <h2>푸시알림관리</h2>
            </div>
            <div className='setting-tab-buttons'>

                <button className={activeComponent === "ScheduledMessage" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("ScheduledMessage")}>발송예약</button>

                <button className={activeComponent === "ReservationList" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("ReservationList")}>예약목록</button>

                <button className={activeComponent === "SendingHistory" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("SendingHistory")}>발송내역</button>
          
            </div>
            
            {/* 발송예약 */}
            {activeComponent === "ScheduledMessage" && <ScheduledMessage />}

            {/* 예약목록 */}
            {activeComponent === "ReservationList" && <ReservationList />}

            {/* 발송내역 */}
            {activeComponent === "SendingHistory" && <SendingHistory />}
    

            {/* 검색 */}
      
        </div>

        </>
    )
}

export default PushNotificationHistory