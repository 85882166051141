import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { getMainProductState } from '../../api/selleradmin/productApi';
import { useEffect, useState } from 'react';


function AdminDashboard(){
    const [productState, setProductState] = useState([]);
    useEffect(()=>{
        getMainProductState()
            .then(res => {
                console.log(res.data.data);
                setProductState(res?.data?.data);
            })
            .catch(error => console.log(error.response.data))
    },[])

    return(
        <>
        <div className='mainContents f9'>
            {/* 셀러 관리자 메인 */}
            {/* 2024.01.25: CHM : 하드코딩 찾기 - 셀러 관리자 메인 하드코딩되어있음*/}
            <section className='contentList'>
                {/* 판매/배송 */}
              
                    <div className='itemWrap'>
                        <div className='tit'>
                            <div>
                                <h2>판매/배송</h2>
                                <span>최근 14일 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 00:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>0</p>
                                <span>주문완료</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>결제완료</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>상품준비중</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>배송중</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>배송완료</span>
                            </div>
                        </div>
                    </div>
               
                    {/* 오늘 할 일 */}
                    <div className='itemWrap'>
                        <div className='tit'>
                            <div>
                                <h2>오늘 할 일</h2>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 00:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>0</p>
                                <span>취소현황</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>반품현황</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>교환현황</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>주문미확인</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>미배송현황</span>
                            </div>
                        </div>
                    </div>

                    <div className='itemsWrap'>
                        {/* 상품통계 */}
                        <div className='itemWrap'>
                            <div className='tit'>
                                <div>
                                    <h2>상품통계</h2>
                                    <span>현재 등록된 전체 상품 기준</span>
                                </div>
                                <div className='reset'>
                                    <span className='time'>최근 00:00</span>
                                    <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                                </div>
                            </div>
                            <div class="detailWrap">
                                <div class="status-box">
                                    <p>{productState.filter((product) => product.sale_status === '판매중').length}</p>
                                    <span>판매중</span>
                                </div>
                                <div class="status-box">
                                    <p>{productState.filter((product) => product.sale_status === '판매중지').length}</p>
                                    <span>판매중지</span>
                                </div>
                                <div class="status-box">
                                    <p>{productState.filter((product) => product.sale_status === '품절').length}</p>
                                    <span>품절</span>
                                </div>
                                <div class="status-box">
                                    <p>{productState.filter((product) => product.approve_status === '승인대기').length}</p>
                                    <span>승인대기</span>
                                </div>
                                <div class="status-box">
                                    <p>{productState.filter((product) => product.approve_status === '승인거절').length}</p>
                                    <span>반려</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                  

                    {/* 미확인 문의 및 후기 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>미확인 문의 및 후기</h2>
                                <span>현재상태 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 00:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>0</p>
                                <span>상품문의</span>
                            </div>
                            <div class="status-box">
                                <p>0</p>
                                <span>1:1문의</span>
                            </div>
                        </div>
                    </div>

                    {/* 판매 현황 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>판매 현황</h2>
                                <span>당일판매 현황 00:00~ 23:59</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 00:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap col">
                            <div class="status-box">
                                <p>0<span>건</span></p>
                                <span>주문 건 수</span>
                            </div>
                            <div class="status-box">
                                <p>0<span>원</span></p>
                                <span>결제완료금액</span>
                            </div>
                        </div>
                    </div>

                    {/* 정산 현황 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>정산 현황</h2>
                                <span>현재상태 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 00:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap col">
                            <div class="status-box">
                                <p>0<span>건</span></p>
                                <span>정산완료</span>
                            </div>
                            <div class="status-box">
                                <p>0<span>원</span></p>
                                <span>정산예정</span>
                            </div>
                        </div>
                    </div>

                    {/* 미확인 문의 및 후기 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>패널티 점수</h2>
                                <span>현재상태 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 00:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>alert('테스트 기간에 사용할 수 없는 기능입니다.')}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>0</p>
                                <span>내 현재 패널티 점수</span>
                            </div>
                        </div>
                    </div>
                   
                    <div className='itemWrap mbox'>
                        <div className='tit'>
                            <div>
                                <h2>판매통계</h2>
                                <span>현재상태 기준</span>
                            </div>

                        </div>
                    </div>
                </section>
              
  
            </div>

        </>
    )
}

export default AdminDashboard