/***************************************************************************************
* FileName      : Management.js
* Description   : 해와관리자 > 회원관리 > 회원관리 리스트 조회 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 2024.02.28 : CHM : 알림 모달용 체크박스 상태확인 기능 추가
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container, Modal} from 'react-bootstrap';
import { useLocation, useNavigate  } from 'react-router-dom';
import React, { useState , useEffect } from 'react';
import { useUserListQuery , handleSearch , handleSort , fetchUserId , getExcel } from '../../../api/hwadmin/userAPI';
import PageSizeSelect from '../common/PageSizeSelect';
import { UserRow } from "./UserRow";
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'
import SortComponent from '../common/SortComponent';
import handleExcelDownload from '../common/ExcelDownload';

// 알림모달
import { EmailModal, SmsModal, PushModal } from './ManagementModal';

function Management()
{
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentSearchQuery = searchParams.get('search');
    const [searchQuery, setSearchQuery] = useState(currentSearchQuery || '');

    const [toggle , setToggle] = useState(false);
 
    const [ordering, setOrdering] = useState('-date_joined');
    const [pageSize, setPageSize] = useState(10); 
  
    const { data, error, isFetching, page, setPage , totalPages } = useUserListQuery(ordering , pageSize);

    const [searchAuth, setSearchAuth] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    console.log(data)
 
    const [fetchedData, setFetchedData] = useState(null);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    
    //회원구분
    const [userType, setUserType] = useState('');

    // SMS 수신
    const [selectedSMSReceiver, setSelectedSMSReceiver] = useState('');

    // 이메일 수신
    const [selectedEmailReceiver, setSelectedEmailReceiver] = useState('');

    // 성별 검색
    const [selectedGender, setSelectedGender] = useState('');

    // 회원상태
    const [selectedMemberStatus, setSelectedMemberStatus] = useState('');

    // 생일/기념일
    const [birthdayStartDate, setBirthdayStartDate] = useState('');
    const [birthdayEndDate, setBirthdayEndDate] = useState('');

    // 나이 
    const [startAge, setStartAge] = useState('');
    const [endAge, setEndAge] = useState('');

    
    // 초기 데이터
    const [initialData, setInitialData] = useState(null);

    
    // 전체체크 
    const [selectAll, setSelectAll] = useState(false);

    // 체크박스 체크 했을 때 user.id 데이터 저장 
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [emailModalShow, setEmailModalShow] = useState(false);
    const [mailData, setMailData] = useState([]);
    const [disagreeMailData, setDisagreeMailData] = useState([]);
    const [smsModalShow, setSmsModalShow] = useState(false);
    const [pushModalShow, setPushModalShow] = useState(false);

    // 2024.02.28 : CHM : 알림 모달용 체크박스 상태확인 기능 추가
    // 체크박스 체크된 user의 email들 콘솔로 확인
    function emailCheck(){
        let emails = [];
        let disagree = [];
        selectedUsers.map(selectedId => {
            data?.data?.some(user => user.id == selectedId && (
                user.email_agree ? emails = [...emails, user.email] : disagree = [...disagree, user.email]
            ))
        });
        setMailData(emails);
        setDisagreeMailData(disagree);
        setEmailModalShow(true);
    }

    
    useEffect(() => {
        if (searchQuery) {
            fetchUserId(searchQuery)
              .then(data => {
                setFetchedData(data);
                console.log(fetchedData.data)
              })
              .catch(error => {
                // 오류 처리
                console.error(error);
              });
        }

        // 검색 쿼리 초기화
        setSearchQuery('');
    }, [searchQuery, data]);

    if (isFetching) {
        return <div>Loading...</div>;
    }
      
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // 회원구분 값 변화
    const handleTypeChange = (event) => {
        setUserType(event.target.value);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
           console.log("검색결과 있음")
        }
    };
        
    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleSearch({
            searchAuth, 
            userType, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            ordering,
            selectedSMSReceiver,
            selectedEmailReceiver,
            startAge,
            endAge,
            birthdayStartDate, 
            birthdayEndDate,
            selectedMemberStatus
        }
        );
        setSearchConfirm(true);
    };
      
    // 초기화
    const handleResetClick = () => {
        // 초기 데이터로 되돌립니다.
        setSearchQuery('');
        setSearchConfirm(false)
        console.log("초기화"+searchResults.count)
        window.location.href = '/admin/member/management/'; // 페이지 리로드
    };
    

    // 개별 체크 박스 
    const handleCheckboxChange = (userId, checked) => {
        if (checked) {
            setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
        } else {
            setSelectedUsers((prevSelectedUsers) =>
            prevSelectedUsers.filter((id) => id !== userId)
        );
        }
    };

    // 전체 체크 
    const users = data.data; 
    const allUserIds = users.map((user) => user.id); 

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);

        if (checked) {
            setSelectedUsers(allUserIds);
        } else {
            setSelectedUsers([]);
        }
    };
    
    const handleButtonClick = () => {
        console.log("Selected User IDs:", selectedUsers);
        // 여기서 선택된 user.id 값을 사용하여 원하는 작업을 수행할 수 있습니다.
    };
    


    // console.log(searchResults)
    // console.log(totalPages);
    // 데이터 총 갯수
    const usertotalCnt = data.totalCnt;
    
    // console.log("ffff"+usertotalCnt)
    // const totalPages = Math.ceil(data.totalCnt / data.count) ;

    
  //  const { isSuccess, isError, isLoading, isFetching, error, data } = useQuery('userList', useUserList);

    if(!data.data) return console.log('no data...');
    if (!data) {
        return <div>Loading...</div>;
    }
      
    if (isFetching) console.log('fetching...');
   // if (isLoading) console.log('loading...');
   // if (isError) console.log('error', error);
   // if (isSuccess) console.log('success', data);

    // console.log(data)
    // SMS 수신 선택
    const handleRadioChange = (event) => {
        setSelectedSMSReceiver(event.target.value);
        console.log(selectedSMSReceiver)
    };

    // 이메일 수신 선택
    const handleEmailRadioChange = (event) => {
        setSelectedEmailReceiver(event.target.value);
    };

    // 성별 선택 
    const handleGenderRadioChange = (event) => {
      setSelectedGender(event.target.value);
    };

    //회원상태 선택
    const handleMemberStatusRadioChange = (event) => {
      setSelectedMemberStatus(event.target.value);
    };

    // 생일/기념일 선택
    const handleBirthdayStartDateChange = (event) => {
        setBirthdayStartDate(event.target.value);
    };
    
    const handleBirthdayEndDateChange = (event) => {
        setBirthdayEndDate(event.target.value);
    };

    // 나이 구간 입력
    const handleStartAgeChange = (event) => {
        setStartAge(event.target.value);
    };
    
    const handleEndAgeChange = (event) => {
        setEndAge(event.target.value);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>회원관리</h2>
            </div>

            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="id">아이디</option>
                                <option value="name">이름</option>
                                <option value="email">이메일</option>
                                <option value="phone">휴대폰번호</option>
                                <option value="address">주소</option>
                            </select>
                            <input type="text"
                                    value={searchAuth}
                                    onChange={(e) => setSearchAuth(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div>
                            <b>날짜</b>
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="join">가입일</option>
                                <option value="last">마지막방문일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>
                    </div>

                    <div className='search-field division'>
                        <div> 
                            <b>회원구분</b> 
                        </div>
                        <div>
                            <select className='filter-select' value={userType} onChange={handleTypeChange}>
                                <option value="">전체</option>
                                <option value="일반회원">일반회원</option>
                                <option value="셀러">셀러</option>
                                <option value="관리자">관리자</option>
                            </select>
                        </div>   
                    </div>
                
                    <div className={toggle ? 'Detailed-search-show' : 'Detailed-search'}>
                        <div className='search-field range'>
                            <div> 
                                <b>결제금액</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                
                        <div className='search-field range'>
                            <div> 
                                <b>주문횟수</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>건</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>건</span>
                            </div>   
                        </div>
                   
                        <div className='search-field range'>
                            <div> 
                                <b>포인트</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div>
                                <b>SMS 수신</b>
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='' checked={selectedSMSReceiver === ''} onChange={handleRadioChange} />
                                <label htmlFor='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='true' checked={selectedSMSReceiver === 'true'} onChange={handleRadioChange} />
                                <label htmlFor='sms2'>동의</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='false' checked={selectedSMSReceiver === 'false'} onChange={handleRadioChange} />
                                <label htmlFor='sms3'>거부</label>
                            </div>
                        </div>
                        
                        <div className='search-field select'>
                            <div>
                                <b>이메일 수신</b>
                            </div>
                            <div>
                                <input type='radio' id='email1' className='mrbtn' name='group2' value='' checked={selectedEmailReceiver === ''} onChange={handleEmailRadioChange} />
                                <label htmlFor='email1'>전체</label>
                                <input type='radio' id='email2' className='mrbtn' name='group2' value='true' checked={selectedEmailReceiver === 'true'} onChange={handleEmailRadioChange} />
                                <label htmlFor='email2'>동의</label>
                                <input type='radio' id='email3' className='mrbtn' name='group2' value='false' checked={selectedEmailReceiver === 'false'} onChange={handleEmailRadioChange} />
                                <label htmlFor='email3'>거부</label>
                            </div>
                        </div>

                        <div className='search-field select'>
                            <div>
                                <b>성별</b>
                            </div>
                            <div>
                                <input type='radio' id='gender1' className='mrbtn' name='group3' value='' checked={selectedGender === ''} onChange={handleGenderRadioChange} />
                                <label htmlFor='gender1'>전체</label>
                                <input type='radio' id='gender2' className='mrbtn' name='group3' value='남' checked={selectedGender === '남'} onChange={handleGenderRadioChange} />
                                <label htmlFor='gender2'>남성</label>
                                <input type='radio' id='gender3' className='mrbtn' name='group3' value='여' checked={selectedGender === '여'} onChange={handleGenderRadioChange} />
                                <label htmlFor='gender3'>여성</label>
                            </div>
                        </div>

                        <div className='search-field range'>
                            <div>
                                <b>나이</b>
                            </div>
                            <div>
                                <span>만</span>
                                <input type="text" value={startAge} onChange={handleStartAgeChange} />
                                <span>세</span>
                                <span>~</span>
                                <input type="text" value={endAge} onChange={handleEndAgeChange} />
                                <span>세</span>
                            </div>
                        </div>

                        <div className='search-field range'>
                            <div>
                                <b>생일/기념일</b>
                            </div>
                            <div>
                                <input type="date" value={birthdayStartDate} onChange={handleBirthdayStartDateChange} />
                                <span>~</span>
                                <input type="date" value={birthdayEndDate} onChange={handleBirthdayEndDateChange} />
                            </div>
                        </div>
                        <div className='search-field select'>
                            <div>
                                <b>회원상태</b>
                            </div>
                            <div>
                                <input type='radio' id='memberStatus1' className='mrbtn' name='group4' value='' checked={selectedMemberStatus === ''} onChange={handleMemberStatusRadioChange} />
                                <label htmlFor='memberStatus1'>전체</label>
                                <input type='radio' id='memberStatus2' className='mrbtn' name='group4' value='정상' checked={selectedMemberStatus === '정상'} onChange={handleMemberStatusRadioChange} />
                                <label htmlFor='memberStatus2'>정상</label>
                                <input type='radio' id='memberStatus3' className='mrbtn' name='group4' value='탈퇴' checked={selectedMemberStatus === '탈퇴'} onChange={handleMemberStatusRadioChange} />
                                <label htmlFor='memberStatus3'>탈퇴</label>
                                <input type='radio' id='memberStatus4' className='mrbtn' name='group4' value='휴먼' checked={selectedMemberStatus === '휴먼'} onChange={handleMemberStatusRadioChange} />
                                <label htmlFor='memberStatus4'>휴먼</label>
                                <input type='radio' id='memberStatus5' className='mrbtn' name='group4' value='정지' checked={selectedMemberStatus === '정지'} onChange={handleMemberStatusRadioChange} />
                                <label htmlFor='memberStatus5'>정지</label>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                    <button className='more-btn' onClick={()=>{setToggle((e)=>!e)}}>                 
                        {
                            toggle
                            ? <i className='xi-angle-up'></i> 
                            : <i className='xi-angle-down'></i>
                        }         
                    </button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{usertotalCnt && usertotalCnt}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                            />
    
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />

                        </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn' onClick={()=>emailCheck()}>이메일 발송</button>
                    <button className='m-sm-btn' onClick={()=>setSmsModalShow(true)}>알림톡 발송</button>
                    <button className='m-sm-btn' onClick={()=>setPushModalShow(true)}>푸시 발송</button>
                    <button className='m-sm-btn'>포인트 지급</button>
                    <button className='m-sm-btn'>쿠폰 지급</button>
                    <button className='m-sm-btn' onClick={()=>{handleExcelDownload({ type: 'user' })}}>
                        다운로드
                    </button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                    <colgroup>
                        {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox"
                                        name="Allcheck"
                                        value=""
                                        onChange={handleSelectAll}
                                    
                                />
                            </th>
                            <th>번호</th>
                            <th>아이디</th>
                            <th>이름</th>
                            <th>성별</th>
                            <th>생년월일</th>
                            <th>이메일</th>
                            <th>휴대폰번호</th>
                            <th>주소</th>
                            <th>회원구분</th>
                            <th>회원상태</th>
                            <th>포인트</th>
                            <th>주문횟수</th>
                            <th>구매금액</th>
                            <th>가입일</th>
                            <th>마지막방문일</th>
                            <th>메시지 전송</th>
                        </tr>
                    </thead>
                      <tbody>
                      {searchResults?.data?.length > 0 ? (
                            searchResults.data.map((user, index) => (
                                <UserRow 
                                    key={user.id} 
                                    user={user} 
                                    index={index}
                                    onCheckboxChange={handleCheckboxChange}
                                    isChecked={selectedUsers.includes(user.id)}
                                />
                            ))
                        ) : (
                        searchConfirm && searchResults.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : (
                        fetchedData && fetchedData.data.length > 0 ? (
                            fetchedData.data.map((user, index) => (
                                <UserRow 
                                    key={user.id} 
                                    user={user} 
                                    index={index}
                                    onCheckboxChange={handleCheckboxChange}
                                    isChecked={selectedUsers.includes(user.id)}
                                />
                            ))
                        ) : (
                        data && data.data && data.data.length > 0 ? (
                            data.data.map((user, index) => (
                            <UserRow 
                                key={user.id} 
                                user={user} 
                                index={index}
                                onCheckboxChange={handleCheckboxChange}
                                isChecked={selectedUsers.includes(user.id)}
                            />
                            ))
                        ) : (
                        <tr>
                            <td colSpan="17">데이터가 없습니다.</td>
                        </tr>
                        )
                    )
                    )
                    )}

                    </tbody>
                </table>
                </div>
            </section>

          
            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                    ) :  fetchedData && fetchedData.totalCnt > 0 ? (
                        <Pagination
                            count={fetchedData.totalCnt}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    ) : (
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                )}
            </section>

        </div>
        <EmailModal
            show={emailModalShow}
            setShow={setEmailModalShow}
            mailData={mailData}
            disagreeMailData={disagreeMailData}
        />
        <SmsModal
            show={smsModalShow}
            setShow={setSmsModalShow}
            users={selectedUsers}
        />
        <PushModal
            show={pushModalShow}
            setShow={setPushModalShow}
            users={selectedUsers}
        />
        </>
    )
}

export default Management