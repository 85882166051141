/***************************************************************************************
 * FileName      : ToggleSwitch.js
* Description   : 해와관리자 > 셀러관리 > 매장조회 > 공개상태 토클버튼 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.05
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { updateVendorHiddenState } from '../../../../api/vendor/updateVendorData';
export default function ToggleSwitch({ pk, checked }) {
    const [isChecked, setIsChecked] = useState(checked);
  
    const handleToggle = async () => {
        try {
            const confirmationMessage = !isChecked
            ? "비공개 매장으로 전환하시겠어요?"
            : "공개 매장으로 전환하시겠어요?";

            if (window.confirm(confirmationMessage)) {
                setIsChecked(!isChecked);
                await updateVendorHiddenState(pk, !isChecked);
                window.location.reload();
            }else{
                setIsChecked(isChecked);
            }
        } catch (error) {
            console.error("Error updating vendor hidden state:", error);
        }
    }
  
    return (
      <>
        <label className={`toggle ${isChecked ? '' : 'checked'}`}>
          <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
          <span className={'toggle-slider'}></span>
        </label>
        <br/>
        {checked ? '비공개' : '공개'}
      </>
    );
  }