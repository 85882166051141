/***************************************************************************************
* FileName      : ReturnModal.js
* Description   : 해와관리자 > 주문배송 > 배송현황 > 반품 상태관리 모달 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { setOrderReturnState } from '../../../../../api/hwadmin/orderAPI';

function ReturnModal({ show, setShow, order, modalType }) {

    const modalMessages = {
        'approval': '반품 승인',
        'rejection': '반품 거절',
        'pickup': '회수 요청',
        'pickup_complete': '회수 완료',
        'refund_approval': '환불 승인',
        'refund_rejection': '환불 거절'
    };

    // 반품거절 > 거절사유
    const [rejectMessage, setRejectMessage] = useState(''); 

    // 반품승인 > 귀책사유
    const [deliveryCostBearer, setDeliveryCostBearer] = useState('판매자');
  
    
    // 환불 거절 > 거절사유
    const [refundRejectionMessage, setRefundRejectionMessage] = useState(''); 

    const handleRadioButtonChange = (event) => {
      setDeliveryCostBearer(event.target.value);
    };
  
    const handleActionClick = () => {
      let state=modalType;
      let rejectReason;
      switch (modalType) {
        case 'approval':
            rejectReason = deliveryCostBearer;
            break;
        case 'rejection':
            rejectReason = rejectMessage;
            break;
        case 'refund_rejection':
            rejectReason = refundRejectionMessage;
            break;

        default:
            break;
      }
  
      setOrderReturnState(order?.returns?.reception_number, state, rejectReason)
        .then(res => {
          console.log(res?.data);
          alert(res?.data.data);
        })
        .catch(error => alert(`처리 실패: ${error?.response?.data.message}`))
        .finally(() => window.location.reload());
    };
  
    return (
      <Modal size={'lg'} show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title id='sm-modal' className='fs-6'>
            {modalType && modalMessages[modalType]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fixTable_wrap my-2'>
            <table className='sTable' id='scrollTable'>
              <thead>
                <tr>
                  <th>주문번호</th>
                  <th>판매자</th>
                  <th>상품ID<br />상품명</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.order_number}</td>
                  <td>{order?.vendor_name}</td>
                  <td>{order?.product_id}<br />{order?.product_name}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {modalType === 'approval' && (
            <div className="mt-2">
              <p>귀책사유</p>
              <label>
                <input
                  type="radio"
                  value="판매자"
                  checked={deliveryCostBearer === '판매자'}
                  onChange={handleRadioButtonChange}
                />
                판매자
              </label>
              <label>
                <input
                  type="radio"
                  value="구매자"
                  checked={deliveryCostBearer === '구매자'}
                  onChange={handleRadioButtonChange}
                />
                구매자
              </label>
            </div>
          )}
  

        {modalType === 'rejection' && (
            <div className="mt-2">
                <p className='mt-10'>거절사유</p> 
                <input 
                    type='text' 
                    className='wsg1' 
                    placeholder='거절사유를 입력해주세요.' 
                    onChange={(e)=>setRejectMessage(e.target.value)}
                />
            </div>
        )}


        {modalType === 'refund_rejection' && (
            <div className="mt-2">
                <p className='mt-10'>환불 거절사유</p> 
                <input 
                    type='text' 
                    className='wsg1' 
                    placeholder='환불 거절사유를 입력해주세요.' 
                    onChange={(e)=>setRefundRejectionMessage(e.target.value)}
                />
            </div>
        )}



          <button
            className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold"
            onClick={handleActionClick}
          >

            {modalMessages[modalType]}
            
          </button>
        </Modal.Body>
      </Modal>
    );
  }
  
  export default ReturnModal;
  