/***************************************************************************************
* FileName      : SendingHistory.js
* Description   : 해와관리자 > 알림 > 푸시알림관리 > 발송내역 관리 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.27
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import PageSizeSelect from "../../common/PageSizeSelect"
import { useNotificationPushHistoryQuery , handleNotificationPushHistorySearch } from '../../../../api/hwadmin/notificationApi';
import { PushRow } from './SendingHistory/PushRow';

import Pagination from '../../common/Pagination';
import SearchPagination from '../../common/SearchPagination';

function SendingHistory()
{
    
    const [pageSize, setPageSize] = useState(10); 
    const { data, error, isFetching, page, setPage } = useNotificationPushHistoryQuery(pageSize);

    console.log(data);
    console.log("push---")
    const [searchMail, setSearchMail] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');


    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 옵션 선택
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
    }


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    
    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const onSearch = () => {

        handleNotificationPushHistorySearch({
            searchMail, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
        });
        
        setSearchConfirm(true);

    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/alarm/pushNotificationHistory'; 
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
    return (
        <>
        {/* 검색 */}
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field word'>
                    <div> 
                        <b>검색어</b> 
                    </div>
                    <div>
                        <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                            <option value="all" selected="selected">전체</option>
                            <option value="title">푸시제목</option>
                            <option value="body">푸시내용</option>

                        </select>
                        <input type="text"
                                value={searchMail}
                                onChange={(e) => setSearchMail(e.target.value)}/> 
                    </div>   
                </div>
                <div className='search-field date'>
                    <div> 
                        <b>날짜</b> 
                    </div>
                    <div>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />    
                    </div>   
                </div>
            </div>
           
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
        </section>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className='resultsCount-wrap'>
                    <span>검색결과</span>
                    <span className='result-wrap'>
                        {searchResults.totalCnt > 0 ? (
                            <>
                                <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                            </>
                            ) : (
                            <>
                                <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                            </>
                        )}
                    </span>
                </div>

                { !searchConfirm && 
                    <div className='selectWrap'>
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                }
            </div>
           

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>푸시제목</th>
                            <th>발송일시</th>
                            <th>푸시내용</th>
                            <th>발송률</th>
                            <th>수신확인율</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((push) => (
                                    <PushRow
                                        key={push?.id}
                                        push={push}
                                    />
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((push) => (
                                    <PushRow
                                        key={push?.id}
                                        push={push}
                                    />
                                ))
                            ) : null}    
                    </tbody>

                </table>


            </div>
        </section>
  
    
    {/* 페이지내비게이션 */}
    <section>
        {searchConfirm ? (
            <SearchPagination
                count={searchResults.totalCnt}
                url={searchUrl}
                pageSize={pageSize}
                updateSearchResults={updateSearchResults}
            />
                
        ) : (
            <Pagination
                count={data?.totalCnt || 0}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
            />
        )}
    </section>
    </>
    )
}

export default SendingHistory