/***************************************************************************************
* FileName      : penaltyApi.js
* Description   : 관리자페이지 패널티 API
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.24
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/

// 해와관리자 > 주문배송 api
import { instance } from '../../axiosInstance/axiosConfig';

// [hm] 패널티점수 조회
export const getPenaltyList = (pk) =>{
    return instance.get(`/penalty/?vendor=${pk}`)
}

// [hm] 패널티점수 지급
export const createPenalty = (pk, score, type, reason) => {
    return instance.post('/penalty/', {
        vendor: pk,
        score: score,
        type: type,
        reason: reason
    })
}

// [hm] 패널티점수 차감
export const removePenalty = (pk, score, reason) => {
    return instance.post('/penalty/', {
        vendor: pk,
        score: score,
        type: '수동차감',
        reason: reason
    })
}

// [hm] 패널티 제재 조회
export const getPenaltyActionList = (pk) =>{
    return instance.get(`/penalty/action/?vendor=${pk}`)
}

// [hm] 패널티 제재 생성
export const createPenaltyAction = (pk, action, reason) => {
    return instance.post('/penalty/action/', {
        vendor: pk,
        action: action,
        reason: reason
    })
}