import { useSelectedCategory , useCategoryUpdate , useCategoryDelete } from '../../../../api/Category';


import { useQueryClient } from 'react-query';
import { useQuery , useMutation } from 'react-query';
import { useState , useEffect } from 'react';

import { BASE_URL } from '../../../../api/apiConfig';
import MainCategories from './MainCategories';

export default function Edit()
{

    const queryClient = useQueryClient();

 
    //선택한 카테고리 조회 
    const { data: selectedCategory, isLoading, isError } = useSelectedCategory();

    // 카테고리 수정
    const categoryUpdateMutation = useCategoryUpdate();

    // 카테고리 삭제
    const deleteCategoryMutation = useCategoryDelete();
    
    // 카테고리 상태 관리
    const { data: selectedMaincategory } = useQuery('selectedMaincategory');
    const { data: selectedSubcategory } = useQuery('selectedSubcategory');
    const { data: selectedSubSubcategory } = useQuery('selectedSubSubcategory');
    const { data: selectedfilteringOptions } = useQuery('selectedfilteringOptions');
    const { data: selectedfiltering } = useQuery('selectedfiltering');
    
    // 선택한 카테고리 ID ,관리 
    const selectedId = queryClient.getQueryData('selectedId');
    const selectedName = queryClient.getQueryData('selectedName');
    const selectedHidden = queryClient.getQueryData('selectedHidden');


    const [name, setName] = useState('');
    const [hidden, setHidden] = useState('');

    const keyIdentifier = `${selectedId}-${selectedName}`;



    useEffect(() => {        
        if (selectedCategory) {
          setName(selectedName);
          setHidden(selectedHidden);
        }
    }, [selectedId,selectedName,selectedCategory]);
      
   

    function handleNameChange(e) {
        setName(e.target.value);
    }

    const handleHiddenChange = (event) => {
        const newValue = event.target.value === 'true';
        setHidden(newValue);
    };

    const handleDelete = async (event) => {
        event.preventDefault();
      
        // 삭제 로직 실행
        const userConfirmed = window.confirm("정말 해당 카테고리를 삭제하시겠습니까?");
    
        if (userConfirmed && selectedId) {
          // 삭제 로직 실행
          try {
            await deleteCategoryMutation.mutateAsync();
            
            // 삭제 성공 시 알림 띄우기
            alert("삭제되었습니다");
          } catch (error) {
            console.error(`카테고리 삭제 중 오류가 발생했습니다.`, error);
          }
     
        }
    };


    const handleUpdate =  async  (event) => {
        event.preventDefault(); // 기본 폼 제출 동작 방지
      
        const formData = new FormData(event.target); // 폼 데이터 가져오기
        const newData = Object.fromEntries(formData.entries()); // 폼 데이터를 객체로 변환
      
        try {
            await categoryUpdateMutation.mutateAsync(newData);
            alert("카테고리가 수정되었습니다.");
            
            queryClient.setQueryData('selectedName', name);
            queryClient.setQueryData('selectedHidden', hidden);

          } catch (error) {
            console.error("카테고리 수정 중 오류가 발생했습니다.", error);
          }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }
     
    if (isError) {
        return <div>Error</div>;
    }
   
  

    return (
        <>
        {/* 카테고리 편집 */}
        <form onSubmit={handleUpdate}>

            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className=''>
                        <span>카테고리 편집</span>  
                        <p className='selectCategoryText'>
                            {selectedMaincategory}
                            {selectedSubcategory ? ' > ' :''}
                            {selectedSubcategory}
                            {selectedSubSubcategory ? ' > ' :''}
                            {selectedSubSubcategory}
                            {selectedfilteringOptions ? ' > ' :''}
                            {selectedfilteringOptions}
                            {selectedfiltering ? ' > ' :''}
                            {selectedfiltering}
                        </p>
                    </div>
                </div>

                <section className=''>
                    <div className='Register-table-wrap' >
                        <table className='s1Table'>
                            <tbody>
                                <tr>
                                    <td>
                                        카테고리 이름
                                        <i className='essential'></i>
                                    </td>
                                    <td>
                                        <input type="text" 
                                                name="name" 
                                                placeholder="상호명"
                                                value={name}
                                                onChange={handleNameChange}
                                                key={keyIdentifier} // 고유한 식별자를 key로 사용
                                        />
                                    
                                    </td>
                                </tr>
                            
                                <tr>
                                    <td>
                                        상태
                                        <i className='essential'></i>
                                    </td>
                                    <td>
                                        <input 
                                            type='radio' 
                                            className='mrbtn' 
                                            id='g1' 
                                            name='hidden'
                                            value='true' 
                                            checked={hidden === true}
                                            onChange={handleHiddenChange}
                                        />
                                        <label htmlFor='g1'>비공개</label>

                                        <input 
                                            type='radio' 
                                            className='mrbtn'
                                            id='g2' 
                                            name='hidden'
                                            value='false' 
                                            checked={hidden === false}
                                            onChange={handleHiddenChange}
                                        />
                                        <label htmlFor='g2'>공개</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className='m-btn-wrap'>
                    <button type="button" className='m-btn m-btn-delete' onClick={handleDelete}>삭제</button>

                    <button type="submit" className='m-btn m-btn-search'>수정</button>
                </div>                               
            </section>
        </form>
    </>
    )
}

