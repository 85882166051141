import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    new Chart(myChartRef, {
      type: 'line',
      data: {
        // x축 레이블
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Apr', 'May', 'Jun'],
        datasets: [
          {
            label: 'Rainfall',
            // y축 데이터
            data: [65, 59, 80, 81, 56, 55],
            backgroundColor: 'rgba(0, 119, 204, 0.3)',
            borderColor: 'rgba(0, 119, 204, 0.3)',
            pointBackgroundColor: 'rgba(0, 119, 204, 0.3)',
            pointBorderColor: 'rgba(0, 119, 204, 0.3)',
            pointHoverBackgroundColor: 'rgba(0, 119, 204, 0.3)',
            pointHoverBorderColor: 'rgba(0, 119, 204, 0.3)',
          },
          {
            label: 'Sunshine',
            // y축 데이터
            data: [28, 48, 40, 19, 86, 27],
            backgroundColor: 'rgba(204, 0, 0, 0.3)',
            borderColor: 'rgba(204, 0, 0, 0.3)',
            pointBackgroundColor: 'rgba(204, 0, 0, 0.3)',
            pointBorderColor: 'rgba(204, 0, 0, 0.3)',
            pointHoverBackgroundColor: 'rgba(204, 0, 0, 0.3)',
            pointHoverBorderColor: 'rgba(204, 0, 0, 0.3)',
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }, []);

  return (
    <div>
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
};

export default LineChart;
