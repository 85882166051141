/***************************************************************************************
* FileName      : Usage.js
* Description   : 해와관리자 > 상품관리 > 상품등록 페이지 > 용도 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from 'react';

function Usage({ usages , setUsage }) {

  // 체크박스가 변경될 때 호출되는 함수
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // 변경된 상태를 업데이트
    if (checked) {
      // 체크가 되었을 때 배열에 추가
      setUsage((prevCheckedItems) => [...prevCheckedItems, name]);
    } else {
      // 체크가 해제되었을 때 배열에서 제거
      setUsage((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item !== name)
      );
    }
    console.log(usages)

  };

  // 체크박스 항목의 렌더링 함수
  const renderCheckbox = (label) => (
    <div className='product-attributes-item' key={label}>
      <label>
        <input
          type="checkbox"
          name={label}
          checked={usages[label]}
          onChange={handleCheckboxChange}
        />
        {label}
      </label>
    </div>
  );

  useEffect(()=>{
    console.log("usages:",usages);
  },[usages])
  return (
    <>
      <p className="notice-text">
            상품의 용도 정보가 부정확한 경우 관리자의 승인이 늦어질 수 있습니다.
      </p>
      <section className="ProductFilterWrap">
        <p className='product-attribute-text'>용도선택</p>
        <div className='product-attributes'>
          {["회", "구이", "볶음", "조림", "찜", "탕", "국", "찌개", "튀김", "부침", "무침", "기타"].map((category) =>
              renderCheckbox(category)
          )}
        </div>
     
        
      </section>
    </>
  );
}

export default Usage;
