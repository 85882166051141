import React, { useState } from 'react';
import { useEffect } from 'react';

import { useQueryClient } from 'react-query';
import { useMainCategories } from '../../../../../api/Category';
import SubCategories from './SubCategories';


function MainCategories({ middleIds }) 
{
    const queryClient = useQueryClient();
    const { isLoading, isError, data: mainCategoriesData } = useMainCategories();
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error occurred while fetching main categories.</div>;
    }

    function handleMainCategoryClick(id,name) 
    {
        setSelectedCategoryId(id);
        queryClient.setQueryData('selectedId', id);
        queryClient.setQueryData('selectedName', name);
    }


    return (
    <>
    <div className='categbox'>
      <p className='ctit'>대분류</p>
      <div type="대분류" className='kwutOX'>
         <ul>
            {mainCategoriesData && mainCategoriesData.data.map((category) => (
              <li className='fBSFej' key={category.id}>
                  <button 
                    onClick={() => handleMainCategoryClick(category.id , category.name)}
                    style={{ color: selectedCategoryId === category.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                    >{category.name}
                  </button>
              </li>
            ))}
        </ul> 
      </div>
    </div>

    {selectedCategoryId && (
        <SubCategories 
          categoryId={selectedCategoryId} 
          middleIds={middleIds}
          key={selectedCategoryId} />
    )}
   
   </>
  );
}

export default MainCategories;