import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidemenu from './Sidemenu.js'
import StatusBar from './StatusBar.js';
import { useMyCouponListQuery } from '../../api/services/mypageApi.js';
import CouponBlock from './Coupon/CouponBlock.js';
import Pagination from '../customer/common/Pagination.js';

function CouponLayout()
{
    return(
      <>
        <Container>
            <StatusBar/>
            <Row className='m-0'>
              <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'coupon'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <Coupon/>
                </Col>
              </div>
            </Row>
        </Container>
      </>
    )
  }

function Coupon()
{   
    let isUsed = false; // 사용한 쿠폰 보기 (isUsed -> true)로 변경하면 됨
    const { data:myCouponData, error, isFetching, page, setPage } = useMyCouponListQuery(isUsed);

    if (!myCouponData) {
        return <div>Loading...</div>;
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    console.log(myCouponData);
    console.log("=====")

    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-5 fw-bold">할인쿠폰 
                    <span className='fs-6 fw-bold text-hw'>
                     &nbsp;총 {myCouponData?.totalCnt}건
                    </span></h3>
                </Col>
            </Row>

            {/* 상태별 tab  */}
            {/* <MypageTabComponent tabs={tabs} /> */}
    
            <div className='mt-3 p-0'>
                <Row className='mycoupon_th'>
                    <Col className='mycoupon_tr'>쿠폰명</Col>
                    <Col className='mycoupon_tr'>혜택구분</Col>
                    <Col className='mycoupon_tr'>할인금액(율)</Col>
                    <Col className='mycoupon_tr'>사용조건</Col>
                    <Col className='mycoupon_tr'>유효기간</Col>
                </Row>

                {/* 쿠폰리스트 */}
                {myCouponData?.totalCnt === 0 ? (
                    <p className="text-center my-5">보유중인 쿠폰이 없습니다.</p>
                ) : (
                    
                    myCouponData?.data?.map((data, index) => (
                        <CouponBlock
                            data={data}
                            index={index}
                        />
                    ))
                )}

           
            </div>
            
            {/* 페이지내비게이션 */}
            <section className='mt-5'>
              <Pagination
                  count={myCouponData?.totalCnt || 0}
                  page={page}
                  setPage={setPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
              />
            </section>
                
        </div>
        </>
    )
}


export default CouponLayout