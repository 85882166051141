import { instance } from '../../axiosInstance/axiosConfig';


// export const getSellerProductData = (pk) =>{
//     return instance.get(`product/seller/${pk}`)
// }

export const getSellerProductData = async (id) => {
    try {
      const response = await instance.get(`product/seller/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
    }
};
  

  
// 정상가 변경
export const updateSellerProductOriginalPrice = (pk, original_price) => {
    return instance.patch(`product/update/direct/${pk}/`,
    {
        original_price: original_price
    })
}

// 판매가 변경
export const updateSellerProductPrice = (pk, price) => {
    return instance.patch(`product/update/direct/${pk}/`,
    {
        price: price
    })
}
// 재고 수량 변경
export const updateSellerProductStock = (pk, stock) => {
    return instance.patch(`product/update/direct/${pk}/`,
    {
        stock: stock
    })
}