/***************************************************************************************
* FileName      : MileageRow.js
* Description   : 고객 > 마이페이지 > 마일리지 사용 및 적립내역 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.03
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { extractYearMonthDayHourMinute2 } from "../Component/extractDateUtils"

function MileageRow({data})
{
    return (
        <>
        <tr>
            <td>
                <p>{extractYearMonthDayHourMinute2(data.created_at)}</p>
            </td>
            <td className="text-left">
                <p>
                    {data.detail}
                    {/* 마일리지 자동 소멸 <br/> 유효기간 만료 */}
                </p>
            </td>
            <td className='rp2'>
                <p> 
                    {data.amount} {data.type}<br/> 
                    잔액 {data.remain}
                </p>    
            </td>
        
        </tr>
        </>
    )
}

export default MileageRow