// cookieUtils.js

// 토큰 값을 쿠키에서 가져오는 함수
export const getAccessFromCookie = () => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'access') {
        return value;
      }
    }
    return null;
};
  

export const setCookie = (name, data) => {
  return document.cookie = `${name}=${data}; path=/;`
}