/***************************************************************************************
* FileName      : EditFaq.js
* Description   : 해와관리자 자주묻는질문 수정 페이지
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.01.01
* Modifide Date : 2024.01.17 : CHM : 에디터 컴포넌트 적용
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { updateFaq , getFaq } from '../../../../api/hwadmin/boardApi';
import { useForm } from 'react-hook-form';
import { useMutation , useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import Editor from '../../../components/Editor';

function EditFaq()
{
    const [description, setDescription] = useState('');

    const { register, handleSubmit, formState: { errors } , reset , watch , setValue } = useForm();

    const { id } = useParams();

    // 데이터 조회
    const { data } = useQuery(['faq',id], () => getFaq(id));


    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
            <div className="spinner" ></div>
            </div>
        );
    };

    useEffect(() => {
        if (data) {
            setValue('title', data.title);
            setValue('menu', data.menu);
            setValue('target', data.target);
            setDescription(data.content);
        }
    }, [data]);


    // 열기 닫기 토글
    const [toggle , setToggle] = useState(
        {
            Title:true,
            select:true,
            target:true,
            content :true, 
        }
    )
    
    const toggleState = (event) =>{
        const newTabState = {...toggle};
        const activeTab = event.currentTarget.id;

        setToggle((prevState) => ({
            ...prevState,
            [activeTab]: !newTabState[activeTab]
        }));
    }


    const updateFaqMutaion = useMutation((data) => updateFaq(id, data), {
        onSuccess : (data) => {
            reset();
            alert("수정 완료");
            setIsLoading(false);
            window.location.href = '/admin/board/faq';
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    });

    const onSubmit = (data) => {
        console.log(data);
        setIsLoading(true);

        const newData = {
            title: data.title || "",
            content: description || "",
            menu : data.menu || "",
            target : data.target || "",
        };

        updateFaqMutaion.mutate(newData);
    };

            
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>자주묻는질문 수정</h2>
            </div>
        
            {/* 등록 */}           


            <section className='product-register-wrap'>
                <div className='product-reguster-inner'>
                    <div className='accordion'>
                    <form onSubmit={handleSubmit(onSubmit)}>    
                    
                        <div className='accordion-item'>
                            <div className='title' id='target' onClick={toggleState}>
                                <p>대상<span>*</span></p>
                                <i className={toggle.target ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>

                            <div className={toggle.target ? 'content-show' : 'content'} >  
                                <select 
                                    className='mSelectBox' 
                                    name="noticeCategory"    
                                    {...register('target', { required: '대상 선택은 필수입니다.' })}>
                                    <option value="셀러" selected="selected">셀러</option>
                                    <option value="사용자">사용자</option>
                                </select>
                            </div>
                        </div>

                        <div className='accordion-item'>
                            <div className='title' id='select' onClick={toggleState}>
                                <p>분류<span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>

                            <div className={toggle.select ? 'content-show' : 'content'} >  
                                <select 
                                    className='mSelectBox' 
                                    name="noticeCategory"    
                                    {...register('menu', { required: '카테고리 선택은 필수입니다.' })}

                                    >
                                    <option value="매장관리" selected="selected">매장관리</option>
                                    <option value="상품등록">상품등록</option>
                                    <option value="상품관리">상품관리</option>
                                    <option value="주문배송">주문배송</option>
                                    <option value="주문배송">주문배송</option>

                                </select>
                            </div>
                        </div>

                        
                    
                            <div className='accordion-item'>
                                <div className='title' id='Title' onClick={toggleState}>
                                    <p>제목 <span>*</span></p>
                                    <i className={toggle.Title ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>

                                <div className={toggle.Title ? 'content-show' : 'content'} >  
                                    <input type="text" 
                                            name="title"
                                            className={`form-input ${errors.title ? 'error-input' : ''}`}
                                            placeholder='제목을 입력해 주세요'
                                            {...register('title', { required: '제목 입력은 필수입니다.' })} />

                                    {errors.title 
                                        && <p className='error_message ml-1'>
                                            {errors.title.message}
                                        </p>
                                    }
                                </div>
                            </div>
                            
                            <div className='accordion-item'>
                                <div className='title' id='content' onClick={toggleState}>
                                    <p>본문 <span>*</span></p>
                                    <i className={toggle.content ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>

                                
                                <div className={toggle.content ? 'content-show' : 'content'} >  
                                    <Editor 
                                        content={description}
                                        setContent={setDescription} />
                                </div>
                            </div>


                            <div className='m-btn-wrap'>
                                <button onClick={handleSubmit} type="submit" className='m-btn m-btn-search'>
                                    {isLoading ?  <LoadingSpinner /> : '수정완료'} 
                                </button>
                            </div> 

                        </form>
                        
                    </div>
                </div>
            </section>
   

           
        </div>
        </>
    )

        
}

export default EditFaq