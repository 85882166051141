/***************************************************************************************
* FileName      : StoreInquiry.js
* Description   : 해와관리자 > 셀러관리 > 매장조회 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useLocation , useNavigate } from 'react-router-dom';
import React, { useState , useEffect , useMemo } from 'react';
import { useVendorQuery , handleVendorSearch , fetchVendorName } from '../../../api/hwadmin/storeApi';
import { VendorRow } from "./StoreInquiry/VendorRow";
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'
import PageSizeSelect from '../common/PageSizeSelect';
import SortComponent from '../common/SortComponent2';
import handleExcelDownload from '../common/ExcelDownload';

function StoreInquiry()
{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentSearchQuery = searchParams.get('search');
    const [searchQuery, setSearchQuery] = useState(currentSearchQuery || '');

    const navigate = useNavigate();
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    // 상세검색 토글
    const [toggle , setToggle] = useState(false);
    const { data, error, isFetching, page, setPage } = useVendorQuery(ordering , pageSize);


    const [searchVendor, setSearchVendor] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('enter');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
    
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
    
    // 사업자구분
    const [selectedBusinessType, setSelectedBusinessType] = useState('');

    // 공개여부 선택
    const [publicity, setPublicity] = useState('');

    // 정지여부 선택 
    const [stopStatus, setStopStatus] = useState('');


    // 다른페이지에서 매장이름 검색 
    const [fetchedData, setFetchedData] = useState(null);

    // 검색중인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');
      

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
  
    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value));
    };

    // data 객체가 정의되어 있을 때만 totalPages를 계산
    const totalPages = useMemo(() => {
        if (data) return Math.ceil(data.totalCnt / data.count);
        return 0;
    }, [data]);

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleEditClick = () => {
        if(selectedRows.length>=2){
            alert("수정은 하나의 매장 정보만 가능합니다.")
        }else{
            navigate(`/admin/store/edit/${selectedRows[0]}`);
        }
    };

    useEffect(() => {
        if (searchQuery) {
            fetchVendorName(searchQuery)
              .then(data => {
                setFetchedData(data);
                console.log(fetchedData.data)
                console.log("-=ffff")
              })
              .catch(error => {
                // 오류 처리
                console.error(error);
              });
        }

        // 검색 쿼리 초기화
        setSearchQuery('');
    }, [searchQuery, data]);


    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleVendorSearch({
            searchVendor,
            setSearchResults,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedBusinessType,
            publicity,
            stopStatus,
            setSearchUrl,
        });
            
        setSearchConfirm(true);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    // 사업자구분 선택
    const handleBusinessTypeRadioChange = (e) => {
        setSelectedBusinessType(e.target.value);
    };

    // 공개여부 선택 
    const handlePublicityChange = (e) => {
        setPublicity(e.target.value);
    };
    
    // 정지여부 선택 
    const handleStopStatusChange = (e) => {
        setStopStatus(e.target.value);
    };
    
    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false)
        window.location.href = '/admin/store/inquiry/'; 
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
    if (isFetching || !data) {
        return <div>Loading...</div>;
    }
      
    if (error) console.log('error', error);
 

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>매장조회</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">매장이름</option>
                                <option value="owner">관리자ID</option>
                                <option value="phone">주소</option>
                                <option value="businessNumber">사업자등록번호</option>
                            </select>
                            <input type="text"
                                    value={searchVendor}
                                    onChange={(e) => setSearchVendor(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="enter">입점일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                    <div className='search-field division'>
                        <div> 
                            <b>사업자구분</b> 
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='g1' name='group1' value='' checked={selectedBusinessType  === ''} onChange={handleBusinessTypeRadioChange} />
                            <label htmlFor='g1'>전체</label>
                            <input type='radio' id='g2' name='group1' value='개인' checked={selectedBusinessType  === '개인'} onChange={handleBusinessTypeRadioChange} />
                            <label htmlFor='g2'>개인</label>
                            <input type='radio' id='g3' name='group1' value='법인' checked={selectedBusinessType  === '법인'} onChange={handleBusinessTypeRadioChange} />
                            <label htmlFor='g3'>법인</label>
                        </div>   
                    </div>
                
                    <div className={toggle ? 'Detailed-search-show' : 'Detailed-search'}>
                        <div className='search-field select'>
                            <div>
                                <b>면세여부</b>
                            </div>
                            <div>
                                <input type='radio' id='gender1' className='mrbtn' name='group3' value='' />
                                <label htmlFor='gender1'>전체</label>
                                <input type='radio' id='gender2' className='mrbtn' name='group3' value='남'/>
                                <label htmlFor='gender2'>개인</label>
                                <input type='radio' id='gender3' className='mrbtn' name='group3' value='여' />
                                <label htmlFor='gender3'>법인</label>
                            </div>
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>판매상품</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>품절상품</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>품절임박상품</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>매출</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>패널티</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                        <div className='search-field select'>
                            <div>
                                <b>공개여부</b>
                            </div>
                            <div>
                                <input type='radio' id='publicity-all' className='mrbtn' name='group3' value='' checked={publicity === ''} onChange={handlePublicityChange} />
                                <label htmlFor='publicity-all'>전체</label>
                                <input type='radio' id='publicity-public' className='mrbtn' name='group3' value='true' checked={publicity === 'true'} onChange={handlePublicityChange} />
                                <label htmlFor='publicity-public'>공개</label>
                                <input type='radio' id='publicity-private' className='mrbtn' name='group3' value='false' checked={publicity === 'false'} onChange={handlePublicityChange} />
                                <label htmlFor='publicity-private'>비공개</label>
                            </div>
                        </div>
                        <div className='search-field select'>
                            <div>
                                <b>정지여부</b>
                            </div>
                            <div>
                                <input type='radio' id='stop-status-all' className='mrbtn' name='group3' value='' checked={stopStatus === ''} onChange={handleStopStatusChange} />
                                <label htmlFor='stop-status-all'>전체</label>
                                <input type='radio' id='stop-status-normal' className='mrbtn' name='group3' value='남' checked={stopStatus === '남'} onChange={handleStopStatusChange} />
                                <label htmlFor='stop-status-normal'>정상</label>
                                <input type='radio' id='stop-status-stopped' className='mrbtn' name='group3' value='여' checked={stopStatus === '여'} onChange={handleStopStatusChange} />
                                <label htmlFor='stop-status-stopped'>정지</label>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
                    <button className='more-btn' onClick={()=>{setToggle((e)=>!e)}}>                 
                        {
                            toggle
                            ? <i className='xi-angle-up'></i> 
                            : <i className='xi-angle-down'></i>
                        }         
                    </button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="최근입점순"
                                oldLabel="오래된입점순"
                            />
    
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
                
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn' onClick={()=>{handleEditClick()}}>수정</button>
                    <button className='m-sm-btn'>패널티 부여</button>
                    <button className='m-sm-btn' onClick={()=>{handleExcelDownload({ type: 'vendor' })}}>
                        다운로드
                    </button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="sticky-col-1">
                                    <input type="checkbox" name="Allcheck" value=""/>
                                </th>
                                <th className="sticky-col-2">번호</th>
                                <th className="sticky-col-3">상호</th>
                                <th>관리자ID</th>
                                <th>구분</th>
                                <th>비공개</th>
                                <th>사업자등록증</th>
                                <th>통신판매신고증</th>
                                <th>사업자등록번호<br/>통신판매신고번호</th>
                                <th>사업장 주소</th>
                                <th>출고지</th>
                                <th>반품지</th>
                                <th>대표자 이미지</th>
                                <th>통장사본</th>
                                <th>
                                    은행 <br/>
                                    계좌번호
                                </th>
                                <th>매장전화<br/>
                                    팩스
                                </th>
                                <th>공개여부</th>
                                <th>과세여부</th>
                                <th>추가관리자ID</th>
                                <th>공동대표여부</th>
                                <th>승인대기 상품 수</th>
                                <th>승인완료 상품 수</th>
                                <th>승인거절 상품 수</th>
                                <th>판매대기 상품 수</th>
                                <th>판매중 상품 수</th>
                                <th>
                                    품절 상품 수 <br/>
                                    품절 임박 수
                                </th>
                                <th>판매중지 상품 수</th>
                                <th>판매종료 상품 수</th>
                                <th>
                                    누적 판매 금액 <br/>
                                    누적 판매 건 수
                                </th>
                                <th>
                                    패널티 점수
                                </th>
                                <th>
                                    입점 신청일<br/>
                                    입점 승인일
                                </th>
                                <th>마지막 방문일</th>
                                <th>관리자 메모</th>
                                <th>이메일/SMS</th>
                                <th>패널티 및 정지</th>
                                <th>정보수정</th>
                            </tr>
                        </thead>
                        <tbody>
                        {searchResults?.data?.length > 0 ? (
                                searchResults.data.map((vendor, index) => (
                                    <VendorRow
                                        vendor={vendor}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                />
                                ))
                            ) : (
                            searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="23">검색 결과가 없습니다.</td>
                                </tr>
                            ) : (
                            fetchedData && fetchedData.data.length > 0 ? (
                                fetchedData.data.map((vendor, index) => (
                                    <VendorRow
                                        vendor={vendor}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                ))
                            ) : (
                            data && data.data && data.data.length > 0 ? (
                                data.data.map((vendor, index) => (
                                    <VendorRow
                                        vendor={vendor}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                />
                                ))
                            ) : (
                            <tr>
                                <td colSpan="23">데이터가 없습니다.</td>
                            </tr>
                            )
                        )
                        )
                        )}
                        </tbody>

                 
                        </table>
                    </div>
                </section>
                
                {/* 페이지내비게이션 */}
                <section className='mt-3'>
                    {searchConfirm ? (
                        <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                        ) :  fetchedData && fetchedData.totalCnt > 0 ? (
                            <Pagination
                                count={fetchedData.totalCnt}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                            />
                        ) : (
                            <Pagination
                                count={data?.totalCnt || 0}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                            />
                    )}
                </section>
            </div>

        </>
    )
}

export default StoreInquiry