import { useState } from 'react';
import {Row, Col, Modal, Form} from 'react-bootstrap';
import { updateOderUserInfo } from '../../../api/orderPaymentsApi';
import useFormatPhoneNumber from '../../../hooks/useFormatPhoneNumber';

// 주문자 정보
export function OrderInfo({userInfo}){
    return(
        <div className='m-0 p-2 border'>
            <Row className='m-0 p-0 border-top border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>이름</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{userInfo.name}</Col>
            </Row>
            <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>휴대폰번호</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{useFormatPhoneNumber(userInfo.phone)}</Col>
            </Row>
            <Row className='m-0 p-0 border-bottom'>
                <Col xs={3} className='m-0 p-2 bg-grey text-start fs-7 fw-bolder'>이메일</Col>
                <Col xs={9} className='m-0 p-2 text-start fs-7'>{userInfo.email}</Col>
            </Row>
        </div>
    )
}

// 주문자 정보 수정 모달
export function UpdateOrderInfoModal({modalState, setModalState, userInfo, setUserInfo, getOrderDetailPk}){
    // 정규표현식
    const phoneRegex = /^01[0-9]\d{3,4}\d{4}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [name, setName] = useState(userInfo?.name);
    const [phone, setPhone] = useState(userInfo?.phone);
    const [email, setEmail] = useState(userInfo?.email);

    const pk = getOrderDetailPk();

    useState(()=>{
        console.log("d",name);
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!phoneRegex.test(phone)) {
            alert('잘못된 휴대폰번호입니다. \n (-)를 제외한 휴대폰 번호를 입력해주세요. (01012345678)');
            return;
        }else if(!emailRegex.test(email)) {
            alert('올바른 이메일 형식을 입력해주세요. (email@example.com)');
            return;
        }
        // 주문자 정보 업데이트 api 사용
        updateOderUserInfo(pk, name, phone, email)
            .then(res => {
                alert('주문자 정보가 변경되었습니다.');
                setUserInfo({
                    name: res.data.orderer_name,
                    phone: res.data.orderer_phone_number,
                    email: res.data.orderer_email
                })

            })
            .catch(error => alert(error.message))
        setModalState(false); // 모달 닫기
    }
    return(
        <>
            <Modal size={'md'} show={modalState} onHide={()=>setModalState(false)} aria-labelledby="sm-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title id='sm-modal' className='fs-6'>
                        정보를 입력해주세요.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="OrderInfoName">
                            <Form.Label>이름</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="주문자 이름을 입력해주세요." value={name} onChange={(e) => setName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="OrderInfoPhone">
                            <Form.Label>휴대폰번호</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="휴대폰번호를 입력해주세요." value={phone} onChange={(e) => setPhone(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="OrderInfoEmail">
                            <Form.Label>이메일</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="email@example.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
                        <div className='d-grid gap-2 mt-4'>
                            <button type='submit' className={name && phone && email ? 'btn btn-primary' : 'btn btn-secondary'}>적용</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}