import { Container, Col, Row } from 'react-bootstrap'; 
import { useNavigate } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';

function StatusBar({mobile}){
    const navigate = useNavigate();

    return(
        <>
            <div className={mobile ? "mypage-status pt-0 pb-1 m-0" : "mypage-status pt-0 pb-1 m-0 d-none d-sm-block"}>
                <Row className="m-0 p-2">
                    <Col xs={4} sm={12} md={3} className='p-1 d-none d-sm-block'>
                        <div className='m-0 bg-grey rounded-2 p-3 pointer'>
                            <p className='pt-2 pb-3'>안녕하세요</p>
                            <p><span className="fs-5 text-hw fw-bold">목포해와</span>입니다</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} className='p-1'>
                        <div className='bg-grey rounded-2 p-3 text-hw pointer' onClick={()=>navigate('/mypage/coupon')}>
                            <p className='fs-7 fw-bold mb-4'>할인쿠폰 <AiOutlineRight/></p>
                            <p className="fs-7 fw-bolder"><span className="fs-5 fw-bold">0</span>개</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} className='p-1'>
                        <div className='bg-grey rounded-2 p-3 text-hw pointer' onClick={()=>navigate('/mypage/mileage')}>
                            <p className='fs-7 fw-bold mb-4'>보유마일리지 <AiOutlineRight/></p>
                            <p className="fs-7 fw-bolder"><span className="fs-5 fw-bold">0</span>원</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} md={3} className='p-1'>
                        <div className='bg-grey rounded-2 p-3 text-hw pointer' onClick={()=>navigate('/mypage/order')}>
                            <p className='fs-7 fw-bold mb-4'>주문/배송 <AiOutlineRight/></p>
                            <p className="fs-7 fw-bolder"><span className="fs-5 fw-bold">0</span>건</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StatusBar