/***************************************************************************************
* FileName      : Faq.js
* Description   : 고객 > 고객센터 > 자주묻는질문 리스트 조회 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React from 'react';
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Routes, Route, Link , useNavigate, Outlet} from 'react-router-dom'
import Accordion from './FaqAccordion.js'
import data from './data.js'
import Sidemenu from './Sidemenu.js';
import TabComponent from '../mypage/Component/TabComponent.js';

import { AiOutlineDown } from 'react-icons/ai';
// import { useFaqListQuery , fetchFaqList2 } from '../../api/hwadmin/boardApi';
import { useFaqListQuery } from '../../api/services/boardApi.js';
import Pagination from './common/Pagination';
import SearchPagination from './common/SearchPagination.js'

function FaqLayout()
{
    const [selectTab, setTab] = useState('전체');
    const { data:questionData, error, isFetching, page, setPage } = useFaqListQuery(selectTab);

    const [expandedRows, setExpandedRows] = useState([]);
    
    if (!questionData) {
        return <div>Loading...</div>;
    }

    console.log('data', data);
    console.log(selectTab);

    let tabs = [ 
        {label: '전체'},
        {label: '매장관리'},
        {label: '상품등록'},
        {label: '상품관리'},
        {label: '주문배송'},
        {label: '정산'}
    ];

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    

    const toggleRow = (index) => {
        const newExpandedRows = [...expandedRows];
        if (newExpandedRows.includes(index)) {
          newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
        } else {
          newExpandedRows.push(index);
        }
        setExpandedRows(newExpandedRows);
    };

    return(
        <>
        <Container>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'faq'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                            <Row className='m-0 p-0'>
                                <Col className='m-0 p-0'>
                                    <h3 class="m-0 p-0 fs-4 fw-bold">무엇을 도와드릴까요?</h3>
                                </Col>
                            </Row>
                        
                            <p className='mt-3 mb-2 p-0 fs-secondary fs-6'>
                                목포해와를 이용하면서 자주하는 질문들입니다.
                            </p>

                            {tabs && (
                                <>
                                <TabComponent 
                                    tabs={tabs}
                                    setTab={setTab}
                                    className="m-0 p-0"
                                />

                            <div className='m-0 p-0'>
                                {questionData && questionData?.data?.map((question, index) => (
                                    <React.Fragment key={index}>
                                        <Row key={index} 
                                            className="border-top border-bottom bg-white m-0 py-2 pointer"
                                            onClick={() => toggleRow(index)}>
                                            <Col xs={10} className="d-flex align-items-center text-start p-3 fs-7 fw-bold">
                                                <span className='q-b'>Q</span>
                                                <span className="text-hw fw-bold">[{question.menu}]</span> {question.title}
                                            </Col>
                                            
                                        </Row>
                                        {expandedRows.includes(index)  && (
                                        <Row className="bg-grey m-0 p-3 ps-4 pe-4 fs-7 fw-bolder text-grey ">
                                            <div className='text-start text-dark fw-bold'>
                                                <span className='answer-b'>A</span>
                                            </div>
                                            <div className='faq-detail editor-content'>
                                                <div dangerouslySetInnerHTML={{ __html: question?.content }} />
                                            </div>
                                        </Row>

                                        )}
                                    </React.Fragment> 
                                ))}
                            </div>
                            </>
                              
                            )
                            }
                   
                        </div>

                        {/* 페이지내비게이션 */}
                        <section className='mt-5'>
                            <Pagination
                                count={questionData?.totalCnt || 0}
                                page={page}
                                setPage={setPage}
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                            />
                        </section>
                    </Col>
                    
                </div>
            </Row>
            
        </Container>
            
        </>
    )
}

export default FaqLayout