/***************************************************************************************
* FileName      : ToggleSwitch.js
* Description   : 해와관리자 > 알림 > 메일발송관리 스위치 토글 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { updateMailHidden } from '../../../../api/hwadmin/notificationApi';

export default function ToggleSwitch({itemKey, checked}) {
    const [isChecked, setIsChecked] = useState(!checked); // isChecked를 checked로 초기화
  
    const handleToggle = () => {
      setIsChecked(!isChecked);
      console.log("key : " + itemKey);  
      updateMailHidden(itemKey, isChecked); 
    }
  
    return (
      <>
        
     
        {itemKey === "signup" || itemKey === "findpw" ? 
        <> </>
        :
          <>
            <label className={`toggle ${isChecked ? 'checked' : ''}`}>
              <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
              <span className="toggle-slider round"></span>
            </label>
            <br/>
          </>
        }

        {isChecked ? '활성' : '비활성'}
      </>
    );
  }