/***************************************************************************************
* FileName      : SearchStats.js
* Description   : 해와관리자 > 통계 > 검색통계 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.21
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BarChart from "../../components/BarChart.js";

import { getStatisticSearch , handleSearchStatisticSearch } from "../../../api/hwadmin/statisticApi.js";


function SearchStats()
{

    const [isUpdated, setIsUpdated] = useState(false);
    
    const [searchResults, setSearchResults] = useState([]);

    const { isLoading, error, data } = useQuery(["StatisticSearch"], () => getStatisticSearch());

    const [chartData,setChartData] = useState([]);

    
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    

    console.log(data);

   

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/searchStats/';
    };
    
    // 검색버튼 클릭 시 
    const onSearch = async  () => {
        await handleSearchStatisticSearch({
            setSearchResults,
            selectedStartDate,
            selectedEndDate
        });
    };

    useEffect(() => {

        console.log(searchResults);
        console.log("searchResults-")
        
        if (searchResults && searchResults.keywords && searchResults.keywords.length > 0) {
  
            updateChartData(searchResults);
        } 
    },[searchResults]);



    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.keywords,
                datasets: [
                    {
                        label: '검색횟수',
                        data: data?.counts,
                        borderColor: '#F6B6C1',
                        backgroundColor: '#F6B6C1',
                    },
                
                ]
            };

            setChartData(newChartData);
        }
    },[data])

    // 검색 후 chart 생성
    function updateChartData(searchResults)
    {
        const newChartData = {
            labels: searchResults?.keywords,
            datasets: [
                {
                    label: '검색횟수',
                    data: searchResults?.counts,
                    borderColor: '#F6B6C1',
                    backgroundColor: '#F6B6C1',
                },
            
            ]
        };

        setChartData(newChartData);
        setIsUpdated(true);
    
    }

    const handleStartDateChange = (event) => {
        const startDate = event.target.value;
        setSelectedStartDate(startDate);

        // 선택된 날짜로부터 한 달 뒤의 날짜 계산
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);

        // 계산된 날짜를 selectedEndDate에 설정
        setSelectedEndDate(endDate.toISOString().substr(0, 10)); // ISO 형식의 문자열로 변환하여 설정
    };

    // selectedEndDate 변경 이벤트 처리
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    return (
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>검색통계</h2>
                <span className="nt">검색어별 검색 횟수만 조회 가능합니다.</span>
            </div>
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field date'>
                        <div> <b>날짜</b> </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            ~
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>
                    </div>            
                </div>

                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <BarChart data={chartData}/>
            </section>
        </div>
    
    </>
    )
}

export default SearchStats