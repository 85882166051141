/***************************************************************************************
* FileName      : AbortReturnView.js
* Description   : 해와관리자 > 주문배송 > 배송현황 > 반품 리스트 조회 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';

import { useOrderReturnListQuery , handleOrderReturnSearch , setOrderReturnState } from '../../../../api/hwadmin/orderAPI';
import OrderRow from './AbortReturnView/OrderRow';
import Pagination from '../../common/Pagination'
import SearchPagination from '../../common/SearchPagination'

import SortComponent from '../../common/SortComponent2';
import PageSizeSelect from '../../common/PageSizeSelect';

import { useApprovedVendors } from '../../../../api/hwadmin/productApi';

import RejectModal from './AbortReturnView/RejectModal';

function AbortReturnView({ selectedStatus })
{

    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 
    const [searchUrl, setSearchUrl] = useState('');

    const { data, error, isFetching, page, setPage } =  useOrderReturnListQuery(ordering,pageSize);
    
   
    console.log(data);
    
    // 수산상회 조회
    const { data: approvedVendors} = useApprovedVendors();

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });


    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
        
    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    // 선택한 상품 Row
    const [selectedRows, setSelectedRows] = useState({});
    // 선택한 상품 Row의 데이터값
    const [selectedRowsData, setSelectedRowsData] = useState({});
    
    // 상품 승인 컨펌창 상태
    const [approveProduct, setApproveProduct] = useState(false);
    const [rejectProduct, setRejectProduct] = useState(false);

    // 상태 체크박스
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const checkBoxList = ['전체', '반품요청', '반품수락', '반품거절', '반품완료', '환불완료', '환불거절'];


    useEffect(() => {
        // 체크박스들이 모두 선택되었는지 확인하여 전체 선택 상태 업데이트
        setIsAllChecked(checkedItems.length === checkBoxList.length);
        if(isAllChecked){
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((item) => item !== '전체')
          );
        }
    }, [checkedItems]);
    

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false); //상품거절모달
    const [productUpdateData, setProductUpdateData] = useState({});// 모달에서 수정한 값 담을 Object 생성
    // 상품 거절 컨펌이 true면 거절 사유 입력할 모달 창 출력
    useEffect(()=>{
        if(rejectProduct){
            setModalIsOpen(true);
        }
        setRejectProduct(false);
    },[rejectProduct])

    const onSearch = () => {
       
        handleOrderReturnSearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate, 
            selectedEndDate,
            selectedStatus,
            checkedItems,
            selectedVendor

        });
        
        setSearchConfirm(true);

    };
    

    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체') {
            if (checked) {
                setCheckedItems(checkBoxList);
            } else {
                setCheckedItems([]);
            }
        } else {
            if (checked) {
                setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
            } else {
                setCheckedItems((prevCheckedItems) =>
                    prevCheckedItems.filter((item) => item !== id)
                );
            }
        }
    };
          
    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/order/cancel'; 
    };
    
    // 선택한 수산상회 저장
    const handleSelectChange = (event) => {
        const selectedOption = approvedVendors.data.find(
              (vendor) => vendor.name === event.target.value
        );
        setSelectedVendor({ id: selectedOption.id, name: selectedOption.name });
    };
         
    useEffect(() => {
        // approvedVendors.data가 존재하고 첫 번째 데이터가 있는지 확인
        if (approvedVendors && approvedVendors.data.length > 0) {
          const firstVendor = approvedVendors.data[0];
          setSelectedVendor({ id: firstVendor.id }); // 첫 번째 데이터의 id를 선택된 값으로 설정
        }
    }, [approvedVendors]);

    const postRejectProduct = () =>{
        // 선택된 체크박스 id 값
        let selectList = Object.keys(selectedRows).filter(key => selectedRows[key] === true)
        
        // 상품 거절 요청할 API 묶음
        const rejectPromises = selectList.map(id => setOrderReturnState(id, "reject",
                                                productUpdateData[id].rejectValue));
        Promise.all(rejectPromises)
            .then(results => {
                results.forEach(res => console.log(res.data));
                alert('상품 등록이 거절되었습니다.');
            })
            .catch(error => alert(error?.response?.data?.product))
            .finally(()=>window.location.reload())
    }


    return (
        <>
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field word'>
                    <div> 
                        <b>검색어</b> 
                    </div>
                    <div>
                    <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                            <option value="all" selected="selected">전체</option>
                            <option value="OrderNumber">주문번호</option>
                            <option value="ProductName">상품명</option>
                            <option value="ProductNumber">상품번호</option>
                    </select>

                    <input type="text"
                            value={searchOrder}
                            onChange={(e) => setSearchOrder(e.target.value)}/> 

                    </div>   
                </div>
                <div className='search-field date'>
                    <div> 
                        <b>날짜</b> 
                    </div>
                    <div>
                        <select value={selectedDateType} onChange={handleDateTypeChange}>
                            <option value="reception_date__range">접수일</option>
                            <option value="return_request_at__range">회수요청일</option>
                            <option value="return_complete_at__range">회수완료일</option>
                            <option value="completed_at__range">처리완료일</option>
                        </select>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                    </div>   
                </div>
                
                <div className='search-field word'>
                    <div> 
                        <b>수산상회</b> 
                    </div>
                    <div>
                        <select className='mSelectBox' name="vendor" value={selectedVendor.name} onChange={handleSelectChange}>
                            <option value="">전체</option>
                            {approvedVendors && approvedVendors.data.map(vendor => (
                                <option key={vendor.id} value={vendor.name}>{vendor.name}</option>
                            ))}
                        </select>  
                    </div>   
                </div>

                <div className='search-field checkbox-wrap mt-4'>
                    <div> 
                        <b>요청상태</b> 
                    </div>
                    <div className='CheckboxWrap2'>
                        {checkBoxList.map((item, idx) => (
                            <p className='checkbox' key={idx}>
                                <input
                                    type='checkbox'
                                    id={item}
                                    checked={checkedItems.includes(item)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={item}>{item}</label>
                            </p>
                        ))}
                    </div>
                                                
                </div>

            </div>
        
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}} >검색</button>
            </div>
        </section>    


        <section className='McontentBox'>
            <div className='subTitle'>
                <span className='resultsCount-wrap'>
                    <span>검색결과</span>
                    {searchResults.totalCnt > 0 ? (
                        <>
                            <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                        </>
                        ) : (
                        <>
                            <p>(총 <b className='sum-s-b'>{data?.totalCnt && data?.totalCnt}</b>개)</p>
                        </>
                    )}
                </span>
                { !searchConfirm && 
                <div className='selectWrap'>
                    <SortComponent
                        ordering={ordering}
                        handleOrderingChange={handleOrderingChange}
                        newLabel="신규주문순"
                        oldLabel="오래된주문순"
                        />
 
                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
            
                </div>
                }
            </div>
                
            <div className='m-btn-wrap-sm'>
                <button className='m-sm-btn'>이메일 발송</button>
                <button className='m-sm-btn'>알림톡 발송</button>
                {/* <button className='m-sm-btn' onClick={()=> setApproveProduct(true)}>반품수락</button>
                <button className='m-sm-btn' onClick={()=> setRejectProduct(true)}>반품거절</button>
                <button className='m-sm-btn'>반품완료</button> */}

                <button className='m-sm-btn'>다운로드</button>

            </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>주문번호</th>
                                <th>판매자</th>
                                <th>요청구분</th>
                                <th>요청상태</th>
                                <th>승인여부</th>
                                <th>
                                    구매자 ID<br/>
                                    구매자 이름
                                </th>
                                <th>
                                    수취인 이름<br/>
                                    수취인 연락처
                                </th>
                                <th>접수일시</th>
                                <th>접수번호</th>
                                <th>
                                    상품번호<br/>
                                    상품명
                                </th>
                                <th>옵션정보</th>
                                <th>반품/취소 사유</th>
                                <th>배송비 부담자</th>
                                <th>주문금액</th>
                                <th>
                                    반품 승인/거절일시<br/>
                                    환불 승인/거절일시
                                </th>
                                <th>에스크로 거래 여부</th>
                                <th>회수요청시간</th>
                                <th>회수완료시간</th>
                                <th>택배사</th>
                                <th>송장번호</th>
                                <th>거절사유</th>
                                <th>처리담당자</th>
                                <th>처리완료일시</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ): searchResults.count > 0 ? (
                                searchResults?.data.map((order, index ) => (
                                    <OrderRow 
                                        key={order.id} 
                                        order={order} 
                                        index={index} 
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        selectedRowsData={selectedRowsData}
                                        setSelectedRowsData={setSelectedRowsData}
                                        setApproveProduct={setApproveProduct}
                                        />
                                ))
                            ) : data && data.data && data.data.length > 0 ? (
                                data.data.map((order, index ) => (
                                    <OrderRow 
                                        key={order.id} 
                                        order={order} 
                                        index={index} 
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        selectedRowsData={selectedRowsData}
                                        setSelectedRowsData={setSelectedRowsData}
                                        setApproveProduct={setApproveProduct}
                                    />
                                ))
                            ) : null}
                        </tbody>                    
                        </table>
                    </div>
                </section>
                
              

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
    
           {/*  거절 모달 */}
           {/* {modalIsOpen ? 
                <RejectModal 
                    show={modalIsOpen} 
                    onHide={setModalIsOpen} 
                    selectedRows={selectedRows} 
                    selectedRowsData={selectedRowsData}
                    setProductUpdateData={setProductUpdateData} 
                    postRejectProduct={postRejectProduct}/> : null} */}


    </>
    )
}

export default AbortReturnView