import { useState , useRef ,useEffect } from 'react'; 

import { useCreatePost , sendAdminNameToApi , verifyEmployeeId } from '../../../api/hwadmin/storeApi.js';
import axios from 'axios';

// 주소 모달
import AddressModal from "./AddressModal.js"

// aws 이미지등록
import { getUploadURL , uploadImage } from '../../../api/uploadImage/uploadImageApi.js';
// import { uploadImage } from '../../../api/uploadImage/uploadApi';
import { NavItem } from 'react-bootstrap';

// 셀러관리 > 매장등록

function Registration()
{
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
          <div className="parent-container">
            <div className="spinner" ></div>
          </div>
        );
    };
      

    //상호
    const [name, setName] = useState('')
    const [isNameError, setIsNameError] = useState(false);
    const inputNameRef = useRef(null);

    //관리자ID
    const [owner, setOwner] = useState('')
    const [isOwnerEmpty, setIsOwnerEmpty] = useState(false);
  
    //관리자이름
    const [manager_name, setManagerName] = useState('');
    const [isManagerNameEmpty, setIsManagerNameEmpty] = useState(false);

    
    const [checkAdmin, setCheckAdmin] = useState(false);


     //구분
    const [business_type, setBusinesstype] = useState('개인');

    //사업자등록번호
    const [business_number, setBusinessnumber] = useState('');
    const [isBNumberError, setIsBNumberError] = useState(false);
    const inputBNumberRef = useRef(null);

    //통신판매등록번호 
    const [sales_registration_number , setSalesRegistrationNumber] = useState('');

    //사업장주소
    const [address, setAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });


    // 출고지주소
    const [dispatchLocation, setDispatchLocation] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

 
    // 반품지 주소
    const [returnAddress, setrReturnAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });



    const [isAdressError, setIsAdressError] = useState(false);
    const inputAdressRef = useRef(null);

    // 사업장주소 변경 모달
    const [modalIsOpen, setModalIsOpen] = useState(false); //주소입력모달

    // 출고지 주소 변경 모달
    const [dispatchModalIsOpen, setDispatchModalIsOpen] = useState(false); 

    // 반품지 주소 변경 모달
    const [returnModalIsOpen, setReturnModalIsOpen] = useState(false); 

    const onCompletePost = (data) => {
        console.table(data);
        setAddress({
            address: data.address,
            detail: "",
            postal_code: data.zonecode,  // 우편번호
            address_type: data.addressType === "R" ? "도로명" : "지번",
        });

        setModalIsOpen(false);
       
    };

    // useEffect(()=>{
    //     console.log("반품지 주소----");
    //     console.log(returnAddress);
    //     console.log("----");
    // },[returnAddress])


    // 출고지 
    const handleDispatchComplete = (data) => {
        setDispatchLocation({
          address: data.address,
          detail: "",
          postal_code: data.zonecode, // 우편번호
          address_type: data.addressType === "R" ? "도로명" : "지번",
        });
      
        setDispatchModalIsOpen(false);
    };

    // 반품지
    const handleReturnComplete = (data) => {    
        console.log("---반품----")
        console.table(data)
        setrReturnAddress({
            address: data.address,
            detail: "",
            postal_code: data.zonecode, // 우편번호
            address_type: data.addressType === "R" ? "도로명" : "지번",
        });
    
        setReturnModalIsOpen(false);
    };



    //은행 
    const [bank, setBank] = useState('산업');

    //계좌번호 
    const [account_number, setAccountNumber] = useState('');

    //예금주
    const [account_holder, setAccountHolder] = useState('');


    //공동대표여부
    const [ceo, setCeo] = useState('false');

    //과세여부
    const [tax, setTax] = useState('과세')


    //이미지 파일들 저장
    const [files, setFiles] = useState({
        business_license: null,
        owner_image: null,
        account_image: null,
        sales_registration: null
    });

    // 이미지 url 
    const [uploadedImages, setUploadedImages] = useState({
        business_license: null,
        owner_image: null,
        account_image: null,
        sales_registration:null
    });

    const handleFileChange = (event, fileKey) => {
        const newFiles = {...files};
        newFiles[fileKey] = event.target.files[0];
        setFiles(newFiles);
    }

    // 추가정보
    const [phone_number, setPhonenumber] = useState('01025874587')
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('dd@naver.com')
    const [fax, setFax] = useState('');


    //직원추가(선택)
    const [input_employeeValue, setInputEmployeeValue] = useState('')
    const [employee_list, setEmployeelist] = useState([])
    const [employeeId_list, setEmployeeIdlist] = useState([])


    //구분 
    const handleDivisionChange = (event) => {
        setBusinesstype(event.target.value);
    };
    
    // 은행 선택 
    const handleBankChange = (event) => {
        setBank(event.target.value);
    };
    
    //공동대표여부
    const handleCeoChange = (event) => {
        setCeo(event.target.value);
    };

    //과세여부
    const handleTaxChange = (event) => {
        setTax(event.target.value);
    };
    
 
    const { post, isLoading: isPostLoading, error } = useCreatePost();


    // 직원 리스트 추가
    const handleEmployeeSubmit = (event) => {
        event.preventDefault();
        // 직원 아이디 확인
    
        const employeeId = input_employeeValue;

        verifyEmployeeId(employeeId)
        .then((checkEmployee) => {
            if(checkEmployee.data === "확인완료"){
                setEmployeeIdlist([...employeeId_list, checkEmployee.id]);      
                setEmployeelist([...employee_list, input_employeeValue]);           
            }   
        })
        .catch((error) => {
            alert(error.response.data.data)
            console.log(error);
        });
        
        setInputEmployeeValue("");
    };

    const handleEKeyDown = (event) => {
        if (event.key === "Enter") {
            alert("엔터")
        }
    };

    // 직원 리스트 삭제
    const handleEmployeeDelete = (index) => {
        const newListItems = [...employee_list];
        newListItems.splice(index, 1);
        setEmployeelist(newListItems);
    };

    // 상호명 입력 변화 확인
    const handleInputNameChange = (e) => {
        setName(e.target.value);
        setIsNameError(false);
    };

    // 사업자등록번호 변화 확인
    const handleInputBNumberChange = (e) => {
        let inputValue = e.target.value;

        // 입력값에서 "-"를 제거
        inputValue = inputValue.replace(/-/g, '');

        setBusinessnumber(inputValue);
        setIsBNumberError(false);
    };

    // 통신판매등록번호 변화 확인
    const handleInputSNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');

        setSalesRegistrationNumber(inputValue);
    }

    // 계좌번호 변화 확인
    const handleInputAccountNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');
        
        setAccountNumber(inputValue);
    }

    // 매장전화 변화 확인
    const handleInputTelNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');

        setTel(inputValue);
    }

    
    // 팩스번호 변화 확인
    const handleInputFaxNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');

        setFax(inputValue);
    }


    // 사업장주소 상세 주소 
    const handleInputDetailChange = (e) => {
        const newDetail = e.target.value;

        setAddress((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

    // 출고지 상세주소 입력
    const handleInputDispatchDetailChange = (e) => {
        const newDetail = e.target.value;

        setDispatchLocation((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

    // 반품지 상세주소 입력
    const handleInputReturnDetailChange = (e) => {
        const newDetail = e.target.value;

        setrReturnAddress((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };


    const validateField = (value, setIsError, inputRef) => {
        if (value.trim() === "") {
          setIsError(true);
          inputRef.current.focus();
          inputRef.current.scrollIntoView({ behavior: "smooth" });
          return false;
        }
        return true;
    };

    // 유효성 검사
    const validateForm = () => {
        let isValid = true;

        // 1. 상호명 검증
        const isNameValid = validateField(name, setIsNameError, inputNameRef);

        // 2. 관리자 ID 이름 확인
        if(checkAdmin === false){
            isValid = false;
            alert("관리자 ID , 이름 확인바랍니다.")
            return;
        }

        // 3. 구분

        // 4. 사업자등록증 이미지 검증
        if(files.business_license === null){
            isValid = false;
            alert("사업자등록증 이미지는 필수사항입니다.")
            return ;
        }
        
        // 5. 사업자 등록번호 검증
        const isBNumberValid = validateField(business_number, setIsBNumberError, inputBNumberRef);

        // 6. 통신판매신고증 이미지 검증
        if(files.sales_registration === null){
            isValid = false;
           
            alert("통신판매신고증 이미지는 필수사항입니다.")
            return ;
        }
        
        // 7. 통신판매등록번호 검증
        if(sales_registration_number === ''){
            isValid = false;
            alert("통신판매등록번호는 필수사항입니다.")
            return ;
        }

        // 8. 사업장 주소 검증
        if(address.address === '' || address.postal_code === '' || address.address_type === '' ){
            isValid = false;
            alert("사업장 주소를 확인해주세요.")
            return ;
        }

        // 9. 대표자 이미지 검증
        if(files.owner_image === null){
            isValid = false;
            alert("대표자 사진 이미지는 필수사항입니다.")
            return ;
        }
   
        // 10. 통장사본 이미지 검증
        if(files.account_image === null){
            isValid = false;
            alert("통장사본 이미지는 필수사항입니다.")
            return ;
        }

        // 11-1. 계좌번호
        if(account_number === '') {
            isValid = false;
            alert("계좌번호 입력은 필수사항입니다.")
            return ;
        }

        // 11-2. 예금주 입력 확인
        if(account_number === '') {
            isValid = false;
            alert("예금주 입력은 필수사항입니다.")
            return ;   
        }

        // 12. 추가정보 > 매장정보 > 매장전화
        if(tel === '')
        {
            isValid = false;
            alert("매장 전화 입력은 필수사항입니다.")
            return ;    
        }
     
        // 13. 출고지 주소 확인
        if(dispatchLocation.address === '' || dispatchLocation.postal_code === '' || dispatchLocation.address_type === '' ){
            isValid = false;
            alert("출고지 주소를 확인해주세요.")
            return ;
        }

        // 13. 반품지 주소 확인
        if(returnAddress.address === '' || returnAddress.postal_code === '' || returnAddress.address_type === '' ){
            isValid = false;
            alert("반품지 주소를 확인해주세요.")
            return ;
        }
            
        isValid = isNameValid && isBNumberValid ;
        return isValid;
    }


    // 이미지 파일 업로드
    const uploadImages = async () => {
        try {
            const businessLicenseURL = await getUploadURL(files.business_license);
            if (businessLicenseURL) {
                const imageURL = await uploadImage(businessLicenseURL);
                uploadedImages.business_license = imageURL;
            }
      
            const ownerImageURL = await getUploadURL(files.owner_image); 
            if (ownerImageURL) {
                const imageURL = await uploadImage(ownerImageURL);
                uploadedImages.owner_image = imageURL;
            }

            const accountImageURL = await getUploadURL(files.account_image);
            if (accountImageURL) {
                const imageURL = await uploadImage(accountImageURL);
                uploadedImages.account_image = imageURL;
            }

            const salesRegistrationImageURL = await getUploadURL(files.sales_registration);
            if (salesRegistrationImageURL) {
                const imageURL = await uploadImage(salesRegistrationImageURL);
                uploadedImages.sales_registration = imageURL;
            }

            console.log("이미지 업로드 완료")
        } catch (error) {
          console.error("이미지 업로드 에러:", error);
        }
    };

    const handleSubmit = (event) => {
        if (validateForm()) {
            setIsLoading(true); // 로딩 상태를 활성화
            event.preventDefault();
            uploadImages()
                .then(() => {
                    const data = {
                        name: name, // 상호
                        owner: owner, // 관리자 ID
                        business_type: business_type, // 구분
                        business_license: uploadedImages.business_license, // 사업자등록증
                        business_number: business_number || "", // 사업등록번호
                        sales_registration : uploadedImages.sales_registration || "", //통신판매신고증
                        sales_registration_number : sales_registration_number  || "", // 통신판매등록번호
         
                        address: address, // 사업장 주소
                        owner_image: uploadedImages.owner_image, // 대표자사진
                        account_image: uploadedImages.account_image, // 통장사본
                        bank: bank || "", // 은행
                        account_number: account_number || "", // 계좌번호
                        account_holder: account_holder || "",  // 예금주
                        co_representative: ceo === "true",// 공동대표여부
                        tax: tax || "", // 과세여부
                        tel: tel || "", // 매장번호
                        fax: fax || "", // 팩스번호(선택)
                        employee_list: employeeId_list || "", // 직원 리스트 
                        dispatch_location : dispatchLocation || "", // 출고지 주소
                        return_address : returnAddress || "", // 반품지 주소
                    };

                    console.log("데이터");
                    console.log(data);

                    console.log("uploadedImages")
                    console.log(uploadedImages)
                    post(data)
                    .then(() => {
                        alert("매장 등록 성공적으로 완료하였습니다");
                        console.log("등록 성공!");
                        window.location.href = '/admin/store/request'; // 페이지 리로드
                        // 매장 조회 페이지로 이동 

                        setIsLoading(false); // 로딩 상태를 비활성화
                    })
                    .catch(error => {
                        console.log("등록 실패:", error.message);
                        setIsLoading(false); // 로딩 상태를 비활성화
                    });
                })
                .catch(error => {
                    console.error("이미지 업로드 에러:", error);
                });

        }
            
        
    };


    // 관리자 ID 유무 확인
    const checkAdminId = () => {
        // 입력 확인
        if (owner === '') {
            setIsOwnerEmpty(true);
          } else if (manager_name === '') {
            setIsManagerNameEmpty(true);
          } else {
            // 관리자 아이디 , 이름 전달
            const adminName = manager_name;
            const adminId = owner;

            console.log(adminName,adminId )

            sendAdminNameToApi(adminName, adminId)
            .then((checkAdmin) => {
                console.log(checkAdmin);
                
                if(checkAdmin.data === "확인완료"){
                    setCheckAdmin(true);
                }
            })
            .catch((error) => {
                alert(error.response.data.data)
            
            });
        }
        
    }; 

    // 관리자ID 이름 입력
    const handleOwnerInputChange = (e) => {
        setOwner(e.target.value);
        setIsOwnerEmpty(false);
    };

    const handleManagerNameInputChange = (e) => {
        setManagerName(e.target.value);
        setIsManagerNameEmpty(false);
    };
    return(
        <>
        <div className='mainContents'>
            {/* 매장등록 */}
            <div className='page-tit'>
                <h2>매장등록</h2>
            </div>

            {/* 기본정보 입력란 */}
            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>기본정보</h3>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    상호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text" 
                                        placeholder="상호명"
                                        value={name}
                                        onChange={handleInputNameChange}
                                        ref={inputNameRef}
                                        style={{ borderColor: isNameError ? "red" : "" }} 
                                    />
                                    {isNameError && <p className="InputError">매장 이름을 입력해주세요.</p>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    관리자ID / 이름
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <div> 
                                        <input type="text" 
                                            placeholder="관리자ID"
                                            className={isOwnerEmpty ? 'mr10 empty-input' : 'mr10'}
                                            value={owner}
                                            onChange={handleOwnerInputChange}
                                            disabled={checkAdmin === true }
                                        />
                                        <input type="text" 
                                            placeholder="이름"
                                            className={isManagerNameEmpty ? 'ml7 empty-input' : 'ml7'}
                                            value={manager_name}
                                            disabled={checkAdmin === true}
                                            onChange={handleManagerNameInputChange}
                                        />
                                        <button className='r-btn ml7' onClick={checkAdminId}>확인</button>
                                    </div>
                                    <div>
                                        {checkAdmin && <p className="confirmed">확인완료</p>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    구분
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input 
                                        type='radio' 
                                        className='mrbtn' 
                                        id='g1' 
                                        name='business_type'
                                        onChange={handleDivisionChange} 
                                        checked={business_type === '개인'}
                                        value='개인' 
                                    />
                                    <label htmlFor="g1">개인사업자</label>

                                    <input 
                                        type='radio' 
                                        className='mrbtn'
                                        id='g2' 
                                        name='business_type'
                                        checked={business_type === '법인'}
                                        onChange={handleDivisionChange} 
                                        value='법인' 
                                    />
                                    <label htmlFor="g2" >법인사업자</label>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    사업자등록증
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex_file">파일선택</label>
                                    <input type="file" 
                                        id="ex_file" 
                                        onChange={(event) => handleFileChange(event, 'business_license')} 
                                    /> 
                                    {files.business_license && <p className='filename'>{files.business_license.name}</p>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    사업자등록번호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="사업자등록번호"
                                        value={business_number}
                                        ref={inputBNumberRef}
                                        style={{ borderColor: isBNumberError  ? "red" : "" }}
                                        onChange={handleInputBNumberChange}
                                    />
                                    {isBNumberError && <p className="InputError">사업자등록번호를 입력해주세요.</p>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통신판매신고증
                                    <i className='essential'></i>
                                </td>

                                <td className='filebox'>                            
                                    <label for="ex3_file">파일선택</label>
                                    <input type="file" 
                                        id="ex3_file" 
                                        onChange={(event) => handleFileChange(event, 'sales_registration')} 
                                    /> 
                                    {files.sales_registration && <p className='filename'>{files.sales_registration.name}</p>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통신판매등록번호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="통신판매등록번호"
                                        value={sales_registration_number}
              
                                        onChange={handleInputSNumberChange}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    사업장 주소{isAdressError}
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={address.postal_code}
                                                disabled 
                                            />  
                                            <button className="ms-2" 
                                                    onClick={()=>setModalIsOpen(true)}>
                                                        주소 검색
                                            </button> 
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="사업장 주소"
                                                value={address.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="상세주소"
                                            value={address.detail}
                                            onChange={handleInputDetailChange}
                                            />
                                        </li>
                                    </ul>      
                                </td>
                             </tr>
                            <tr>
                                <td>
                                    대표자 사진
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex1_file">파일선택</label>
                                    <input type="file" 
                                        id="ex1_file" 
                                        onChange={(event) => handleFileChange(event, 'owner_image')} 
                                    /> 
                                    {files.owner_image && <p className='filename'>{files.owner_image.name}</p>}
 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통장사본
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex2_file">파일선택</label>
                                    <input type="file" 
                                        id="ex2_file" 
                                        onChange={(event) => handleFileChange(event, 'account_image')} 
                                    /> 
                                    {files.account_image && <p className='filename'>{files.account_image.name}</p>}

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    은행/계좌번호/예금주
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <select className='mSelectBox'
                                            value={bank}
                                            onChange={handleBankChange}>
                                        <option value="산업" selected="selected">산업은행</option>
                                        <option value="기업">기업은행</option>
                                        <option value="국민">국민은행</option>
                                        <option value="수협">수협은행</option>
                                        <option value="농협">농협은행</option>
                                        <option value="우리">우리은행</option>
                                        <option value="SC제일">SC제일은행</option>
                                        <option value="한국씨티">한국씨티은행</option>
                                        <option value="대구">대구은행</option>
                                        <option value="부산">부산은행</option>
                                        <option value="광주">광주은행</option>
                                        <option value="제주">제주은행</option>
                                        <option value="전북">전북은행</option>
                                        <option value="경남">경남은행</option>
                                        <option value="새마을">새마을금고</option>
                                        <option value="신협">신협</option>
                                        <option value="우체국">우체국</option>
                                        <option value="하나">하나은행</option>
                                        <option value="신한">신한은행</option>
                                        <option value="케이">케이뱅크</option>
                                        <option value="카카오">카카오뱅크</option>
                                        <option value="토스">토스뱅크</option>
                                        <option value="지역농축협">지역농축협</option>
                                        <option value="미래에셋증권">미래에셋증권</option>
                                        <option value="NH투자증권">NH투자증권</option>
                                        <option value="삼성증권">삼성증권</option>
                                        <option value="한국투자증권">한국투자증권</option>
                                        <option value="KB증권">KB증권</option>
                                        <option value="메리츠증권">메리츠증권</option>
                                        <option value="신한금융증권">신한금융증권</option>
                                        <option value="하나증권">하나증권</option>
                                    </select>
                                    <input type="text"
                                            className='ml7'
                                            placeholder="계좌번호"
                                            value={account_number}
                                            onChange={handleInputAccountNumberChange}
                                     />       
                                    <input type="text"
                                            className='ml7'
                                            placeholder="예금주"
                                            value={account_holder}
                                            onChange={(e) => setAccountHolder(e.target.value)}
                                     />       
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    공동대표여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input 
                                        type='radio' 
                                        className='mrbtn'
                                        id='c1' 
                                        name='ceo'
                                        onChange={handleCeoChange} 
                                        checked={ceo === "false"}
                                        value='false' 
                                    />
                                    <label for='c1'>공동대표 없음</label>

                               
                                    <input 
                                        type='radio' 
                                        className='mrbtn' 
                                        id='c2' 
                                        name='ceo'
                                        onChange={handleCeoChange} 
                                        checked={ceo === "true"}
                                        value='true'
                                    />
                                    <label for='c2'>공동대표 있음</label>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    과세여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type='radio' 
                                        className='mrbtn'
                                        id='t1' 
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '과세'}
                                        value='과세' 
                                    />
                                    <label for='t1'>과세</label>

                                    <input type='radio' 
                                        className='mrbtn'
                                        id='t2'
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '면세'}
                                        value='면세' 
                                    />
                                    <label for='t2'>면세</label>

                                    <input type='radio'
                                        className='mrbtn' 
                                        id='t3' 
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '간이과세'}
                                        value='간이과세'  />
                                    <label for='t3'>간이과세</label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </section>

                <section className='McontentBox m-p0'>
                    <div className='Register-table-wrap'>
                        <h3>추가정보</h3>
                        <table className='s1Table'>
                            <tbody>
                                <tr>
                                    <td>
                                        매장전화
                                        <i className='essential'></i>   
                                    </td>
                                    <td>
                                        <input 
                                            type="text"
                                            placeholder="매장전화"
                                            value={tel}
                                            onChange={handleInputTelNumberChange}
                                        />      
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>팩스번호(선택)</td>
                                    <td>
                                        <input 
                                            type="text" 
                                            placeholder="팩스번호"
                                            value={fax}
                                            onChange={handleInputFaxNumberChange}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>직원추가(선택)</td>
                                    <td className='ep10'>
                                        <input 
                                        type="text"
                                        placeholder='추가 하실 직원의 아이디를 입력한 뒤 추가버튼을 누르세요'
                                        value={input_employeeValue}
                                        onKeyDown={handleEKeyDown}
                                        onChange={(e) => setInputEmployeeValue(e.target.value)}
                                        />

                                        <button 
                                        className='addEmployeeBtn'
                                        onClick={handleEmployeeSubmit}>추가</button>

                                        <ul className='employeeList'>
                                            {employee_list.map((item, index) => (
                                                <li key={index}>
                                                    {item}
                                                    <button onClick={() => handleEmployeeDelete(index)}>X</button>
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        출고지 주소
                                        <i className='essential'></i>
                                    </td>
                                    <td className='py-2'>
                                        <ul className='ec-address'>
                                            <li>
                                                <input type="text"
                                                    placeholder="우편번호"
                                                    value={dispatchLocation.postal_code}
                                                    disabled 
                                                />  
                                                <button className="ms-2" 
                                                        onClick={()=>setDispatchModalIsOpen(true)}>
                                                            주소 검색
                                                </button> 
                                            </li>
                                            <li>
                                                <input type="text"
                                                    placeholder="출고지 주소"
                                                    value={dispatchLocation.address}
                                                    disabled 
                                                />   
                                            </li>
                                            <li>
                                            <input
                                                type="text"
                                                placeholder="출고지 상세주소"
                                                value={dispatchLocation.detail}
                                                onChange={handleInputDispatchDetailChange}
                                                />
                                            </li>
                                        </ul>      
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        반품지 주소
                                        <i className='essential'></i>
                                    </td>
                                    <td className='py-2'>
                                        <ul className='ec-address'>
                                            <li>
                                                <input type="text"
                                                    placeholder="우편번호"
                                                    value={returnAddress.postal_code}
                                                    disabled 
                                                />  
                                                <button className="ms-2" 
                                                        onClick={()=>setReturnModalIsOpen(true)}>
                                                            주소 검색
                                                </button> 
                                            </li>
                                            <li>
                                                <input type="text"
                                                    placeholder="반품지 주소"
                                                    value={returnAddress.address}
                                                    disabled 
                                                />   
                                            </li>
                                            <li>
                                            <input
                                                type="text"
                                                placeholder="반품지 상세주소"
                                                value={returnAddress.detail}
                                                onChange={handleInputReturnDetailChange}
                                                />
                                            </li>
                                        </ul>      
                                    </td>
                                </tr>
                 
                            </tbody>
                        </table>
                    </div>
                    
                    <div className='m-btn-wrap'>
                        <button onClick={handleSubmit} className='m-btn m-btn-search'>
                            {isLoading ?  <LoadingSpinner /> : '매장등록'} 
                        
                        </button>
                    </div> 
                </section>
           
        {/* 사업장 주소지 모달 */}
        {
            modalIsOpen  ? (
                <AddressModal 
                    show={modalIsOpen} 
                    onHide={setModalIsOpen} 
                    autoClose 
                    onComplete={onCompletePost} 
               />
            ) : null
        }

        {/* 출고지 변경 모달 */}

        {
            dispatchModalIsOpen ? (
                <AddressModal
                    show={dispatchModalIsOpen}
                    onHide={setDispatchModalIsOpen}
                    autoClose
                    onComplete={handleDispatchComplete} // 출고지 주소 입력 모달용 함수
            
                />
            ) : null
        }

        {/* 반품지 변경 모달 */}
        {
            returnModalIsOpen ? (
                <AddressModal
                    show={returnModalIsOpen}
                    onHide={setReturnModalIsOpen}
                    autoClose
                    onComplete={handleReturnComplete} // 반품지 주소 입력 모달용 함수
                />
            ) : null
        }






            </div>

        </>
    )
}

export default Registration