/***************************************************************************************
* FileName      : OrderDetail.js
* Description   : 해와관리자 > 주문배송 > 주문조회 상세내역 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { fetchOrderDetail } from '../../../api/hwadmin/orderAPI';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import React, { useState , useEffect } from 'react';
import { extractYearMonthDayHourMinute2 } from '../../mypage/Component/extractDateUtils';
function OrderDetail()
{
    const { orderNumber , orderId } = useParams();

    const { data, error, isFetching } = useQuery([`order${orderId}`], () => fetchOrderDetail(orderId));

    console.log(data);

    if (error) {
        console.error("Error fetching order data:", error);
    }
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>주문조회({orderNumber})</h2>
            </div>
            <section className='McontentBox'>
                <div className='subTitle'>
                    <span>주문정보</span>
                </div>
                <table className='sTable'>
                    <colgroup>
                    </colgroup>
                        <thead>
                            <tr>
                                <th>주문번호</th>
                                <th>주문자</th>
                                <th>수취인</th>
                                <th>결제수단</th>
                                <th>결제일시</th>
                                <th>주문총액</th>
                                <th>사용한 마일리지</th>
                                <th>최종결제금액</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.order_number}</td>
                                <td>{data?.orderer_name}</td>
                                <td>{data?.recipient_name}</td>
                                <td>{data?.pay_method}</td>
                                <td>
                                    {extractYearMonthDayHourMinute2(data?.delivery_items?.[0]?.order_items?.[0]?.paid_at)}
                                </td>
                                <td>{data?.total_price.toLocaleString()}</td>

                            </tr>
                        </tbody>
                    </table>
            </section>

            <section className='McontentBox'>
                <div className='subTitle'>
                    <span>주문상품</span>
                </div>

                <table className='sTable'>
                    <colgroup>
                    </colgroup>
                        <thead>
                            <tr>
                                <th>상품명</th>
                                <th>옵션명</th>
                                <th>수량</th>
                                <th>상품금액</th>
                                <th>판매자</th>
                                <th>주문상태</th>
                                <th>배송</th>
                                <th>주문금액</th>
                            </tr>
                        </thead>
          
                        <tbody>
                        {data?.delivery_items.map((deliveryItem, deliveryIndex) => (
                            <React.Fragment key={deliveryIndex}>
                            {deliveryItem.order_items.map((item, index) => (
                                 <>
                                    {item.orderitem_option_rows.map((option, optionIndex) => (
                                        <tr key={optionIndex}>
                                            <td>{option.name}</td>
                                            <td>{option.order_item}</td>
                                            <td>{option.quantity}</td>
                                            <td>{option.total_price.toLocaleString()}</td>
                                            <td>{item.vendor_name}</td>
                                            <td>{item.status}</td>
                                            </tr>
                                    ))}
                                     {item.orderitem_extra_options.map((option, optionIndex) => (
                                        <tr key={optionIndex}>
                                            <td>{option.name}</td>
                                            <td>{option.order_item}</td>
                                            <td>{option.quantity}</td>
                                            <td>{option.total_price.toLocaleString()}</td>
                                            <td>{item.vendor_name}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    ))}
                                </>
                                
                            ))}
                        </React.Fragment>
                        ))}

                        </tbody>
                    </table>
            </section>

            <section className='McontentBox'>
                <div className='subTitle'>
                    <span>배송지 정보</span>
                </div>

                <table className='sTable'>
                    <colgroup>
                          
                    </colgroup>
                        <thead>
                            <tr>
                                <th>수취인</th>
                                <th>연락처1</th>
                                <th>연락처2</th>
                                <th>우편번호</th>
                                <th>도로명</th>
                                <th>지번</th>
                                <th>상세주소</th>
                                <th>배송메시지</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.recipient_name}</td>
                                <td>{data?.recipient_phone_number}</td>
                                <td></td>
                                <td>{data?.postal_code}</td>
                                <td>{data?.address}</td>
                                <td>{data?.detail_address}</td>
                            </tr>
                        </tbody>
                    </table>
            </section>

            <section className='McontentBox'>
                <div className='subTitle'>
                    <span>출고내역</span>
                </div>

                <table className='sTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>상품명</th>
                            <th>수량</th>
                            <th>출고일</th>
                            <th>출고번호</th>
                            <th>출고상태</th>
                            <th>출고완료일시</th>
                            <th>배송완료일시</th>
                            <th>적립(예정)마일리지</th>
                        </tr>
                    </thead>
                    {/* order_items.delivery 출고내역 있음 */}
                    <tbody>
                        {data?.delivery_items?.map((deliveryItem, deliveryIndex) => (
                            <React.Fragment key={deliveryIndex}>
                            {deliveryItem.order_items?.map((item, orderIndex) => (
                                <tr key={orderIndex}>
                                {item.delivery && (
                                    <>
                                    <td>{item.product_name}</td>
                                    <td>{item.delivery.status}</td>
                                    
                                    </>
                                )}
                                </tr>
                            ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </section>
            
            <section className='McontentBox'>
                <div className='subTitle'>
                    <span>반품/취소내역</span>
                </div>

                <table className='sTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>처리내용</th>
                            <th>처리금액</th>
                            <th>처리수량</th>
                            <th>처리상태</th>
                            <th>접수일시</th>
                            <th>완료일시</th>
                            <th>처리담당자</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {data?.delivery_items?.map((deliveryItem, deliveryIndex) => (
                            <React.Fragment key={deliveryIndex}>
                            {deliveryItem.order_items?.map((item, orderIndex) => (
                                <tr key={orderIndex}>
                                {item.cancels && (
                                    <>
                                    <td>{item.cancels.cancel_reason}</td>
                               
                                    </>
                                )}
                                </tr>
                            ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </section>

        </div>


        </>
    )
}

export default OrderDetail