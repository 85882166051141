/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 셀러관리자 > 정산 > 미지급내역 AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <>
        <tr>
          <td>{Adjust.order_number}</td>
          <td>배송완료일</td>
          <td>{Adjust.purchase_confirmation_date ? Adjust.purchase_confirmation_date : "-"}</td>
          <td>{Adjust.product_name}</td>
          <td>{Adjust.settlement_amount.toLocaleString() }</td>
          <td>
            {Adjust.orderer_username} <br/>
            {Adjust.orderer_name}
          </td>
          <td>
            {Adjust.recipient_name}<br/>
            {Adjust.recipient_phone_number}
          </td>
          <td>{Adjust.address}</td>
         </tr>
      </>
    </>
  );
};
