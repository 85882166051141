function generateOptionCombinations(optionsList) {
  if (!optionsList || !optionsList.length) {
    return [];
  }

  function permute(optionsList, index, current, result) {
    if (index === optionsList.length) {
      result.push(current.slice());
      return;
    }

    const option = optionsList[index];

    for (let i = 0; i < option.value.length; i++) {
      current.push({ name: option.name, value: option.value[i] });
      permute(optionsList, index + 1, current, result);
      current.pop();
    }
  }

  const result = [];
  permute(optionsList, 0, [], result);
  return result;
}

export default generateOptionCombinations;