import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFilteringOptions } from '../../../../api/Category'

function FilteringOptions({ categoryId }) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data: filteringData } = useFilteringOptions(categoryId);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching main categories.</div>;
  }
  function handleSubCategoryClick(category) {
    console.log(category?.id)
    queryClient.setQueryData('selectedId', category?.id);
    queryClient.setQueryData('selectedName', category?.name);
    queryClient.setQueryData('selectedHidden', category?.hidden);


    queryClient.setQueryData('selectedfiltering', category?.name);
    
    setSelectedCategoryId(category?.id);
  }
  
  return (
    <div className='categbox'>
      <p className='ctit'>필터링 옵션명</p>
      <div type="필터링옵션" className='kwutOX'>
        <ul>
          {filteringData?.data !== undefined && filteringData?.data.map((category) => (
            <li className='fBSFej' key={category?.id}>
              <button 
                onClick={() => handleSubCategoryClick(category)}
                style={{ color: selectedCategoryId === category?.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                >{category?.name}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FilteringOptions;