// 해와관리자 > 정산 > adjustApi.js
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

// 해와관리자 정산 > 정산현황
const fetchAdjustDocument = (ordering) => {
    return instance.get(`adjust/state/?page_size=all&ordering=${ordering}`)
      .then(res => res.data)
};
  
export const useAdjustDocumentQuery = (ordering) => {
 
    const { data, error, isFetching } = useQuery(
      ['adjustDocument' , ordering ], () => fetchAdjustDocument(ordering)
    );
  
    return {
      data,
      error,
      isFetching
    };
};


// 검색 
export const handleAdjustDocumentSearch = async ({
    setSearchResults, 
    setSearchUrl,
    selectedStartDate, 
    selectedEndDate,
    ordering,
    selectedVendor
    }) => {
    try {
      let url = `adjust/state/?`;
  
      if (selectedStartDate && selectedEndDate) {
        url += `&complete_at__range=${selectedStartDate}__${selectedEndDate}`;
      }

      if(selectedVendor.name){
        url += `&vendor=${selectedVendor.name}`;
      }
      
      if(ordering){
        url += `&ordering=${ordering}` 
      }

      console.log(url)
      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};
  

  
// 해와관리자 정산 > 매출내역
const fetchAdjustRevenue = (page , pageSize , ordering) => {
    return instance.get(`adjust/revenue/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
      .then(res => res.data)
      .catch(error => error.data)
};
  
export const useAdjustRevenueQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);

    useEffect(() => {
      setPage(1);
    }, [pageSize]);

    const { data, error, isFetching } = useQuery(
      ['adjustRevenue', page, pageSize, ordering  ], () => fetchAdjustRevenue(page , pageSize, ordering)
    );
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,

    };
};
  
  
// 매출내역 > 구매확정 기준 > 검색 
export const handleAdjustRevenueSearch = async ({
  searchAdjust,
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  selectedVendor
  }) => {
  try {
    let url = `adjust/revenue/`;

    if (searchAdjust) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchAdjust}`;
          break;
        // case 'id':
        //   url += `?username__contains=${searchAdjust}`;
        //   break;
        
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'join':
          url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        case 'last':
          url += `&last_login__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }

    if(selectedVendor){
      url += `&vendor=${selectedVendor}`;
    }
    
    if(ordering){
      url += `&ordering=${ordering}` 
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 해와관리자 정산 > 부가세내역
const fetchAdjustTaxDocument = (page , pageSize, ordering) => {
  return instance.get(`adjust/tax/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
};

export const useAdjustTaxDocumentQuery = (ordering,pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);
  

  const { data, error, isFetching } = useQuery(
    ['adjustDocument', page,  pageSize, ordering ], () => fetchAdjustTaxDocument(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 해와관리자 > 정산 > 부가세내역 >  검색 
export const handleAdjustTaxSearch = async ({
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  selectedVendor,
  }) => {
  try {
    let url = `adjust/tax/document/`;

    if (selectedStartDate && selectedEndDate) {
      url += `?date__range=${selectedStartDate}__${selectedEndDate}`;
    }else{
      url += `?`;
    }

    if(selectedVendor.name){
      url += `&vendor=${selectedVendor.id}`;
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 해와관리자 정산 > 매출내역 > 구매확정 기준
const fetchAdjustSummaryDocument = (page , pageSize , ordering ) => {
  return instance.get(`adjust/summary/?type=confirm&page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useAdjustSummaryDocumentQuery = (ordering , pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['adjustSummaryDocument', page, pageSize, ordering ],
    () => fetchAdjustSummaryDocument(page , pageSize , ordering)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 해와관리자 > 정산 > 매출내역 
const fetchAdjustSummary2Document = (page , pageSize, ordering ) => {
  return instance.get(`adjust/summary/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useAdjustSummary2DocumentQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['adjustSummaryDocument',page, pageSize, ordering],
    () => fetchAdjustSummary2Document(page , pageSize , ordering)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};


// 해와관리자 > 정산 > 매출내역 > 검색 
export const handleAdjustSummary2Search = async ({
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  }) => {
  try {
    let url = `adjust/summary/`;

    if (selectedStartDate && selectedEndDate) {
      url += `?date__range=${selectedStartDate}__${selectedEndDate}`;
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 정산 > 세금계산서내역
const fetchAdjustInvoiceDocument = (page , pageSize, ordering) => {
  return instance.get(`/adjust/invoice/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useAdjustInvoiceDocumentQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['adjustInvoiceDocument', page, pageSize, ordering],
    () => fetchAdjustInvoiceDocument(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 해와관리자 정산 > 세금계산서내역 > 검색 
export const handleAdjustInvoiceSearch = async ({
  searchAdjust,
  searchOption,
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  selectedVendor
  }) => {
  try {
    let url = `/adjust/invoice/document/`;

    if (searchAdjust) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchAdjust}`;
          break;
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&complete_at__range=${selectedStartDate}__${selectedEndDate}`;
    }

    if(selectedVendor){
      url += `&vendor=${selectedVendor.id}`;
    }
    
    if(ordering){
      url += `&ordering=${ordering}` 
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 > 정산 > 세금계산서내역 > 세금계산서 내역 추가
export const RegisterInvoice= (data) =>
  instance.post(`adjust/invoice/`, data).then(response => response.data);


// 해와관리자 정산 > 지급내역 > 지급내역 추가 > 입점사 별 지급 예정 목록
const fetchAdjustVendorSchedule = (vendorId, page) => {
  const url = vendorId ? `adjust/vendor/schedule/?vendor=${vendorId}&page=${page}` : `adjust/vendor/schedule/?page=${page}`;

  return instance.get(url)
    .then(res => res.data);
};


export const useAdjustVendorScheduleQuery = (vendorId) => {
    const [page, setPage] = useState(1);

    const { data, error, isFetching } = useQuery(
        ['adjustVendorSchedule', vendorId , page ], () => fetchAdjustVendorSchedule(vendorId , page )
    );

    return {
      data,
      error,
      isFetching,
      page,
      setPage
    };
};

// 해와관리자 > 정산 > 지급내역 >  지급내역 추가 모달 > 정산 그룹 생성
export const RegisterAdjustGroup= (data) =>
  instance.post(`adjust/group/`, data).then(response => response.data);

// 해와관리자 > 정산 > 지급내역 >  지급내역 수정 > 수정
export const EditAdjustGroup= (Id , data) => {
    const url = `adjust/group/${Id}/`;
    return instance.patch(url, data)
    .then(res => res.data);
};


// 해와관리자 정산 > 지급내역 > 지급내역 상태 > 수정 버튼 클릭시 > 정산 그룹 상세 조회 
const fetchAdjustGroup = (Id, page) => {
  const url = `adjust/group/${Id}/?page_size=all`;

  return instance.get(url)
    .then(res => res.data);
};


export const useAdjustGroupQuery = (Id) => {
    const [page, setPage] = useState(1);

    const { data, error, isFetching } = useQuery(
        ['adjustGroup', Id , page ], () => fetchAdjustGroup(Id , page )
    );

    return {
      data,
      error,
      isFetching,
      page,
      setPage
    };
};

// 해와관리자 > 정산 > 미지급내역
const fetchAdjustUnpaidDocument = (page, pageSize, ordering) => {
  return instance.get(`adjust/vendor/unpaid/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
};

export const useAdjustUnpaidDocumentQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['adjustUnpaidDocument', page , pageSize, ordering ], () => fetchAdjustUnpaidDocument(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage
  };
};


// 해와관리자 > 정산 > 미지급내역 > 검색 
export const handleAdjustUnpaidDocumentSearch = async ({
  searchAdjust,
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  selectedVendor
  }) => {
  try {
    let url = `adjust/vendor/unpaid/document/`;

    if (searchAdjust && searchOption) {
      if(searchOption==='all'){
        url += `?search=${searchAdjust}`;
      }else{
        url += `?${searchOption}=${searchAdjust}`;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
        url += `&purchase_confirmation_date=${selectedStartDate}__${selectedEndDate}`;
    }

    if(selectedVendor.id){
      url += `&vendor=${selectedVendor.id}`;
    }
    
    if(ordering){
      url += `&ordering=${ordering}` 
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 > 정산 > 지급내역
const fetchAdjustPayment = (page, pageSize, ordering) => {
    return instance.get(`adjust/group/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
      .then(res => res.data)
};
  
export const useAdjustPaymentQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);
  
    useEffect(() => {
      setPage(1);
    }, [pageSize]);

    const { data, error, isFetching } = useQuery(
      ['adjustPayment', page , pageSize, ordering ], () => fetchAdjustPayment(page , pageSize, ordering)
    );
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage
    };
};


// 해와관리자 > 정산 > 지급내역 > 검색 
export const handleAdjustPaymentSearch = async ({
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  selectedVendor
  }) => {
  try {
    let url = `adjust/group/document/?`;

    if (selectedStartDate && selectedEndDate) {
      url += `&complete_at=${selectedStartDate}__${selectedEndDate}`;
    }

    if(selectedVendor){
      url += `&vendor=${selectedVendor.name}`;
    }

    if(ordering){
      url += `&ordering=${ordering}` 
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

//정산 그룹 지급 처리 

export const fetchadjustGroupPayment = (groupId) => {
  const url = `adjust/group/payment/${groupId}/`;
  return instance.patch(url).then(res => res.data);
};