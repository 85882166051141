/***************************************************************************************
* FileName      : MReviewRow.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 나의 리뷰 > MReviewRow 컴포넌트 (모바일)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.12
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';
import ViewStarRating from './ViewStarRating.js';
import ViewReviewModal from './ViewReviewModal.js';

function MReviewRow({orderItem})
{
    // 후기 보기 모달 
    const [isOpen, setIsOpen] = useState(false);

    const handleReviewView = () => {
        setIsOpen(true);
    };

    
    function TruncatedText({ text }) {
        const maxLength = 15;
        const displayText = text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
        return <p className='rcontent'>{displayText}</p>;
    }
    
    
    const [rating, setRating] = useState(0);

    useEffect(() => {
        const numericRating = parseFloat(orderItem.rating);
        setRating(numericRating);
    }, [orderItem.rating]); 

    return (
        <>
        
        <div className='m-order-wrap'>   
            <div className='m-prod-wrap'>  
                <div className='m-prod-thum' >
                    <img
                        src={orderItem?.orderitem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </div>
                <div className='textus'>
          
                    <a>
                        <span className='tit'>
                            [{orderItem?.orderitem.vendor_name}] 
                        </span> 
                        <span className='txt'>
                            {orderItem?.orderitem.product_name}
                        </span>
                    </a>
              
                    <div className='rating'>
                        <ViewStarRating 
                            rating={rating}
                        />
                    </div>
                    <TruncatedText text={orderItem?.content} />
             
                    <button className='mpbtn1 mt-2' onClick={() => handleReviewView()}>리뷰 보기</button>
      
                          
                    {isOpen && (
                        <ViewReviewModal
                            reviewId={orderItem.id}
                            setIsOpen={setIsOpen}/>
                    )}
                </div>
            </div>

        </div>
        </>
    )
}

export default MReviewRow