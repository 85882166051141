// 해와관리자 > 마케팅
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import axios from 'axios';
import { BASE_URL } from './apiConfig';
import { useState } from 'react';
import { instance } from '../axiosInstance/axiosConfig';
import { getAccessFromCookie } from './cookieUtils';
const PAGE_SIZE = 10;
const boundary = Math.random().toString().substr(2);


// 제철상품 카테고리 등록 API
export function useRegisterSeasonalProductCategory() {
    const [isPostLoading, setIsPostLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const access = getAccessFromCookie();
  
    if (!access) {
      return null;
    }
    
    const post = async (data) => {
      setIsPostLoading(true);
      setError(null);
  
      try {
        const response = await fetch(`${BASE_URL}/ad/seasonal/`, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${access}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        if (!response.ok) {
          const errorResponseData = await response.json();
          throw new Error(`Failed to post: ${errorResponseData.message}`);
        }
  
        const responseData = await response.json();
        return responseData;
      } catch (error) {
        setError("Failed to post");
        throw new Error("Failed to post");
      } finally {
        setIsPostLoading(false);
      }
    };
  
    return {
      post,
      isPostLoading,
      error,
    };
  }


// 제철 상품 카테고리 삭제
export const DeleteSeasonalProductCategory = (selectCategoryId) => {
  return instance.delete(`/ad/seasonal/${selectCategoryId}`)
}

// 제철상품 카테고리 목록 반환
const fetchProducts = (page , pageSize) => {
    return fetch(`${BASE_URL}/ad/seasonal/?page=${page}&page_size=all`)
      .then(res => res.json());
};
  
export const useSeasonalProductsQuery = () => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [toggle, setToggle] = useState(false);
  
    const { data, error, isFetching , isLoading } = useQuery(['seasonalproducts', page], () => fetchProducts(page), {
      onSuccess: (data) => {
        // setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
     
        if (page < totalPages) {
          prefetchQuery(['products', page + 1], () => fetchProducts(page + 1));
        }
      }
    });
 
    return {
      data,
      error,
      isLoading,
      isFetching,
      page,
      setPage,
      totalPages,
      toggle,
      setToggle,
      
    };
};

