/***************************************************************************************
* FileName      : ProductInquiryRow.js
* Description   : 해와관리자 > 고객관리 > 상품문의 > ProductInquiryRow 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddAnswer } from '../../../../api/hwadmin/customerApi';

import CheckAnswerModal from './CheckAnswerModal';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

export const ProductInquiryRow  =  ({ productInquiry, index, selectedRows, setSelectedRows }) => {

    const { post, isLoading: isPostLoading, error } = useAddAnswer();

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
          <div className="parent-container">
            <div className="spinner" ></div>
          </div>
        );
  };

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    // 답변 완료 , 수정 모달
    const [isAOpen, setIsAOpen] = useState(false);

    // 답변확인 모달 
    const openAnswerModal = () =>{
      setIsAOpen(true);
    }


    // 답변 
    const [answer, setAnswer] = useState('');

    const handleTextareaChange = (event) => {
      setAnswer(event.target.value);
    };

    const handleCellClick = (username) => {
      const searchQuery = encodeURIComponent(username);
      const newUrl = `/inquiry/product/document/?search=${searchQuery}`;
      navigate(newUrl);
    };

    // user 검색
    const handleUserCellClick = (username) => {
      const searchQuery = encodeURIComponent(username);
      const newUrl = `/admin/member/management/?search=${searchQuery}`;
      navigate(newUrl);
    };

    // 답변작성 모달창 열기
    const openModal = () => {
      setIsOpen(true);
    };

    // 답변작성 모달창 닫기
    const closeModal = () => {
      setIsOpen(false);
    };

    const handleSubmit = () => {
      const data = {
        id: productInquiry.id,
        answer: answer,
      }
      const url = '/inquiry/product/answer/'; 
      
      post(url , data)
      .then(() => {
          alert("등록 성공");
          setIsLoading(false); // 로딩 상태를 비활성화
          setIsOpen(false);
          
      })
      .catch(error => {
          console.log("등록 실패:", error.message);
          setIsLoading(false); // 로딩 상태를 비활성화
      });

      console.log(data)
  }

  console.log(productInquiry);

  return(
    <tr
      key={productInquiry.id}
      style={{
        backgroundColor: selectedRows[productInquiry.id] ? "#f7f7f6" : "transparent"
      }}
    >
      <td className="sticky-col-1">
        <input
          type="checkbox"
          name=""
          value=""
          onClick={() =>
            setSelectedRows({
              ...selectedRows,
              [productInquiry.id]: !selectedRows[productInquiry.id]
            })
          }
        />
      </td>
      <td className="sticky-col-2">{index + 1}</td>
      <td className="sticky-col-3">{productInquiry.type}</td>
      <td className="sticky-col-4" style={{left:'124px'}}>
        {productInquiry.product}
      </td>

      <td className='blueLink' onClick={() => handleCellClick(productInquiry.vendor)}>
          {productInquiry.vendor}
      </td>

      <td>
        {productInquiry.product_id} <br/>
        {productInquiry.title}
      </td>

      <td className='blueLink' onClick={() => handleUserCellClick(productInquiry.user)}>
        {productInquiry.user}
      </td>


      <td>
        {extractYearMonthDayHourMinute2(productInquiry.created_at)}
      </td>
      <td>답변 상태</td>
      <td>  
        {/* {productInquiry.answer} */}
          {productInquiry.answer ? (
            <button className='t-s-btn bb' onClick={openAnswerModal}>
            답변완료
            </button>
          ) : (
            <button className='t-s-btn' onClick={openModal} style={{marginRight:'5px'}}>
              답변작성
            </button>
          )} 

          <button className='t-s-btn' onClick={openModal}>
              판매자 알림
          </button>
          {/* 답변 내용있으면 답변 수정 버튼 ?  */}
      </td>
      <td>  
        {productInquiry.manager}

      </td>

    {/* 답변작성 모달창 */}
    {isOpen && (
        <div className="modal ">
          <div className="modal-content CenterModal" >
              <span className="close" onClick={closeModal}>&times;</span>

              <p className='modal-tit' style={{ color : '#5494f5'}}>
                [{productInquiry.type}]
              </p>
              
              <p className='modal-tit'> {productInquiry.title} </p>

              <div className='modal-content-box' style={{marginBottom : '30px' }}>
                <p style={{ color : '#5494f5'}}> 
                  {productInquiry.product} 
                </p>
              </div>

              <p className='modal-tit'>문의 답변</p>
              <textarea cols="30" rows="10" value={answer} onChange={handleTextareaChange}>

              </textarea>
        
              <div className='modal-button-wrap'>
                <button className='m-btn m-btn-search'
                        onClick={handleSubmit}>
                        {isLoading ?  <LoadingSpinner /> : '답변완료'} 
                </button>
              </div>
            
          </div>
        </div>
    )}
  
  {/* 답변확인 모달 */}

  {isAOpen && (
        <>
          <CheckAnswerModal 
            setIsAOpen ={setIsAOpen} 
            productInquiry={productInquiry}
          />
        </>
      )}
   

  </tr>
  )
}
