import adminLogin from '../../api/login/adminLogin';
import { useEffect, useState } from 'react';

function AdminLogin()
{
    const [username , setUsername] = useState('')
    const [password, setPassword] = useState('')
    
    // const { isLoading, error, data, refetch } = adminLogin(username, password);
 
    // const handleSubmit = (event) =>{
    //     console.log({username, password })
    //     event.preventDefault();
    //     refetch();
        
    //     // navigate('/')
    // }

    const login = adminLogin();

    const handleSubmit = (event) =>{
        console.log({username, password })
        event.preventDefault();
    }

    return (
        <> 
        <body id="Adminlogin">
            <section className="Adminlogin">
				<img src={process.env.PUBLIC_URL + '/img/Logo_00.png'} /> 
                <h1>관리자 로그인</h1>
                <form onSubmit={handleSubmit}>
                    <div className="input-field">
                        <input type="text"
                                placeholder="아이디를 입력해주세요"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                />

                    </div>
                    
                    <div className="input-field">
                        <input type="password" 
                                placeholder="비밀번호를 입력해주세요"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                />
                    </div>

                    <div className="button-field">
                        <button className='login-button' type="submit"onClick={()=>{login.mutate({ username, password })}}>로그인</button>
                    </div>

                    <div className="add-info">
                        <a href="#">아이디 찾기</a>
                        <a href="#">비밀번호 찾기</a>
                    </div>
                </form>  
            </section>
        </body>
        </>
    )

}

export default AdminLogin