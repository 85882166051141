
/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 셀러관리자 > 주문배송 > 반품/취소 > 반품,취소 전체 리스트 조회 > Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React from "react";
import { extractYearMonthDayHourMinute2 } from "../../../../mypage/Component/extractDateUtils";


export const OrderRow = ({ order, index }) => {

  return (
    <tr key={order?.id}>
      <td>{order?.product_id}</td>
      {/* <td>{order?.confirmation_status}</td> */}
      <td>{order?.abort_type}</td>
      <td>
        {order?.cancels ? (
          order?.cancels.status
        ) : (
          order?.returns ? (
            order?.returns.status
          ) : (
            order?.abort_type
          )
        )}
      </td>

      <td>
        {order?.orderer_username}<br/>
        {order?.orderer_name}
      </td>
      <td>
        {order?.recipient_name} <br/>
        {order?.recipient_phone_number}
      </td>
      <td>
        {order?.cancels ? (
          extractYearMonthDayHourMinute2(order?.cancels.created_at)
        ) : (
          order?.returns ? (
              extractYearMonthDayHourMinute2(order?.returns.created_at)
          ) : (
            "-"
          )
        )}
      </td>
      <td>
        {order?.cancels ? (
            order?.cancels.reception_number
        ) : (
          order?.returns ? (
            order?.returns.reception_number
          ) : (
            "-"
          )
        )}
      </td>

      <td>
        {order?.product_id} <br/>
        {order?.product_name}
      </td>

      <td>
        {order?.option_rows.map((option,index) => (
            <p key={index}>{option.name} - {option.quantity}개</p>
        ))}
      </td>
      <td>
        {order?.cancels ? (
          order?.cancels.cancel_reason
        ) : (
          order?.returns ? (
            order?.returns.return_reason
          ) : (
            "-"
          )
        )}
      </td>
      <td>{order?.returns?.delivery_cost_bearer}</td>
      <td>{order.total_price.toLocaleString()}원</td>
  
      <td>{order?.courier}</td>
      <td>{order?.tracking_number}</td>
      <td>{order?.is_escrow ? "O" : "-"}</td>
        
      <td>{order?.returns?.return_request_at ? 
              extractYearMonthDayHourMinute2(order?.returns?.return_request_at) : "-"}</td>
      <td>{order?.returns?.return_complete_at ? 
            extractYearMonthDayHourMinute2(order?.returns?.return_complete_at) : "-"}</td>
      <td>{order?.returns?.reject_reason}</td>
      
      <td>
        {order?.cancels ? (
          order?.cancels.manager_name
        ) : (
          order?.returns ? (
            order?.returns.manager_name
          ) : (
            "-"
          )
        )}
      </td>

      <td>  
        {order?.cancels?.completed_at ? (
          extractYearMonthDayHourMinute2(order?.cancels.completed_at)
        ) : (
          order?.returns?.completed_at ? (
            extractYearMonthDayHourMinute2(order?.returns.completed_at)
          ) : (
            "-"
          )
        )}
      </td>
    
    </tr>
  )
}