const monthColors = {
    '1': {
        backgroundColor: '#7CCE76',
        borderColor: '#3B8E41', 
    },
    '2': {
        backgroundColor: '#FFCE76', 
        borderColor: '#D49C19', 
    },
    '3': {
        backgroundColor: '#76CCE7', 
        borderColor: '#196D8C',
    },
    '4': {
        backgroundColor: '#FF7676', 
        borderColor: '#CC2525', 
    },
    '5': {
        backgroundColor: '#E776CC', 
        borderColor: '#8C1980', 
    },
    '6': {
        backgroundColor: '#76E7CC', 
        borderColor: '#198C6D', 
    },
    '7': {
        backgroundColor: '#CC76E7', 
        borderColor: '#6D1980', 
    },
    '8': {
        backgroundColor: '#76A1E7',
        borderColor: '#195E8C', 
    },
    '9': {
        backgroundColor: '#E7E776', 
        borderColor: '#8C8919',
    },
    '10': {
        backgroundColor: '#FFA876', 
        borderColor: '#CC7519', 
    },
    '11': {
        backgroundColor: '#76E79C', 
        borderColor: '#198C52', 
    },
    '12': {
        backgroundColor: '#FF76FF', 
        borderColor: '#CC19CC',
    }
};
export default monthColors;