import React from 'react';

function ProductItem({extraOption , setSelectedExtraOptions}) {

    const handleRemoveOption = (id) => {
        setSelectedExtraOptions((prevOptions) =>
          prevOptions.filter((option) => option.id !== id)
        );
    };

  const handleQuantityChange = (id, change) => {
      setSelectedExtraOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id
            ? { ...option, 
              quantity: Math.max(1, option.quantity + change),
              original_price: option.original_price,
              price: option.original_price * Math.max(1, option.quantity + change),
              }
            : option
        )
      );
  };

  return (
    <div className='prd-add'>
      <div className='prd-amount-txt'>
        <span className='prd-name'>{extraOption.name}</span>
        <span className='prd-remove' onClick={() => handleRemoveOption(extraOption.id)}>x</span>
      </div>
      <div className='prd-amount-wrap'>
        <div className='amount-box'>
          <div className='amount'>
            <a className='del_item_quantity cursor amount-down'
                onClick={() => handleQuantityChange(extraOption.id, -1)}>-</a>
            <input type="text" value={extraOption.quantity} />
            <a className='add_item_quantity cursor amount-up'
                 onClick={() => handleQuantityChange(extraOption.id, 1)}
            >+</a>
          </div>
        </div>

        <p className='fs-6 fw-bold'>{parseInt(extraOption.price).toLocaleString()}원</p>
      </div>
    </div>
  );
}

export default ProductItem;
