import { instance } from '../axiosInstance/axiosConfig';

// [hm] 배송불가일정 등록(관리자)
export const createDeliveryDelay = (start, end, delayReason, vendor) => {
    return instance.post(`/delivery/delay/`, {
        start: start,
        end: end,
        vendor: vendor,
        delay_reason: delayReason
    });
};

// [hm] 배송불가일정 등록(셀러)
export const createSellerDeliveryDelay = (start, end, delayReason) => {
    return instance.post(`/delivery/delay/`, {
        start: start,
        end: end,
        delay_reason: delayReason,
    });
};

// [hm] 배송불가일정 조회
export const getDeliveryDelay = () => instance.get(`/delivery/delay/`);