import { instance } from "../axiosInstance/axiosConfig";
import { imageUploadInstance } from "../axiosInstance/axiosConfig";

import { BASE_URL } from "./apiConfig";
import { getAccessFromCookie } from "./cookieUtils";

// 이미지 업로드 함수
export async function uploadImage(ImageFile) {
  const access = getAccessFromCookie();

  try {
    const response1 = await fetch(`${BASE_URL}/image/upload/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${access}`,
        'Content-Type': 'application/json',
      },
    });

    if (response1.ok) {
      const data1 = await response1.json();
      console.log(data1.uploadURL)
      const formData = new FormData();
      formData.append('file', ImageFile);

      const response2 = await fetch(data1.uploadURL, {
        method: 'POST',
        body: formData,
      });

      if (response2.ok) {
        const data2 = await response2.json();
        console.log('이미지 업로드 성공:', data2.result.variants[0]);
        return data2.result.variants[0]; // 이미지 업로드 후 반환된 URL
      } else {
        console.log('이미지 업로드 실패');
        return null;
      }
    } else {
      console.log('이미지 등록 URL을 얻어오는 요청이 실패했습니다.');
      return null;
    }
  } catch (error) {
    console.log('이미지 등록 오류가 발생했습니다.', error);
    return null;
  }
}

// 배너 리스트 받아오기
export const getBanner = (type) =>{
    return type ? instance.get(`/ad/banner/?type=${type}`) : instance.get('/ad/banner/');
}

// 배너 생성
export const createBanner = (img, link, type) => {
  if (img === null) {
    return null;
  }
  return instance.post('/ad/banner/',
  {
    image: img,
    link: link,
    type: type
  })
}

// 배너 수정(공개/비공개)
export const setBanner = (pk, hidden) => {
  return instance.patch(`/ad/banner/${pk}/`,
  {
      hidden: hidden
  })
}

// 베너 삭제
export const deleteBanner = (pk) =>{
  return instance.delete(`/ad/banner/${pk}/`);;
}

// 배너 순서 변경
export const setBannerOrder = (order, type) => {
  return instance.patch(`/ad/banner/${type}`,
  {
      order: order
  })
}

export const getMainBanner = (type) =>{
  return instance.get(`/ad/banner/main/?type=${type}`)
}

// 팝업 리스트 받아오기
export const getPopup = () =>{
  return instance.get('/ad/popup/');
}

// 메인페이지 팝업 리스트 받아오기
export const getMainPopup = () =>{
  return instance.get('/ad/popup/main/');
}

// 팝업 생성
export const createPopup = (img, link) => {
  if (img === null) {
    return null;
  }
  return instance.post('/ad/popup/',
  {
    image: img,
    link: link,
  })
}

// 팝업 수정(공개/비공개)
export const setPopup = (pk, hidden) => {
  return instance.patch(`/ad/popup/${pk}/`,
  {
    hidden: hidden
  })
}

// 팝업 삭제
export const deletePopup = (pk) =>{
  return instance.delete(`/ad/popup/${pk}/`);;
}

// 배너 순서 변경
export const setPopupOrder = (order) => {
  return instance.patch('/ad/popup/order/',
  {
    order: order
  })
}