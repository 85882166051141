/***************************************************************************************
* FileName      : CancelModal.js
* Description   : 셀러관리자 > 주문배송 > 배송현황 > 반품 상태관리 모달 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { setOrderCancelState } from '../../../../../api/hwadmin/orderAPI';

function CancelModal({ show, setShow, order, modalType }) {

    const modalMessages = {
        'approval': '취소 승인',
        'rejection': '취소 거절',
    };

    // 취소 > 거절사유
    const [rejectMessage, setRejectMessage] = useState(''); 


    const handleActionClick = () => {
      let state=modalType;
      let rejectReason;
      switch (modalType) {
        case 'rejection':
            rejectReason = rejectMessage;
            break;
        default:
            break;
      }
  
      setOrderCancelState(order?.cancels?.reception_number, state, rejectReason)
        .then(res => {
          console.log(res?.data);
          alert(res?.data.data);
        })
        .catch(error => alert(`처리 실패: ${error?.response?.data}`))
        .finally(() => window.location.reload());
    };
  
    return (
      <Modal size={'lg'} show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title id='sm-modal' className='fs-6'>
            {modalType && modalMessages[modalType]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fixTable_wrap my-2'>
            <table className='sTable' id='scrollTable'>
              <thead>
                <tr>
                  <th>주문번호</th>
                  <th>판매자</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.order_number}</td>
                  <td>{order?.vendor_name}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {modalType === 'rejection' && (
              <div className="mt-2">
                  <p className='mt-10'>거절사유</p> 
                  <input 
                      type='text' 
                      className='wsg1' 
                      placeholder='거절사유를 입력해주세요.' 
                      onChange={(e)=>setRejectMessage(e.target.value)}
                  />
              </div>
          )}



          <button
            className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold"
            onClick={handleActionClick}
          >

            {modalMessages[modalType]}
            
          </button>
        </Modal.Body>
      </Modal>
    );
  }
  
  export default CancelModal;
  