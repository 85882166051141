import { Container } from "react-bootstrap";

export const SellerConditions = () => {
    return(
        <Container>
            <h1>판매자 이용약관</h1>
            <p>
                ○ 이 약관은 2023년 9월 12부터 적용됩니다.<br/>
                <br/>
            </p>
            <h2>제1조 (목적)</h2>
                <p>
                    1. 본 약관은 목포해와 쇼핑몰('https://www.mokpoheawa.com')이 제공하는 전자상거래 및 기타 관련 서비스(이하 ‘서비스’라 함)를 이용하기 위하여 판매회원으로 가입한 자(이하 ‘판매회원’이라 함)간의 권리, 의무 및 제반절차규정을 확정하고 이행함으로써 상호 발전 도모를 목적으로 합니다.<br/>
                    <br/>
                    2. PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 본 약관을 준용합니다.<br/>
                    <br/>
                </p>
            <h2>제2조 (정의)</h2>
                <p>
                    1. '회사'라 함은, '주식회사 목포해와'가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 운영하는 사업자를 말하며, 목포해와 쇼핑몰('https://www.mokpoheawa.com') 서비스를 통해 제공되는 전자상거래 관련 서비스의 의미로도 사용합니다.<br/>
                    <br/>
                    2. '이용자'라 함은, 목포해와 쇼핑몰('https://www.mokpoheawa.com')에 접속하여 본 약관에 따라 '회사'가 제공하는 서비스를 받는 회원을 말합니다.<br/>
                    <br/>
                    3. '회원'이라 함은, '회사'에 개인정보를 제공하고 회원으로 등록한 자로서, '회사'의 서비스를 계속하여 이용할 수 있는 자를 말합니다.<br/>
                    <br/>
                    4. '상품'이라 함은 목포해와 쇼핑몰('https://www.mokpoheawa.com')를 통하여 판매 및 제공되는 재화 또는 용역을 말합니다.<br/>
                    <br/>
                    5. '판매자'라 함은 '회사'가 제공하는 '상품'에 대한 판매서비스의 이용을 청약한 '회원'을 말합니다.<br/>
                    <br/>
                </p>
            <h2>제3조 (약관 외 준칙)</h2>
                <p>
                    본 약관에서 정하지 아니한 사항은 법령 또는 회사가 정한 서비스의 개별 약관, 운영정책 및 규칙(이하 '세부지침'이라 합니다)의 규정에 따릅니다. 또한 본 약관과 세부지침이 충돌할 경우에는 세부지침을 우선시합니다.<br/>
                    <br/>
                </p>
            <h2>제4조 (약관의 명시, 개정 및 효력)</h2>
                <p>
                    1. '회사'는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지, 전화번호, 모사전송번호(FAX), 전자우편주소, 사업자등록번호, 통신판매업신고번호 등을 이용자가 쉽게 알 수 있도록 '서비스'의 초기화면 하단에 게시합니다. 다만 본 약관의 내용은 '이용자'가 연결화면을 통하여 확인할 수 있도록 합니다.<br/>
                    <br/>
                    2. '회사'는 '이용자'가 약관에 동의하기에 앞서 약관에 정해진 내용 중 청약철회, 배송책임, 환불조건 등과 같은 내용을 '이용자'가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 통하여 '이용자'의 확인을 구합니다.<br/>
                    <br/>
                    3. '회사'는 '전자상거래 등에서의 소비자보호에 관한 법률', '약관의 규제에 관한 법률', '전자거래기본법', '정보통신망 이용촉진등에 관한 법률', '소비지보호법' 등 관련 법령(이하 '관계법령'이라 합니다)에 위배되지 않는 범위 내에서 '회사' 및 '서비스'의 운영 상황에 따라 본 약관을 개정할 수 있습니다.<br/>
                    <br/>
                    4. '회사'가 본 약관을 개정하고자 할 경우, 적용일자 및 개정사유를 명시하여 현행약관과 함께 온라인 쇼핑몰 내부 페이지에 그 적용일자 7일 전부터 적용일자 전날까지 공지합니다. 다만, '이용자'에게 불리한 내용으로 약관을 변경하는 경우 최소 30일 이상 유예기간을 두고 공지합니다.<br/>
                    <br/>
                    5. '회사'가 본 약관을 개정한 경우, 개정약관은 적용일자 이후 체결되는 계약에만 적용되며, 적용일자 이전 체결된 계약에 대해 개정 전 약관을 적용합니다. 다만, 이미 계약을 체결한 '이용자'가 개정약관의 내용을 적용받고자 하는 뜻을 '회사'에 전달한 경우 '회사'의 동의 여부에 따라 개정된 약관을 적용할 수 있습니다.<br/>
                    <br/>
                    6. 회사는 서비스 중 특정 서비스에 관한 약관(이하 ‘개별약관‘이라 함)을 별도로 제정할 수 있으며, 판매회원이 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과 상충하는 내용이 있을 경우 개별 약관이 우선적으로 적용됩니다.<br/>
                    <br/>
                    7. 본 약관에서 정하지 아니한 사항 및 본 약관의 해석에 관하여 관계법령 및 건전한 상관례에 따릅니다.<br/>
                    <br/>
                </p>
            <h2>제5조 (이용계약의 성립)</h2>
                <p>
                    1. 이용계약은 ‘목포해와 입점자ID’를 생성하고 서비스를 이용하고자 하는 자(이하 ‘입점신청자‘라 함)가 이 약관에 동의하고 회사가 정한 절차에 따라 판매회원 가입을 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다. 회사는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 email 또는 기타 방법으로 할 수 있습니다.<br/>
                    <br/>
                    2. 입점신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 제출하여야 하며, 회사는 해당 서류를 징구하는 시점까지 가입신청에 대한 승낙을 보류하거나 거부할 수 있습니다.<br/>
                    <br/>
                    3. ‘목포해와 입점자 ID’의 생성 및 판매회원 가입은 만 14세 이상의 개인(개인, 간이 사업자 포함) 또는 법인사업자가 할 수 있습니다.<br/>
                    <br/>
                    4. 회사는 제1항에 따라 입점자회원 가입을 신청 한 자 중에서 아래 각호에 해당되는 경우에는 승인을 거부할 수 있으며, 등록이 된 이후에도 아래 각호의 사유가 확인된 경우에는 승낙을 취소할 수 있습니다.<br/>
                    4-1) 실명이 아니거나 타인의 정보 등(예)주민등록번호, 사업자번호, I-PIN 등)를 이용한 경우<br/>
                    4-2) 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br/>
                    4-3) 기술적 지장 등으로 이용신청에 대한 승낙이 어려운 경우<br/>
                    4-4) 가입을 신청한 자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는 서류를 제출하지 않는 등 기타 제반 사항을 위반하여 신청하는 경우<br/>
                    4-5) 가입을 신청한 자가 여신전문금융업법 등 금융관련 법령 위반한 불법거래 등으로 신용카드 등 결제 제공이 제한되는 것으로 확인되거나 그에 관한 합리적 의심이 드는 경우<br/>
                    4-6) 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우<br/>
                    <br/>
                    5. 판매회원은 회원가입정보와 관련하여 주소지 또는 대금결제를 위한 통장계좌 등의 변경이 있을 경우 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한 손해에 대하여 책임을 지지 않습니다.<br/>
                    <br/>
                    6. 다음 각호의 경우 회사는 해당 ‘목포해와 입점자ID‘의 이용의사가 없다고 볼 시, 삭제 처리 할 수 있습니다. 단, 회사는 본항에 따라 ‘목포해와 입점자ID’를 삭제 처리하기 30일 전 및 7일 전에 생성 시 기재된 연락처(전자우편주소 등)로 통지하여야 합니다.<br/>
                    <br/>
                </p>
            <h2>제6조 (대리행위 및 보증부인)</h2>
                <p>
                    1. 회사는 구매회원과 판매회원간의 재화 또는 용역 등(이하 상품이라 함)의 거래를 위한 통신판매중개시스템의 운영 및 관리 책임만을 부담하며, 상품의 판매 또는 구매와 관련하여 회사의 어떠한 행위도 판매회원 또는 구매회원을 대리하는 행위로 간주되지 않습니다.<br/>
                    <br/>
                    2. 회사는 서비스를 통하여 이루어지는 회원간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록물품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, 회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 회원이 부담해야 합니다.<br/>
                    <br/>
                    3. 회사는 판매회원이 서비스를 통해 취급하는 상품의 판매효과에 대하여 어떠한 보증을 제공하지 아니하며, 판매회원은 자신이 의도한 판매효과의 미흡 등을 이유로 회사에 어떠한 책임도 물을 수 없습니다.<br/>
                    <br/>
                </p>
            <h2>제7조 (제공하는 서비스이용 종류)</h2>
                <p>
                    ① '회사'는 다음의 서비스를 제공합니다.<br/>
                    <br/>
                    1. 이커머스 상품등록 및 관련제반기능 개발 및 운영을 통한 통신판매중개서비스<br/>
                    <br/>
                    2. 판매회원이 제공한 상품 이미지 등 상품 정보 및 데이터베이스를 활용하는 다양한 형태의 서비스<br/>
                    <br/>
                    3. 카테고리별 상품 가격정보를 제공하여 판매회원의 상품가격 관리를 용이하게 하는 서비스<br/>
                    <br/>
                    4. 직접 또는 제휴사와 공동으로 제공하는 이벤트 등의 서비스<br/>
                    <br/>
                    5. 기타 통신판매중개와 관련한 제반 서비스<br/>
                    <br/>
                    ②회사가 제공하는 전항의 서비스는 판매회원이 재화 등을 거래할 수 있도록 서비스의 이용을 허락하거나, 통신판매를 알선하는 것을 목적으로 하며, 개별 판매회원이 서비스에 등록한 상품과 관련해서 회사는 일체의 책임을 지지 않습니다.<br/>
                    <br/>
                    ③회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 서비스 제공을 원칙으로 하지만, 정기점검이나 시스템의 업그레이드가 필요한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인해 서비스를 일시 중단하는 경우 입점자센터 등을 통해 사전에 공지합니다.<br/>
                    <br/>
                </p>
            <h2>제8조 (서비스 이용 수수료)</h2>
                <p>
                    ①회사는 판매회원의 다음과 같은 서비스이용료를 부과할 수 있으며, 서비스이용료의 구체적인 사항 및 요율 등을 판매자센터를 통해 공지합니다.<br/>
                    <br/>
                    1. 결제서비스 이용 수수료<br/>
                    <br/>
                    2. 기타 부가서비스 등의 수수료<br/>
                    <br/>
                    ②회사는 서비스이용료를 판매회원에게 지급해야 하는 정산대금에서의 공제로 징수할 수 있으며, 회사와 판매회원간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료 및 징수 방법을 변경할 수 있습니다.<br/>
                    <br/>
                    ③회사는 필요한 경우 서비스수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 판매자센터를 통하여 공지합니다.<br/>
                    <br/>
                </p>
            <h2>제9조 (서비스의 중단 등)</h2>
                <p>
                    1. '회사'가 제공하는 서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 다만 '회사'의 시스템 유지·관리를 위한 점검과 통신장비 교체 등의 이유로 특별한 사유가 있는 경우에 서비스 전부 또는 일부에 대한 일시적 제공이 중단될 수 있습니다.<br/>
                    <br/>
                    2. '회사'는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우, 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 부득이한 사유가 발생한 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.<br/>
                    <br/>
                    3. '회사‘는 판매회원의 서비스 이용이 다음 각 호 중 어느 하나 이상에 해당하는 경우 서비스 제공을 중단하거나 이용계약을 해제하는 등의 조치를 취할 수 있습니다. 회사는 본항에 따른 조치 시 법률적으로 통지가 허용되지 아니하거나(ex.통지로 인해 법령 또는 규제기관의 명령을 위반하거나 규제기관의 조사를 방해하게 되는 경우), 통지로 인하여 회사, 이용자, 다른 판매회원 및 기타 제3자에 손해가 발생할 수 있다고 합리적으로 판단되는 경우(ex.보안을 저해하는 경우), 통지를 하지 않을 수 있습니다.<br/>
                    3-1)판매회원 계정 도용 정황에 따른 사기 피해 우려가 있는 경우<br/>
                    3-2)고의적으로 소비자가 가격을 오인/착각하도록 하는 경우(옵션기준악용, 별도비용추가 등)<br/>
                    3-3)적발을 회피하고자 업무시간 외의 시간대(주말, 심야 등)에 운영정책 위반 행위(가격, 배송비 조작, 불법 상품, 콘텐츠 등록 등)를 반복하는 경우<br/>
                    3-4)본조에 따른 조치가 취해진 판매회원과 주소, 전화번호 등 사업자 세부정보의 연관성이 확인되어 동종 위반행위로 인한 소비자 피해가 우려되는 경우<br/>
                    3-5)회사가 판매자센터에 게시한 운영정책을 위반한 행위로서 위 사항에 준하는 경우<br/>
                    <br/>
                    4. '회사'가 서비스를 정지하거나 이용을 제한하는 경우 그 사유 및 기간, 복구 예정 일시 등을 지체없이 '이용자'에게 알립니다.<br/>
                    <br/>
                    5. 전항의 사유로 서비스 제공을 중단하는 경우, 서비스 제공 중단의 구체적인 기준 등에 대해서는 운영정책에서 정합니다.<br/>
                    <br/>
                    6. 회사는 서비스의 원활한 운영을 위하여 판매회원이 등록한 상품이 판매 이력이 없거나 또는 판매회원의 정보관리 이력이 없는 경우 공지 또는 통지한 후 판매회원이 등록한 상품정보를 삭제 할 수 있습니다.<br/>
                    <br/>
                    7. 회사는 본 조에 따라 서비스를 중단하는 경우 이러한 사실을 판매자센터 또는 전자우편 등의 방법으로 공지 또는 통지합니다. 단, 기술적 장애 사유 등의 경우에는 사후에 통지할 수 있습니다.<br/>
                    <br/>
                </p>
            <h2>제10조 (회사의 권리와 의무)</h2>
                <p>
                    1. 회사는 이 약관에 따라 지속적이고 안정적인 서비스를 제공하는데 최우선의 노력을 다합니다.<br/>
                    <br/>
                    2. 회사는 판매회원으로부터 제기되는 불편사항 및 서비스의 문제점에 대해 정당하다고 판단되는 경우 우선적으로 해당 문제를 해결하며, 신속한 해결이 곤란한 경우에는 판매회원에게 그 사유와 처리절차를 안내합니다.<br/>
                    <br/>
                    3. 회사는 판매회원이 서비스에 등록한 상품정보 등을 서비스 범위 및 서비스 자체의 홍보 목적 등으로 사용할 수 있고, 필요한 경우 수정∙편집하여 활용할 수 있습니다.<br/>
                    <br/>
                    4. 회사는 판매회원이 서비스를 이용하여 발생하는 광고 및 매출효과 등의 정보를 통계자료 작성, 인공지능 분야 기술 등의 연구 개발, 판매회원의 판매 활동에 도움을 줄 수 있는 서비스 등에의 적용 등의 목적으로 활용할 수 있습니다.<br/>
                    <br/>
                    5. 회사는 판매회원의 서비스 이용과 관련한 자료를 수사기관의 수사목적의 요청 및 기타 공공기관이 관련 법률에 따른 절차를 통해 요청하는 경우 판매회원의 동의 없이 해당 기관에 제공할 수 있습니다.<br/>
                    <br/>
                    6. 회사는 판매회원이 서비스에 등록한 상품 및 그 정보 등이 불법정보에 해당한다고 판단되거나, 발송통신심의위원회 등 관련기관으로부터 요청이 있는 경우 또는 판매회원이 서비스를 이 약관 이 외의 목적으로 사용한다고 판단되는 경우 판매회원에 대한 사전 통보 없이 해당 정보를 삭제할 수 있습니다.<br/>
                    <br/>
                    7. 회사는 안정적인 서비스 제공을 위해 거래액 등을 기준으로 판매회원 별로 판매상품수(판매중 및 판매대기 상품을 모두 포함)의 한도를 정할 수 있으며 이에 대한 자세한 내용은 운영정책에서 정합니다.<br/>
                    <br/>
                </p>
            <h2>제11조 (통지)</h2>
                <p>
                    1. 회사는 이 약관과 관련한 통지 시 판매회원이 제공한 전자메일, 모바일 메시지, 서비스 내 알림 또는 기타 적절한 전자적 수단을 통하여 할 수 있습니다.<br/>
                    <br/>
                    2. 회사는 판매회원 전체에 대한 통지의 경우 7일 이상 판매자센터 내 공지사항 등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 판매 회원의 서비스 이용과 관련하여 중대한 영향을 미치는 사항에 대해서는 개별 통지합니다.<br/>
                    <br/>
                    3. 판매회원은 회사에 실제로 연락이 가능한 전자우편, (휴대)전화번호, 주소 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며, 회사의 통지를 확인하여야 합니다.<br/>
                    <br/>
                    4. 판매회원이 전항의 의무를 소홀히 하여 발생한 불이익에 대해서는 보호받지 못합니다.<br/>
                    <br/>
                </p>
            <h2>제12조 (판매회원의 금지행위)</h2>
                <p>
                    판매회원은 다음 각 호에 해당하는 행위를 하여서는 아니 되며 이를 위반한 경우 회사는 서비스 이용정지 및 이용계약해지 등의 조치를 취할 수 있습니다. 서비스 이용정지의 구체적인 기준 등에 대해서는 운영정책에서 정합니다.<br/>
                    <br/>
                    1. 일반사항<br/>
                    1-1) 서비스를 통해 음란정보, 거짓정보 등 유해한 정보를 게재하거나 링크하는 행위<br/>
                    1-2) 범죄행위와 결부되는 모든 행위 및 기타 관계법령에 위반되는 행위<br/>
                    1-3) 해킹, 컴퓨터 바이러스 유포, 서버 공격 등으로 타인과 회사에 해가 되는 경우<br/>
                    1-4) 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 지속적으로 전송하는 행위<br/>
                    1-5) 통신판매 이외의 목적으로 서비스를 이용하는 행위<br/>
                    1-6) 판매자센터 서비스에서의 판매행위와 직접적인 연관이 없는 이벤트 및 홍보를 위해 서비스를 이용하는 행위<br/>
                    1-7) 위법한 방법을 통해 회사 서비스를 방해하거나 장애를 발생시킬 수 있는 모든 행위<br/>
                    1-8) 이 약관에 따른 권리∙의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나 이를 담보제공 하는 행위<br/>
                    <br/>
                    2. 상품등록 및 판매관련 금지행위<br/>
                    2-1) 물품 또는 용역을 허위로 가장하거나 가족 또는 지인 등 타인의 id를 이용하여 판매자 본인의 물품을 구매하는 등의 부정거래 행위<br/>
                    2-2) 회사의 결제대금 보호서비스를 통하지 않는 직거래 유도행위 및 구매회원의 직거래 요청을 수락하는 행위<br/>
                    2-3) 허가 없이 타인의 상표나 로고를 사용하는 행위 등 타인의 지식재산권을 침해행위<br/>
                    2-4) 상품 등록 시 상품과 상관없는 상표명을 사용하거나 중복해서 사용하는 행위<br/>
                    2-5) 상품등록 시 제조사, 원산지, 브랜드를 허위로 기재하거나 표시하지 않는 행위<br/>
                    2-6) 타인이 창작한 이미지나 문구 또는 타인이 촬영하여 제작한 사진 등 타인의 창작물을 무단으로 사용하는 행위<br/>
                    2-7) 연예인 사진 등 타인의 초상권 및 성명권을 침해하는 행위<br/>
                    2-8) 관련법령에 위반되는 상품을 판매하거나 판매 자격을 갖추지 아니하고 특정상품을 판매하는 행위<br/>
                    2-9) 카테고리 부정등록 등 기타 비정상적인 방법으로 상품을 등록하는 행위<br/>
                    <br/>
                </p>
            <h2>제13조 (지식재산권보호 및 권리침해신고 등)</h2>
                <p>
                    1. 판매회원은 제3자의 지식재산권을 사용하게 되는 경우 당해 권리자로부터 사용에 대한 허가를 취득한 후에 사용해야 하며 상품의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지식재산권을 침해하지 않아야 합니다.<br/>
                    <br/>
                    2. 회사는 판매회원이 서비스에 등록∙사용한 상품정보 및 제반 정보에 대하여 적법한 권리자의 권리를 침해하였다고 볼 상당한 이유가 있거나, 제3자로부터 권리침해의 주장이 있는 경우 판매회원에게 통지 후 해당 정보를 삭제하고 재등록을 제한하는 등의 조치를 취할 수 있습니다. 또한 회사는 권리자 또는 감정기관과 협력하여 판매 상품의 감정을 진행할 수 있으며, 상품의 감정결과 해당 상품이 위조상품 등 권리침해 상품으로 판단될 경우 해당 상품의 삭제, 주문 취소 및 판매회원의 판매활동 제한 등 조치를 취할 수 있습니다.<br/>
                    <br/>
                    3. 전항과 관련하여 회사는 판매회원에게 소명자료를 요청할 수 있으며, 판매회원은 이에 적극 협조하여야 합니다. 단, 판매회원이 제출한 자료가 법원의 확정판결 등 회사가 인정하는 자료가 아닌 경우 회사는 상품 삭제 및 등록 제한, 주문 취소, 판매활동 제한 상태를 유지할 수 있습니다.<br/>
                    <br/>
                    4. 회사는 판매회원이 서비스에 등록·사용한 상품명과 url 정보(이하 '상품 정보')와 관련하여 다음 각 호의 사유가 발생하는 경우, 판매회원에 대해 소명자료의 제출을 요청하거나 상품정보의 수정을 요청할 수 있으며, 판매회원은 이에 적극 협조하여야 합니다. 판매회원이 회사의 요청일로부터 최대 3영업일 이내에 제출 또는 수정하지 않는 경우, 회사는 운영정책에 따라 판매회원의 상품정보를 수정하거나 서비스 이용을 제한할 수 있습니다.<br/>
                    4-1) 판매회원의 상품정보 사용이 부정경쟁방지 및 영업비밀의 보호에 관한 법률(이하 '부정경쟁방지법')상 부정경쟁행위에 해당한다거나 상표법 등 관련 법령을 위반하는 것에 해당한다는 법원의 판결문, 기타 관련 국가기관의 유권해석이 제출된 경우<br/>
                    4-2) 적법한 권리자로부터 판매회원의 상품정보 사용이 권리자의 상표권, 서비스표권 등 지식재산권 및 기타 적법한 권리를 침해하였다는 주장이 제기된 경우<br/>
                    4-3) 다른 판매회원의 상품정보와 혼동, 오인의 여지가 있는 정보를 사용하거나, 다른 상품정보를 포함하여 사용하는 경우<br/>
                    4-4) 등록상표(서비스표)와 동일·유사한 정보를 사용하면서도 상품을 전혀 등록하지 아니하거나 서비스를 통한 상품 판매 이력이 전혀 없는 경우<br/>
                    4-5) 부정경쟁방지법, 인터넷주소자원에 관한 법률, 상표법, 저작권법 등 관련 법령 또는 이 약관에 위배되는 정보 사용 등 회사가 합리적인 판단에 의하여 필요하다고 인정하여 운영정책상 명시한 경우<br/>
                    <br/>
                    5. 회사는 상품과 관련하여 지식재산권 침해가 의심되는 합리적인 사유가 있는 경우 권리자 또는 감정기관과 협력하여 해당 상품에 대한 감정을 진행할 수 있으며, 감정이 진행되는 임시적 기간 동안 구매자 보호를 위하여 해당 상품 또는 상품의 노출을 제한(이하 ‘구매보호’라 함)할 수 있습니다. 이러한 구매보호는 감정 진행에 필요한 최소한의 기간 동안 진행되며, 판매자는 구매보호 기간 동안 이에 관한 소명자료를 제출할 수 있습니다. 또한 회사는 배송완료된 상품의 경우에도 구매회원의 동의를 받아 지식재산권 침해 상품인지 여부의 사후검증을 실시할 수 있고, 해당 상품이 권리침해 상품으로 확인될 경우 동조에서 정한 조치를 취할 수 있습니다. 이러한 구매보호 및 사후검증의 대상, 기간, 방식 등 구체적인 사항은 운영정책에서 정합니다.<br/>
                    <br/>
                    6. 판매회원은 서비스에 등록한 정보와 관련하여 제3자로부터 분쟁이 발생하는 경우 회사를 면책 시키고 그로 인해 발생한 손해를 배상하여야 합니다.<br/>
                    <br/>
                </p>
            <h2>제14조 (취득한 개인정보의 보호)</h2>
                <p>
                    1. 판매회원은 서비스의 이용에 따라 취득한 구매회원 등 타인의 개인정보를 이 약관에서 정한 목적 이외의 용도로 사용하거나 제3자에게 제공하는 등 외부에 유출할 수 없으며, 관련 법령 등에 따라 철저히 보호하여야 합니다.<br/>
                    <br/>
                    2. 회사는 배송 등의 목적으로 판매회원에게 공개되어 있는 구매회원의 개인정보를 회사의 정책에 따라 상당 기간이 경과한 후 비공개 조치할 수 있습니다.<br/>
                    <br/>
                    3. 판매회원이 본 조를 위반하여 구매회원 등으로부터 분쟁이 발생하는 경우 자신의 노력과 비용으로 회사를 면책시켜야 하며, 민/형사 상 일체의 법적 책임을 부담하여야 합니다.<br/>
                    <br/>
                </p>
            <h2>제15조 (회원가입 및 계약성립)</h2>
                <p>
                    1. '회사'가 정한 양식에 따라 '입점자'가 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.<br/>
                    <br/>
                    2. '회사'는 전항에 따라 회원가입을 신청한 '입점자' 중 다음 각호의 사유가 없는 한 '회원'으로 등록합니다.<br/>
                    2-1) 가입 신청자가 본 약관에 따라 '회원'의 자격을 상실한 이력이 있는 경우. 다만, '회사'의 재가입 승낙을 얻은 경우에는 예외로 합니다.<br/>
                    2-2) 회원정보에 허위, 기재누락, 오기 등 불완전한 부분이 있는 경우.<br/>
                    2-3) '서비스'에서 허용하는 회원 가입 절차에서 벗어난 방법을 통해 가입을 시도한 경우.<br/>
                    2-4) 기타 '회원'으로 등록하는 것이 '회사'의 운영에 현저한 지장을 초래하는 것으로 인정되는 경우.<br/>
                    2-5) '회원'으로 등록 및 사용 이력이 있는 경우.<br/>
                    <br/>
                    3. 회원가입 시기는 '회사'의 가입승낙 안내가 '회원'에게 도달한 시점으로 합니다.<br/>
                    <br/>
                    4. '회사'는 다음 각호의 사유가 있는 경우 본 약관의 '판매신청' 조항에 따른 판매신청을 승낙하지 않을 수 있습니다.<br/>
                    4-1) 신청 내용에 허위, 누락, 오기가 있는 경우<br/>
                    4-2) '회원'의 자격이 제한 또는 정지된 고객이 판매를 신청한 경우<br/>
                    4-3) 부정한 방법이나 목적으로 판매를 신청하였음이 인정되는 경우<br/>
                    4-4) 기타 판매신청을 승낙하는 것이 '회사'의 기술상 현저한 지장을 초래하는 것으로 인정되는 경우<br/>
                    <br/>
                    5. '회사'의 승낙이 본 약관의 '수신확인통지' 형태로 이용자에게 도달한 시점에 계약이 성립된 것으로 봅니다.<br/>
                    <br/>
                    6. '회사'가 승낙의 의사표시를 하는 경우 이용자의 판매신청에 대한 확인 및 판매가능여부, 판매신청의 정정 및 취소 등에 관한 정보가 포함되어 있어야합니다.<br/>
                    <br/>
                </p>
            <h2>제16조 (회원탈퇴 및 자격상실, 계약종료 등)</h2>
                <p>
                    1. '회원'은 '회사'에 언제든지 탈퇴를 요청할 수 있으며, '회사'는 지체없이 회원탈퇴 요청을 처리합니다. 다만, 이미 체결된 거래 계약을 이행할 필요가 있는 경우에는 본 약관이 계속 적용됩니다.<br/>
                    <br/>
                    2. '회사'는 다음 각 호의 사유가 발생한 경우 '회원'의 자격을 제한 또는 정지시킬 수 있습니다.<br/>
                    <br/>
                    2-1) 회원가입 시 허위정보를 기재한 경우<br/>
                    2-2) 다른 이용자의 정상적인 이용을 방해하는 경우<br/>
                    2-3) 관계법령 또는 본 약관에서 금지하는 행위를 한 경우<br/>
                    2-4) 공서양속에 어긋나는 행위를 한 경우<br/>
                    2-5) 기타 '회원'으로 등록하는 것이 적절하지 않은 것으로 판단되는 경우<br/>
                    2-6) 본 약관에서 정한 사항을 위반하는 경우<br/>
                    2-7) 쇼핑몰을 이용하여 판매된 재화, 용역 등의 대금, 기타 쇼핑몰 판매이용과 관련하여 회원이 부담하는 판매상품을 정한 기일에 공급하지 않은 경우<br/>
                    <br/>
                    3. '회사'의 서비스를 1년 동안 이용하지 않는 '회원'의 경우 휴면계정으로 전환하고 '서비스'의 이용을 제한할 수 있습니다.<br/>
                    <br/>
                    4. 휴면계정 전환 시 계정 활성을 위해 필요하다고 판단되는 아이디, 비밀번호, 이름, 중복가입 방지를 위한 본인 인증값, 휴대전화번호를 제외한 나머지 정보는 별도로 저장 및 관리됩니다. 다만, 관계법령에 의해 보존할 필요가 있는 경우 '회사'는 정해진 기간 동안 회원정보를 보관합니다<br/>
                    <br/>
                    5. 회사는 다음 각호의 사유가 발생한 경우 체결된 계약을 종료 또는 해지할 수 있습니다.<br/>
                    5-1) 본 약관 및 회사의 운영정책을 위반하거나 회사로부터 그 시정을 요구 받은 후 7일 이내에 이를 시정하지 아니한 경우<br/>
                    5-2) 압류 및 가압류 등 금융기관의 거래정지, 회생 및 파산절차의 개시, 영업정지 및 취소 등의 행정처분, 주요 자산에 대한 보전처분, 영업양도 및 합병 등으로 이용계약의 이행이 불가능한 경우<br/>
                    5-3) 관련 법령을 위반하거나 판매회원의 책임 있는 사유로 인하여 회사가 명예 실추 등 유/무형적 손해를 입은 경우<br/>
                    5-4) 제5조 제4항의 승낙 거부사유가 있음이 확인된 경우<br/>
                    5-5) 기타 제1호 내지 제4호에 준할 정도로 객관적으로 이용계약의 유지가 어려운 경우<br/>
                    <br/>
                    6. 회사는 본 조에 따라 이용계약을 해지하는 경우 판매회원에게 통지합니다. 이 경우 회사는 해지를 하기 전에 상당한 기간을 정하여 판매회원에게 이의 신청의 기회를 부여합니다. 다만, 약관 및 운영정책에서 정하는 사유가 있을 경우에는 별도의 이의 신청 기간을 부여하지 않을 수 있습니다.<br/>
                    <br/>
                    7. 판매회원이 이 약관에 의한 이용계약을 해지하고자 하는 경우, 해지사유를 기재하여 판매자센터를 통해 회원 탈퇴를 요청하여야 합니다. 만약 현재 진행 중인 거래, 문의, 또는 민원이 있거나 회원자격이 정지된 경우에는 탈퇴 신청이 불가능하며, 판매회원이 해당 사항을 처리 완료한 후 이 약관 및 운영정책에 정하는 바에 따라 탈퇴 및 이용계약 해지가 가능합니다.<br/>
                    <br/>
                    8. 이용계약의 해지에도 불구하고 판매회원은 해지 시까지 완결되지 않은 주문건의 배송, 교환, 환불에 필요한 조치를 취하여야 하며, 해지 이전에 이미 판매한 상품과 관련하여 발생한 판매회원의 책임과 관련된 조항은 그 효력을 유지합니다.<br/>
                    <br/>
                    9. 판매회원이 각 이용약관에 따라 탈퇴 및 이용계약 해지가 가능한 상황인 경우, 판매회원은 삭제 요청을 통해 본 약관에 기한 서비스 이용계약을 포함한 서비스 이용계약 전체를 해지하고 모든 서비스로부터 탈퇴할 수 있습니다.<br/>
                    <br/>
                    10. 본 조에 의한 이용계약의 해지는 기발생한 양당사자의 권리관계 및 손해배상 청구권에 영향을 미치지 아니합니다.<br/>
                    <br/>
                </p>
            <h2>제17조 (판매신청이용 및 판매관리)</h2>
                <p>
                    '이용자'는 온라인 쇼핑몰 상에서 다음 방법 또는 이와 유사한 방법에 따라 판매를 신청할 수 있으며, '회사'는 '이용자'를 위하여 다음 각호의 내용을 알기 쉽게 제공하여야 합니다.<br/>
                    <br/>
                    1. 서비스를 통한 상품의 등록 및 판매는 회사의 판매회원가입 승인 이후에 가능하며, 판매회원은 상품 및 용역에 관한 정보를 판매자센터를 통하여 직접 등록, 관리하여야 합니다.<br/>
                    <br/>
                    2. 판매회원은 서비스를 통한 상품 판매 및 상품 광고 등이 제반 법령과 이 약관에 위반되지 않도록 신의에 따라 성실하게 관리 및 운영할 책임이 있습니다.<br/>
                    <br/>
                    3. 판매회원은 상품의 재고 수량 등 수시로 변동되는 사항에 대한 데이터를 판매자사이트를 통해 적절히 관리하여야 하며, 판매자센터에 허위의 데이터를 기재할 수 없습니다.<br/>
                    <br/>
                    4. 판매회원은 판매자센터를 주문 조회, 배송 처리 등의 회사가 정한 목적에 한하여 이용할 수 있으며, 판매자센터를 통해 제공된 정보를 관련법령 및 이 약관이 외의 목적으로 제3자에게 제공할 수 없습니다.<br/>
                    <br/>
                    5. 판매회원은 판매자센터의 로그인 정보(ID 및 Password)를 관리할 책임이 있으며, 회사는 판매회원의 귀책사유에 따른 로그인 정보의 분실 및 제3자 사용 등과 관련하여 발생하는 손해에 대해 책임지지 않습니다.<br/>
                    <br/>
                    6. 판매회원은 회사가 서비스 운영에 필요한 정보 및 증빙자료의 제공을 요청할 경우 이에 성실히 응하여야 합니다.<br/>
                    <br/>
                    7. 판매회원은 구매회원으로부터의 세금계산서 등의 발급 요구에 성실히 응하여야 합니다.<br/>
                    <br/>
                    8. 판매회원은 구매회원의 문의에 성실, 정확히 답변해야 하며, 판매회원의 부정확하거나 불성실한 답변으로 인하여 발생하는 모든 책임과 의무는 판매회원 본인에게 있습니다. 또한, 본 항을 위반하여 구매회원으로부터 계속적인 분쟁이 발생하는 경우 회사는 판매회원의 서비스 이용을 중단하거나 이 계약을 해지할 수 있습니다.<br/>
                    <br/>
                    9. 구매회원이 본인이 작성한 문의글 또는 리뷰를 삭제하는 경우 판매회원이 해당 글에 대해 작성한 답변글도 삭제될 수 있습니다. 단, 관계법령에 따라 삭제된 게시글은 작성일로부터 3년간 보관됩니다.<br/>
                    <br/>
                    10. 판매회원이 상품 등록 후 1년 이상 거래내역이 없는 상품의 경우 회사는 별도 통지 없이 상품 검색의 효율성 제고 및 구매회원 편의 개선을 위해 해당 상품을 삭제하거나 판매 중단 처리할 수 있습니다.<br/>
                    <br/>
                    11. 판매회원은 회사가 판매자센터 등을 통해 게시 및 안내하는 서비스 운영 정책 및 공지사항 등을 정확히 숙지하여야 하며, 이를 확인하지 않거나 오인하여 발생하는 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.<br/>
                    <br/>
                </p>
            <h2>제18조 (매매부적합상품)</h2>
                <p>
                    1. 회사는 등록된 물품이 본 조 3항에 따른 매매부적합상품 등 온라인상에서의 매매, 유통에 적합하지 않은 물품인 경우에는 직권 또는 권리주장자의 요청에 따라 해당 물품 등록을 삭제, 취소하거나 중지할 수 있습니다.<br/>
                    <br/>
                    2. 회사는 등록된 물품이 법령 또는 약관에 위반되거나, 공서양속을 저해하거나 기타 탈법행위와 관련되거나 그러한 목적이 있는 경우 또는 회사의 정책상 필요에 의한 경우에는 직권으로 이를 삭제, 취소하거나 중지할 수 있습니다.<br/>
                    <br/>
                    3. 다음 각 호의 매매부적합상품은 판매를 금하며, 매매부적합상품을 판매함에 따른 모든 책임은 해당 상품을 등록한 판매회원이 부담합니다.<br/>
                    3-1) 허위 또는 과장 광고를 통한 상품<br/>
                    3-2) 지식재산권(상표권, 저작권 등) 등 타인의 권리를 침해하는 상품<br/>
                    3-3) 관련 법령에서 유통을 금지하는 음란물 등<br/>
                    3-4) 주류(관련 국세청 고시에 따라 통신판매가 허용되는 전통주 제외), 담배, 도수가 있는 안경, 콘택트렌즈, 선글라스, 군수품, 의약품 등 관련 법령에 의하여 통신판매가 금지되는 상품<br/>
                    3-5) 관계기관으로부터 판매중지 또는 유보 결정(요청)이 있는 상품<br/>
                    3-6) 기타 회사의 정책에 따라 취급이 금지되거나 공서양속에 반하는 상품<br/>
                    <br/>
                </p>
            <h2>제19조 (상품배송)</h2>
                <p>
                    1. 구매회원의 주문에 따른 결제가 완료되면, 회사는 판매회원이 주문 정보를 확인할 수 있도록 조치를 취하고, 판매회원은 당해 주문 정보에 따라 상품이 파손되지 않도록 적절한 포장을 한 후 배송의 증명이나 추적이 가능한 물류대행(택배)업체에 배송을 위탁하여 배송하여야 합니다.<br/>
                    <br/>
                    2. 판매회원은 구매회원의 결제일로부터 2영업일 이내에 상품의 발송을 완료하여야 하고(단, 판매회원이 별도 배송조건을 설정한 상품의 경우 규정에 따름), 판매자센터에 송장번호 등의 발송 관련 정보를 입력하여 발송이 완료되었음을 증명하여야 합니다.<br/>
                    <br/>
                    3. 판매회원이 전항의 기한 내에 발송하지 못할 경우에는 판매자센터 내 발송지연 안내 기능을 이용하여 발송지연사유를 입력해야 하며, 발송지연 안내 처리를 하지 않거나, 발송지연 안내 처리시 입력한 발송예정일을 초과하는 경우 회사는 페널티 부여 등으로 불이익을 줄 수 있고, 구매자 취소 요청 시 해당 주문건은 판매회원의 승인 없이 환불처리 됩니다.<br/>
                    <br/>
                    4. 구매회원의 귀책사유 없이, 판매회원의 배송지 오류 등으로 인하여 구매회원이 상품을 정상적으로 수령하지 못한 경우 또는 상품의 배송과정에서 하자가 발생한 경우, 판매회원은 그에 관한 모든 책임을 부담하여야 합니다.<br/>
                    <br/>
                    5. 회사는 제3자와 업무제휴를 통해 배송상태 조회, 송장출력, 반품택배 네트워킹 서비스 등을 실시할 수 있습니다.<br/>
                    <br/>
                    6. 구매회원의 귀책사유 없이 상품의 배송이 지연되거나 미 배송으로 거래가 취소되는 경우 회사는 판매회원에게 페널티 부여 등으로 불이익을 줄 수 있습니다.<br/>
                    <br/>
                    7. 상품의 배송과 관련하여 구매회원과 분쟁이 발생한 경우 회사는 판매회원에게 배송완료의 증빙을 요청할 수 있으며, 판매회원은 회사의 요청이 있을시 신속히 해당 자료를 제출하여야 합니다.<br/>
                    <br/>
                    8. 판매회원이 전항의 증빙자료를 제출하지 않거나, 허위 배송정보의 입력으로 구매회원 및 회사에의 발생하는 손해 및 제반 문제에 대한 모든 책임은 판매회원에게 있으며, 회사는 판매회원에게 페널티 부여 등으로 불이익을 줄 수 있습니다.<br/>
                    <br/>
                    9. 판매회원은 배송 방식을 배송의 트래킹이 되지 않는 직접전달 등으로 선택하여 발송할 수 있으나 배송의 증명 또는 배송의 추적이 되지 않아 클레임이 발생할 경우, 상품배송에 대한 증빙자료를 제시하여야 합니다.<br/>
                    <br/>
                </p>
            <h2>제20조 (취소, 반품, 교환, 환불)</h2>
                <p>
                    1. 판매회원은 구매회원이 주문한 상품을 공급하기 곤란하다는 것을 알았을 때, 즉시 해당 사실을 구매회원 및 회사에 유선으로 통보하고 구매회원의 동의를 얻은 후 취소를 하여야 하며, 회사가 구매회원의 상품 대금 결제일로부터 3일 이내에 상품대금 환불 및 환불에 필요한 조치를 취할 수 있도록 하여야 합니다.<br/>
                    <br/>
                    2. 발주확인 상태로 결제완료일로부터 2영업일이 경과 되거나 발송 지연처리 시 입력한 배송 예정일을 경과하거나 판매회원이 별도 배송조건을 준수하지 않아 구매회원이 주문을 취소하는 경우 해당 주문 건은 환불처리 됩니다. 단, 배송중인 경우에는 취소가 아닌 반품절차에 의합니다.<br/>
                    <br/>
                    3. 판매회원은 반품상품 수령일로부터 2영업일 이내에 환불 또는 환불에 필요한 조치를 취하여야 합니다. 만약 판매회원이 2영업일 내에 아무런 조치를 취하지 않은 주문건은 환불 될 수 있습니다.<br/>
                    <br/>
                    4. 판매회원은 단순 변심에 의한 반품 등 구매회원에게 반품 배송비를 청구해야 하는 경우 판매자 센터의 환불보류를 통하여 반품처리를 유보할 수 있습니다.<br/>
                    <br/>
                    5. 판매회원은 상품하자나 오배송 등 구매회원의 귀책사유없는 반품 또는 교환의 경우 구매회원에게 해당 배송비를 부담시켜서는 안되며, 반품 배송비를 선 결제한 구매회원이 반품상품 발송 시 추가 부담한 착불 배송료는 구매회원의 요청에 따라 반환하여야 합니다.<br/>
                    <br/>
                    6. 구매회원은 상품 배송완료일로부터 24시간 이내에 반품요청을 할 수 있으며 판매회원은 구매회원이 이 기간 내 반품이나 교환을 요청하는 경우 구매회원의 요청에 따라 반품 또는 교환을 해 주어야 합니다. 단, 구매회원의 귀책사유로 상품이 훼손된 경우, 사용이나 일부 소비로 인해 상품의 가치가 현저히 감소한 경우, 복제 가능한 상품의 포장을 훼손한 경우, 구매회원의 주문에 따라 개별적으로 생산되는 상품 등으로서 상품 판매시 반품이나 교환의 제한을 명시적으로 고지하고 구매회원의 동의를 받은 경우 및 기타 법령에 의하여 반품이 제한되어 있는 경우는 예외로 합니다. 또한 상품이 표시 또는 광고 내용과 다를 경우에는 상품수령 후 90일 이내 또는 그 사실을 알게 된 날 또는 알 수 있었던 날로부터 30일 이내에 구매회원이 반품 또는 교환을 요청하는 경우, 판매회원은 반품 또는 교환을 해주어야 합니다.<br/>
                    <br/>
                    7. 판매회원은 상품Q&A이나 판매자센터, 전화 등을 통한 반품신청을 수시로 확인하여 필요한 조치를 취하여야 합니다. 상품Q&A 또는 판매자센터를 통한 반품신청을 확인하지 못한 것은 판매회원의 과실로 인정하고, 판매회원에게 사전협의를 하지 않은 것을 사유로 반품을 거부하여서는 아니 됩니다.<br/>
                    <br/>
                    8. 판매회원이 전자상거래등에서의소비자보호에관한법률(이하 ‘전자상거래법’)이 정하는 범위에 따라 구매회원의 청약철회를 제한하고자 하는 경우, 상품페이지 등 구매회원이 쉽게 알 수 있는 곳에 반품제한 사유를 게재하여야 합니다.<br/>
                    <br/>
                    9. 판매회원은 주문건에 대한 취소 및 반품 처리시 정확한 사유를 입력하여야 하며, 이와 관련된 클레임 발생 시 판매회원에게 페널티 부여 등의 불이익이 발생될 수 있습니다.<br/>
                    <br/>
                    10. 구매회원이 상품 수령 후 교환이나 환불을 요청하는 경우 판매회원은 관련 법률에 의거하여 반품을 받은 후 교환이나 환불 또는 환불에 필요한 조치를 하며, 추가로 발생하는 비용은 교환이나 환불의 책임이 있는 측에서 부담합니다.<br/>
                    <br/>
                    11. 판매회원은 상품의 하자 또는 사용상의 안전성에 결함이 있는 경우 전량 리콜(수리, 교환, 환불)하여야 하며, 리콜에 따른 모든 비용을 부담하여야 합니다.<br/>
                    <br/>
                    12. 구매회원이 주문취소, 교환∙반품 신청 등을 했음에도 불구하고 판매회원의 택배사 미설정 또는 오설정 등의 귀책사유로 인해 상품 수거가 원활히 이루어지지 않는 경우, 회사가 지정하는 택배사가 반품∙교환 택배사로 설정될 수 있습니다. 단, 판매회원은 언제든지 판매자센터를 통해 반품∙교환 택배사를 재변경할 수 있습니다.<br/>
                    <br/>
                    13. 판매회원은 상품 주문일로부터 90일 이내 해당 주문건의 원활한 배송 및 취소/반품/교환 처리가 종료될 수 있도록 필요한 조치를 취하여야 합니다. 만약, 주문일로부터 90일 이내 주문이 정상적으로 종료되지 못한 경우 회사는 거래관계의 확정을 위하여 판매회원의 의사에 명시적으로 반하지 않는 한 해당 주문을 임의로 구매확정 내지 주문 취소 및 환불 처리할 수 있으며, 처리 15일전 관련 내용을 판매회원에게 통보하여야 합니다. 만약, 판매회원이 이를 원치 않을 경우 이의를 제기할 수 있습니다.<br/>
                    <br/>
                    14. 회사의 제14항의 처리로 인해 판매회원에게 정산된 주문건에 대해 구매회원이 민원을 제기할 경우 판매회원은 신의성실의 원칙에 따라 해결하여야 합니다.<br/>
                    <br/>
                </p>
            <h2>제21조 (패널티 및 자격 정지)</h2>
                <p>
                    1. 회사는 서비스를 통한 거래의 안전성과 신뢰성을 제고하기 위하여 패널티 규정에서 정한 바에 따라 판매회원의 회원자격을 정지하거나 서비스의 이용을 제한 할 수 있습니다.<br/>
                    1-1) 페널티에 따른 회원 자격정지 및 불이익 제공<br/>
                    1-1-1) 회사는 판매회원의 거래를 평가하여 판매회원에게 페널티를 부여합니다. 회사는 판매회원에 대해 회사가 미리 고지하는 일정기간의 판매실적과 판매거부 내역, 배송, 반품, 민원 처리지연 등에 기초한 페널티를 부여하며 판매회원의 페널티에 따라 일정한 혜택(또는 불이익)을 제공하거나 회원자격을 정지할 수 있습니다.<br/>
                    1-1-2) 회사는 판매회원이 페널티에 대한 상세 내용을 확인할 수 있도록 운영정책을 통해 상세 내용을 안내합니다.<br/>
                    1-2) 부정거래 또는 매매불가상품등록 등 이용규칙위반 또는 기타 위법, 부당행위에 따른 이용정지<br/>
                    <br/>
                    2. 회사는 판매회원이 승낙거부 사유가 있음이 확인되었거나, 본 약관, 회사의 운영정책 및 관련법령을 위반하거나, 타인의 권리를 침해하는 행위 또는 그러한 위법•부당한 행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우 판매회원의 회원자격을 정지하거나 서비스의 이용을 제한할 수 있습니다.<br/>
                    <br/>
                    3. 판매회원에 대한 자격정지 및 서비스 이용제한의 구체적인 기준 등에 대해서는 운영정책에서 정합니다.<br/>
                    <br/>
                </p>
            <h2>제22조 (정산 및 정산보류)</h2>
                <p>
                    1. 서비스를 통해 판매된 상품이 구매확정되는 경우, 회사의 정산일정 및 정책에 따라 판매대금에서 서비스이용수수료, 미납금 및 기타 채무금을 공제한 나머지 금액(이하, ‘정산대금’이라 함)을 판매회원에게 지급합니다.<br/>
                    <br/>
                    2. 판매회원은 회원가입 시점에 정산대금의 수령방법은 계좌입금이며, 수령방법으로 선택한 경우 판매회원이 지정한 입금계좌의 예금주는 판매회원과 동일인임을 원칙으로 합니다. 판매회원의 탈퇴 이후에 발생한 정산대금은 판매회원이 탈퇴하기 전 최종적으로 지정한 입금계좌로 지급됩니다.<br/>
                    <br/>
                    3. 판매회원이 배송하고 배송완료일(또는 서비스 이행)일로부터 7일(7일 중 영업일 2일 미만인 경우 2영업일)이내 구매회원으로부터 교환, 반품, 환불에 대한 의사가 없는 경우 회사는 회사의 정책에 따른 구매확정 절차를 진행할 수 있으며, 구매확정 절차의 구체적인 사항은 ‘판매자센터’를 통해 공지합니다. 다만, “구매회원”의 이의 제기 등 민원 등이 접수되는 경우 구매확정이 되지 않을 수 있습니다.<br/>
                    <br/>
                    4. 판매회원이 배송을 완료하였음에도 구매회원이 반품, 환불 등의 의사표시 없이 구매확정을 지연하는 경우, 판매회원은 배송처리일로부터 7일 이후부터 구매확정을 요청할 수 있습니다. 다만, “구매회원”의 이의 제기 등 민원 등이 접수되는 경우 구매확정이 되지 않을 수 있습니다.<br/>
                    <br/>
                    5. 회사는 세금계산서 발행 및 정산대금을 지급하기 위하여 주민등록번호(외국인인 경우 외국인등록번호. 이하, ‘주민등록번호등’이라 함)의 수집 및 이용 등에 대한 동의를 요청할 수 있으며, 판매회원이 세금계산서 발행등을 위한 주민등록번호등의 수집 및 이용 등에 동의하지 않은 경우 동의 시점까지 관련신청을 제한할 수 있습니다.<br/>
                    <br/>
                    6. 판매회원의 귀책사유로 인해 회사 및 구매회원에게 발생한 손해 등의 제비용을 판매대금 정산 시 공제할 수 있으며, 판매회원과의 이용계약 종료 후 당해 판매회원의 판매대금의 일정 비율에 해당하는 금액을 이용계약 종료일로부터 일정기간 동안 예치하여 동 기간 동안 구매회원으로부터의 환불, 교환 등 클레임 제기 시 관련 비용의 지급에 사용할 수 있습니다.<br/>
                    <br/>
                    7. 판매회원의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을 경우, 파이낸셜은 판매회원과 채권자 간의 합의 또는 채무 액의 변제 등으로 동 결정이 해제될 때까지 판매대금의 정산을 중지할 수 있습니다.<br/>
                    <br/>
                    8. 판매회원이 본 약관의 규정을 위반하거나 위반한 것으로 의심될 만한 타당한 정황이 있는 경우, 회사는 거래에 대한 처리, 취소 등을 위해 판매대금의 정산을 일정기간 보류 할 수 있습니다.<br/>
                    <br/>
                    9. 본 조에 정한 외에도 법률의 규정에 의하거나 자금세탁방지 관련 의무 미준수 등 합리적인 사유가 있는 경우에는 판매회원에게 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보하거나 회사가 판매회원에게 가지는 채권과 상계할 수 있습니다.<br/>
                    <br/>
                </p>
            <h2>제23조 (적립금, 할인쿠폰)</h2>
                <p>
                    1. 회사 또는 판매자는 아래 각 호의 방법으로 회사가 정한 절차 및 개별 약관 등에 따라 적립금 쿠폰을 발행할 수 있습니다.<br/>
                    1-1) 회사가 단독으로 발행<br/>
                    1-2) 판매회원이 단독으로 발행<br/>
                    1-3) 회사와 판매회원이 공동으로 발행<br/>
                    <br/>
                    2. 회사는 회사의 운영정책에 위반되는 적립금, 쿠폰 발행을 제한할 수 있습니다.<br/>
                    <br/>
                    3. 판매회원의 요청에 의해 발행된 적립금, 쿠폰의 경우 발행일 이후에는 발행 조건의 변경 또는 발행 취소를 요청할 수 없습니다.<br/>
                    <br/>
                    4. 회사는 구매회원이 사용한 적립금 또는 쿠폰 할인금액을 산정하여 해당거래의 정산 시 판매회원에게 지급합니다. 다만, 회사는 본 조 제1항에 따라 판매회원이 지급할 비용이 있는 경우 이를 제외하고 지급할 수 있습니다.<br/>
                    <br/>
                    5. 판매회원이 회사에서 인정하지 않는 부당, 부정 또는 불법적인 방법으로 적립금 또는 쿠폰이 사용됨을 알면서도 거래를 승인하는 경우, 회사는 해당 거래에 대한 정산금액을 판매회원에게 지급하지 않을 수 있습니다.<br/>
                    <br/>
                    6 회사는 적립금, 쿠폰의 발행을 신청 또는 참여한 판매회원의 경우 구매회원에게 부여된 할인금액 중 상호합의된 판매회원 부담 비용을 산정하여 판매회원의 정산금액에서 공제할 수 있습니다.<br/>
                    <br/>
                    7. 회사는 구매회원이 채널 서비스에서 판매회원의 상품 구매를 위해 적립금, 쿠폰 등을 사용하는 경우 판매회원에게 회사가 판매자센터를 통해 공지한 사용수수료를 판매회원의 정산금액에서 공제할 수 있습니다.<br/>
                    <br/>
                    8. 판매회원은 판매자센터를 통하여 정산 비용을 확인할 수 있습니다.<br/>
                    <br/>
                    9. 회사는 판매회원이 본 조에 따른 적립금, 쿠폰 발행 비용 등을 지급하지 않는 경우, 경고, 이용정지, 계약해지 등 제재 조치를 취할 수 있습니다.<br/>
                    <br/>
                    10. 본 조에 적립금, 쿠폰 의 발행 또는 사용 및 정산에 관련한 구체적인 사항은 개별약관 및 회사의 운영정책에 따릅니다.<br/>
                    <br/>
                </p>
            <h2>제24조 (개인정보보호 및 비밀유지)</h2>
                <p>
                    1. 개인정보보호에 관한 사항은 '회사'의 '개인정보처리방침'에 따릅니다.<br/>
                    <br/>
                    2. 판매회원은 구매회원정보 등 서비스 이용과 관련하여 취득한 개인정보보호법상 개인정보, 회사의 기술 및 사업정보, 생산 및 판매 계획, 노하우 등 부정경쟁방지법상 비밀로 관리되는 정보를 회사의 서면 동의 없이 외부에 유출하거나 이 약관 이외의 목적으로 사용할 수 없습니다. <br/>
                    <br/>
                    3. 전항의 의무는 이용계약의 종료 후에도 존속합니다.<br/>
                    <br/>
                </p>
            <h2>제25조 (손해배상)</h2>
                <p>
                    회사 또는 판매회원의 명백한 귀책 사유로 이 약관을 위반하여 상대방 또는 다른 회원에게 손해를 발생하게 한 때에는 귀책 당사자는 이를 배상할 책임을 부담합니다.<br/>
                    <br/>
                </p>
            <h2>제26조 (회사의 의무)</h2>
                <p>
                    1. '회사'는 관계법령, 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 약관이 정하는 바에 따라 지속적으로 재화 및 용역을 제공하는데 최선을 다하여야합니다.<br/>
                    <br/>
                    2. '회사'는 '이용자'가 안전하게 인터넷 서비스를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.<br/>
                    <br/>
                    3. '회사'가 상품에 대하여 '표시 및 광고의 공정화에 관한 법률' 제3조 소정의 부당한 표시·광고행위를 하여 '이용자'가 손해를 입은 때에는 이를 배상할 책임을 집니다.<br/>
                    <br/>
                </p>
            <h2>제27조 (회사의 면책)</h2>
                <p>
                    1. 회사는 구매회원과 판매회원간의 통신판매를 위한 거래시스템만을 제공할 뿐, 판매회원이 등록한 상품 및 용역 등에 관한 정보 또는 구매회원과의 거래에 관하여 분쟁이 발생한 경우 회사는 그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 판매회원이 부담합니다. 또한 제3자가 회사를 상대로 민•형사상 등의 문제를 제기하는 경우 판매회원은 해당 문제해결을 위해 적극 협조하여야 하며, 이와 관련하여 회사에 손해가 발생한 경우 손해를 배상합니다. 단, 회사의 고의 또는 중과실로 인하여 발생한 손해에 대해서는 회사가 그 책임을 부담합니다. 단, 회사는 분쟁의 합리적이고 원활한 조정을 위하여 회사가 설치 운영하는 고객센터 를 통하여 예외적으로 당해 분쟁에 개입할 수 있으며, 판매회원은 고객센터의 결정을 신의칙에 입각하여 최대한 존중해야 합니다.<br/>
                    <br/>
                    2. 회사는 적법한 권리자의 요구가 있는 경우에는 당해 상품 및 용역 등에 관한 정보를 삭제하거나 수정할 수 있으며, 판매회원은 이로 인한 손해배상을 회사에 청구할 수 없습니다.<br/>
                    <br/>
                    3. 회사는 관련법령에 의거하여 판매회원의 정보를 열람할 수 있는 방법을 구매회원에게 제공할 수 있으며, 판매회원은 당해 정보를 기재하지 아니하거나, 허위로 기재함으로써 발생하는 모든 책임을 부담하여야 합니다.<br/>
                    <br/>
                    4. 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 판매서비스의 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.<br/>
                    <br/>
                    5. 판매회원은 자신의 개인정보 또는 판매자센터 로그인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 책임을 지지 않습니다.<br/>
                    <br/>
                    6. 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별약관의 변경, 판매회원 공지사항 등의 확인의무를 게을리하여 발생한 판매회원의 피해에 대해서 회사는 책임을 지지 않습니다.<br/>
                    <br/>
                </p>
            <h2>제28조 (분쟁의 해결)</h2>
                <p>
                    1. '회사'는 '이용자'가 제기하는 불만사항 및 의견을 지체없이 처리하기 위하여 노력합니다. 다만 신속한 처리가 곤란한 경우 '이용자'에게 그 사유와 처리일정을 즉시 통보합니다.<br/>
                    <br/>
                    2. '회사'와 '이용자'간 전자상거래에 관한 분쟁이 발생한 경우, '이용자'는 한국소비자원, 전자거래분쟁조정위원회 등 분쟁조정기관에 조정을 신청할 수 있습니다.<br/>
                    <br/>
                    3. '회사'와 '이용자'간 발생한 분쟁에 관한 소송은 '회사' 소재지를 관할하는 법원을 제1심 관할법원으로 하며, 준거법은 대한민국의 법령을 적용합니다.<br/>
                    <br/>
                </p>
            <h2>제29조 (본 약관의 적용)</h2>
                <p>
                본 약관은 별도의 정함이 없는 이상 회사의 다른 이용약관에 우선하여 적용됩니다.<br/>
                    <br/>
                </p>
            <h2>① 이 약관은 2023년 7월 12일 부터 적용됩니다.</h2>
        </Container>
    )
}