import { useQuery , useQueryClient , prefetchQuery  } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

// 고객 > 게시판 > 공지사항 리스트 조회
const fetchNoticeList = (page, pageSize) => {
  return instance.get(`notice/document/?hidden=false&page=${page}&page_size=${pageSize}`)
    .then(res => res.data);
};
  
export const useNoticeListQuery = () => {
  
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);

  const [pageSize , setPageSize] = useState(10);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    if (data && data.nextPage) {
      const nextPage = page + 1;
      queryClient.prefetchQuery([`notice`, page , nextPage],
        () => fetchNoticeList(nextPage)
      );
    }
  },[page , queryClient])


  const { data, error, isFetching } = useQuery(
    ['notice', page , pageSize], () => fetchNoticeList(page , pageSize),
    {
      staleTime:5000,
      keepPreviousData:true
    },
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};


// 관리자 > 게시판 > 자주묻는질문 리스트 조회
const fetchFaqList = (selectTab, page) => {
  let url = '';
  if (selectTab === '전체') {
      url = `faq/document/?hidden=false&page=${page}`;
  } else {
      url = `faq/document/?menu=${selectTab}&hidden=false&page=${page}`;
  }
  return instance.get(url).then(res => res.data);
};

  
export const useFaqListQuery = (selectTab) => {
    const [page, setPage] = useState(1);
    
    const { data, error, isFetching } = useQuery(
      [`faq`, page , selectTab], () => fetchFaqList(selectTab,page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
    );

    return {
        data,
        error,
        isFetching,
        page,
        setPage
    };
};
