import { useNavigate } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'; 

function Sidemenu({nowPage}){
    const navigate = useNavigate();

    let menuClassName = { //Active 메뉴 스타일 적용
        active: 'fs-6 fw-bold m-0 p-2 pb-3 text-hw d-flex justify-content-between align-items-center pointer',
        default: 'fs-6 fw-bolder m-0 p-2 pb-3 text-grey d-flex justify-content-between align-items-center pointer'
    }

    return(
        <div className="mypage_side m-0 py-2 px-3">
            <Container className='m-0 p-2'>
                <h3 className='pagetit pointer' onClick={()=>navigate('/customer/notice')}>고객센터</h3>
                <Row className='m-0 sideMu'>
                    <Col xs={12} className={nowPage === 'notice' ? menuClassName.active : menuClassName.default} onClick={()=>navigate('/customer/notice')}>공지사항</Col>
                    <Col xs={12} className={nowPage === 'faq' ? menuClassName.active : menuClassName.default} onClick={()=>navigate('/customer/faq')}>자주묻는질문</Col>
                    <Col xs={12} className={nowPage === 'qna' ? menuClassName.active : menuClassName.default} onClick={()=>navigate('/customer/qna')}>상품 Q&A</Col>
                    {/* <Col xs={12} className={nowPage === 'oto' ? menuClassName.active : menuClassName.default} onClick={()=>navigate('/customer/oto')}>1:1 문의</Col> */}
                </Row>
                <Row className='m-0 mt-3 px-3 py-4 bg-grey rounded-2'>
                    <Col xs={12} className='fs-6 fw-bold'>목포해와<br/>고객센터입니다.</Col>
                    <Col xs={12} className='fs-5 text-hw fw-800 py-3 line-height-15'>070<span className='fs-6 fw-bold'>-</span>4249<span className='fs-6 fw-bold'>-</span>0302</Col>
                    <hr/>
                    <Col xs={12} className='fs-7 fw-bolder'>평일 09:00 ~ 18:00</Col>
                    <Col xs={12} className='fs-7 fw-bolder'>점심시간 11:50 ~ 13:00</Col>
                    <Col xs={12} className='fs-7 fw-bolder'>주말 및 공휴일 휴무입니다.</Col>
                </Row>
            </Container>
        </div>
    )
}

export default Sidemenu