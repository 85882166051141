import { useQuery , useQueryClient , prefetchQuery  } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

// 관리자 > 게시판 > 공지사항 리스트 조회
const fetchNoticeList = (page, pageSize) => {
  return instance.get(`notice/document/?page=${page}&page_size=${pageSize}`)
    .then(res => res.data);
};
  
export const useNoticeListQuery = () => {
  
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);

  const [pageSize , setPageSize] = useState(10);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    if (data && data.nextPage) {
      const nextPage = page + 1;
      queryClient.prefetchQuery([`notice`, page , nextPage],
        () => fetchNoticeList(nextPage)
      );
    }
  },[page , queryClient])


  const { data, error, isFetching } = useQuery(
    ['notice', page , pageSize], () => fetchNoticeList(page , pageSize),
    {
      staleTime:5000,
      keepPreviousData:true
    },
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};


// 관리자 > 게시판 > 자주묻는질문 리스트 조회
const fetchFaqList = (page) => {
    const url = `faq/document/?page=${page}`;
    return instance.get(url).then(res => res.data);
};
  
export const useFaqListQuery = () => {
    const [page, setPage] = useState(1);


    const { data, error, isFetching } = useQuery(
      [`faq`, page ], () => fetchFaqList(page),
      {
        staleTime:5000,
        keepPreviousData:true
      },
    );

    return {
        data,
        error,
        isFetching,
        page,
        setPage
    };
};

// 관리자 > 게시판 > 공지사항 등록 
export const RegisterNotice = (data) =>
  instance.post(`notice/`, data ).then(response => response.data);

  // 선택한 공지사항 수정
export const updateNotice = (id, data) =>
instance.patch(`notice/${id}`, data).then(response => response.data);



// 선택한 공지사항 정보 조회
export const getNotice = async (id) => {
  try {
    const response = await instance.get(`notice/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// export function useNoticeRegistration() {
//     const [isPostLoading, setIsPostLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const post = async (data) => {
//       setIsPostLoading(true);
//       setError(null);
  
//       try {
//         const response = await instance.post(`notice/`,data);
//         return response.data;
//       } catch (error) {
//         setError("Failed to post");
//         throw new Error("Failed to post");
//       } finally {
//         setIsPostLoading(false);
//       }
//     };
  
//     return {
//       post,
//       isPostLoading,
//       error,
//     };
//   }

  
// 관리자 > 공지사항 검색
export const handleNoticeSearch  = async ({
  searchAuth, 
  setSearchResults,
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  selectedCategory, 
  visibility,
  ordering,

  }) => {
  try {
    let url = `notice/document/`;

    if (searchAuth) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchAuth}`;
          break;
        case 'title':
          url += `?title=${searchAuth}`;
          break;
        case 'name':
          url += `?user=${searchAuth}`;
          break;
  
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'created':
          url += `&created_at__range=${selectedStartDate}__${selectedEndDate}`;
          break;
    
        default:
          break;
      }
    }

  
    if (selectedCategory) url += `&type=${selectedCategory}` 
    if (visibility) url += `&hidden=${visibility}`

    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 관리자 > faq 검색
export const handleFaqSearch = async ({
  searchAuth, 
  setSearchResults,
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  selectedCategory, 
  visibility,
  ordering,

  }) => {
  try {
    let url = `faq/document/`;

    if (searchAuth) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchAuth}`;
          break;
        case 'title':
          url += `?title=${searchAuth}`;
          break;
        case 'name':
          url += `?writer=${searchAuth}`;
          break;
  
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'created':
          url += `&d_at__range=${selectedStartDate}__${selectedEndDate}`;
          break;
    
        default:
          break;
      }
    }

  
    if (selectedCategory) url += `&menu=${selectedCategory}` 
    if (visibility) url += `&hidden=${visibility}`

    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 관리자 > 게시판 > faq 등록 
export const RegistrationFaq = (data) =>
  instance.post(`faq/`, data).then(response => response.data);

// 선택한 faq 정보 조회
export const getFaq = async (id) => {
  try {
    const response = await instance.get(`faq/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 선택한 faq 수정
export const updateFaq = (id, data) =>
  instance.patch(`faq/${id}`, data).then(response => response.data);



// 관리자 > 게시판 > 공지사항 > 공개전환 , 
export function updateNoticeStatusData(id, state) {
  const newData = { hidden : state };

  return instance
    .patch(`/notice/${id}`, newData)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}


// 공지글 공개/비공개 처리
export const updateNoticeHidden = (pk, hidden)=>{
  return instance.patch(`/notice/${pk}/`,
  {
      hidden: hidden
  })
}

// 공지글 삭제
export const deleteNotice = (pk) => instance.delete(`/notice/${pk}/`)

// 해와관리자 > 게시판 > 상품후기 조회
const fetchProductReview = (page, pageSize ,ordering) => {
  return instance.get(`product/review-document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useProductReviewListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);
  
  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['productReviews', page, pageSize, ordering], () => fetchProductReview(page, pageSize , ordering), {
    onSuccess: (data) => {
      return data;
    }
  });

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};



// 해와관리자 > 게시판 > 상품후기 검색
export const handleProductReviewSearch = async ({
  searchReview, 
  setSearchResults,
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  isImage,
  ordering,
  checkedItems
  }) => {
  try {
    let url = `product/review-document/`;

    if (searchReview) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchReview}`;
          break;
        case 'title':
          url += `?title=${searchReview}`;
          break;
        case 'name':
          url += `?writer=${searchReview}`;
          break;
  
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'created':
          url += `&created_at__range=${selectedStartDate}__${selectedEndDate}`;
          break;
    
        default:
          break;
      }
    }

    // 평점 검색
    if(checkedItems){
      const resultString = checkedItems.map((num, index) => `${num}${index < checkedItems.length - 1 ? '__' : ''}`).join('');
      url += `&rating__in=${resultString}`;
    }

  
    if (isImage) url += `&review_image_status=${isImage}`

    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 자주묻는질문 공개/비공개 처리
export const updateFaqHidden = (pk, hidden)=>{
  return instance.patch(`/faq/${pk}/`,
  {
      hidden: hidden
  })
}
