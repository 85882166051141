/***************************************************************************************
* FileName      : EditNotice.js
* Description   : 해와관리자 공지사항 수정 페이지
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.01.01
* Modifide Date : 2024.01.17 : CHM : 에디터 컴포넌트 적용
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { updateNotice , getNotice } from '../../../../api/hwadmin/boardApi';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation , useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import Editor from '../../../components/Editor';

function EditNotice()
{

    const { id } = useParams();

    const { register, handleSubmit, formState: { errors } , reset , watch , setValue } = useForm();

    const navigate = useNavigate(); 
    
    // 데이터 조회
    const { data } = useQuery(['notice',id], () => getNotice(id));


    console.log(data);

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
            <div className="spinner" ></div>
            </div>
        );
    };

    // 글제목 길이제한
    const [TitleLength, setTitleLength] = useState('');


    const [description, setDescription] = useState('');

    const handleTitleChange = (e) => {
        const title = e.target.value;
        setTitleLength(title.length);
    
        if (title.length > 100) {
          alert('제목은 100자를 초과할 수 없습니다.');
          e.target.value = title.slice(0, 100);
          setTitleLength(100);
        }
    };

    // 공개 비공개 선택 
    const [hiddenSelected, setHiddenSelected] = useState('false');

    const handleOptionChange = (e) => {
        setHiddenSelected(e.target.value);
    };
    
    // 열기 닫기 토글
    const [toggle , setToggle] = useState(
        {
            noticeTitle:true,
            select:true,
            hidden:true,
            editor:true, 
        }
    )
    
    const toggleState = (event) =>{
        const newTabState = {...toggle};
        const activeTab = event.currentTarget.id;

        setToggle((prevState) => ({
            ...prevState,
            [activeTab]: !newTabState[activeTab]
        }));
    }

    useEffect(() => {
        if (data) {
            setValue('title', data.title);
            setValue('type', data.type);
            setHiddenSelected(data.hidden.toString());
            setDescription(data.description);
        }
    }, [data]);

    const updateNoticeMutaion = useMutation((data) => updateNotice(id, data), {
        onSuccess : (data) => {
            reset();
            alert("수정 완료");
            setIsLoading(false);
            window.location.href = '/admin/board/notice';
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    });

    const onSubmit = (data) => {
        console.log(data);
        setIsLoading(true);
        if(description === ''){
            alert('내용을 입력해주세요')
            return;
        }

        const newData = {
            title: data.title || "",
            description: description || "",
            hidden : hiddenSelected || "",
            type : data.type || "",
        };

        updateNoticeMutaion.mutate(newData);
    };

            
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>공지사항 수정</h2>
            </div>
        
            {/* 수정 */}           
            <section className='product-register-wrap'>
                <div className='product-reguster-inner'>
                    <div className='accordion'>
                        <form onSubmit={handleSubmit(onSubmit)}>    
                            <div className='accordion-item'>
                                <div className='title' id='select' onClick={toggleState}>
                                    <p>분류<span>*</span></p>
                                    <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>

                                <div className={toggle.select ? 'content-show' : 'content'} >  
                                    <select className='mSelectBox' 
                                            name="noticeCategory" 
                                            {...register('type', { required: '분류 선택은 필수입니다.' })}> 

                                        <option value="이벤트" selected="selected">이벤트</option>
                                        <option value="공지">공지</option>
                                        <option value="긴급공지">긴급공지</option>
                                    </select>
                                </div>
                            </div>
                            
                  
                            <div className='accordion-item'>
                                <div className='title' id='noticeTitle'>
                                    <p>글제목 <span>*</span></p>
                                    <i className={toggle.noticeTitle ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>

                                <div className={toggle.noticeTitle ? 'content-show' : 'content'} >  
                                    <input type="text" 
                                            name="name"
                                            className='form-input'
                                            placeholder='공지글 제목을 입력해 주세요'
                                            {...register('title', { required: '제목 입력은 필수입니다.' })} 
                                            onChange={handleTitleChange}
                                            />

                                        {errors.title 
                                        && <p className='error_message ml-1'>
                                            {errors.title.message}
                                        </p>
                                    }

                                    <span className='productNameL'>{TitleLength}</span>
                                    <span>/100</span>
                                </div>
                            </div>

                            <div className='accordion-item'>
                                <div className='title' id='hidden'>
                                    <p>공개 / 비공개 <span>*</span></p>
                                    <i className={toggle.hidden ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>

                                <div className={toggle.hidden ? 'content-show' : 'content'} >  
                                    <div className="radio-button-container">
                                        <label>
                                            <input
                                            type="radio"
                                            value="false"
                                            checked={hiddenSelected === 'false'}
                                            onChange={handleOptionChange}
                                            />
                                            공개
                                        </label>
                                        <label>
                                            <input
                                            type="radio"
                                            value="true"
                                            checked={hiddenSelected === 'true'}
                                            onChange={handleOptionChange}
                                            />
                                            비공개
                                        </label>
                                    </div>    
                                </div>
                            </div>

                            <div className='accordion-item'>
                                <Editor 
                                    content={description}
                                    setContent={setDescription} />
                            </div>


                            <div className='m-btn-wrap'>
                                <button onClick={handleSubmit} className='m-btn m-btn-search'>
                                    {isLoading ?  <LoadingSpinner /> : '작성완료'} 
                                </button>
                            </div> 
                        </form>
                    </div>
                </div>
            </section>

        

        </div>
        </>
    )

        
}

export default EditNotice