import React from 'react';

const SearchField = ({ selectedPayment, handleCategoryTypeRadioChange }) => {
    return (
        <div className='search-field division'>
            <div>
                <b>단위<br/>(결제완료기준)</b>
            </div>
            <div className='SearchRadio'>
                <input
                    type='radio'
                    id='g1'
                    name='group1'
                    value='판매금액(원)'
                    checked={selectedPayment === '판매금액(원)'}
                    onChange={handleCategoryTypeRadioChange}
                />
                <label htmlFor='g1'>판매금액(원)</label>

                <input
                    type='radio'
                    id='g2'
                    name='group1'
                    value='주문건수(건)'
                    checked={selectedPayment === '주문건수(건)'}
                    onChange={handleCategoryTypeRadioChange}
                />
                <label htmlFor='g2'>주문건수(건)</label>
            </div>
        </div>
    );
};

export default SearchField;
