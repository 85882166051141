/***************************************************************************************
* FileName      : SearchPagination.js
* Description   : 검색결과 페이지내비게이션 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React from 'react';
import { useState } from 'react';
import { instance } from '../../../axiosInstance/axiosConfig';

function SearchPagination({ count, url, pageSize, updateSearchResults }) 
{
  const totalPages = Math.ceil(count / pageSize);

  // 검색결과 페이지내비게이션 현재
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick =  async (pageNumber) => {
      setCurrentPage(pageNumber)

      const fetchUrl = `${url}&page=${pageNumber}`;

      try {
        const response = await instance.get(fetchUrl);
        updateSearchResults(response.data);
      } catch (error) {
        console.error(error);
      }
  };


  const renderPageNumbers = () => {
      const pageNumbers = [];
  
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    
      if (totalPages <= 10) {
        return pageNumbers.map((pg) => (
          <li
            key={pg}
            className={pg === currentPage ? 'cur' : ''}
            onClick={() => handlePageClick(pg)} 
          >
            {pg}
          </li>
        ));
      } else {
        let displayedPages = [];
        if (currentPage <= 6) {
          displayedPages = [...pageNumbers.slice(0, 7), '...', totalPages];
        } else if (currentPage >= totalPages - 5) {
          displayedPages = [1, '...', ...pageNumbers.slice(totalPages - 7)];
        } else {
          displayedPages = [1, '...', ...pageNumbers.slice(currentPage - 4, currentPage + 2), '...', totalPages];
        }
  
        return displayedPages.map((pg, index) => (
          <li
              key={index}
              className={pg === currentPage ? 'cur' : ''}
              onClick={() => {
                if (pg === '...') return;
                handlePageClick(pg);
              }}
          >
            {pg === '...' ? pg : <span>{pg}</span>}
          </li>
      ));
    }
  };
  
  return (
    <ul className="m-page-nav-btn">
   
      {renderPageNumbers()}
   
    </ul>
  );
}

export default SearchPagination;
