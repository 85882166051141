import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

export const AdjustRow = ({ Adjust }) => {
  return (
    <>
      <>
        <tr>
          <td> <input type="checkbox" /> </td>
          <td>{Adjust.name}</td>
          <td>{Adjust.scheduled_amount.toLocaleString()}원</td>
          <td>{Adjust.complete_amount.toLocaleString()}원</td>
          <td>{Adjust.total_settlement_amount.toLocaleString()}원</td>
          <td>
            {Adjust.last_settlement_date ? 
              extractYearMonthDayHourMinute2(Adjust.last_settlement_date) : "-"}</td>
         </tr>
      </>
    </>
  );
};
