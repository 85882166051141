import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSubSubCategories } from '../../../../api/Category'
import FilteringOptions from './FilteringOptions';

function SubSubCategories({ categoryId }) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data: subsubCategoriesData } = useSubSubCategories(categoryId);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching main categories.</div>;
  }
  function handleSubCategoryClick(category) {
    console.log(category?.id)
    queryClient.setQueryData('selectedId', category?.id);
    queryClient.setQueryData('selectedName', category?.name);
    queryClient.setQueryData('selectedHidden', category?.hidden);

    queryClient.setQueryData('selectedSubSubcategory', category?.name);
    queryClient.setQueryData('selectedfiltering', '');
    queryClient.setQueryData('selectedfilteringOptions', '');
    
    setSelectedCategoryId(category?.id);
  }
  return (
    <>
    <div className='categbox'>
      <p className='ctit'>소분류</p>
      <div type="소분류" className='kwutOX'>
        <ul>
          {subsubCategoriesData?.data !== undefined && subsubCategoriesData?.data.map((category) => (
            <li className='fBSFej' key={category?.id}>
              <button 
                onClick={() => handleSubCategoryClick(category)}
                style={{ color: selectedCategoryId === category?.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                >{category?.name}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
    {selectedCategoryId && (
      <FilteringOptions categoryId={selectedCategoryId} key={selectedCategoryId} />
    )}
    </>
  );
}

export default SubSubCategories;