/***************************************************************************************
* FileName      : DeliveryList.js
* Description   : 셀러관리자 > 주문배송 > 배송현황 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useDeliveryStatusQuery , handleDeliverySearch } from '../../../api/selleradmin/orderAPI';

import React, { useState , useEffect } from 'react';

import DeliveryStatusView  from "./DeliveryStatus/DeliveryStatusView";

function DeliveryList()
{
    
    // 현황 데이터
    const { data, error, isFetching} =  useDeliveryStatusQuery();
    
    const [isChecked, setIsChecked] = useState(false);

    const [selectedStatus , setSelectStatus] = useState('');

    const [deliveryData, setDeliveryData] = useState(null); 

    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
    
    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    // 택배사 
    const [courier, setCourier] = useState('전체');

    const [isOpen, setIsOpen] = useState(false);


    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
    const handleButtonClick = (status) => {
        setSelectStatus(status);
    };


    const handleCourierChange = (e) => {
        const newCourier = e.target.value;        
        setCourier(newCourier);
    };
    
    // 초기화
    const handleResetClick = () => {
        window.location.href = '/selleradmin/orderDelivery/deliveryList';
    };

    const openModal = () => {
        console.log("cccc");
        setIsOpen(true);
    }
  
    const closeModal = () => {
      setIsOpen(false);
    }


    const onSearch = () => {
        handleDeliverySearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate, 
            selectedEndDate,
            selectedStatus,
            courier,
        });
        
        setSearchConfirm(true);
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>배송현황</h2>
            </div>
         
            {/* 배송 현황 */}
            <section className='state-wrap'>
               <div className={`state-box ${selectedStatus === "" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("")}>
                    <p className='tit'>전체</p>
                    {data ? (
                        <p className='dec'>{data.total_count && data.total_count}</p>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
 
                <div className={`state-box ${selectedStatus === "출고준비중" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("출고준비중")}>
                    <p className='tit'>출고준비중</p>
                    {data ? (
                        <p className='dec'>{data.delivery_ready_count && data.delivery_ready_count}</p>
                    ) : (
                        <p>Loading...</p>
                    )}
       
                </div>
        
                <div className={`state-box ${selectedStatus === "배송중" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("배송중")}>
                    <p className='tit'>배송중</p>
                    {data ? (
                        <p className='dec'>{data.shipping_count && data.shipping_count}</p>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
                <div className={`state-box ${selectedStatus === "배송완료" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("배송완료")}>
                    <p className='tit'>배송완료</p>
                    {data ? (
                        <p className='dec'>{data.shipping_complete_count && data.shipping_complete_count}</p>
                    ) : (
                        <p>Loading...</p>
                    )}
 
                </div>
            </section>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                        <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="OrderNumber">주문번호</option>
                                <option value="ProductName">상품명</option>
                                <option value="ProductNumber">상품번호</option>
                        </select>

                        <input type="text"
                                value={searchOrder}
                                onChange={(e) => setSearchOrder(e.target.value)}/> 

                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="payment">결제완료일</option>
                                <option value="delivery">출고완료일</option>

                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>

                    <div className='search-field word'>
                        <div> 
                            <b>택배사</b> 
                        </div>
                        <div>
                            <select className='product-select' value={courier} onChange={handleCourierChange}>
                                <option>전체</option>
                                <option>CJ대한통운</option>
                                <option>한진택배</option>
                                <option>일양로지스</option>
                                <option>롯데택배</option>
                                <option>경동택배</option>
                                <option>로젠택배</option>
                                <option>KGB택배</option>
                                <option>합동택배</option>
                                <option>GTX로지스택배</option>
                                <option>천일택배</option>
                                <option>우체국택배</option>
                                <option>대신택배</option>
                                <option>편의점택배</option>
                                <option>드림택배</option>
                            </select>
                        </div>   
                    </div>
                </div>
          
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch} >검색</button>
                </div>
            </section>    

            {/* 전체 */}
            {selectedStatus === "" && 
                <DeliveryStatusView 
                    searchConfirm={searchConfirm}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults} 
                    selectedStatus={selectedStatus}/>}
        
            {/* 출고준비중 */}
            {selectedStatus === "출고준비중" && 
                 <DeliveryStatusView 
                    searchConfirm={searchConfirm}
                    searchResults={searchResults} 
                    setSearchResults={setSearchResults}
                    selectedStatus={selectedStatus}/>}


            {/* 배송중 */}
            {selectedStatus === "배송중" && 
               <DeliveryStatusView 
                    searchConfirm={searchConfirm}
                    searchResults={searchResults} 
                    setSearchResults={setSearchResults}
                    selectedStatus={selectedStatus}/>}
     
            {/* 배송완료 */}
            {selectedStatus === "배송완료" && 
                <DeliveryStatusView 
                    searchConfirm={searchConfirm}
                    searchResults={searchResults} 
                    setSearchResults={setSearchResults}
                    selectedStatus={selectedStatus}/>}
                
            </div>

        </>
    )
}

export default DeliveryList