/***************************************************************************************
* FileName      : statusModal.js
* Description   : 해와관리자, 셀러관리자 주문상태 변경 모달
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.24
* Modifide Date : 2024.01.24 : CHM : 운송장 번호 등록 필드 구분(일반배송/에스크로배송/직접배송)
*               : 
* Reference     : 
***************************************************************************************/

import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { updateOrderState, updateEscrowOrderState, updateDirectOrderState, updateEscrowDirectOrderState } from '../../../../api/hwadmin/orderAPI';


export function OrderStateChangerModal({ open, setPopup, selectedRows, selectedRowsData }) {
    // 주문상태 변경 모달
    const handleClose = () => {
        setPopup(false);
    };

    const handleOkSign = () => {
        // 수정된 값 콘솔에 표시
        console.log(orderUpdateData);

        // 이쯤에서 수정 값에 빈 값 있는지 확인 한번 하고
        const invalidFields = Object.keys(orderUpdateData).filter(id => {
            const data = orderUpdateData[id];
            console.log("아이디는 : ", id);
            console.log("데이터는 : ", orderUpdateData[id]);
            return(
                data?.deliveryType !== '' && data?.deliveryType !== undefined ? (
                    data?.deliveryType === '일반택배' ? (
                        data?.useEscrow ? ( 
                            //에스크로 사용
                            data?.deliveryCompany !== '' &&
                             data?.deliveryCompany !== undefined &&
                            data?.trackingNumber !== '' &&
                            data?.trackingNumber !== undefined ? null : '값이 비어있습니다.'
                        ) : ( 
                            //에스크로 미사용
                            data?.deliveryCompany !== '' &&
                            data?.deliveryCompany !== undefined &&
                            data?.trackingNumber !== '' &&
                            data?.trackingNumber !== undefined &&
                            data?.deliveryType !== '' &&
                            data?.deliveryType !== undefined ? null : '값이 비어있습니다.'
                        )
                    ) : (
                        // 직접배송
                        data?.receiveTime !== '' &&
                        data?.receiveTime !== undefined &&
                        data?.receiveName !== '' &&
                        data?.receiveName !== undefined &&
                        data?.rcvrelation !== '' ? null : '값이 비어있습니다.'
                    )
                ) : '배송구분 미선택'
            );
        });
        // 비어있으면 경고
        if (invalidFields.length > 0) {
            console.log(invalidFields);
            alert('잘못된 입력값이 있습니다. 정확한 정보 변경을 위해 다시 확인해주세요.');
            return;
        }
        // 검증해서 이상없으면 주문상태 변경 API 요청

        // 상태변경 요청할 API 묶음
        const updateOrderStatePromises = Object.keys(orderUpdateData)?.map(id => {
            if(orderUpdateData[id]?.deliveryType === "일반택배" && orderUpdateData[id]?.useEscrow === true){
                // 일반택배인 경우 일반배송 API요청
                return updateEscrowOrderState(id, orderUpdateData[id].deliveryCompany, orderUpdateData[id].deliveryTime, orderUpdateData[id].trackingNumber)
                    .then(result => ({ id, result }))
                    .catch(error => ({ id, error }));
            }else if(orderUpdateData[id]?.deliveryType === "일반택배" && orderUpdateData[id]?.useEscrow === false){
                return updateOrderState(id, orderUpdateData[id].deliveryCompany, orderUpdateData[id].trackingNumber)
                    .then(result => ({ id, result }))
                    .catch(error => ({ id, error }));
            }else if(orderUpdateData[id]?.deliveryType === "직접전달" && orderUpdateData[id]?.useEscrow === true){
                // 직접전달 에스크로
                return updateEscrowDirectOrderState(id, orderUpdateData[id].receiveTime, orderUpdateData[id].receiveName, orderUpdateData[id].rcvrelation)
                    .then(result => ({ id, result }))
                    .catch(error => ({ id, error }));
            }else{
                // 직접전달 일반결제
                return updateDirectOrderState(id, orderUpdateData[id].receiveTime, orderUpdateData[id].receiveName, orderUpdateData[id].rcvrelation)
                    .then(result => ({ id, result }))
                    .catch(error => ({ id, error }));
            }
            
        });
        Promise.all(updateOrderStatePromises)
            .then(results => {
                let errorCounter = 0;
                results.forEach(res => {
                    if(res.error) {
                        console.error(`Error in order ${res.id}:`, res.error);
                        errorCounter++;
                    }
                });

                if (errorCounter > 0) {
                    alert(`총 ${errorCounter}건의 상태변경이 실패하였습니다.`);
                } else {
                    alert('주문상태 변경이 정상적으로 요청되었습니다.');
                }
            })
            .catch(error => {
                console.error("Unexpected error:", error);
                alert('주문상태 변경 중 예상치 못한 오류가 발생했습니다.');
            })
            .finally(()=>{
                // 팝업 닫고 새로고침
                setPopup(false);
                window.location.reload();
            })
    }
    
    // 모달에서 수정한 값 담을 Object 생성
    const [orderUpdateData, setOrderUpdateData] = useState({});

    // 선택된 주문을 모두 표시
    const selectedOrderIds = Object.keys(selectedRows).filter(key => selectedRows[key] === true);

    return (
        <>
            <Modal show={open} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>주문상태 변경(상품출고)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedOrderIds.length > 0
                ? <span className='text-hw fw-bold'>총 {selectedOrderIds?.length}건의 주문을 "출고 준비" 상태로 변경합니다.</span>
                : '선택된 주문이 없습니다.'}

                <div className='fixTable_wrap my-2 py-3'>
                    <table className='sTable' id='scrollTable'>
                        <thead>
                            <tr>
                                <th>주문번호<br/>상품명</th>
                                <th>주문상태</th>
                                <th>옵션</th>
                                <th>추가옵션</th>
                                <th>배송구분</th>
                                <th>택배사</th>
                                <th>송장번호</th>
                                <th>발송일</th>
                                <th>발송시간</th>
                                <th>수령일</th>
                                <th>수령시간</th>
                                <th>수령자</th>
                                <th>수령자와의 관계</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* 주문 리스트 */}
                            {selectedOrderIds.length > 0 ? selectedOrderIds?.map(orderId => <ProductReleaseTable orderId={orderId} setOrderUpdateData={setOrderUpdateData} selectedRowsData={selectedRowsData[orderId]}/>) : <tr>데이터가 없습니다.</tr>}
                        </tbody>
                    </table>
                </div>
                
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center align-items-center'>
                <Button variant="primary" onClick={()=>handleOkSign()} className="text-center w-25 m-0 py-2 px-3">
                    확인
                </Button>
            </Modal.Footer>
            </Modal>
        </>
    );
}

// 상품출고 상태로 전환 시 사용되는 모달의 테이블
function ProductReleaseTable({orderId, setOrderUpdateData, selectedRowsData}){
    // 해당 주문건 정보

    const [deliveryType, setDeliveryType] = useState(); // 배송구분
    const [deliveryCompany, setDeliveryCompany] = useState(); // 택배사
    const [trackingNumber, setTrackingNumber] = useState(); // 송장번호
    const [deliveryTime, setDeliveryTime] = useState(); // 발송일시
    const [receiveTime, setReceiveTime] = useState(); // 수령일시
    const [receiveName, setReceiveName] = useState(); // 수령자
    const [rcvrelation, setRelation] = useState(); // 수령자

    // 발송일시 변환
    const [deliveryDate, setDeliveryDate] = useState(''); // 발송일
    const [deliveryHour, setDeliveryHour] = useState(''); // 발송시간(시)
    const [deliveryMinute, setDeliveryMinute] = useState(''); // 발송시간(분)
    useEffect(()=>{
        if(deliveryDate !== ''){
            const formattedDate = deliveryDate.replace(/-/g, '');
            setDeliveryTime(formattedDate+deliveryHour+deliveryMinute);
        }
    }, [deliveryDate, deliveryHour, deliveryMinute])

    // 수령일시 변환
    const [receiveDate, setReceiveDate] = useState(''); // 수령일
    const [receiveHour, setReceiveHour] = useState(''); // 수령시간(시)
    const [receiveMinute, setReceiveMinute] = useState(''); // 수령시간(분)
    useEffect(()=>{
        if(receiveDate !== ''){
            const formattedDate = receiveDate.replace(/-/g, '');
            setReceiveTime(formattedDate+receiveHour+receiveMinute);
        }
    }, [receiveDate, receiveHour, receiveMinute])

    // 에스크로 주문 사용 여부
    const [useEscrow, setUseEscrow] = useState(selectedRowsData?.pay_method); 
    // useEffect(()=>{
    //     selectedRowsData?.pay_method === '계좌이체' ? setUseEscrow(true) : (
    //         selectedRowsData?.pay_method === '가상계좌' ? setUseEscrow(true) : setUseEscrow(false)
    //     )
    // },[])

    // 정보가 업데이트되면 수정값 object에 적용
    useEffect(() => {
        setOrderUpdateData(prevData => ({
            ...prevData,
            [orderId]: {
                trackingNumber: trackingNumber,
                deliveryCompany: deliveryCompany,
                deliveryType: deliveryType,
                deliveryTime: deliveryTime,
                receiveTime: receiveTime,
                receiveName: receiveName,
                rcvrelation: rcvrelation,
                useEscrow: useEscrow
            }
        }));
    }, [deliveryType, deliveryCompany, trackingNumber, deliveryTime, receiveTime, receiveName, rcvrelation]);

    // 직접전달 / 일반배송 구분에 따른 기타 값 초기화
    const handleDeliveryTypeChange = (e) => {
        const newDeliveryType = e.target.value;
        setDeliveryType(newDeliveryType);

        if (newDeliveryType === "직접전달") {
            setDeliveryCompany('');
            setTrackingNumber('');
            setDeliveryTime('');
            setDeliveryDate('');
            setDeliveryHour('');
            setDeliveryMinute('');
        }
        if (newDeliveryType === "일반택배") {
            setReceiveTime('');
            setReceiveName('');
            setRelation('');
            setReceiveDate('');
            setReceiveHour('');
            setReceiveMinute('');
        }
    };

    const deliveryCompanys = {
        true: [
            "CJ대한통운", "한진택배", "롯데택배", "우체국택배", "로젠택배", "일양로지스", "EMS", "DHL", "한덱스", "FedEx", "UPS", "USPS", "대신택배", "경동택배", "합동택배", "CU 편의점 택배", "GS 편의점 택배", "TNT Express", "한의사랑택배", "천일택배", "건영택배", "GSMNtoN", "KGL네트웍스", "DHL Global Mail", "i-Parcel",
            "LX판토스", "ECMS Express", "굿투럭", "GSI Express", "CJ대한통운 국제특송", "애니트랙", "SLX택배", "우리택배(구호남택배)", "우리한방택배", "ACI Express", "A.C.E EXPRESS INC", "GPS Logix", "성원글로벌카고", "농협택배", "홈픽택배", "EuroParcel", "Cway Express", "YJS글로벌(영국)", "은하쉬핑", "YJS글로벌(월드)",
            "Giant Network Group", "디디로지스", "대림통운", "LOTOS CORPORATION", "IK물류", "성훈물류", "CR로지텍", "용마로지스", "원더스퀵", "LineExpress", "로지스밸리택배", "제니엘시스템", "컬리넥스트마일", "스마트로지스", "풀앳홈", "삼성전자물류", "이투 마스(ETOMARS)", "큐런택배", "두발히어로", "위니아딤채", "하이브시티", 
            "지니고 당일배송", "팬스타국제특송(PIEX)", "오늘의픽업", "큐익스프레스", "로지스밸리", "에이씨티앤코아물류", "롯데택배 해외특송", "나은물류", "한샘서비스원 택배", "배송하기좋은날(SHIPNERGY)", "NDEX KOREA", "도도플렉스(dodoflex)", "BRIDGE LOGIS", "허브넷로지스틱스", "LG전자(판토스)", "MEXGLOBAL", "파테크해운항공", "부릉", 
            "SBGLS", "1004홈", "썬더히어로", "캐나다쉬핑", "㈜팀프레시", "YUNDA EXPRESS", "롯데칠성", "핑퐁", "발렉스 특수물류", "바바바(bababa)", "BAIMA EXPRESS", "엔티엘피스", "LTL", "GTS로지스", "㈜올타코리아", "로지스팟", "판월드로지스틱㈜", "홈픽 오늘도착", "UFO로지스", "딜리래빗", "지오피", "에이치케이홀딩스", "HTNS", 
            "케이제이티", "더바오", "라스트마일", "오늘회 러쉬", "직구문", "인터로지스", "탱고앤고", "투데이", "큐브플로우(CUBEFLOW)", "현대글로비스", "국제로지스틱(KSE)", "에스더쉬핑", "ARGO", "골드스넵스", "자이언트", "㈜엠티인터내셔널", "㈜이지로지스틱", "KT EXPRESS(케이티익스프레스)", "HY", "유피로지스", "우진인터로지스", "Auspost", "삼다수 가정배송", "와이드테크"
        ],
        false: ["CJ대한통운", "한진택배", "일양로지스", "롯데택배", "경동택배", "로젠택배", "합동택배", "천일택배", "우체국택배", "대신택배", "GS 편의점 택배"]
    }

    return(
        <tr>
            <td>{selectedRowsData?.order_number}<br/>{selectedRowsData?.product_name}</td>
            <td>{selectedRowsData?.status}</td>
            <td>{selectedRowsData?.option_rows?.map(option => option?.name + " : " + option?.quantity+"개")}</td>
            <td>{selectedRowsData?.extra_options.length !== 0 ? (selectedRowsData?.extra_options?.map(option => option?.name + " : " + option?.quantity+"개")) : "-"}</td>
            {/* 배송구분 */}
            <td>
                <select className='mSelectBox mr6'  value={deliveryType} onChange={handleDeliveryTypeChange}>
                    <option value="" selected="selected" disabled>배송구분</option>
                    <option value="일반택배">일반택배</option>
                    <option value="직접전달">직접전달</option>
                </select>
            </td>
            {/* 택배사선택 */}
            <td>
                {deliveryType === '직접전달' ? '-' : (
                    <select className='mSelectBox mr6'  value={deliveryCompany} onChange={(e) => setDeliveryCompany(e.target.value)} disabled={deliveryType === "직접전달"}>
                        <option value="" selected="selected" disabled>택배사 선택</option>
                        {deliveryCompanys[useEscrow]?.map((company, index) => <option key={index} value={company}>{company}</option>)}
                    </select>
                )}
            </td>
            {/* 송장번호 */}
            <td>
                {deliveryType === '직접전달' ? '-' : (
                    <input type="text"
                        className='border-default p-1'
                        value={trackingNumber}
                        placeholder="송장번호"
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        disabled={deliveryType === "직접전달"}
                    />
                )}
            </td>
            <td>
                {deliveryType === '직접전달' ? '-' : !useEscrow ? '-' :(
                    <input type="date"
                        className='border-default p-1'
                        value={deliveryDate}
                        // placeholder="발송일시(YYYYMMDDHHDD)"
                        onChange={(e) => setDeliveryDate(e.target.value)}
                        disabled={deliveryType === "직접전달"}
                    />
                )}
            </td>
            <td>
                {deliveryType === '직접전달' ? '-' : !useEscrow ? '-' :(
                    <>
                        <select className='mSelectBox mr6'  value={deliveryHour} onChange={(e) => setDeliveryHour(e.target.value)}>
                            <option value="" selected="selected" disabled>시</option>
                            {
                                ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']?.map(
                                    hour => <option value={hour}>{hour}시</option>
                                )
                            }
                        </select>
                        <select className='mSelectBox mr6'  value={deliveryMinute} onChange={(e) => setDeliveryMinute(e.target.value)}>
                            <option value="" selected="selected" disabled>분</option>
                            {
                                ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
                                 '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
                                 '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
                                 '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
                                 '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
                                 '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
                                ]?.map(
                                    minute => <option value={minute}>{minute}분</option>
                                )
                            }
                        </select>
                    </>
                )}
            </td>

            {/* 직접전달 */}
            <td>
                {deliveryType !== '직접전달' ? '-' : (
                    <input type="date"
                        className='border-default p-1'
                        value={receiveDate}
                        // placeholder="수령일시(YYYYMMDDHHDD)"
                        onChange={(e) => setReceiveDate(e.target.value)}
                        disabled={deliveryType !== "직접전달"}
                    /> 
                )}
            </td>
            <td>
                {deliveryType !== '직접전달' ? '-' : (
                    <>
                        <select className='mSelectBox mr6'  value={receiveHour} onChange={(e) => setReceiveHour(e.target.value)}>
                            <option value="" selected="selected" disabled>시</option>
                            {
                                ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']?.map(
                                    hour => <option value={hour}>{hour}시</option>
                                )
                            }
                        </select>
                        <select className='mSelectBox mr6'  value={receiveMinute} onChange={(e) => setReceiveMinute(e.target.value)}>
                            <option value="" selected="selected" disabled>분</option>
                            {
                                ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
                                 '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
                                 '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
                                 '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
                                 '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
                                 '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
                                ]?.map(
                                    minute => <option value={minute}>{minute}분</option>
                                )
                            }
                        </select>
                    </>
                )}
            </td>
            <td>
                {deliveryType !== '직접전달' ? '-' : (
                    <input type="text"
                        className='border-default p-1'
                        value={receiveName}
                        placeholder="수령자"
                        onChange={(e) => setReceiveName(e.target.value)}
                        disabled={deliveryType !== "직접전달"}
                    /> 
                )}
            </td>
            <td>
                {deliveryType !== '직접전달' ? '-' : (
                    <input type="text"
                        className='border-default p-1'
                        value={rcvrelation}
                        placeholder="수령자와의 관계"
                        onChange={(e) => setRelation(e.target.value)}
                        disabled={deliveryType !== "직접전달"}
                    /> 
                )}
            </td>
        </tr>
    )
}