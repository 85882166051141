/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 셀러관리자 > 정산 > 부가세내역 AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <>
        <tr>
          <td>{Adjust.month}</td>
          <td>{Adjust.taxable_sum.toLocaleString()}원</td>
          <td>{Adjust.nontaxable_sum.toLocaleString()}원</td>
          <td>{Adjust.card_sales_sum.toLocaleString()}원</td>
          <td>{Adjust.income_sales_sum.toLocaleString()}원</td>
          <td>{Adjust.expenditure_sales_sum.toLocaleString()}원</td>
          <td>{Adjust.none_sales_sum.toLocaleString()}원</td>

         </tr>
      </>
    </>
  );
};
