import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import TermsAndConditions from "./Operating/TermsAndConditions";
import ConsentManagement from "./Operating/ConsentManagement";
import CancellationPolicy from "./Operating/CancellationPolicy";
import PrivacyPolicy from "./Operating/PrivacyPolicy";

import React, { useState } from 'react';
function PushNotificationHistory()
{
    const [activeComponent, setActiveComponent] = useState("TermsAndConditions");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            {/*  운영방침설정  */}
            <div className='page-tit'>
                <h2>운영방침설정</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "TermsAndConditions" ? "active-button" : "m-sm-btn"}
                        onClick={() => handleButtonClick("TermsAndConditions")}>이용약관</button>

                <button className={activeComponent === "PrivacyPolicy" ? "active-button" : "m-sm-btn"}
                        onClick={() => handleButtonClick("PrivacyPolicy")}>개인정보처리방침</button>

                <button className={activeComponent === "CancellationPolicy" ? "active-button" : "m-sm-btn"}
                        onClick={() => handleButtonClick("CancellationPolicy")}>교환/환불정책</button>

                {/* <button className={activeComponent === "ConsentManagement" ? "active-button" : "m-sm-btn"}
                        onClick={() => handleButtonClick("ConsentManagement")}>개인정보제공</button> */}
          
           
            </div>
            {/* 이용약관 */}
            {activeComponent === "TermsAndConditions" && <TermsAndConditions />}
            
            {/* 개인정보처리방침 */}
            {activeComponent === "PrivacyPolicy" && <PrivacyPolicy />}

            {/* 교환/환불정책 */}
            {activeComponent === "CancellationPolicy" && <CancellationPolicy />}

            {/* 개인정보제공 */}
            {/* {activeComponent === "ConsentManagement" && <ConsentManagement />} */}
        </div>

        </>
    )
}

export default PushNotificationHistory