import React, { useState } from 'react';

function Pagination({ count, page, setPage, pageSize, handlePrevPage, handleNextPage }) {
  const totalPages = Math.ceil(count / pageSize);
  const [visiblePages, setVisiblePages] = useState([]);
  
  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    if (totalPages <= 10) {
      return pageNumbers.map((pg) => (
          <li
            key={pg}
            className={pg === page ? 'cur' : ''}
            onClick={() => setPage(pg)}
          >
            {pg}
          </li>
       ));
    } else {
      let displayedPages = [];
      if (page <= 6) {
        displayedPages = [...pageNumbers.slice(0, 7), '...', totalPages];
      } else if (page >= totalPages - 5) {
        displayedPages = [1, '...', ...pageNumbers.slice(totalPages - 7)];
      } else {
        displayedPages = [1, '...', ...pageNumbers.slice(page - 4, page + 2), '...', totalPages];
      }

      return displayedPages.map((pg) => (
        <li
          key={pg}
          className={pg === page ? 'cur' : ''}
          onClick={() => {
            if (pg === '...') return;
            setPage(pg);
          }}
        >
          {pg === '...' ? pg : <span>{pg}</span>}
        </li>
      ));
    }
  };

  return (
    <ul className="m-page-nav-btn">
      <button onClick={handlePrevPage} disabled={page === 1}>
        <i className="xi-angle-left"></i>
      </button>
      {renderPageNumbers()}
      <button onClick={handleNextPage} disabled={page === totalPages}>
        <i className="xi-angle-right"></i>
      </button>
    </ul>
  );
}

export default Pagination;
