// 해와관리자 > productApi.js
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { BASE_URL } from '../apiConfig';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';
import { getAccessFromCookie } from '../cookieUtils';

const PAGE_SIZE = 10;
const boundary = Math.random().toString().substr(2);


// 상품 메인 이미지 등록 
export async function uploadProductImage(ImageFile) {
  const access = getAccessFromCookie();

  try {
    const response1 = await fetch(`${BASE_URL}/image/upload/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${access}`,
        'Content-Type': 'application/json',
      },
    });

    if (response1.ok) {
      const data1 = await response1.json();

      const formData = new FormData();
      formData.append('file', ImageFile);

      const response2 = await fetch(data1.uploadURL, {
        method: 'POST',
        body: formData,
      });

      if (response2.ok) {
        const data2 = await response2.json();
        console.log('이미지 업로드 성공:', data2.result.variants[0]);
        return data2.result.variants[0]; // 이미지 업로드 후 반환된 URL
      } else {
        console.log('이미지 업로드 실패');
        return null;
      }
    } else {
      console.log('이미지 등록 URL을 얻어오는 요청이 실패했습니다.');
      return null;
    }
  } catch (error) {
    console.log('이미지 등록 오류가 발생했습니다.', error);
    return null;
  }
}


// 상품 상세 조회
const fetchProductDetails = (productId) => {
  return fetch(`${BASE_URL}/product/${productId}`)
    .then(res => res.json());
};

// 상품관리 > 신규요청
// 신규요청
const fetchNewProducts = (page, pageSize , ordering) => {
  return instance.get(`product/requested/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)

};

export const useNewProductsQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['newproducts', page, pageSize, ordering], () => fetchNewProducts(page, pageSize , ordering));

  const fetchNewProductDetailsById = (productId) => {
    return fetchProductDetails(productId);
  };

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    fetchNewProductDetailsById
  };
};



// 상품 조회
const fetchProducts = (page, pageSize ,ordering) => {
  return instance.get(`/product/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useProductsQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['products', page, pageSize, ordering ], () => fetchProducts(page, pageSize , ordering), {
    onSuccess: (data) => {
    
    }
  });

  const fetchProductDetailsById = (productId) => {
    return fetchProductDetails(productId);
  };


  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    fetchProductDetailsById
  };
};

// 상품 검색 
export const handleSearch = async ({
    searchProduct, 
    setSearchResults, 
    searchOption,
    selectedDateType,
    selectedStartDate,
    selectedEndDate, 
    status,
    startPrice,
    endPrice,
    selectedVendor,
    setSearchUrl
    }) => {
    try {
        let url = `product/document/`;
        if (searchProduct) {
          switch(searchOption) {
            case 'all':
              url += `?search=${searchProduct}`;
              break;
            case 'name':
              url += `?name=${searchProduct}`;
              break;
            case 'id':
              url += `?search=${searchProduct}`;
              break;
            default:
              url += `?search=${searchProduct}`;
          }
      }else{
        url += `?`;
      }

      if (selectedStartDate && selectedEndDate) {
        switch(selectedDateType) {
          case 'apply':
            url += `&register_date=${selectedStartDate}__${selectedEndDate}`;
            break;
          default:
            break;
        }
      }

      if(status) url += `&sale_status=${status}`;
      
      if (startPrice && endPrice) {
        url += `&price__range=${startPrice}__${endPrice}`;
      }

      if(selectedVendor.name) url += `&vendor_name=${selectedVendor.name}` 

      const response = await instance.get(url);
      setSearchUrl(url);
      console.log(url);
      
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 신규요청 > 검색 
export const handleNewProductSearch = async ({
  searchProduct, 
  setSearchResults, 
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  requestType,
  setSearchUrl
  }) => {
  try {
    let url = `product/requested/`;
    if (searchProduct) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchProduct}`;
          break;
        case 'name':
          url += `?name=${searchProduct}`;
          break;
        case 'id':
          url += `?id__in=${searchProduct}`;
          break;
        default:
          url += `?search=${searchProduct}`;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'apply':
          url += `&register_date=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }
    if (requestType) {
      url += `&request_status=${requestType}`;
    }
    
    console.log(url)

    const response = await instance.get(url);
    setSearchUrl(url)
    setSearchResults(response.data);
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 상품등록
// 상품등록 > 수산상회 선택 (조회)
export const useApprovedVendors = () => {
  return useQuery('approvedVendors', async () => {
      const response = await instance.get(`vendor/approved/?page_size=all`);
      return response.data;
    });
};



// 상품등록
export function useProductRegistration() {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(null);

  const access = getAccessFromCookie();

  if (!access) {
    return null;
  }
  
  const post = async (data) => {
    setIsPostLoading(true);
    setError(null);

    try {
      const response = await instance.post(`product/`, data);
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      setError("Failed to post");
      throw new Error("Failed to post");
    } finally {
      setIsPostLoading(false);
    }
  };

  return {
    post,
    isPostLoading,
    error,
  };
}


// 해와관리자 > 상품등록 > 카테고리검색 

export const handleCategorySearch = async ({
  searchCategory, 
  setSearchResults, 
  }) => {
  try {
    let url = `category/document/`;

    if (searchCategory) {
        url += `?search=${searchCategory}`; 
    }
   
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 > 상품등록 > 카테고리 검색 조회 
export const fetchCategorySearch = async (Category) => {
  try {
    const response = await instance.get(`category/document/?search=${Category}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// [hm] 상품 승인/거절 API
export const setProductState = (id, state, rejectReason) => {
  // 상품등록 승인(현재 신규입점/정보수정 구분없음)
  if(state === "approve"){
    return instance.post(`/product/approve/`,
      {
        product: id
      }
    )
  }
  else if(state === "reject"){
    // 상품등록 거절(현재 신규입점/정보수정 구분없음)
    return instance.post('/product/reject/',
      {
        product: id,
        reject_reason: rejectReason
      }
    )
  }
};

// 상품관리 > 활동기록 > 상품등록/수정 
const fetchProductRecordDate = (page, pageSize, ordering) => {
  return instance.get(`record/product/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useProductRecordQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['ProductRecord', page, pageSize, ordering], () => fetchProductRecordDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 상품관리 > 활동기록 > 카테고리
const fetchCategoryRecordDate = (page, pageSize, ordering) => {
  return instance.get(`record/category/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useCategoryRecordQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['CategoryRecord', page, pageSize, ordering], () => fetchCategoryRecordDate(page,pageSize,ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 해와관리 > 상품관리 >  상품조회 > 상품아이디 검색 조회 
export const fetchProductId = (id) => {
  return instance.get(`product/document/?search=${id}`)
    .then(res => res.data);
};