// 셀러관리자 > 정산 > adjustApi.js
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

// 셀러관리자 정산 > 정산현황
const fetchAdjustDocument = (page, pageSize, ordering) => {
    return instance.get(`adjust/vendor/state/?page=${page}&page_size=all&ordering=${ordering}`)
      .then(res => res.data)
};
  
export const useAdjustDocumentQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);
  
    useEffect(() => {
      setPage(1);
    }, [pageSize]);

    const { data, error, isFetching } = useQuery(
      ['sellerAdjustDocument', page , pageSize, ordering ], () => fetchAdjustDocument(page , pageSize, ordering)
    );
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage
    };
};


// 셀러관리자 > 정산 > 정산현황 > 검색 
export const handleAdjustDocumentSearch = async ({
    setSearchResults, 
    setSearchUrl,
    selectedStartDate, 
    selectedEndDate,
    ordering,
    }) => {
    try {
      let url = `adjust/vendor/state/`;
  
      if (selectedStartDate && selectedEndDate) {
          url += `?complete_at__range=${selectedStartDate}__${selectedEndDate}`; 
      }

      // if(ordering){
      //   url += `&ordering=${ordering}` 
      // }

      console.log(url)
      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};
  
// 셀러관리자 정산 > 미지급내역
const fetchSAdjustUnpaidDocument = (page , pageSize, ordering) => {
  return instance.get(`adjust/vendor/unpaid/seller/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useSAdjustUnpaidDocumentQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['adjustUnpaidDocument', page], () => fetchSAdjustUnpaidDocument(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};


// 셀러관리자 > 정산 > 미지급내역 > 검색
export const handleSAdjustUnpaidSearch = async ({
  searchAdjust,
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  }) => {
  try {
    let url = `adjust/vendor/unpaid/seller/document/`;

    if (searchAdjust && searchOption) {
      if(searchOption==='all'){
        url += `?search=${searchAdjust}`;
      }else{
        url += `?${searchOption}=${searchAdjust}`;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&purchase_confirmation_date=${selectedStartDate}__${selectedEndDate}`;
    }


    // if(ordering){
    //   url += `&ordering=${ordering}` 
    // }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


  
// 셀러관리자 정산 > 매출내역
const fetchAdjustRevenue = (page , pageSize) => {
    return instance.get(`adjust/revenue/?page=${page}&page_size=${pageSize}`)
      .then(res => res.data)
      .catch(error => error.data)
};
  
export const useAdjustRevenueQuery = (ordering) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
  
    const { data, error, isFetching } = useQuery(['adjustRevenue', page], () => fetchAdjustRevenue(page , PAGE_SIZE), {
      onSuccess: (data) => {
        // setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
     
        // if (page < totalPages) {
        //   prefetchQuery(['adjustDocument', page + 1], () => fetchAdjustDocument(page + 1));
        // }
      }
    });
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,
      totalPages,

    };
};
  
  
// 셀러관리자 > 매출내역 > 구매확정 기준 > 검색 
export const handleAdjustRevenueSearch = async ({
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  }) => {
  try {
    let url = `adjsut/vendor/sale/summary/`;

    if (selectedStartDate && selectedEndDate) {
      url += `&sale_date__range=${selectedStartDate}__${selectedEndDate}`;
    }

    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 셀러관리자 정산 > 부가세내역
const fetchAdjustTaxDocument = (page , pageSize, ordering) => {
  return instance.get(`adjust/seller/tax/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
};

export const useAdjustTaxDocumentQuery = (ordering,pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);
  

  const { data, error, isFetching } = useQuery(
    ['adjustDocument', page, pageSize, ordering], () => fetchAdjustTaxDocument(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 셀러관리자>  정산 >  부가세내역 > 검색 
export const handleSAdjustTaxSearch = async ({
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  }) => {
  try {
    let url = `adjust/seller/tax/document/`;

    if (selectedStartDate && selectedEndDate) {
        url += `?month__range=${selectedStartDate}__${selectedEndDate}`;
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 정산 > 매출내역 > 구매확정 기준
const fetchAdjustSummaryDocument = (page , pageSize, ordering) => {
  return instance.get(`adjust/vendor/confirm/summary/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useAdjustSummaryDocumentQuery = (ordering,pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);
  
  const { data, error, isFetching } = useQuery(
    ['SadjustSummaryDocument', page, pageSize, ordering], 
    () => fetchAdjustSummaryDocument(page , pageSize, ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 셀러관리자 > 정산 > 매출내역 > 상품판매 기준
const fetchAdjustSummary2Document = (page , pageSize, ordering) => {
  return instance.get(`adjust/vendor/sale/summary/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useAdjustSummary2DocumentQuery = (ordering,pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['SadjustSummaryDocument', page, pageSize, ordering], 
    () => fetchAdjustSummary2Document(page , pageSize, ordering));

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리자>  정산 >  매출내역 > 구매확정 기준 > 검색 
export const handleAdjustTaxSearch = async ({
  searchAdjust,
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  selectedVendor
  }) => {
  try {
    let url = `adjust/summary/?type=confirm`;

    if (searchAdjust) {
      switch(searchOption) {
        case 'all':
          url += `&search=${searchAdjust}`;
          break;
        // case 'id':
        //   url += `?username__contains=${searchAdjust}`;
        //   break;
        
        default:
          break;
      }
    }else{
      url += `&`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'join':
          url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        case 'last':
          url += `&last_login__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }

    if(ordering){
      url += `&ordering=${ordering}` 
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 셀러관리자 정산 > 세금계산서내역
const fetchAdjustInvoiceDocument = (page , pageSize, ordering) => {
  return instance.get(`adjust/seller/invoice/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useAdjustInvoiceDocumentQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['adjustInvoiceDocument', page], () => fetchAdjustInvoiceDocument(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리자>  정산 >  세금계산서내역 > 검색 
export const handleSAdjustInvoiceSearch = async ({
  setSearchResults, 
  setSearchUrl,
  selectedStartDate, 
  selectedEndDate,
  }) => {
  try {
    let url = `adjust/seller/invoice/document/`;

    if (selectedStartDate && selectedEndDate) {
        url += `?issued_at__range=${selectedStartDate}__${selectedEndDate}`;
    }

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 셀러관리자 > 정산 > 세금계산서내역 > 세금계산서 내역 추가
export const RegisterInvoice= (data) =>
  instance.post(`adjust/invoice/`, data).then(response => response.data);
