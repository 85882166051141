/***************************************************************************************
* FileName      : Month.js
* Description   : 해와관리자 > 가입통계 > 월별 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Chart from '../../../components/Chart'

import { labelDataObject , chartBackgroundColor , chartBorderColor } from './chartConfig.js';

import YearSelect from "../Component/YearSelect";
import GenderCheckboxGroup from "../Component/SignupStats/GenderCheckboxGroup";
import AgeCheckboxGroup from "../Component/SignupStats/AgeCheckboxGroup";

import { getSignupMonthly , handleSearchSignupMonthly } from "../../../../api/hwadmin/statisticApi";


function Month()
{
    const [chartData,setChartData] = useState([]);

    const currentYear = new Date().getFullYear().toString();

    const [year, setYear] = useState(currentYear);
    const [searchYear, setSearchYear] = useState(currentYear);

    const { isLoading, error, data } = useQuery(["signupMonthly", year], () => getSignupMonthly(year));
    
    console.log(data);
    console.log("--가입--")
    const [isUpdated, setIsUpdated] = useState(false);
    

    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    // 성별 체크박스 
    const [genderCheckedState, setGenderCheckedState] = useState({
        male_user: true,
        female_user: true,
    });
    
    const handleGenderCheckboxChange = (event) => {
        setGenderCheckedState({
            ...genderCheckedState,
            [event.target.name]: event.target.checked,
        });
    };

    // 연령별 체크박스 
    const [ageCheckedState, setAgeCheckedState] = useState({
        "10": false,
        "20": false,
        "30": false,
        "40": false,
        "50": false,
        "60": false,
        // "60대 이상": false,
    });
    
    const handleAgeCheckboxChange = (event) => {
        setAgeCheckedState({
          ...ageCheckedState,
          [event.target.name]: event.target.checked,
        });
    };

    // 지역별 
    const [checkedState, setCheckedState] = useState({
        seoul: false,
        incheon: false,
        busan: false,
        daejeon: false,
        gwangju: false,
        daegu: false,
        ulsan: false,
        gyeonggi: false,
        gangwon: false,
        chungbuk: false,
        chungnam: false,
        jeonbuk: false,
        jeonnam: false,
        gyeongbuk: false,
        gyeongnam: false,
        jeju: false,
        sejong: false
    });
    
    const handleRegionCheckboxChange = (event) => {
        setCheckedState({
          ...checkedState,
          [event.target.name]: event.target.checked
        });
    };

    useEffect(() => {
        if (searchResults && searchResults.labels && searchResults.labels.length > 0) {
            updateChartData(genderCheckedState);
        }
    },[searchResults]);

    // 검색버튼 클릭 시 
    const onSearch = async  () => {
        setYear(searchYear);

        const selectedAges = Object.keys(ageCheckedState).filter((age) => ageCheckedState[age]).join(',');

        await handleSearchSignupMonthly({
            setSearchResults,
            year,
            selectedAges
        });

        setSearchConfirm(true);

    };

    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.labels, 
                datasets: [
                  {
                    label: '여성회원',
                    data: data?.female_user,
                    backgroundColor: '#F6B6C1',
                    borderColor: '#ED6E85',
                    pointBackgroundColor: '#FBE7B4',
                    pointBorderColor: '#F7CF6B',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },  {
                    label: '남성회원',
                    data: data?.male_user,
                    backgroundColor: '#AACFF2',
                    borderColor: '#57A0E5',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },
                ],
            };
    
            setChartData(newChartData);
        }

    },[data])


    function updateChartData(genderCheckedState)
    {
        const labels = searchResults?.labels;
        const datasets = [];
        for (const key in genderCheckedState) {
            if (genderCheckedState[key]) {
            const dataKey = searchResults?.[key];
            const backgroundColor = chartBackgroundColor[key];
            const borderColor = chartBorderColor[key];
            const label = labelDataObject[key];

            console.log("label:",label);

            datasets.push({
                label: label,
                data: dataKey,
                backgroundColor,
                borderColor,
                pointBackgroundColor: backgroundColor,
                pointBorderColor: borderColor,
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
            });
            }
        }
        
        const newChartData = {
            labels: labels,
            datasets: datasets,
        };
        
    
        setChartData(newChartData);
        setIsUpdated(true);
    
    }

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/signupStats/';
    };
    
    return (
        <>
        {/* 검색 */}
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field date'>
                    <div> <b>기간</b> </div>
                    <div>
                        <YearSelect value={searchYear} onChange={(e) => setSearchYear(e.target.value)} />
                    </div>   
                </div>
            
                <div className='search-field division'>
                    <div> 
                        <b>성별</b> 
                    </div>
                    <GenderCheckboxGroup
                        checkedState={genderCheckedState}
                        onChange={handleGenderCheckboxChange}
                    />
                </div>
                <div className='search-field division'>
                    <div> 
                        <b>연령별</b> 
                    </div>
                    <AgeCheckboxGroup
                        checkedState={ageCheckedState}
                        onChange={handleAgeCheckboxChange}
                    />  
                </div>
            </div>
            
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={handleResetClick}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
        </section>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <Chart data={chartData}/>
        </section>

    </>
    )
}

export default Month