import { instance } from '../../axiosInstance/axiosConfig';
import { useQuery } from 'react-query';
import { useState , useEffect } from 'react';

// 해와관리자 알림 > 메일발송관리 > 자동발송관리
const fetchMailNotificationList = (page , pageSize ) => {
    return instance.get(`notification/mail/condition/?page=${page}&page_size=${pageSize}`)
      .then(res => res.data)
      .catch(error => error.data)
};
  
export const useMailNotificationListQuery = (pageSize)  => {
    const [page, setPage] = useState(1);
  
    useEffect(() => {
      setPage(1);
    }, [pageSize]);
  
    const { data, error, isFetching } = useQuery(
      ['MailNotificationList', page, pageSize ],
      () => fetchMailNotificationList(page , pageSize)  
    );
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,
  
    };
};

// 해와관리자 알림 > 메일발송관리 > 자동발송관리 공개/비공개 처리
export const updateMailHidden = (key, isChecked)=>{
  return instance.patch(`notification/mail/condition/${key}/`,
  {
      is_active : isChecked
  })
}


// 해와관리자 알림 > 메일발송관리 > 발송내역
const fetchNotificationMailHistory = (page , pageSize ) => {
  return instance.get(`notification/mail/history/?page=${page}&page_size=${pageSize}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationMailHistoryQuery = (pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['NotificationMailHistory', page, pageSize ],
    () => fetchNotificationMailHistory(page , pageSize)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};


// 해와관리자 알림 > 메일발송관리 > 발송내역 > 검색 
export const handleNotificationMailHistorySearch = async ({
  searchMail,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  }) => {
  try {
    let url = `notification/mail/history/`;

    if (searchMail) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchMail}`;
          break;
        case 'content':
          url += `?content=${searchMail}`;
          break;
        case 'receiver__username':
          url += `?receiver__username=${searchMail}`;
          break;
        case 'receiver__email':
          url += `?receiver__email=${searchMail}`;
          break;
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&sent_at__range=${selectedStartDate}__${selectedEndDate}` 
    }

    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 알림 > 알림톡관리 > 자동발송관리
const fetchNotificationTalkList = (page , pageSize ) => {
  return instance.get(`notification/talk/condition/?page=${page}&page_size=${pageSize}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationTalkListQuery = (pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['NotificationTalkList', page, pageSize ],
    () => fetchNotificationTalkList(page , pageSize)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 알림톡 발송가능한 건수 조회
export const getTalkAvailable = () => instance.get(`notification/talk/available/`);

// 알림톡 템플릿 리스트 조회
export const useTalkTemplate = () => {
  return useQuery('TalkTemplate', async () => {
      const response = await instance.get(`notification/talk/send-template/`);
      return response.data;
    });
};

// 해와관리자 알림 > 알림톡관리 > 발송내역
const fetchNotificationTalkHistory = (page , pageSize ) => {
  return instance.get(`notification/talk/history/?page=${page}&page_size=${pageSize}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationTalkHistoryQuery = (pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['NotificationTalkHistory', page, pageSize ],
    () => fetchNotificationTalkHistory(page , pageSize)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 해와관리자 알림 > 알림톡관리 > 발송내역 > 검색 
export const handleNotificationTalkSearch = async ({
  searchTalk, 
  setSearchResults, 
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  requestType,
  setSearchUrl
  }) => {
  try {
    let url = `notification/talk/history/`;
    if (searchTalk) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchTalk}`;
          break;
        case 'content':
          url += `?content=${searchTalk}`;
          break;
        case 'id':
          url += `?id=${searchTalk}`;
          break;
        default:
          url += `?search=${searchTalk}`;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'apply':
          url += `&register_date=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }
    if (requestType) {
      url += `&request_status=${requestType}`;
    }
    
    console.log(url)

    const response = await instance.get(url);
    setSearchUrl(url)
    setSearchResults(response.data);
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 해와관리자 알림 > 알림톡관리 > 예약목록
const fetchNotificationTalkReservation = (page , pageSize ) => {
  return instance.get(`notification/talk/reservation/?page=${page}&limit=${pageSize}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationTalkReservationQuery = (pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['NotificationTalkReservation', page, pageSize ],
    () => fetchNotificationTalkReservation(page , pageSize)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};
// 해와관리자 > 알림톡관리 > 예약목록 >  삭제
export const deleteNotificationTalkReservation = (id) => instance.post(`/notification/talk/cancel/`,{mid:id})


// 해와관리자 알림톡관리 > 발송예약 등록
export function useNotificationTalkSend() {
  const post = async (data) => {
    try {
        const response = await instance.post(`notification/talk/send/`, data );
        const responseData = await response.data;
        return responseData;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          alert(error.response.data.data); 
        }
        throw new Error("Failed to post");
      }
  };
  
  return {
    post,
  };
}

// 해와관리자 알림 > 알림톡관리 > 발송내역
const fetchNotificationTalkHistoryDetail = (mId , page) => {
  return instance.get(`notification/talk/history/${mId}/?page=${page}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationTalkHistoryDetailQuery = (mId)  => {
  const [page, setPage] = useState(1);

  const { data, error, isFetching } = useQuery(
    ['NotificationTalkHistoryDetail', page ],
    () => fetchNotificationTalkHistoryDetail(mId , page)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};


// 해와관리자 푸시알림 > 발송예약 등록
export function useNotificationPushSend() {
  const post = async (data) => {
    try {
        const response = await instance.post(`notification/push/send/`, data );
        const responseData = await response.data;
        return responseData;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          alert(error.response.data.data); 
        }
        throw new Error("Failed to post");
      }
  };
  
  return {
    post,
  };
}

// 해와관리자 알림 > 푸시알림 > 예약목록
const fetchNotificationPushRHistory = (page , pageSize ) => {
  return instance.get(`notification/push/history/?status=예약?page=${page}&page_size=${pageSize}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationPushRHistoryQuery = (pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['NotificationPushRHistory', page, pageSize ],
    () => fetchNotificationPushRHistory(page , pageSize)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};
// 해와관리자 > 푸시알림 > 예약목록 >  예약취소
export const deleteNotificationpushCancel = (id) => instance.post(`/notification/push/cancel/`,{id:id})


// 해와관리자 알림 > 푸시알림 > 발송내역
const fetchNotificationPushHistory = (page , pageSize ) => {
  return instance.get(`notification/push/history/?page=${page}&page_size=${pageSize}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useNotificationPushHistoryQuery = (pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['NotificationPushHistory', page, pageSize ],
    () => fetchNotificationPushHistory(page , pageSize)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};


// 해와관리자 알림 > 푸시알림 > 발송내역 > 검색 
export const handleNotificationPushHistorySearch = async ({
  searchMail,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  }) => {
  try {
    let url = `notification/push/history/`;

    if (searchMail) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchMail}`;
          break;
        case 'title':
          url += `?title=${searchMail}`;
          break;
        case 'body':
          url += `?body=${searchMail}`;
          break;
     
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      url += `&sent_at__range=${selectedStartDate}__${selectedEndDate}` 
    }

    console.log(url)
    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};