/***************************************************************************************
* FileName      : ProductItemCancel.js
* Description   : 고객 > 마이페이지 > 주문상세내역 > 부분 취소 신청
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Container, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';
import 'react-datepicker/dist/react-datepicker.css';

import { fetchMyOrderDeliveryitemDetail } from '../../api/services/mypageApi.js';
import { useParams , useNavigate } from 'react-router-dom';
import Address from './Component/Address.js';

import LoadingSpinner from '../components/LoadingSpinner.js';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { OrderDeliveryitemCancel , OrderDeliveryitemCancel2 } from '../../api/services/mypageApi.js';

import OrderRow from './ProductCancel/OrderRow.js';

function OrderInquiryLayout(){
  return(
    <>
    <Container>
        <StatusBar/>
        <Row className='m-0'>
            <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'order'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <ProductItemCancel />
                </Col>
            </div>
        </Row>
    </Container>
    </>
  )
}

function ProductItemCancel()
{
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    const { orderId , status } = useParams();
    const statusNumber = parseInt(status); 

    const [orderDetail, setOrderDetail] = useState(null);

    console.log("status"+status);
    //status===1 이면 OrderDeliveryitemCancel2
    
    useEffect(() => {
        fetchMyOrderDeliveryitemDetail(orderId)
          .then(details => {
            setOrderDetail(details);
          })
          .catch(error => {
            console.error('상품 상세 조회 오류:', error);
          });
    }, [orderId]);

    //status===1 이면 상태가 "상품준비중" -> OrderDeliveryitemCancel2 -> 관리자 승인이 필요함 
    //status===0 이면 상태가 "결제완료" (바로 취소가 됨)-> OrderDeliveryitemCancel
    const createMutaion = useMutation( statusNumber === 1 ? OrderDeliveryitemCancel2 : OrderDeliveryitemCancel, 
        {
        onSuccess : (data) => {
            reset();
            alert("취소 신청이 완료 되었습니다");
            setIsLoading(false);
            navigate('/mypage/order/'); 
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    });

    const onSubmit = (data) => {
        setIsLoading(true);

        console.log(data);

        const newData = {
            delivery_item: orderId || "",
            cancel_reason: data.cancel_reason || "",
        };

        createMutaion.mutate(newData);
    };



    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <>
            <Row className='m-0 p-0 mb-4'>
                <Col xs={12} sm={12} className='m-0 p-0'>
                      <h3 class="m-0 p-0 fs-5 fw-bold">부분 취소신청</h3>
                </Col>
            </Row>
            
            <div className='area-over mt-2'>
                <h3 className='mypage-sub-title mb-2'>취소상품</h3>
            </div>
            <table className='mypage-table'>
                <colgroup>
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <thead >
                    <tr>
                        <th>상품명</th>
                        <th>판매가</th>
                        <th>수량</th>
                        <th>구매가</th>
                        <th>진행현황</th>
                    </tr>
                </thead>
                <tbody className='history'>
                    { orderDetail && orderDetail?.order_items.map((orderItem) => 
                        <tr key={orderItem.id}>
                            <OrderRow     
                                orderItem={orderItem} />
                        </tr>
                    )}                   
                </tbody>
            </table>

            <form onSubmit={handleSubmit(onSubmit)}>    
                <div className='area-over mt-5'>
                    <h3 className='mypage-sub-title mb-2'>취소사유 입력</h3>
                </div>
                <table className='mypage-table view mb-5'>
                    <colgroup>
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '20%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>사유</th>
                            <td colSpan={3}>
                                <input
                                    className={`mypage_input w100 ${errors.cancel_reason ? 'error-input' : ''}`}
                                    placeholder='취소사유를 입력해 주세요'
                                    {...register('cancel_reason', { required: '사유 입력은 필수입니다.' })} 
                                    type='text'  
                                />

                            </td>
                        </tr>
                    
                    </tbody>
                    
                </table>
                
           
             
                <div class="mpe_btn_area">
                    <button className='m-btn m-btn-search'>
                        {isLoading ?  <LoadingSpinner /> : ' 취소 신청하기'} 
                    </button>
                </div>

            </form>

 
{/* 
            <div className='area-over mt-4'>
                <h3 className='mypage-sub-title mb-2'>배송지 정보</h3>
            </div> */}



                
        </>
        </div>  
        </>
    )
}


export default OrderInquiryLayout