import { useForm } from 'react-hook-form';

function CheckAnswerModal({setIsAOpen , productInquiry})
{
    const { register, handleSubmit, formState: { errors } , reset  } = useForm();

    const closeAnswerModal = () =>{
        setIsAOpen(false);
    }

    return (
        <>
         <div className="modal ">
            <div className="modal-content CenterModal" >
                <span className="close" onClick={closeAnswerModal}>&times;</span>

                <p className='modal-tit' style={{ color : '#5494f5'}}>
                  [{productInquiry.type}]
                </p>
                
                <p className='modal-tit'>{productInquiry.title}</p>

                <div className='modal-content-box' style={{marginBottom : '30px' }}>
                  <div className='editor-content'>
                    <p dangerouslySetInnerHTML={{ __html: productInquiry.content }}></p>
                  </div>
                </div>

                <p className='modal-tit'>문의 답변</p>
                <form>   
                  <textarea cols="30" rows="10" >
                    {/* {...register('answer', { required: '답변 입력은 필수입니다.' })}  > */}
                    {productInquiry.answer}
                  </textarea>

                  <div className='modal-button-wrap'>
                    {/* <button className='m-btn m-btn-search' type="submit">
                            {isLoading ?  <LoadingSpinner /> : '답변완료'} 
                    </button> */}
                  </div>
                </form>
            </div>
          </div>
   
        </>
    )
}

export default CheckAnswerModal