/***************************************************************************************
* FileName      : VendorHistory.js
* Description   : 해와관리자 > 셀러관리 > 활동기록
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.12
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import Vendor from './History/Vendor';
import Penalty from './History/Penalty';
import PenaltyNegative from './History/PenaltyNegative';
import Memo from './History/Memo';
import React, { useRef, useEffect , useState } from 'react';
function VendorHistory()
{
    const [activeComponent, setActiveComponent] = useState("Vendor");

    const handleButtonClick = (componentName) => {
        setActiveComponent(componentName);
    };


    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>활동기록</h2>
            </div>
            
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "Vendor" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Vendor")}>매장</button>

                <button className={activeComponent === "Penalty" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Penalty")}>패널티(점수)</button>

                <button className={activeComponent === "PenaltyNegative" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("PenaltyNegative")}>패널티(정지)</button>

                <button className={activeComponent === "Memo" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Memo")}>메모</button>
           
            </div>
      
            {/* 매장 */}
            {activeComponent === "Vendor" && <Vendor />}

            {/* 패널티 (점수) */}
            {activeComponent === "Penalty" && <Penalty />}

            {/* 패널티 (정지) */}
            {activeComponent === "PenaltyNegative" && <PenaltyNegative />}

            {/* 메모 */}
            {activeComponent === "Memo" && <Memo />}




        </div>

        </>
    )
}

export default VendorHistory