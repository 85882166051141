// 검색 > 기간 > 월 

const MonthSelect = ({ value, onChange }) => (
    <select className='mSelectBox mr6' value={value} onChange={onChange}>
        {Array.from({ length: 12 }, (_, index) => {
            const monthValue = (index + 1).toString();
            return (
                <option key={monthValue} value={monthValue}>
                    {`${index + 1}월`}
                </option>
            );
        })}
    </select>
);
  
export default MonthSelect