import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const QaRow  =  ({ index, qna }) => {

    const navigate = useNavigate();

    const handleOrderNumberClick = (Id) => {
        navigate(`./${Id}`);
    };

    return(
        <>
        <Row key={index} 
            className="border-bottom bg-white m-0 p-0 py-2"
            onClick={() => handleOrderNumberClick(qna.id)}
            >
            {/* 번호 */}
            <Col sm={1} md={1} className="text-center p-2 fs-7 fw-bold text-secondary d-none d-md-flex justify-content-center">
                {index} 
            </Col>

            {/* 상품명 */}
            <Col xs={3} md={3} className="text-start p-2 fs-7 fw-bold">
                {qna.product.length > 10 ? `${qna.product.slice(0, 10)}...` : qna.product}
            </Col>

            {/* 제목 */}
            <Col xs={5} md={5} className="text-start p-2 fs-7 fw-bold cursor-pointer">
                {qna.title}
            </Col>

            {/* 작성자 */}
            <Col xs={2} md={1} className="text-center p-2 fs-7 fw-bold text-secondary">
                {qna.user}

            </Col>
            {/* 작성일 */}
            <Col xs={1} md={2} className="text-center p-2 fs-7 fw-bold text-secondary">
                {qna.created_at.substring(0, 10)}
            </Col>
        </Row>
        {qna?.answer && 
            <Qanswer index={index} qa={qna}/>
        }
        </>
    )
}

function Qanswer({index, qa})
{
    const navigate = useNavigate();

    const handleOrderNumberClick = (Id) => {
        navigate(`./${Id}/answer`);
    };

    return (
        <>
        <Row key={index} 
            className="border-bottom bg-white m-0 p-0 py-2 pointer"
            onClick={() => handleOrderNumberClick(qa.id)}
            >
            {/* 번호 */}
            <Col sm={1} md={1} className="text-center p-2 fs-7 fw-bold text-secondary d-none d-md-flex justify-content-center">
                
            </Col>

            {/* 상품명 */}
            <Col xs={3} md={3} className="text-start p-2 fs-7 fw-bold">
                <span className="text-hw fw-bold">[{qa.status}] </span>
            </Col>

            {/* 제목 */}
            <Col xs={5} md={5} className="text-start p-2 fs-7 fw-bold">
                <span className='d-inline-block text-truncate w-100'>{qa?.answer}</span>
            </Col>

            {/* 작성자 */}
            <Col xs={2} md={1} className="text-center p-2 fs-7 fw-bold text-secondary">
                {/* {qa?.manager} */}   
                관리자
            </Col>
            {/* 작성일 */}
            <Col xs={1} md={2} className="text-center p-2 fs-7 fw-bold text-secondary">
                {qa.answered_at.substring(0, 10)}
            </Col>
        </Row>
        </>
    )
}