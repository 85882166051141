import React from 'react';

const GenderCheckboxGroup = ({ checkedState, onChange }) => (
    <div className="radio-wrap ml-2">
        <label>
            <input
                type="checkbox"
                name="total_sales"
                checked={checkedState.total_sales}
                onChange={onChange}
            />
            전체
        </label>
        <label>
            <input
                type="checkbox"
                name="male_sales"
                checked={checkedState.male_sales}
                onChange={onChange}
            />
            남성
        </label>
        <label>
            <input
                type="checkbox"
                name="female_sales"
                checked={checkedState.female_sales}
                onChange={onChange}
            />
            여성
        </label>
  </div>
);

export default GenderCheckboxGroup