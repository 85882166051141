import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';

function NotFound()
{
    return (
        <>
        <Container>
            <Row>
                <Col>
                    <h1>404 Not Found</h1>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default NotFound;