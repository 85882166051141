import {Row, Container} from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { changePassword } from '../../../api/services/memberApi';
//사용자페이지 > 아이디 찾기 > 결과 페이지 
function VerifyEmailPw()
{
    // 현재 페이지의 URL을 가져오기
    const url = window.location.href;

    // URL을 파싱하여 검색 매개변수를 추출
    const searchParams = new URL(url).searchParams;
    const email = searchParams.get("email");
    const userid = searchParams.get("username");
    const verification_code = searchParams.get("verification_code");

    const navigate = useNavigate();

    const [inputPw, setInputPw] = useState('');
    const [inputPw2, setInputPw2] = useState('');

    const handleSubmit = () => {
        changePassword(email, userid, verification_code, inputPw, inputPw2)
            .then(res => {
                alert(res?.data?.data);
                navigate('/member/login/');
            })
            .catch(error => {
                error?.response?.data?.data ? (
                    alert(error?.response?.data?.data)
                ) : (
                    alert("비밀번호 변경에 실패했습니다.")
                )
            })
    }
    return(
        <>
        <Container>
            <Row className='m-0 p-0'>
                <div className='find_wrap'>
                    <div id="contents">
                        <div className="find-titleArea">
                            <h2>비밀번호 변경</h2>
                            <p>        
                                새롭게 사용할 비밀번호를 입력해주세요
                            </p>
                        </div>

                        <div className='findId'>
                            <div className='name_view'>
                                <strong id="name_label">아이디</strong>
                                <input
                                    name="id"
                                    type="text"
                                    value={userid}
                                    disabled
                                />
                            </div>
                            <div className='email_view'>
                                <strong id="name_label">이메일</strong>
                                <input
                                    name="email"
                                    type="text"
                                    value={email}
                                    disabled
                                />
                            </div>
                            <div className='pw'>
                                <strong id="name_label">새 비밀번호</strong>
                                <input
                                    name="pw"
                                    placeholder='새로운 비밀번호를 입력해주세요'
                                    type="password"
                                    onChange={(e) => setInputPw(e.target.value)}
                                />
                            </div>
                            <div className='비밀번호'>
                                <strong id="name_label">비밀번호 확인</strong>
                                <input
                                    name="pw2"
                                    placeholder='비밀번호 확인'
                                    type="password"
                                    onChange={(e) => setInputPw2(e.target.value)}
                                />
                            </div>
                        </div>
                      
                        <div className='ec-base-button'>
                            <button onClick={() => handleSubmit()}>비밀번호 변경</button>
                        </div>
                    </div>
                </div>
            </Row>
            
        </Container>
        </>
    )
}

export default VerifyEmailPw