import React, { useEffect } from "react";
import useDateFormat from "../../../hooks/useDateFormat";

export const UserRow = ({ user, index, onCheckboxChange , isChecked}) => {
  
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    onCheckboxChange(user.id, checked);
  };
  return (
    <tr key={user.id}>
      <td>
        <input
            type="checkbox" 
            name="" 
            value=""
            onChange={handleCheckboxChange}
            checked={isChecked}
            />
      </td>
      {/* <td>{1 + index} {user.id}</td> */}
      <td>{user.id}</td>
      <td>{user?.username}</td>
      <td>{user?.name}</td>
      <td>{user?.gender}</td>
      <td>{user?.birth_date}</td>
      <td>{user?.email}</td>
      <td>{user?.phone_number}</td>
      <td>{user?.address?.address}<br/>{user?.address?.detail}</td>
      {/* <td>{user.address.id && user.address.id}</td> */}
      <td>{user?.type && user?.type}</td> 
      <td>{user?.status && user?.status}</td> 
      <td>{user?.mileage}</td>
      <td>{user?.order_count}</td>
      <td>{user?.order_total_amount}</td>
      <td>{useDateFormat(user?.date_joined)}</td>
      <td>{useDateFormat(user?.last_login)}</td>
      <td><button>메시지 전송</button></td>
    </tr>
  )
};