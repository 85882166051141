// 관리자 엑셀 다운로드
import { getExcel } from "../../../api/excelDownloadApi";

const handleExcelDownload = async (options) => {
    try {
        const response = await getExcel(options); // 서버에서 엑셀 파일 받기
    
        // await console.log(response);

        // Content-Disposition 헤더에서 파일 이름 추출
        const contentDisposition = response.headers['Content-Disposition'];

        let filename = 'downloaded-file.xlsx'; // 기본 파일 이름
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length > 1) {
                filename = filenameMatch[1];
            }
        }

        // 파일 다운로드 로직
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); 
        document.body.appendChild(link);
        link.click();

        // 정리
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file', error);
    }

};

export default handleExcelDownload;