import {Row, Col, Button , Navbar, Nav, Container, Tab , InputGroup, Form, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidemenu from '../Sidemenu.js'
import { fetchNoticeDetails } from '../../../api/services/customerApi.js'

export default function View()
{
    const { id } = useParams();
    const [NoticeDetails, setNoticeDetails] = useState(null);

    useEffect(() => {
        fetchNoticeDetails(id)
          .then(details => {
            setNoticeDetails(details);
          })
          .catch(error => {
            console.error('상세 조회 오류:', error);
          });
    }, [id]);

    return(
        <>
         <Container>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'notice'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <Notice NoticeDetails={NoticeDetails} />
                    </Col>
                </div>
            </Row>
        </Container>
        </>
    )

}

function Notice({ NoticeDetails })
{
    const navigate = useNavigate();
    const datePart = NoticeDetails?.created_at.split('T')[0];

    return (
        <>
         <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-4 fw-bold">공지사항</h3>
                </Col>
            </Row>

             {/* 콘텐츠 내용 */}
             <div className='board-wrap'>
                <div className='board-tit'>
                    <h2>{NoticeDetails?.title}</h2>
                </div>
                <div className='board-sub'>
                    <span>{NoticeDetails?.author}</span>
                    <span>{datePart}</span>
                    <span className='hit'>조회수 {NoticeDetails?.view_count}</span>
                </div>
                <div className='board-detail editor-content' dangerouslySetInnerHTML={{ __html: NoticeDetails?.description }} />
                <div className='board-btns'>
                    <button className='btnNormalFix' onClick={()=>navigate('/customer/notice')} >목록</button>
                </div>
            </div>
        </div>
        
        </>
    )
}