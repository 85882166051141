/***************************************************************************************
* FileName      : Write.js
* Description   : 고객 > 마이페이지 > 1:1 문의 > 1:1 문의 글쓰기
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useNavigate , useLocation } from 'react-router-dom';
import Sidemenu from '../Sidemenu.js';


import React, { useState, Component , useEffect} from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'ckeditor5-custom-hw';
import { BASE_URL } from '../../../api/apiConfig.js';

import { fetchProductTiny } from '../../../api/services/customerApi.js';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { RegisterOnetoone } from '../../../api/services/customerApi.js';

import { getAccessFromCookie } from '../../../api/cookieUtils.js';

export default function Write()
{
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();


    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };

    // 상세설명 
    const [detailedDescription, setDetailedDescription] = useState('');


    useEffect(()=>{
        console.log("------에디터")
        console.log(detailedDescription)
    },[detailedDescription])

    const createMutaion = useMutation(RegisterOnetoone , {
        onSuccess : (data) => {
            reset();
            alert("등록 완료");
            setIsLoading(false);
            navigate('/mypage/otoList/'); 
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    });

    const onSubmit = (data) => {
        console.log(data);
        setIsLoading(true);

        const newData = {
            title: data.title || "",
            content: detailedDescription || "",
            type : data.type || "",
        };

        createMutaion.mutate(newData);
    };


    return (
        <>
        <Container>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'oto'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                            <Row className='m-0 p-0'>
                                <Col className='m-0 p-0'>
                                    <h3 class="m-0 p-0 fs-5 fw-bold mb-4">1:1 문의쓰기</h3>
                                </Col>
                            </Row>
                    
                            <form onSubmit={handleSubmit(onSubmit)}>    
                                <div className='ec-base-table typeWrite pt-2'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>제목</th>
                                                <td>
                                                    <input
                                                        className={`${errors.title ? 'error-input' : ''}`}
                                                        placeholder='제목을 입력해 주세요'
                                                        {...register('title', { required: '제목 입력은 필수입니다.' })} 
                                                        type='text'  
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>문의종류</th>
                                                <td>
                                                    <select {...register('type', { required: '대상 선택은 필수입니다.' })}>
                                                        <option value="배송지연" selected="selected">배송지연</option>
                                                        <option value="반품문의">반품문의</option>
                                                        <option value="교환문의">교환문의</option>
                                                        <option value="취소문의">취소문의</option>
                                                        <option value="회원정보문의">회원정보문의</option>
                                                        <option value="주문결제문의">주문결제문의</option>
                                                        <option value="상품정보문의">상품정보문의</option>
                                                        <option value="기타문의">기타문의</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <Edit   
                                                        content={detailedDescription}
                                                        setContent={setDetailedDescription} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <section className="d-flex justify-content-end pt-5 gap-1">

                                    <button className='btnSubmitFix m'>취소</button>

                                    <button type="submit" className='btnSubmitFix size'>
                                        {isLoading ?  <LoadingSpinner /> : '등록'} 
                                    </button>

                                </section>

                            </form>
                        </div>

                      

                    </Col>
                </div>
                  
                
              
            </Row>
        </Container>
        </>
    );
}

function Edit({setContent})
{
    const [editorTemplate, setEditorTemplate] = useState('<p> <span style="font-family:나눔스퀘어 네오, Arial, sans-serif;">상세정보를 입력해주세요</span></p>');
    
    const access = getAccessFromCookie();

    return(
        <>
        <CKEditor
            editor={ CustomEditor }
            data = {editorTemplate}
            config={{
                simpleUpload: {
                    uploadUrl: `${BASE_URL}/image/directupload/`, // 이미지 업로드 URL
                    withCredentials: true,
                    headers: {
                        'Authorization': `Bearer ${access}`,
                        // 'Content-Type': 'application/json',
                    },
                },
                fontFamily: {
                    options: [
                        'default',
                        '나눔스퀘어 네오, Arial, sans-serif',
                        '조선100년체, Arial, noto-sans',
                        '함렛, Arial, noto-snas',
                        '에스코어드림, Arial, sans-serif',
                        '휴먼범석체, Arial, sans-serif',
                    ]
                },
                toolbar: [
                    'fontFamily',
                    'fontSize',
                    'fontColor',
                    'fontBackgroundColor',
                    'italic',
                    '|',
                    'alignment:left',
                    'alignment:center',
                    'alignment:right',
                    '|',
                    'insertTable',
                    'mediaEmbed',
                    'link',
                    '|',
                    'imageUpload',
                    'imageStyle:alignLeft', // 이미지 좌측 정렬
                    'imageStyle:alignCenter', // 이미지 우측 정렬
                    'imageStyle:alignRight', // 이미지 우측 정렬
                    '|',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                    '|',
                    'undo',
                    'redo',
                ],
                image: {
                    styles: ['alignLeft', 'alignCenter', 'alignRight'],
                    toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', 'imageTextAlternative'],
                },
            }}
            onReady={ editor => {
                console.log('에디터 사용준비!', editor)
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                console.log( {event, editor, data });
                setContent(data);
            }}
            onBlur={(event, editor) => {
                console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
                console.log('Focus.', editor);
            }}
            onError={ ( event, editor ) => {
                console.error( 'CKEditor error: ', event );
            }}
        />
        </>
    )
 
}