/***************************************************************************************
* FileName      : Mileage.js
* Description   : 고객 > 마이페이지 > 마일리지 사용 및 적립내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';
import { useMyMileageListQuery , getMyAvailableMileage, getMyMileage, getMyMileagePlan } from '../../api/services/mypageApi.js';
import Pagination from '../customer/common/Pagination.js';
import MileageRow from './Mileage/MileageRow.js';

export default function MileageLayout()
{
    const [availableMileage, setAvailableMileage] = useState(0); //사용가능한 마일리지
    const [expiringMileage, setExpiringMileage] = useState(0); //소멸 예정 마일리지
    const [saveMileage, setSaveMileage] = useState(0); //적립 예정 마일리지

  
    useEffect(()=>{
        getMyAvailableMileage()
        .then(res => {
          setAvailableMileage(res?.data?.mileage);
        })
      

        getMyMileagePlan()
        .then(res => {
          setExpiringMileage(res?.data?.expiring_mileages);
          setSaveMileage(res?.data?.expiring_mileages);
        })
    }, [])

    return(
    <>
      <Container>
          <StatusBar/>
          <Row className='m-0'>
            <div className='mypage_wrap'>
              <Col md={3} lg={3}><Sidemenu nowPage={'mileage'}/></Col>
              <Col xs={12} sm={12} md={9} lg={9}>
                  <Mileage 
                    availableMileage={availableMileage}
                    expiringMileage={expiringMileage} 
                    saveMileage={saveMileage}/>
              </Col>
            </div>
          </Row>
      </Container>
    </>
  )
}

function Mileage({ availableMileage, expiringMileage, saveMileage})
{

    return (
    <>
    <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
        <>
          <Row className='m-0 p-0'>
            <Col className='m-0 p-0'>
              <h3 class="m-0 p-0 fs-5 fw-bold">마일리지</h3>
            </Col>
          </Row>
        </>
        <div className='mt-3 p-0'>
          <Row className='m-0 p-0'>
            <Col xs={12} className='border m-0 p-3 fs-6 d-flex justify-content-between bg-hw-light'>
              <p className='fw-bolder'>보유 마일리지</p>
              <p className='text-hw fw-bold'>{availableMileage.toLocaleString()}원</p>
            </Col>

            <Col xs={12} className='border m-0 p-3 fs-6 d-flex justify-content-between'>
              <p className='fw-bolder'>소멸예정 마일리지</p>
              <p className='text-hw fw-bold'>{expiringMileage.toLocaleString()}원</p>
            </Col>

            <Col xs={12} className='border m-0 p-3 fs-6 d-flex justify-content-between'>
              <p className='fw-bolder'>적립예정 마일리지</p>
              <p className='text-hw fw-bold'>{saveMileage.toLocaleString()}원</p>
            </Col>
          </Row>
        </div>

        <div className='mt-5 p-0'>
          <Row className='m-0 p-0'>
            <Col xs={12} className='m-0 p-0 fs-6 fw-bold'>사용 및 적립내역</Col>
          </Row>
          <Row className='mt-3 mb-3 ms-0 me-0 p-0'>
            <MileageHistoryBox />
          </Row>
        </div>
      </div>
    </>
  )
}


function MileageHistoryBox()
{
    const { data:myMileageData, error, isFetching, page, setPage } = useMyMileageListQuery();

    if (!myMileageData) {
        return <div>Loading...</div>;
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    // console.log(myMileageData);
    // console.log("=====")

    return(
      <>
      <table className='mypage-table'>
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '50%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <thead>
            <tr>
                <th>적용/예상일자</th>
                <th>적용내용</th>
                <th>적립/사용</th>
            </tr>
          </thead>
          <tbody className='history'>
            {/* 마일리지 리스트 */}
              {myMileageData?.totalCnt === 0 ? (
                    <p className="text-center my-5">보유중인 마일리지가 없습니다.</p>
              ) : (
                myMileageData?.data?.map((data, index) => (
                    <MileageRow
                        data={data}
                        index={index}
                    />
                ))
              )}
          </tbody>
      </table>

      {/* 페이지내비게이션 */}
      <section className='mt-5'>
        <Pagination
            count={myMileageData?.totalCnt || 0}
            page={page}
            setPage={setPage}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
        />
      </section>

    
      
      </>  
    )
}
