/***************************************************************************************
* FileName      : WriteReviewModal.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 작성 가능한 리뷰 > 리뷰쓰기 모달창
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import StarRating from './StarRating';
import { useMutation } from 'react-query';
import { RegisterReview } from '../../../api/services/mypageApi';
import LoadingSpinner from '../../components/LoadingSpinner.js';
import { getUploadURL , uploadImage } from '../../../api/uploadImage/uploadImageApi';
import { useNavigate } from 'react-router-dom';
import UploadImages from '../Component/UploadImages.js';

function WriteReviewModal({orderItem , setIsOpen})
{
    const navigate = useNavigate();
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    const [images, setImages] = useState([]);
  
    const [rating, setRating] = useState(0);

    const [content, setContent] = useState('');

    const [uploadedImages, setUploadedImages] = useState({
        image: [],
    });
    const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(3).fill(''));

   
    const handleInputChange = (e) => {
        setContent(e.target.value); 
    };


    const closeModal = () => {
        setIsOpen(false);
    };



    // 유효성 검사
    const validateForm = () => {
        let isValid = true;

        if(rating === 0){
            alert("상품 별점을 입력해주세요.");
            return;
        }

        if(content.length === 0){
            alert("리뷰를 입력해주세요.");
            return;
        }

        if(content.length < 15){
            alert("최소 15자 이상 작성해주세요.");
            return;
        }

        return isValid;
    }


    const createMutaion = useMutation(RegisterReview , {
        onSuccess : (data) => {
            alert("리뷰등록이 완료 되었습니다.");
            setIsLoading(false);
            navigate('/mypage/review/'); 
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    });

    // 이미지 파일 업로드
    const uploadImages = async () => {  
        try {
            if(images.length > 0){
                const updatedImages = [];
                for (const ImageFile of images) {
                    const ImageURL = await getUploadURL(ImageFile);
                    if (ImageURL) {
                        const imageURL = await uploadImage(ImageURL);
                        updatedImages.push(imageURL);
                    }
                }
                uploadedImages.image = updatedImages;  
            }
        } catch (error) {
            console.error("이미지 업로드 에러:", error);
        }
    };
    
    const handleSubmit = async () => {
        if(validateForm()){
       
            setIsLoading(true);

            await uploadImages();
            
            const newData = {
                orderitem: orderItem.id || "",
                rating: rating || "",
                content:content || "",
                review_images : uploadedImages.image || "",
            };

            createMutaion.mutate(newData);
           
        }
  
    };

    console.log(orderItem);
    return (
        <div className="modal ">
            <div className="modal-content ReviewWModal" >
                <header className='rmheader'>
                    <h3>리뷰 작성</h3>
                    <span className="close" onClick={closeModal}>&times;</span>
                </header>
            
                {/* 상품정보 */}
                <Row className='pb-2 border-bottom border-1 border-gray'>
                    <Col sm={3} md={2} className=''>
                        <img className='w-100'src={orderItem?.product.main_image} alt="상품 이미지"/>
                    </Col>
                    <Col sm={9} md={10} className='text-left'>
                        <p>{orderItem?.product.name}</p>
                        <p>{orderItem?.product.vendor_name}</p>
                    </Col>
                </Row>
      
               {/* 적입 예상 마일리지 ? */}
               <Row>

               </Row>
               
               {/* 상품 평가 */}
               <Row className='p-2 border-bottom border-1 border-gray'>
                    <Col sm={6} md={6} className="pd-rwr">
                        <span>상품은 만족하셨나요?</span>
                    </Col>
                    <Col sm={6} md={6}>
                        <StarRating 
                            rating={rating}
                            setRating={setRating}
                        />
                    </Col>
               </Row>

     
                {/* 후기 작성 */}
                <Row className='p-2 border-bottom border-1 border-gray'>
                    <textarea 
                        className="pd-rwr-input " 
                        rows="6" 
                        value={content} 
                        onChange={handleInputChange}
                        placeholder="최소 15자 이상 작성해주세요."/>
                </Row>

                {/* 후기 이미지 등록 */}              
                <UploadImages images={images} setImages={setImages}/>


                <div className='rWmodal-button-wrap '>
                    <button className='m-btn m-btn-search'
                            onClick={()=>handleSubmit()}>
                            {isLoading ?  <LoadingSpinner /> : '등록하기'} 
                    </button>
                </div>
                
            </div>
    </div>
    )
}

export default WriteReviewModal