import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toggleProductLike } from '../../../api/mainApi.js';

function WishBox({wishData})
{    
    const navigate = useNavigate();

    const handleOrderNumberClick = (productId) => {
        navigate(`/product/detail/${productId}`);
    };

    const createLikeMutaion = useMutation(toggleProductLike , {
        onSuccess : (data) => {
            console.log("좋아요성공",data);
            window.location.reload();
        },
        onError : (data) => {
            alert(data.request.responseText);
        }
    });


    const toggleWish = () => {
        const userConfirmed = window.confirm('상품을 삭제하시겠습니까?');

        if (userConfirmed) {
            const data = {
                product: wishData?.id,
            };
            createLikeMutaion.mutate(data);
        }

    };

    return(
        <tr key={wishData?.id}>
            <td className='subject pointer' onClick={() => handleOrderNumberClick(wishData?.id)}>
                <div className='area'>
                    <a className='thum'>
                        <img src={wishData?.main_image} alt="Product" />
                    </a>
                    <div className='textus'>
                        <a>
                            <span className='tit'>
                                {/* {wishData?.vendor_name}(vendor이름이 없음) */}
                            </span>
                            <span className='txt'>
                                {wishData?.name}
                            </span>
                        </a>
                    </div>
                </div>
            </td>
            <td>
                <p className='sale'>{wishData?.original_price.toLocaleString()}<em>원</em></p>
                <p className='won'>{wishData?.price.toLocaleString()}<em>원</em></p>
            </td>
            <td>
                <button className='mpbtn1' onClick={()=>{toggleWish()}}>삭제</button>
            </td>
          
    </tr>


    )
}

export default WishBox