import { Row, Col, Badge } from 'react-bootstrap';

// bootstrap icon
import { PiBookmarkSimpleDuotone } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";

export function Card(props)
{
    let categoryDepth2 = props?.product?.category?.[2]?.name;
    return(
        <Col xs={6} sm={4} md={4} lg={3} className="item p-2 px-2">
            <a href={"/product/detail/"+props?.product?.id} className='pointer'> 
            <div className="bg-white border overflow-hidden rounded-2">
                <div className='thumb'>
                    {/* <div className='item-like' onClick={()=>console.log('hi')}><PiBookmarkSimpleDuotone/></div> */}
                    
                        <img src={props?.product?.main_image}/>
                </div>
                <div className='prd-info py-3 px-2'>
                    <span className='prd-seller mt-1 mb-1'>{props?.product?.vendor?.name}</span>
                    <div className='prd-name-wrap'>
                        <span className='prd-name fs-6 fw-bold'>{props?.product?.name}</span>
                    </div>
                    
                    <Row className="m-0 mt-2 p-0">
                        <Col className="m-0 p-0">
                            <span className='prd-price text-start'>
                                {props?.product?.sale_percent}%
                            </span>
                        </Col>
                        <Col md="auto" sm="auto" xs="auto" className="m-0 p-0">
                            <span className='prd-price-sub d-inline'>
                                {(props?.product?.original_price === undefined ? 0 : props?.product?.original_price).toLocaleString()}원
                            </span>
                            <span className='prd-price d-inline me-1'>
                                {(props?.product?.price === undefined ? 0 : props?.product?.price).toLocaleString()}원
                            </span>
                        </Col>
                    </Row>
                    <div className='badge-area'>
                        {/* 카테고리 생물/건조/반건조 키워드가 있는경우만 표시 */}
                        {categoryDepth2 && (
                            <div className={categoryDepth2 === '생물' ? 'prd-badge red' : categoryDepth2 === '냉동' ? 'prd-badge blue' : 'prd-badge grey'}>{props?.product?.category[2]?.name}</div>
                        )}
                        {props?.product?.delivery_info?.shipping_cost_type === '무료배송' && (
                            <div className='prd-badge grey'><TbTruckDelivery className='fs-6' /> 무료배송</div>
                        )}
                    </div>

                </div>
                {/* <img src={'http://codingapple1.github.io/shop/shoes' + (props.i+1) + '.jpg'} width="80%"/> */}
            </div>

            </a>
        </Col>
    )
}


export function SliderCard(props)
{
    let categoryDepth2 = props?.product?.category?.[2]?.name;
    return(
        <div className="item p-2 px-2">
            <a href={"/product/detail/"+props?.product?.id} className='pointer'> 
            <div className="bg-white border overflow-hidden">
                <div className='thumb'>
                    {/* <div className='item-like' onClick={()=>console.log('hi')}><PiBookmarkSimpleDuotone/></div> */}
                    
                        <img src={props?.product?.main_image}/>
                </div>
                <div className='prd-info py-3 px-2'>
                    <span className='prd-seller mt-1 mb-1'>{props?.product?.vendor?.name}</span>
                    <div className='prd-name-wrap'>
                        <span className='prd-name fs-6 fw-bold'>{props?.product?.name} 글자 수 많이많이 적어보기</span>
                    </div>
                    
                    <Row className="m-0 mt-2 p-0">
                        <Col className="m-0 p-0">
                            <span className='prd-price text-start'>
                                {props?.product?.sale_percent}%
                            </span>
                        </Col>
                        <Col md="auto" sm="auto" xs="auto" className="m-0 p-0">
                            <span className='prd-price-sub d-inline'>
                                {(props?.product?.original_price === undefined ? 0 : props?.product?.original_price).toLocaleString()}원
                            </span>
                            <span className='prd-price d-inline me-1'>
                                {(props?.product?.price === undefined ? 0 : props?.product?.price).toLocaleString()}원
                            </span>
                        </Col>
                    </Row>
                    <div className='badge-area'>
                        {/* 카테고리 생물/건조/반건조 키워드가 있는경우만 표시 */}
                        {categoryDepth2 && (
                            <div className={categoryDepth2 === '생물' ? 'prd-badge red' : categoryDepth2 === '냉동' ? 'prd-badge blue' : 'prd-badge grey'}>{props?.product?.category[2]?.name}</div>
                        )}
                        {props?.product?.delivery_info?.shipping_cost_type === '무료배송' && (
                            <div className='prd-badge grey'><TbTruckDelivery className='fs-6' /> 무료배송</div>
                        )}
                    </div>

                </div>
                {/* <img src={'http://codingapple1.github.io/shop/shoes' + (props.i+1) + '.jpg'} width="80%"/> */}
            </div>

            </a>
        </div>
    )
}

export default Card;