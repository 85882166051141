import React, { useState , useEffect} from 'react';
import AquaticInfo from './aquaticInfo/AquaticInfo'
import ProcessedfoodInfo from './processedfoodInfo/ProcessedfoodInfo'

function ProductInfoNotice({prdIfSelected, setprdIfSelected , onAquaticInfo , onProcessedfoodInfo})
{
    const [selectedOption, setSelectedOption] = useState(''); // 초기 선택 값

    const handleOptionChange = (e) => {

        const userConfirmed = window.confirm(
            "변경시, 다시 정보를 등록해야합니다. 그래도 실행하시겠습니까?"
        );
        
        if (userConfirmed) {
            setSelectedOption(e.target.value);
            setprdIfSelected(e.target.value)
        }
      
    };

    // 농축수산물 
    const [aquaticInfoData , setAquaticInfoData] = useState({ 
        name : "", // 상품명
        volume : "", // 용량,수량,크기
        manufacturer : "", // 생산자(수입자)
        origin : "", // 원산지
        manufactured_date : "", // 제조연월일
        mark_detail : "" , // 세부 품목군별 표시사항
        imported_food_text : "", // 수입식품 문구 여부 
        package : "", // 상품구성
        storage_method:"", // 보관방법 또는 취급방법
        caution:"", // 소비자안전을 위한 주의사항
        cs:"", // 소비자 상담 관련 전화번호 
    });

    // 가공식품
    const [processedfoodInfo , setProcessedfoodInfo] = useState({
        name : "", //상품명
		foodtype : "", //식품의 유형
		manufacturer : "", //생산자 및 소재지 
		manufactured_date : "", //제조연월일
		volume : "", // 포장단위별 내용물의 용량, 수량
		material : "", // 원재료명
		nutrition : "", // 영양성분
		mark_detail : "", // 유전자 변형 수산물
		caution : "", // 소비자 안전을 위한 주의사항
		imported : "", // 수입식품 여부에 따라 수입신고 
		cs : "" // 소비자 상담 관련 전화번호
    });
 
    useEffect(() => {
        onAquaticInfo(aquaticInfoData);
    }, [aquaticInfoData]);


    useEffect(() => {
        onProcessedfoodInfo(processedfoodInfo);
    }, [processedfoodInfo]);

    

    return (
        <>
        <span>판매상품에 여러 구성품이 포함되어 있는 경우 모든 구성품에 대해 '상품정보제공고시'를 상품 상세페이지에 제공해주세요.</span>
        <p className="notice-text">
            2023년부터 [전자상거래 등에서의 상품 들의 정보제공에 관한 고시] 계정으로 인해 2개 이상의 제품으로 점들 구성되었거나 다른 부속 구성품이 포함되어있는 경우
            <br/>
            모든 구성품에 대해서 상품정보제공고시를 제공해야 합니다.
        </p>

        <section className="ProductInfoNotice-Wrap">
            <select className="mSelectBox" onChange={handleOptionChange} value={selectedOption}>
                <option value="">선택하세요</option>
                <option value="농축수산물">농축수산물</option>
                <option value="가공식품">가공식품</option>
            </select>

            {selectedOption === '농축수산물' && (
              <AquaticInfo
                aquaticInfoData={aquaticInfoData}
                setAquaticInfoData={setAquaticInfoData}
              />
            )}

            {selectedOption === '가공식품' && (
              <>
                <ProcessedfoodInfo
                    processedfoodInfo={processedfoodInfo}
                    setProcessedfoodInfo={setProcessedfoodInfo}
                />
              </>
            )}
        </section>
        </>
    )
}

export default ProductInfoNotice