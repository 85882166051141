import { getUploadURL , uploadImage } from '../../../api/uploadImage/uploadImageApi';
import React, { useState } from 'react';

function UploadImages({images , setImages})
{    
    const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(3).fill(''));

            
    const handleImageChange = (e, index) => {
        e.preventDefault();
    
        const file = e.target.files[0];
        
        const updatedImages = [...images];
        updatedImages.push(file);
        setImages(updatedImages);
        

        const reader = new FileReader();
        reader.onloadend = () => {
          const newImagePreviewUrls = [...imagePreviewUrls];
          newImagePreviewUrls[index] = reader.result;
          setImagePreviewUrls(newImagePreviewUrls);
        }

        reader.readAsDataURL(file);
    }


    const removeImageAtIndex = (index) => {
        setImages(images.filter((_, idx) => idx !== index));
    };

    // 이미지 삭제 기능 함수 
    const handleImageDelete = (index) => {
        setImagePreviewUrls((prevImagePreviewUrls) => {
            const newImagePreviewUrls = [...prevImagePreviewUrls];
            newImagePreviewUrls[index] = '';
            return newImagePreviewUrls;
        });
        removeImageAtIndex(index);
    };
    
    return (
        <>
        <div className="productImageUploader">
            {[...Array(3)].map((_, index) => (
                <div className="imageUploadContainer" key={index}>
                    <label htmlFor={`fileInput${index}`} className="fileInputLabel">
                        <div className="imagePreviewContainer">
                            {imagePreviewUrls[index] ? (
                                <img 
                                    src={imagePreviewUrls[index]} 
                                    alt={`Product ${index + 1}`} 
                                    className="imagePreview" />
                                ) : (
                                    <div className="imagePlaceholder"></div>
                                )}
                                {imagePreviewUrls[index] && (
                                    <button 
                                        className="deleteImageButton" 
                                        onClick={() => handleImageDelete(index)}>
                                    X
                                    </button>
                                )}
                        </div>
                        <input
                            id={`fileInput${index}`}
                            type="file"
                            className="fileInput"
                            accept=".jpg,.jpeg,.png"
                            key={imagePreviewUrls[index]}
                            onChange={(e) => handleImageChange(e, index)}
                        />
                    </label>
        
                </div>
            ))}
        </div>
        </>
    )
}
export default UploadImages