import { instance } from '../../axiosInstance/axiosConfig';

// 일반 사용자가 전체 입점사 조회
export const getVendorApproved = ()=>{
    return instance.get('/vendor/customer/?page_size=all')
}

// 일반 사용자가 특정 입점사 상품 리스트 조회
export const getVendorProduct = (id) => {
    return instance.get(`/product/customer/document/?vendor_id=${id}`)
}