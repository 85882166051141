/***************************************************************************************
* FileName      : OrderDetail.js
* Description   : 고객 > 마이페이지 > 반품/취소내역 > 취소 주문상세 내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Container, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Sidemenu from '../Sidemenu.js';
import StatusBar from '../StatusBar.js';
import ko from 'date-fns/locale/ko'; // 날짜 선택필드 한국어로 변경

import { fetchMyOrderItemDetail } from '../../../api/services/mypageApi.js';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { extractYearMonthDayHourMinute2 } from '../Component/extractDateUtils.js';
import OrderRow from '../MyorderList_return/OrderRow2.js';
import MOrderDetailRow from './MOrderDetailRow.js';

function OrderInquiryLayout(){
  return(
    <>
    <Container>
        <StatusBar/>
        <Row className='m-0'>
            <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'cancel'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <OrderCancelDetail />
                </Col>
            </div>
        </Row>
    </Container>
    </>
  )
}

function OrderCancelDetail()
{
    const navigate = useNavigate();

    const { id } = useParams();

    const [orderDetail, setOrderDetail] = useState(null);

    useEffect(() => {
        fetchMyOrderItemDetail(id)
          .then(details => {
            setOrderDetail(details);
            console.log("상세데이터")
            console.log(details)
          })
          .catch(error => {
            console.error('상품 상세 조회 오류:', error);
          });
    }, [id]);

    let dataset = {};
    if (orderDetail) {

        dataset = {
            id:orderDetail.id,
            order_number:orderDetail.order_number || '',
            ordered_at: orderDetail.ordered_at || '',
            product_name: orderDetail.product_name || '',
            vendor_name : orderDetail.vendor_name || '',
            main_image: orderDetail.main_image || [],
            total_price : orderDetail.total_price,
            status:orderDetail.status,
            confirmation_status:orderDetail.confirmation_status
          }

    }else{
        dataset = {};
    }

    const handleClick = (orderId,status) => {
     
    };
    console.log(orderDetail);
    console.log("orderDetail")
    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <>
            <Row className='m-0 p-0 mb-4'>
                <Col xs={12} sm={12} className='m-0 p-0'>
                      <h3 class="m-0 p-0 fs-5 fw-bold">주문상세내역 </h3>
                </Col>
            </Row>
            
            <ul className='mypage-infor-data mb-4'>
                <li>
                    <span>주문일자:</span>
                    <strong>{extractYearMonthDayHourMinute2(dataset?.ordered_at)}</strong>
                </li>
                <li>
                    <span>주문번호:</span>
                    <strong>{dataset?.order_number}</strong>
                </li>
                
            </ul>

            <div className='area-over mt-2'>
                <h3 className='mypage-sub-title mb-2'>배송상품</h3>
            </div>
            <table className='mypage-table' id="mypage-order-table">
                <colgroup>
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                </colgroup>
                <thead >
                    <tr>
                        <th>상품명</th>
                        <th>판매가</th>
                        <th>수량</th>
                        <th>배송비</th>
                        <th>진행현황</th>
                    </tr>
                </thead>
            
                <tbody className='history'>
                    {orderDetail?.orderitem_option_rows?.map((orderItem, index) => (
                        <tr key={orderItem.id}>
                            <OrderRow  
                                deliveryItem={orderDetail}   
                                orderItem={orderItem} />
                        </tr>
                    ))}  
                </tbody> 
            </table>

             {/* 모바일 */}
             <div className='mOrderContents' id="mContents">
                <div>
                    {orderDetail?.orderitem_option_rows.map((orderItem , index) => 
                        (
                        <MOrderDetailRow
                            key={orderItem.id}
                            deliveryItem={orderDetail}
                            orderItem={orderItem}
                        />       
                        ))
                    }
                </div>
            </div>


            <div className='area-over mt-5'>
                <h3 className='mypage-sub-title mb-2'>구매자 정보</h3>
            </div>
            <table className='mypage-table view'>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>주문자</th>
                        <td colSpan={3}>
                            {orderDetail?.order?.orderer_name}
                        </td>
                    </tr>
                    <tr>
                        <th>이메일주소</th>
                        <td colSpan={3}>
                            {orderDetail?.order?.orderer_email}
                        </td>
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td colSpan={3}>{orderDetail?.order?.recipient_phone_number}</td>
                    </tr>
                </tbody>
            </table>
            <div className='area-over mt-5 '>
                <h3 className='mypage-sub-title mb-2'>배송지 정보</h3>
            </div>
            <table className='mypage-table view mb-4'>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>받는사람</th>
                        <td colSpan={3}>{orderDetail?.order?.recipient_name}</td>
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td colSpan={3}>{orderDetail?.order?.recipient_phone_number}</td>
                    </tr>
                    <tr>
                        <th>주소</th>
                        <td colSpan={3}>
                            [{orderDetail?.order?.postal_code}]&nbsp;
                            {orderDetail?.order?.address} &nbsp;
                            {orderDetail?.order?.detail_address} 
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className='area-over mt-5'>
                <h3 className='mypage-sub-title mb-2'>결제 정보</h3>
            </div>
            <table className='mypage-table view'>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>결제수단</th>
                        <td colSpan={3}>
                            {orderDetail?.order?.pay_method}
                        </td>
                    </tr>
                    <tr>
                        <th>총 상품금액</th>
                        <td colSpan={3}>
                            {orderDetail?.returns?.refund_amount > 0 ?
                                (orderDetail?.returns?.refund_amount ?? 0).toLocaleString() :
                                (orderDetail?.order?.original_total_price ?? 0).toLocaleString()}원
                        </td>
                    </tr>
                    <tr>
                        <th>배송비</th>
                        <td colSpan={3}>
                            {orderDetail?.returns?.refund_delivery_fee > 0 ?
                            (orderDetail?.returns?.refund_delivery_fee ?? 0).toLocaleString():
                            (orderDetail?.order?.total_delivery_fee ?? 0).toLocaleString()}원
                           
                        </td>
                    </tr>
                    <tr>
                        <th>할인</th>
                        <td colSpan={3}>
                            {orderDetail && 
                                orderDetail.discount_price ? 
                                orderDetail.discount_price.toLocaleString() : "0"}원
                        </td>
                    </tr>
                    <tr>
                        <th>총 주문금액</th>
                        <td colSpan={3}>
                            { orderDetail?.returns?.total_refund_amount > 0 ?
                                (orderDetail?.returns?.total_refund_amount ?? 0).toLocaleString(): 
                                (orderDetail?.order?.total_price ?? 0).toLocaleString()}원
                        </td>
                    </tr>
                 
                </tbody>
                
            </table>
{/*             
            {myOrderData && myOrderData?.data?.map((orderItem, index) => (
              <div key={orderItem?.id}>
                  <OrderRow
                      orderItem={orderItem}
                      index={index}
                  />
              </div>
          ))} */}

      
                
        </>
        </div>  
        </>
    )
}


export default OrderInquiryLayout