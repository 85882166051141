
/***************************************************************************************
* FileName      : Category.js
* Description   : 해와관리자 > 상품관리 > 활동기록 > 카테고리
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SortComponent from "../../common/SortComponent2";
import PageSizeSelect from "../../common/PageSizeSelect";
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils";
import Pagination from '../../common/Pagination';
import { useCategoryRecordQuery } from "../../../../api/hwadmin/productApi";

function Category()
{
    const navigate = useNavigate();
  
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useCategoryRecordQuery(ordering , pageSize);

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value));
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

        
    console.log(data);
    
    return(
        <>
             <section className='McontentBox'>
            <div className='subTitle'>
                <div className=''>
                    <span>결과</span>
                    <span className='result-wrap'>
                        (총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)
                    </span>
                </div>
                <div className='selectWrap'>
                    <SortComponent
                        ordering={ordering}
                        handleOrderingChange={handleOrderingChange}
                        newLabel="최근활동순"
                        oldLabel="오래된활동순"
                    />

                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                </div>
            </div>
                 
            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                        {/* <col style="width:10%"/>
                        <col style="width:10%"/>
                        <col style="width:10%"/>
                        <col style="width:10%"/>
                        <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>카테고리명</th>
                            <th>구분</th>
                            <th>활동</th>
                            <th>변경일시</th>
                            <th>처리자</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data?.data.map((category, index) => (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{category.name}</td>
                            <td>{category.type}</td>
                            <td>{category.activity}</td>
                            <td>{category.manager_name}</td>    
                            <td>{category.created_at}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </section>

        {/* 페이지내비게이션 */}
        <section className='mt-3'>
            <Pagination
                count={data?.totalCnt || 0}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
            />
        </section>
        </>
    )
}

export default Category