/***************************************************************************************
* FileName      : NewRequest.js
* Description   : 해와관리자 > 셀러관리 > 신규요청 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container, Modal} from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNewRequestStoreQuery , handleSearch, setVendorState } from '../../../api/hwadmin/storeApi';
import PageSizeSelect from '../common/PageSizeSelect';
import SortComponent from '../common/SortComponent2';
import { VendorRow } from "./NewRequest/VendorRow";
import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'

function Request()
{
    const [ordering, setOrdering] = useState('created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useNewRequestStoreQuery(ordering , pageSize);

    const [searchVendor, setSearchVendor] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    
    // 선택한 입점사 Row
    const [selectedRows, setSelectedRows] = useState({});
    // 선택한 입점사 Row의 데이터값
    const [selectedRowsData, setSelectedRowsData] = useState({});
    // 입점사 승인 컨펌창 상태
    const [approveVendor, setApproveVendor] = useState(false);
    const [rejectVendor, setRejectVendor] = useState(false);

    useEffect(()=>{
        console.log(selectedRows);
    },[selectedRows])

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 입점사 승인 컨펌이 true면 승인여부 물어보기
    useEffect(()=>{
        if(approveVendor){
            // 선택된 체크박스 id 값
            let selectList = Object.keys(selectedRows).filter(key => selectedRows[key] === true)
            // 승인 요청 컨펌창
            if(window.confirm(selectList.length+"건의 입점 등록을 승인 하시겠습니까?")){
                // 입점 승인/거절 요청할 API 묶음
                const approvePromises = selectList.map(id => setVendorState(id, "approve", null, data.data.find(x => x.id === ~~id).status));
                // 입점 승인/거절 요청
                Promise.all(approvePromises)
                    .then(results => {
                        results.forEach(res => console.log(res.data));
                        alert('판매자가 등록되었습니다.');
                    })
                    .catch(error => alert(error?.response?.data?.product))
                    .finally(()=>window.location.reload());
            }else{
                alert('판매자 등록이 취소되었습니다.');
                window.location.reload();
            }
            setApproveVendor(false);
        }
    }, [approveVendor])

    const [modalIsOpen, setModalIsOpen] = useState(false); //입점거절모달
    const [vendorUpdateData, setVendorUpdateData] = useState({});// 모달에서 수정한 값 담을 Object 생성
    // 상품 거절 컨펌이 true면 거절 사유 입력할 모달 창 출력
    useEffect(()=>{
        if(rejectVendor){
            setModalIsOpen(true);
        }
        setRejectVendor(false);
    },[rejectVendor])
    // 모달 [확인] 버튼 클릭 시 거절 API 요청
    const postRejectVendor = () =>{
        // 선택된 체크박스 id 값
        let selectList = Object.keys(selectedRows).filter(key => selectedRows[key] === true)
        // 입점 거절 요청할 API 묶음
        const rejectPromises = selectList.map(id => setVendorState(id, "reject", vendorUpdateData[id].rejectValue, data.data.find(x => x.id === ~~id).status));
        Promise.all(rejectPromises)
            .then(results => {
                results.forEach(res => console.log(res.data));
                alert('입점이 거절되었습니다.');
            })
            .catch(error => alert(error?.response?.data?.product))
            .finally(()=>window.location.reload())
    }


    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');


    // 요청구분
    const [selectedRequestType, setSelectedRequestType] = useState('');

    // 검색중인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    // const [pageSize, setPageSize] = useState(10); 

    // const totalPages = Math.ceil(data.totalCnt / data.count) ;

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    if(!data) return <div/>;

    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleSearch({
            searchVendor, 
            setSearchResults,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedRequestType,
            setSearchUrl,
        });
        setSearchConfirm(true);
    };
      
    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/admin/store/request/'; // 페이지 리로드
    };


        
    const vendertotalCnt = data.totalCnt;
    // console.log("ddd"+data.totalCnt)
    //console.log(vendertotalCnt)

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    // 요청구분 선택 
    const handleRequestTypeRadioChange = (event) => {
        setSelectedRequestType(event.target.value);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
      
    console.log(data)

    console.log(searchResults);
    console.log("searchResults--")
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>신규요청(신규 입점 요청, 매장 정보 수정)</h2>
            </div>  

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">매장이름</option>
                                <option value="owner">관리자ID</option>
                                <option value="address">주소</option>
                                <option value="businessNumber">사업자등록번호</option>
                            </select>
                            <input type="text"
                                    value={searchVendor}
                                    onChange={(e) => setSearchVendor(e.target.value)}/> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="apply">신청일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                    <div className='search-field division' style={{ margin: '20px 0px' }}>
                        <div> 
                            <b>요청구분</b> 
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='g1' name='group1' value='' checked={selectedRequestType === ''} onChange={handleRequestTypeRadioChange} />
                            <label htmlFor='g1'>전체</label>
                            <input type='radio' id='g2' name='group1' value='입점요청' checked={selectedRequestType === '입점요청'} onChange={handleRequestTypeRadioChange} />
                            <label htmlFor='g2'>입점요청</label>
                            <input type='radio' id='g3' name='group1' value='정보수정' checked={selectedRequestType === '정보수정'} onChange={handleRequestTypeRadioChange} />
                            <label htmlFor='g3'>정보수정</label>
                        </div>   
                    </div>
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{vendertotalCnt && vendertotalCnt}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="최근요청순"
                                oldLabel="오래된요청순"
                            />
    
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
                    <div className='m-btn-wrap-sm'>
                        <button className='m-sm-btn'>이메일 발송</button>
                        <button className='m-sm-btn'>SMS 발송</button>
                        <button className='m-sm-btn' onClick={()=> setApproveVendor(true)}>승인</button>
                        <button className='m-sm-btn' onClick={()=> setRejectVendor(true)}>반려</button>
                    </div>
                    <div className='fixTable_wrap'>
                        <table className='sTable' id='scrollTable'>
                            <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="sticky-col-1">
                                        <input type="checkbox" name="Allcheck" value=""/>
                                    </th>
                                    <th className="sticky-col-2">번호</th>
                                    <th className='sticky-col-3'>상호</th>
                                    <th>요청상태</th>
                                    <th>관리자ID</th>
                                    <th>구분</th>
                                    <th>사업자등록증</th>
                                    <th>통신판매신고증</th>
                                    <th>사업자등록번호<br/>통신판매신고번호</th>
                                    <th>수정사항</th>
                                    <th>출고지</th>
                                    <th>반품지</th>
                                    <th>
                                        회사전화<br/>
                                        회사팩스
                                    </th>
                                    <th>공개여부</th>
                                    <th>추가관리자</th>
                                    <th>사업장주소</th>
                                    <th>대표자 사진</th>
                                    <th>통장사본</th>
                                    <th>
                                        은행<br/>
                                        계좌번호
                                    </th>
                                    <th>
                                        입점신청일
                                    </th>
                                    <th>
                                        공동대표여부
                                    </th>
                                    <th>
                                        과세여부
                                    </th>
                                    <th>
                                        승인 / 반려
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {searchResults.count > 0 ? (
                                // 검색 결과를 렌더링하는 코드
                                searchResults.data.map((vendor, index) => (
                                    <VendorRow
                                        vendor={vendor}
                                        index={index}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        selectedRowsData={selectedRowsData}
                                        setSelectedRowsData={setSelectedRowsData}
                                        setApproveVendor={setApproveVendor}
                                    />
                                ))
                            ) : (
                                searchConfirm && searchResults.count === 0 ? (
                                    <tr>
                                        <td colSpan="23">검색 결과가 없습니다.</td>
                                    </tr>
                            ) : (
                                data && data.data && data.data.length > 0 && (
                                    // 기본 데이터를 렌더링하는 코드
                                    data.data.map((vendor, index) => (
                                        <VendorRow
                                            vendor={vendor}
                                            index={index}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                            selectedRowsData={selectedRowsData}
                                            setSelectedRowsData={setSelectedRowsData}
                                            setApproveVendor={setApproveVendor}
                                        />
                                    ))
                                )
                            )
                            )}
                            </tbody>
                        </table>
                    </div>
                        
                    {/* 페이지내비게이션 */}
                    <section className='mt-3'>
                        {searchConfirm ? (
                             <SearchPagination
                                count={searchResults.totalCnt}
                                url={searchUrl}
                                pageSize={pageSize}
                                updateSearchResults={updateSearchResults}
                            />
                            
                        ) : (
                            <Pagination
                                count={data?.totalCnt || 0}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                            />
                        )}
                    </section>

                    
                </section>

            </div>
            {/* 입점 거절 모달 */}
            {modalIsOpen ? <RejectModal show={modalIsOpen} onHide={setModalIsOpen} selectedRows={selectedRows} selectedRowsData={selectedRowsData} setVendorUpdateData={setVendorUpdateData} postRejectVendor={postRejectVendor}/> : null}
        </>
    )
}

// 입점거절 모달
function RejectModal({show, onHide, selectedRows, selectedRowsData, setVendorUpdateData, postRejectVendor}){

    // 선택된 상품을 모두 표시
    const selectedVendorIds = Object.keys(selectedRows).filter(key => selectedRows[key] === true);

    return(
        <Modal size={'lg'} show={show} onHide={()=>onHide(false)} centered>
            <Modal.Header>
                <Modal.Title id='sm-modal' className='fs-6'>
                    입점 거절
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedVendorIds.length > 0
                ? <span className='text-hw fw-bold'>총 {selectedVendorIds?.length}건의 등록을 거절합니다.</span>
                : '선택된 입점사가 없습니다.'}

                <div className='fixTable_wrap my-2'>
                    <table className='sTable' id='scrollTable'>
                        <thead>
                            <tr>
                                <th>상호</th>
                                <th>관리자</th>
                                <th>사업자등록번호<br/>통신판매신고번호</th>
                                <th>거절사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedVendorIds.length > 0 ? selectedVendorIds.map(vendorId => <VendorRejectTable vendorId={vendorId} setVendorUpdateData={setVendorUpdateData} selectedRowsData={selectedRowsData[vendorId]}/>) : <tr>데이터가 없습니다.</tr>}
                        </tbody>
                    </table>
                </div>
                <button className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold" onClick={()=>postRejectVendor()}>확인</button>
            </Modal.Body>
        </Modal>
    )
}

// 입점거절 모달 내 테이블
function VendorRejectTable({vendorId, setVendorUpdateData, selectedRowsData}){
    const [rejectValue, setRejectValue] = useState();
    useEffect(() => {
        setVendorUpdateData(prevData => ({
            ...prevData,
            [vendorId]:{
                rejectValue: rejectValue
            }
        }))
    },[rejectValue]);
    return(
        <tr>
            <td>
                {selectedRowsData?.vendor_name}
            </td>
            <td>
                {selectedRowsData?.vendor_owner}
            </td>
            <td>
                {selectedRowsData?.business_number}<br/>{selectedRowsData?.business_license}
            </td>
            <td>
                <input 
                    type="text" 
                    value = {rejectValue}
                    placeholder='거절사유를 입력해주세요.' 
                    onChange={(e)=>setRejectValue(e.target.value)}
                    className="w-100 p-1"
                />
            </td>
        </tr>
    )
}
export default Request