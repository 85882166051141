import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;
const boundary = Math.random().toString().substr(2);


// 상품관리 > 신규요청
// 신규요청
const fetchNewProducts = (page , pageSize, ordering) => {
  return instance.get(`product/requested/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};


export const useNewProductsQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['newproducts', page], () => fetchNewProducts(page , pageSize, ordering)

  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};


// 상품 조회
// const fetchProducts = (page , pageSize) => {
//   return fetch(`${BASE_URL}/product/document/?page=${page}&page_size=${pageSize}`)
//     .then(res => res.json());
// };

// 
const fetchProducts = (page , pageSize, ordering) => {
  return instance.get(`product/seller/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};


// 상품 상세 조회
const fetchProductDetails = (productId) => {
  return instance.get(`product/seller/${productId}`)
    .then(res => res.data)
};

export const useProductsQuery = (ordering , pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);


  const { data, error, isFetching } = useQuery(
    ['products', page , pageSize, ordering], () => fetchProducts(page , pageSize, ordering)
  );

  const fetchNewProductDetailsById = (productId) => {
    return fetchProductDetails(productId);
  };

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    fetchNewProductDetailsById
  };
};

// 상품관리 > 상품목록 > 상품 검색 
export const handleSearch = async ({
  searchProduct, 
  setSearchResults,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  setSearchUrl,
  status,
  }) => {
    try {
      let url = `product/seller/document/`;

      if (searchProduct) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchProduct}`;
            break;
          case 'name':
            url += `?name=${searchProduct}`;
            break;
          case 'id':
            url += `?id__in=${searchProduct}`;
            break;
          default:
            break;
        }
      }else{
        url += `?`;
      }

      if (selectedStartDate && selectedEndDate) {
        url += `&register_date=${selectedStartDate}__${selectedEndDate}` 
      }

      if(status) url += `&sale_status=${status}`;

      console.log("url:",url)
      setSearchUrl(url);
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 신규요청 > 검색 
export const handleNewProductSearch = async (searchProduct, setSearchResults, searchOption) => {
  try {
    let url = `product/requested/?search=${searchProduct}`;
    //   if (searchOption !== 'all') {
    //     url += `&category=${searchOption}`;
    //   }
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 상품등록
// 상품등록 > 수산상회 선택 (조회)
export const useApprovedVendors = () => {
  return useQuery('approvedVendors', async () => {
    const response = await instance.get(`vendor/approved/?page_size=all`);
    return response.data;
  });
}; 


// 상품등록
export function useProductRegistration() {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = async (data) => {
    setIsPostLoading(true);
    setError(null);

    try {
      const response = await instance.post(`product/seller/`, data);
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      setError("Failed to post");
      throw new Error("Failed to post");
    } finally {
      setIsPostLoading(false);
    }

  };

  return {
    post,
    isPostLoading,
    error,
  };
}

// 셀러관리자 > 상품등록 > 카테고리검색 

export const handleCategorySearch = async ({
  searchCategory, 
  setSearchResults, 
  }) => {
  try {
    let url = `/category/document/`;

    if (searchCategory) {
        url += `?search=${searchCategory}`; 
    }
   
    // console.log(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};




// 셀러관리자 > 상품등록 > 카테고리 검색 조회 
export const fetchCategorySearch = (Category) => {
  return instance.get(`category/document/?search=${Category}`)
    .then(res => res.data)
};



// [hm] 셀러관리자 > 상품관리 > 요청목록
export const getProductRequestList = () => {
  return instance.get('product/seller/requested/')
}

// [hm] 셀러관리자 > 상품관리 > 요청목록 > 상품id에 맞춰 상세정보 조회
export const getProductRequestDetail = (productId) => {
  return instance.get(`product/seller/${productId}`)
}

// [hm] 셀러관리자 > 상품등록 > 출고지,반품지 자동입력을 위한 셀러 본인 입점사 정보조회
export const getSellerInfo = () => {
  return instance.get('vendor/seller/')
}

// [hm] 셀러관리자 > 메인 > 상품 통계 정보 조회
export const getMainProductState = () => {
  return instance.get('product/seller/document/?page=1&page_size=100')
}

// 셀러관리자 > 상품 수정 요청 
export const updateProductData = async (newData) => {
  try {
    await instance.post(`product/update/request/`, newData);
    alert('데이터 수정 요청이 완료 되었습니다.');
  } catch (error) {
    throw new Error('데이터 수정 요청 중 오류가 발생했습니다.');
  }
};


// 셀러관리자 > 상품관리 > 요청목록
const fetchProductRequest = (page , pageSize, ordering) => {
  return instance.get(`product/seller/requested/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useProductRequestQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['ProductRequest', page,pageSize,ordering], () => fetchProductRequest(page , pageSize, ordering)

  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 검색 
export const handleAdjustDocumentSearch = async ({
  searchProduct,
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  status,
  }) => {
  try {
    let url = `product/seller/requested/`;

    if (searchProduct) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchProduct}`;
          break;

        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'join':
          url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        case 'last':
          url += `&last_login__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }

    if(status) url += `&sale_status=${status}`;

    if(ordering){
      url += `&ordering=${ordering}` 
    }

    console.log(url)
    console.log("---검색url")
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);

    console.log(response.data);
    console.log("--검색결과---");

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 셀러관리자 > 주문배송 > 구매확정내역
const fetchSellerConfirm = (page , pageSize, ordering) => {
  return instance.get(`order/seller/confirm/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useSellerConfirmQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['SellerConfirmList', page,pageSize,ordering], () => fetchSellerConfirm(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리자 > 주문배송 > 구매확정내역 검색 
export const handleSelleConfirmrSearch = async ({
  searchProduct, 
  setSearchResults,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  setSearchUrl,
  status,
  }) => {
    try {
      let url = `order/seller/confirm/`;

      if (searchProduct) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchProduct}`;
            break;
          case 'orderNumber':
            url += `?order_number=${searchProduct}`;
            break;
          case 'id':
            url += `?id__in=${searchProduct}`;
            break;
          default:
            break;
        }
      }else{
        url += `?`;
      }

      if (selectedStartDate && selectedEndDate) {
        url += `&paid_at=${selectedStartDate}__${selectedEndDate}` 
      }

      console.log("url:",url)
      setSearchUrl(url);
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};


// 셀러관리자 > 주문배송 > 미결제내역
const fetchSellerUnpaid = (page , pageSize, ordering) => {
  return instance.get(`order/seller/unpaid/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useSellerUnpaidQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['SellerUnpaidmList', page,pageSize,ordering], () => fetchSellerUnpaid(page , pageSize, ordering)
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
  };
};

// 셀러관리자 > 주문배송 > 미결제내역 검색 
export const handleSelleUnpaidSearch = async ({
  searchProduct, 
  setSearchResults,
  searchOption,
  selectedStartDate, 
  selectedEndDate,
  setSearchUrl,
  status,
  }) => {
    try {
      let url = `order/seller/unpaid/`;

      if (searchProduct) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchProduct}`;
            break;
          default:
            break;
        }
      }else{
        url += `?`;
      }

      if (selectedStartDate && selectedEndDate) {
        url += `&paid_at=${selectedStartDate}__${selectedEndDate}` 
      }

      setSearchUrl(url);
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};