import React, { useState , useEffect} from 'react';
import { addCommasToNumberString } from '../../../common/numberUtils';
import AddressModal from '../../Register/AddressModal';

function DeliveryDetails({ deliveryInfoData , setDeliveryInfoData })
{
    // 기존 데이터 조회
    useEffect(()=>{
        // 배송비 종류
        if(deliveryInfoData.shipping_cost_type === "조건부무료배송"){
            setShippingCostType("유료배송");
        }else{
            setShippingCostType(deliveryInfoData.shipping_cost_type);
        }

        // 배송비
        setShippingCost(String(deliveryInfoData.shipping_cost));

        // 무료배송조건 확인 
        if(deliveryInfoData.free_condition > 0 ){
            setFreeShippingEnabled(true);
            setFreeCondition(String(deliveryInfoData.free_condition));
        }
        
        // 합포장조건 확인
        if (deliveryInfoData.shipping_cost_count > 1) {
            setShippingCostCountEnabled(true);
            setShippingCostCount(String(deliveryInfoData.shipping_cost_count));  
        } 

        // 묶음배송 여부 
        setBundleDeliveryAvailable(deliveryInfoData.bundle_delivery_available);

        if(deliveryInfoData.days_required_for_shipping) {
            setDaysRequiredForShipping(deliveryInfoData.days_required_for_shipping);
            setRequiredTypeForShipping(true);
        }

    },[deliveryInfoData])


    //출고지
    const [dispatchLocation, setDispatchLocation] = useState('');

    // 출고지 변경 모달
    const [modalIsOpen, setModalIsOpen] = useState(false); //주소입력모달
    const [detailAddress, setDetailAddress] = useState(''); //상세주소
    const [detailModalState, setDetailModalState] = useState(false); //주소 입력모달 false(주소입력화면) <-> true(상세주소)
   
    useEffect(()=>{
        const updatedDeliveryInfoData = {
          ...deliveryInfoData,
          dispatch_location: {
            ...deliveryInfoData.dispatch_location,
            detail: detailAddress,
          },
        };
   
        setDeliveryInfoData(updatedDeliveryInfoData);
    },[detailAddress])

    const onCompletePost = (data) => {
        console.table(data);

        setDeliveryInfoData({
            ...deliveryInfoData, // 기존 데이터 유지
            dispatch_location: {
              ...deliveryInfoData.dispatch_location, // 기존 dispatch_location 내용 유지
              address: data.address,
              detail: detailAddress?detailAddress:"",
              postal_code: data.zonecode,
              address_type: data.addressType === "R" ? "도로명" : "지번",
            }
        });

        setDetailModalState(true);
    };

    // 출고지 상세주소를 입력하지 않고 모달을 닫으면 모달 상태 초기화
    useEffect(()=>{
        if(!modalIsOpen){
            setDetailModalState(false);
        }
    },[modalIsOpen]);
    // 출고지 상세 주소까지 변경한 경우 상태 변경

    const changeAddress = () =>{
        setModalIsOpen(false);
    }
    
 
    // dispatchLocation 상태가 비어있을 때 기본 값으로 체크 상태를 설정합니다.
    const isChecked = dispatchLocation === '';
  
    // dispatchLocation 상태를 토글하는 함수를 정의합니다.
    const toggleDispatchLocation = (event) => {
        event.preventDefault(); 
        setDispatchLocation(prevLocation => (prevLocation === '' ? '출고지' : ''));
    };
    
    //제주/도서산간 배송
    const [islandDelivery, setIslandDelivery] = useState('false');

    const handleIsislandDeliveryChange = (e) => {
        const newIslandDelivery = e.target.value;
        setIslandDelivery(newIslandDelivery);

        setDeliveryInfoData((prevDeliveryInfoData) => ({
              ...prevDeliveryInfoData,
              island_delivery_available : newIslandDelivery
         }));
    };

    // 택배사 
    const [courier, setCourier] = useState('');

    const handleCourierChange = (e) => {
        const newCourier = e.target.value;        
        setCourier(newCourier);

        setDeliveryInfoData((prevDeliveryInfoData) => ({
              ...prevDeliveryInfoData,
              courier : newCourier
         }));
    };

    // 배송방법
    const [shippingMethod, setShippingMethod] = useState('');
    const handleshippingMethod = (e) => {
        const newShippingMethod = e.target.value;
        setShippingMethod(newShippingMethod);

        
        setDeliveryInfoData((prevDeliveryInfoData) => ({
              ...prevDeliveryInfoData,
              shipping_method : newShippingMethod
         }));
    };

    // 배송비
    const [shippingCost, setShippingCost] = useState('');

    const handleShippingCostMethod = (e) => {
        const newShippingCost = e.target.value;
        const ShippingCostWithoutCommas = newShippingCost.replace(/,/g, ''); // 콤마 제거
        setShippingCost(ShippingCostWithoutCommas);

        setDeliveryInfoData((prevDeliveryInfoData) => ({
            ...prevDeliveryInfoData,
            shipping_cost: parseInt(ShippingCostWithoutCommas, 10),
        }));
    }

    // 무료배송조건 (사용함 , 사용안함)
    const [isFreeShippingEnabled , setFreeShippingEnabled] = useState(false);

    const handleFreeConditionMethod = (event) => {
        const selectedValue = event.target.value === 'true';
        setFreeShippingEnabled(selectedValue);

        if(!selectedValue){
            setFreeCondition('');

            setDeliveryInfoData((prevDeliveryInfoData) => ({
                ...prevDeliveryInfoData,
                free_condition: 0, 
            }));
        }
    };


    // 무료배송 조건 사용시 금액 입력
    const [freeCondition , setFreeCondition] = useState('');
    const handleFreeMethod = (e) =>{
        const newFreeMethod = e.target.value;
        const freeConditionWithoutCommas = newFreeMethod.replace(/,/g, ''); // 콤마 제거
        setFreeCondition(freeConditionWithoutCommas);
    
        setDeliveryInfoData((prevDeliveryInfoData) => ({
            ...prevDeliveryInfoData,
            free_condition: parseInt(freeConditionWithoutCommas, 10), // 콤마가 제거된 정수를 저장
        }));
    }
    
    // 배송비 종류
    const [shippingCostType, setShippingCostType] = useState('');

    const handleshippingCostTypeMethod = (event) => {
        const selectedValue = event.target.value;
        setShippingCostType(selectedValue);

        if(selectedValue==="무료배송"){
            console.log("shippingCost:");
            console.log(selectedValue);

            setShippingCost('');
            setShippingCostCountEnabled(false);
            setFreeShippingEnabled(false);
            setShippingCostCount('');
            setFreeCondition('');
            setBundleDeliveryAvailable(true);
        }

        setDeliveryInfoData((prevDeliveryInfoData) => ({
            ...prevDeliveryInfoData,
            shipping_cost_type: selectedValue,
        }));
        
    };

    // 합포장조건 (사용함 , 사용안함)
    const [isShippingCostCountEnabled , setShippingCostCountEnabled] = useState(false);

    const handleShippingCostCountMethod = (event) => {
        const selectedValue = event.target.value === 'true';
        if(!selectedValue){
            setShippingCostCount('');

            setDeliveryInfoData((prevDeliveryInfoData) => ({
                ...prevDeliveryInfoData,
                shipping_cost_count: 0, 
            }));
        }
        setShippingCostCountEnabled(selectedValue);
    };

    // 합포장조건 개당 입력
    const [shippingCostCount , setShippingCostCount] = useState();

    const handleshippingCostCountMethod = (e) =>{
        
        const newshippingCostCountMethod = e.target.value;
        const CountWithoutCommas = newshippingCostCountMethod.replace(/,/g, ''); // 콤마 제거
        setShippingCostCount(CountWithoutCommas);
    
        setDeliveryInfoData((prevDeliveryInfoData) => ({
            ...prevDeliveryInfoData,
            shipping_cost_count: parseInt(CountWithoutCommas, 10), // 콤마가 제거된 정수를 저장
        }));
    }
            
    // 묶음 배송 
    const [bundleDeliveryAvailable, setBundleDeliveryAvailable] = useState(true);

    const handleIsbundleDeliveryAvailableChange = (e) => {
        const newBundleDeliveryAvailable = e.target.value === 'true';

        setBundleDeliveryAvailable(newBundleDeliveryAvailable);

        setDeliveryInfoData((prevDeliveryInfoData) => ({
            ...prevDeliveryInfoData,
            bundle_delivery_available : newBundleDeliveryAvailable
        }));
        
    };
    
    // 출고소요일
    const [daysRequiredForShipping , setDaysRequiredForShipping] = useState('');
    // [hm] 당일출고 가능/불가능 선택 (default : 불가능)
    const [requiredTypeForShipping , setRequiredTypeForShipping] = useState(false); //true : 당일출고 가능, false : 당일출고 불가

    const handleDaysRequiredChange  = (e) => {
        const newDaysRequiredForShipping = e.target.value;

          // 입력값이 숫자이고 1부터 7까지의 범위 내에 있는지 확인
        // if (/^\d+$/.test(newDaysRequiredForShipping) && newDaysRequiredForShipping >= 1 && newDaysRequiredForShipping <= 7) {

        setDaysRequiredForShipping(newDaysRequiredForShipping);

        setDeliveryInfoData((prevDeliveryInfoData) => ({
            ...prevDeliveryInfoData,
            days_required_for_shipping: newDaysRequiredForShipping
        }));
    
    };



    // 사업장주소 상세 주소 
    const handleInputDetailChange = (e) => {
        const newDetail = e.target.value;
      
        const updatedDeliveryInfoData = {
          ...deliveryInfoData,
          dispatch_location: {
            ...deliveryInfoData.dispatch_location,
            detail: newDetail,
          },
        };
      
        // 수정된 데이터로 상태 업데이트
        setDeliveryInfoData(updatedDeliveryInfoData);
    };

    // 유료배송 & 무료배송 조건 입력 -> "조건부무료배송"
    useEffect(()=>{
        if(deliveryInfoData.shipping_cost_type === "유료배송" 
            && parseInt(deliveryInfoData.free_condition, 10)>0){
            setDeliveryInfoData((prevDeliveryInfoData) => ({
                ...prevDeliveryInfoData,
                shipping_cost_type: "조건부무료배송",
            }));
        }
    },[deliveryInfoData.free_condition])
      

    // 합포장 조건 데이터가 0보타 크면
    // shippingCostCount가 0보다 크면 isShippingCostCountEnabled를 true로 설정

    return (
        <>
        <section className="">
            <div className='product-info'>
                <table>
                    <colgroup>
                              
                    </colgroup>
                   
                    <tbody>
                        <tr>
                            <th>출고지</th>
                            <td>
                                <ul className='ec-address w-50'>
                                    <li className='d-flex'>
                                        <input type="text"
                                            placeholder="우편번호"
                                            value={deliveryInfoData.dispatch_location.postal_code}
                                            disabled 
                                        />  
                                        <button className="ms-2" 
                                                onClick={()=>setModalIsOpen(true)}>
                                                    주소 검색
                                        </button> 
                                    </li>
                                    <li>
                                        <input type="text"
                                            placeholder="사업장 주소"
                                            value={deliveryInfoData.dispatch_location.address}
                                            disabled 
                                        />   
                                    </li>
                                    <li>
                                    <input
                                        type="text"
                                        placeholder="상세주소"
                                        value={deliveryInfoData.dispatch_location.detail}
                                        onChange={handleInputDetailChange}
                                  
                                        />
                                    </li>
                                </ul>      
                                    
                              
                            </td>
                        </tr>
                        <tr>
                            <th>제주/도서산간 배송</th>  
                            <td className="radioBtn-wrap">
                                {/* <label>
                                    <input
                                    type="radio"
                                    value="true"
                                    checked={islandDelivery === 'true'}
                                    onChange={handleIsislandDeliveryChange}
                                    />
                                    배송 가능
                                </label> */}

                                <label>
                                    <input
                                    type="radio"
                                    value="false"
                                    checked={islandDelivery === 'false'}
                                    onChange={handleIsislandDeliveryChange}
                                    />
                                    배송 불가능
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>택배사</th>  
                            <td>
                                <select className='product-select' 
                                        onChange={handleCourierChange}  
                                        value={deliveryInfoData.courier}>
                                    <option value="CJ대한통운" >CJ대한통운</option>
                                    <option value="한진택배">한진택배</option>
                                    <option value="일양로지스">일양로지스</option>
                                    <option value="롯데택배">롯데택배</option>
                                    <option value="경동택배">경동택배</option>
                                    <option value="로젠택배">로젠택배</option>
                                    <option value="KGB택배">KGB택배</option>
                                    <option value="합동택배">합동택배</option>
                                    <option value="GTX로지스택배">GTX로지스택배</option>
                                    <option value="천일택배">천일택배</option>
                                    <option value="우체국택배">우체국택배</option>
                                    <option value="대신택배">대신택배</option>
                                    <option value="편의점택배">편의점택배</option>
                                    <option value="드림택배">드림택배</option>
                                </select>
                            </td>
                           
                        </tr>
                        <tr>
                            <th>배송방법</th>  
                            <td>
                                <p>일반배송</p>
                                {/* <select className='product-select'  onChange={handleshippingMethod}>
                                    <option>무료배송</option>
                                    <option>유료배송</option>
                                    <option>부분무료배송</option>
                                </select> */}
                            </td>
                        </tr>
                        <tr>
                            <th>배송비 종류</th>  
                            <td className='radioBtn-wrap'>
                                <label>
                                    <input
                                        type="radio"
                                        value="무료배송"
                                        checked={shippingCostType === '무료배송'}
                                        onChange={handleshippingCostTypeMethod}
                                    />
                                    무료배송
                                </label>

                                <label>
                                    <input
                                        type="radio"
                                        value="유료배송"
                                        checked={shippingCostType === '유료배송'}
                                        onChange={handleshippingCostTypeMethod}
                                    />
                                    유료배송
                                </label>

                         
                            </td>
                        </tr>

                        {shippingCostType==="유료배송" && (
                            <>
                            <tr className='sm-text-wrap'>
                                <th>배송비</th>
                                <td>  
                                    <input 
                                        type="text" 
                                        placeholder=""
                                        value={addCommasToNumberString(shippingCost)}
                                        onChange={handleShippingCostMethod}
                                    />
                                    <span>원</span>
                                </td>
                            </tr>

                            <tr className='sm-text-wrap'>
                                <th>
                                    무료배송조건                                    
                                </th>
                                <td className='radioBtn-wrap'>  
                                    <label>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={isFreeShippingEnabled === true}
                                            onChange={handleFreeConditionMethod}
                                        />
                                        사용함
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="false"
                                            checked={isFreeShippingEnabled === false}
                                            onChange={handleFreeConditionMethod}
                                        />
                                        사용안함
                                    </label>
                                    
                                    {isFreeShippingEnabled && (
                                        <>
                                            <input type="text" 
                                                placeholder=""
                                                value={addCommasToNumberString(freeCondition)}
                                                onChange={handleFreeMethod}
                                            />
                                            <span className='tf500'>원 이상 구매 시 무료배송</span>
                                        </>
                                    )}
                                  
                                </td>
                            </tr>

                            <tr className='sm-text-wrap'>
                                <th>
                                    합포장조건
                                </th>
                                 <td className='radioBtn-wrap'>  
                                    <label>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={isShippingCostCountEnabled === true}
                                            onChange={handleShippingCostCountMethod}
                                        />
                                        사용함
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="false"
                                            checked={isShippingCostCountEnabled === false}
                                            onChange={handleShippingCostCountMethod}
                                        />
                                        사용안함
                                    </label>
                                    
                                    {isShippingCostCountEnabled && (
                                        <>
                                            <input type="text" 
                                                placeholder=""
                                                value={addCommasToNumberString(shippingCostCount)}
                                                onChange={handleshippingCostCountMethod}
                                            />
                                            <span className='tf500'>
                                            개당 배송비 {addCommasToNumberString(shippingCost)}원 부가
                                            </span>
                                        </>
                                    )}
                                  
                                </td>
                            </tr>

                            <tr className='sm-text-wrap'>
                                <th>
                                    묶음배송
                                </th>
                               
                                <td className="radioBtn-wrap">
                                    <label>
                                        <input
                                        type="radio"
                                        value="true"
                                        checked={bundleDeliveryAvailable === true }
                                        onChange={handleIsbundleDeliveryAvailableChange}
                                        />
                                        묶음배송 가능
                                    </label>

                                    <label>
                                        <input
                                        type="radio"
                                        value="false"
                                        checked={bundleDeliveryAvailable === false } 
                                        onChange={handleIsbundleDeliveryAvailableChange}
                                        />
                                        묶음배송 불가능
                                    </label>

                                    {bundleDeliveryAvailable === 'true' && (
                                        <span className='noticeText'>
                                        *000님의 여러 상품을 한번에 주문하는 경우 가장 낮은 배송비만 청구됩니다.
                                        </span>
                                    )}
                                    
                                </td>
                                    
                            </tr>

                        </>
                        )}
                  
                        <tr>
                            <th>출고 소요일</th>  
                            <td className='sm-text-wrap'>
                                {/* [hm] 출고일 선택 방식 변경 */}
                                <label className='me-3'>
                                    <input type="radio" 
                                            value="false" 
                                            checked={requiredTypeForShipping} 
                                            onChange={()=>setRequiredTypeForShipping(true)}/> 
                                    당일출고
                                </label>
                                <label>
                                    <input type="radio" 
                                            value="false"
                                            checked={!requiredTypeForShipping} 
                                            onChange={()=>setRequiredTypeForShipping(false)}/>
                                    일반배송(주문일+2영업일 이내)
                                </label>
                                <br/>
                                {!requiredTypeForShipping ? (
                                    // 날짜선택
                                    <select
                                        className="mt-2 px-2 py-1"
                                        value={daysRequiredForShipping}
                                        onChange={handleDaysRequiredChange}
                                    >
                                        {/* <option value="1일">1일</option> */}
                                        <option value="2일">2일</option>
                                        {/* <option value="3일">3일</option>
                                        <option value="4일">4일</option>
                                        <option value="5일">5일</option>
                                        <option value="6일">6일</option>
                                        <option value="7일">7일</option> */}
                                    </select>
                                ) : (
                                    // 시간선택
                                    <select
                                        className="mt-2 px-2 py-1"
                                        value={daysRequiredForShipping}
                                        onChange={handleDaysRequiredChange}
                                    >
                                        <option value="(당일출고) 00시">00시</option>
                                        <option value="(당일출고) 01시">01시</option>
                                        <option value="(당일출고) 02시">02시</option>
                                        <option value="(당일출고) 03시">03시</option>
                                        <option value="(당일출고) 04시">04시</option>
                                        <option value="(당일출고) 05시">05시</option>
                                        <option value="(당일출고) 06시">06시</option>
                                        <option value="(당일출고) 07시">07시</option>
                                        <option value="(당일출고) 08시">08시</option>
                                        <option value="(당일출고) 09시">09시</option>
                                        <option value="(당일출고) 10시">10시</option>
                                        <option value="(당일출고) 11시">11시</option>
                                        <option value="(당일출고) 12시">12시</option>
                                        <option value="(당일출고) 13시">13시</option>
                                        <option value="(당일출고) 14시">14시</option>
                                        <option value="(당일출고) 15시">15시</option>
                                        <option value="(당일출고) 16시">16시</option>
                                        <option value="(당일출고) 17시">17시</option>
                                        <option value="(당일출고) 18시">18시</option>
                                        <option value="(당일출고) 19시">19시</option>
                                        <option value="(당일출고) 20시">20시</option>
                                        <option value="(당일출고) 21시">21시</option>
                                        <option value="(당일출고) 22시">22시</option>
                                        <option value="(당일출고) 23시">23시</option>
                                    </select>
                                )}
                            </td>
                        </tr>
                    </tbody>

                </table>     
            </div>

        </section>
        {/* 출고지변경 모달 */}
        {
            modalIsOpen  ? (
                <AddressModal show={modalIsOpen} 
                                onHide={setModalIsOpen} 
                                autoClose 
                                onComplete={onCompletePost} 
                                detailModalState={detailModalState} 
                                detailAddress={detailAddress} 
                                setDetailAddress={setDetailAddress} 
                                changeAddress={changeAddress}/>
            ) : null
        }
        </>
    )
}


export default DeliveryDetails