/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 해와관리자 > 주문배송 > 결제내역 OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils";

export const OrderRow = ({ order, index, selectedRows, setSelectedRows, selectedRowsData, setSelectedRowsData }) => {
      
  const navigate = useNavigate();

  // 주문번호 선택 핸들링
  const handleOrderNumberClick = (orderNumber,orderId) => {
    navigate(`/admin/order/inquire/${orderNumber}/${orderId}`);
  };
    
  
    
  return(
    <tr key={order.id}>
      <td 
        className="blueLink"
        onClick={() => handleOrderNumberClick(order.order_number,order.id)}>
          {order.order_number && order.order_number}
      </td>

      <td>{order?.status}</td>
      <td>
        {order?.pay_method}<br/>
      </td>
      <td>
        {order.bank && order.account_number ? 
          <>
            {order.bank} <br/>
            {order.account_number}
          </> 
          : "-"}
      </td>
      <td>
        {extractYearMonthDayHourMinute2(order?.paid_at)}<br/>
        {order?.virtual_account?.expired_at ?? ""}
      </td>
      <td>
        {order?.total_price.toLocaleString()}원
      </td>
      <td>
        {order?.orderer_name} <br/>
        {order?.orderer_phone_number}
      </td>
      <td>
        {order?.recipient_name} <br/>
        {order?.recipient_phone_number}
      </td>
      <td>
        [{order?.postal_code}]<br/>
        {order?.address}
      </td>
      <td>{order?.total_reward_mileage}</td>
    </tr>
  )

};