import { instance } from "../axiosInstance/axiosConfig";

// 해와관리자  > 엑셀 다운로드 
export const getExcel = (options) => {
    let url = 'excel/user/';  // 기본 URL

    switch (options.type) {
        case 'user':
            url = 'excel/user/';  // 회원관리 다운로드
            break;
        case 'vendor':
            url = 'excel/vendor/';  // 입점사 리스트 엑셀 다운로드
            break;
        case 'product':
            url = 'excel/product/';  // 상품 리스트 엑셀 다운로드
            break;

        default:
            // 기본 케이스 처리
            break;
    }


  return instance.get(url, {
      responseType: 'blob',  // Blob 형식으로 응답을 받음
  });
};
