// 사용하지 않는 파일인 듯 합니다. 삭제 하도록 하겠습니다. (pkh)
import {Row, Col, Button , Navbar, Nav, Container, Tab} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

function Detail()
{
    let [tab, setTab] = useState(0)

    return(
        <>
        <Container>
            <div className='product-headcategory'>
                <ol>
                    <li><a>홈</a></li>
                    <li><a>전체 상품</a></li>
                    <li><a>제품별</a></li>
                    <li><a>제품별</a></li>
                </ol>
            </div>
            <Row>
                <Col xs={12} md={6}>
                    <div className='product-image'>
						<img src={process.env.PUBLIC_URL + '/img/pd_01.jpg'} />      
                    </div>
                    <div className='product-addimage listImg'>
                        <ul>
                            <li>
						        <img src={process.env.PUBLIC_URL + '/img/pd_01.jpg'} />      
                            </li>
                            <li>
						        <img src={process.env.PUBLIC_URL + '/img/pd_01.jpg'} />      
                            </li>
                            <li>
						        <img src={process.env.PUBLIC_URL + '/img/pd_01.jpg'} />      
                            </li>
                            <li>
						        <img src={process.env.PUBLIC_URL + '/img/pd_01.jpg'} />      
                            </li>

                        </ul>
                    </div>
                </Col>
                
                <Col xs={12} md={6}>
                    <div className='infoArea'>
                        <div className='title'>
                            <h3>
                                <span>[서희수산] 흰다리새우 (500g,16~18마리)</span>
                            </h3>
                            <ul className='sl'>
                                <li><img src={process.env.PUBLIC_URL + '/img/icon/like.svg'} /> </li>
                                <li><img src={process.env.PUBLIC_URL + '/img/icon/share.svg'} /></li>
                            </ul>
                        </div>
                       <div className='product-detail-t'>
                            <table border="1">
                                <tbody>
                                    <tr className=''>
                                        <th>
                                            <span>상품명</span>
                                        </th>
                                        <td>
                                            <span>흰다리새우</span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th>
                                            <span>정상가</span>
                                        </th>
                                        <td>
                                            <span>25,000원</span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th>
                                            <span>회원 쿠폰 적용가</span>
                                        </th>
                                        <td>
                                            <span>22,000원 (12%할인)</span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th>
                                            <span>적립금</span>
                                        </th>
                                        <td>
                                            <span>250원</span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th>
                                            <span>배송비</span>
                                        </th>
                                        <td>
                                            <span>무료</span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th>
                                            <span>판매자</span>
                                        </th>
                                        <td>
                                            <span>서희수산</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <select className="pd-select opt-this-goods">
                                <option selected>상품선택</option>
                                <option value="">민물장어 1kg (손질후 600g이내) (+0원 x 개)</option>
                                <option value="">민물장어 1kg (손질후 600g이내) (+0원 x 개)</option>
                            </select>

                            <div className='opt-selects-this'>
                                <div className='opt-sel'>
                                    <div class="opt-sm-wrap">
                                        <span className='opt-sel-name'>민물장어 1kg (손질후 750g이내)</span>
                                        <a className='opt-sel-del'></a>
                                    </div>
                                    <div class="opt-sm-wrap">
                                        <div className='opt-sel-qty'>
                                            <a className='down QuantityDown'></a>
                                            <input type="text" value="1" size="1" className='buy_qty' min="1"/>
                                            <a className='up QuantityUp'></a>
                                        </div>
                                        <span className='opt-sel-price'>41,000원</span>
                                    </div>
                                </div>
                            </div>
                            <div className='btnWrap'>
                                <button type='button' name='addCart' id="addCart" className='btn_resp'>
                                장바구니
                                </button>
                                <button type='button' name='buy' id="buy" className='btn_resp'>
                                구매하기
                                </button>
                            </div>
                       </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={12}>
                    <div className='articleBody'>
                        <Nav justify variant="tabs" defaultActiveKey="link-0" >
                            <Nav.Item>
                                <Nav.Link eventKey="link-0" onClick={()=>{setTab(0)}}>상세정보</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-1" onClick={()=>{setTab(1)}}>상품후기(9)</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-2" onClick={()=>{setTab(2)}}>상품문의(12)</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-3" onClick={()=>{setTab(3)}}>배송/교환/반품</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <TabContent tab={tab}/>

                </Col>
                    
                    
            </Row>
        </Container>
        </>
    )
}

function TabContent({tab})
{
    return(<div>
        {[<div>상세정보</div> ,<div>상품후기</div>, <div>상품문의</div> , <div>배송</div>][tab] }
        </div>
    )
}
export default Detail