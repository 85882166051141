/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 해와관리자 > 정산 > 세금계산서내역 > AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const AdjustRow = ({ Adjust }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <>
        <tr>
          <td> <input type="checkbox" /> </td>
          <td>{Adjust.issue_date}</td>
          <td>{Adjust.vendor}</td>
          <td>{Adjust.bussiness_number}</td>
          <td>{Adjust.detail}</td>
          <td>{Adjust.issue_number}</td>
          <td>{Adjust.supply_amount.toLocaleString()}원</td>
          <td>{Adjust.tax_amount.toLocaleString()}원</td>
          <td>{Adjust.total_amount.toLocaleString()}원</td>
          <td>
            <img src={Adjust.invoice_image} className='cursor-pointer' onClick={openModal}/>
          </td>

          <ImageModal 
            show={isOpen} 
            setShow={setIsOpen} 
            vendor={Adjust.vendor}
            img={Adjust.invoice_image}/>
          
         </tr>
      </>
    </>
  );
};

function ImageModal({show, setShow, vendor, img}){
  return(
    <>
    <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {vendor} 세금계산서
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div width="90%">
              <div className='editor-content'>
                <img src={img}/>
              </div>
            </div>
        </Modal.Body>
    </Modal>
  </>
)
}