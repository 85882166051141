import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useFindUsername } from '../../api/services/memberApi';

//사용자페이지 > 아이디 찾기
function Find()
{
    const { post, isPostLoading, error } = useFindUsername();
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
            <div className="spinner" ></div>
            </div>
        );
    };

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        email:'',
    });

    const { username, email } = formData;
   
    // 이름 입력 변화 확인
    const handleInputUserNameChange = (e) => {
        const newData = e.target.value;
        setFormData({ ...formData, username : newData })
    };
    
    const validateForm = () => {
        let isValid = true;


        // 1. 이름 입력 확인    
        if(username === ''){
            alert("이름을 입력해주세요.");
            return ;
        }

        // 2. 이메일 입력 확인    
        if(email === ''){
            alert("이메일을 입력해주세요.");
            return ;
        }

        return isValid;
    }

    const handleSubmit = () => {
        if (!isLoading) {
          setIsLoading(true); // 버튼을 비활성화
    
          if (validateForm()) {
            const data = {
              email: email || "",
            };
    
            console.log("데이터");
            console.log(data);
    
            post(data)
              .then(() => {
                alert("해당 이메일로 인증 코드가 전송됩니다.");
              })
              .catch((error) => {
                console.log("등록 실패:");
                console.log(error);
              })
              .finally(() => {
                setIsLoading(false); // 버튼을 다시 활성화
              });
          } else {
            setIsLoading(false); // 유효성 검사 실패 시 버튼을 다시 활성화
          }
        }
      };
    
    return(
        <>
        <Container>
            <Row>
                <div className='find_wrap'>
                    <div id="contents">
                        <div className="find-titleArea">
                            <h2>아이디 찾기</h2>
                            <p>        
                            일반회원 아이디찾기로, 네이버, 카카오로 
                            <br/>
                            회원가입한 회원은 아이디 검색이 되지 않습니다
                            </p>
                        </div>
                        <div className='check'>
                          <input type='radio' id="check_method1" name='check_method' value='email' checked   />
                          <label for="check_method1">이메일</label>

                          {/*
                          <input type='radio' id="check_method2" name='check_method' value='email' />
                          <label for="check_method2">휴대폰번호</label>
                          
                          <input type='radio' id="check_method3" name='check_method' value='email' />
                          <label for="check_method3">휴대폰인증</label>
                          */}
                        </div>

                        <div className='findId'>
                            <div className='name_view'>
                                <strong id="name_label">이름</strong>
                                <input 
                                    name="name" 
                                    placeholder='이름을 입력해주세요' 
                                    value={username}
                                    onChange={handleInputUserNameChange}
                                    type="text"/>
                            </div>

                            <div className='email_view'>
                                <strong id="name_label">이메일</strong>
                                <input 
                                    name="email" 
                                    placeholder='이메일을 입력해주세요' 
                                    type="text"
                                    value={email} 
                                    onChange={e => setFormData({ ...formData, email: e.target.value })}
                                    />
                            </div>
                        </div>

                        <div className='ec-base-button'>
                            <button onClick={handleSubmit} disabled={isLoading}>
                                {isLoading ? <LoadingSpinner /> : ' 아이디 찾기'} 
                            </button>
                            
                  

                            <ul>
                                <li className='pointer'>
                                    <a onClick={()=>{navigate('../login')}}>로그인하기</a>
                                </li>
                                <li className='pointer'>
                                    <a onClick={()=>{navigate('../findpasswd')}}>비밀번호 찾기</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Row>
            
        </Container>
        </>
    )
}

export default Find