/***************************************************************************************
* FileName      : CouponBlock.js
* Description   : 고객 > 상품 결제페이지 > 상품쿠폰, 배송비 쿠폰 적용 박스
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.05
* Modifide Date : 2024.01.05 : CHM : order 페이지 내 상품쿠폰 및 배송비 쿠폰 적용 컴포넌트 분리
*               : 
* Reference     : 
***************************************************************************************/
import { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import { SelectCouponModal } from "./CouponModal";

// 상품쿠폰박스
export function ItemCouponBlock({data, setOrderData}) {
    const couponOrderItem = [];

    data?.delivery_items.map((item) => {
        item?.order_items.map((oi) => {
            const orderItem = {
                id: oi?.id, // 상품pk
                vendor: oi?.vendor_id, //입점사pk
                productName: oi?.product_name, // 상품명
                vendorName: oi?.vendor_name, // 판매자
                price: oi?.original_total_price, // 해당상품 결제금액
                discountPrice: oi?.discount_price, // 쿠폰할인금액
                coupon: oi?.coupon_name //사용된 쿠폰
            }
            couponOrderItem.push(orderItem);
        });
    });

    const [couponModals, setCouponModals] = useState([]);
    useEffect(() => {
        setCouponModals([]);// data가 변경될 때마다 초기 상태 생성
        if(data){
            setCouponModals(prevCouponModals => {
                // 각 oi 수 만큼 false를 추가한 새로운 배열 생성
                const newCouponModals = data?.delivery_items.flatMap(di =>
                    di.order_items.flatMap(oi => [...prevCouponModals, false])
                );
                console.log("쿠폰모달 상태 : ", newCouponModals);
                return newCouponModals;
            });
        }
    }, [data]);
    
  
    return (
        <div className="m-0 p-2 border">
            <Row className="m-0 p-0">
                <Col xs={6} className="m-0 p-2 bg-grey text-center fs-7 fw-bolder">상품</Col>
                <Col xs={3} className="m-0 p-2 bg-grey text-center fs-7 fw-bolder">금액</Col>
                <Col xs={3} className="m-0 p-2 bg-grey text-center fs-7 fw-bolder">할인쿠폰</Col>
                <hr className="m-0 p-0" />
            </Row>
            {couponOrderItem.map((item, index) => (
                <div key={index}>
                    <Row className="m-0 p-0">
                        <Col xs={6} className="m-0 p-2 text-start">
                            <p className="fs-7 fw-bolder">{item?.productName}</p>
                            <p className="fs-8 fw-bolder text-grey">{item?.vendorName}</p>
                        </Col>
                        <Col xs={3} className="m-0 p-2 text-end">
                            <p className="fs-7 fw-bolder">{item?.price}원</p>
                            <p className="fs-8 fw-bolder text-danger">{item?.coupon}  (-){item?.discountPrice}원</p>
                        </Col>
                        <Col xs={3} className="m-0 p-2 text-center fs-7 fw-bolder d-flex justify-content-center align-items-center">
                            
                            <button
                                className="btn border-hw text-hw fs-7 rounded-0"
                                onClick={() =>{
                                    setCouponModals((prevModals) =>
                                        prevModals.map((state, idx) => (idx === index ? true : state))
                                    )}
                                }
                            >
                                쿠폰선택
                            </button>
                        </Col>
                        <hr className="m-0 p-0" />
                    </Row>
                    <SelectCouponModal
                        id={item.id}
                        type={"상품"}
                        vendor={item.vendor}
                        price={item.price}
                        modalState={couponModals[index]}
                        setModalState={(value) =>
                            setCouponModals((prevModals) =>
                                prevModals.map((state, idx) => (idx === index ? value : state))
                            )
                        }
                        setOrderData={setOrderData}
                    />
                </div>
            ))}
        </div>
    );
}

// 배송비쿠폰박스
export function DeliveryCouponBlock({data, setOrderData}) {
    const couponDeliveryItem = [];

    data?.delivery_items.map((item, index) => {
            const deliveryItem = {
                id: item?.id, // 상품pk
                vendor: item?.order_items[0].vendor_id, //입점사pk
                vendorName: item?.order_items[0].vendor_name,
                productName: item?.order_items.map((i)=> i.product_name), // 상품명
                price: item?.delivery_fee, // 해당상품 배송비
                discountPrice: item?.discount_price, //사용한 쿠폰 할인액
                coupon: item?.coupon_name  //사용된 쿠폰
            }
            couponDeliveryItem.push(deliveryItem);
    });

    const [couponModals, setCouponModals] = useState([]);
    useEffect(() => {
        // data가 변경될 때마다 초기 상태 생성
        if (data && data.delivery_items) {
          const initialModals = data.delivery_items.map(() => false);
          setCouponModals(initialModals);
        }
    }, [data]);
  
    return (
        <div className="m-0 p-2 border">
            <Row className="m-0 p-0">
                <Col xs={6} className="m-0 p-2 bg-grey text-center fs-7 fw-bolder">배송건</Col>
                <Col xs={3} className="m-0 p-2 bg-grey text-center fs-7 fw-bolder">배송비</Col>
                <Col xs={3} className="m-0 p-2 bg-grey text-center fs-7 fw-bolder">할인쿠폰</Col>
                <hr className="m-0 p-0" />
            </Row>
            {couponDeliveryItem.map((item, index) => (
                <div key={index}>
                    <Row className="m-0 p-0">
                        <Col xs={6} className="m-0 p-2 text-start">
                            <p className="fs-7 fw-bolder">{item?.vendorName}</p>
                            <p className="fs-8 fw-bolder text-grey">{item?.productName.length > 1 ? item?.productName[0] + ' 외 ' + ~~(item?.productName.length-1) + '건': item?.productName[0]}</p>
                        </Col>
                        <Col xs={3} className="m-0 p-2 text-end">
                            <p className="fs-7 fw-bolder">{item?.price}원</p>
                            <p className="fs-8 fw-bolder text-danger">{item?.coupon ? item?.coupon+' (-)'+item?.discountPrice.toLocaleString() : '(-)0'}원</p>
                        </Col>
                        <Col xs={3} className="m-0 p-2 text-center fs-7 fw-bolder d-flex justify-content-center align-items-center">
                            {
                                item?.price === 0 ? (
                                    <button className="btn border text-grey fs-7 rounded-0" onClick={()=>alert('배송비가 없는 경우 쿠폰 사용이 불가능합니다.')}>쿠폰선택</button>
                                ): (
                                    <button
                                        className="btn border-hw text-hw fs-7 rounded-0"
                                        onClick={() => setCouponModals((prevModals) =>
                                            prevModals.map((state, idx) => (idx === index ? true : state)))
                                        }
                                    >
                                        쿠폰선택
                                    </button>
                                )
                            }
                            
                        </Col>
                        <hr className="m-0 p-0" />
                    </Row>
                    <SelectCouponModal
                        id={item?.id}
                        type={"배송비"}
                        vendor={item?.vendor}
                        price={item?.price}
                        modalState={couponModals[index]}
                        setModalState={(value) =>
                            setCouponModals((prevModals) =>
                                prevModals.map((state, idx) => (idx === index ? value : state))
                            )
                        }
                        setOrderData={setOrderData}
                    />
                </div>
            ))}
        </div>
    );
}