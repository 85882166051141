/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 셀러관리자 > 주문배송 > 배송현황 조회 페이지 >  OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';

function OrderRow ({ order, index }) 
{
  
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
    <tr key={order?.id}>
      <td className="">{order?.order_number}</td>
      <td>
          {extractYearMonthDayHourMinute2(order?.paid_at)}<br/>
          출고일시
      </td>
      <td>{order?.status}</td>
      <td>{order?.delivery_type}</td>
      <td>
        {order?.product_id }<br/>
        {order?.product?.name}
      </td>
      <td>
        {order?.option_rows.map((option,index) => (
            <p key={index}>{option.name}</p>
        ))}
      </td>
      <td>{order.courier ? order.courier : "-" }</td>
      <td>
          송장번호
      </td>
      <td>{order?.option_rows?.map(x => x.quantity).join('\n')}</td>
      <td>{typeof order.total_price === 'number' && order.total_price.toLocaleString()}원</td>
      <td>
          {order.orderer_username && order.orderer_username} <br/>
          {order.orderer_name && order.orderer_name}
      </td>
      <td>
        {order.recipient_name && order.recipient_name} <br/>
        {order.recipient_phone_number && order.recipient_phone_number}
      </td>
      <td>
          [{order.postal_code && order.postal_code}] 
          {order.address && order.address}<br/>
          {order?.address?.detail}
      </td>
      <td>{extractYearMonthDayHourMinute2(order?.arrived_at)}</td>
      <td>{extractYearMonthDayHourMinute2(order?.expected_confirmed_at)}</td>
 
    </tr>
    </>
  )
}

export default OrderRow
