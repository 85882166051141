/***************************************************************************************
* FileName      : SortComponent4.js
* Description   : 셀러관리자 > 정산 > 정산현황 > 정렬 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from 'react';

const SortComponent4 = ({ ordering, handleOrderingChange }) => {
  return (
    <div className='selectWrap'>
        <select className='mSelectBox' id="ordering" value={ordering} onChange={handleOrderingChange}>
            <option value="-settlement_date">정산일 내림차순</option>
            <option value="settlement_date">정산일 오름차순</option>
        </select>
    </div>
  );
}

export default SortComponent4;