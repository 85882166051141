/***************************************************************************************
* FileName      : ProductInspectInfo.js
* Description   : 고객 > 상품 상세페이지 > 검수 정보 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';

function ProductInspectInfo({ productDetails }) {
    return (
        <Table className="order-detail-table">
            <tbody className="fs-7">
                <tr>
                    <td colSpan={1}>제조사</td>
                    <td colSpan={3}>{productDetails?.inspect_info?.manufacturer}</td>
                </tr>
                <tr>
                    <td colSpan={1}>인증대상</td>
                    <td colSpan={3}>{productDetails?.inspect_info?.certification_target}</td>
                </tr>
                <tr>
                    <td colSpan={1}>미성년자 구매가능</td>
                    <td colSpan={3}>{productDetails?.inspect_info?.minor_purchase ? "가능" : "불가능"}</td>
                </tr>
                <tr>
                    <td colSpan={1}>1인당 최대 구매수량</td>
                    <td colSpan={3}>{productDetails?.inspect_info?.max_purchase_per_person.toLocaleString()}개</td>
                </tr>
                <tr>
                    <td colSpan={1}>판매시작일</td>
                    <td colSpan={3}>{extractYearMonthDay(productDetails?.inspect_info?.sales_start_date)}</td>
                </tr>
                <tr>
                    <td colSpan={1}>판매종료일</td>
                    <td colSpan={3}>{extractYearMonthDay(productDetails?.inspect_info?.sales_end_date)}</td>
                </tr>
                <tr>
                    <td colSpan={1}>과세여부</td>
                    <td colSpan={3}>{productDetails?.inspect_info?.tax}</td>
                </tr>
            </tbody>
        </Table>
    )
}

function extractYearMonthDay(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    if (year === 9999) return "없음";
    const month = date.getMonth() + 1;  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
    const day = date.getDate();

    return `${year}년 ${month}월 ${day}일`;
}


export default ProductInspectInfo;
