
import { useNavigate } from 'react-router-dom';
import { getMe, logOut } from './api/useLogin.js';
import Cookies from 'js-cookie';
import { useState, useEffect} from 'react';
import { Row, Col, DropdownButton, Dropdown, Badge } from "react-bootstrap";

function SellerManagerheader(){
	const [userName, setUserName] = useState('');
	let navigate = useNavigate();

	useEffect(()=>{
		getMe()
			.then(res => setUserName(res?.data?.userName))
			.catch(error => alert('로그인 정보 에러'))
	}, [])

	const logout = () => {
		// 토큰 삭제
		logOut()
			.then(res => {
				console.log(res?.data);
				Cookies.remove('access');
				Cookies.remove('refresh');
				// 메인페이지로 새로고침
				navigate('/');
				window.location.reload();
			})
	}

	// [hm] 회원 토큰 만료 시 로그아웃 프로세스 실행
	useEffect(()=>{
		if(userName === "guest"){
			logout();
		}
	},[userName])


    return (
        <> 
        <header className="m_header seller">
			<div className="lnb">
				<div className="logo">
					<a>
						<img src={process.env.PUBLIC_URL + '/img/Logo_00.png'} onClick={()=>navigate('/selleradmin/main')} /> 
					</a>
				</div>
				<div className="search-content">
					<div className='search-field'>
						<input type="text" placeholder='검색어를 입력해주세요'/>
						<i className='xi-search'></i>
					</div>
                </div>
				<div className="user-content ms-2">
					<div>
						<DropdownButton variant="white" drop="down" alignRight title={userName}>
							<Dropdown.Item eventKey="1" onClick={() => navigate('/selleradmin/sellerInfo/editVendorInfo')}>설정</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item eventKey="2" onClick={() => logout()} className="text-danger">로그아웃</Dropdown.Item>
						</DropdownButton>
					</div>
				</div>
			</div>

        </header>    
        </>
    )

}

export default SellerManagerheader