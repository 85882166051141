
import {useNavigate , useLocation  } from 'react-router-dom'
import React, { useRef, useEffect , useState } from 'react';
import { Link } from 'react-router-dom';

function MNav()
{

    const location = useLocation();
    const currentPath = location.pathname;

	let navigate = useNavigate();


    const [openIndex, setOpenIndex] = useState(-1);
    const menuItems = [
        { title: '상품관리', 
            contents: [
                {title: '상품목록', link: '/selleradmin/product/list/'},
                {title: '상품등록', link: '/selleradmin/product/register/'},
                {title: '요청목록', link: '/selleradmin/product/requestList/'},

            ] 
        },
        { title: '주문배송', 
            contents: [
                {title: '주문조회', link: '/selleradmin/orderDelivery/list'},
                {title: '배송현황', link: '/selleradmin/orderDelivery/deliveryList'},
                {title: '구매확정내역', link: '/selleradmin/orderDelivery/purchaseList'},
                {title: '반품/취소', link: '/selleradmin/orderDelivery/returnExchangeCancel'},
            ] 
        },
        { title: '고객관리', 
            contents: [
                {title: '상품문의', link: '/selleradmin/customerManagement/productInquiryList'}, 
                {title: '상품평', link: '/selleradmin/customerManagement/productReviewList'}, 
            ] 
        },
        { title: '정산', 
            contents: [
                {title: '정산현황', link: '/selleradmin/settlement/settlementStatus'}, 
                {title: '미지급내역', link: '/selleradmin/settlement/unpaid'},
                {title: '매출내역', link: '/selleradmin/settlement/salesHistory'}, 
                {title: '부가세내역', link: '/selleradmin/settlement/TaxHistory'}, 
                {title: '세금계산서내역', link: '/selleradmin/settlement/invoiceHistory'},
        ] 
        },
        { title: '판매통계', 
            contents: [
                {title: '판매통계', link: '/selleradmin/salesstatistics/salesstatistics'}, 
            ] 
        },
        { title: '판매자정보', 
            contents: [
                {title: '배송달력 관리', link: '/selleradmin/sellerInfo/deliveryCalendar'}, 
                {title: '매장정보', link: '/selleradmin/sellerInfo/vendorInfo'}, 
                // {title: '정보수정', link: '/selleradmin/sellerInfo/editProfile'}, 
                {title: '매장정보 수정요청', link: '/selleradmin/sellerInfo/editVendorInfo'}, 
                {title: '직원관리', link: '/selleradmin/sellerInfo/employeeManagement'}, 

            ] 
        },
      
      ];
  
    const handleClick = (index) => {
      if (openIndex === index) {
        setOpenIndex(-1);
      } else {
        setOpenIndex(index);
      }
    };
    console.log(location.pathname)
    return (
        <> 
		<nav className='Mnav'>
            {menuItems.map((item, index) => (
                <div className="category-wrap" key={index}>
                    <div onClick={() => handleClick(index)}>
                        <h2>{item.title}</h2>
                        {openIndex === index ? 
                            <i className='xi-angle-up'></i> : <i className='xi-angle-down'></i>
                        }
                    </div>
                    {openIndex === index && (
                    <ul>
                        {item.contents.map((content, subIndex) => (
                            <li 
                            className={location.pathname === content.link ? 'active' : ''}
                            key={subIndex}>
                                <Link to={content.link}>{content.title}</Link>
                            </li>
                        ))}
                    </ul>
                    )}
                </div>
            ))}
             
	
		</nav>
        </>
    )

}

export default MNav