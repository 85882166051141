import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MypageTabComponent from './MypageTab_v2.js';
import Sidemenu from './Sidemenu.js'
import StatusBar from './StatusBar.js';
import Pagination from '../customer/common/Pagination.js';
import { useMyLikeProductListQuery } from '../../api/services/mypageApi';
import WishBox from './WishList/WishBox.js';

function WishListLayout(){
    return(
        <>
        <Container>
            <StatusBar/>
            <Row className='m-0'>
            <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'wish'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <WishList />
                </Col>
            </div>
            </Row>
        </Container>
        </>
      )
}

function WishList()
{
    const { data:myLikeProductData, error, isFetching, page, setPage } = useMyLikeProductListQuery();

    const tabs = [
        {label: '상품' , url : '/mypage/wish'},
        {label: '상회' ,  url : '/mypage/vendorWish' }
    ];

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
          

    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-5 fw-bold">찜한 상품</h3>
                </Col>
            </Row>
        
            <MypageTabComponent tabs={tabs}/>

            <span className='mypage_w_total'>
                <strong>전체 <em>{myLikeProductData?.totalCnt}</em>개</strong>
            </span>

            <table className='mypage-table'>
                <colgroup>
                    <col style={{ width: '50%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '25%' }} />
                </colgroup>
                <thead >
                    <tr>
                        <th>상품</th>
                        <th>가격</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody className='history'>   
                    {myLikeProductData?.totalCnt === 0 ? (
                        <tr>
                            <td colSpan="3">찜한 상품이 없습니다.</td>
                        </tr>
                    ) : (    
                        myLikeProductData?.data?.map((wishData, index) => (
                            <WishBox
                                wishData={wishData}
                                index={index}
                            />
                    ))
                )}

                </tbody>
            </table>


            {/* 페이지내비게이션 */}
            <section className='mt-5'>
              <Pagination
                  count={myLikeProductData?.totalCnt || 0}
                  page={page}
                  setPage={setPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
              />
          </section>

        </div>
        </>
    );
}



export default WishListLayout