/***************************************************************************************
* FileName      : AddModal.js
* Description   : 해와관리자 > 정산 > 세금계산서내역 추가 모달창
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect , useRef } from 'react';
import { Modal , Button } from 'react-bootstrap';
import { RegisterInvoice } from '../../../../api/hwadmin/adjustApi';
import { useApprovedVendors } from '../../../../api/hwadmin/productApi';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { getUploadURL , uploadImage } from '../../../../api/uploadImage/uploadImageApi';

function AddModal({show, setShow})
{
    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    // 수산상회 조회
    const { data: approvedVendors } = useApprovedVendors();

    
    // 파일업로드
    const [selectedFile, setSelectedFile] = useState(null);

    const inputRef = useRef(null); 

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };

    const createMutaion = useMutation(RegisterInvoice , {
        onSuccess : (data) => {
            reset();
            alert("등록 완료");
            setIsLoading(false);
            setShow(false);
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        }
    }); 

    // 이미지 파일 업로드 
    const uploadImages = async () => {
        try {
            const URL = await getUploadURL(selectedFile);
            if (URL) {
                const imageURL = await uploadImage(URL);
                console.log("이미지 업로드 완료");
                return imageURL; 
            }
        }catch (error) {
            console.error("이미지 업로드 에러:", error);
        }
    }

    const onSubmit = async (data) => {
        setIsLoading(true);
        const imageURL = await uploadImages();

        const newData = {
            issued_at : data.issued_at,
            vendor : data.vendor,
            detail : data.detail,
            issue_number : data.issue_number,
            supply_amount : data.supply_amount,
            tax_amount : data.tax_amount,
            invoice_image : imageURL,
        };
        createMutaion.mutate(newData);
    
    };

    return (
        <>
         <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
        
        <Modal.Header closeButton>
            <Modal.Title  style={{ fontSize: '18px' }}>
               세금계산서 내역 추가
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>    
            <table className='sTable' id="addModalInput">
                <thead>
                    <tr>
                        <th>수상상회</th>
                        <th>발행일</th>
                        <th>발행내용</th>
                        <th>발행번호</th>
                        <th>공급가액</th>
                        <th>세액</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr>
                        <td>
                            <select {...register('vendor', { required: '수산상회 선택은 필수입니다.' })}>
                                { approvedVendors && approvedVendors.data.map(vendor => (
                                    <option key={vendor.id} value={vendor.name}>
                                        {vendor.name}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <input
                                className={`${errors.issued_at ? 'error-input' : ''}`}
                                {...register('issued_at', { required: '날짜 입력은 필수입니다.' })}
                                type='date'
                            />
                        </td>
                        <td>
                            <input
                                    className={`${errors.detail ? 'error-input' : ''}`}
                                    placeholder='상품판매수수료'
                                    {...register('detail', { required: '상품판매수수료 입력은 필수입니다.' })} 
                                    type='text'  
                                />
                        </td>
                        <td>
                            <input
                                className={`${errors.issue_number ? 'error-input' : ''}`}
                                placeholder='세금계산서번호'
                                {...register('issue_number', { required: '세금계산서번호 입력은 필수입니다.' })} 
                                type='text'  
                            />
                        </td>
                        <td>
                            <input
                                className={`${errors.supply_amount ? 'error-input' : ''}`}
                                placeholder='공급가액'
                                {...register('supply_amount', { 
                                    required: '공급가액 입력은 필수입니다.',
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: '숫자만 입력하세요.',
                                    },
                                })} 
                                type='text'  
                            />
                        </td>
                    
                        <td>
                            <input
                                className={`${errors.tax_amount ? 'error-input' : ''}`}
                                placeholder='세액'
                                {...register('tax_amount', { 
                                    required: '세액 입력은 필수입니다.', 
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: '숫자만 입력하세요.',
                                    },
                                })} 
                                type='text'  
                            />
                        </td>
            
                    </tr>
                </tbody>
            </table>
            
            <div className='invoice-file-upload-wrap'>
                <p>파일업로드</p>
                <div className='invoice-file-upload-box' onClick={() => inputRef.current.click()}>
                    {selectedFile ? (
                        <p>파일 선택됨: {selectedFile.name}</p>
                    ) : (
                        <p>클릭하여 세금계산서 파일 업로드</p>
                    )}

                    <input
                        type="file"
                        ref={inputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
            </div>

            <div className='btn-wrap-center'>
                <Button type="submit" variant="primary"> 
                    {isLoading ?  <LoadingSpinner /> : '출고완료'} 
                </Button>
            </div>
            
            </form>
        </Modal.Body>
    </Modal>
    </>
    )
} 

export default AddModal