import { useState , useRef , useEffect } from 'react';
import { fetchVendorData } from '../../../api/selleradmin/vendorApi';
import { updateEmployeeData } from '../../../api/selleradmin/vendorApi';

import { verifyEmployeeId } from '../../../api/hwadmin/storeApi';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useQuery , useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';


// 셀러관리 > 직원관리 (직접수정가능)
function EmployeeManagement()
{
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };
      
    // 데이터 조회
    const { data, isError } = useQuery(['vendorData'], () => fetchVendorData());

    // 데이터 수정
    const handleUpdate = async () => {
        try {
            const newData = {
                employee_list: employeeId_list || "",
            };

            setIsLoading(true); 

            const updatedData = await updateEmployeeData(newData);

            setIsLoading(false);
            alert("수정되었습니다.");
            // window.location.href = '/admin/store/inquiry';

            // console.log('업데이트된 데이터:', updatedData);

            } catch (error) {
           
                console.error('업데이트 오류:', error);

            }
    };

  

    //직원추가(선택)
    const [input_employeeValue, setInputEmployeeValue] = useState('')
    const [employee_list, setEmployeelist] = useState([])
    const [employeeId_list, setEmployeeIdlist] = useState([])


    // 기존 데이터 조회
    useEffect(() => {

        // // 3. 직원
        if (data && data.employee_list) {
            const usernames = data.employee_list.map((employee) => employee.username);
            setEmployeelist(usernames);
        }

    }, [data]);    


    if (isError) {
        return <div>Error occurred while fetching data</div>;
    }

    console.log(data)
    
  // 직원 리스트 추가
  const handleEmployeeSubmit = (event) => {
        event.preventDefault();
        // 직원 아이디 확인

        const employeeId = input_employeeValue;

        verifyEmployeeId(employeeId)
        .then((checkEmployee) => {
            if(checkEmployee.data === "확인완료"){
                setEmployeeIdlist([...employeeId_list, checkEmployee.id]);      
                setEmployeelist([...employee_list, input_employeeValue]);           
            }   
        })
        .catch((error) => {
            alert("등록된 회원이 아닙니다")
            console.log(error);
        });
        
        setInputEmployeeValue("");  
    };

    // 직원 리스트 삭제
    const handleEmployeeDelete = (index) => {
        const newListItems = [...employee_list];
        newListItems.splice(index, 1);
        setEmployeelist(newListItems);
    };

    return(
        <>
        <div className='mainContents'>
            {/* 직원관리 (직접수정가능) */}
            <div className='page-tit'>
                <h2>직원관리</h2>
            </div>

    
            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>직원관리</h3>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>직원추가(선택)</td>
                                <td className='ep10'>
                                    <input 
                                        type="text"
                                        value={input_employeeValue}
                                        placeholder='직원의 아이디를 입력하세요'
                                        onChange={(e) => setInputEmployeeValue(e.target.value)}
                                    />
                                     <button 
                                        className='addEmployeeBtn'
                                        onClick={handleEmployeeSubmit}>추가</button>

                                    <ul className='employeeList'>
                                        {employee_list.map((item, index) => (
                                             <li key={index}>
                                                {item}
                                                <button onClick={() => handleEmployeeDelete(index)}>X</button>
                                            </li>
                                        ))}
                                    </ul>

                                   
                                </td>
                            </tr>
                
                        </tbody>
                    </table>
                </div>
            
                <div className='m-btn-wrap'>
                    <button onClick={handleUpdate} className='m-btn m-btn-search'>
                        {isLoading ?  <LoadingSpinner /> : '직원수정'} 
                    
                    </button>
                </div> 

            </section>



        </div>

        </>
    )
}

export default EmployeeManagement

