import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; //토큰저장을 위한 쿠키 사용
import { instance } from '../axiosInstance/axiosConfig';
import { setCookie } from './cookieUtils';




function useLogin() {
  const navigate = useNavigate();

  const mutation = useMutation(
    async ({ username, password }) => {
      const response = await instance.post(`auth/login/`, { username, password });
      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log(data.access);
        console.log("--로그인됨--");
        if (process.env.REACT_APP_ENV_NAME === 'dev') {
          setCookie("access", data?.access);
          setCookie("refresh", data?.refresh);
        }
        navigate('/');
        window.location.reload();
      },
      onError: (error) => {
        alert("로그인에러 : " + error.response.data.data);
      },
    }
  );

  return mutation;
}


// export const getMe = async () => {
//   try {
//     const response = await instance.get('/auth/me');
//     return response.data.data;
//   } catch (error) {
//     return 'guest';
//   }
// };
export const getMe = () =>{
  return instance.get('/auth/me/');
}


export const logOut = async () => {
  try {
    const response = await instance.get('/auth/logout');
  } catch (error) {
    console.log("로그아웃 에러! ",error);
  }
}


export default useLogin;