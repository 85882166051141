import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { getSalesDelivery, getTodos, getProduct, getVendor, getNews, getInquiry, getSales } from '../../api/dashboardApi';
import { useEffect, useState } from 'react';

function AdminDashboard()
{
    // 판매/배송
    const [deliveryData, setDeliveryData] = useState({
        payment_complete_count: 0,
        product_preparing_count: 0,
        delivery_instruction_count: 0,
        delivery_count: 0,
        delivery_complete_count: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getSalesDelivery()
            .then(res => {
                setDeliveryData(res.data);
            })
            .catch(error => error.response.data)
    },[])

    // 오늘 할 일
    const [todosData, setTodosData] = useState({
        cancel_count: 0,
        refund_count: 0,
        exchange_count: 0,
        order_not_confirmed_count: 0,
        not_delivery_count: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getTodos()
            .then(res => setTodosData(res.data))
            .catch(error => error.response.data)
    },[])

    // 상품통계
    const [productData, setProductData] = useState({
        on_sale_count: 0,
        stop_sale_count: 0,
        sold_out_count: 0,
        approval_waiting_count: 0,
        approval_reject_count: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getProduct()
            .then(res => setProductData(res.data))
            .catch(error => error.response.data)
    },[])

    // 매장통계
    const [vendorData, setVendorData] = useState({
        total_vendor_count: 0,
        open_vendor_count: 0,
        hidden_vendor_count: 0,
        approval_waiting_vendor_count: 0,
        approval_reject_vendor_count: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getVendor()
            .then(res => setVendorData(res.data))
            .catch(error => error.response.data)
    },[])

    // 신규등록
    const [newsData, setNewsData] = useState({
        new_vendor_count: 0,
        new_product_count: 0,
        new_review_count: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getNews()
            .then(res => setNewsData(res.data))
            .catch(error => error.response.data)
    },[])

    // 미답변 문의
    const [inquiryData, setInquiryData] = useState({
        product_inquiry_count: 0,
        onetoone_inquiry_count: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getInquiry()
            .then(res => setInquiryData(res.data))
            .catch(error => error.response.data)
    },[])

    // 판매 현황
    const [salesData, setSalesData] = useState({
        today_order_count: 0,
        today_payment_complete_price: 0,
        updated_at: "00:00"
    })
    useEffect(()=>{
        getSales()
            .then(res => setSalesData(res.data))
            .catch(error => error.response.data)
    },[])


    return(
        <>
        {/* 콘텐츠 부분 */}
            <div className='mainContents f9'>
                {/* 관리자 메인 */}
                <section className='contentList'>
                    {/* 판매/배송 */}
                    <div className='itemWrap'>
                        <div className='tit'>
                            <div>
                                <h2>판매/배송</h2>
                                {/* <p>현재 토큰: {token}</p> */}
                                <span>최근 14일 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {deliveryData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>{deliveryData?.payment_complete_count}</p>
                                <span>결제완료</span>
                            </div>
                            <div class="status-box">
                                <p>{deliveryData?.product_preparing_count}</p>
                                <span>상품준비중</span>
                            </div>
                            <div class="status-box">
                                <p>{deliveryData?.delivery_instruction_count}</p>
                                <span>배송지시</span>
                            </div>
                            <div class="status-box">
                                <p>{deliveryData?.delivery_count}</p>
                                <span>배송중</span>
                            </div>
                            <div class="status-box">
                                <p>{deliveryData?.delivery_complete_count}</p>
                                <span>배송완료</span>
                            </div>
                        </div>
                    </div>
                    {/* 오늘 할 일 */}
                    <div className='itemWrap'>
                        <div className='tit'>
                            <div>
                                <h2>오늘 할 일</h2>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {todosData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>{todosData?.cancel_count}</p>
                                <span>취소현황</span>
                            </div>
                            <div class="status-box">
                                <p>{todosData?.refund_count}</p>
                                <span>반품현황</span>
                            </div>
                            <div class="status-box">
                                <p>{todosData?.exchange_count}</p>
                                <span>교환현황</span>
                            </div>
                            <div class="status-box">
                                <p>{todosData?.order_not_confirmed_count}</p>
                                <span>주문미확인</span>
                            </div>
                            <div class="status-box">
                                <p>{todosData?.not_delivery_count}</p>
                                <span>미배송현황</span>
                            </div>
                        </div>
                    </div>

                    {/* 상품통계 */}
                    <div className='itemWrap'>
                        <div className='tit'>
                            <div>
                                <h2>상품통계</h2>
                                <span>현재 등록된 전체 상품 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {productData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>{productData?.on_sale_count}</p>
                                <span>판매중</span>
                            </div>
                            <div class="status-box">
                                <p>{productData?.stop_sale_count}</p>
                                <span>판매중지</span>
                            </div>
                            <div class="status-box">
                                <p>{productData?.sold_out_count}</p>
                                <span>품절</span>
                            </div>
                            <div class="status-box">
                                <p>{productData?.approval_waiting_count}</p>
                                <span>승인대기</span>
                            </div>
                            <div class="status-box">
                                <p>{productData?.approval_reject_count}</p>
                                <span>반려</span>
                            </div>
                        </div>
                    </div>

                    {/* 매장통계 */}
                    <div className='itemWrap'>
                        <div className='tit'>
                            <div>
                                <h2>매장통계</h2>
                                <span>현재 등록된 전체 매장 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {vendorData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>{vendorData?.total_vendor_count}</p>
                                <span>전체매장</span>
                            </div>
                            <div class="status-box">
                                <p>{vendorData?.open_vendor_count}</p>
                                <span>공개</span>
                            </div>
                            <div class="status-box">
                                <p>{vendorData?.hidden_vendor_count}</p>
                                <span>비공개</span>
                            </div>
                            <div class="status-box">
                                <p>{vendorData?.approval_waiting_vendor_count}</p>
                                <span>승인대기</span>
                            </div>
                            <div class="status-box">
                                <p>{vendorData?.approval_reject_vendor_count}</p>
                                <span>반려</span>
                            </div>
                        </div>
                    </div>

                    {/* 신규등록 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>신규등록</h2>
                                <span>최근 14일 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {newsData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>{newsData?.new_vendor_count}</p>
                                <span>신규매장</span>
                            </div>
                            <div class="status-box">
                                <p>{newsData?.new_product_count}</p>
                                <span>신규상품</span>
                            </div>
                            <div class="status-box">
                                <p>{newsData?.new_review_count}</p>
                                <span>상품후기</span>
                            </div>
                        </div>
                    </div>

                    {/* 미답변 문의 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>미답변 문의</h2>
                                <span>현재상태 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {inquiryData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap">
                            <div class="status-box">
                                <p>{inquiryData?.product_inquiry_count}</p>
                                <span>상품문의</span>
                            </div>
                            <div class="status-box">
                                <p>{inquiryData?.onetoone_inquiry_count}</p>
                                <span>1:1문의</span>
                            </div>
                        </div>
                    </div>

                    {/* 판매 현황 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>판매 현황</h2>
                                <span>당일판매 현황 00:00~ 23:59</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 {salesData?.updated_at}</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap col">
                            <div class="status-box">
                                <p>{salesData?.today_order_count}<span>건</span></p>
                                <span>주문 건 수</span>
                            </div>
                            <div class="status-box">
                                <p>{salesData?.today_payment_complete_price}<span>원</span></p>
                                <span>결제완료금액</span>
                            </div>
                        </div>
                    </div>

                    {/* 정산 현황 */}
                    <div className='itemWrap sbox'>
                        <div className='tit'>
                            <div>
                                <h2>정산 현황</h2>
                                <span>현재상태 기준</span>
                            </div>
                            <div className='reset'>
                                <span className='time'>최근 15:00</span>
                                <p className='reset_btn'><i className='xi-refresh pointer' onClick={()=>window.location.reload()}></i></p>
                            </div>
                        </div>
                        <div class="detailWrap col">
                            <div class="status-box">
                                <p>0<span>건</span></p>
                                <span>정산완료</span>
                            </div>
                            <div class="status-box">
                                <p>0<span>원</span></p>
                                <span>정산예정</span>
                            </div>
                        </div>
                    </div>

                    <div className='itemWrap mbox'>
                        <p>test</p>
                    </div>
                </section>
              
  
            </div>

        </>
    )
}

export default AdminDashboard