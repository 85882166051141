/***************************************************************************************
* FileName      : MemoModal.js
* Description   : 해와관리자 > 매장조회 테이블 > 관리자메모 > 메모 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.08
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useEffect, useState , useRef } from 'react';
import { useVendorMemoQuery , fetchvendorMemo2 , RegisterVendorMemo , deleteVendorMemo , updateVendorMemo } from '../../../../api/hwadmin/storeApi';

import { useMutation, } from 'react-query';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';
import Pagination from '../../../customer/common/Pagination';

function MemoModal({show, setShow, vendorId , vendorName})
{
    const [page, setPage] = useState(1);

    const { data, error, isFetching, } = useVendorMemoQuery(vendorId);
 
    const [memoData , setMemoData ] = useState(data);

    const [addStatus , setAddStatus] = useState(false);

    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editedContent, setEditedContent] = useState('');

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
            <div className="spinner" ></div>
            </div>
        );
    };

    const [content, setContent] = useState('');

    const createNoticeMutaion = useMutation(RegisterVendorMemo , {
        onSuccess : (newData) => {
            updateData(vendorId , page);
            setIsLoading(false);
            setContent('');
        },
        onError : (data) => {
            console.log("error", data);
            setIsLoading(false);
        },
        
    });

    const updateData = (vendorId) => {
        fetchvendorMemo2(vendorId , page)
        .then(response => {
            setMemoData(response);
        })
        .catch(error => {
          console.error(error);
        });
    }

    const onSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true);
        if(content === ''){
            alert('내용을 입력해주세요')
            return;
        }

        const newData = {
            vendor: vendorId || "",
            content: content || "",
        }

        createNoticeMutaion.mutate(newData);
    };

    // 메모 삭제 
    const deleteThisMemo = (event, id) => {
        event.preventDefault();
        
        deleteVendorMemo(id)
        .then(() => {
            updateData(vendorId);
        })
        .catch((error) => console.log(error.response.data));
      
    };

    // 메모 수정 버튼 클릭
    const startEditing = (index, content) => {
      setEditingRowIndex(index);
      setEditedContent(content);
    };

    // 메모 수정 후 저장
    const saveChanges = (id) => {
        updateVendorMemo(id, editedContent)
        .then(() => {
            setEditingRowIndex(null);
            setEditedContent('');
            updateData(vendorId);
        })
        .catch((error) => console.log(error.response.data));
    };


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(()=>{
        updateData(vendorId,page);
    },[page])

    return(
      <>
      <Modal
          size="xl"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" style={{ fontSize: '18px' }}>
            {vendorName} 메모
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <table className='sTable' id='scrollTable'>
               
                <colgroup>
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '55%' }} />
                    <col style={{ width: '10%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>작성일시</th>
                        <th>작성자</th>
                        <th>내용</th>
                        <th>수정/삭제</th>
                    </tr>
                </thead>
                
                <tbody>
                    {memoData?.data?.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index+1}</td>
                            <td>{extractYearMonthDayHourMinute2(item.created_at)}</td>
                            <td>{item.user}</td>
                            <td>
                                {editingRowIndex === index ? (
                                    <input
                                        type="text"
                                        value={editedContent}
                                        className='memoinput-box'
                                        onChange={(e) => setEditedContent(e.target.value)}
                                    />
                                ) : (
                                    item.content
                                )}
                            </td>

                            <td>              
                                {editingRowIndex === index ? (
                                    <button
                                        className='m-sm-btn mr5'
                                        onClick={() => saveChanges(item.id)}
                                    >
                                    저장
                                    </button>
                                ) : (
                                    <button
                                        className='m-sm-btn mr5'
                                        onClick={() => startEditing(index, item.content)}
                                    >
                                    수정
                                    </button>
                                )}


                                <button 
                                    className='m-sm-btn' 
                                    type="button" 
                                    onClick={(event) => deleteThisMemo(event, item.id)}>삭제</button>
                            </td>
                        </tr>
                    ))}

                
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <input type="text"
                                    value={content}
                                    className='memoinput-box' 
                                    onChange={(e) => setContent(e.target.value)}/> 
                        </td>
                        <td>
                            <button type="button" className="btn btn-primary" onClick={(event) => onSubmit(event)}>
                                {isLoading ?  <LoadingSpinner /> : '추가'} 
                            </button>
                        </td>
                    </tr>
                
                </tbody>

            </table>

    
            {/* 페이지내비게이션 */}
            <section className='mt-5'>
                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                />
            </section>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default MemoModal