// 관리자 페이지 > 마케팅 > 제철상품 
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import MainCategories from './SeasonalProduct/Category/MainCategories';
import { useEffect , useState } from 'react';
import { useSeasonalProductsQuery } from '../../../api/marketingApi';
import Pagination from '../common/Pagination'

function SeasonalProduct()
{
    const { data, 
            error, 
            isLoading,
            isFetching,     
            page, 
            setPage, 
            totalPages, 
            pageSize, 
            setPageSize } = useSeasonalProductsQuery();
    
    // 중분류 ids 
    const [ middleIds , setMiddleIds ] = useState([]);

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    
    
    useEffect(()=>{
        if( data===undefined || data.length === 0 ){
        //   console.log("여기는오나")  
            // &page_size=all 했을 때 db에서 빈 배열[] 만 보내주기 때문에 
            // 일단 이렇게 막았음
        }else if(data){
            // middle_id만 필터링하여 배열로 저장
            const  newArray =  data.data.map((item) => item.category.middle_id); 
            setMiddleIds(newArray);
        }

    },[data])

    if (isLoading) {
        return <div>Loading...</div>;
    }

      
    return(
        <>
        <div className='mainContents'>
            {/* 제철상품 */}
            <div className='page-tit'>
                <h2>제철상품</h2>
            </div>

            {/* 카테고리 선택 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className=''>
                    <div className='categbox'>
                        <MainCategories  
                            middleIds={middleIds}
                        />
                    </div>
                    </div>
                </div>
            </section>


            {/* 제철 상품 리스트 조회 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className=''>
                        <span className='fs-6'>제철상품 리스트</span>
                    </div>    
                </div>
               
                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>대분류</th>
                                <th>중분류</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 || (data && data?.count === 0) ? (
                                <tr>
                                    <td colSpan="17"> 결과가 없습니다.</td>
                                </tr>
                            ) : data?.count > 0 ? (
                                data.data.map((product) => (
                                    <tr key={product.id}>
                                        <td>{product.category.big}</td> 
                                        <td>{product.category.middle}</td> 
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="17">로딩 중...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>


            {/* 페이지내비게이션 */}
            {/* <section>
                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                />
            </section> */}
        </div>

        </>
    )
}

export default SeasonalProduct