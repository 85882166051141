import { useEffect, useState } from 'react';
import { useFilteringOptions } from '../../../../../api/Category';
import Filtering from './Filtering';

function ProductFilter({categoryId_arr , filteringCheck , setFilteringCheck})
{
    const [filteringCheckData, setFilteringCheckData] = useState({});
    // 조회용
    const [viewfilteringCheckIds, setViewfilteringCheckIds] = useState([]);

    useEffect(()=>{
        // console.log("안filteringCheck:",filteringCheck);
        // console.log("checked:",checked)

        const extractedIds = filteringCheck.map(item => item.id);
        setViewfilteringCheckIds(extractedIds);

    },[filteringCheck])
 
    // 상품 등록 api에 보내야할 데이터 처리 
    // useEffect(()=>{
    //     const newArray = [];
    //     for (const key in filteringCheckData) {
    //       if (filteringCheckData[key] !== null) {
    //         newArray.push(filteringCheckData[key]);
    //       }
    //     }

    //     setFilteringCheck(newArray);
      
    //     // console.log("filteringCheckData 상태:")
    //     // console.log(filteringCheckData);
    // },[filteringCheckData])


    const [lastCategoryId, setLastCategoryId] = useState(null);
    const [options, setOptions] = useState([]);

    const { isLoading, isError, data: filteringData } = useFilteringOptions(lastCategoryId);
    const [tableRows, setTableRows] = useState([]);
    const [checked, setChecked] = useState({});

   

    // 0. 카테고리 가장 마지막 (소분류) ID 저장하기 
    useEffect(()=>{
        console.log("categoryId_arr::",categoryId_arr)
        if (categoryId_arr && categoryId_arr.length > 0) {
            setLastCategoryId(categoryId_arr[categoryId_arr.length - 1]);
        }
    },[categoryId_arr])


    // 1. 카테고리 가장 마지막 (소분류) ID 로 필터링 조회
    useEffect(() => {
        if (lastCategoryId !== null) {
            if (filteringData && filteringData.data) {
                const extractedOptions = filteringData.data.map(item => item);
                console.log('extractedOptions:', extractedOptions);
                setOptions(extractedOptions);
            }
        }
        
    }, [lastCategoryId, filteringData]);

        
    // 2. 상품필터 (보이기 감추기) 체크박스 초기화 
    useEffect(()=>{
        
        options.forEach((option) => {
            setChecked((prevChecked) => ({
              ...prevChecked,
              [option.id]: filteringCheck.some(item => item.parent === option.id),
            }));
        });
        

    },[options])

    // useEffect(()=>{
    //     console.log("checkedchecked:",checked)
    // },[checked])

    // 3. 필터링 테이블 생성
    useEffect(() => {
        console.log("0.options 확인",options)

        const updatedTableRows = options.map((option) => {
            const optionId = option.id;
            return (
                <>
                  {checked[optionId] && (
                    <tr key={optionId}>
                      <td>{option.name}</td>
                      <td> 
                        {option && (
                            <Filtering 
                                categoryId={optionId} 
                                key={optionId} 
                                viewfilteringCheckIds={viewfilteringCheckIds}
                                setFilterIdArr={(newFilterIdArr) => {
                                // filteringCheckData 객체의 해당 키에 newFilterIdArr 저장
                                setFilteringCheckData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[optionId] = newFilterIdArr;
                                    return newData;
                                });
                                }}
                            />
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
              
        });
        // 업데이트된 테이블 행 상태를 설정합니다.
        setTableRows(updatedTableRows);

    }, [options , checked]);

  
    const handleCheckboxChange = (id) => {
        setChecked((prevChecked) => ({
          ...prevChecked,
          [id]: !prevChecked[id],
        }));

        // 체크 상태가 false이면 해당 id 키의 데이터를 삭제
        if (checked[id]) {
            setFilteringCheckData((prevData) => {
                const newData = { ...prevData };
                delete newData[id];
                return newData;
            });
        }
    };
    
      
    if (isLoading) {
        return <p>데이터를 불러오고 있습니다...</p>;
    }

    const checkboxes = options.length === 0 ? (
            <div>해당 필터링 데이터가 없습니다.</div>
    ) : (
        options.map((option, index) => (
          <div className='product-attributes-item' key={index}>
            <input
              type="checkbox"
              id={`checkbox${index}`}
              checked={checked[option.id]}
              disabled 
              onChange={() => handleCheckboxChange(option.id)}
            />
            <label htmlFor={`checkbox${index}`}>{option.name}</label>
          </div>
        ))
    );
      
    
    return (
        <>
        <p className="notice-text">
            상품의 필터 데이터 수정을 원할 경우 다시 상품 등록을 해주세요.
        </p>
        
        <section className="ProductFilterWrap">
            <p className='product-attribute-text'>상품필터</p>
            <div className='product-attributes'>   
                {checkboxes}
            </div>

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                                    
                    </colgroup>
                    <thead>
                
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>

                </table>     
            </div>

        </section>
        </>
    )
}

export default ProductFilter