import React, { useState , useEffect } from 'react';
import { fetchCategorySearch } from '../../../../../api/hwadmin/productApi.js';

function CategorySearch({categoryId_arr,setCategoryIdArr})
{   
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
 
    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
          <div className="parent-container">
            <div className="spinner" ></div>
          </div>
        );
    };
      
    // const [selectCategoryId, setSelectCategoryId] = useState(null);

    const [searchCategory, setSearchCategory] = useState('');

    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const onSearch = () => {
        setSearchConfirm(true)
        setIsLoading(true); 
        
        console.log(searchCategory)

        fetchCategorySearch(searchCategory)
        .then(data => {
            setSearchResults(data);
            console.log("결과")
            console.log(searchResults)
        })
        .catch(error => {
          // 오류 처리
          console.error(error);
        });

        setIsLoading(false); 
        // setSearchConfirm(false);

    };

    if (!searchResults) {
        return <div>loading...</div>
    }

    const handleSelectCategory = (idArr) => {
        console.log(idArr)
        setCategoryIdArr(idArr)
        console.log(categoryId_arr)
        // setSelectCategoryId(data.id_arr)
    };

    // 검색 결과
    const renderSearchResults = () => {
        if (!searchResults || !searchResults.data) {
            return null; 
        }        

        return searchResults.data.map((data) => (
            <p
                key={data.id}
                className='castext'
                onClick={() => handleSelectCategory(data.id_arr)}>
                {data.detail} 
            </p>
        ));

    };

    return (
        <>
        <div className='categorySearch'>
            <div className='select-categorys'>
                <span className=''>선택한 카테고리:</span>
            </div>
            
            <div className='select-field'>
                현재
                {categoryId_arr}
            </div>
            <div className="search-field">
                <input type="text"
                        placeholder="검색어를 입력해주세요"
                        value={searchCategory}
                        onChange={(e) => setSearchCategory(e.target.value)}/> 

                <i className="xi-search" onClick={onSearch}></i>
            </div>
            <div>  
                {searchConfirm && renderSearchResults()}
            </div>
        </div>
        </>
    )
}

export default CategorySearch