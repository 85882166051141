import { useEffect, useState } from 'react';
// 장바구니 
import { useCartQuantityPatch , useCartItemExtraDelete , useCartItemRowDelete } from '../../../api/services/cartApi';

function ModifiedModal ({setIsOpen ,selectedCartItem, setSelectedCartItem,
                         cartDeliveryItems,setCartDeliveryItems , maxPurchas })
{
    const { patchQuantity, isPatchQuantityLoading, patchQuantityError } = useCartQuantityPatch();
   
    const [ singleProduct , setSingleProduct] = useState(false);
    // const { cartItemExtraDelete } = useCartItemExtraDelete();
    // const { cartItemRowDelete  } = useCartItemRowDelete();

    const closeModal = () => {
        setIsOpen(false);
    };



    // 주문수정 수량 조절 
    const handleQuantityChange = (id, amount, optionType) => {
        setSelectedCartItem((prevSelectedItem) => {
          let updatedOptions;
          if (optionType === 'option_rows') {
            updatedOptions = prevSelectedItem.option_rows.map((option) => {
              if (option.id === id) {
                if(option.quantity + amount > maxPurchas) {
                  alert("인당 최대 구매 수량은 "+maxPurchas+"개 입니다");            
                }else{
                  const newQuantity = option.quantity + amount;

                  const adjustedQuantity = Math.max(1, newQuantity);
                  return {
                    ...option,
                    quantity: adjustedQuantity,
                  };
                }
               
              }
              return option;
            });
          } else if (optionType === 'extra_options') {
            updatedOptions = prevSelectedItem.extra_options.map((extraOption) => {
              if (extraOption.id === id) {
                const newQuantity = extraOption.quantity + amount;
                const adjustedQuantity = Math.max(1, newQuantity);
                return {
                  ...extraOption,
                  quantity: adjustedQuantity,
                };
              }
              return extraOption;
            });
          } 
    
          return {
            ...prevSelectedItem,
            [optionType]: updatedOptions,
          };
        });
    };
    

    // const handleRemoveOption = async (id, type) => {
    //     try {
    //         if (type === 'option_rows') {
    //             await cartItemRowDelete(id);
    //         } else if (type === 'extra_options') {
    //             await cartItemExtraDelete(id);
    //         }
    
    //         // 성공적으로 삭제되었다면, 해당 아이템을 상태에서 제거합니다.
    //         setSelectedCartItem((prevSelectedItem) => ({
    //             ...prevSelectedItem,
    //             option_rows: prevSelectedItem.option_rows.filter((option) => option.id !== id),
    //             extra_options: prevSelectedItem.extra_options.filter((extraOption) => extraOption.id !== id),
    //         }));
    //     } catch (error) {
    //         // 삭제 중 에러 처리를 수행합니다.
    //     }
    // };

    const createPatchData = () => {
        const cartitem_option_rows = selectedCartItem.option_rows.map((option) => ({
          id: option.id,
          quantity: option.quantity,
        }));
    
        const cartitem_extra_options = selectedCartItem.extra_options.map((extraOption) => ({
          id: extraOption.id,
          quantity: extraOption.quantity,
        }));
    
        return {
          cartitem_option_rows,
          cartitem_extra_options,
        };
    };

      
    const confirm = () => {
        const cartItemId = selectedCartItem.cart_item_id
        const data = createPatchData();

        patchQuantity(cartItemId,data)
        .then((response) => {
            if (response) {
                const updatedCartItem = response;
                setCartDeliveryItems(updatedCartItem.cart_delivery_items);
            }
            setIsOpen(false);
        })
        .catch(error => {
            console.log("등록 실패:", error.message);
        });
    };


    return (
        <>
         <div className="modal ">
            <div className="modal-content ExtraOptionModal ">
                <span className="close" onClick={closeModal}>&times;</span>
                
                <p className='ModifiedExtra-tit'>주문수정</p>
                
                {selectedCartItem.option_rows.map((option, optionIndex) => (
                    <>
                    <div className='prd-add'>
                        <div className='prd-amount-txt'>
                            <span className='prd-name'>{option.name}</span>
                            {/* <span className='prd-remove' onClick={() =>  handleRemoveOption(option.id, 'option_rows')}>x</span> */}
                        </div>
                        <div className='prd-amount-wrap'>
                            <div className='amount-box'>
                                <div className='amount'>
                                    <a className='del_item_quantity cursor amount-down'
                                       onClick={() => handleQuantityChange(option.id, -1, 'option_rows')}>-</a>
                                    <input type="text" value={option.quantity} />
                                    <a className='add_item_quantity cursor amount-up'
                                        onClick={() => handleQuantityChange(option.id, 1, 'option_rows')}>+</a>
                                </div>
                            </div>

                            <p className='fs-6 fw-bold'>{parseInt(option.price*option.quantity).toLocaleString()}원</p>
                        </div>

                    </div>
                    </>

                ))}

                

                {selectedCartItem.extra_options.map((extraOption, optionIndex) => (
                    <>
                        <div className='prd-add'>
                            <div className='prd-amount-txt'>
                                <span className='prd-name'>{extraOption.name}</span>
                                {/* <span className='prd-remove' onClick={() =>handleRemoveOption(extraOption.id, 'extra_options')}>x</span> */}
                            </div>
                            <div className='prd-amount-wrap'>
                                <div className='amount-box'>
                                    <div className='amount'>
                                        <a className='del_item_quantity cursor amount-down'
                                             onClick={() => handleQuantityChange(extraOption.id, -1, 'extra_options')}>-</a>
                                        <input type="text" value={extraOption.quantity} />
                                        <a className='add_item_quantity cursor amount-up'
                                             onClick={() => handleQuantityChange(extraOption.id, 1, 'extra_options')}>+</a>
                                    </div>
                                </div>

                                <p className='fs-6 fw-bold'>{parseInt(extraOption.price*extraOption.quantity).toLocaleString()}원</p>
                            </div>
                        </div>
                    </>

                ))}

            <div className='m_btn_area'>
                <button onClick={closeModal}>취소</button>
                <button className='btn_confirm'
                        onClick={confirm}>확인</button>
            </div>
            </div>
           
        </div>
        </>
    )
}

export default ModifiedModal ;