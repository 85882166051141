import axios from 'axios';
import { useQuery ,  prefetchQuery } from 'react-query';
import React, { useState } from 'react';

function AutoApprove()
{
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>자동승인설정</h2>
            </div>
            <section className='McontentBox'>
                <p className='settingSubTitle'>자동승인 사용 여부</p>
            
                <div className='radio-box'>
                    <input type='radio' id='g1' name='group1' value='all' />
                    <label for='g1'>사용함</label>
                    <input type='radio' id='g2' name='group1' value='m' />
                    <label for='g2'>사용안함</label>
                </div>
            </section>
            <section className='McontentBox'>
                <p className='settingSubTitle'>자동승인 시간 설정</p>
            
                <div className='select-box'>
                    <select className='mSelectBox'>
                        <option value="" selected="selected">00시</option>
                        <option value="">00시</option>
                        <option value="">01시</option>
                        <option value="">02시</option>
                        <option value="">03시</option>
                        <option value="">04시</option>
                        <option value="">05시</option>
                        <option value="">06시</option>
                        <option value="">07시</option>
                        <option value="">08시</option>
                        <option value="">09시</option>
                        <option value="">10시</option>
                        <option value="">11시</option>
                        <option value="">12시</option>
                        <option value="">13시</option>
                        <option value="">14시</option>
                        <option value="">15시</option>
                        <option value="">16시</option>
                        <option value="">17시</option>
                        <option value="">18시</option>
                        <option value="">19시</option>
                        <option value="">20시</option>
                        <option value="">21시</option>
                        <option value="">22시</option>
                        <option value="">23시</option>
                    </select>
                    <select className='mSelectBox'>
                        <option value="" selected="selected">00분</option>
                        <option value="">10분</option>
                        <option value="">20분</option>
                        <option value="">30분</option>
                        <option value="">40분</option>
                        <option value="">50분</option>
                    </select>
                    <span>~</span>
                    <select className='mSelectBox'>
                        <option value="" selected="selected">00시</option>
                        <option value="">00시</option>
                        <option value="">01시</option>
                        <option value="">02시</option>
                        <option value="">03시</option>
                        <option value="">04시</option>
                        <option value="">05시</option>
                        <option value="">06시</option>
                        <option value="">07시</option>
                        <option value="">08시</option>
                        <option value="">09시</option>
                        <option value="">10시</option>
                        <option value="">11시</option>
                        <option value="">12시</option>
                        <option value="">13시</option>
                        <option value="">14시</option>
                        <option value="">15시</option>
                        <option value="">16시</option>
                        <option value="">17시</option>
                        <option value="">18시</option>
                        <option value="">19시</option>
                        <option value="">20시</option>
                        <option value="">21시</option>
                        <option value="">22시</option>
                        <option value="">23시</option>
                    </select>
                    <select className='mSelectBox'>
                        <option value="" selected="selected">00분</option>
                        <option value="">10분</option>
                        <option value="">20분</option>
                        <option value="">30분</option>
                        <option value="">40분</option>
                        <option value="">50분</option>
                    </select>
                </div>
            </section>
            <div className='setting-btn-box'>
                <button className='m-btn m-btn-search' onClick={()=>alert('설정이 저장되었습니다.')}>설정 저장</button>
            </div>
        </div>
                   
        </>
    )
}

export default AutoApprove