/***************************************************************************************
* FileName      : History.js
* Description   : 해와관리자 > 알림 > 발송내역 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useNotificationMailHistoryQuery , handleNotificationMailHistorySearch } from '../../../../api/hwadmin/notificationApi';
import Pagination from '../../common/Pagination';
import SearchPagination from '../../common/SearchPagination';
import { MailRow } from './MailRow';

function History()
{
    const [pageSize, setPageSize] = useState(50); 
    const { data, error, isFetching, page, setPage } = useNotificationMailHistoryQuery(pageSize);

    const [searchMail, setSearchMail] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
    
    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    
    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const onSearch = () => {
        handleNotificationMailHistorySearch({
            searchMail, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
        });
        
        setSearchConfirm(true);
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };


    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/admin/alarm/mailsendinghistory';
    };
    
    return (
        <>
          {/* 검색 */}
          <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6'>
                                <option value="all" selected="selected">전체</option>
                                <option value="receiver__username">회원ID</option>
                                <option value="content">내용</option>
                                <option value="receiver__email">메일</option>
                            </select>
                            <input type="text"
                              /> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select>
                                <option value="">발송일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch} >검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <select className='mSelectBox' value={pageSize} onChange={handlePageSizeChange}>
                                <option value="50">50개씩</option>
                                <option value="100">100개씩</option>
                            </select>
                        </div>
                    }

                </div>


                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>내용</th>
                                <th>수신메일</th>
                                <th>발송일시</th>
                            </tr>
                        </thead>
                        <tbody>
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((mail) => (
                                    <MailRow
                                        key={mail?.id}
                                        talk={mail}
                                    />
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((mail) => (
                                    <MailRow
                                        key={mail?.id}
                                        talk={mail}
                                    />
                                ))
                            ) : null}    
                        </tbody>
                    </table>
                </div>
            </section>

            
            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                        
                ) : (
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>

   
    </>
    )
}

export default History