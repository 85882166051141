
/***************************************************************************************
* FileName      : CancelStatusView.js
* Description   : 셀러관리자 > 주문배송 > 반품/취소 > 반품,취소 전체 리스트 조회
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';

import { useOrderAbortListQuery , handleOrderAbortSearch } from '../../../../api/selleradmin/orderAPI';
import { OrderRow } from "./CancelStatusView/OrderRow";
import Pagination from '../../common/Pagination'

import SearchPagination from '../../../admin/common/SearchPagination';
import SortComponent from '../../../admin/common/SortComponent2';
import PageSizeSelect from '../../common/PageSizeSelect';

function CancelStatusView({ selectedStatus })
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 
    const [searchUrl, setSearchUrl] = useState('');

    const { data, error, isFetching, page, setPage } =  useOrderAbortListQuery(ordering,pageSize);
   
 


    console.log(data);
    console.log("--전체--")
    
    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('reception_date__range');
    
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    // 반품/취소 상태
    const [status , setStatus] = useState('전체');
    const statusOptions = [
        { id: 'all', label: '전체', value: '전체' },
        { id: 'Incomplete', label: '미완료', value: '미완료' },
        { id: 'Processed', label: '처리완료', value: '처리완료' }
    ];

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
        

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    
    const handleResetClick = () => {
        window.location.href = '/selleradmin/orderDelivery/returnExchangeCancel'; 
    };

    const onSearch = () => {
       
        handleOrderAbortSearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate, 
            selectedEndDate,
            selectedStatus,
            status
        });
        
        setSearchConfirm(true);

    };




 
    // 반품/취소 상태 검색
    const handleStatusChange = (e) => {
        setStatus(e.target.value); 
    };
    return (
        <>
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field word'>
                    <div> 
                        <b>검색어</b> 
                    </div>
                    <div>
                    <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                            <option value="all" selected="selected">전체</option>
                            <option value="OrderNumber">주문번호</option>
                            <option value="ProductName">상품명</option>
                            <option value="ProductNumber">상품번호</option>
                    </select>

                    <input type="text"
                            value={searchOrder}
                            onChange={(e) => setSearchOrder(e.target.value)}/> 

                    </div>   
                </div>
                <div className='search-field date'>
                    <div> 
                        <b>날짜</b> 
                    </div>
                    <div>
                        <select value={selectedDateType} onChange={handleDateTypeChange}>
                            <option value="reception_date__range">접수일</option>
                            <option value="return_request_at__range">회수요청일</option>
                            <option value="return_complete_at__range">회수완료일</option>
                            <option value="completed_at__range">처리완료일</option>
                        </select>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                    </div>   
                </div>
                
                <div className='search-field word'>
                    <div> 
                        <b>반품/취소상태</b> 
                    </div>
                    <div className='SearchRadio'>
                        {statusOptions.map(option => (
                            <React.Fragment key={option.id}>
                            <input
                                type='radio'
                                id={option.id}
                                name='status'
                                value={option.value}
                                checked={status === option.value}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor={option.id}>{option.label}</label>
                            </React.Fragment>
                        ))}
                    </div>
                </div>

            </div>
        
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}} >검색</button>
            </div>
        </section>    


        <section className='McontentBox'>
            <div className='subTitle'>
                <span className='resultsCount-wrap'>
                    <span>검색결과</span>
                    {searchResults.totalCnt > 0 ? (
                        <>
                            <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                        </>
                        ) : (
                        <>
                            <p>(총 <b className='sum-s-b'>{data?.totalCnt && data?.totalCnt}</b>개)</p>
                        </>
                    )}
                </span>
                <div className='selectWrap'>
                    <SortComponent
                        ordering={ordering}
                        handleOrderingChange={handleOrderingChange}
                        newLabel="신규주문순"
                        oldLabel="오래된주문순"
                        />
 
                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
            
                </div>
            </div>
                
            <div className='m-btn-wrap-sm'>
                <button className='m-sm-btn'>이메일 발송</button>
                <button className='m-sm-btn'>SMS 방송</button>
                <button className='m-sm-btn'>다운로드</button>
            </div>

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>주문번호</th>
                            <th>요청구분</th>
                            <th>요청상태</th>
                            <th>
                                구매자 ID<br/>
                                구매자 이름
                            </th>
                            <th>
                                수취인 이름<br/>
                                수취인 연락처
                            </th>
                            <th>접수일시</th>
                            <th>접수번호</th>
                            <th>
                                상품번호<br/>
                                상품명
                            </th>
                            <th>옵션정보</th>
                            <th>반품/교환/취소 사유</th>
                            <th>배송비 부담자</th>
                            <th>주문금액</th>
                            <th>택배사</th>
                            <th>송장번호</th>
                            <th>에스크로 거래 여부</th>
                            <th>회수요청시간</th>
                            <th>회수완료시간</th>
                            <th>거절사유</th>
                            <th>처리담당자</th>
                            <th>처리완료일시</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchConfirm && searchResults.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ): searchResults.count > 0 ? (
                            searchResults?.data.map((order, index ) => (
                                <OrderRow key={order.id} order={order} index={index} />
                            ))
                        ) : data && data.data && data.data.length > 0 ? (
                            data.data.map((order, index ) => (
                                <OrderRow key={order.id} order={order} index={index} />
                            ))
                        ) : null}
                    </tbody>                    
                    </table>
                </div>
            </section>
                
              

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
    
    </>
    )
}

export default CancelStatusView