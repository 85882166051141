import { Modal } from 'react-bootstrap';
import {Row, Col, Button ,Table } from 'react-bootstrap';

function InfoNoticeModal({show, setShow, productDetails}){
    return(
      <>
      <Modal
          size="lg"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
      >
          <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            상품정보제공고시 ({productDetails?.info_type})
          </Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <table className='order-detail-table table'>
                <ProductInfo productDetails={productDetails} />
            </table>

          </Modal.Body>
      </Modal>
    </>
    )
}


function ProductInfo({productDetails}){

    if(productDetails?.info_type === '농축수산물'){
        return(
            <Table className="order-detail-table">
                <tbody className="fs-7">
                    <tr>
                        <td colSpan={1}>품목 또는 명칭</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.name ? productDetails?.aquatic_info?.name : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>포장단위별 용량(중량), 수량, 크기</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.volume ? productDetails?.aquatic_info?.volume : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>제조연월일, 소비기한 또는 품질유지기한</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.manufactured_date ? productDetails?.aquatic_info?.manufactured_date : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>원산지</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.origin ? productDetails?.aquatic_info?.origin : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>생산자</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.manufacturer ? productDetails?.aquatic_info?.manufacturer : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>유전자변형수산물</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.is_gmo ? productDetails?.aquatic_info?.is_gmo : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>수입식품 문구 여부</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.imported_food_text ? productDetails?.aquatic_info?.imported_food_text : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>상품구성</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.package ? productDetails?.aquatic_info?.package : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>보관방법 또는 취급방법</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.storage_method ? productDetails?.aquatic_info?.storage_method : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>소비자 안전을 위한 주의사항</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.caution ? productDetails?.aquatic_info?.caution : "상품 상세페이지 참조"} 
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>소비자 상담 관련 전화번호</td>
                        <td colSpan={3}>
                            {productDetails?.aquatic_info?.cs ? productDetails?.aquatic_info?.cs : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }else{
        return(
            <Table className="order-detail-table">
                <tbody className="fs-7">
                    <tr>
                        <td colSpan={1}>품목 또는 명칭</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.name ? productDetails?.processed_food_info?.name : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>식품의 유형</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.foodtype ? productDetails?.processed_food_info?.foodtype : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>생산자 및 소재지</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.manufacturer ? productDetails?.processed_food_info?.manufacturer : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>제조연월일, 소비기한 또는 품질유지기한</td>
                        <td colSpan={3}>{productDetails?.processed_food_info?.manufactured_date ? productDetails?.processed_food_info?.manufactured_date : "상품 상세페이지 참조"}</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>용량, 수량, 크기</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.volume ? productDetails?.processed_food_info?.volume : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>원재료명</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.material ? productDetails?.processed_food_info?.material : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>영양성분</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.nutrition ? productDetails?.processed_food_info?.nutrition : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>유전자 변형 수산물</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.is_gmo ? productDetails?.processed_food_info?.is_gmo : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>식소비자 안전을 위한 주의사항</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.caution ? productDetails?.processed_food_info?.caution : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>수입식품 여부에 따라 수입신고</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.imported ? productDetails?.processed_food_info?.imported : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>소비자 상담 관련 전화번호</td>
                        <td colSpan={3}>
                            {productDetails?.processed_food_info?.cs ? productDetails?.processed_food_info?.cs : "상품 상세페이지 참조"}
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }
}


export default InfoNoticeModal