import React from 'react';

const AgeCheckboxGroup = ({ checkedState, onChange }) => (
    <div className="radio-wrap ml-2">
        {Object.keys(checkedState).map((age) => (
            <label key={age}>
                <input
                    type="checkbox"
                    name={age}
                    checked={checkedState[age]}
                    onChange={onChange}
                />
                {age}대
            </label>
        ))}
    </div>
);

export default AgeCheckboxGroup