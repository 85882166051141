import './css/reset.css';
import './css/style.css';
import './css/App.css';
import './css/Mstyle.css';
import './css/Spinner.css';
import './css/responsive.css';
import './css/editor.css';

import NotFound from './routes/NotFound.js';
import UserLayout from './UserLayout.js'
import ManagerLayout from './ManagerLayout.js'
import SellerManagerLayout from './SellerManagerLayout.js'

import Home from './routes/Home.js'
import Login from './routes/member/Login.js'
import Join from './routes/member/Join.js'
import Join2 from './routes/member/Join2.js'
import Register from './routes/member/Register.js'
import Agreement from './routes/member/Agreement.js'
import Cart from './routes/member/Cart.js'
import Find from './routes/member/Find.js'
import FindPasswd from './routes/member/FindPasswd.js'
import VerifyEmail from './routes/member/Find/VerifyEmail.js'
import VerifyEmailPw from './routes/member/Find/VerifyEmailPw.js'

import Detail from './routes/product/Detail.js'
import Detail2 from './routes/product/Detail2.js'
import ServicesSearch from './routes/product/SearchResults.js'
import List from './routes/product/List.js'
import Vendors from './routes/product/Vendors.js'
import Seasonals from './routes/product/Seasonals.js'
import MokpoBest from './routes/product/MokpoBest.js'
import GiftSet from './routes/product/GiftSet.js'
import VendorProducts from './routes/product/VendorProducts.js'

import Order from './routes/order/Order.js'
import SuccessPg from './routes/order/Success.js'
import FailPg from './routes/order/Fail.js'

import MobileMypage from './routes/mypage/MobileMypage.js'
import Orderinquiry from './routes/mypage/Orderinquiry.js'
import MyOrderDetail from './routes/mypage/Orderinquiry/OrderDetail.js'
import ProductReturn from './routes/mypage/ProductReturn.js'
import ProductItemCancel from './routes/mypage/ProductItemCancel.js'
import ProductCancel from './routes/mypage/ProductCancel.js'

import CancelList from './routes/mypage/MyorderList_cancel';
import ReturnList from './routes/mypage/MyorderList_return.js'
import MyOrderCancelDetail from './routes/mypage/MyorderList_cancel/OrderDetail.js'
import MyOrderReturnDetail from './routes/mypage/MyorderList_return/OrderDetail.js'

import MypageCouponList from './routes/mypage/Coupon.js';
import Mileage from './routes/mypage/Mileage';
import Review from './routes/mypage/Review';
import Reviewable from './routes/mypage/Reviewable.js'

import MyInquiry from './routes/mypage/MyInquiry.js';
import MyOnetoOne from './routes/mypage/MyOnetoOne.js';
import OtoWrite from './routes/mypage/MyOnetoOne/Write.js';

import WishList from './routes/mypage/WishList';
import VendorWishList from './routes/mypage/VendorWishList.js'
import MyInfo from './routes/mypage/MyInfo';
import Address from './routes/mypage/Address';


import NoticeList from './routes/customer/NoticeList.js'
import NoticeView from './routes/customer/Notice/View.js'
import Faq from './routes/customer/Faq.js'
import Qa from './routes/customer/Qa.js'
import QView from './routes/customer/Qa/QView.js'
import QAnswer from './routes/customer/Qa/QAnswer.js';
import QnAWrite from './routes/customer/Qa/Write.js'
import QnASearchBoardList from './routes/customer/Qa/SearchBoardList.js'



import Oto from './routes/customer/Onetoone.js'
import OtoView from './routes/customer/Onetoone/OtoView.js';


import Term from './routes/customer/Term.js'
import Conditions from './routes/customer/Conditions.js'
import Refunds from './routes/customer/Refunds.js'
import AdminDashboard from './routes/admin/AdminDashboard.js'
import AdminLogin from './routes/admin/AdminLogin.js'
import MemberManagement from './routes/admin/member/Management.js'
import Editortest from './routes/admin/member/Editortest.js'

import StoreNewRequest from './routes/admin/store/NewRequest.js'
import StoreInquiry from './routes/admin/store/StoreInquiry.js'
import StoreRegistration from './routes/admin/store/Registration.js'
import VendorHistory from './routes/admin/store/VendorHistory.js'
import StoreEdit from './routes/admin/store/Edit.js'

import ProductNewRequest from './routes/admin/product/NewRequest.js'
import ProductCheck from './routes/admin/product/Check.js'
import ProductRegister from './routes/admin/product/Register.js'
import ProductEdit from './routes/admin/product/ProductEdit.js'
import ProductCopy from './routes/admin/product/ProductCopy.js'


import ProductActivityRecord from './routes/admin/product/ActivityRecord.js'
import CategoryManagement from './routes/admin/product/CategoryManagement.js'
import OrderInquiry from './routes/admin/order/Inquire.js'
import OrderDetail from './routes/admin/order/OrderDetail.js'

import DeliveryStatus from './routes/admin/order/DeliveryStatus.js'
import PurchaseConfirmation from './routes/admin/order/PurchaseConfirmation.js'
import CheckOutstanding from './routes/admin/order/CheckOutstanding.js'
import OrderReturnAll from './routes/admin/order/return_exchange/All.js'

import OrderCancelStatus from './routes/admin/order/Cancel.js'

import CustomerCenter from './routes/admin/customer/Center.js'
import ProductInquiryList from './routes/admin/customer/ProductInquiryList.js'
import NoticeBoard from './routes/admin/board/NoticeBoard.js'
import CreateNotice from './routes/admin/board/NoticeBoard/CreateNotice.js'
import EditNotice from './routes/admin/board/NoticeBoard/EditNotice.js'

import AdminFaq from './routes/admin/board/Faq.js'
import CreateFaq from './routes/admin/board/Faq/CreateFaq.js'
import EditFaq from './routes/admin/board/Faq/EditFaq.js';

import ReviewList from './routes/admin/board/ReviewBoard.js'

import Settlement from './routes/admin/settle/Settlement.js'
import Payment from './routes/admin/settle/Payment.js'
import UnpaidHistory from './routes/admin/settle/UnpaidHistory.js';
import SalesHistory from './routes/admin/settle/SalesHistory.js'
import TaxHistory from './routes/admin/settle/TaxHistory.js'
import InvoiceHistory from './routes/admin/settle/InvoiceHistory.js'

import BannerCard from './routes/admin/marketing/BannerCard.js'
import CouponList from './routes/admin/marketing/CouponList.js'
import MileageHistory from './routes/admin/marketing/MileageHistory.js'
import SeasonalProduct from './routes/admin/marketing/SeasonalProduct.js'

import MailSendingHistory from './routes/admin/alarm/MailSendingHistory.js'
import AlimTalkSendingHistory from './routes/admin/alarm/AlimTalkSendingHistory.js'
import PushNotificationHistory from './routes/admin/alarm/PushNotificationHistory.js'

import AccordionMenuTest from './routes/admin/test/AccordionMenuTest.js'

import SummaryStats from './routes/admin/statistics/SummaryStats.js'
import VisitStats from './routes/admin/statistics/VisitStats.js'
import SignupStats from './routes/admin/statistics/SignupStats.js'
import ProduceStats from './routes/admin/statistics/ProduceStats.js';
import SalesStats from './routes/admin/statistics/SalesStats.js'
import ProductStats from './routes/admin/statistics/ProductStats.js'
import SearchStats from './routes/admin/statistics/SearchStats.js'

import AdminSettings from './routes/admin/setting/AdminSettings.js'
import AutoApprove from './routes/admin/setting/AutoApprove.js'
import PolicySettings from './routes/admin/setting/PolicySettings.js'
import DeliveryCalendar from './routes/admin/setting/DeliveryCalendar.js'

import ChartTest from './routes/admin/test/Chart.js'
import { ReactQueryDevtools } from 'react-query/devtools';

import { Routes, Route, Link , useNavigate, Outlet} from 'react-router-dom'

// 셀러관리자 페이지 > 로그인페이지
import SellerAdminLogin from './routes/selleradmin/SellerAdminLogin.js'
import SellerAdminRegist from './routes/selleradmin/register/Registration.js'
import SellerAdminWaiting from './routes/selleradmin/register/Waiting.js'


// 셀러관리자 페이지 > 메인페이지
import SellerAdminDashboard from './routes/selleradmin/AdminDashboard.js'

// 셀러관리자 페이지 > 상품관리 > 
import SAProductList from './routes/selleradmin/product/ProductsList.js'
import SAProductRegistration from './routes/selleradmin/product/Register.js'
import SARequestList from './routes/selleradmin/product/RequestList.js'
import SAProductEdit from './routes/selleradmin/product/ProductEdit.js'
import SAProductCopy from './routes/selleradmin/product/ProductCopy.js'


// 셀러관리자 페이지 > 주문배송 
import SAOrderList from './routes/selleradmin/orderDelivery/OrderList.js'
import SADeliveryList from './routes/selleradmin/orderDelivery/DeliveryList.js'
import SOrderDetail from './routes/selleradmin/orderDelivery/OrderDetail.js'
import SAPurchaseList from './routes/selleradmin/orderDelivery/PurchaseList.js'
import SAReturnExchangeCancel from './routes/selleradmin/orderDelivery/ReturnExchangeCancel.js'

// 셀러관리자 페이지 > 고객관리
import SAProductInquiryList from './routes/selleradmin/customerManagement/ProductInquiryList.js'
import SAProductReviewList from './routes/selleradmin/customerManagement/ProductReviewList.js'

// 셀러관리자 페이지 > 정산
import SASettlementStatus from './routes/selleradmin/settlement/SettlementStatus.js'
import SASalesHistory from './routes/selleradmin/settlement/SalesHistory.js'
import SATaxHistory from './routes/selleradmin/settlement/TaxHistory.js'
import SAInvoiceHistory from './routes/selleradmin/settlement/InvoiceHistory.js'
import SAUnpaidHistory from './routes/selleradmin/settlement/UnpaidHistory.js';

// 셀러관리자 페이지 > 판매통계 
import SASalesStatistics from './routes/selleradmin/salesStatistics/SalesStatistics.js'

// 셀러관리자 페이지 > 판매자 정보 
import SADeliveryCalendar from './routes/selleradmin/sellerInfo/DeliveryCalendar.js'
import SAEditProfile from './routes/selleradmin/sellerInfo/EditProfile.js'
import EditVendorInfo from './routes/selleradmin/sellerInfo/EditVendorInfo.js'
import VendorInfo from './routes/selleradmin/sellerInfo/VendorInfo.js'
import EmployeeManagement from './routes/selleradmin/sellerInfo/EmployeeManagement.js'


// pkh : 테스트페이지 (삭제예정)
import PTest from './routes/product/Test.js'
function App()
{

  return (
   <>
   <Routes>
      {/* 사용자(고객) 페이지 */}
      <Route path="/" element={<Home />} />


      <Route path="/member" element={<UserLayout />}>
        <Route path="login" element={<Login/>}/>     
        <Route path="Join" element={<Join/>}/>
        <Route path="Join2" element={<Join2/>}/>
        <Route path="agreement" element={<Agreement/>}/>     
        <Route path="Register" element={<Register/>}/>     
        <Route path="Cart" element={<Cart/>}/>     
        <Route path="Find" element={<Find/>}/>     
        <Route path="FindPasswd" element={<FindPasswd/>}/>     
        <Route path="verify-email-username" element={<VerifyEmail/>}/>     
        <Route path="verify-email-password" element={<VerifyEmailPw/>}/>     
        <Route path="*" element={<NotFound />} />
        
      </Route>

      <Route path="/product" element={<UserLayout />}>
        <Route path="list" element={<List/> } />
        <Route path="vendors" element={<Vendors/> } />
        <Route path="seasonals" element={<Seasonals/> } />
        <Route path="mokpobest" element={<MokpoBest/> } />
        <Route path="giftset" element={<GiftSet/> } />
        <Route path="vendor/items" element={<VendorProducts/> } />
        {/* <Route path="detail" element={<Detail/> } /> */}
        <Route path="detail" element={<Detail2/> } />
        <Route path="detail/:id" element={<Detail2 />} />
        {/* <Route path="detail/:id" element={<Detail2 />} /> */}
        <Route path="search/" element={<ServicesSearch />} />
      </Route>

      <Route path="/mypage" element={<UserLayout/>}>
        <Route path="mobile" element={<MobileMypage/> } />
        <Route path="order" element={<Orderinquiry />} />
        <Route path="order/:id" element={<MyOrderDetail />} />

        <Route path="order/:id/return" element={<ProductReturn />} />
        <Route path="order/:orderId/itemcancel/:status" element={<ProductItemCancel />} />

        <Route path="order/:orderNum/cancel/:orderId" element={<ProductCancel />} />

        <Route path="cancel" element={<CancelList />} />
        <Route path="return" element={<ReturnList />} />

        <Route path="cancel/:id" element={<MyOrderCancelDetail />} />
        <Route path="return/:id" element={<MyOrderReturnDetail />} />


        <Route path="coupon" element={<MypageCouponList />} />
        <Route path="mileage" element={<Mileage />} />
        <Route path="review" element={<Review />} />
        <Route path="reviewable" element={<Reviewable />} />


        <Route path="qnaList" element={<MyInquiry />} />
        <Route path="otoList" element={<MyOnetoOne />} />
        <Route path="oto/write/" element={<OtoWrite/>}/>
 

        <Route path="wish" element={<WishList />} />
        <Route path="vendorWish" element={<VendorWishList />} />

        <Route path="info" element={<MyInfo />} />
        <Route path="address" element={<Address />} />
      </Route>

      <Route path="/customer" element={<UserLayout />}>
        <Route path="notice" element={<NoticeList/> } />
        <Route path="notice/:id" element={<NoticeView />} />
        <Route path="faq" element={<Faq/> } />
        <Route path="qna" element={<Qa/> } />
        <Route path="qna/:id" element={<QView/> } />
        <Route path="qna/:id/answer" element={<QAnswer/> } />
        <Route path="qna/write/:id" element={<QnAWrite/>}/>
       
        <Route path="oto" element={<Oto/> } />
        <Route path="oto/:id" element={<OtoView/> } />
 

        <Route path="term" element={<Term/> } />
        <Route path="conditions" element={<Conditions/> } />
        <Route path="refunds" element={<Refunds/> } />
      </Route>

      <Route path="/customer/qna/QnASearchBoardList" element={<QnASearchBoardList/>}/>

      <Route path="/order" element={<UserLayout />}>
        <Route path="order" element={<Order/>}/>
        <Route path="success" element={<SuccessPg/>}/>
        <Route path="fail" element={<FailPg/>}/>
      </Route>

      {/* 관리자 페이지 */} 
      <Route path="/admin" element={<ManagerLayout/>}>
        <Route path="main" element={<AdminDashboard/> } />
      </Route>

      {/* 관리자 로그인 페이지 */}
      <Route path="/admin" >
        <Route path="login" element={<AdminLogin/> } />
      </Route>

      {/* 관리자 페이지 - 회원관리  */}
        <Route path="/admin/member" element={<ManagerLayout/>}>
          <Route path="management" element={<MemberManagement/> } />
          <Route path="Editortest" element={<Editortest/> } />
        </Route>

      {/* 관리자 페이지 - 매장관리  */}
      <Route path="/admin/store" element={<ManagerLayout/>}>
        <Route path="request" element={<StoreNewRequest/> } />
        <Route path="inquiry" element={<StoreInquiry/> } />
        <Route path="registration" element={<StoreRegistration/> } />
        <Route path="edit/:vendorId" element={<StoreEdit/> } />
        <Route path="history" element={<VendorHistory/> } />
      </Route>
      
      {/* 관리자 페이지 - 상품관리  */}
      <Route path="/admin/product" element={<ManagerLayout/>}>
        <Route path="request" element={<ProductNewRequest/> } />
        <Route path="check" element={<ProductCheck/> } />
        <Route path="register" element={<ProductRegister/> } />
        <Route path="record" element={<ProductActivityRecord/> } />
        <Route path="category" element={<CategoryManagement/> } />
        <Route path="edit/:productId" element={<ProductEdit/>}/>
        <Route path="copy/:productId" element={<ProductCopy/>}/>
      </Route>

      {/* 관리자 페이지 - 주문배송  */}
      <Route path="/admin/order" element={<ManagerLayout/>}>
        <Route path="outstanding" element={<CheckOutstanding/> } />
        <Route path="inquire" element={<OrderInquiry/> } />
        <Route path="inquire/:orderNumber/:orderId" element={<OrderDetail />} />
        <Route path="deliverystatus" element={<DeliveryStatus/> } />
        <Route path="purchaseconfirmation" element={<PurchaseConfirmation/> } />
        <Route path="cancel" element={<OrderCancelStatus/> } />
      </Route>

      {/* 관리자 페이지 - 고객관리  */}
      <Route path="/admin/customer" element={<ManagerLayout/>}>
        <Route path="center" element={<CustomerCenter/> } />
        <Route path="productInquiry" element={<ProductInquiryList/> } />
      </Route>

      
      {/* 관리자 페이지 - 고객관리  */}
      {/* <Route path="/customer" element={<ManagerLayout/>}>
        <Route path="center" element={<CustomerCenter/> } />
        <Route path="productInquiry" element={<ProductInquiryList/> } />
      </Route> */}

      {/* 관리자 페이지 - 게시판  */}
      <Route path="/admin/board" element={<ManagerLayout/>}>
        <Route path="notice" element={<NoticeBoard/> } />
        <Route path="createNotice" element={<CreateNotice/> } />
        <Route path="editNotice/:id" element={<EditNotice/> } />
        
        <Route path="faq" element={<AdminFaq/> } />
        <Route path="createFaq" element={<CreateFaq/> } />
        <Route path="editFaq/:id" element={<EditFaq/> } />

        <Route path="review" element={<ReviewList/> } />
      </Route>

      {/* 관리자 페이지 - 정산  */}
      <Route path="/admin/settle" element={<ManagerLayout/>}>
        <Route path="settlement" element={<Settlement/> } />
        <Route path="payment" element={<Payment/> } />
        <Route path="unpaid" element={<UnpaidHistory/> } />

        <Route path="saleshistory" element={<SalesHistory/> } />
        <Route path="taxhistory" element={<TaxHistory/> } />
        <Route path="invoicehistory" element={<InvoiceHistory/> } />
      </Route>

      {/* 관리자 페이지 - 마케팅  */}
      <Route path="/admin/marketing" element={<ManagerLayout/>}>
        <Route path="bannercard" element={<BannerCard/> } />
        <Route path="couponlist" element={<CouponList/> } />
        <Route path="mileagehistory" element={<MileageHistory/> } />
        <Route path="seasonalproduct" element={<SeasonalProduct/> } />
      </Route>


      {/* 관리자 페이지 - 알람  */}
      <Route path="/admin/alarm" element={<ManagerLayout/>}>
        <Route path="mailsendinghistory" element={<MailSendingHistory/> } />
        <Route path="alimTalkSendingHistory" element={<AlimTalkSendingHistory/> } />
        <Route path="pushNotificationHistory" element={<PushNotificationHistory/> } />
      </Route>

    {/* 관리자 페이지 - 통계  */}
    <Route path="/admin/statistics" element={<ManagerLayout/>}>
      <Route path="summaryStats" element={<SummaryStats/> } />
      <Route path="visitStats" element={<VisitStats/> } />
      <Route path="signupStats" element={<SignupStats/> } />
      <Route path="produceStats" element={<ProduceStats/> } />  
      <Route path="salesStats" element={<SalesStats/> } />
      <Route path="productStats" element={<ProductStats/> } />
      <Route path="searchStats" element={<SearchStats/> } />
    </Route>


    {/* 관리자 페이지 - 테스트  */}
    <Route path="/test">
      <Route path="atest" element={<AccordionMenuTest/> } />
      <Route path="charttest" element={<ChartTest/> } /> 

    </Route>

    {/* 관리자 페이지 - 설정  */}
    <Route path="/admin/setting" element={<ManagerLayout/>}>
      <Route path="adminSettings" element={<AdminSettings/> } />
      <Route path="autoApprove" element={<AutoApprove/> } />
      <Route path="policySettings" element={<PolicySettings/> } />
      <Route path="deliveryCalendar" element={<DeliveryCalendar/> } />
    </Route>


    {/* 샐러 관리자 페이지 */} 
    <Route path="/selleradmin" element={<SellerManagerLayout/>}>
        <Route path="main" element={<SellerAdminDashboard/> } />
    </Route>

    {/* 샐러 로그인 페이지 */}
    <Route path="/selleradmin" >
      <Route path="login" element={<SellerAdminLogin/> } />
      <Route path="regist" element={<SellerAdminRegist/> } />
      <Route path="wait" element={<SellerAdminWaiting/> } />
    </Route>


    {/* 샐러 관리자 페이지 - 상품관리  */}
    <Route path="/selleradmin/product" element={<SellerManagerLayout/>}>
        <Route path="list" element={<SAProductList/> } />
        <Route path="register" element={<SAProductRegistration/> } />
        <Route path="requestList" element={<SARequestList/> } />
        <Route path="edit/:productId" element={<SAProductEdit/> } />
        <Route path="copy/:productId" element={<SAProductCopy/> } />
    </Route>

    {/* 샐러 관리자 페이지 - 주문배송  */}
    <Route path="/selleradmin/orderDelivery" element={<SellerManagerLayout/>}>
        <Route path="list" element={<SAOrderList/> } />
        <Route path="list/:orderNumber/:orderId" element={<SOrderDetail />} />
        <Route path="deliveryList" element={<SADeliveryList/> } />
        <Route path="purchaseList" element={<SAPurchaseList/> } />
        <Route path="returnExchangeCancel" element={<SAReturnExchangeCancel/> } />
    </Route>

    {/* 샐러 관리자 페이지 - 고객관리  */}
    <Route path="/selleradmin/customerManagement" element={<SellerManagerLayout/>}>
        <Route path="productInquiryList" element={<SAProductInquiryList/> } />
        <Route path="productReviewList" element={<SAProductReviewList/> } />
    </Route>

    {/* 샐러 관리자 페이지 - 정산  */}
    <Route path="/selleradmin/settlement" element={<SellerManagerLayout/>}>
        <Route path="settlementStatus" element={<SASettlementStatus/> } />
        <Route path="salesHistory" element={<SASalesHistory/> } />
        <Route path="unpaid" element={<SAUnpaidHistory/> } />

        <Route path="TaxHistory" element={<SATaxHistory/> } />
        <Route path="invoiceHistory" element={<SAInvoiceHistory/> } />
    </Route>

    {/* 샐러 관리자 페이지 - 판매통계  */}
    <Route path="/selleradmin/salesstatistics" element={<SellerManagerLayout/>}>
        <Route path="salesstatistics" element={<SASalesStatistics/> } />
    </Route>

    {/* 샐러 관리자 페이지 - 판매자정보  */}
    <Route path="/selleradmin/sellerInfo" element={<SellerManagerLayout/>}>
        <Route path="deliveryCalendar" element={<SADeliveryCalendar/> } />
        <Route path="editProfile" element={<SAEditProfile/> } />
        <Route path="vendorInfo" element={<VendorInfo/> } />

        <Route path="editVendorInfo" element={<EditVendorInfo/> } />
        <Route path="employeeManagement" element={<EmployeeManagement/> } />

        
    </Route>
    
  </Routes>

  <ReactQueryDevtools initialIsOpen={false} />
  </>
  );
}



export default App;
