import React, {useState, useEffect} from 'react';
// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import Editor from '../../../components/Editor';
import { getCancellation, patchCancellation } from '../../../../api/policyApi';

function CancellationPolicy()
{
    const [content, setContent] = useState('');
    const [cancellation, setCancellation] = useState('');
    useEffect(() => {
        getCancellation().then((res) => {
            setCancellation(res?.data?.content);
            console.log(res?.data?.content);
        })
    }, []);
    function onSubmit(content){
        patchCancellation(content)
        .then((res) => {
            alert('저장되었습니다.');
            window.location.reload();
        })
        .catch((error) => {
            alert(error?.response?.data?.data);
        })
    }
    return (
        <>
        <section className="McontentBox mt30">
            <p className="settingSubTitle">교환/환불정책(청약철회 방침)</p>

            <Editor
                content={cancellation}
                setContent={setContent} 
            />
        </section>
       
       <div className="setting-btn-box">
            <button className="m-btn m-btn-search" onClick={()=>onSubmit(content)}>저장하기</button>
       </div>
    </>
    )
}

export default CancellationPolicy