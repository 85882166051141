import React, { useEffect, useState } from 'react';

function Usage({ viewUsage, usages, setUsage })
{
  useEffect(() => {
    setUsage(viewUsage);
  }, [viewUsage]);

  // 체크박스가 변경될 때 호출되는 함수
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setUsage((prevUsages) => {
      if (checked) {
        // 체크가 되었을 때 배열에 추가
        return [...prevUsages, name];
      } else {
        // 체크가 해제되었을 때 배열에서 제거
        return prevUsages.filter((item) => item !== name);
      }
    });
  };

  // 체크박스 항목의 렌더링 함수
  const renderCheckbox = (label) => (
    <div className='product-attributes-item' key={label}>
        <label>
          <input
            type="checkbox"
            name={label}
            checked={usages.includes(label)} // usages 배열에 포함되어 있는지 확인
            onChange={handleCheckboxChange}
          />
          {label}
      </label>
    </div>
  );

  return (
    <>
      <p className="notice-text">
        상품의 용도 정보가 부정확한 경우 관리자의 승인이 늦어질 수 있습니다.
      </p>
      <section className="ProductFilterWrap">
          <p className='product-attribute-text'>용도선택</p>
          <div className='product-attributes'>
            {["회", "구이", "볶음", "조림", "찜", "탕", "국", "찌개", "튀김", "부침", "무침", "기타"].map((category) =>
              renderCheckbox(category)
            )}
        </div>
      </section>
    </>
  );
}

export default Usage;
