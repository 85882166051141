import {  queryClient , prefetchQuery , useQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

// 샐러관리자 > 나의 입점사 상품 문의 목록 조회
const fetchInquiry = (page, pageSize ,ordering) => {
    return instance.get(`inquiry/product/vendor/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useInquiryListQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);

    useEffect(() => {
      setPage(1);
    }, [pageSize]);
  
    const { data, error, isFetching } = useQuery(
      ['inquiryList', page, pageSize, ordering], () => fetchInquiry(page, pageSize , ordering), {
  
    });

    return {
      data,
      error,
      page,
      setPage,
      isFetching,
    };
};

// 샐러관리자 > 나의 입점사 상품 문의 검색
export const handleProductInquirySearch  = async ({
    searchProduct, 
    setSearchResults,
    setSearchUrl,
    searchOption,
    selectedDateType,
    selectedStartDate,
    selectedEndDate, 
    selectedCategory, 
    answerStatus,
    ordering,
    checkedItems
    }) => {
    try {
      let url = `inquiry/product/vendor/document/`;
  
      if (searchProduct) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchProduct}`;
            break;
          case 'title':
            url += `?title=${searchProduct}`;
            break;
          case 'user':
            url += `?user=${searchProduct}`;
            break;
        case 'manager':
            url += `?manager=${searchProduct}`;
            break;
            
          default:
            break;
        }
      }else{
        url += `?`;
      }
  
      if (selectedStartDate && selectedEndDate) {
        switch(selectedDateType) {
          case 'created':
            url += `&created_at__range=${selectedStartDate}__${selectedEndDate}`;
            break;
  
        case 'answered':
            url += `&answered_at__range=${selectedStartDate}__${selectedEndDate}`;
            break;
      
        default:
            break;
        }
      }
  
    
      if (answerStatus) url += `&status=${answerStatus}` 
      
      if (checkedItems.length > 0) {
        for (let i = 0; i < checkedItems.length; i++) {
          url += `&type=${checkedItems[i]}`;
        }
      }
    
  
      url += `&ordering=${ordering}` 
  
      console.log(url)
      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  

// 샐러관리자 > 나의 입점사 상품 리뷰 리스트 조회 
const fetchsellerReview = (page, pageSize ,ordering) => {
  return instance.get(`product/seller/review-document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data);
};

export const useReviewListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['sellerReviewList', page, pageSize, ordering], () => fetchsellerReview(page, pageSize , ordering), {
    onSuccess: (data) => {
      return data;
    }
  });

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 샐러관리자 > 나의 입점사 상품 리뷰 검색 
export const handleSellerReviewSearch  = async ({
  searchProduct, 
  setSearchResults,
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate,
  selectedEndDate, 
  isImage,
  ordering,
  checkedItems
  }) => {
  try {
    let url = `product/seller/review-document/`;

    if (searchProduct) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchProduct}`;
          break;
        case 'title':
          url += `?title=${searchProduct}`;
          break;
        case 'user':
          url += `?user=${searchProduct}`;
          break;
      case 'manager':
          url += `?manager=${searchProduct}`;
          break;
          
        default:
          break;
      }
    }else{
      url += `?`;
    }

    // 날짜 검색
    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'created':
          url += `&created_at__range=${selectedStartDate}__${selectedEndDate}`;
          break;

      default:
          break;
      }
    }

    
    // 평점 검색
    if(checkedItems){
      const resultString = checkedItems.map((num, index) => `${num}${index < checkedItems.length - 1 ? '__' : ''}`).join('');
      url += `&rating__in=${resultString}`;
    }


    // 이미지 여부 검색
    if (isImage) url += `&review_image_status=${isImage}`

   
    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};