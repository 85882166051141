/***************************************************************************************
* FileName      : TalkRow.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발생내역 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.06
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { extractYearMonthDayHourMinute2 } from '../../../../mypage/Component/extractDateUtils';
import { Modal } from 'react-bootstrap';
import DetailModal from './DetailModal';

export const TalkRow  =  ({ talk, index }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return(
    <tr key={talk?.mid}>
      <td>{talk?.mid}</td>
      <td>{talk?.reserve_state}</td>
      <td>
        {talk?.mbody.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
        ))}
      </td>

      <td className="blueLink" onClick={openModal}>{talk?.msg_count}</td>
      <td>{talk?.regdate}</td>
      <td>{talk?.reserve_date}</td>

      <DetailModal 
        show={isOpen} 
        setShow={setIsOpen} 
        mId={talk?.mid}          
      />

  </tr>
  
  )
}

