/***************************************************************************************
* FileName      : Condition.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 > 쿠폰등록 > 발급조건 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useRef, useEffect , useState } from 'react';
import { addCommasToNumberString } from '../../../common/numberUtils';

export default function Condition({couponInfoData ,setCouponInfoData}){

    const updateCouponInfoField = (fieldName, newValue) => {
        setCouponInfoData((prevData) => ({
          ...prevData,
          [fieldName]: newValue
        }));    
    };


    // 발급조건 > 수량제한 여부
    const [limitCount, setLimitCount] = useState(false);

    // 발급조건 > 수량제한이 있을 때 개수
    const [issueCount, setIssueCount] = useState('');

    const handleLimitCountMethod = (event) => {
        const value = event.target.value === 'true';
        setLimitCount(value);

        if(!value) {
            setIssueCount('');
            updateCouponInfoField("issue_count", 0);
        }
        updateCouponInfoField("limit_count", value);
    };

    const handleIssueCountMethod = (e) => {
        const newData = e.target.value ;
        const issueCountDate = newData.replace(/,/g, '');  // 콤마제거
        setIssueCount(issueCountDate);

        updateCouponInfoField("issue_count", parseInt(issueCountDate, 10));

    }

    // 발급조건 > 기간제한 여부
    const [issuePeriod, setIssuePeriod] = useState(false);
    const handleIssuePeriodMethod = (event) => {
        const value = event.target.value === 'true';
        setIssuePeriod(value);

        if(!value) {
            setIssuePeriodStart('');
            setIssuePeriodEnd('');

            updateCouponInfoField("issue_period_start", null);
            updateCouponInfoField("issue_period_end", null);
        }
    };

    // 발급조건  > 기간제한
    const [issuePeriodStart , setIssuePeriodStart ] = useState('');
    const [issuePeriodEnd, setIssuePeriodEnd] = useState('');

    const handleissuePeriodInputChange = (e, periodType) => {
        const value = e.target.value;
        
        if (periodType === 'start') {
            setIssuePeriodStart(value);
        } else if (periodType === 'end') {
            setIssuePeriodEnd(value);
        }

        updateCouponInfoField(
            periodType === 'start' ? 'issue_period_start' : 'issue_period_end',
            value
        );
    };
      
    return(
        <>
        <div className='product-info'>
            <table>                            
                <tbody>
                    <tr>
                        <th>수량제한</th>  
                        <td className="radioBtn-wrap">
                            <label>
                                <input
                                type="radio"
                                value="false"
                                checked={limitCount === false}
                                onChange={handleLimitCountMethod}
                                />
                                제한없음
                            </label>

                            <label>
                                <input
                                type="radio"
                                value="true"
                                checked={limitCount === true}
                                onChange={handleLimitCountMethod}
                                />
                                수량제한
                            </label>

                            <span>(</span>
                            <input
                                type="text"   
                                className='w10'
                                value={addCommasToNumberString(issueCount)}
                                onChange={handleIssueCountMethod}
                                disabled={limitCount !== true}
                                />  
                            <span>개)</span>
                        </td>
                    </tr>
                    <tr>
                        <th>기간제한</th>  
                        <td className="radioBtn-wrap">
                          
                            <label>
                                <input
                                type="radio"
                                value="false"
                                checked={issuePeriod === false}
                                onChange={handleIssuePeriodMethod}
                                />
                                제한없음
                            </label>

                            <label>
                                <input
                                type="radio"
                                value="true"
                                checked={issuePeriod === true}
                                onChange={handleIssuePeriodMethod}
                                />
                                기간제한
                            </label>
                            <span>(</span>

                            <input
                                type="datetime-local"
                                className='bgray5'
                                value={issuePeriodStart}
                                onChange={(e) => handleissuePeriodInputChange(e, 'start')}
                                disabled={issuePeriod !== true}
                                
                            />
                            <span> ~ </span>
                            <input
                                type="datetime-local"
                                className='bgray5'
                                value={issuePeriodEnd}
                                onChange={(e) => handleissuePeriodInputChange(e, 'end')}
                                disabled={issuePeriod !== true}
                            />
                            <span>)</span>
                        </td>
                    </tr>
                 
                </tbody>
        </table>
        </div>
       </>
    )
}