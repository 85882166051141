import React from 'react';
import { Modal } from 'react-bootstrap';
import {Row, Col, Button ,Table } from 'react-bootstrap';
import { useNotificationTalkHistoryDetailQuery } from '../../../../../api/hwadmin/notificationApi';

function DetailModal({show, setShow, mId}){

    const { data, error, isFetching, page, setPage } = useNotificationTalkHistoryDetailQuery(mId);

    console.log(data);
    console.log("-ddssssd")

    return(
      <>
      <Modal
          size="xl"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
      >
          <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            알림톡 발송내역 ({mId})
          </Modal.Title>
          </Modal.Header>
          
          <Modal.Body style={{ overflowY: 'auto' }}>
            <table className='sTable' id='scrollTable'>    
                <thead>
                    <tr>
                        <th>id</th>
                        <th>상태</th>
                        <th>내용</th>
                        <th>수신번호</th>
                        <th>요청일시</th>
                        <th>전송일시</th>
                        <th>응답일</th>
                    </tr>
                </thead>
              
                {data && data.list.map((item, index) => (
                    <tr key={index}>
                        <td>{item.smid}</td>
                        <td>{item.status}</td>
                        {/* <td>{item.message}</td> */}
                        <td>
                            {item?.message.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                            {line}
                            <br />
                            </React.Fragment>
                            ))}
                        </td>
                        <td>{item.phone}</td>
                        <td>{item.reqdate}</td>
                        <td>{item.sentdate}</td>
                        <td>{item.rsltdate}</td>
                    </tr>
                ))} 
            </table>

          </Modal.Body>
      </Modal>
    </>
    )
}


export default DetailModal