/***************************************************************************************
* FileName      : CouponView.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 리스트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';

import { useCouponListQuery , handleCouponSearch } from '../../../../api/hwadmin/marketingApi';
import Pagination from '../../common/Pagination';
import CouponListRow from './CouponListRow';

import SortComponent from '../../common/SortComponent2';
import PageSizeSelect from '../../common/PageSizeSelect';

function CouponView()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useCouponListQuery(ordering,pageSize);

    const [searchCoupon, setSearchCoupon] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');


    const [isDiscount, setIsDiscount] = useState('');
  

    const [types, setTypes] = useState([]);
    const handleCheckboxChange = (event) => {
        const value = event.target.value;

        if (types.includes(value)) {
            setTypes(types.filter((type) => type !== value));
        } else {
            setTypes([...types, value]);
        }
    };
    
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const onSearch = () => {
        handleCouponSearch({
            searchCoupon, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate,
            selectedEndDate, 
            types
         });
 
         setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/marketing/couponlist'; 
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleDuplicateChange = (event) => {
        setIsDiscount(event.target.value);
    };
    
    console.log(data);
    console.log("---")


    return (
        <>
        {/* 검색 */}
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field word'>
                    <div> 
                        <b>검색어</b> 
                    </div>
                    <div>
                        <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                            <option value="all" selected="selected">전체</option>
                            <option value="name">쿠폰명</option>
                            <option value="vendor_name">이용가능매장</option>

                        </select>
                        <input type="text"
                                value={searchCoupon}
                                onChange={(e) => setSearchCoupon(e.target.value)}/> 
                    </div>   
                </div>
                <div className='search-field date'>
                    <div> 
                        <b>쿠폰 생성일</b> 
                    </div>
                    <div>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />    
                    </div>   
                </div>
                <div className='search-field division'>
                    <div> 
                        <b>혜택구분</b> 
                    </div>

                    <div className='CheckboxWrap w30'>
                        <label style={{ marginRight: '10px' }}>
                            <input
                                type='checkbox'
                                value='상품할인쿠폰'
                                checked={types.includes('상품할인쿠폰')}
                                onChange={handleCheckboxChange}
                            />
                            상품할인쿠폰
                        </label>
                        <label>
                            <input
                                type='checkbox'
                                value='배송쿠폰'
                                checked={types.includes('배송쿠폰')}
                                onChange={handleCheckboxChange}
                            />
                            배송쿠폰
                        </label>
                    </div>
                </div>

                
            </div>
           
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    <div className='selectWrap'>
                        <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="최근발행순"
                                oldLabel="오래된발행순"
                            />

                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>쿠폰명</th>
                                <th>혜택구분</th>
                                <th>쿠폰유형</th>
                                <th>혜택</th>
                                <th>쿠폰 생성일</th>
                                <th>쿠폰 발급 가능 기간</th>
                                <th>(쿠폰사용) 유효기간</th>
                                <th>이용가능매장</th>
                                <th>수량제한</th>
                                <th>보유 회원 수</th>
                                <th>이용한 회원 수</th>
                                <th>미등록 쿠폰 수</th>
                                <th>쿠폰코드</th>
                            </tr>
                        </thead>

                        <tbody>
                                    
                            {searchConfirm && searchResults?.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((coupons , index) => (
                                    <CouponListRow 
                                        key={coupons?.id} 
                                        coupons={coupons}/>
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((coupons , index ) => (
                                    <CouponListRow 
                                    key={coupons?.id} 
                                    coupons={coupons}/>
                                
                                ))
                            ) : null}
    
                        </tbody>
                    </table>

                </div>
            </section>

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                        <Pagination
                            count={searchResults?.count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
    
    </>
    )
}

export default CouponView