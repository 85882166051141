/***************************************************************************************
* FileName      : banner.js
* Description   : 해와관리자 > 마케팅 > 배너/팝업
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.09
* Modifide Date : 2024.01.09 : CHM : PC/모바일 배너 구분
*               : 
* Reference     : 
***************************************************************************************/
import BannerManager from "./Banner/BannerManager";
import PopupManager from "./Banner/PopupManager";
import React, { useState, useEffect } from 'react';

function BannerCard()
{
    const [activeComponent, setActiveComponent] = useState("PCBannerManager");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            {/*  배너/팝업  */}
            <div className='page-tit'>
                <h2>배너/팝업</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "PCBannerManager" ? "active-button" : "m-sm-btn"}
                    onClick={() => handleButtonClick("PCBannerManager")}>
                    PC 배너
                </button>

                <button className={activeComponent === "MobileBannerManager" ? "active-button" : "m-sm-btn"}
                    onClick={() => handleButtonClick("MobileBannerManager")}>
                    Mobile 배너
                </button>

                <button className={activeComponent === "PopupManager" ? "active-button" : "m-sm-btn"}
                    onClick={() => handleButtonClick("PopupManager")}>
                    팝업관리
                </button>
          
           
            </div>
            {/* 배너관리 */}
            {activeComponent === "PCBannerManager" && <BannerManager type={'pc'}/>}

            {/* 모바일배너관리 */}
            {activeComponent === "MobileBannerManager" && <BannerManager type={'mobile'}/>}
            
            {/* 팝업관리 */}
            {activeComponent === "PopupManager" && <PopupManager />}


        </div>

        </>
    )
}

export default BannerCard