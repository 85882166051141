const CheckboxGroup = ({ checkedItems, onChange }) => {
    const checkboxOptions = [
      { name: 'total_visiter', label: '전체 방문자 수' },
      { name: 'pc_visiter', label: 'PC' },
      { name: 'mobile_app_visiter', label: '모바일(웹+앱)' },
      { name: 'mobile_visiter', label: '모바일(웹)' },
      { name: 'app_visiter', label: '모바일(앱)' },
    ];
  
    return (
      <div className="radio-wrap ml-2">
            {checkboxOptions.map((option) => (
                <label key={option.name}>
                    <input
                        type="checkbox"
                        name={option.name}
                        checked={checkedItems[option.name]}
                        onChange={onChange}
                    />
                    {option.label}
                </label>
            ))}
      </div>
    );
  };

export default CheckboxGroup