import { BASE_URL } from '../apiConfig';
import { getAccessFromCookie } from '../cookieUtils.js';
import { instance } from '../../axiosInstance/axiosConfig';

const access = getAccessFromCookie();

export const updateVendorData = async (id, newData) => {
  try {
    await instance.put(`/vendor/${id}`, newData);
    alert('데이터 수정이 완료되었습니다.');
  } catch (error) {
    throw new Error('데이터 수정 중 오류가 발생했습니다.');
  }
};


//해와 관리자가 입점사 정보를 수정할 때 사용
export const updateAdminVendorData = async (vendorId, newData) => {
  const access = getAccessFromCookie();
 
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    
    if (access) {
      headers['Authorization'] = `Bearer ${access}`;
    }

    const response = await fetch(`${BASE_URL}/vendor/admin/${vendorId}`, {
      method: 'PATCH', 
      headers: headers ,
      credentials: 'include',
      body: JSON.stringify(newData), 
    });

    if (!response.ok) {
      throw new Error('업데이트에 실패했습니다.');
    }

    return response.json(); // 업데이트된 데이터를 JSON으로 파싱하여 반환
  } catch (error) {
    console.error('업데이트 오류:', error);
    throw error; 
  }
};

// 해와 관리자가 매장 공개 변경 
export const updateVendorHiddenState = (pk, hidden_status) => {
  return instance.patch(`/vendor/admin/${pk}/`,
  {
    hidden : hidden_status
  })
}
