import {  queryClient , prefetchQuery , useQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';
import { getAccessFromCookie } from '../cookieUtils';

const PAGE_SIZE = 10;

const access = getAccessFromCookie();

export const useOrderList = async () => {
    const response = await instance.get(`order/seller/document/`);
    return response.data;
}

// 셀러관리자 > 주문배송 > 주문 조회

const fetchOrder = (page, pageSize ,ordering) => {
  return instance.get(`order/deliveryitem/seller/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
};
  
export const useOrderListQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);

    const { data, error, isFetching } = useQuery(
      ['SAorderList', page, pageSize, ordering], () => fetchOrder(page, pageSize, ordering), {
    });

    return {
      data,
      error,
      page,
      setPage,
      isFetching,
    };
};

// 셀러관리자 > 주문배송 > 주문조회
export const handleSearch = async ({
    searchOrder, 
    setSearchResults,
    setSearchUrl,
    searchOption,
    selectedStartDate, 
    selectedEndDate,
    checkedItems,
    ordering
    }) => {
    try {
      let url = `order/deliveryitem/seller/document/`;

      if (searchOrder) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchOrder}`;
            break;
          case 'OrderNumber':
            url += `?order_number=${searchOrder}`;
            break;
          case 'ProductName':
            url += `?product_name=${searchOrder}`;
            break;
          case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break;
          default:
            break;
        }
      }else{
        url += `?`;
      }

      if (selectedStartDate && selectedEndDate) {
        url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}` 
      }
      
      if(checkedItems){
        const statusParams = checkedItems.map((status) => `status=${encodeURIComponent(status)}`).join('&');
        url += `${statusParams}`
      }

      url += `&ordering=${ordering}` 

      console.log(url)
      setSearchUrl(url)

      const response = await instance.get(url);
      setSearchResults(response.data);
   
      return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 셀러 > 주문배송 > 배송현황 
const fetchDelivery = (page, pageSize, ordering, requestStatus) => {
 
    let url = `order/seller/delivery/?page=${page}&page_size=${pageSize}&ordering=${ordering}`;

    if (requestStatus) {
      url += `&status=${requestStatus}`;
    }
        
    return instance.get(url)
    .then(res => res.data);
};

export const useDeliveryListQuery = (ordering,pageSize,selectedStatus) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize,selectedStatus]);

  const { data, error, isFetching } = useQuery(
    ['deliveryList', page, pageSize, ordering], 
    () => fetchDelivery(page, pageSize , ordering ,selectedStatus));


  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 셀러관리자 > 주문배송 > 배송현황 > 현황
const fetchDeliveryStatus = () => {
  let url = `order/seller/delivery/status/`;

  return instance.get(url)
  .then(res => res.data);

};

export const useDeliveryStatusQuery = () => {
  const { data, error, isFetching } = useQuery(['deliveryStatus'], () => fetchDeliveryStatus(), {
    onSuccess: (data) => {
      return data;
    }
  });

  return {
    data,
    error,
    isFetching,
  };
};


// 셀러관리자 > 주문배송 > 배송 현황 > 검색 
export const handleDeliverySearch = async ({
    searchOrder,  
    setSearchResults, 
    setSearchUrl,
    searchOption,
    selectedDateType,
    selectedStartDate, 
    selectedEndDate,
    selectedStatus,
    courier
     }
    ) => {
    try {
      let url = `order/seller/delivery/`;

      if (searchOrder) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchOrder}`;
            break;
          case 'OrderNumber':
            url += `?order_number=${searchOrder}`;
            break;
          case 'ProductName':
            url += `?product_name=${searchOrder}`;
            break;
          case 'ProductNumber':
              url += `?product_id=${searchOrder}`;
              break;
          default:
            break;
        }
      }else{
        url += `?`;
      }

      if(selectedStartDate && selectedEndDate){
        switch(selectedDateType) {
          case 'paid_at':
            url += `&paid_at=${selectedStartDate}__${selectedEndDate}`;
            break;
          case 'shipped_at':
            url += `&shipped_at=${selectedStartDate}__${selectedEndDate}`;
            break;    
          default:
            break;
        }
      }
      if(courier !="전체"){
        url += `&courier=${courier}` 
      }
      
      if(selectedStatus){
        url += `&status=${selectedStatus}` 
      }

      setSearchUrl(url);
      
      console.log(url);
      
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 셀러관리자 > 주문배송 > 반품/취소 > 현황
export const fetchSellerOrderAbortStatus = () => {
  return instance.get(`order/seller/abort/status/`)
    .then(res => res.data);
};

// 주문배송 > 반품교환취소 전체 조회
const fetchOrderAbort = (page, pageSize ,ordering) => {
  return instance.get(`order/seller/abort/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderAbortListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['sorderAbortList', page, pageSize, ordering], () => fetchOrderAbort(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};


// 셀러 > 주문배송 > 반품/취소 > 전체 검색 
export const handleOrderAbortSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  selectedVendor,
  status,
}) => {
  try {
   let url = `order/seller/abort/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break; 
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break; 
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate && selectedDateType) {
      url += `&${selectedDateType}=${selectedStartDate}__${selectedEndDate}`;
    }

    if(status!='전체'){
      if(status === '처리완료') url += `&is_abort=true` 
      else  url += `&is_abort=false` 
    }

    setSearchUrl(url)

    console.log(url);
    console.log("-rr");
    
    const response = await instance.get(url);
    setSearchResults(response.data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};



// 셀러관리자 > 주문배송 > 반품교환취소 취소 조회
const fetchOrderCancel = (page, pageSize ,ordering) => {
  return instance.get(`/order/deliveryitem/seller/cancel/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderCancelListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['sorderCancelList', page, pageSize, ordering], () => fetchOrderCancel(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 셀러 > 주문배송 > 반품/취소 조회 > 취소 검색 
export const handleOrderCancelSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  checkedItems,
  selectedVendor,
}) => {
  try {
   let url = `order/deliveryitem/seller/cancel/document/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break; 
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break; 
        default:
          break;
      }
    }else{
      url += `?`;
    }
    
    if (selectedStartDate && selectedEndDate && selectedDateType) {
      url += `&${selectedDateType}=${selectedStartDate}__${selectedEndDate}`;
    }


    if(checkedItems){
      const statusParams = checkedItems.map((status) => `cancel_status=${status}`).join('&');
      url += `${statusParams}`
    }

    setSearchUrl(url)

    const response = await instance.get(url);
    setSearchResults(response.data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};



// 셀러관리자 > 주문배송 > 반품교환취소 반품 조회
const fetchOrderReturn = (page, pageSize ,ordering) => {
  return instance.get(`order/seller/abort/return/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
  .then(res => res.data);
};

export const useOrderReturnListQuery = (ordering , pageSize) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['sorderReturnList', page, pageSize, ordering], () => fetchOrderReturn(page, pageSize , ordering));

  return {
    data,
    error,
    page,
    setPage,
    isFetching,
  };
};

// 셀러 > 주문배송 > 반품/취소 조회 > 반품 검색 
export const handleOrderReturnSearch = async ({
  searchOrder,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  checkedItems,

  }) => {
  try {
   let url = `order/seller/abort/return/`;

    if (searchOrder) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchOrder}`;
          break;
        case 'OrderNumber':
          url += `?order_number=${searchOrder}`;
          break; 
        case 'ProductName':
          url += `?product_name=${searchOrder}`;
          break;
        case 'ProductNumber':
            url += `?product_id=${searchOrder}`;
            break; 
        default:
          break;
      }
    }else{
      url += `?`;
    }
    
    if (selectedStartDate && selectedEndDate && selectedDateType) {
      url += `&${selectedDateType}=${selectedStartDate}__${selectedEndDate}`;
    }
     

    if(checkedItems){
      const statusParams = checkedItems.map((status) => `return_status=${status}`).join('&');
      url += `${statusParams}`
    }

    setSearchUrl(url);

    const response = await instance.get(url);
    setSearchResults(response.data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
