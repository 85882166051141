/***************************************************************************************
* FileName      : DeliveryCalendar.js
* Description   : 셀러관리자페이지 배송일정
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.01.25
* Modifide Date : 2024.02.05 : CHM : 배송불가일정 API 연동
*               : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Modal} from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction';
import { createSellerDeliveryDelay, getDeliveryDelay } from '../../../api/deliveryApi';

function DeliveryCalendar(){
    const [modalState, setModalState] = useState(false);
    const [selectDate, setSelectDate] = useState(''); // 선택한 날짜
    const [processedData, setProcessedData] = useState([]);
    const handleDateClick = (arg) => {
        console.log('클릭한 날짜는 :', arg.dateStr);
        setSelectDate(arg?.dateStr);
        setModalState(true);
    };
    useEffect(()=>{
        getDeliveryDelay()
            .then(res => {
                const processed = [];
                res?.data?.data.forEach(item => {
                const startDate = new Date(item?.start);
                const endDate = new Date(item?.end);
                // start와 end 사이의 모든 날짜를 생성
                const currentDate = new Date(startDate);
                while (currentDate <= endDate) {
                    const formattedDate = currentDate.toISOString().split('T')[0]; // yyyy-mm-dd 형식으로 변환
                    processed.push({
                      title: item?.delay_reason,
                      date: formattedDate,
                    });
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                });
                setProcessedData(processed);
            })
            .catch(error => {
                alert(error?.response?.data?.data);
            });
    },[modalState == false])
    return(
        <>
            <div className='mainContents'> 
                <div className='page-tit'>
                    <h2>배송달력 관리</h2>
                </div>
                {/* 검색 결과 */}
                <section className='McontentBox'>
                <FullCalendar
                    plugins={[multiMonthPlugin, interactionPlugin]}
                    initialView='multiMonthYear' // 뷰 설정(연단위로 보이기)
                    events={processedData}
                    locale='ko'
                    dateClick={handleDateClick}
                />
                </section>
            </div>
            <DatePickerModal modalState={modalState} setModalState={setModalState} selectDate={selectDate}/>
        </>
    )
}

function DatePickerModal({modalState, setModalState, selectDate}){
    const [detail, setDetail] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(); 
    function PostDelayReason(){
        if(startDate === '' || endDate === '' || detail === ''){
            alert('모든 항목을 입력해주세요.');
        }else if(startDate <= endDate){
            createSellerDeliveryDelay(startDate, endDate, detail)
                .then(res => {
                    alert('배송불가일정이 추가되었습니다.');
                    setModalState(false);
                })
                .catch(error => {
                    alert(error?.response?.data?.non_field_errors);
                });
        }else{
            alert('시작일은 종료일보다 빠를 수 없습니다.');
        }
    }
    useEffect(()=>{
        setStartDate(selectDate);
        setEndDate(selectDate);
    },[selectDate]);
    return(
        <Modal show={modalState} onHide={()=>setModalState(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>배송 불가일정 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='align-items-center p-2'>
                    <Col xs={2}><p className='fs-6 fw-bold'>배송불가 일정</p></Col>
                    <Col xs={10} className='d-flex'>
                        <input type="date" className='px-2' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        <p className='px-3'> - </p>
                        <input type="date" className='px-2' value={endDate} onChange={(e) => setEndDate(e.target.value)} />  
                    </Col>
                </Row>
                <Row className='align-items-center p-2'>
                    <Col xs={2}><p className='fs-6 fw-bold'>배송불가 사유</p></Col>
                    <Col xs={10}><input type='text' className='w-100 border-default rounded-1 px-3 py-1 fs-7 fw-light' placeholder='배송 불가 사유를 입력해주세요.' onChange={(e) => setDetail(e.target.value)}/></Col>
                </Row>
                <Row className='align-items-center mt-4 p-2'>
                    <Col xs={4}></Col>
                    <Col xs={4}>
                        <Button variant="primary" onClick={()=>PostDelayReason()} className="text-center w-100 m-0 py-1 px-2">
                            확인
                        </Button>
                    </Col>
                    <Col xs={4}></Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default DeliveryCalendar