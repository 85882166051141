import React from 'react';
import axios from 'axios';
import { useState } from 'react';
function SearchPagination({ count, url, updateSearchResults }) {
  const totalPages = Math.ceil(count / 10);
   // 검색결과 페이지내비게이션 현재
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick =  async (pageNumber) => {
    setCurrentPage(pageNumber)
    
    console.log("vvv",url)
    const fetchUrl = `${url}&page=${pageNumber}`;
    try {
      const response = await axios.get(fetchUrl);
      updateSearchResults(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((pg) => (
      <li
        key={pg}
        className={pg === currentPage ? 'cur' : ''}
        onClick={() => handlePageClick(pg)} 
      >
        {pg}
      </li>
    ));
  };

  return (
    <ul className="m-page-nav-btn">
   
      {renderPageNumbers()}
   
    </ul>
  );
}

export default SearchPagination;
