import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';


function SalesStats()
{
    return(
        <>
        {/* 콘텐츠 부분 */}
        <div className='mainContents'>
            {/* 주문조회 */}
            <div className='page-tit'>
                <h2>상품통계</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='Mgray'>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>검색어</td>
                                <td>
                                    <select className='mSelectBox'>
                                        <option value="" selected="selected">전체</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                    </select>
                                    <input type="text"/>
                                </td>
                            </tr>
                            <tr>
                                <td>날짜</td>
                                <td>
                                    <select className='mSelectBox'>
                                        <option value="" selected="selected">입점일</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                    </select>
                                    <input type="date" id="start" name="trip-start"/>
                                    <input type="date" id="end" name="trip-end"/>
                                </td>
                            </tr>
                            <tr>
                                <td>상태</td>
                                <td>
                                    <input type='radio' id='g1' name='group1' value='all' />
                                    <label for='g1'>전체</label>
                                    <input type='radio' id='g2' name='group1' value='m' />
                                    <label for='g2'>판매중</label>
                                    <input type='radio' id='g3' name='group1' value='g' />
                                    <label for='g3'>품절</label>
                                    <input type='radio' id='g3' name='group1' value='g' />
                                    <label for='g3'>판매중지</label>
                                    <input type='radio' id='g3' name='group1' value='g' />
                                    <label for='g3'>판매종료</label>
                                </td>
                            </tr>
                            <tr>
                                <td>수산상회</td>
                                <td>
                                    <select className='mSelectBox'>
                                        <option value="" selected="selected">수산상회</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn'>초기화</button>
                    <button className='m-btn m-btn-search'>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className=''>
                        <span>검색결과</span>
                        <span className='result-wrap'>(총 <b className='sum-s-b'>0</b>개)</span>
                    </div>
                    <div className=''>
                        <select className='mSelectBox'>
                            <option value="" selected="selected">신규등록순</option>
                            <option value="">오래된 가입일 순</option>
                            <option value="">포인트 많은 순</option>
                            <option value="">포인트 적은 순</option>
                            <option value="">구매금액 많은 순</option>
                            <option value="">구매금액 적은 순</option>
                            <option value="">주문횟수 많은 순</option>
                            <option value="">주문횟수 적은 순</option>
                        </select>
                        <select className='mSelectBox'>
                            <option value="" selected="selected">10개씩</option>
                            <option value="">50개씩</option>
                            <option value="">100개씩</option>
                        </select>
                    </div>
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>주문번호</th>
                                <th>판매자</th>
                                <th>주문일시</th>
                                <th>주문상태</th>
                                <th>배송구분</th>
                                <th>상품번호<br/>
                                    상품명
                                </th>
                                <th>옵션정보</th>
                                <th>수량</th>
                                <th>주문금액</th>
                                <th>구매자 ID<br/>
                                    구매자 이름
                                </th>
                                <th>수취인</th>
                                <th>주소</th>
                            </tr>
                            <tr>
                                <td><input type="checkbox" name="Allcheck" value=""/></td>
                                <td>202303056677</td>
                                <td>
                                    <p>코끼리상회</p>
                                </td>
                                <td>
                                2023/03/05 18:40
                                </td>
                                <td>주문접수</td>
                                <td>
                                일반배송
                                </td>
                                <td>
                                    21334456<br/>
                                    오늘만세일 목포 쫀드기 500g...      
                                </td>
                                <td> 용량 : +200g</td>
                                <td>5</td>
                                <td>50,000원</td>
                                <td>
                                    비회원<br/>
                                    최현민
                                </td>
                                <td>                                        
                                최현민
                                </td>
                                <td>[61718] 광주광역시 남구 주월동613-17</td>   
                            </tr>
                            <tr>
                                <td><input type="checkbox" name="Allcheck" value=""/></td>
                                <td>202303056677</td>
                                <td>
                                    <p>코끼리상회</p>
                                </td>
                                <td>
                                2023/03/05 18:40
                                </td>
                                <td>주문접수</td>
                                <td>
                                일반배송
                                </td>
                                <td>
                                    21334456<br/>
                                    오늘만세일 목포 쫀드기 500g...      
                                </td>
                                <td> 용량 : +200g</td>
                                <td>5</td>
                                <td>50,000원</td>
                                <td>
                                    비회원<br/>
                                    최현민
                                </td>
                                <td>                                        
                                최현민
                                </td>
                                <td>[61718] 광주광역시 남구 주월동613-17</td>   
                            </tr>
                            </thead>
                        </table>
                    </div>
                </section>
  
            </div>

        </>
    )
}

export default SalesStats