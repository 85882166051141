/***************************************************************************************
* FileName      : MailRow.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발생내역 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.06
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';
export const MailRow  =  ({ talk, index }) => {
  const navigate = useNavigate();


  return(
    <tr key={talk?.id}>

      <td>{talk?.id}</td>
      <td>{talk?.content}</td>
      <td>  
        {talk?.receiver.map((receiver, index) => (
            <span key={index}>
                {receiver.email}
                <br />
            </span>
        ))}
        </td>

      <td>{extractYearMonthDayHourMinute2(talk?.sent_at)}</td>


  </tr>
  
  )
}

