/***************************************************************************************
* FileName      : AlimTalkSendingHistory.js
* Description   : 해와관리자 > 알림 > 알림톡발송관리 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import Manager from "./Talk/Manager";
import History from "./Talk/History";
import Reservation from './Talk/Reservation';
import Send from './Talk/Send';

import React, { useState } from 'react';

function AlimTalkSendingHistory()
{
    const [activeComponent, setActiveComponent] = useState("Manager");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>알림톡발송관리</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "Manager" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Manager")}>자동발송 관리</button>

                <button className={activeComponent === "History" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("History")}>발송예약</button>
          
                <button className={activeComponent === "Reservation" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Reservation")}>예약목록</button>

                <button className={activeComponent === "Send" ? "active-button w130" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Send")}>발송내역</button>
                    
            </div>
            {/* 자동발송 관리 */}
            {activeComponent === "Manager" && <Manager />}
            
            {/* 발송예약 */}
            {activeComponent === "History" && <History />}

            {/* 예약목록 */}
            {activeComponent === "Reservation" && <Reservation />}

            {/* 발송내역 */}
            {activeComponent === "Send" && <Send />}


        </div>

        </>
    )
}

export default AlimTalkSendingHistory