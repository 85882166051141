import { useState , useRef , useEffect } from 'react';
import { fetchVendorData } from '../../../api/selleradmin/vendorApi';


import { updateRequestVendorData } from '../../../api/selleradmin/vendorApi';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useQuery , useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// 이미지등록
import { uploadImage } from '../../../api/uploadImage/uploadApi';

// 주소 모달
import AddressModal from "./AddressModal.js"

// 셀러관리 > 매장정보 수정요청
function EditVendorInfo()
{
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };
      
    // 데이터 조회
    const { data, isError } = useQuery(['vendorData'], () => fetchVendorData());

    

    // 데이터 수정
    const handleUpdate = async () => {
        try {
            const newData = {
                business_license: uploadedImages.business_license,
                owner_image: uploadedImages.owner_image,
                tax: tax || "",
                phone_number: phone_number || "",
                tel: tel || "",
                fax: fax || "",

                // dispatch_location : dispatchLocation || "",
                // return_address : returnAddress || "",
            };

            setIsLoading(true); 

            const updatedData = await updateRequestVendorData(newData);

            setIsLoading(false);
            alert("수정되었습니다.");
            // window.location.href = '/admin/store/inquiry';

            // console.log('업데이트된 데이터:', updatedData);

            } catch (error) {
           
                console.error('업데이트 오류:', error);

            }
    };


    const inputNameRef = useRef(null);



    
    // 출고지주소
    const [dispatchLocation, setDispatchLocation] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

 
    // 반품지 주소
    const [returnAddress, setrReturnAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

   
    //과세여부
    const [tax, setTax] = useState('과세')


    // 사업장주소 변경 모달
    const [modalIsOpen, setModalIsOpen] = useState(false); //주소입력모달

    // 출고지 주소 변경 모달
    const [dispatchModalIsOpen, setDispatchModalIsOpen] = useState(false); 

    // 반품지 주소 변경 모달
    const [returnModalIsOpen, setReturnModalIsOpen] = useState(false); 
  
    const [detailAddress, setDetailAddress] = useState(''); //상세주소
   

    const [detailModalState, setDetailModalState] = useState(false); //주소 입력모달 false(주소입력화면) <-> true(상세주소)
   


    // 출고지 
    const handleDispatchComplete = (data) => {
        setDispatchLocation({
          address: data.address,
          detail: "",
          postal_code: data.zonecode, // 우편번호
          address_type: data.addressType === "R" ? "도로명" : "지번",
        });
      
        setDispatchModalIsOpen(false);
    };

    // 반품지
    const handleReturnComplete = (data) => {    
        setrReturnAddress({
            address: data.address,
            detail: "",
            postal_code: data.zonecode, // 우편번호
            address_type: data.addressType === "R" ? "도로명" : "지번",
        });
    
        setReturnModalIsOpen(false);
    };


     // 출고지 상세주소 입력
     const handleInputDispatchDetailChange = (e) => {
        const newDetail = e.target.value;

        setDispatchLocation((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

    // 반품지 상세주소 입력
    const handleInputReturnDetailChange = (e) => {
        const newDetail = e.target.value;

        setrReturnAddress((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

   
    const [isAdressError, setIsAdressError] = useState(false);
    const inputAdressRef = useRef(null);


    //이미지 파일들 저장
    const [files, setFiles] = useState({
        business_license: null,
        owner_image: null,
    });

    // 이미지 url 
    const [uploadedImages, setUploadedImages] = useState({
        business_license: null,
        owner_image: null,
    });
    
    const handleFileChange = (event, fileKey) => {
        const newFiles = {...files};
        newFiles[fileKey] = event.target.files[0];
        setFiles(newFiles);
    }

    // 추가정보(선택)
    const [phone_number, setPhonenumber] = useState('01025874587')
    const [tel, setTel] = useState('');
    const [fax, setFax] = useState('');
   
 
    // 기존 데이터 조회
    useEffect(() => {
        // // 4. 사업자등록증 , 대표자사진
        if( data && data.business_license  && data.owner_image) {
            setUploadedImages({
                business_license: data?.business_license,
                owner_image: data?.owner_image,
              
            });
        }

      
        // // 7. 대표자 사진 (4번에서 같이함)

 
        // // 11. 과세 여부 
        setTax(data?.tax);


        // // 추가정보
        // // 1. 매장전화
        setTel(data?.tel);

        // // 2. 팩스번호(선택)
        setFax(data?.fax);

   
        // 4. 출고지 주소
        if( data && data.dispatch_location) {
            setDispatchLocation({
                address: data?.dispatch_location?.address,
                detail: data?.dispatch_location?.detail,
                postal_code: data?.dispatch_location?.postal_code, 
                address_type: data?.dispatch_location?.address_type 
            });
        }   
        
        // 5.반품지 주소
        if( data && data.return_address) {
            setrReturnAddress({
                address: data?.return_address?.address,
                detail: data?.return_address?.detail,
                postal_code: data?.return_address?.postal_code, 
                address_type: data?.return_address?.address_type 
            });
        } 
        
    }, [data]);    



    //과세여부
    const handleTaxChange = (event) => {
        setTax(event.target.value);
    };


    
    if (isError) {
        return <div>Error occurred while fetching data</div>;
    }

    console.log(data)
    
    // 입력 관련 함수


      // 유효성 검사
      const validateForm = () => {
        let isValid = true;

      
        return isValid;
    }


    // 이미지 파일 업로드
    const uploadImages = async () => {
        try {
            const businessLicenseURL = await uploadImage(files?.business_license);
            const ownerImageURL = await uploadImage(files?.owner_image);

            uploadedImages.business_license = businessLicenseURL;
            uploadedImages.owner_image = ownerImageURL;

            console.log("이미지 업로드 완료")
        } catch (error) {
          console.error("이미지 업로드 에러:", error);
        }
    };


    return(
        <>
        <div className='mainContents'>
            {/* 매장정보수정 요청 (셀러가 매장 정보 수정 요청 ) */}
            <div className='page-tit'>
                <h2>매장정보수정 요청</h2>
            </div>

        
            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>기본정보</h3>                  
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    사업자등록증
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex_file">파일 수정</label>
                                    <input type="file" 
                                        id="ex_file" 
                                        onChange={(event) => handleFileChange(event, 'business_license')} 
                                    /> 
                                    <a href={uploadedImages && uploadedImages?.business_license} target="_blank">사업자등록증 확인</a>

                                </td>
                            </tr>
                      
                            <tr>
                                <td>
                                    대표자 사진
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex1_file">파일수정</label>
                                    <input type="file" 
                                        id="ex1_file" 
                                        onChange={(event) => handleFileChange(event, 'owner_image')} 
                                    /> 
                                    {/* {files.owner_image && <p className='filename'>{files.owner_image.name}</p>} */}

                                    <a href={uploadedImages && uploadedImages?.owner_image} target="_blank">대표자 사진 확인하기</a>

                                </td>
                            </tr>
                       
                            <tr>
                                <td>
                                    과세여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type='radio' 
                                        className='mrbtn'
                                        id='t1' 
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '과세'}
                                        value='과세' 
                                    />
                                    <label for='t1'>과세</label>

                                    <input type='radio' 
                                        className='mrbtn'
                                        id='t2'
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '면세'}
                                        value='면세' 
                                    />
                                    <label for='t2'>면세</label>

                                    <input type='radio'
                                        className='mrbtn' 
                                        id='t3' 
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '간이과세'}
                                        value='간이과세'  />
                                    <label for='t3'>간이과세</label>
                                </td>
                            </tr>
                            </tbody>
                    
                    </table>
            
                    </div>
                    
            </section>

            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>추가정보</h3>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    매장전화
                                    <i className='essential'></i>   
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="매장전화"
                                        value={tel}
                                        onChange={(e) => setTel(e.target.value)}
                                    />      
                                    
                                </td>
                            </tr>
                            <tr>
                                <td>팩스번호(선택)</td>
                                <td>
                                    <input 
                                        type="text" 
                                        placeholder="팩스번호"
                                        value={fax}
                                        onChange={(e)=>setFax(e.target.value)}/>
                                </td>
                            </tr>
                          
                            <tr>
                                <td>
                                    출고지 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={dispatchLocation.postal_code}
                                                disabled 
                                            />  
                                            <button className="ms-2" 
                                                    onClick={()=>setDispatchModalIsOpen(true)}>
                                                        주소 검색
                                            </button> 
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="출고지 주소"
                                                value={dispatchLocation.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="출고지 상세주소"
                                            value={dispatchLocation.detail}
                                            onChange={handleInputDispatchDetailChange}
                                            />
                                        </li>
                                    </ul>      
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    반품지 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={returnAddress.postal_code}
                                                disabled 
                                            />  
                                            <button className="ms-2" 
                                                    onClick={()=>setReturnModalIsOpen(true)}>
                                                        주소 검색
                                            </button> 
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="반품지 주소"
                                                value={returnAddress.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="반품지 상세주소"
                                            value={returnAddress.detail}
                                            onChange={handleInputReturnDetailChange}
                                            />
                                        </li>
                                    </ul>      
                                </td>
                            </tr>
                
                        </tbody>
                    </table>
                </div>
            
                <div className='m-btn-wrap'>
                    {
                        data?.update_status === "수정요청" ? (
                            <button onClick={() => alert('이미 요청중인 수정사항이 있습니다.')} className='m-btn'>
                            승인 대기중
                            </button>
                        ) : (
                            <button onClick={handleUpdate} className='m-btn m-btn-search'>
                            {isLoading ? <LoadingSpinner /> : '매장수정 요청'}
                            </button>
                        )
                    }
                </div>
                

            </section>

              
         

            {/* 출고지 변경 모달 */}
            {
                dispatchModalIsOpen ? (
                <AddressModal
                    show={dispatchModalIsOpen}
                    onHide={setDispatchModalIsOpen}
                    autoClose
                    onComplete={handleDispatchComplete} // 출고지 주소 입력 모달용 함수
            
                />
            ) : null
            }

            {/* 반품지 변경 모달 */}
            {
                returnModalIsOpen ? (
                    <AddressModal
                        show={returnModalIsOpen}
                        onHide={setReturnModalIsOpen}
                        autoClose
                        onComplete={handleReturnComplete} // 반품지 주소 입력 모달용 함수
                    />
                ) : null
            }



        </div>

        </>
    )
}

export default EditVendorInfo