import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { findPasswd } from '../../api/services/memberApi';

// 사용자페이지 > 비밀번호 찾기
function FindPasswd(){
    const [inputId, setInputId] = useState('');
    const [inputEmail, setInputEmail] = useState('');

    const navigate = useNavigate();

    const handleSubmit = () => {
        if(inputId === ''){
            alert("아이디를 입력해주세요.");
            return ;
        }
        if(inputEmail === ''){
            alert("이메일을 입력해주세요.");
            return ;
        }
        findPasswd(inputId, inputEmail)
            .then(res => alert(res?.data?.data))
            .catch(error => {
                error?.response?.data?.data ? (
                    alert(error?.response?.data?.data)
                ) : (
                    alert("아이디 또는 비밀번호를 확인해주세요.")
                )
            })
    }
    return(
        <>
        <Container>
            <Row>
                <div className='find_wrap'>
                    <div id="contents">
                        <div className="find-titleArea">
                            <h2>비밀번호 찾기</h2>
                            <p>        
                            일반회원 아이디찾기로, 네이버, 카카오로 
                            <br/>
                            회원가입한 회원은 아이디 검색이 되지 않습니다
                            </p>
                        </div>
                        <div className='check'>
                          <input type='radio' id="check_method1" name='check_method' value='email' checked   />
                          <label for="check_method1">이메일</label>

                          {/*
                          <input type='radio' id="check_method2" name='check_method' value='phone' />
                          <label for="check_method2">휴대폰번호</label>
                          */}
                        </div>

                        <div className='findId'>
                            <div className='name_view'>
                                <strong id="name_label">아이디</strong>
                                <input
                                    name="id"
                                    placeholder='아이디를 입력해주세요'
                                    type="text"
                                    onChange={(e) => setInputId(e.target.value)}
                                />
                            </div>
                            <div className='email_view'>
                                <strong id="name_label">이메일</strong>
                                <input
                                    name="email"
                                    placeholder='이메일을 입력해주세요'
                                    type="text"
                                    onChange={(e) => setInputEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='ec-base-button'>
                            <button onClick={() => handleSubmit()}>비밀번호 찾기</button>
                            <ul>
                                <li className='pointer'>
                                    <a onClick={()=>{navigate('../login')}}>로그인하기</a>
                                </li>
                                <li className='pointer'>
                                    <a onClick={()=>{navigate('../find')}}>아이디 찾기</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Row>
            
        </Container>
        </>
    )
}

export default FindPasswd