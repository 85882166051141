import React, { useState } from 'react';
import Cancel from './Cancel'
import Exchange from './Exchange'
import Return from './Return'


function All()
{
    let [tab , setTab] = useState(0);

    return(
        <>
        {/* 콘텐츠 부분 */}
        <div className='mainContents'>
            {/* 반품/교환/취소 - 반품 */}
            <div className='page-tit'>
                <h2>반품/취소</h2>
            </div>

            {/* 배송 현황 */}
            <section className='state-wrap'>
                <div className={ tab===0 ? "state-box click":"state-box"} onClick={()=>{setTab(0)}}>
                    <p className='tit'>전체</p>
                    <p className='dec'>0</p>
                </div>
                <div className={ tab===1 ? "state-box click":"state-box"} onClick={()=>{setTab(1)}}>
                    <p className='tit'>반품</p>
                    <p className='dec'>0</p>
                </div>
                <div className={ tab===2 ? "state-box click":"state-box"} onClick={()=>{setTab(2)}}>
                    <p className='tit'>교환</p>
                    <p className='dec'>0</p>
                </div>
                <div className={ tab===3 ? "state-box click":"state-box"} onClick={()=>{setTab(3)}}>
                    <p className='tit'>취소</p>
                    <p className='dec'>0</p>
                </div>
            </section>


            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='Mgray'>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>검색어</td>
                                <td>
                                    <select className='mSelectBox'>
                                        <option value="" selected="selected">전체</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                    </select>
                                    <input type="text"/>
                                </td>
                            </tr>
                            <tr>
                                <td>날짜</td>
                                <td>
                                    <select className='mSelectBox'>
                                        <option value="" selected="selected">결제완료일</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                    </select>
                                    <input type="date" id="start" name="trip-start"/>
                                    <input type="date" id="end" name="trip-end"/>
                                </td>
                            </tr>
                            <tr>
                                <td>수산상회</td>
                                <td>
                                    <select className='mSelectBox'>
                                        <option value="" selected="selected">수산상회</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                        <option value="">선택 옵션2</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn'>초기화</button>
                    <button className='m-btn m-btn-search'>검색</button>
                </div>
            </section>

            {/* 검색 결과*/}
            <SearchContent tab={tab}/>

  


            {/* 페이지내비게이션 */}
            {/* <section>
                <ul className='m-page-nav-btn'>
                    <li className='cur'>1</li>
                    <li>2</li>
                    <li>3</li>
                </ul>
            </section> */}
                
        </div>

        </>
    )
}
function SearchContent({tab}){
   return (<div className=''>
        {[<Cancel/>, <Return/>, <Exchange/> , <Cancel/>][tab]}
    </div>)
}

export default All