import Carousel from '../common/Carousel'
import {Row, Col, Container} from 'react-bootstrap';
import { useEffect, useState, memo } from 'react';
import data from '../data.js';
import Header from '../header.js'
import Footer from '../Footer.js'
import { useNavigate } from 'react-router-dom';
import sectionBanner from '../../src/assets/img/section-banner.png';
import { BsStarFill } from 'react-icons/bs';
import { getUserProductList , getUserSeasonalProductList, getSeasonalCategory, getSellerList, getReviewList } from '../api/mainApi';
import {Card, SliderCard} from './home/Card';
import { SeasonalSlider } from './mainslider/SeasonalSlider';
import { SlAnchor } from "react-icons/sl";
import { getPopup } from '../api/bannerApi.js';

// import Slick
import Slider from 'react-slick';

function Main() {
    const [product, setProduct] = useState(data);

    // 제철상품
    const [seasonalProduct , setSeasonalProduct] =  useState('');

    // 제철 카테고리
    const [seasonalCategory, setSeasonalCategory] = useState([]);
    const [activeSeasonalCategory, setActiveSeasonalCategory] = useState('전체');

    // 팝업 리스트
    const [popupList, setPopupList] = useState([]);

    let navigate = useNavigate();
    const MemoizedHeader = memo(Header);

    useEffect(()=>{
        getUserProductList()
            .then(res => setProduct(res?.data?.data))
            .catch(error => alert(error?.data))
    }, [])
    useEffect(() => {
        //제철상품
        getUserSeasonalProductList()
            .then(res => {
                activeSeasonalCategory === '전체' ? setSeasonalProduct(res?.data?.data) : (
                    setSeasonalProduct(res?.data?.data.filter(item => 
                        item.category?.[1]?.parent_name === activeSeasonalCategory
                    ))
                )
                
            })
            .catch(error => alert(error?.data))

        // 제철 카테고리
        getSeasonalCategory()
            .then(res => setSeasonalCategory(res?.data?.data))
            .catch(error => alert(error?.response?.data))
    }, [activeSeasonalCategory])

    // 셀러 리스트
    const [sellerList, setSellerList] = useState([]);
    const [totalSeller, setTotalSeller] = useState(0);
    useEffect(()=>{
        // 셀러 리스트 조회
        getSellerList()
            .then(res => {
                console.log(res?.data);
                setTotalSeller(res?.data?.totalCnt);
                setSellerList(res?.data?.data);
            })
            .catch(error => console.log(error?.response?.data))
    }, [])

    // 리뷰 리스트
    const [reviewList, setReviewList] = useState([]);
    useEffect(()=>{
        getReviewList()
            .then(res => setReviewList(res?.data?.data))
            .catch(error => console.log(error?.response?.data))
    }, [])

    // 팝업 리스트
    useEffect(()=>{
        getPopup()
            .then(res=>setPopupList(res?.data?.data))
            .catch(error=>console.log(error?.response?.data))
    },[])

 
    return(
        <>
        <MemoizedHeader/>
        {/* 슬라이드 배너  */}
        <Carousel />
        
        {/* 인기상품 리스트 */}
        <section className="p-0 pb-5">
            <div className="container ps-3 pe-3">
                <div className="row">
                    <div xs={12} className="col m-banner-section-pc">
                        <img className="rounded-1 mt-4" src="https://d3w2dltk08a0sm.cloudfront.net/images/094d64ee18e148bb82c01adfe6956deb" width="100%"/>
                    </div>
                    <div xs={12} className="col m-banner-section-mobile">
                        <img className="rounded-1 mt-4" src="https://d3w2dltk08a0sm.cloudfront.net/images/337d5a5856204f5cb90199ff126c42ea" width="100%"/>
                    </div>
                    <div className="col-md-12 text-start">
                        <h1 className="MItemheading">
                            목포해와<b> 실시간 베스트 수산물 </b>
                        </h1>
                        <p className='pr_title2'>
                            지금 가장 인기있는 신선한 수산물을 만나보세요.
                        </p>
                    </div>
                </div>
                <Row>
                {
                    product.map((a,i)=>{
                        return (
                            <Card product={product[i]} i={i} key={i}></Card>
                        )
                    })
                }
                </Row>
            </div>
        </section>

        {/* 추천상품리스트 */}
        {/* <section className='bg-hw-light section2 pt-5'></section> */}
        <section className="p-0 pb-5 section2">
            <Container className="ps-3 pe-3">
                <Row>
                    <Col xs={12} className='m-banner-section-pc'>
                        <img className="d-flex rounded-1 mt-4" src="https://d3w2dltk08a0sm.cloudfront.net/images/6951235b3802449cabb701596a3fdef6" width="100%"/>
                    </Col>
                    <Col xs={12} className='m-banner-section-mobile'>
                        <img className="d-flex rounded-1 mt-4" src="https://d3w2dltk08a0sm.cloudfront.net/images/368d9bf62f1845adb0777904159db640" width="100%"/>
                    </Col>
                    <Col>
                        <h1 className="MItemheading">
                            <b> 실시간 추천 수산물 </b>
                        </h1>
                        <p className='pr_title2'>
                            목포해와 MD가 추천하는 수산물을 만나보세요.
                        </p>
                    </Col>
                </Row>
                <Row>
                {
                    product && product.length > 0 && (
                        product.map((product,i)=>(                        
                            <Card product={product} i={i} key={i} />
                        ))
                    )
                }
                </Row>
            </Container>
        </section>

        {/* 제철상품 리스트 */}
        <section className="p-0">
            <div className="container ps-3 pe-3">
                <div className="row">
                    <div className="col-md-12 text-start">
                        <h1 className="MItemheading">
                            목포해와<b> 제철 수산물 </b>
                        </h1>
                        <p className='pr_title2'>
                            제철 수산물을 만나보세요.
                        </p>
                    </div>
                </div>
                <div className="seasonal-category">
                    {/* 제철 수산물 선택용 카테고리 리스트 조회(임시) */}
                    <p className={`seasonal-category-btn mb-1 ${activeSeasonalCategory === '전체' && 'active'}`}onClick={()=>setActiveSeasonalCategory('전체')}>전체</p>
                    {
                        seasonalCategory.length > 0 && (
                            seasonalCategory?.map(data => {
                                return <p className={`seasonal-category-btn mb-1 ${activeSeasonalCategory === data?.category?.middle && 'active'}`} onClick={()=>setActiveSeasonalCategory(data?.category?.middle)}>{data?.category?.middle}</p>
                            })
                        )
                    }
                </div>
                <Row>
                    <SeasonalSlider seasonalProduct={seasonalProduct}/>
                </Row>
            </div>
        </section>

        {/* 셀러 리스트 */}
        <section className="p-0">
            <div className="container ps-3 pe-3">
                <div className="row">
                    <div className="col-md-12 text-start">
                        <h2 className="MItemheading fw-bold mb-2">
                            목포해와는 지금,<br/>
                            <span className='fw-bold text-hw'>{totalSeller}명의 수산업계 대표님</span>과 함께하고있습니다
                        </h2>
                    </div>
                </div>
                <Row className='mt-3'>
                    <Col xs={12} sm={5} md={4} lg={3}>
                        <div className='py-xs-0 py-sm-2 h-100'>
                            <div className='p-4 bg-light rounded-3 h-100 position-relative shadow'>
                                <p className='fs-1 fw-bold py-3 text-hw'><SlAnchor /></p>
                                <p className='fs-7 fw-bold pt-1 pb-4 text-grey'>안전한 수산물이 고민이라면?</p>
                                
                                <p className='fs-4 fw-bold py-2'>우리 입점상회를</p>
                                <p className='fs-4 fw-bold py-2'>만나보세요</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={7} md={8} lg={9}>
                        <SellerSlider sellerList={sellerList}/>
                    </Col>
                    
                </Row>
            </div>
            <div className='wave-box pc-width'>
                <svg className="wave-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#0B75B8" fill-opacity="1" d="M0,64L48,96C96,128,192,192,288,197.3C384,203,480,149,576,149.3C672,149,768,203,864,192C960,181,1056,107,1152,85.3C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
                <svg className="wave-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#0B75B8" fill-opacity="1" d="M0,96L30,106.7C60,117,120,139,180,122.7C240,107,300,53,360,37.3C420,21,480,43,540,69.3C600,96,660,128,720,144C780,160,840,160,900,138.7C960,117,1020,75,1080,69.3C1140,64,1200,96,1260,117.3C1320,139,1380,149,1410,154.7L1440,160L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
                </svg>
            </div>
            <div className='wave-box mobile-width'>
                <svg className="wave-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#0B75B8" fill-opacity="1" d="M0,32L60,80C120,128,240,224,360,245.3C480,267,600,213,720,181.3C840,149,960,139,1080,112C1200,85,1320,43,1380,21.3L1440,0L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                </svg>
                <svg className="wave-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#0B75B8" fill-opacity="1" d="M0,256L48,250.7C96,245,192,235,288,224C384,213,480,203,576,213.3C672,224,768,256,864,224C960,192,1056,96,1152,90.7C1248,85,1344,171,1392,213.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                </svg>
            </div>
        </section>

        {/* 상품후기 리스트 */}
        <section className="p-0 pb-5 bg-light">
            <div className="container ps-3 pe-3">
                <div className="row">
                    <div className="col-md-12 text-start">
                        <h1 className="MItemheading">
                            <b>상품후기</b>
                        </h1>
                        <p className='pr_title2'>
                            고객님의 실 후기만을 담았습니다.
                        </p>
                    </div>
                </div>
                <Row>
                    <ReviewSlider reviewList={reviewList}/>
                </Row>
            </div>
        </section>
        <Footer/>
        {
            popupList != [] && popupList?.length > 0 && (
                window?.innerWidth <= 480 ? (
                    <PopupModal 
                        type='mobile'
                        popupList={popupList}
                    />
                ) : (
                    <PopupModal
                        type='pc'
                        popupList={popupList}
                    />
                )
            )
        }
        </>
    )
}



function SellerCard({data}){
    let locationTarget = `/product/vendor/items?name=${data?.name}&id=${data?.id}`
    return(
        <div className="item p-2 pb-2">
            <div className="rounded-1 bg-white overflow-hidden shadow-sm">
                <div className='thumb'>
                    <a href={locationTarget}> <img src={data?.owner_image}/> </a>
                    {/* <LikeButton />  */}
                </div>
                <div className='prd-info text-center mt-3 pb-3'>
                    <div className='prd-name-wrap'><span className='prd-name fs-6'>{data?.name}</span></div>
                    {/* <span className='prd-seller mt-1 mb-1 ps-3 pe-3'>3대째 목포에서 오징어를 판매하고있는 목포대표 오징어 수산상회입니다. 최상급 오징어만 판매하겠습니다.</span> */}
                    
                    <p className="my-3 mx-4 border text-secondary fs-7 pointer" onClick={()=>window.location.href=locationTarget}>제품 보러가기 › </p>
                </div>
            </div>
        </div>
      )
}

function ReviewCard({data}){
    let herfLink = `/product/detail/${data?.product?.id}`
    return(
        <Col className="item p-2 pb-3">
            <div className="p-0 border bg-white">
                <div className='thumb'>
                    <a href={herfLink}> <img src={data?.product?.main_image}/> </a>
                </div>
                <div className='prd-info text-start'>
                    <Row className="p-3 pt-2">
                        <Col md="auto" sm="auto" xs="auto" className="pe-0 text-grey">{data?.user?.username?.[0]}{data?.user?.username?.[1]}{data?.user?.username?.[2]}***</Col>
                        <Col className='text-end'>
                            <BsStarFill className='text-hw'/>
                            <BsStarFill className='text-hw'/>
                            <BsStarFill className='text-hw'/>
                            <BsStarFill className='text-hw'/>
                            <BsStarFill className='text-hw'/>
                        </Col>
                    </Row>
                    <div className='prd-name-wrap ps-3 pe-3'><span className='prd-name'>{data?.product?.name}</span></div>
                    <span className='prd-seller mt-1 mb-1 ps-3 pe-3 pb-3'>
                    {data?.content && data.content.length > 25 ? `${data.content.substring(0, 25)}...` : data.content}
                    </span>
                </div>
            </div>
        </Col>
      )
}

function SellerSlider({sellerList}){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        centerPadding: '0px',
        initialSlide: 0,
        arrows: false,
        fade: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return(
        <Slider { ...settings }>
            { sellerList?.map(data => <SellerCard data={data}/>)}
        </Slider>
    )
}

function ReviewSlider({reviewList}){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        centerPadding: '0px',
        initialSlide: 0,
        arrows: false,
        fade: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            }
        ]
    }
    console.log(reviewList);
    return(
        <Slider { ...settings }>
            {reviewList?.length > 0 && reviewList?.map(data => <ReviewCard data={data}/>)}
        </Slider>
    )
}

function PopupModal({type, popupList}) {
    const [isOpen, setIsOpen] = useState(false);
    const [nowPopup, setNowPopup] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false); // 이미지 로딩 상태
    const closePopup = () => setIsOpen(false);
    const popupLength = popupList?.length;

    const [blockStyle, setBlockStyle] = useState({
        maxWidth: "400px",
        position: "relative"
    });

    useEffect(()=>{
        type === 'mobile' && setBlockStyle({
            maxWidth: "90%",
            position: "relative"
        })
    }, []);

    const handleCloseClick = () => {
        setIsOpen(false);
        const date = new Date();
        date.setDate(date.getDate() + 1); // 현재 날짜에 1일을 더하여 만료일을 설정합니다.
        document.cookie = "hidePopup=true; expires=" + date.toUTCString() + "; path=/";
    };

    const checkPopupCookie = () => {
        const cookies = document.cookie.split(";").map(cookie => cookie.trim());
        for (const cookie of cookies) {
            if (cookie.startsWith("hidePopup=")) {
                return false; // 쿠키에 "hidePopup"이 있으면 팝업을 보여주지 않습니다.
            }
        }
        return true; // 쿠키에 "hidePopup"이 없으면 팝업을 보여줍니다.
    };

    useEffect(()=>{
        if (checkPopupCookie()) {
            setIsOpen(true);
        }else{
            return ;
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // 모달이 열릴 때 스크롤을 막음
        } else {
            document.body.style.overflow = 'auto'; // 모달이 닫힐 때 스크롤을 허용
        }

        return () => {
            document.body.style.overflow = 'auto'; // 컴포넌트가 언마운트될 때 스크롤을 허용
        };
    }, [isOpen]);

    useEffect(() => {
        if(popupList?.length === 0) return;
        const interval = setInterval(() => {
            setNowPopup(prevNowPopup => (prevNowPopup + 1) % popupLength);
        }, 4000);
        return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 타이머를 정리
    }, [popupList?.length]);

    useEffect(() => {
        if (imagesLoaded) {
            setIsOpen(true); // 이미지 로딩 후 팝업 오픈
        }
    }, [imagesLoaded]);

    const handleImageLoad = () => {
        setImagesLoaded(true); // 이미지가 로딩될 때마다 이미지 로딩 상태 변경
    };

    return (
        <div>

            {isOpen && popupLength > 0 && (
                <div className="popup-wrap" style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 99,
                    background: "rgba(0, 0, 0, 0.6)", // 반투명 배경
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div className="popup-content" style={blockStyle}>
                        <img src={popupList[nowPopup]?.image}
                            onClick={()=>{ window.location.href = popupList[nowPopup]?.link }}
                            onLoad={()=>handleImageLoad()}
                            className='w-100 rounded-2 pointer'
                            style={{transition: "opacity 0.5s ease-in-out"}}
                        />

                        {
                            imagesLoaded && popupList?.length > 1 && (
                                <div style={{ 
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "#ffffff",
                                    border: "1px solid #dee2e6",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: "20px",
                                    width: "48px",
                                    height: "32px",
                                    textAlign: "center",
                                    lineHeight: "30px",
                                    cursor: "pointer"
                                }}>{nowPopup+1}/{popupList?.length}</div> 
                            )
                        }
                        {
                            imagesLoaded && (
                                <>
                                    <a onClick={handleCloseClick}
                                        className="text-white fw-bold pointer"
                                        style={{ position: "absolute", bottom: "-32px", left: "10px"}}
                                    >오늘 하루 보지 않기</a>

                                    <a onClick={closePopup} 
                                        className="text-white fw-bold pointer" 
                                        style={{ position: "absolute", bottom: "-32px", right: "10px" }}
                                    >닫기</a>
                                </>
                            )
                        }
                    </div>
                </div>
            )}
        </div>
    );
}

export default Main