import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { SlArrowUp, SlArrowDown} from "react-icons/sl";

function SideCategory({categoryData}){
	const [categoryList, setCategoryList] = useState([]);
	useEffect(()=>{
		// categoryData undefined 방지용
		categoryData && setCategoryList(categoryData);
		console.log(categoryData);
	},[categoryData])

	return (
		<>
			{
				categoryList.length > 0 && categoryList?.map(data => (
					<SideCategoryBlock categoryName={data?.name} categoryId={data?.id} subCategory={data?.children}/>
				))
			}
			
		</>
	)
}

function SideCategoryBlock({categoryName, categoryId, subCategory}){
	const [categoryList, setCategoryList] = useState([]);
	const [displayState, setDisplayState] = useState('off');
	useEffect(()=>{
		// subCategory undefined 방지용
		subCategory && setCategoryList(subCategory);
	},[subCategory])

	return(
		<>
			<li className='d-flex justify-content-between align-items-center' 
				onClick={()=>{
					displayState === 'on' ? setDisplayState('off') : setDisplayState('on');
				}}>
				<p onClick={()=>window.location.href=`/product/list?category=${categoryName}/${categoryId}`} className='fw-bolder'>{categoryName}</p>
				{categoryList.length > 0 && (
					<p>{displayState === 'on' ? <SlArrowUp/> : <SlArrowDown/> }</p>
				)}
			</li>
			{/* 세부카테고리 */}
			<ul className={'side-category-block '+displayState}>
				{
					categoryList.length > 0 && categoryList?.map(data => (
						<li className='fs-7 text-grey fw-bold' onClick={()=>{window.location.href='/product/list?category='+data?.name+'/'+data?.id+'/'+data?.parent}}>{data?.name}</li>
					))
				}
			</ul>
		</>
	)
}

export default SideCategory