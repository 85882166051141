/***************************************************************************************
* FileName      : ProductRow.js
* Description   : 해와관리자 > 상품관리 > 상품조회 ProductRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useProductsQuery } from '../../../../api/hwadmin/productApi.js';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { updateProductState, updateProductOriginalPrice, updateProductPrice, updateProductStock } from '../../../../api/product/updateProductData.js';
import InfoNoticeModal from '../NewRequest/InfoNoticeModal.js';

export const ProductRow = ({ product, expandedIds, handleExpandClick, selectedRows, setSelectedRows }) => {
  const navigate = useNavigate();
  
  const { fetchProductDetailsById } = useProductsQuery();

  const [productDetails, setProductDetails] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  
  // 상품정보제공고시 모달 
  const [isInfoNoticeOpen , setIsInfoNoticeOpen] = useState(false);
  



  useEffect(() => {
    async function fetchProductDetails() {
      try {
        const details = await fetchProductDetailsById(product.id);
        setProductDetails(details);
      } catch (error) {
        console.error('제품 세부 정보를 가져오는 중 오류가 발생했습니다:', error);
      }
    }

    fetchProductDetails();
  }, [product?.id]);

  if (!productDetails) {
    return <div>Loading...</div>;
  }


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // 수정버튼
  const handleOrderNumberClick = (productId) => {
    navigate(`/admin/product/edit/${productId}`);
  };

  // 복사버튼 클릭시 
  const handleCopyClick = (productId) => {
    navigate(`/admin/product/copy/${productId}`);
  };

  // 날짜 형식 변경
  const dateFormat = (date) => {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const hours = formattedDate.getHours().toString().padStart(2, '0');
    const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  // 상품정보제공고시 모달   
  const openInfoNoticeModal = () => {
    setIsInfoNoticeOpen(true);
  };

  // vendor 검색 페이지 이동
  const handleCellClick = (name) => {
    const searchQuery = encodeURIComponent(name);
    const newUrl = `/admin/store/inquiry/?search=${searchQuery}`;
    navigate(newUrl);
  };
  
  // product ID 검색 페이지 이동
  const handleProductIdCellClick = (productId) => {
    const searchQuery = encodeURIComponent(productId);
    const newUrl = `/admin/product/check/?search=${searchQuery}`;
    navigate(newUrl);
  };
    
  return (
    <React.Fragment key={product?.id}>
      <tr>
        <td>
          <button
            className='expand-btn'
            onClick={() => handleExpandClick(product?.id)}
          >
            <i
              className={
                expandedIds.includes(product?.id) ? 'xi-angle-up' : 'xi-angle-down'
              }
            ></i>
          </button>
        </td>
        <td>
          <input type="checkbox" 
            onClick={()=>{
              setSelectedRows({
                ...selectedRows,
                [product?.id]: !selectedRows[product?.id]
              })
            }}
          />
        </td>
        <td className="px-4"><span className={productDetails?.approve_status === "승인완료" ? "text-success fw-bold" : productDetails?.approve_status === "승인거절" ? "text-danger fw-bold" : "text-grey"}>{productDetails?.approve_status}</span></td>
        {/* <td className="text-grey">{productDetails?.sale_status === "판매중" || productDetails?.sale_status === "판매중지" ? <span className="text-success fw-bold">판매가능</span> : productDetails?.sale_status}</td> */}
        <td className="px-4"><ToggleSwitch pk={product?.id} checked={productDetails?.sale_status}/></td>
        <td>
          <button className='t-s-btn me-2' onClick={() => handleOrderNumberClick(product?.id)}>수정</button> 
          <button className='t-s-btn' onClick={() => handleCopyClick(product?.id)}>복사</button> 
        </td>
        <td className='t-product-image'>
          <img src={productDetails?.main_image} alt="Product" />
        </td>
        <td>
          <p className='blueLink' onClick={() => handleProductIdCellClick(product?.product_id)}>
            {product?.product_id}
          </p>
          {product?.name.length > 20 ? `${product?.name.substring(0, 10)}...` : product?.name}
        </td>
        <td className='blueLink' onClick={() => handleCellClick(productDetails?.vendor?.name)}>
          {productDetails?.vendor?.name}
        </td>

        {/* [hm] 정상가 변경(prompt 사용) : 2023.09.20 */}
        <td>
          <span className="fs-7 text-grey">
            {product?.original_price} {productDetails?.option_rows?.length == 0 && <AiOutlineEdit className="text-grey pointer" 
              onClick={()=>{
                let newOriginalPrice = prompt("정상가 수정(숫자만 입력)\n"+product?.name, product?.original_price);
                if(Number(newOriginalPrice)){
                  updateProductOriginalPrice(product?.id, newOriginalPrice);
                }else{
                  alert('변경 실패 : 숫자만 입력해주세요.');
                }
              }}
            />}
          </span>
        </td>

        {/* [hm] 상품금액 변경(prompt 사용) : 2023.09.20 */}
        <td>
          {product?.price} {productDetails?.option_rows?.length == 0 && <AiOutlineEdit className="text-grey pointer" 
            onClick={()=>{
              let newPrice = prompt("판매금액 수정(숫자만 입력)\n"+product?.name, product?.price);
              if(Number(newPrice)){
                if(newPrice <= product?.original_price){
                  updateProductPrice(product?.id, newPrice);
                }else{
                  alert('변경 실패 : 정상가보다 비싸게 판매할 수 없습니다.');
                }
              }else{
                alert('변경 실패 : 숫자만 입력해주세요.');
              }
            }}
          />}
        </td>

        {/* [hm] 재고수량 변경(prompt 사용) : 2023.09.20 */}
        <td>
          {product?.stock} {productDetails?.option_rows?.length == 0 && <AiOutlineEdit className="text-grey pointer" 
            onClick={()=>{
              let newStock = prompt("재고 수정(숫자만 입력)\n"+product?.name, product?.stock);
              if(Number(newStock)){
                updateProductStock(product?.id, newStock);
              }else{
                alert('변경 실패 : 숫자만 입력해주세요.');
              }
            }}
          />}
        </td>

        <td><button className='t-s-btn' onClick={openModal}>상세설명</button></td>
        <td>{productDetails?.inspect_info?.manufacturer ? productDetails?.inspect_info?.manufacturer : productDetails?.aquatic_info?.manufacturer}</td>
        {/* <td>{product?.category[0]?.name} / {product?.category[1]?.name} / {product?.category[2]?.name}</td> */}
        {/* [hm] 카테고리 순서 섞이는 오류 수정 : 2023.09.18 */}
        <td>{product?.category.find(category => category.depth === 1)?.name || ""} / {product?.category.find(category => category.depth === 2)?.name || ""} / {product?.category.find(category => category.depth === 3)?.name || ""}</td>
        <td>{productDetails?.inspect_info?.minor_purchase ? "구매가능" : "구매불가"}</td>
        <td>{productDetails?.inspect_info?.max_purchase_per_person === 99999999 ? "제한없음" : productDetails?.inspect_info?.max_purchase_per_person+"개"}</td>
        <td>{productDetails?.delivery_info?.days_required_for_shipping}일</td>
        <td>배송방법</td>
        <td>{productDetails?.delivery_info?.bundle_delivery_available ? "가능" : "불가"}</td>
        <td>{productDetails?.delivery_info?.island_delivery_available ? "배송가능" : "배송불가"}</td>
        <td>          
          <button className='t-s-btn' onClick={openInfoNoticeModal}>자세히</button> 
        </td>
        <td>{productDetails?.delivery_info?.courier}</td>
        <td>{productDetails?.delivery_info?.shipping_cost_type}</td>
        <td>{productDetails?.return_exchange_info?.initial_delivery_fee}</td>
        <td>{productDetails?.return_exchange_info?.return_delivery_fee}</td>
        <td>{dateFormat(productDetails?.inspect_info?.sales_start_date)}</td>
        <td>{dateFormat(productDetails?.inspect_info?.sales_end_date)}</td>
        <td>{dateFormat(productDetails?.inspect_info?.created_at)}</td>
        <td>{dateFormat(productDetails?.inspect_info?.updated_at)}</td>
        {/* <td>{productDetails}</td> */}
        <td><button className='t-s-btn' onClick={openModal}>수정이력</button></td>

        {/* 상세설명 모달창 */}
        {/* {isOpen && (
          <div className="modal ">
            <div className="modal-content ProductDetailModal">
                <span className="close" onClick={closeModal}>&times;</span>
                <div dangerouslySetInnerHTML={{ __html: productDetails?.detail }}/>
              </div>
            </div>
        )} */}
        <DetailModal show={isOpen} setShow={setIsOpen} tag={productDetails?.detail}/>
              
        {/* 상품정보제공고시 모달창 */}
          <InfoNoticeModal 
            show={isInfoNoticeOpen} 
            setShow={setIsInfoNoticeOpen} 
            productDetails={productDetails}          
          />

      </tr>
      {expandedIds.includes(product?.id) && (
        <tr className='sub-table'>
          <td colSpan={13}>
            <table>
              <thead>
                <tr>
                  <th>상태</th>
                  <th>옵션아이디</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>재고수량</th>
                </tr>
              </thead>
              <tbody>
                {/* sub-table 내용 */}
                {product.option_rows.map((optionRow, index) => (
                  <tr key={index}>
                    <td></td>
                    <td>{optionRow?.id}</td>
                    <td>{optionRow?.name}</td>
                    {/* {optionRow.option_value.map((optionValue) => (
                      <td key={optionValue.id}>{optionValue.name}</td>
                    ))} */}
                    <td>{optionRow?.original_price}</td>
                    <td>{optionRow?.price}</td>
                    <td>{optionRow?.stock}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
  </React.Fragment>
  );
};

function DetailModal({show, setShow, tag}){
  return(
    <>
    <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            상세페이지 미리보기
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div width="90%">
              <div className='editor-content'>
                <div dangerouslySetInnerHTML={{ __html: tag }}/>
              </div>
            </div>
        </Modal.Body>
    </Modal>
  </>
)
}

// [hm] 판매중/판매중지 상태 변경을 토글방식으로 적용 : 2023.09.19
export default function ToggleSwitch({ pk, checked }) {
  // 초기값을 조건에 따라 설정
  const initialChecked = checked === '판매중' || checked === '품절' ? true : false;
  const [isChecked, setIsChecked] = useState(initialChecked);
  const [disabledToggle, setDisabledToggle] = useState(checked === '판매대기' || checked === '품절');

  const handleToggle = () => {
    // 토글이 가능한 경우에만 토글 상태 변경
    if (!disabledToggle) {
      setIsChecked(!isChecked);
      if(checked === '판매중'){
        updateProductState(pk, "판매중지");
      }else if(checked === '판매중지'){
        updateProductState(pk, "판매중");
      }else{
        alert('판매상태 변경이 불가능한 상품입니다.');
      }
      window.location.reload();
    }
  }

  return (
    <>
      <label className={`toggle ${isChecked ? 'checked' : ''}`}>
        <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
        <span className={disabledToggle ? 'toggle-slider disabled' : 'toggle-slider'}></span>
      </label>
      {/* <br/>{isChecked ? <span className="text-hw fw-bold">판매중</span> : <span className="text-grey">판매중지</span>} */}
      <br/><span className={checked === "판매중" ? "text-hw fs-8 fw-bold" : checked === "판매중지" ? "text-grey fs-8 fw-bold" : checked === "품절" ? "text-danger fs-8 fw-bold" : "text-secondary fs-8 fw-bold"}>{checked}</span>
    </>
  );
}