/***************************************************************************************
* FileName      : CouponList.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 리스트 , 등록 선택 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import CouponView from "./Coupon/CouponView";
import CouponForm from "./Coupon/CouponForm";
import CouponHistory from './Coupon/CouponHistory';
import React, { useState } from 'react';

function CouponList()
{
    const [activeComponent, setActiveComponent] = useState("CouponView");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };

    return(
        <>
        <div className='mainContents'>
            {/*  할인쿠폰  */}
            <div className='page-tit'>
                <h2>할인쿠폰</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "CouponView" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("CouponView")}>쿠폰리스트</button>

                <button className={activeComponent === "CouponForm" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("CouponForm")}>쿠폰등록</button>

                <button className={activeComponent === "CouponHistory" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("CouponHistory")}>발행/사용내역</button>
          
            </div>
            {/* 쿠폰리스트 */}
            {activeComponent === "CouponView" && <CouponView />}
            
            {/* 쿠폰등록 */}
            {activeComponent === "CouponForm" && <CouponForm />}

            {/* 발행/사용내역 */}
            {activeComponent === "CouponHistory" && <CouponHistory />}

        </div>

        </>
    )
}

export default CouponList