
import {useNavigate , useLocation  } from 'react-router-dom'
import React, { useRef, useEffect , useState } from 'react';
import { Link } from 'react-router-dom';

function MNav()
{

    const location = useLocation();
    const currentPath = location.pathname;

	let navigate = useNavigate();


    const [openIndex, setOpenIndex] = useState(-1);
    const menuItems = [
        { title: '회원관리', 
            contents: [
                {title: '회원관리', link: '/admin/member/management/'}
            ] 
        },
        { title: '셀러관리', 
            contents: [
                {title: '신규요청', link: '/admin/store/request'},
                {title: '매장조회', link: '/admin/store/inquiry'},
                {title: '매장등록', link: '/admin/store/registration'},
                {title : '활동기록', link: '/admin/store/history'}
            ] 
        },
        { title: '상품관리', 
            contents: [
                {title: '신규요청', link: '/admin/product/request'}, 
                {title: '상품조회', link: '/admin/product/check'}, 
                {title: '상품등록', link: '/admin/product/register'}, 
                {title: '카테고리/필터링', link: '/admin/product/category'},
                {title: '활동기록', link: '/admin/product/record'},
            ] 
        },
        { title: '주문배송', 
            contents: [
                {title: '결제내역', link: '/admin/order/outstanding'},
                {title: '주문조회', link: '/admin/order/inquire'}, 
                {title: '배송현황', link: '/admin/order/deliverystatus'}, 
                {title: '구매확정 내역', link: '/admin/order/purchaseconfirmation'}, 
                {title: '반품/취소', link: '/admin/order/cancel'},

        ] 
        },
        { title: '고객관리', 
            contents: [
                {title: '고객센터', link: '/admin/customer/center'}, 
                {title: '상품문의', link: '/admin/customer/productInquiry'}
            ] 
        },
        { title: '게시판', 
            contents: [
                {title: '공지사항', link: '/admin/board/notice'}, 
                {title: '자주묻는질문', link: '/admin/board/faq'}, 
                {title: '상품후기', link: '/admin/board/review'}, 
            ] 
        },
        { title: '정산', 
            contents: [
                {title: '정산현황', link: '/admin/settle/settlement'}, 
                {title: '지급내역', link: '/admin/settle/payment'}, 
                {title: '미지급내역', link: '/admin/settle/unpaid'}, 
                {title: '매출내역', link: '/admin/settle/SalesHistory'}, 
                {title: '부가세내역', link: '/admin/settle/TaxHistory'},
                {title: '세금계산서내역', link: '/admin/settle/invoicehistory'},
            ] 
        },
        { title: '마케팅', 
            contents: [
                {title: '배너/팝업', link: '/admin/marketing/bannercard'}, 
                {title: '할인쿠폰', link: '/admin/marketing/couponlist'}, 
                {title: '마일리지', link: '/admin/marketing/mileagehistory'}, 
                {title: '제철상품', link: '/admin/marketing/seasonalproduct'}
            ] 
        },
        { title: '통계', 
            contents: [
                {title: '요약통계', link: '/admin/statistics/summaryStats'}, 
                {title: '방문통계', link: '/admin/statistics/visitStats'}, 
                {title: '가입통계', link: '/admin/statistics/signupStats'}, 
                {title: '판매통계', link: '/admin/statistics/produceStats'},
                {title: '상품통계', link: '/admin/statistics/productStats'},
                {title: '검색통계', link: '/admin/statistics/searchStats'},
            ] 
        },
        { title: '알림', 
            contents: [
                {title: '메일발송관리', link: '/admin/alarm/mailsendinghistory'}, 
                {title: '알림톡발송관리', link: '/admin/alarm/alimTalkSendingHistory'}, 
                {title: '푸시알림', link: '/admin/alarm/pushNotificationHistory'}, 
            ] 
        },
        { title: '설정', 
            contents: [
                {title: '자동승인설정', link: '/admin/setting/autoApprove'}, 
                {title: '운영방침설정', link: '/admin/setting/policySettings'}, 
                {title: '관리자설정', link: '/admin/setting/adminSettings'},
                {title: '배송달력', link: '/admin/setting/deliveryCalendar'},
            ] 
        },
      ];
  
    const handleClick = (index) => {
      if (openIndex === index) {
        setOpenIndex(-1);
      } else {
        setOpenIndex(index);
      }
    };
    console.log(location.pathname)
    return (
        <> 
		<nav className='Mnav'>
            {menuItems.map((item, index) => (
                <div className="category-wrap" key={index}>
                    <div onClick={() => handleClick(index)}>
                        <h2>{item.title}</h2>
                        {openIndex === index ? 
                            <i className='xi-angle-up'></i> : <i className='xi-angle-down'></i>
                        }
                    </div>
                    {openIndex === index && (
                    <ul>
                        {item.contents.map((content, subIndex) => (
                            <li 
                            className={location.pathname === content.link ? 'active' : ''}
                            key={subIndex}>
                                <Link to={content.link}>{content.title}</Link>
                            </li>
                        ))}
                    </ul>
                    )}
                </div>
            ))}
             
	
		</nav>
        </>
    )

}

export default MNav