import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { instance } from '../../axiosInstance/axiosConfig';
import { setCookie } from '../cookieUtils';

// 입점사 로그인 정보 확인
const getVendorMe = async (access) => {
  return instance.get('/vendor/my',
  {
    headers: {
      'Authorization': `Bearer ${access}`,
      'Content-Type': 'application/json',
  },
  })
};


function useLogin2() {
  const navigate = useNavigate();

  const mutation = useMutation(
    async ({ username, password }) => {

      const response = await instance.post(`auth/login/`, { username, password });
      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log(data.access);
        console.log("--로그인됨--");
        if (process.env.REACT_APP_ENV_NAME === 'dev') {
          setCookie("access", data.access);
          setCookie("refresh", data.refresh);
        }
        
        getVendorMe(data.access)
            .then(res => {
              res?.status === 200 ? window.location.href='/selleradmin/main' : window.location.href='/selleradmin/wait'
            })
            .catch(error => window.location.href='/selleradmin/wait')
      },
      onError: (error) => {
        alert("로그인에러 : " + error.response.data.data);
      },
    }
  );

  return mutation;
}

export default useLogin2;
