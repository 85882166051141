/***************************************************************************************
* FileName      : Category.js
* Description   : 해와관리자 > 통계 > 상품통계 > 카테고리별 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.20
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BarChart from '../../../components/BarChart.js'

import SearchField from "../Component/ProductStats/SearchField.js";

import { getCategorySales , handleSearchCategorySales } from "../../../../api/hwadmin/statisticApi.js";


function Category()
{

    const [isUpdated, setIsUpdated] = useState(false);
    
    const [searchResults, setSearchResults] = useState([]);

    const { isLoading, error, data } = useQuery(["CategorySales"], () => getCategorySales());

    const [chartData,setChartData] = useState([]);

    
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };


    
   
    // 단위(결제완료기준)
    const [selectedPayment , setSelectedPayment ] = useState('판매금액(원)');


    // 단위(결제완료기준) 값 변화
    const handleCategoryTypeRadioChange = (event) => {
        setSelectedPayment(event.target.value);
    };
   

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/productStats/';
    };
    
    // 검색버튼 클릭 시 
    const onSearch = async  () => {
        await handleSearchCategorySales({
            setSearchResults,
            selectedStartDate,
            selectedEndDate
        });
    };

    useEffect(() => {
        if (searchResults && searchResults.category && searchResults.category.length > 0) {
            updateChartData(searchResults);
        } 
    },[searchResults]);



    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.category,
                datasets: [
                    {
                        label: selectedPayment,
                        data: data?.sales_price,
                        borderColor: '#F6B6C1',
                        backgroundColor: '#F6B6C1',
                    },
                
                ]
            };

            setChartData(newChartData);
        }
    },[data])

    // 검색 후 chart 생성
    function updateChartData(searchResults)
    {
        const dataFieldMap = {
            '판매금액(원)': 'sales_price',
            '판매건수(건)': 'sales_count',
            '승인된 상품(건)': 'approved_products_count',
            '판매중 상품(건)': 'on_sale_products_count',
        };
        const dataField = dataFieldMap[selectedPayment]; 
 

        const newChartData = {
            labels: searchResults?.category,
            datasets: [
                {
                    label: selectedPayment,
                    data: searchResults?.[dataField],
                    borderColor: '#F6B6C1',
                    backgroundColor: '#F6B6C1',
                },
            
            ]
        };

        setChartData(newChartData);
        setIsUpdated(true);
    
    }

    return (
        <>
          {/* 검색 */}
          <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field date'>
                    <div> <b>날짜</b> </div>
                    <div>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        ~
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                    </div>
                </div>          

                <SearchField 
                    selectedPayment={selectedPayment} 
                    handleCategoryTypeRadioChange={handleCategoryTypeRadioChange} />
              
            </div>

            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={handleResetClick}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
        </section>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <BarChart data={chartData}/>
        </section>
      
    
    </>
    )
}

export default Category