/***************************************************************************************
* FileName      : FaqRow.js
* Description   : 해와관리자 > 게시판 > 자주묻는질문 FaqRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';
import { updateFaqHidden } from '../../../../api/hwadmin/boardApi';


export const FaqRow  =  ({ faq, index, selectedRows, setSelectedRows }) => {
  const navigate = useNavigate();

  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/member/management/?search=${searchQuery}`;
    navigate(newUrl);
  };

  const handleEditClick = (id) => {
    const editUrl = `/admin/board/editFaq/${id}`;
    window.location.href = editUrl;
  };

  return(
    <tr
      key={faq.id}
      style={{
        backgroundColor: selectedRows[faq.id] ? "#f7f7f6" : "transparent"
      }}
    >
      <td className="sticky-col-1">
        <input
          type="checkbox"
          name=""
          value=""
          onClick={() =>
            setSelectedRows({
              ...selectedRows,
              [faq.id]: !selectedRows[faq.id]
            })
          }
        />
      </td>
      <td className="sticky-col-2">{index + 1}</td>
      <td className="sticky-col-3">{faq.menu}</td>
      <td className="sticky-col-4">
        {faq.title}
      </td>

      <td className='blueLink' onClick={() => handleCellClick(faq.writer)}>
        {faq.writer}
      </td>

      <td>
        {faq.target}
      </td>
      <td>
        {extractYearMonthDayHourMinute2(faq.created_at)}
      </td>
        
      <td>{faq.view_count}</td>
      <td>
        <ToggleSwitch pk={faq?.id} checked={faq?.hidden}/> <br/>
        {/* {faq.hidden ? '공개' : '비공개'} */}
      </td>

      <td>
        <button className='t-s-btn'  onClick={() => handleEditClick(faq?.id)}>수정</button>
      </td>
  
  </tr>
  )
}



export default function ToggleSwitch({pk, checked}) {
  const [isChecked, setIsChecked] = useState(!checked); // isChecked를 checked로 초기화

  const handleToggle = () => {
    setIsChecked(!isChecked);
    updateFaqHidden(pk, isChecked); // 업데이트 함수에 isChecked 값을 전달
  }

  return (
    <>
      <label className={`toggle ${isChecked ? 'checked' : ''}`}>
        <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
        <span className="toggle-slider round"></span>
      </label>
      <br/>{isChecked ? '공개' : '비공개'}
    </>
  );
}