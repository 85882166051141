import {Row, Col} from 'react-bootstrap';
import { useState } from 'react';


export default function TabComponent({tabs , setTab }){
    const [selectedTab, setSelectedTab] = useState(tabs[0].label);
   
    const handleTabClick = (tabLabel) => {
        setSelectedTab(tabLabel);

        if(tabLabel==="전체") setTab('')
        else setTab(tabLabel)
    };

    return (
        <>
        <Row className="text-center mt-4 mb-4 m-0 p-0">
            {tabs.map((tab) => (
                <Col
                    key={tab.label}
                    className={`${selectedTab === tab.label ? 'select' : ''} faqTab`}
                    onClick={() => handleTabClick(tab.label)}
                >
                    {tab.label}
                </Col>
            ))}
        </Row>
           
        </>
    );
}

