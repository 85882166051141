// 상품수정페이지 > 가공식품 상품정보제공고시 

import React, { useState , useEffect } from 'react';

function ProcessedfoodInfo({ processedfoodInfo,setProcessedfoodInfo })
{
    // 1. 품목 또는 명칭
    const [name, setName] = useState(processedfoodInfo.name || '');

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            name: newName
        }));
    };

    // 2. 식품의 유형
    const [foodtype, setFoodtype] = useState(processedfoodInfo.foodtype || '');

    const handleFoodtypeChange = (e) => {
        const newFoodtype = e.target.value;
        setFoodtype(newFoodtype);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            foodtype: newFoodtype
        }));
    };

    // 3. 생산자 및 소재지
    const [manufacturer, setManufacturer] = useState(processedfoodInfo.manufacturer || '');

    const handleManufacturerChange = (e) => {
        const newManufacturer = e.target.value;
        setManufacturer(newManufacturer);

        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            manufacturer: newManufacturer
        }));
    };

   
   // 4. 제조연월일, 소비기한 또는 품질유지기한 항목
    const [manufacturedDate, setManufacturedDate] = useState(processedfoodInfo.manufactured_date || '');

    const handleManufacturedDateChange = (e) => {
        const newManufacturedDate = e.target.value;
        setManufacturedDate(newManufacturedDate);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            manufactured_date: newManufacturedDate
        }));
    };

    // 5. 용량, 수량, 크기 항목
    const [volume, setVolume] = useState(processedfoodInfo.volume || '');

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            volume: newVolume
        }));
    };

    // 6. 원재료명 항목
    const [material, setMaterial] = useState(processedfoodInfo.material || '');

    const handleMaterialChange = (e) => {
        const newMaterial = e.target.value;
        setMaterial(newMaterial);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            material: newMaterial
        }));
    };

    // 7. 영양성분 항목
    const [nutrition, setNutrition] = useState(processedfoodInfo.nutrition || '');

    const handleNutritionChange = (e) => {
        const newNutrition = e.target.value;
        setNutrition(newNutrition);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            nutrition: newNutrition
        }));
    };

    // 8. 유전자 변형 수산물 항목
    const [markDetail, setMarkDetail] = useState(processedfoodInfo.mark_detail || '');

    const handleMarkDetailChange = (e) => {
        const newMarkDetailDate = e.target.value;
        setMarkDetail(newMarkDetailDate);

        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData, 
            mark_detail: newMarkDetailDate
        }));
    }


    // 9. 소비자 안전을 위한 주의사항 항목
    const [caution, setCaution] = useState(processedfoodInfo.caution || '');

    const handleCautionChange = (e) => {
        const newCaution = e.target.value;
        setCaution(newCaution);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            caution: newCaution
        }));
    };

    // 10. 수입식품 여부에 따라 수입신고 항목
    const [imported, setImported] = useState(processedfoodInfo.imported || '');

    const handleImportedChange = (e) => {
        const newImported = e.target.value;
        setImported(newImported);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            imported: newImported
        }));
    };

    // 11. 소비자 상담 관련 전화번호 항목
    const [cs, setCs] = useState(processedfoodInfo.cs || '');

    const handleCsChange = (e) => {
        const newCs = e.target.value;
        setCs(newCs);
        setProcessedfoodInfo((prevProcessedfoodInfoData) => ({
            ...prevProcessedfoodInfoData,
            cs: newCs
        }));
    };
       
    return (
        <>
        <div className='Table_wrap'>
            <table className="sTable">
                <colgroup>
                            
                </colgroup>
                <thead>
                    <tr>
                        <th>고시정보명</th>
                        <th>내용</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>제품명</td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    value={name}
                                    onChange={handleNameChange}
                                    placeholder="직접 입력해주세요."
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference" 
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleNameChange(e); 
                                            } else {
                                                handleNameChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            식품의 유형
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={foodtype}
                                    onChange={handleFoodtypeChange}>        
                            </textarea>

                            <label>
                                <input type="checkbox" 
                                        name="product_reference"
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleFoodtypeChange(e); 
                                            } else {
                                                handleFoodtypeChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        
                                        />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            생산자 및 소재지
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    value={manufacturer}
                                    onChange={handleManufacturerChange }   
                                    placeholder="직접 입력해주세요."
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference" 
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleManufacturerChange(e); 
                                            } else {
                                                handleManufacturerChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            제조연월일, 소비기한 또는 품질유지기한
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={manufacturedDate}
                                    onChange={handleManufacturedDateChange}   
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference"
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleManufacturedDateChange(e); 
                                            } else {
                                                handleManufacturedDateChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            포장단위별 내용물의 용량(중량),수량
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={volume}
                                    onChange={handleVolumeChange}   
                            ></textarea>
                            <label>
                                <input type="checkbox"
                                    name="product_reference" 
                                    value="상품 상세페이지 참조" 
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleVolumeChange(e); 
                                        } else {
                                            handleVolumeChange({ target: { value: '' } }); 
                                        }
                                    }}
                                    />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            원재료명 
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={material}
                                    onChange={handleMaterialChange}   
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                    name="product_reference" 
                                    value="상품 상세페이지 참조" 
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleMaterialChange(e); 
                                        } else {
                                            handleMaterialChange({ target: { value: '' } }); 
                                        }
                                    }} />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            영양성분 
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={nutrition}
                                    onChange={handleNutritionChange}         
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference" 
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleNutritionChange(e); 
                                            } else {
                                                handleNutritionChange({ target: { value: '' } }); 
                                            }
                                        }} />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            유전자변형식품에 해당하는 경우의 표시
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={markDetail}
                                    onChange={handleMarkDetailChange} 
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference"
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleMarkDetailChange(e); 
                                            } else {
                                                handleMarkDetailChange({ target: { value: '' } }); 
                                            }
                                        }}
                                    />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            소비자 안전을 위한 주의사항
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={caution}
                                    onChange={handleCautionChange}   
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference"
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleCautionChange(e); 
                                            } else {
                                                handleCautionChange({ target: { value: '' } }); 
                                            }
                                        }}
                                    />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        수입식품의 경우 “수입식품안전관리 특별법에 따른 수입신고를 필함”의 문구
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={imported}
                                    onChange={handleImportedChange}   
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference" 
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleImportedChange(e); 
                                            } else {
                                                handleImportedChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            소비자상담 관련 전화번호
                        </td>
                        <td className="td-flex">
                            <textarea className="textarea-box"
                                    placeholder="직접 입력해주세요."
                                    value={cs}
                                    onChange={handleCsChange}   
                            ></textarea>
                            <label>
                                <input type="checkbox" 
                                        name="product_reference"
                                        value="상품 상세페이지 참조" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleCsChange(e); 
                                            } else {
                                                handleCsChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        />
                                상품 상세페이지 참조
                            </label>
                        </td>
                    </tr>
                </tbody>

            </table>     
            
        </div>
        </>
    );
}

export default ProcessedfoodInfo