/***************************************************************************************
* FileName      : OrderRow2.js
* Description   : 고객 > 마이페이지 > 반품/취소내역 > 주문상세내역 > OrderRow 컴포넌트 (취소 , 반품 함께 사용)
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.15
* Modifide Date : 2024.01.22 : CHM : 배송조회 모달 추가
*               : 2024.01.29 : CHM : 배송조회 모달 API 연동
* Reference     : 
***************************************************************************************/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';
import DeliveryModal from '../Component/DeliveryModal.js';

function OrderRow2({deliveryItem, orderItem})
{
    const navigate = useNavigate();

    
    const handleClick = (status , orderId) => {
        if(status==="반품" && orderId ){
            navigate(`/mypage/order/${orderId}/return`);
        }
    };

    console.log(orderItem.is_returnable);

    console.log("deliveryItem")
    console.log(deliveryItem);
    // 2024.01.22 : CHM : 배송조회 모달 추가
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
        <td >
            <div className='myorder_piwrap'>
                <a className='thum' href=''>
                    <img
                        src={deliveryItem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </a>
                <div className='pn'>
                    <a>
                        {/* <span className='vendortxt'>{deliveryItem.vendor_name}</span> */}
                        <span className='pdtxt'>{orderItem.order_item}</span>
                     
                        {/* 옵션 */}
                        {deliveryItem.orderitem_option_rows && deliveryItem.orderitem_option_rows.length > 0 && (
                        <>
                            <span className='option_list2'>
                                <i className='optiontxt'>옵션</i>
                                <div>
                                    {deliveryItem.orderitem_option_rows.map((option, index) => (
                                        <p key={index}>
                                            {option.name}
                                        </p>
                                    ))}
                                </div>
                            </span>
                        </>
                        )}

                        {/* 추가상품 */}
                        {deliveryItem.orderitem_extra_options && deliveryItem.orderitem_extra_options.length > 0 && (
                        <>
                            <span className='option_list2'> 
                                <i className='optiontxt'>추가상품</i>
                                <div>
                                {deliveryItem.orderitem_extra_options.map((extra_option, index) => (
                                    <p key={index}>
                                        {extra_option.name}
                                    </p>
                                ))}
                                 </div>
                            </span>
                        </>
                        )}
                        
                        {deliveryItem?.confirmation_status === "반품" && (
                            <p className='claim_reason'>반품사유 : {deliveryItem?.returns?.reason}</p>
                        )} 
                     
                        {deliveryItem?.cancel_reason && (
                             <p className='claim_reason'>취소사유 : {deliveryItem?.cancel_reason}</p>
                        )}

                    </a>
                </div>
           
            </div>

        </td>
        <td className='lbe6'>
            {(orderItem?.sale_price ?? 0).toLocaleString()}원
        </td>

        <td className='lbe6'>
            {orderItem?.quantity?.toLocaleString() ?? "0"}
        </td>

        <td className='lbe6'>
            {deliveryItem?.order?.total_delivery_fee?.toLocaleString() ?? "0"}원
        </td>

        <td className='status_td lbe6'>
            <p>
                {deliveryItem?.confirmation_status} <br/>
                {deliveryItem?.returns?.status}
            </p>
            <button className='mpbtn1 mt-2' onClick={()=>setModalIsOpen(true)}>배송조회</button>
        </td>
    
        {/* 2024.01.22 : CHM : 배송조회 모달 추가 */}
        <DeliveryModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} id={orderItem?.id}/>
        </>
    )
}

export default OrderRow2