/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 해와관리자 > 주문배송 > 구매확정 내역 OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils";

export const OrderRow = ({ order, index, selectedRows, setSelectedRows, selectedRowsData, setSelectedRowsData }) => {
  
  const navigate = useNavigate();
  
  return(
    <tr key={order?.id}>
      <td>  
        <input
          type="checkbox"/>
      </td>
      <td>{order?.order_number}</td>
      <td>{order?.vendor_name}</td>
      <td>{extractYearMonthDayHourMinute2(order?.confirmed_at)}</td>
      <td></td>
      <td>
        {order?.orderer_username} <br/>
        {order?.orderer_name}
      </td>
      <td>
        {order?.recipient_name} <br/>
        {order?.recipient_phone_number}
      </td>
      <td>{order?.delivery_type}</td>
      <td>{order?.courier}</td>
      <td>{order?.tracking_number}</td>
      <td>{order?.arrived_at && extractYearMonthDayHourMinute2(order?.arrived_at)}</td>
      <td>
        {order?.product_id} <br/>
        {order?.product_name}
      </td>
      <td>
        {order?.option_rows?.map((option, index) => (
          <span key={index}>{option.order_item}</span>
        ))}
      </td>
      <td>{order?.settlement_amount != null ? order.settlement_amount.toLocaleString() : ''}</td>
    </tr>
  )

};