/***************************************************************************************
* FileName      : Sale.js 
* Description   : 셀러관리자 > 정산 > 매출내역 > 상품판매 기준 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.05
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';

import { useAdjustSummary2DocumentQuery  , handleAdjustRevenueSearch } from '../../../../api/selleradmin/adjustApi';

import SortComponent from '../../../admin/common/SortComponent2';
import PageSizeSelect from '../../../admin/common/PageSizeSelect';

import { AdjustRow } from './Sale/AdjustRow';
import Pagination from '../../common/Pagination'

function Sale()
{
    const [ordering, setOrdering] = useState('-sale_date');
    const [pageSize, setPageSize] = useState(10); 

    const { data, page, setPage } = useAdjustSummary2DocumentQuery(ordering,pageSize);
    

    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    console.log(data)
    console.log("데이터")


        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
 
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
     
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
 
     const onSearch = () => {
        handleAdjustRevenueSearch({
             setSearchResults,
             setSearchUrl,
             selectedStartDate,
             selectedEndDate, 
         });
 
         setSearchConfirm(true);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

     // 초기화
     const handleResetClick = () => {
        window.location.href = '/selleradmin/settlement/taxHistory'; 
    };

    return (
        <>
        <span className="nt">해당날짜에 판매된 상품의 매출 내역입니다.</span>

        {/* 검색 */}
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                
                <div className='search-field date'>
                    <div> 
                        <b>상품판매일</b> 
                    </div>
                    <div>
                        
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />    
                    </div>   
                </div>
        
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
        </section>


        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className='resultsCount-wrap'>
                    <span>검색결과</span>
                    <span className='result-wrap'>
                        {searchResults.totalCnt > 0 ? (
                            <>
                                <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                            </>
                            ) : (
                            <>
                                <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                            </>
                        )}
                    </span>
                </div>
                <div className='selectWrap'>
                    <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="최근날짜순"
                            oldLabel="오래된날짜순"
                        />

                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                </div>
            </div>   
        
            <div className='m-btn-wrap-sm'>
                <button className='m-sm-btn'>다운로드</button>
            </div>

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>상품판매일</th>
                            <th>매출</th>
                            <th>수수료(9.9%)</th>
                            <th>
                                정산금액 <br/>
                                (결제금액 - 수수료)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    {searchConfirm && searchResults?.count === 0 ? (
                        <tr>
                            <td colSpan="17">검색 결과가 없습니다.</td>
                        </tr>
                    ) : searchResults?.count > 0 ? (
                        searchResults?.data.map((Adjust , index) => (
                            <AdjustRow 
                                index={index} 
                                Adjust={Adjust}/>
                        
                        ))
                    ) : data && data?.data && data?.data.length > 0 ? (
                        data?.data.map((Adjust , index ) => (
                            <AdjustRow 
                                index={index} 
                                Adjust={Adjust}/>
                        
                        ))
                    ) : null}

                </tbody>
                </table>
            </div>
        </section>
    
        {/* 페이지내비게이션 */}
        <section>
            {searchConfirm && searchResults?.count === 0 ? (
                <>
                </>
            ) : searchResults?.count > 0 ? (
                    <Pagination
                        count={searchResults?.count}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                />
            ) : (

                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                />
            )}
        </section>
    </>
    )
}

export default Sale