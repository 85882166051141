/***************************************************************************************
* FileName      : OrderRow2.js
* Description   : 고객 > 마이페이지 > 주문배송조회 > 주문상세내역 > OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.15
* Modifide Date : 2024.01.22 : CHM : 배송조회 모달 추가
*               : 2024.01.29 : CHM : 배송조회 모달 API 연동
* Reference     : 
***************************************************************************************/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';

import { OrderPurchaseConfirm } from '../../../api/services/mypageApi.js';
import { useMutation } from 'react-query';

import DeliveryModal from '../Component/DeliveryModal.js';

function OrderRow2({index , deliveryItem , orderItem , deliveryItem_length})
{
    const navigate = useNavigate();

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        const total = orderItem.orderitem_option_rows.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(total);
    }, [orderItem]);
    
    const handleClick = (status , orderId ,cancelStatus) => {
        if(status==="반품" && orderId ){
            navigate(`/mypage/order/${orderId}/return`);
        }
        if(status==="부분취소" && orderId && cancelStatus === 0){
            navigate(`/mypage/order/${orderId}/itemcancel/0`);
        }
        if(status==="부분취소" && orderId && cancelStatus === 1){
            navigate(`/mypage/order/${orderId}/itemcancel/1`);
        }  
    };

    // 구매확정
    const createMutaion = useMutation(OrderPurchaseConfirm , {
        onSuccess : (data) => {
            alert("구매확정이 완료 되었습니다");
        },
        onError : (data) => {
            console.log("error", data);
        }
    });


    // 구매확정 버튼 클릭시
    const handlePurchaseConfirmClick = (orderId) => {
        const userConfirmed = window.confirm('상품을 받으셨나요?\n구매확정하신 뒤에는 반품/교환 신청하실 수 없습니다.');

          if (userConfirmed) {
            const data = {
                order_item: orderId || "",
            };
    
            createMutaion.mutate(data);
          } 

    }

    // 2024.01.22 : CHM : 배송조회 모달 추가
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // console.log("---deliveryItem---")
    // console.log(orderItem)
    // console.log(index);

    // console.log(deliveryItem_length);
    // console.log("-deliveryItem_length갯수")
    // 배송비 쿠폰이면 0원?
    // 배송비 쿠폰 확인해서 사용했으면 -  그 값 나오게 해야됨 
    // 
    // 선불인지, ? 

    // console.log(orderItem.is_returnable);

    console.log(deliveryItem.cancel_request_available);
    console.log("----");
    return (
        <>
        <td>
            <div className='myorder_piwrap cursor-pointer'   
                onClick={()=>window.location.href=`/product/detail/${orderItem.product}`}>
                <a className='thum' href=''>
                    <img
                        src={orderItem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </a>
                <div className='pn'>
                    <a>
                        <span className='vendortxt'>{orderItem.vendor_name}</span>
                        <span className='pdtxt'>{orderItem.product_name}</span>
            
                        {/* 옵션 */}
                        {orderItem.orderitem_option_rows && orderItem.orderitem_option_rows.length > 0 && (
                        <>
                            <span className='option_list2'>
                                <i className='optiontxt'>옵션</i>
                                <div>
                                    {orderItem.orderitem_option_rows.map((option, index) => (
                                        <p key={index}>
                                            {option.name} [{option.total_price.toLocaleString()}원 
                                            / 수량: {option.quantity}]  
                                        </p>
                                    ))}
                                </div>
                            </span>
                        </>
                        )}

                        {/* 추가상품 */}
                        {orderItem.orderitem_extra_options && orderItem.orderitem_extra_options.length > 0 && (
                        <>
                            <span className='option_list2'> 
                                <i className='optiontxt'>추가상품</i>
                                <div>
                                {orderItem.orderitem_extra_options.map((extra_option, index) => (
                                    <p key={index}>
                                        {extra_option.name} [{extra_option.total_price.toLocaleString()}원 / 수량 : {extra_option.quantity} ]  
                                    </p>
                                ))}
                                 </div>
                            </span>
                        </>
                        )}


                    </a>
                </div>
           
            </div>

        </td>
        <td className='lbe6'>
            {/* <p className='org_price'>{orderItem?.original_total_price.toLocaleString()} 원</p> */}
            <p className=''>{orderItem?.original_total_price.toLocaleString()}원</p>

        </td>
        {/* <td className='lbe6'>
            {totalQuantity}
        </td> */}
        {/* <td className='lbe6'>
            {orderItem && orderItem.original_total_price ? 
                orderItem?.original_total_price.toLocaleString(): "0"}원
        </td> */}

        {/* 배송비 */}
        {index === 0 && deliveryItem_length >= 1 && (
        <>
          <td className='lbe6' rowSpan={deliveryItem_length}>
            <p>{deliveryItem.delivery_fee.toLocaleString()}원</p>
            
          

            {deliveryItem.discount_price > 0 && (
                <>
                <p className='org_price'>{deliveryItem.original_delivery_fee.toLocaleString()}원</p>
                <p className='c713'>{deliveryItem.discount_price.toLocaleString()}원 할인</p>
                </>
            )}
            <p className='c713'>({deliveryItem.type})</p>
          </td>
        </>
        )}

        <td className='status_td lbe6'>
            <p>
            {
                orderItem?.cancels.status ? "취소요청" 
                :
                    orderItem?.confirmation_status === "취소" ||  orderItem?.confirmation_status === "반품"
                    ?
                        orderItem?.confirmation_status
                    :
                        orderItem?.status === "배송완료" 
                        ? orderItem?.confirmation_status
                        : orderItem?.status
            }
            </p>

            {orderItem?.is_trackable &&
                <button className='mpbtn1 mt-2' onClick={()=>setModalIsOpen(true)}>배송조회</button>
            }
            
            {orderItem.is_returnable &&
                <button className='mpbtn1 mt-1' 
                    onClick={()=>{handleClick("반품" , orderItem.id ,0)}}>반품신청</button>
            }

            {orderItem?.confirmation_status === "배송완료" && 
                <button className='mpbtn1 mt-1' 
                    onClick={()=>{handlePurchaseConfirmClick(orderItem.id)}}>구매확정</button>
            }
                 
                 
            {/* 상품준비중일때 부분취소 */}
            {deliveryItem.cancel_request_available && (
                <button
                    className='mpbtn1 mt-2'
                    onClick={() => { handleClick("부분취소", deliveryItem.id ,1) }}>
                    부분취소 요청
                </button>
            )}

            {/* 결제완료일때 부분취소 */}
            {deliveryItem.is_cancelable && (
                <button
                    className='mpbtn1 mt-2'
                    onClick={() => { handleClick("부분취소", deliveryItem.id ,0) }}>
                    부분취소
                </button>
            )}
        </td>

        {/* 2024.01.22 : CHM : 배송조회 모달 추가 */}
        <DeliveryModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} id={orderItem?.id}/>
        </>
    )
}

export default OrderRow2