/***************************************************************************************
* FileName      : Benefits.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 > 쿠폰등록 > 혜택설정 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useRef, useEffect , useState } from 'react';
import SelectVendor from "../../../product/Component/SelectVendor"
import { addCommasToNumberString } from '../../../common/numberUtils';

export default function Benefits({couponInfoData ,setCouponInfoData}){

    const updateCouponInfoField = (fieldName, newValue) => {
        setCouponInfoData((prevData) => ({
          ...prevData,
          [fieldName]: newValue
        }));    
    };


    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    // 혜택구분
    const [discountType, setDiscountType] = useState('할인금액형');

    const handleDiscountTypeMethod = (event) => {
        const value = event.target.value;
        setDiscountType(value);

        if(value === "할인율형") {
            setDiscountAmount('');
            updateCouponInfoField("discount_amount", 0);
        }else{
            setDiscountRate('');
            setLimitDiscountAmount('');

            updateCouponInfoField("discount_rate", 0);
            updateCouponInfoField("limit_discount_amount", 0);
        }

        updateCouponInfoField("discount_type", value);

    };

    // 혜택구분 > 할인금액형 > 할인금액 입력
    const [discountAmount, setDiscountAmount] = useState('');

    const handleDiscountAmountMethod = (e) => {
        const newData = e.target.value;
        const DiscountAmount = newData.replace(/,/g, ''); // 콤마 제거
        setDiscountAmount(DiscountAmount);

        updateCouponInfoField("discount_amount", parseInt(DiscountAmount, 10));
    }

    // 혜택구분 > 할인율형 > 할인율 % 입력
    const [discountRate, setDiscountRate] = useState('');

    const handleDiscountRateMethod = (e) => {
        const newData = e.target.value;
        const DiscountRate = newData.replace(/,/g, ''); 
        setDiscountRate(DiscountRate);
     
        updateCouponInfoField("discount_rate",  parseInt(DiscountRate, 10));
    }

    
    // 혜택구분 > 할인율형 > 최대 할인금액
    const [limitDiscountAmount, setLimitDiscountAmount] = useState('');

    const handleLimitDiscountAmountMethod = (e) => {
        const newData = e.target.value;
        const Amount = newData.replace(/,/g, ''); // 콤마 제거
        setLimitDiscountAmount(Amount);

        updateCouponInfoField("limit_discount_amount", parseInt(Amount, 10));
       
    }

    // 최소주문금액
    const [useCondition, setUseCondition] = useState('');
        
    const handleUseConditionMethod = (e) => {
        const newData = e.target.value;
        const UseCondition = newData.replace(/,/g, ''); // 콤마 제거
        setUseCondition(UseCondition);

        updateCouponInfoField("use_condition", parseInt(UseCondition, 10));
    }

    // 혜택 설정 > 유효기간 
    const [expirationType , setExpirationType] = useState('일정선택형');
    const [validityPeriodStart , setValidityPeriodStart ] = useState('');
    const [validityPeriodEnd, setValidityPeriodEnd] = useState('');

    const handleExpirationTypeMethod = (event) => {
        const value = event.target.value;
        setExpirationType(value);

        if(value==='일정선택형'){
            setExpirationDate('');
            updateCouponInfoField("expiration_date", 0);
        }else{
            setValidityPeriodStart('');
            setValidityPeriodEnd('');

            updateCouponInfoField("validity_period_start", null);
            updateCouponInfoField("validity_period_end", null);
        }

        updateCouponInfoField("expiration_type", value);

    };

    // 유효 기간(시작) 입력 값 변경 시 호출되는 함수
    const handleValidityPeriodStartInputChange = (e) => {
        const value = e.target.value;
        setValidityPeriodStart(value);

        updateCouponInfoField("validity_period_start", value);

    };

    // 유효 기간(끝) 입력 값 변경 시 호출되는 함수
    const handleValidityPeriodEndInputChange = (e) => {
        const value = e.target.value;
        setValidityPeriodEnd(value);

        updateCouponInfoField("validity_period_end", value);
    };

    // 혜택설정 > 유효기간 > 만료일선택형 > 발급일로부터 __일
    const [expirationDate, setExpirationDate] = useState('');

    const handleExpirationDateMethod = (e) => {
        const newData = e.target.value;
        const ExpirationDate = newData.replace(/,/g, '');  // 콤마제거
        setExpirationDate(ExpirationDate);

        updateCouponInfoField("expiration_date", parseInt(ExpirationDate, 10));
        
    }

    // 이용가능매장
    const [vendor , setVendor] = useState('false');
    const handleVendorMethod = (e) => {
        const newData = e.target.value;
        setVendor(newData);

        if(newData === 'false'){
            updateCouponInfoField("vendor", null);
        }
    }

    useEffect(()=>{
        updateCouponInfoField("vendor", selectedVendor.id);
    },[selectedVendor])

    return(
       <>
        <div className='product-info'>
                <table>                            
                    <tbody>
                        <tr>
                            <th>혜택구분</th>  
                            <td className="radioBtn-wrap-col">
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="할인금액형"
                                            checked={discountType === '할인금액형'}
                                            onChange={handleDiscountTypeMethod}
                                        />
                                        할인금액형                              
                                    </label> 
                                    
                                    <input
                                        type="text"   
                                        className='w50'
                                        value={addCommasToNumberString(discountAmount)}
                                        onChange={handleDiscountAmountMethod}
                                        disabled={discountType !== '할인금액형'}
                                        />  
                                        <span>원</span>
                                </div>
                                
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="할인율형"
                                            checked={discountType === '할인율형'}
                                            onChange={handleDiscountTypeMethod}
                                        />
                                        할인율형
                                    </label> 
                                

                                    <input 
                                        type="text" 
                                        value={addCommasToNumberString(discountRate)}
                                        onChange={handleDiscountRateMethod}
                                        disabled={discountType !== '할인율형'}
                                        />  
                                    <span>% (최대 </span>

                                    <input 
                                        type="text" 
                                        className='w50'
                                        value={addCommasToNumberString(limitDiscountAmount)}
                                        onChange={handleLimitDiscountAmountMethod}
                                        disabled={discountType !== '할인율형'}
                                        />
                                    <span>원까지)</span>
                                </div>
                             
                            </td>
                        </tr>
                        <tr>
                            <th>최소주문금액</th>  
                            <td className="radioBtn-wrap w30">
                                <input type="text" 
                                    className='w50'
                                    value={addCommasToNumberString(useCondition)}
                                    onChange={handleUseConditionMethod}/> 
                                
                                <span>원 이상 구매 시</span>
                            
                            </td>
                        </tr>
                        <tr>
                            <th>유효기간</th>  
                            <td className="radioBtn-wrap-col">
                                <div>
                                    <label>
                                        <input
                                        type="radio"
                                        value="일정선택형"
                                        checked={expirationType === '일정선택형'}
                                        onChange={handleExpirationTypeMethod}
                                        />
                                        일정선택형
                                    </label>

                                    <input
                                        type="datetime-local"
                                        className='bgray5'
                                        value={validityPeriodStart}
                                        onChange={handleValidityPeriodStartInputChange}
                                        disabled={expirationType !== '일정선택형'}
                                    />
                                    <span> ~ </span>
                                    <input
                                        type="datetime-local"
                                        className='bgray5'
                                        value={validityPeriodEnd}
                                        onChange={handleValidityPeriodEndInputChange}
                                        disabled={expirationType !== '일정선택형'}
                                    />
                                    <span>까지</span>
                                </div>
                               
                                <div>    
                                    <label>
                                        <input
                                            type="radio"
                                            value="만료일선택형"
                                            checked={expirationType === '만료일선택형'}
                                            onChange={handleExpirationTypeMethod}
                                    />
                                    만료일선택형
                                    </label>

                                    <span>(발급일로부터 </span>

                                    <input 
                                        type="text" 
                                        className='w50'
                                        value={addCommasToNumberString(expirationDate)}
                                        onChange={handleExpirationDateMethod}
                                        disabled={expirationType !== '만료일선택형'}
                                    /> 
                                    
                                    <span>일)</span>
                                </div>
                            
                            </td>
                        </tr>
                        <tr>
                            <th>이용가능매장</th>  
                            <td className="radioBtn-wrap w50">
                            <label>
                                    <input
                                    type="radio"
                                    value="false"
                                    checked={vendor === 'false'}
                                    onChange={handleVendorMethod}
                                    />
                                    제한없음
                                </label>

                                <label>
                                    <input
                                    type="radio"
                                    value="true"
                                    checked={vendor === 'true'}
                                    onChange={handleVendorMethod}
                                    />
                                제한있음 
                                </label>
                                {vendor === 'true' &&
                                <>
                                    <span>(</span>
                                    <SelectVendor 
                                        selectedVendor={selectedVendor}
                                        setSelectedVendor={setSelectedVendor}
                                        />    
                                    <span>만 사용가능)</span>
                                </>
                                }
                            </td>
                        </tr>
                    </tbody>
            </table>
            </div>
       </>
    )
}