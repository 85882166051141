import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // 차트 인스턴스를 저장할 ref

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    // 이전 차트 파괴
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(myChartRef, {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 10,
            borderHight:20,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
          },
          
        }

        },
      },
    );
    chartRef.current.style.height = `${data.labels.length * 40}px`;

    // 컴포넌트가 언마운트될 때 차트 파괴
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div>
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
};

export default BarChart;
