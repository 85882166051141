/***************************************************************************************
* FileName      : Edit.js
* Description   : 해와관리자 > 셀러관리 > 매장조회 > 매장정보수정
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { useState , useRef , useEffect } from 'react';
import fetchVendorData from '../../../api/vendor/fetchVendorData';
import { updateAdminVendorData } from '../../../api/vendor/updateVendorData';
import { verifyEmployeeId } from '../../../api/hwadmin/storeApi.js';
import { useParams } from 'react-router-dom';
import { useQuery , useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { validateField } from './validationUtils';

// 이미지등록
import { uploadImage } from '../../../api/uploadImage/uploadApi';

// 주소 모달
import AddressModal from "./AddressModal.js"

// 셀러관리 > 매장수정
function Edit()
{
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };
      
    // id 추출
    const { vendorId } = useParams();
   
    // 데이터 조회
    const { data, isError } = useQuery(['vendorData', vendorId], () => fetchVendorData(vendorId));

    // 데이터 수정
    const handleUpdate = async () => {
        if (validateForm()) {
            try {
                setIsLoading(true); 

                // 이미지 파일이 수정 되었을 시 만 이미지 업로드 함수 호출
                if(files) {            
                    await uploadImages();
                }
       
                const newData = {
                    name: name,
                    business_type: business_type,
                    business_number: business_number,
                    business_license: uploadedImages.business_license,
                    business_number: business_number,
                    address: address,
                    owner_image: uploadedImages.owner_image,
                    account_image: uploadedImages.account_image,
                    bank: bank || "",
                    account_number: account_number || "",
                    account_holder: account_holder || "",
                    phone_number: phone_number || "",
                    tel: tel || "",
                    fax: fax || "",
                    email: email || "",
                    employee_list: employeeId_list || "",
                    tax: tax || "",
                    dispatch_location : dispatchLocation || "",
                    return_address : returnAddress || "",
                    co_representative: ceo === "true", // 공동대표여부
                    sales_registration : uploadedImages.sales_registration, //통신판매신고증
                    sales_registration_number : sales_registration_number, // 통신판매등록번호
  
                };

                const updatedData = await updateAdminVendorData(vendorId, newData);

                setIsLoading(false);
                alert("수정되었습니다.");
                window.location.href = '/admin/store/inquiry';

                console.log('업데이트된 데이터:', updatedData);

                } catch (error) {
            
                    console.error('업데이트 오류:', error);

                }
        }
    };

    //상호
    const [name, setName] = useState('');

    const [isNameError, setIsNameError] = useState(false);
    const inputNameRef = useRef(null);

    //관리자ID
    const [owner, setOwner] = useState('')

    //관리자이름
    const [manager_name, setManagerName] = useState('')
    
    const [checkAdmin, setCheckAdmin] = useState(false)


    //구분
    const [business_type, setBusinesstype] = useState('')

    //사업자등록번호
    const [business_number, setBusinessnumber] = useState('')
    const [isBNumberError, setIsBNumberError] = useState(false);
    const inputBNumberRef = useRef(null);

    
    //통신판매등록번호 
    const [sales_registration_number , setSalesRegistrationNumber] = useState('');


    //사업장주소
    const [address, setAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

    // 출고지주소
    const [dispatchLocation, setDispatchLocation] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });

 
    // 반품지 주소
    const [returnAddress, setrReturnAddress] = useState({
        address: "",
        detail: "",
        postal_code:"",
        address_type: ""
    });


    // 사업장주소 변경 모달
    const [modalIsOpen, setModalIsOpen] = useState(false); //주소입력모달

    // 출고지 주소 변경 모달
    const [dispatchModalIsOpen, setDispatchModalIsOpen] = useState(false); 

    // 반품지 주소 변경 모달
    const [returnModalIsOpen, setReturnModalIsOpen] = useState(false); 
  
    const [detailAddress, setDetailAddress] = useState(''); //상세주소
   

    const [detailModalState, setDetailModalState] = useState(false); //주소 입력모달 false(주소입력화면) <-> true(상세주소)
   
    const onCompletePost = (data) => {
        console.table(data);
        setAddress({
            address: data.address,
            detail: "",
            postal_code: data.zonecode,  // 우편번호
            address_type: data.addressType === "R" ? "도로명" : "지번",
        });

        setModalIsOpen(false);
    };


    // 출고지 
    const handleDispatchComplete = (data) => {
        setDispatchLocation({
          address: data.address,
          detail: "",
          postal_code: data.zonecode, // 우편번호
          address_type: data.addressType === "R" ? "도로명" : "지번",
        });
      
        setDispatchModalIsOpen(false);
    };

    // 반품지
    const handleReturnComplete = (data) => {    
        setrReturnAddress({
            address: data.address,
            detail: "",
            postal_code: data.zonecode, // 우편번호
            address_type: data.addressType === "R" ? "도로명" : "지번",
        });
    
        setReturnModalIsOpen(false);
    };

    // 사업장주소 상세 주소 
    const handleInputDetailChange = (e) => {
        const newDetail = e.target.value;

        setAddress((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

     // 출고지 상세주소 입력
     const handleInputDispatchDetailChange = (e) => {
        const newDetail = e.target.value;

        setDispatchLocation((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

    // 반품지 상세주소 입력
    const handleInputReturnDetailChange = (e) => {
        const newDetail = e.target.value;

        setrReturnAddress((prevAdress) => ({
            ...prevAdress,
            detail: newDetail,
        }));

    };

   
    const [isAdressError, setIsAdressError] = useState(false);
    const inputAdressRef = useRef(null);


    //은행 
    const [bank, setBank] = useState('');

    //계좌번호 
    const [account_number, setAccountNumber] = useState('');

    //예금주
    const [account_holder, setAccountHolder] = useState('');


    //공동대표여부
    const [ceo, setCeo] = useState('');

    //과세여부
    const [tax, setTax] = useState('')



    // 이름
    const handleNameChange = (value) => {
        setName(value);
    };

    //이미지 파일들 저장
    const [files, setFiles] = useState({
        business_license: null,
        owner_image: null,
        account_image: null,
        sales_registration: null
    });

    // 이미지 url 
    const [uploadedImages, setUploadedImages] = useState({
        business_license: null,
        owner_image: null,
        account_image: null,
        sales_registration:null
    });

    
    const handleFileChange = (event, fileKey) => {
        const newFiles = {...files};
        newFiles[fileKey] = event.target.files[0];
        setFiles(newFiles);
    }


    // 추가정보(선택)
    const [phone_number, setPhonenumber] = useState('01025874587')
    const [tel, setTel] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('dd@naver.com')


    //직원추가(선택)
    const [input_employeeValue, setInputEmployeeValue] = useState('')
    const [employee_list, setEmployeelist] = useState([])
    const [employeeId_list, setEmployeeIdlist] = useState([])


    // 기존 데이터 조회
    useEffect(() => {
        // 1. 상호명
        if (data && data.name) setName(data.name);
        
        // 2-1. 관리자 ID 
        if(data && data.owner.username) setOwner(data.owner.username)
        
        // 2-2. 관리자 이름
        if(data && data.owner.name) setManagerName(data.owner.name)

       
        // 3. 구분 
        if( data && data.business_type) setBusinesstype(data.business_type)
        
        // 4. 사업자등록증 (대표자사진 , 통장사본 포함)
        if( data && data.business_license && data.account_image && data.owner_image && data.sales_registration ) {
            setUploadedImages({
                business_license: data.business_license,
                owner_image: data.owner_image,
                account_image : data.account_image ,
                sales_registration : data.sales_registration
            });
        }
        
        // 5. 사업자등록번호
        if( data && data.business_number) setBusinessnumber(data.business_number)

        // 6. 통신판매신고등 (4번에서 같이함)

        // 7. 통신판매신고번호
        if( data && data.sales_registration_number) setSalesRegistrationNumber(data.sales_registration_number)


        // 8. 사업자 주소
        if( data && data.address) {
            setAddress({
                address: data.address.address,
                detail: data.address.detail,
                postal_code: data.address.postal_code, 
                address_type: data.address.address_type 
            });
        }   
        
        // 9. 대표자 사진 (4번에서 같이함)
        // 10. 통장사본 (4번에서 같이함)


        // 11-1. 은행
        if (data && data.bank ) setBank(data.bank);

        // 11-2. 계좌번호
        if( data && data.account_number) setAccountNumber(data.account_number);

        // 11-3. 예금주
        if( data && data.account_holder) setAccountHolder(data.account_holder);

        // 12. 공동대표여부
        if( data ) {
            const coRepresentativeString = data.co_representative.toString();
            console.log("coRepresentativeStringcoRepresentativeString",coRepresentativeString)
            setCeo(coRepresentativeString);
        }
        
        // 13. 과세 여부 
        if( data && data.tax) setTax(data.tax);


        // 추가정보
        // 1. 매장전화
        if (data && data.tel) setTel(data.tel);

        // 2. 팩스번호(선택)
        if (data && data.fax) setFax(data.fax);

        // 3. 직원
        if (data && data.employee_list) {
            const usernames = data.employee_list.map((employee) => employee.username);
            setEmployeelist(usernames);
        }

        // 4. 출고지 주소
        if( data && data.dispatch_location) {
            setDispatchLocation({
                address: data.dispatch_location.address,
                detail: data.dispatch_location.detail,
                postal_code: data.dispatch_location.postal_code, 
                address_type: data.dispatch_location.address_type 
            });
        }   
        
        // 5.반품지 주소
        if( data && data.return_address) {
            setrReturnAddress({
                address: data.return_address.address,
                detail: data.return_address.detail,
                postal_code: data.return_address.postal_code, 
                address_type: data.return_address.address_type 
            });
        } 

        
    }, [data]);    

    //구분 
    const handleDivisionChange = (event) => {
        setBusinesstype(event.target.value);
    };
    
    // 은행 선택 
    const handleBankChange = (event) => {
        setBank(event.target.value);
    };
    
    //공동대표여부
    const handleCeoChange = (event) => {
        setCeo(event.target.value);
    };


    //과세여부
    const handleTaxChange = (event) => {
        setTax(event.target.value);
    };



    if (isError) {
        return <div>Error occurred while fetching data</div>;
    }

    console.log(data)
    
    
    // 직원 리스트 추가
    const handleEmployeeSubmit = (event) => {
        event.preventDefault();
        // 직원 아이디 확인
    
        const employeeId = input_employeeValue;

        verifyEmployeeId(employeeId)
        .then((checkEmployee) => {
            if(checkEmployee.data === "확인완료"){
                setEmployeeIdlist([...employeeId_list, checkEmployee.id]);      
                setEmployeelist([...employee_list, input_employeeValue]);           
            }   
        })
        .catch((error) => {
            alert("등록된 회원이 아닙니다")
            console.log(error);
        });
        
        setInputEmployeeValue("");
    };

    // 직원 리스트 삭제
    const handleEmployeeDelete = (index) => {
        const newListItems = [...employee_list];
        newListItems.splice(index, 1);
        setEmployeelist(newListItems);
    };
    
    // 입력 관련 함수

    // 상호명 입력 변화 확인
    const handleInputNameChange = (e) => {
        setName(e.target.value);
        setIsNameError(false);
    };

    // 사업장등록번호 변화 확인
    const handleInputBNumberChange = (e) => {
        let inputValue = e.target.value;

        // 입력값에서 "-"를 제거
        inputValue = inputValue.replace(/-/g, '');

        setBusinessnumber(inputValue);
        setIsBNumberError(false);
    };

     // 통신판매등록번호 변화 확인
     const handleInputSNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');

        setSalesRegistrationNumber(inputValue);
    }
       // 계좌번호 변화 확인
       const handleInputAccountNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');
        
        setAccountNumber(inputValue);
    }

    // 매장전화 변화 확인
    const handleInputTelNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');

        setTel(inputValue);
    }

    
    // 팩스번호 변화 확인
    const handleInputFaxNumberChange = (e) =>{
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/-/g, '');

        setFax(inputValue);
    }



    // 유효성 검사
    const validateForm = () => {
        let isValid = true;

        // 1. 상호명 검증
        const isNameValid = validateField(name, setIsNameError, inputNameRef);

        // 5. 사업자 등록번호 검증
        const isBNumberValid = validateField(business_number, setIsBNumberError, inputBNumberRef);


        isValid = isNameValid && isBNumberValid ;
        return isValid;
    }


    // 이미지 파일 업로드 (수정 된 파일만 업로드)
    const uploadImages = async () => {
        try {
            if (files.business_license) {
                const businessLicenseURL = await uploadImage(files.business_license);
                uploadedImages.business_license = businessLicenseURL;
            }

            if(files.owner_image){
                const ownerImageURL = await uploadImage(files.owner_image);
                uploadedImages.owner_image = ownerImageURL;
            }

            if(files.account_image){
                const accountImageURL = await uploadImage(files.account_image);
                uploadedImages.account_image = accountImageURL;
            }

            if(files.sales_registration){
                const salesRegistrationImageURL = await uploadImage(files.sales_registration);
                uploadedImages.sales_registration = salesRegistrationImageURL;
            }
            console.log("이미지 업로드 완료")
        } catch (error) {
          console.error("이미지 업로드 에러:", error);
        }
    };


    return(
        <>
        <div className='mainContents'>
            {/* 매장정보수정 */}
            <div className='page-tit'>
                <h2>매장정보수정 </h2>
            </div>

        
            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>기본정보</h3>                  
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    상호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text" 
                                        name="name" 
                                        placeholder="상호명"
                                        value={name}
                                        ref={inputNameRef}
                                        style={{ borderColor: isNameError ? "red" : "" }} 
                                        onChange={handleInputNameChange}  
                                    />
                                    {isNameError && <p className="InputError">매장 이름을 입력해주세요.</p>}
                    
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    관리자ID / 이름
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <div> 
                                        <input type="text" 
                                            placeholder="관리자ID"
                                            className='mr10'
                                            value={owner}
                                            onChange={(e) => setOwner(e.target.value)}
                                            disabled 
                                        
                                        />
                                        <input type="text" 
                                            placeholder="이름"
                                            className='ml7'
                                            value={manager_name}
                                            onChange={(e) => setManagerName(e.target.value)}
                                            disabled
                                    
                                        />
                                    </div>
                                 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    구분
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input 
                                        type='radio' 
                                        className='mrbtn' 
                                        id='g1' 
                                        name='business_type'
                                        onChange={handleDivisionChange} 
                                        checked={business_type === '개인'}
                                        value='개인' 
                                    />
                                    <label for='g1'>개인사업자</label>

                                    <input 
                                        type='radio' 
                                        className='mrbtn'
                                        id='g2' 
                                        name='business_type'
                                        checked={business_type === '법인'}
                                        onChange={handleDivisionChange} 
                                        value='법인' 
                                    />
                                    <label for='g2'>법인사업자</label>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    사업자등록증
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex_file">파일 수정</label>
                                    <input type="file" 
                                        id="ex_file" 
                                        onChange={(event) => handleFileChange(event, 'business_license')} 
                                    /> 
                                    {files.business_license ? (
                                        <p className='filename'>{files.business_license.name}</p>
                                    ) : (
                                        <a href={uploadedImages && uploadedImages?.business_license} target="_blank" className="pointer">
                                            사업자등록증 확인
                                        </a>
                                    )}

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    사업자등록번호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="사업자등록번호"
                                        name="business_number" 
                                        value={business_number}
                                        ref={inputBNumberRef}
                                        onChange={handleInputBNumberChange}
                                        style={{ borderColor: isBNumberError  ? "red" : "" }}
                                        
                                    />
                                    {isBNumberError && <p className="InputError">사업자등록번호를 입력해주세요.</p>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통신판매신고증
                                    <i className='essential'></i>
                                </td>

                                <td className='filebox'>                            
                                    <label for="ex3_file">파일선택</label>
                                    <input type="file" 
                                        id="ex3_file" 
                                        onChange={(event) => handleFileChange(event, 'sales_registration')} 
                                    /> 
                                     {files.sales_registration ? (
                                        <p className='filename'>{files.sales_registration.name}</p>
                                    ) : (
                                        <a href={uploadedImages && uploadedImages?.sales_registration} target="_blank" className="pointer">
                                            통신판매신고증 확인
                                        </a>
                                    )}
                                  
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통신판매등록번호
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="통신판매등록번호"
                                        value={sales_registration_number}
                                        onChange={handleInputSNumberChange}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    사업장 주소{isAdressError}
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                <ul className='ec-address'>
                                    <li>
                                        <input type="text"
                                            placeholder="사업장 주소"
                                            value={address.postal_code}
                                            disabled 
                                        />  
                                        <button className="ms-2" 
                                                onClick={()=>setModalIsOpen(true)}>
                                                    주소 검색
                                        </button> 
                                    </li>
                                    <li>
                                        <input type="text"
                                            placeholder="사업장 주소"
                                            value={address.address}
                                            disabled 
                                        />   
                                    </li>
                                    <li>
                                    <input
                                        type="text"
                                        placeholder="상세주소"
                                        value={address.detail}
                                        onChange={handleInputDetailChange}
                                        />
                                    </li>
                                </ul>
                                
                            </td>
                            </tr>
                            <tr>
                                <td>
                                    대표자 사진
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex1_file">파일수정</label>
                                    <input type="file" 
                                        id="ex1_file" 
                                        onChange={(event) => handleFileChange(event, 'owner_image')} 
                                    /> 
                                    {files.owner_image ? (
                                        <p className='filename'>{files.owner_image.name}</p>
                                    ) : (
                                        <a href={uploadedImages && uploadedImages?.owner_image} target="_blank" className="pointer">대표자 사진 확인하기</a>
                                    )}
                                    {/* {files.owner_image && <p className='filename'>{files.owner_image.name}</p>} */}
                                    {/* <a href={uploadedImages && uploadedImages?.owner_image} target="_blank" className="pointer">대표자 사진 확인하기</a> */}

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    통장사본
                                    <i className='essential'></i>
                                </td>
                                <td className='filebox'>                            
                                    <label for="ex2_file">파일선택</label>
                                    <input type="file" 
                                        id="ex2_file" 
                                        onChange={(event) => handleFileChange(event, 'account_image')} 
                                    /> 
                                    {files.account_image ? (
                                        <p className='filename'>{files.account_image.name}</p>
                                    ) : (
                                        <a href={uploadedImages && uploadedImages?.account_image} target="_blank" className="pointer">통장사본 사진 확인</a>
                                    )}
                                    {/* {files.account_image && <p className='filename'>{files.account_image.name}</p>} */}
                                    {/* <a href={uploadedImages && uploadedImages?.account_image} target="_blank" className="pointer">통장사본 사진 확인</a> */}

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    은행/계좌번호/예금주
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <select className='mSelectBox'
                                            value={bank}
                                            onChange={handleBankChange}>
                                        <option value="" selected="selected">산업은행</option>
                                        <option value="기업">기업은행</option>
                                        <option value="국민">국민은행</option>
                                        <option value="수협">수협은행</option>
                                        <option value="농협">농협은행</option>
                                        <option value="우리">우리은행</option>
                                        <option value="SC제일">SC제일은행</option>
                                        <option value="한국씨티">한국씨티은행</option>
                                        <option value="대구">대구은행</option>
                                        <option value="부산">부산은행</option>
                                        <option value="광주">광주은행</option>
                                        <option value="제주">제주은행</option>
                                        <option value="전북">전북은행</option>
                                        <option value="경남">경남은행</option>
                                        <option value="새마을">새마을금고</option>
                                        <option value="신협">신협</option>
                                        <option value="우체국">우체국</option>
                                        <option value="하나">하나은행</option>
                                        <option value="신한">신한은행</option>
                                        <option value="케이">케이뱅크</option>
                                        <option value="카카오">카카오뱅크</option>
                                        <option value="토스">토스뱅크</option>
                                        <option value="지역농축협">지역농축협</option>
                                        <option value="미래에셋증권">미래에셋증권</option>
                                        <option value="NH투자증권">NH투자증권</option>
                                        <option value="삼성증권">삼성증권</option>
                                        <option value="한국투자증권">한국투자증권</option>
                                        <option value="KB증권">KB증권</option>
                                        <option value="메리츠증권">메리츠증권</option>
                                        <option value="신한금융증권">신한금융증권</option>
                                        <option value="하나증권">하나증권</option>
                                    </select>
                                    <input type="text"
                                            className='ml7'
                                            placeholder="계좌번호"
                                            value={account_number}
                                            onChange={handleInputAccountNumberChange}
                                    />       
                                    <input type="text"
                                            className='ml7'
                                            placeholder="예금주"
                                            value={account_holder}
                                            onChange={(e) => setAccountHolder(e.target.value)}
                                    />       
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    공동대표여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input 
                                        type='radio' 
                                        className='mrbtn'
                                        id='c1' 
                                        name='ceo'
                                        onChange={handleCeoChange} 
                                        checked={ceo === "false"}
                                        value='false'   
                                    />
                                    <label for='c1'>공동대표 없음</label>

                                    <input 
                                        type='radio' 
                                        className='mrbtn' 
                                        id='c2' 
                                        name='ceo'
                                        onChange={handleCeoChange} 
                                        checked={ceo === "true"}
                                        value='true'
                                    />
                                    <label for='c2'>공동대표 있음</label>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    과세여부
                                    <i className='essential'></i>
                                </td>
                                <td>
                                    <input type='radio' 
                                        className='mrbtn'
                                        id='t1' 
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '과세'}
                                        value='과세' 
                                    />
                                    <label for='t1'>과세</label>

                                    <input type='radio' 
                                        className='mrbtn'
                                        id='t2'
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '면세'}
                                        value='면세' 
                                    />
                                    <label for='t2'>면세</label>

                                    <input type='radio'
                                        className='mrbtn' 
                                        id='t3' 
                                        name='group1'
                                        onChange={handleTaxChange} 
                                        checked={tax === '간이과세'}
                                        value='간이과세'  />
                                    <label for='t3'>간이과세</label>
                                </td>
                            </tr>
                            
                            </tbody>

                    
                    </table>
            
                    </div>
                    
            </section>

            <section className='McontentBox m-p0'>
                <div className='Register-table-wrap'>
                    <h3>추가정보</h3>
                    <table className='s1Table'>
                        <tbody>
                            <tr>
                                <td>
                                    매장전화
                                    <i className='essential'></i>   
                                </td>
                                <td>
                                    <input type="text"
                                        placeholder="매장전화"
                                        value={tel}
                                        onChange={handleInputTelNumberChange}
                                        
                                    />      
                                    
                                </td>
                            </tr>
                            <tr>
                                <td>팩스번호(선택)</td>
                                <td>
                                    <input 
                                        type="text" 
                                        placeholder="팩스번호"
                                        value={fax}
                                        onChange={handleInputFaxNumberChange}/>
                                </td>
                            </tr>
                            <tr>
                                <td>직원추가(선택)</td>
                                <td className='ep10'>
                                    <input 
                                        type="text"
                                        value={input_employeeValue}
                                        placeholder='직원의 아이디를 입력하세요'
                                        onChange={(e) => setInputEmployeeValue(e.target.value)}
                                    />
                                    <button 
                                        className='addEmployeeBtn'
                                        onClick={handleEmployeeSubmit}>추가</button>

                                    <ul className='employeeList'>
                                        {employee_list.map((item, index) => (
                                             <li key={index}>
                                                {item}
                                                <button onClick={() => handleEmployeeDelete(index)}>X</button>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    출고지 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={dispatchLocation.postal_code}
                                                disabled 
                                            />  
                                            <button className="ms-2" 
                                                    onClick={()=>setDispatchModalIsOpen(true)}>
                                                        주소 검색
                                            </button> 
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="출고지 주소"
                                                value={dispatchLocation.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="출고지 상세주소"
                                            value={dispatchLocation.detail}
                                            onChange={handleInputDispatchDetailChange}
                                            />
                                        </li>
                                    </ul>      
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    반품지 주소
                                    <i className='essential'></i>
                                </td>
                                <td className='py-2'>
                                    <ul className='ec-address'>
                                        <li>
                                            <input type="text"
                                                placeholder="우편번호"
                                                value={returnAddress.postal_code}
                                                disabled 
                                            />  
                                            <button className="ms-2" 
                                                    onClick={()=>setReturnModalIsOpen(true)}>
                                                        주소 검색
                                            </button> 
                                        </li>
                                        <li>
                                            <input type="text"
                                                placeholder="반품지 주소"
                                                value={returnAddress.address}
                                                disabled 
                                            />   
                                        </li>
                                        <li>
                                        <input
                                            type="text"
                                            placeholder="반품지 상세주소"
                                            value={returnAddress.detail}
                                            onChange={handleInputReturnDetailChange}
                                            />
                                        </li>
                                    </ul>      
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            
                <div className='m-btn-wrap'>
                    <button onClick={handleUpdate} className='m-btn m-btn-search'>
                        {isLoading ?  <LoadingSpinner /> : '매장수정'} 
                    </button>
                </div> 

            </section>

              
            {/* 사업장 주소지 모달 */}
            {
                modalIsOpen  ? (
                    <AddressModal 
                        show={modalIsOpen} 
                        onHide={setModalIsOpen} 
                        autoClose 
                        onComplete={onCompletePost} 
                />
                ) : null
            }

            {/* 출고지 변경 모달 */}
            {
                dispatchModalIsOpen ? (
                <AddressModal
                    show={dispatchModalIsOpen}
                    onHide={setDispatchModalIsOpen}
                    autoClose
                    onComplete={handleDispatchComplete} // 출고지 주소 입력 모달용 함수
            
                />
            ) : null
            }

            {/* 반품지 변경 모달 */}
            {
                returnModalIsOpen ? (
                    <AddressModal
                        show={returnModalIsOpen}
                        onHide={setReturnModalIsOpen}
                        autoClose
                        onComplete={handleReturnComplete} // 반품지 주소 입력 모달용 함수
                    />
                ) : null
            }



        </div>

        </>
    )
}

export default Edit