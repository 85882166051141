/***************************************************************************************
* FileName      : CouponForm.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 > 쿠폰등록 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import Benefits from "./CouponForm/Benefits"
import Info from "./CouponForm/Info"
import Condition from "./CouponForm/Condition"
import LoadingSpinner from "../../../components/LoadingSpinner";
import React, { useRef, useEffect , useState } from 'react';
import { useDiscountCoupon } from "../../../../api/hwadmin/marketingApi";

function CouponForm()
{
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    const { post, isLoading: isPostLoading, error } = useDiscountCoupon();

    const handleSubmit = (event) => {
        if (validateForm()) {
            setIsLoading(true); 
            event.preventDefault();

            if (couponInfoData.format != '번호입력형') {
                delete couponInfoData.code;
            }

            post(couponInfoData)
            .then(() => {
                alert("할인쿠폰 등록 성공적으로 완료하였습니다");
            
                window.location.href = '/admin/marketing/couponlist'; 
        
                 setIsLoading(false); // 로딩 상태를 비활성화
            })
            .catch(error => {
                console.log("등록 실패:", error.message);
                setIsLoading(false); // 로딩 상태를 비활성화
            });
        }
    }



    const validateForm = () => {
        let isValid = true;

        if(couponInfoData.format === '번호입력형' && (couponInfoData.code === null || couponInfoData.code === "")){
            alert("쿠폰유형이 번호입력형이라면 쿠폰코드는 필수항목 입니다")
            return false;
        }

        // 쿠폰이름 확인
        if(couponInfoData.name ===''){
            alert("쿠폰이름은 필수항목 입니다")
            return false;
        }

        if(couponInfoData.discount_type === '할인금액형' && couponInfoData.discount_amount === 0){
            alert("혜택구분 > 할인금액형 입력란을 확인해주세요")
            return false;
        }

        if(couponInfoData.discount_type === '할인율형' && couponInfoData.discount_rate === 0){
            alert("혜택구분> 할인율 입력란을 확인해주세요")
            return false;
        }

        if(couponInfoData.discount_type === '할인율형' && couponInfoData.limit_discount_amount === 0){
            alert("혜택구분> 할인율 입력란을 확인해주세요")
            return false;
        }

        if(couponInfoData.expiration_type === "일정선택형" && couponInfoData.validity_period_start === null){
            alert("유효기간> 일정선택형이라면 유효기간 시작일을 선택해주세요")
            return false;
        }

        if(couponInfoData.expiration_type === "일정선택형" && couponInfoData.validity_period_end === null){
            alert("유효기간> 일정선택형이라면 유효기간 만료일을 선택해주세요")
            return false;
        }
        

        if(couponInfoData.expiration_type === "만료일선택형" && couponInfoData.expiration_date === 0){
            alert("유효기간> 만료일선택형이라면 입력란을 확인해주세요")
            return false;
        }

        return isValid;
    }

     // 쿠폰등록
     const [couponInfoData , setCouponInfoData] = useState({
        type : "상품" ,// 기본정보 > 혜택구분
        format : "회원선택형" , // 기본정보 > 쿠폰유형
        name :"",  // 기본정보 > 쿠폰이름
        code : null, // 번호입력형 일 시, 쿠폰 코드 입력 

        discount_type : "할인금액형" , // 혜택설정 > 혜택구분
        discount_amount : 0 , //  혜택설정 > 혜택구분 - 할인금액형 
        discount_rate : 0 , //  혜택설정 > 혜택구분 > 할인율형 - 할인율
        limit_discount_amount : 0 , // 혜택설정 > 혜택구분 > 할인율형 - 최대 금액

        use_condition : 0 , // 혜택설정 > 최소주문금액

        expiration_type : "일정선택형" , // 혜택설정 > 유효기간 유형선택
        validity_period_start : null ,// 혜택설정 > 유효기간 - 쿠폰 유효기간 시작일 
        validity_period_end : null , //  혜택설정 > 유효기간 - 쿠폰 만료일
        
        expiration_date : 0 , // 혜택설정 > 유효기간 - 만료일 선택 () 일
        vendor : "", // 혜택설정 > 이용가능매장 
        
        limit_count : false , // 발급조건 > 수량제한여부
        issue_count : 0 , // 발급조건 > 수량제한 갯수
        issue_period_start : null ,//  발급조건 > 기한제한 시작 시간
        issue_period_end : null ,//  발급조건 > 기한제한 끝 시간
    });


    // useEffect(()=>{
    //     console.log(couponInfoData);
    //     console.log("--couponInfoData--")
    // },[couponInfoData])

    return (
        <>
        <section className='product-register-wrap'>
            <div className='product-reguster-inner'>
                <div className='accordion'>
                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>기본정보 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Info 
                                couponInfoData={couponInfoData}
                                setCouponInfoData={setCouponInfoData}/>
                        </div>
                    </div>

                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>혜택 설정 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Benefits
                                couponInfoData={couponInfoData}
                                setCouponInfoData={setCouponInfoData}/>
                        </div>
                    </div>

                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>발급조건 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Condition
                                couponInfoData={couponInfoData}
                                setCouponInfoData={setCouponInfoData}/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    

        <div className='m-btn-wrap'>
                <button onClick={handleSubmit} className='m-btn m-btn-search'>
                    {isLoading ?  <LoadingSpinner /> : '쿠폰등록'} 
                </button>
            </div> 
        </>
    )
}

export default CouponForm