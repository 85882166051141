import React from 'react';
import {Row, Col, Button , Navbar, Nav, Container, Tab , InputGroup,Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

function SingleProduct({singleProductItem , setSingleProductItem})
{
   
    const [productPrice, setProductPrice] = useState(singleProductItem.price);

    const handleQuantityChange = (change) => {
        setSingleProductItem((prevItem) => ({
        ...prevItem,
        quantity: Math.max(1, prevItem.quantity + change)
        }));

    };

    useEffect(() => {
        setSingleProductItem((prevItem) => ({
        ...prevItem,
        price: productPrice * Math.max(1, prevItem.quantity) 
        }));
    }, [singleProductItem.quantity]);

    return (
        <Row>
            <Col>
                <div className='prd-add'>
                    <div className='prd-amount-wrap'>
                        <div className='amount-box'>
                            <div className='amount'>
                                <a className='del_item_quantity cursor amount-down'
                                    onClick={() => handleQuantityChange(-1)}
                                >-</a>
                                <input type="text" value={singleProductItem.quantity} readOnly />
                                <a className='add_item_quantity cursor amount-up'
                                    onClick={() => handleQuantityChange(1)}
                                >+</a>
                            </div>
                        </div>
                        <p className='fs-6 fw-bold'>{singleProductItem.price.toLocaleString()}원</p>
                    </div>
                </div>
            </Col> 
        </Row>
    );
}

export default SingleProduct;