import {  queryClient , prefetchQuery , useQuery } from 'react-query';
import { useState , useEffect } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;

export const useUserList = async () => {
    const response = await instance.get(`auth/document/`);
    return response.data;
}
// 회원관리 > id 검색 조회 
export const fetchUserId = (username) => {
  return instance.get(`auth/document/?username__contains=${username}`)
    .then(res => res.data);
};



// 회원관리 > 회원 조회
const fetchUser = (page, pageSize ,ordering) => {
    return instance.get(`auth/document/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
      .then(res => res.data);
};

export const useUserListQuery = (ordering , pageSize) => {
    const [page, setPage] = useState(1);
    
    useEffect(() => {
      setPage(1);
    }, [pageSize]);

    const { data, error, isFetching } = useQuery(
      ['userList', page, pageSize, ordering], () => fetchUser(page, pageSize , ordering), {
      onSuccess: (data) => {
        return data;
      }
    });

    return {
      data,
      error,
      page,
      setPage,
      isFetching,
    };
};

// 고객관리 검색 
export const handleSearch = async ({
  searchAuth, 
  userType,  
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  selectedSMSReceiver,
  selectedEmailReceiver,
  startAge,
  endAge,
  birthdayStartDate,
  birthdayEndDate,
  selectedMemberStatus
  }) => {
  try {
    let url = `auth/document/`;

    if (searchAuth) {
      switch(searchOption) {
        case 'all':
          url += `?search=${searchAuth}`;
          break;
        case 'id':
          url += `?username__contains=${searchAuth}`;
          break;
        case 'name':
          url += `?name__contains=${searchAuth}`;
          break;
        case 'email':
          url += `?email__contains=${searchAuth}`;
          break;
        case 'phone':
          url += `?phone_number__contains=${searchAuth}`;
          break;
        case 'address':
          url += `?address=${searchAuth}`;
          break;
        
        default:
          break;
      }
    }else{
      url += `?`;
    }

    if (selectedStartDate && selectedEndDate) {
      switch(selectedDateType) {
        case 'join':
          url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        case 'last':
          url += `&last_login__range=${selectedStartDate}__${selectedEndDate}`;
          break;
        default:
          break;
      }
    }

    if (startAge && endAge) {
      url += `&age__range=${startAge}__${endAge}`;
    }

    if (userType) url += `&type=${userType}` 

    if (selectedSMSReceiver) url += `&sms_agree=${selectedSMSReceiver}` 

    if (selectedEmailReceiver) url += `&email_agree=${selectedEmailReceiver}` 

    if (birthdayStartDate && birthdayEndDate) {
        url += `&birth_date__range=${birthdayStartDate}__${birthdayEndDate}` 
    }

   

    if(selectedMemberStatus) url += `&status=${selectedMemberStatus}` 

    url += `&ordering=${ordering}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

