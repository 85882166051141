/***************************************************************************************
* FileName      : useProtect.js
* Description   : 비회원 접근 제한 훅, 새로고침 제한 훅
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.01
* Modifide Date : 2024.01.01 : CHM : 비회원 접근 제한
* Reference     : 
***************************************************************************************/
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useProtect(isLoggedIn){
    const navigate = useNavigate();

    useEffect(()=>{
        if (!isLoggedIn){
            navigate('/member/login');
        }
    },[isLoggedIn, navigate]);
}

// 새로고침 막기 경고창
export function useRefreshProtect(){
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = '현재 페이지를 떠나면 데이터가 초기화됩니다.';
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
}

// 뒤로가기 막기 경고창
export function useBackProtect(){
    const navigate = useNavigate();
    useEffect(() => {
        const handleBackButton = (event) => {
        event.preventDefault();
        
        const confirmation = window.confirm("뒤로 가시겠습니까?");
        if (confirmation) {
            // 사용자가 확인하면 이전 페이지로 이동
            navigate(-1);
        }
        };

        // 백버튼 이벤트 리스너 등록
        window.addEventListener("popstate", handleBackButton);
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [navigate]);
}