/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 해와관리자 > 주문배송 > 배송현황 조회 페이지 > 배송중 조회 OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React from "react";
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils";

function OrderRow ({ order, index }) 
{
  const navigate = useNavigate();

  // vendor 검색 페이지 이동
  const handleCellClick = (name) => {
      const searchQuery = encodeURIComponent(name);
      const newUrl = `/admin/store/inquiry/?search=${searchQuery}`;
      navigate(newUrl);
  };
  
  return (
    <tr key={order.id}>
        <td><input type="checkbox" name="" value=""/></td>
        <td>{order?.order_number}</td>
        
        <td className='blueLink' onClick={() => handleCellClick(order?.vendor_name)}>
          {order?.vendor_name}
        </td>
        <td>
          {extractYearMonthDayHourMinute2(order?.paid_at)}<br/>
        </td>

        <td>{order?.status}</td>
        <td>{order?.delivery_type}</td>
        <td>
          {order?.product_id} <br/>
          {order?.product_name}
        </td>
        <td>
          {order?.option_rows.map((option,index) => (
              <p key={index}>{option.name}</p>
          ))}
        </td>
        <td>{order.courier && order.courier }</td>
        <td>송장번호</td>
        <td>{order?.option_rows?.map(x => x.quantity).join('\n')}</td>
        <td>{typeof order.total_price === 'number' && order.total_price.toLocaleString()}원</td>
        <td>
          {order.orderer_username && order.orderer_username} <br/>
          {order.orderer_name && order.orderer_name}
        </td>
        <td>
          {order.recipient_name && order.recipient_name} <br/>
          {order.recipient_phone_number && order.recipient_phone_number}
        </td>
        <td>
          [{order.postal_code && order.postal_code}]
          {order.address && order.address}
        </td>

        <td>{extractYearMonthDayHourMinute2(order?.arrived_at)}</td>
        <td>{extractYearMonthDayHourMinute2(order?.expected_confirmed_at)}</td>
  </tr>
  )
}

export default OrderRow