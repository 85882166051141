/***************************************************************************************
* FileName      : MyorderList_cancel.js
* Description   : 고객 > 마이페이지 > 반품/취소내역 > 취소내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.18
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import MypageTabComponent from './MypageTab_v2.js';
import Sidemenu from './Sidemenu.js'
import StatusBar from './StatusBar.js';
import DatePicker from 'react-datepicker'; // 날짜 선택용 인풋박스
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko'; // 날짜 선택필드 한국어로 변경
import { extractYearMonthDayHourMinute , extractYearMonthDay } from './Component/extractDateUtils.js';
import Pagination from '../customer/common/Pagination.js';

import { useMyOrderCancelListQuery } from '../../api/services/mypageApi.js';
import OrderRow from './MyorderList_cancel/OrderRow.js';
import MOrderRow from './MyorderList_cancel/MOrderRow.js';

function CancelListLayout()
{
    return(
      <>
          <Container>
            <StatusBar/>
            <Row className='m-0'>
              <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'cancel'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <CancelList />
                </Col>
              </div>
            </Row>
          </Container>
      </>
    )
}

function CancelList()
{    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const { data:myOrderCancelData, error, isFetching, page, setPage } = useMyOrderCancelListQuery();

    if (!myOrderCancelData) {
        return <div>Loading...</div>;
    }


    console.log(myOrderCancelData);
    
    console.log("취소내역")

    const tabs = [
        {label: '반품' , url : '/mypage/return'},
        {label: '취소' , url : '/mypage/cancel'},
    ];


    const handleStartDate = date => setStartDate(date);
    const handleEndDate = date => setEndDate(date);

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <>
            <Row className='m-0 p-0 mb-3'>
                <Col className='m-0 p-0'>
                    <h3 className="m-0 p-0 fs-5 fw-bold">
                    반품/취소내역 
                    <span className='fs-6 fw-bold text-hw'>
                    &nbsp;총{myOrderCancelData?.data.length}건</span>
                    </h3>
                </Col>
                {/* <Col className='d-flex justify-content-end align-items-center m-0 p-0'>
                    <p className='me-2 fs-7 fw-bold text-grey'>조회기간</p>
                    <div className="date-picker-wrapper m-0 p-0">
                        <DatePicker selected={startDate} onChange={handleStartDate} placeholderText={'시작일'} dateFormat="yyyy-MM-dd" locale={ko}/>
                    </div>
                    <p className="ms-1 me-1"> ~ </p>
                    <div className="date-picker-wrapper m-0 p-0">
                    <DatePicker selected={endDate} onChange={handleEndDate} placeholderText={'종료일'} dateFormat="yyyy-MM-dd" locale={ko}/>
                    </div>
                </Col> */}
            </Row>

            {/* 상태별 tab  */}
            <MypageTabComponent tabs={tabs} />
              
            <table className='mypage-table' id="mypage-order-table">
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '15%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>주문번호</th>
                        <th>상품</th>
                        <th>수량</th>
                        <th>주문금액</th>
                        <th>진행현황</th>
                    </tr>
                </thead>
                <tbody className='history'>
                    {myOrderCancelData?.data?.length > 0 ? (
                        myOrderCancelData.data.map((orderItem) => (
                            <OrderRow 
                                orderItem={orderItem}/>
                           
                        ))
                        ) : null}

                </tbody>
            </table>

            {/* 모바일 */}
            <div className='mOrderContents' id="mContents">
                <div className=''>
                    {myOrderCancelData?.data?.length > 0 ? (   
                        myOrderCancelData.data.map((orderItem) => (
                            <MOrderRow orderItem={orderItem}/>   
                        ))
                        ) : null}
                </div>
            </div>


            {/* 페이지내비게이션 */}
            <section className='mt-5'>
                <Pagination
                    count={myOrderCancelData?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                />
            </section>
                
            </>
        </div>  
        </>
    )
}



export default CancelListLayout