
/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 셀러관리자 > 주문배송 > 반품/취소 > 취소 리스트 조회 > Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import CancelModal from "./CancelModal";
import { extractYearMonthDayHourMinute2 } from "../../../../mypage/Component/extractDateUtils";

export const OrderRow = ({ order, index , expandedIds, handleExpandClick}) => {
  
  // 취소 승인 모달 상태
  const [CancelApprovalModalOpen , setIsCancelApprovalModalOpen] = useState(false);

  // 취소 거절 모달 상태
  const [CancelRejectionModalOpen , setIsCancelRejectionModalOpen] = useState(false);
  
  const navigate = useNavigate();

  
  // 취소 승인 모달
  const openApprovalModalOpen = () => {
    setIsCancelApprovalModalOpen(true);
  };

  // 취소 반려 모달   
  const openRejectionModalOpen = () => {
    setIsCancelRejectionModalOpen(true);
  };


  return (
    <React.Fragment key={order?.id}>
      <tr key={order?.id}>
          <td>
            <button
              className='expand-btn'
              onClick={() => handleExpandClick(order?.id)}>
            <i
                className={
                  expandedIds.includes(order?.id) ? 'xi-angle-up' : 'xi-angle-down'
                }
            ></i>
          </button>
        </td>
        <td>{order?.id}</td>
         
        <td      
            className="blueLink">
            {order?.order_number}
        </td>
         
        <td>취소</td>
        <td>{order?.cancels?.status}</td>
        <td>    
            {order?.cancels?.status === "취소요청"
              && 
              <>
                <button className='t-s-btn me-2' onClick={openApprovalModalOpen}>승인</button> 
                <button className='t-s-btn' onClick={openRejectionModalOpen}>거절</button>  
              </>
            }
          </td>
        <td>
          {order?.orderer_username}<br/>
          {order?.orderer_name}
        </td>
        <td>
          {order?.recipient_name} <br/>
          {order?.recipient_phone_number}
        </td>
        <td>
          { order?.cancels?.created_at ? extractYearMonthDayHourMinute2(order?.cancels?.created_at) : "-"}
        </td>
        <td>{order?.cancels?.reception_number}</td>
        <td>{order?.cancels?.cancel_reason}</td>
        <td>
          {order?.total_price.toLocaleString()}원
        </td>
          
        <td>{order?.cancels?.reject_reason}</td> 
        <td>
          {order?.cancels?.manager_name}
        </td>      

        <td>
          {order?.cancels?.completed_at ? extractYearMonthDayHourMinute2(order.cancels.completed_at) : "-"}
        </td>
      </tr>
      
      {expandedIds.includes(order?.id) && (
        <tr className='sub-table'>
          <td colSpan={13}>
            <table>
              <thead>
                <tr>
                  <th>상품번호</th>
                  <th>상품명</th>
                  <th>옵션정보</th>
                  <th>추가옵션</th>
                  <th>주문금액</th>
                </tr>
              </thead>
              <tbody>
                {order?.order_items?.map((orderItem, index) => (
                  <tr key={index}>
                    <td><p>{orderItem.product}</p></td>
                    <td><p>{orderItem.product_name}</p></td>
                    <td>
                      {orderItem.orderitem_option_rows?.map((option, index) => (
                        <p key={index}>{option.name} - {option.quantity}개</p>
                      ))}
                    </td>
                    <td>
                      {orderItem.orderitem_extra_options?.map((extraOption, index) => (
                        <p key={index}>{extraOption.name} - {extraOption.quantity}개</p>
                      ))}
                    </td>
                    <td>{orderItem.total_price.toLocaleString()}원</td>
                  </tr>
                ))}
              </tbody>

          </table>
        </td>
      </tr>
    )}

    <CancelModal 
      show={CancelApprovalModalOpen} 
      setShow={setIsCancelApprovalModalOpen} 
      order={order}
      modalType="approval"
    />

    <CancelModal 
      show={CancelRejectionModalOpen} 
      setShow={setIsCancelRejectionModalOpen} 
      order={order}
      modalType="rejection"
    />


  </React.Fragment>
  )
}
