/***************************************************************************************
* FileName      : UnpaidHistory.js
* Description   : 셀러관리자 > 정산 > 미지급내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';

import React, { useState, useEffect } from 'react';

import SortComponent from '../../admin/common/SortComponent2.js';
import PageSizeSelect from '../../admin/common/PageSizeSelect.js';
import { useSAdjustUnpaidDocumentQuery , handleSAdjustUnpaidSearch } from '../../../api/selleradmin/adjustApi.js';
import { AdjustRow } from './UnpaidHistory/AdjustRow.js';
import Pagination from '../common/Pagination.js';
import SearchPagination from '../../admin/common/SearchPagination.js';

function UnpaidHistory()
{
    const [ordering, setOrdering] = useState('created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useSAdjustUnpaidDocumentQuery(ordering,pageSize);
    
    const [searchAdjust, setSearchAdjust] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
        

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
        
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
    


    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const onSearch = () => {
        handleSAdjustUnpaidSearch({
            searchAdjust,
            setSearchResults, 
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate, 
            selectedEndDate,
            ordering,
        });

        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/selleradmin/settlement/unpaid'; 
    };

    useEffect(()=>{
        console.log(searchResults);
    },[searchResults])

    console.log(data);

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>미지급내역</h2>
            </div>
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="order_number">주문번호</option>
                                <option value="product_name">상품명</option>
                                <option value="orderer_username">구매자ID</option>
                                <option value="orderer_name">구매자이름</option>
                                <option value="recipient_name">수취인이름</option>
                                <option value="recipient_phone_number">수취인 연락처</option>
                                <option value="address">주소</option>
                            </select>
                            <input type="text"  
                                value={searchAdjust}
                                onChange={(e) => setSearchAdjust(e.target.value)}/> 
                         
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="purchase_confirmation">구매확정일</option>
                                <option value="">배송완료일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                            
                        </div>   
                    </div>
    
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search'  onClick={onSearch}>검색</button>
                  
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                    <div className='selectWrap'>
                        <select className='mSelectBox' id="ordering" value={ordering} onChange={handleOrderingChange}>
                            <option value="total_settlement_amount">배송완료일 내림차순</option>
                            <option value="-total_settlement_amount">배송완료일 오름차순</option>
                            <option value="-confirm_at">구매확정일 내림차순</option>
                            <option value="confirm_at">구매확정일 오름차순</option>
                        </select>
                    </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주문번호</th>
                                <th>배송완료일</th>
                                <th>구매확정일</th>
                                <th>상품명</th>
                                <th>정산대상금액</th>
                                <th>구매자 ID <br/>
                                    구매자 이름
                                </th>
                                <th>수취인 이름 <br/>
                                    수취인 연락처
                                </th>
                                <th>주소</th>
                            </tr>                        
                        </thead>
                        <tbody>
                            {searchConfirm && searchResults?.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((Adjust , index) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((Adjust , index ) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            ) : null}
                        </tbody>
                    </table>
                </div>
            </section>

        
            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    /> 
                ) : (
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>


        </>
    )
}

export default UnpaidHistory