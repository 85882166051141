import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from './../apiConfig';
import { useState } from 'react';
import { getAccessFromCookie } from '../cookieUtils';
import { instance } from '../../axiosInstance/axiosConfig';

// 장바구니 상품등록
const postAddCart = async (data, access) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    // 토큰이 존재하는 경우에만 'Authorization' 헤더 추가
    if (access) {
        headers['Authorization'] = `Bearer ${access}`;
    }

    const response = await fetch(`${BASE_URL}/cart/`, {
        method: "POST",
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(data),
    });

    const responseData = await response.json();
    return responseData;
};

export function useCartProductRegistration() {
    const access = getAccessFromCookie();
    console.log("장바구니",access)
    const post = async (data) => {
      try {
        const headers = {
            'Content-Type': 'application/json',
        };
    
        // 토큰이 존재하는 경우에만 'Authorization' 헤더 추가
        if (access) {
            headers['Authorization'] = `Bearer ${access}`;
        }
    
        const response = await fetch(`${BASE_URL}/cart/`, {
            method: "POST",
            headers: headers,
            credentials: 'include',  
            body: JSON.stringify(data),
        });
        // const response = await instance.post('/cart/', data, {headers});
       
        if (!response.ok) {
            const errorResponseData = await response.json();
            if(errorResponseData.is_in)
            {
              const userConfirmed = window.confirm('장바구니에 같은 상품이 있습니다. 그래도 담으시겠습니까?');

              if (userConfirmed) {
                  data.force = true;
                  const responseData = await postAddCart(data, access);
                  return responseData;
              }         
            }
        }
  
        const responseData = await response.json();
        return responseData;

      } catch (error) {
        throw new Error("Failed to post");
      }
    };
  
   return {
      post,
    };
  }
  
// 장바구니 조회
export const fetchCartDetails = () => {
    const access = getAccessFromCookie();
    const headers = {};
  
    // 토큰이 존재하는 경우에만 'Authorization' 헤더 추가
    if (access) {
      headers['Authorization'] = `Bearer ${access}`;
    }
  
    return fetch(`${BASE_URL}/cart/my/`, {
      headers: headers,
      credentials: 'include',  
    })
      .then(res => res.json());
  };

  
// 상품 조회
const fetchMyCart = () => {
  const access = getAccessFromCookie();
  const headers = {};

  // 토큰이 존재하는 경우에만 'Authorization' 헤더 추가
  if (access) {
    headers['Authorization'] = `Bearer ${access}`;
  }

  return fetch(`${BASE_URL}/cart/my/`, {
    headers: headers,
    credentials: 'include',  
  })
    .then(res => res.json());
};

export const useMyCartQuery = () => {
  const { data, error, isFetching } = useQuery(['mycart'], () => fetchMyCart(), {
    onSuccess: (data) => {
   
    }
  });

  return {
    data,
    error,
    isFetching,
  };
};

// 장바구니 > 주문하기 버튼 클릭 시 
export function useOrderPurchase() {
    const [isPostLoading, setIsPostLoading] = useState(false);
    const [error, setError] = useState(null);

    const access = getAccessFromCookie();

    const post = async (data) => {
      setIsPostLoading(true);
      setError(null);
      console.log("주문하기 데이터...",data)
      try {
        const response = await fetch(`${BASE_URL}/order/cart/purchase/`, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${access}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',  
          body: JSON.stringify(data),
        });
  
        if (!response.ok) {
          const errorResponseData = await response.json();
          alert(errorResponseData.data);
          // throw new Error(`Failed to post: ${errorResponseData.message}`);
        }
  
        const responseData = await response.json();
        return responseData;
      } catch (error) {
        setError("Failed to post");
        throw new Error("Failed to post");
      } finally {
        setIsPostLoading(false);
      }
    };
  
    return {
      post,
      isPostLoading,
      error,
    };
  }

  // 장바구니 > 비우기 버튼 클릭 시 
export function useCartDelete() {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [error, setError] = useState(null);

  const access = getAccessFromCookie();
  const cartDelete = async () => {
    setIsDeleteLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/cart/delete/`, {
        method: "DELETE",
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      console.log("삭제 성공")
      console.log(response)
      
      if (response?.status === 204) {
        console.log("삭제 성공 - 상태 코드 204");
      }
      console.log("-----------")
      return response;
    } catch (error) {
      setError(error);
      throw new Error(error);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return {
    cartDelete,
    isDeleteLoading,
    error,
  };
}


  // 장바구니 > 아이템 삭제  
  export function useCartItemDelete() {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const access = getAccessFromCookie();
    const cartItemDelete = async (data) => {
      setIsDeleteLoading(true);
      setError(null);
  
      try {
        const response = await fetch(`${BASE_URL}/cart/item/`, {
          method: "DELETE",
          credentials: 'include',
          
          headers: {
            'Authorization': `Bearer ${access}`, 
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        console.log("삭제 성공")
        console.log(response)
        
        if (response?.status === 204) {
          console.log("삭제 성공 - 상태 코드 204");
        }
        console.log("-----------")
        return response;
      } catch (error) {
        setError(error);
        throw new Error(error);
      } finally {
        setIsDeleteLoading(false);
      }
    };
  
    return {
      cartItemDelete,
      isDeleteLoading,
      error,
    };
  }
  
  // 장바구니 페이지 > 옵션,추가옵션 수량 수정 
  export function useCartQuantityPatch() {
    const access = getAccessFromCookie();
    console.log("장바구니",access);
    const patchQuantity = async (cartItemId,data) => {
      try {
        const headers = {
            'Content-Type': 'application/json',
        };
    
        console.log("토큰:",access);
        // 토큰이 존재하는 경우에만 'Authorization' 헤더 추가
        if (access) {
            headers['Authorization'] = `Bearer ${access}`;
        }
  
        const response = await fetch(`${BASE_URL}/cart/item/${cartItemId}/`, {
          method: 'PATCH',
          headers: headers,
          credentials: 'include',
          body: JSON.stringify(data),
        });

    
        if (!response.ok) {
            const errorResponseData = await response.json();
            throw new Error(`Failed to post: ${errorResponseData.message}`);
        }
  
        const responseData = await response.json();
        return responseData;

      } catch (error) {
        throw new Error("Failed to post");
      }
    };
  
   return {
      patchQuantity,
  };
  }

// 장바구니 아이템의 옵션 삭제
  export function useCartItemRowDelete() {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const access = getAccessFromCookie();
    const cartItemRowDelete = async (cartItemId) => {
      setIsDeleteLoading(true);
      setError(null);
  
      try {
        const response = await fetch(`${BASE_URL}/cart/item/row/${cartItemId}/`, {
          method: "DELETE",
          credentials: 'include',
          
          headers: {
            'Authorization': `Bearer ${access}`, 
          },
        });
        console.log("삭제 성공")
        console.log(response)
        
        if (response?.status === 204) {
          console.log("삭제 성공 - 상태 코드 204");
        }
        console.log("-----------")
        return response;
      } catch (error) {
        setError(error);
        throw new Error(error);
      } finally {
        setIsDeleteLoading(false);
      }
    };
  
    return {
      cartItemRowDelete,
      isDeleteLoading,
      error,
    };
  }

  // 장바구니 아이템의 추가옵션 삭제
  export function useCartItemExtraDelete() {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const access = getAccessFromCookie();
    const cartItemExtraDelete = async (cartItemId) => {
      setIsDeleteLoading(true);
      setError(null);
  
      try {
        const response = await fetch(`${BASE_URL}/cart/item/extra/${cartItemId}/`, {
          method: "DELETE",
          credentials: 'include',
          
          headers: {
            'Authorization': `Bearer ${access}`, 
          },
        });
        console.log("삭제 성공")
        console.log(response)
        
        if (response?.status === 204) {
          console.log("삭제 성공 - 상태 코드 204");
        }
        console.log("-----------")
        return response;
      } catch (error) {
        setError(error);
        throw new Error(error);
      } finally {
        setIsDeleteLoading(false);
      }
    };
  
    return {
      cartItemExtraDelete,
      isDeleteLoading,
      error,
    };
  }