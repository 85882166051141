// aws-upload 이미지 업로드 수정완료 시, 삭제할 예정 : PKH 
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { BASE_URL } from '../apiConfig';

import { useState } from 'react';
import { getAccessFromCookie } from '../cookieUtils'


// 이미지 등록 
export async function uploadImage(ImageFile) {
    const access = getAccessFromCookie();
  
    try {
      console.log("eee",access);
      const response1 = await fetch(`${BASE_URL}/image/upload/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${access}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response1.ok) {
        const data1 = await response1.json();
        console.log(data1.uploadURL)
        const formData = new FormData();
        formData.append('file', ImageFile);
  
        const response2 = await fetch(data1.uploadURL, {
          method: 'POST',
          body: formData,
        });
  
        if (response2.ok) {
          const data2 = await response2.json();
          console.log('이미지 업로드 성공:', data2.result.variants[0]);
          return data2.result.variants[0]; // 이미지 업로드 후 반환된 URL
        } else {
          console.log('이미지 업로드 실패');
          return null;
        }
      } else {
        console.log('이미지 등록 URL을 얻어오는 요청이 실패했습니다.');
        return null;
      }
    } catch (error) {
      console.log('이미지 등록 오류가 발생했습니다.', error);
      return null;
    }

  }