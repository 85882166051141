import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

const PAGE_SIZE = 10;
const boundary = Math.random().toString().substr(2);

// 공지사항 상세 조회
export const fetchNoticeDetails = async (NoticeId) => {
  try {
    const response = await instance.get(`notice/${NoticeId}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 상품 qna 상세 조회
export const fetchQnaDetails = async (QnaId) => {
  try {
    const response = await instance.get(`inquiry/product/${QnaId}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};


 
// 사용자페이지 > 고객센터 > 공지사항 검색
export const handleNoticeSearch  = async ({
  searchNotice, 
  setSearchResults,
  setSearchUrl,

  }) => {
  try {
    let url = `/notice/document/?search=${searchNotice}`;

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// 사용자페이지 > 고객센터 > 상품 Q&A 검색
export const handleQnaSearch  = async ({
  searchNotice, 
  setSearchResults,
  setSearchUrl,

  }) => {
  try {
    let url = `/inquiry/product/document/?search=${searchNotice}`;

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 사용자페이지 > 고객센터 > 1:1문의 검색
export const handleOtoSearch  = async ({
  searchOto, 
  setSearchResults,
  setSearchUrl,

  }) => {
  try {
    let url = `/inquiry/onetoone/document/?search=${searchOto}`;

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 사용자 > 고객센터 > 상품 QnA 등록
export function useProductQnARegistration() {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = async (data) => {
    setIsPostLoading(true);
    setError(null);

    try {
      const response = await instance.post('/inquiry/product/', data);
      return response.data;

    } catch (error) {
      setError("Failed to post");
      throw new Error("Failed to post");
    } finally {
      setIsPostLoading(false);
    }
  };

  return {
    post,
    isPostLoading,
    error,
  };
}


// 상품 Q&A 문의글 작성페이지 >  상품 간략 조회
export const fetchProductTiny = (productId) => 
  instance.get(`product/${productId}/tiny`).then((response)=>response.data);


// 고객 > 고객센터 > 1:1 문의 등록
export const RegisterOnetoone = (data) =>
  instance.post(`inquiry/onetoone/`, data).then(response => response.data);


// 1:1 문의 상세 조회
export const fetchOnetooneDetails = async (Id) => {
  try {
    const response = await instance.get(`inquiry/onetoone/${Id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};