import { Table } from 'react-bootstrap'; 

function ProductDeliveryInfo({ productDetails }) {
    console.log(productDetails);

    return (
    <Table className="order-detail-table">
        <tbody className="fs-7">
            <tr>
                <td colSpan={1}>출고지</td>
                <td colSpan={3}>
                    [{productDetails?.delivery_info?.dispatch_location.postal_code}] 
                    {productDetails?.delivery_info?.dispatch_location.address}
                    {productDetails?.delivery_info?.dispatch_location.detail}

                    
                </td>
            </tr>
            <tr>
                <td colSpan={1}>제주/도서산간 배송가능여부</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.island_delivery_available ? "가능" : "불가능"}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>택배사</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.courier}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>배송방법</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.shipping_method == "" ? "일반배송" : "신선배송"}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>묶음배송가능여부</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.bundle_delivery_available ? "가능" : "불가능"}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>배송비 종류</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.shipping_cost_type}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>배송비</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.shipping_cost == 0 ?
                        "무료배송" : `${productDetails?.delivery_info.shipping_cost}원`}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>무료배송 조건</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.free_condition == "" ? "없음" : ""}
                </td>
            </tr>
            <tr>
                <td colSpan={1}>출고 소요일</td>
                <td colSpan={3}>
                    {productDetails?.delivery_info?.days_required_for_shipping}일
                </td>
            </tr>
        </tbody>
    </Table>
    )
}
export default ProductDeliveryInfo;
