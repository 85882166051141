/***************************************************************************************
* FileName      : NoticeList.js
* Description   : 고객 > 고객센터 > 공지사항 리스트 조회 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Routes, Route, Link , useNavigate, Outlet } from 'react-router-dom';
import Sidemenu from './Sidemenu.js';
import { useNoticeListQuery } from '../../api/services/boardApi.js';
import { handleNoticeSearch } from '../../api/services/customerApi.js'

import Pagination from './common/Pagination';
import SearchPagination from './common/SearchPagination.js'

import { NoticeRow } from "./Notice/NoticeRow.js";
import ComingSoonPage from '../components/ComingSoonPage.js';

function NoticeLayout()
{
    const { data, error, isFetching, page, setPage , pageSize , setPageSize} = useNoticeListQuery();

    const [searchNotice, setSearchNotice] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');


    console.log(data);
    console.log("-ddd")
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const onSearch = () => {
        handleNoticeSearch({
            searchNotice, 
            setSearchResults,
            setSearchUrl,
        }
        );
        setSearchConfirm('true')
    };


    return(
        <>
        <Container>
            {/* <ComingSoonPage/> */}
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'notice'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                            <Row className='m-0 p-0'>
                                <Col className='m-0 p-0'>
                                    <h3 class="m-0 p-0 fs-4 fw-bold">공지사항</h3>
                                </Col>
                            </Row>

                            {/* 검색 */}
                            <div className='mt-5 customer_search_box'>
                                <input id="search" 
                                        name="search" 
                                        type="text"
                                        value={searchNotice}
                                        onChange={(e) => setSearchNotice(e.target.value)}
                                        placeholder='제목,내용'/>

                                <a className='sBtn' onClick={onSearch}>검색</a>
                            </div> 
                                
                            {/* 테이블 컬럼명 */}
                            <div className='m-0 p-0'>
                                <Row className='border-top-dark border-bottom m-0 p-0 d-flex'>
                                    <Col md={1} className='text-center py-4 fs-12 fw-bold d-none d-md-flex justify-content-center'>글 번호</Col>
                                    <Col xs={8} md={8} className='text-center py-4 fs-12 fw-bold'>제목</Col>
                                    <Col xs={3} md={2} className='text-center py-4 fs-12 fw-bold'>작성일</Col>
                                    <Col xs={1} md={1} className='text-center py-4 fs-12 fw-bold'>조회</Col>
                                </Row>
                            </div>

                            {/* 콘텐츠 내용 */}
                            <div className='m-0 p-0'>
                                {searchResults.count > 0 ? (
                                    // 검색 결과를 렌더링하는 코드
                                    searchResults.data.map((notice, index) => (
                                        <>
                                            <NoticeRow index={index+1} notice={notice} />
                                        </>
                                        
                                    ))
                                ) : data && data.data && data.data.length > 0 ? (
                                    // 기본 데이터를 렌더링하는 코드
                                    data.data.map((notice, index) => (
                                        <>
                                            <NoticeRow index={index+1} notice={notice} />
                                        </>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="23">검색 결과가 없습니다.</td>
                                    </tr>
                                )}
        
                               
                            </div>


                        {/* 페이지내비게이션 */}
                        <section className='mt-5'>
                            {searchConfirm ? (
                                <SearchPagination
                                    count={searchResults.totalCnt}
                                    url={searchUrl}
                                    updateSearchResults={updateSearchResults}
                                />
                                
                            ) : (
                                <Pagination
                                    count={data?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                />
                            )}
                        </section>

                        </div>
                    </Col>
                    
                </div>
            </Row>  
        </Container>
                            
        </>
    )
}


export default NoticeLayout