import { BASE_URL } from '../apiConfig';
import { instance } from '../../axiosInstance/axiosConfig';
import { getAccessFromCookie } from '../cookieUtils.js';


// 상품 상세 조회
export const  fetchVendorData = () => {
  const access = getAccessFromCookie();
  const headers = {
    'Authorization': `Bearer ${access}`,
    'Content-Type': 'application/json'
  };
  return fetch(`${BASE_URL}/vendor/seller`, { headers })
    .then(res => res.json())
};

  

// 셀러 관리자 > 본인 입점사 정보 조회
// export const fetchVendorData = () => {
//     return instance.get(`/vendor/seller`)
//       .then((res) => res.data)
//       .catch((error) => {
//         console.error('에러:', error);
//         throw error; // 에러 처리를 호출자에게 위임
//       });
// };
  
export const updateVendorData = async (id, newData) => {
  try {
    await instance.put(`/vendor/${id}`, newData);
    alert('데이터 수정이 완료되었습니다.');
  } catch (error) {
    throw new Error('데이터 수정 중 오류가 발생했습니다.');
  }
};


//샐러 관리자가 입점사 정보를 수정 "요청" 할 때 사용
export const updateRequestVendorData = async (newData) => {
  const access = getAccessFromCookie();
 
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    
    if (access) {
      headers['Authorization'] = `Bearer ${access}`;
    }

    const response = await fetch(`${BASE_URL}/vendor/update/request/`, {
      method: 'POST', 
      headers: headers ,
      credentials: 'include',
      body: JSON.stringify(newData), 
    });

    if (!response.ok) {
      throw new Error('업데이트에 실패했습니다.');
    }

    return response.json(); // 업데이트된 데이터를 JSON으로 파싱하여 반환
  } catch (error) {
    console.error('업데이트 오류:', error);
    throw error; 
  }
};





//샐러 관리자가 직원 정보를 수정 "직접" 할 때 사용
export const updateEmployeeData = async (newData) => {
  const access = getAccessFromCookie();
 
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    
    if (access) {
      headers['Authorization'] = `Bearer ${access}`;
    }

    const response = await fetch(`${BASE_URL}/vendor/update/direct/`, {
      method: 'PATCH', 
      headers: headers ,
      credentials: 'include',
      body: JSON.stringify(newData), 
    });

    if (!response.ok) {
      throw new Error('업데이트에 실패했습니다.');
    }

    return response.json(); // 업데이트된 데이터를 JSON으로 파싱하여 반환
  } catch (error) {
    console.error('업데이트 오류:', error);
    throw error; 
  }
};
