/***************************************************************************************
* FileName      : MileageHistory.js
* Description   : 해와관리자 > 통계 > 마케팅 > 마일리지 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useState, useEffect } from 'react';
import { Row, Col, Button , Navbar, Nav, Container } from 'react-bootstrap';
import { useMileagehistoryQuery } from '../../../api/hwadmin/marketingApi';
import Pagination from '../common/Pagination';
import MileagRow from './Mileage/MileagRow';

import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';

function MileageHistory()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useMileagehistoryQuery(ordering,pageSize);

    console.log(data);
    console.log("---data---");
    const [searchCoupon, setSearchCoupon] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const onSearch = () => {
        // handleMileagehistorySearch({
        //     searchCoupon, 
        //     setSearchResults,
        //     setSearchUrl,
        //     searchOption,
        //     selectedDateType,
        //     selectedStartDate,
        //     selectedEndDate, 

        // });

        setSearchConfirm(true)
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };

    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };



    return(
        <>
        <div className='mainContents'>
            {/* 마일리지 */}
            <div className='page-tit'>
                <h2>마일리지</h2>
            </div>

    
            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    <div className='selectWrap'>
                        <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="최근날짜순"
                                oldLabel="오래된날짜순"
                            />

                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowSpan={2}>기준일</th>
                                <th colSpan={3}>지급한 포인트</th>
                                <th rowSpan={2}>사용한 포인트</th>
                                <th rowSpan={2}>적립 예정 포인트</th>
                                <th rowSpan={2}>미사용(보유)포인트</th>
                                <th rowSpan={2}>14일내 소멸예정 포인트</th>
                                <th rowSpan={2}>소멸된 포인트</th>
                            </tr>
                            <tr>
                                <th>상품구매</th>
                                <th>관리자 지급</th>
                                <th>합계</th>
                            </tr>
                        </thead>

                        <tbody>
                                    
                            {searchConfirm && searchResults?.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((mileage , index) => (
                                    <MileagRow 
                                        key={mileage?.id} 
                                        mileage={mileage}/>
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((mileage , index ) => (
                                    <MileagRow 
                                        key={mileage?.id} 
                                        mileage={mileage}/>
                                ))
                            ) : null}
    
                        </tbody>
                    </table>

                </div>
            </section>

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                        <Pagination
                            count={searchResults?.count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
        </div>
        </>
    )
}



export default MileageHistory