/***************************************************************************************
* FileName      : Month.js
* Description   : 해와관리자 > 통계 > 판매통계 > 일별 페이지 
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.00.00
* Modifide Date : 2024.02.23 : CHM : 일별 가입통계 캘린더 추가
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Chart from '../../../components/Chart'

import GenderCheckboxGroup from "../Component/ProduceStats/GenderCheckboxGroup.js";
import AgeCheckboxGroup from "../Component/SignupStats/AgeCheckboxGroup";
import YearSelect from "../Component/YearSelect";
import MonthSelect from "../Component/MonthSelect";
import SearchField from "../Component/ProduceStats/SearchField.js";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { getSalesDaily , handleSearchSalesDaily } from "../../../../api/hwadmin/statisticApi";

import { labelDataObject , chartBackgroundColor , chartBorderColor } from './chartConfig.js';

function Day()
{
    const [chartData,setChartData] = useState([]);

    const [isUpdated, setIsUpdated] = useState(false);
    
    const currentYear = new Date().getFullYear().toString();
    const [year, setYear] = useState(currentYear);
    const [searchYear, setSearchYear] = useState(currentYear);

    const currentMonth = (new Date().getMonth() + 1).toString(); 
    const [month, setMonth] = useState(currentMonth);
    const [searchMonth, setSearchMonth] = useState(currentMonth);


    const { isLoading, error, data } = useQuery(["signupDaily", year, month], () => getSalesDaily(year, month));

    const [searchResults, setSearchResults] = useState([]);

    // 성별 체크박스 
    const [genderCheckedState, setGenderCheckedState] = useState({
        total_sales: true,
        male_sales: true,
        female_sales: true,
    });
    
    const handleGenderCheckboxChange = (event) => {
        setGenderCheckedState({
            ...genderCheckedState,
            [event.target.name]: event.target.checked,
        });
    };

    // 연령별 체크박스 
    const [ageCheckedState, setAgeCheckedState] = useState({
        "10": false,
        "20": false,
        "30": false,
        "40": false,
        "50": false,
        "60": false,
        // "60대 이상": false,
    });
    
    
    const handleAgeCheckboxChange = (event) => {
        setAgeCheckedState({
            ...ageCheckedState,
            [event.target.name]: event.target.checked,
        });
    };


    // 단위(결제완료기준)
    const [selectedPayment , setSelectedPayment ] = useState('판매금액(원)');


    // 단위(결제완료기준) 값 변화
    const handleCategoryTypeRadioChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/produceStats/';
    };
    
    // 검색버튼 클릭 시 
    const onSearch = async  () => {
        setYear(searchYear);

        const selectedAges = Object.keys(ageCheckedState).filter((age) => ageCheckedState[age]).join(',');

        await handleSearchSalesDaily({
            setSearchResults,
            year,
            searchMonth,
            selectedAges
        });
    };

    useEffect(() => {
        if (searchResults && searchResults.labels && searchResults.labels.length > 0) {
            updateChartData(genderCheckedState);
        }
    },[searchResults]);


    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.labels, 
                datasets: [
                    {
                        label: '총 판매 금액',
                        data: data?.total_sales_price,
                        backgroundColor: '#7CCE76',
                        borderColor: '#2F9D27',
                        pointBackgroundColor: '#7CCE76',
                        pointBorderColor: '#2F9D27',
                        pointHoverBackgroundColor: 'red',
                        pointHoverBorderColor: 'red',
                    },
                    {
                        label: '여성 판매 금액',
                        data: data?.female_sales_price,
                        backgroundColor: '#F6B6C1',
                        borderColor: '#ED6E85',
                        pointBackgroundColor: '#F6B6C1',
                        pointBorderColor: '#ED6E85',
                        pointHoverBackgroundColor: 'red',
                        pointHoverBorderColor: 'red',
                    },  {
                        label: '남성 판매 금액',
                        data: data?.male_sales_price,
                        backgroundColor: '#AACFF2',
                        borderColor: '#57A0E5',
                        pointBackgroundColor: '#AACFF2',
                        pointBorderColor: '#57A0E5',
                        pointHoverBackgroundColor: 'red',
                        pointHoverBorderColor: 'red',
                    },
                ],
            };
    
            setChartData(newChartData);
        }
    },[data])

    function updateChartData(genderCheckedState)
    {
        const labels = searchResults?.labels;
        const datasets = [];
        for (const key in genderCheckedState) {
            if (genderCheckedState[key]) {
                const newKey = selectedPayment === '판매금액(원)' ? key + "_price" : key + "_count"; 

                const dataKey = searchResults?.[newKey];
                
                const backgroundColor = chartBackgroundColor[key];
                const borderColor = chartBorderColor[key];

                const label = selectedPayment === '판매금액(원)' ? 
                    labelDataObject[key]+"금액" : labelDataObject[key]+"수";

                console.log("label:",label);


                datasets.push({
                    label: label,
                    data: dataKey,
                    backgroundColor,
                    borderColor,
                    pointBackgroundColor: backgroundColor,
                    pointBorderColor: borderColor,
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                });
            }
        }
        
        const newChartData = {
            labels: labels,
            datasets: datasets,
        };
        
    
        setChartData(newChartData);
        setIsUpdated(true);
    
    }
    return (
        <>
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field date'>
                    <div> <b>기간</b> </div>
                    <div>
                        <YearSelect value={searchYear} onChange={(e) => setSearchYear(e.target.value)} />    
                        <MonthSelect value={searchMonth} onChange={(e) => setSearchMonth(e.target.value)} />
                    </div>
                </div>
            
                <div className='search-field division'>
                    <div> 
                        <b>성별</b> 
                    </div>
                    <GenderCheckboxGroup
                        checkedState={genderCheckedState}
                        onChange={handleGenderCheckboxChange}
                    />
                </div>
                <div className='search-field division'>
                    <div> 
                        <b>연령대별 필터</b> 
                    </div>
                    <AgeCheckboxGroup
                        checkedState={ageCheckedState}
                        onChange={handleAgeCheckboxChange}
                    />
                </div>

                {/* 단위(결제완료기준) */}
                <SearchField
                    selectedPayment={selectedPayment}
                    handleCategoryTypeRadioChange={handleCategoryTypeRadioChange}
                    />

              
            </div>

            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={handleResetClick}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
        </section>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <Chart data={chartData}/>
        </section>
      
        {/* 검색결과 캘린더 */}
        <section className='McontentBox' key={String(searchYear)+String(searchMonth)+selectedPayment}> 
            <DayCalendar 
                searchYear={searchYear}
                searchMonth={searchMonth}
                data={data}
                selectedPayment={selectedPayment}
            />
        </section>
    </>
    )
}

function DayCalendar({searchYear, searchMonth, data, selectedPayment}){
    // 캘린더용 날짜 변환
    const [calendarDate, setCalendarDate] = useState(`${searchYear}-${String(searchMonth).padStart(2, '0')}-01`);
    useEffect(()=>{
        setCalendarDate(`${searchYear}-${String(searchMonth).padStart(2, '0')}-01`);
    }, [searchYear, searchMonth]);

    // 캘린더 데이터 가공
    const [maleData, setMaleData] = useState();
    const [femaleData, setFemaleData] = useState();
    const [totalData, setTotalData] = useState();
    console.log('캘린더에 사용할 데이터 : ', data);
    console.log('payment : ', selectedPayment);
    useEffect(()=>{
        if (selectedPayment === '판매금액(원)'){
            setMaleData(data?.male_sales_price?.map((price, index) => ({title: `- 남성 : ${price?.toLocaleString()}원`, start: `${searchYear}-${String(searchMonth).padStart(2, '0')}-${String(index+1).padStart(2, '0')}`, backgroundColor: '#fff', textColor: '#0b6daa'})));
            setFemaleData(data?.female_sales_price?.map((price, index) => ({title: `- 여성 : ${price?.toLocaleString()}원`, start: `${searchYear}-${String(searchMonth).padStart(2, '0')}-${String(index+1).padStart(2, '0')}`, backgroundColor: '#fff', textColor: '#dc3545'})));
            setTotalData(data?.total_sales_price?.map((price, index) => ({title: `- 합계 : ${price?.toLocaleString()}원`, start: `${searchYear}-${String(searchMonth).padStart(2, '0')}-${String(index+1).padStart(2, '0')}`, backgroundColor: '#fff', textColor: '#595959'})));
        } else {
            setMaleData(data?.male_sales_count?.map((count, index) => ({title: `- 남성 : ${count?.toLocaleString()}건`, start: `${searchYear}-${String(searchMonth).padStart(2, '0')}-${String(index+1).padStart(2, '0')}`, backgroundColor: '#fff', textColor: '#0b6daa'})));
            setFemaleData(data?.female_sales_count?.map((count, index) => ({title: `- 여성 : ${count?.toLocaleString()}건`, start: `${searchYear}-${String(searchMonth).padStart(2, '0')}-${String(index+1).padStart(2, '0')}`, backgroundColor: '#fff', textColor: '#dc3545'})));
            setTotalData(data?.total_sales_count?.map((count, index) => ({title: `- 합계 : ${count?.toLocaleString()}건`, start: `${searchYear}-${String(searchMonth).padStart(2, '0')}-${String(index+1).padStart(2, '0')}`, backgroundColor: '#fff', textColor: '#595959'})));
        }
        
    },[data]);
    return (
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView='dayGridMonth' // 뷰 설정(월단위로 보이기)
            headerToolbar={{
                start: '',
                center: 'title',
                end: ''
            }}
            initialDate={calendarDate}
            locale='ko'
            events={
                maleData && femaleData && totalData && [...maleData, ...femaleData, ...totalData]
            }
        />
    )
}

export default Day