import { Row, Col, Button , Navbar, Nav, Container, Modal } from 'react-bootstrap';
import React, { useRef, useEffect , useState } from 'react';
import { useQuery , useMutation } from 'react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import { vendorgetData } from '../../../api/storeApi';


import CategorySearch from "./Copy/CategorySelector/CategorySearch";
import SelectCategory from "./Copy/CategorySelector/SelectCategory";

import SingleProduct from "./Copy/OptionGroup/SingleProduct";

//옵션
import UseOption from "./Copy/OptionGroup/UseOption";

import AvailableProducts from "./Copy/AdditionalProducts/AvailableProducts";
import NoAdditionalProducts from "./Copy/AdditionalProducts/NoAdditionalProducts";

import ProductImageUpload from "./Copy/ProductImage/ProductImageUpload";
import SearchWord from "./Copy/SearchWord/SearchWord";

import ProductFilter from "./Copy/ProductFilter/ProductFilter";
import Usage from "./Copy/Usage/Usage";

import ProductDetails from "./Copy/ProductDetails/ProductDetails";
import ProductInfoNotice from "./Copy/ProductInfoNotice/ProductInfoNotice";

import DeliveryDetails from "./Copy/DeliveryDetails/DeliveryDetails";

import ReturnExchange from "./Copy/ReturnExchange/ReturnExchange";

import DocumentUpload from "./Copy/DocumentUpload/DocumentUpload";



// 2024.01.17 : CHM : 에디터 컴포넌트 적용
import DetailedDescription from '../../components/Editor.js';
// import DetailedDescription from "./Copy/DetailedDescription/Editor";


import { useApprovedVendors } from '../../../api/hwadmin/productApi';

import { useProductRegistration } from '../../../api/hwadmin/productApi';

// 상품 이미지
import { uploadProductImage , fetchImageFileUpload } from '../../../api/hwadmin/productApi';

// 이미지등록
// import { uploadImage } from '../../../api/uploadImage/uploadApi';
// aws 이미지등록
import { getUploadURL , uploadImage } from '../../../api/uploadImage/uploadImageApi.js';

// 상품 조회 
import fetchProductData from '../../../api/product/fetchProductData';
// 상품 데이터 수정
import updateProductData from '../../../api/product/updateProductData';

// 해와관리자 > 상품관리 > 상품 수정 페이지
function ProductCopy()
{
    const { post, isPostLoading, error } = useProductRegistration();

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
            <div className="parent-container">
                <div className="spinner" ></div>
            </div>
        );
    };

    // id 추출
    const { productId } = useParams();

    // 데이터 조회
    const { data, isError } = useQuery(['productData', productId], () => fetchProductData(productId));

    // 데이터 수정
    // const updateDataMutation = useMutation((newData) => updateProductData(productId, newData));
   
    // useEffect(()=>{
        console.log("---상품데이터--")
        console.log(data)
    
    // },[data])
 

   
    // 미리보기 모달
    const [contentModal, setContentModal] = useState(false);

    // 상품명
    const [productName, setProductName] =  useState();

    
    //price , original_price , stock 
    // 옵션사용여부 > 단일상품
    const [SingleProductData , setSingleProductData] = useState({
        regularPrice : null,
        salePrice : null,
        stockQuantity : null
    });



    // const handleUpdate = (event) => {
    //     event.preventDefault(); // 기본 폼 제출 동작 방지
      
    //     const newData = {
    //         name : productName || "",
    //         vendor: selectedVendor.id || "",
    //     };
      
      
        
    //     updateDataMutation.mutate(newData)
    //     .then(() => {
    //         // history.push('/admin/store/inquiry'); // 데이터 업데이트가 성공한 경우 페이지 이동
    //     })
    //     .catch((error) => {
    //         // 데이터 업데이트 실패 처리
    //     });

    // };  

    // 수산상회 조회
    const { data: approvedVendors, isLoading: isApprovedVendorsLoading, isError: isApprovedVendorsError } = useApprovedVendors();
    // 선택한 수산상회 
    //const [selectedVendor, setSelectedVendor] = useState({ id: data ? data.vendor.id : '', name: data ? data.vendor.name :'' });
    const [selectedVendor, setSelectedVendor] = useState({ id: data && data.vendor ? data.vendor.id : '', name: data && data.vendor ? data.vendor.name : '' });

    const [ProductNameLenth, setProductNameLenth] = useState('');


    // 카테고리선택 탭 버튼
    const [CategoryActiveComponent, setCategoryActiveComponent] = useState("SelectCategory");

    // 선택한 카테고리 ID arr
    const [categoryId_arr , setCategoryIdArr] = useState([]);



    // 선택한 상품 필터 ID Array
    const [filteringCheck , setFilteringCheck ] = useState([]);

    // 선택한 용도 저장
    const [ usages, setUsage ]= useState([]);

    // 조회용 용도 데이터
    const [viewUsage, setViewUsage] = useState([]); 
  
    // 옵션사용여부 탭 버튼
    const [UseOptionActiveComponent, setUseOptionComponent] = useState("UseOption");
// 
    // 옵션
    // const [optionValues, setOptionValues] = useState([{ name: '', value: '' }]);


    const [option_values, setOptionValues] = useState({});

    const [option_rows, setOptionRows] = useState([]);



    // 추가상품 탭 버튼
    const [AvailableProductsActiveComponent, setAvailableProductsActiveComponent] = useState("NoAdditionalProducts");


    // 상품이미지 저장 
    // const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(6).fill(''));
    const [ProductMainImageFile, setProductMainImageFile] = useState([]);
    const [productSubImageFiles, setProductSubImageFiles] = useState([]);

    const [uploadedImages, setUploadedImages] = useState({
        main_image: null,
        sub_image: [],
        document_images:[],
    });
 
    // 메인 이미지 url 저장
    const [uploadedMainUrl, setUploadedMainUrl] = useState();


    // 서브 이미지 저장 객체
    const productSubImages = {};

    // 상세설명 
    const [detailedDescription, setDetailedDescription] = useState('');

    // 추가상품
    const [additionalItems, setAdditionalItems] = useState([]);
    
    // 검색어(필수)
    const [metaTags, setMetaTags] = useState('');

    // 조회용 검색어
    const [viewMetaTags, setViewMetaTags] = useState([]);

    // 상품필터 
    const [filterItems, setfilterItems] = useState([]);

    // 상품주요정보(필수)
    const [inspectInfoData, setInspectInfoData] = useState({
        manufacturer: "",
        certification_target:"",
        minor_purchase: false,
        max_purchase_per_person: "",
        sales_start_date: "",
        sales_end_date: "",
        tax :""
    });

    // 상품주요정보 inspectInfoData 값 업데이트하는 함수
    const handleInspectInfoDataChange = (newInspectInfoData) => {
        console.log("주요정보",JSON.stringify(newInspectInfoData));
        setInspectInfoData(newInspectInfoData);
    };
    // 상품정보제공고시 선택 옵션
    const [prdIfSelected, setprdIfSelected] = useState(''); // 초기 선택 값


 
    // 상품정보제공고시(농축수산물) 값 업데이트하는 함수 
    const handleAquaticInfoDataChange = (newAquaticInfoData) => {
        console.log("상품정보제공고시",JSON.stringify(newAquaticInfoData));
        setAquaticInfoData(newAquaticInfoData);
    };

    // 상품정보제공고시(가공식품) 값 업데이트하는 함수 
    const handleProcessedfoodInfoDataChange = (newProcessedfoodInfoData) => {
        console.log("상품정보제공고시",JSON.stringify(newProcessedfoodInfoData));
        setProcessedfoodInfo(newProcessedfoodInfoData);
    };

    // 상품정보제공공시(필수) : 농축수산물
    const [aquaticInfoData , setAquaticInfoData] = useState({ 
        name : "", // 품목 또는 명칭
        volume : "", // 용량,수량, 크기
        manufacturer : "", // 생산자(수입자)
        origin : "", // 원산지
        manufactured_date : "", // 제조연월일
        mark_detail : "" , // 세부 품목군별 표시사항
        imported_food_text : "", // 수입식품 문구 여부 
        package : "", // 상품구성
        storage_method:"", // 보관방법 또는 취급방법
        caution:"", // 소비자안전을 위한 주의사항
        cs:"", // 소비자 상담 관련 전화번호 
    });


    // 조회용 상품정보제공공시(필수) :  농축수산물
    const [viewAquaticInfoData , setViewaquaticInfoData] = useState({ 
        name : "", // 품목 또는 명칭
        volume : "", // 용량,수량, 크기
        manufacturer : "", // 생산자(수입자)
        origin : "", // 원산지
        manufactured_date : "", // 제조연월일
        mark_detail : "" , // 세부 품목군별 표시사항
        imported_food_text : "", // 수입식품 문구 여부 
        package : "", // 상품구성
        storage_method:"", // 보관방법 또는 취급방법
        caution:"", // 소비자안전을 위한 주의사항
        cs:"", // 소비자 상담 관련 전화번호 
    });

    // 상품정보제공공시(필수) : 가공식품
    const [processedfoodInfo , setProcessedfoodInfo] = useState({
        name : "", //상품명
		foodtype : "", //식품의 유형
		manufacturer : "", //생산자 및 소재지 
		manufactured_date : "", //제조연월일
		volume : "", // 포장단위별 내용물의 용량, 수량
		material : "", // 원재료명
		nutrition : "", // 영양성분
		mark_detail : "", // 유전자 변형 수산물
		caution : "", // 소비자 안전을 위한 주의사항
		imported : "", // 수입식품 여부에 따라 수입신고 
		cs : "" // 소비자 상담 관련 전화번호
    });

    // 조회용
    const [viewProcessedfoodInfo , setViewprocessedfoodInfo] = useState({
        name : "", //상품명
		foodtype : "", //식품의 유형
		manufacturer : "", //생산자 및 소재지 
		manufactured_date : "", //제조연월일
		volume : "", // 포장단위별 내용물의 용량, 수량
		material : "", // 원재료명
		nutrition : "", // 영양성분
		mark_detail : "", // 유전자 변형 수산물
		caution : "", // 소비자 안전을 위한 주의사항
		imported : "", // 수입식품 여부에 따라 수입신고 
		cs : "" // 소비자 상담 관련 전화번호
    });


    // 배송정보(필수)
    const [deliveryInfoData , setDeliveryInfoData] = useState({
        dispatch_location: {
            address: "",
            detail: "",
            postal_code: "",
            address_type: ""
        }, //출고지
        island_delivery_available:"false", // 제주/도서산간 배송가능여부
        courier:"CJ대한통운", // 택배사
        shipping_method:"일반배송", //배송방법
        shipping_cost_type:"무료배송", //배송비 종류
        bundle_delivery_available:"false", //묶음배송 가능여부
        shipping_cost_count: 1, // 합배송조건
        shipping_cost:0, //배송비
        days_required_for_shipping:"2", //출고 소요일
        free_condition:0, // 무료배송 조건( ~원 이상 무료)
    });
    
    // 구비서류 
    const [files, setFiles] = useState([]);

    // 조회용 구비서류 
    const [viewFiles , setViewFiles] = useState([]);

    // 구비서류 업데이트하는 함수 
    // const handleFilesChange = (newFilesData) => {
    //     console.log("구비서류",JSON.stringify(newFilesData));
    //     setFiles(newFilesData);
    // };

    // 배송정보 값 업데이트 함수 
    const handleDeliveryInfoDataChange = (newDeliveryInfoData) => {
        console.log("배송정보",JSON.stringify(newDeliveryInfoData));
        setDeliveryInfoData(newDeliveryInfoData);
    };


    // AvailableProducts 컴포넌트로 전달할 함수
    const handleOptionApply = (newAdditionalItem) => {
        setAdditionalItems([...additionalItems, newAdditionalItem]);
    };

    // 교환반품(필수)
    const [returnexChangeData , setReturnexChangeData] = useState({
        use_basic: true,
        return_address:{
            postal_code: "",
            address: "",
            detail: "",
            address_type: ""
        },
        initial_delivery_fee: "",
        return_delivery_fee: "",
    });

    
    // AvailableProducts 컴포넌트에서 사용할 데이터
    const availableProductsData = {
        additionalItems,
        handleOptionApply,
    };
    console.log(availableProductsData)

    // 상품이미지 저장
    const handleImageUpload = (imageFiles,index) => {
        if(index===0){
            // 메인이미지
            setProductMainImageFile(imageFiles);
        }else{
             // 서브이미지 
             const newFiles = [...productSubImageFiles]; 

             // 이미 같은 index의 데이터가 있는지 확인하고 있다면 삭제
             const indexToRemove = newFiles.findIndex(item => item.index === index);
             if (indexToRemove !== -1)  newFiles.splice(indexToRemove, 1);
             
             const newObj = { index : index, image: imageFiles };
             newFiles.push(newObj);
             setProductSubImageFiles(newFiles);
        }
        
        console.log(imageFiles);
        
    };

    // 상품 추가이미지 삭제 
    const handleImageDelete = (indexToDelete) =>{
        if(indexToDelete > 0){
            const newFiles = productSubImageFiles.filter(item => item.index !== indexToDelete);
            setProductSubImageFiles(newFiles);
        }
    }
    
  

    const handleCategoryButtonClick = (componentName) => {
        setCategoryActiveComponent(componentName);
    };

    const handleUseOptionButtonClick = (componentName) => {
        setUseOptionComponent(componentName);
    };

    
    // 추가상품 사용함, 사용안함 버튼 
    const handleAvailableButtonClick = (componentName) => {
        const userConfirmed = window.confirm(
            "변경시, 다시 추가상품 정보를 등록해야합니다. 그래도 실행하시겠습니까?"
        );
        
        if (userConfirmed) {
            setAvailableProductsActiveComponent(componentName);
            setAdditionalItems([]);
        }

    };

    
    const onChangeName = (e) => {
        const name = e.target.value;

        if (name.length <= 100) {
            setProductName(name);
            setProductNameLenth(name.length);
        } else {
            alert('상품명의 길이는 100자 까지 입력가능합니다.');
        }
        
    };

    const [toggle , setToggle] = useState(
        {
            select:true,
            productName:true,
            categorySelection:true,
            options:true,
            image:true,
            additionalProduct:true,
            detailedDescription:true,
            searchWord:true,
            productFilter:true,
            usage:true,
            keyInformation:true,
            notification:true,
            delivery:true,
            exchange:true,
            documents:true
        }
    )
    

    const toggleState = (event) =>{
        const newTabState = {...toggle};
        const activeTab = event.currentTarget.id;

        setToggle((prevState) => ({
            ...prevState,
            [activeTab]: !newTabState[activeTab]
        }));
    }


    console.log(approvedVendors)
     
    // 스크롤을 해당 요소로 이동하는 함수
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    };


     // 유효성 검사
     const validateForm = () => {
        let isValid = true;

        // 상품명 확인
        if(productName===''){
            alert("상품명은 필수항목 입니다.")

            // 스크롤을 해당 요소로 이동
            scrollToElement('select');
            return false;
        }

        // 카테고리 확인
        if (categoryId_arr[0] === undefined) {   
            alert("카테고리 선택은 필수항목 입니다.")

            // 스크롤을 해당 요소로 이동
            scrollToElement('select');
            return false;
        }
        if (categoryId_arr[2] === undefined) {   
            alert("카테고리 소분류 선택은 필수항목 입니다.")

            // 스크롤을 해당 요소로 이동
            scrollToElement('select');
            return false;
        }

        // 상품 이미지 확인
        // if (ProductMainImageFile.length === 0) {
        //     isValid = false;
        //     alert("상품이미지 대표이미지는 필수항목 입니다.");
               
        //     // 스크롤을 해당 요소로 이동
        //     scrollToElement('additionalProduct');
        //     return false;
        // }

        // 상세 설명 확인
        if(detailedDescription === null){
            isValid = false;
            alert("상세 설명은 필수항목 입니다.");
               
            // 스크롤을 해당 요소로 이동
            scrollToElement('productImage');
            return false;
        }

        // 상품 주요정보 확인 
        if(!inspectInfoData.manufacturer) {
            isValid = false;
            alert("상품주요정보 > 제조사 입력은 필수항목 입니다.");
            
            // 스크롤을 해당 요소로 이동
            scrollToElement('searchWord');
            return false;
        }

        //상품정보제공고시 선택 확인
        if(prdIfSelected===""){
            isValid = false;
            alert("상품정보제공고시 입력은 필수항목 입니다.");
            scrollToElement('keyInformation');
            return false;
        }
    
        // 배송정보 출고지 주소 입력 확인
        if(!deliveryInfoData.dispatch_location.address){
            isValid = false;
            alert("배송정보 출고지 주소는 필수항목 입니다.");

            // 스크롤을 해당 요소로 이동
            scrollToElement('notification');
            
            return false;
        }

        if(!deliveryInfoData.dispatch_location.detail){
            isValid = false;
            alert("배송정보 출고지 상세 주소는 필수항목 입니다.");

            // 스크롤을 해당 요소로 이동
            scrollToElement('notification');
            
            return false;
        }

        // 반품교환 > 반품교환지 입력 확인
        if(!returnexChangeData.return_address.address){
            isValid = false;
            alert("반품 교환지 주소는 필수항목 입니다.");            
            return false;
        }
        if(!returnexChangeData.return_address.detail){
            isValid = false;
            alert("반품 교환지 상세 주소는 필수항목 입니다.");            
            return false;
        }

        // 반품교환 > 초도배송비, 반품배송비 입력 확인
        
        if(!returnexChangeData.initial_delivery_fee){
            isValid = false;
            alert("초도배송비 입력은 필수항목 입니다.");
          
            return false;

        }

        if(!returnexChangeData.return_delivery_fee){
            isValid = false;
            alert("반품배송비 입력은 필수항목 입니다.");            
            return false;
        }
        


        return isValid;
    }


    // 선택한 수산상회 저장
    const handleSelectChange = (event) => {
        const selectedOption = approvedVendors.data.find(
          (vendor) => vendor.name === event.target.value
        );
        setSelectedVendor({ id: selectedOption.id, name: selectedOption.name });
    };
      
    // 상품주요정보

    // 검색어 추가
    const handleSearchWord = (newMetaTag) => {
        // const updatedMetaTags = [...metaTags, newMetaTag];
        setMetaTags(newMetaTag);
    };
    

    // 옵션 
    const handleOption = (option) => {
        console.log("가져옴:"+option)

    };


    // 이미지 파일 업로드
    const uploadImages = async () => {  
        try {
            let check = false;

            //메인 이미지 저장
            if(ProductMainImageFile.length === 0 ){
                check=true;
            }

            if(!check && !ProductMainImageFile.length){
                const mainImageURL = await getUploadURL(ProductMainImageFile);
                if (mainImageURL) {
                    const imageURL = await uploadImage(mainImageURL);
                    uploadedImages.main_image = imageURL;
                }

            }else{
                // 메인 이미지 변경 없을 때 
                if(data && data.main_image) uploadedImages.main_image = data.main_image;
            }
    
            //서브 이미지 저장
            if(productSubImageFiles.length > 0){
                const updatedSubImages = [];
            
                for (const subImageFile of productSubImageFiles) {
                    if (subImageFile.image instanceof File) {
                        const subImageURL = await getUploadURL(subImageFile.image);

                        if (subImageURL) {
                            const imageURL = await uploadImage(subImageURL);
                            updatedSubImages.push(imageURL);
                        }

                    }else{
                        updatedSubImages.push(subImageFile.image);
                    }
                }

                console.log("updatedSubImages::",updatedSubImages);
                uploadedImages.sub_image = updatedSubImages;
            }

            // 구비서류 저장
            if(files.length > 0){
                const filesImages = [];
             
                for(const fileImageFile of files){
                    if(typeof fileImageFile === 'object' ){

                        const fileImageURL = await getUploadURL(fileImageFile);

                        if (fileImageURL) {
                            const imageURL = await uploadImage(fileImageURL);
                            filesImages.push(imageURL);
                        }

                    }else{
                        filesImages.push(fileImageFile);
                    }
                }
                uploadedImages.document_images = filesImages;
            }
       
        } catch (error) {
            console.error("이미지 업로드 에러:", error);
          }
      };
      

        //   useEffect(()=>{
    //     console.log("밖categoryId_arr,",categoryId_arr)
    //   },[categoryId_arr])

    // 데이터 복사
    const handleCopy = async () => {
        if (validateForm()) {
            try {
                setIsLoading(true); 

                // 이미지 파일이 수정 되었을 시 만 이미지 업로드 함수 호출
                await uploadImages();
                
                const newData = {
                    vendor: selectedVendor.id || "", // 수산상회
                    name : productName || "",  // 상품명
                    category : [...categoryId_arr, ...filteringCheck] || [], // 카테고리 + 상품필터 
                    product_filter: usages || [], // 용도

                    // 단일상품일때
                    ...(SingleProductData.salePrice && {
                        price: parseInt(SingleProductData.salePrice.replace(/,/g, '')) 
                    }),
                    ...(SingleProductData.regularPrice && {
                        original_price: parseInt(SingleProductData.regularPrice.replace(/,/g, ''))
                    }),
                    ...(SingleProductData.stockQuantity && {
                        stock: parseInt(SingleProductData.stockQuantity.replace(/,/g, ''))
                    }),

                    option_values: option_values || "", // 옵션사용
                    option_rows : option_rows || "",

                    extra_options : availableProductsData.additionalItems || "", // 추가상품

                    main_image: uploadedImages.main_image || "", // 상품이미지 > 대표이미지 
                    sub_images: uploadedImages.sub_image || "" , // 상품이미지 > 서브이미지

                    detail : detailedDescription || "", // 상세설명
                    meta_tag: metaTags || "", // 검색어
        
                    inspect_info: { ...inspectInfoData }, // 상품주요정보 복사

                    aquatic_info: prdIfSelected === "농축수산물" ?  aquaticInfoData : undefined, // 상품정보제공고시
                    processedfood_info:  prdIfSelected === "가공식품" ? processedfoodInfo : undefined, // 상품정보제공고시
                    delivery_info: deliveryInfoData || "", //배송정보
                
                    return_exchange_info: returnexChangeData || "", //반품교환
                    
                    files : uploadedImages.document_images || "" , //구비서류
                };

    
                // 서브이미지가 [] 비어있을때 빈배열 보내야되는데 그 처리가 아직 안되어있음 

                // files 필드가 비어있을 때 제거
                if(!newData.files){
                    delete newData.files;
                }
                
                // inspectInfoData에 sales_start_date와 sales_end_date 필드가 비어있을 때만 제거
                if (!newData.inspect_info.sales_start_date) {
                    delete newData.inspect_info.sales_start_date;
                }
                if (!newData.inspect_info.sales_end_date) {
                    delete newData.inspect_info.sales_end_date;
                }

                // inspectInfoData에 max_purchase_per_person 필드가 비어있을 때만 제거 
                if (!newData.inspect_info.max_purchase_per_person) {
                    delete newData.inspect_info.max_purchase_per_person;
                }

                console.log("newData:",newData)

                post(newData)
                .then(() => {
                    alert("등록 성공");
                    console.log("상품등록 성공!");
                    setIsLoading(false); // 로딩 상태를 비활성화
                    window.location.href = '/admin/product/request/'; // 페이지 리로드
                })
                .catch(error => {
                    console.log("등록 실패:", error.message);
                    setIsLoading(false); // 로딩 상태를 비활성화
                });


            } catch (error) {
        
                console.error('업데이트 오류:', error);

            }
        }
    };



    // 기존 데이터 조회
    useEffect(() => {
        // 1. 수산상회 선택
        if (data && data.vendor && data.vendor.id && data.vendor.name) {
            setSelectedVendor({ id: data.vendor.id, name: data.vendor.name });
        }
        
        // 2. 상품명
        if (data) {
          setProductName(data.name);
        }

        // 3. 카테고리 (처음부터 선택)
        // 4. 상품필터 (처음부터 선택)
      

          
        // 5. 용도
        if( data && data.product_filter ){
            const filteredNames = data.product_filter
            .filter(item => isNaN(parseInt(item.name, 10)))
            .map(item => item.name);

            setViewUsage(filteredNames);
        }


        // 6. 옵션사용여부 (처음부터 선택)
        // 6-1. 옵션사용일 경우

        // 6-2. 단일상품일 경우 정상가, 판매가, 재고수량
 
        // 7. 추가상품
        if(data && data.extra_options){
            setAdditionalItems(data.extra_options); // 데이터 저장

            if(data.extra_options.length===0){
                setAvailableProductsActiveComponent("NoAdditionalProducts");
            }else{
                // setToggle((prevToggle) => ({ ...prevToggle, additionalProduct: true })); // 버튼 활성화
                setAvailableProductsActiveComponent("AvailableProducts"); // 컴포넌트 보여주기
            }
        
        }

        // 8. 상품이미지 > 메인이미지,서브이미지
        if (data && data.main_image) {
            let subImages = [];
            let subImagesObj = [];

            if (data && data.sub_images.length > 0) {
            //   subImages = data.sub_images.map((subImg) => subImg.path);

              subImagesObj = data.sub_images.map((subImg, index) => ({
                index: index + 1,
                image: subImg.path,
              }));
            }
          
            setUploadedImages({
              main_image: data.main_image,
            });

            setProductSubImageFiles(subImagesObj);
        }
          


        // 9. 상세설명
        if(data && data.detail){
            setDetailedDescription(data.detail);
        }

        // 10. 검색어 
        if(data && data.meta_tag.length > 0){
            const metaTagsArray = data.meta_tag.split(',');
            setViewMetaTags(metaTagsArray);
        }

        // 11. 상품주요정보 
        if(data && data.inspect_info){
            setInspectInfoData(data.inspect_info)
        }

        // 12. 상품정보제공공시
        // 12-1 . 농축수산물 경우 
        if(data && data.aquatic_info){
            setprdIfSelected('농축수산물');
            setViewaquaticInfoData(data.aquatic_info);
              
        }
        // 12-2 . 가공식품 경우 
        if(data && data.processedfood_info){
            setprdIfSelected('가공식품');
            setViewprocessedfoodInfo(data.processedfood_info);
        }

        // 13. 배송정보
        if (data && data.delivery_info) {
            setDeliveryInfoData(data.delivery_info);
        }
 
        // 14. 반품교환 
        if(data && data.return_exchange_info){
            setReturnexChangeData(data.return_exchange_info);
        }

        // 15. 구비서류
        if(data && data.files){
            const paths = data.files.map((file) => file.path);
            setFiles(paths);
        }
       
    }, [data]);


    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>상품복사</h2>
            </div>
        
            {/* 복사 */}           
            <section className='product-register-wrap'>
                <div className='product-reguster-inner'>
                    <div className='accordion'>
                        <div className='accordion-item'>
                            <div className='title' id='select' onClick={toggleState}>
                                <p>수산상회 선택 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>

                            <div className={toggle.select ? 'content-show' : 'content'} >  
                                <select className='mSelectBox' name="vendor"  value={selectedVendor.name} onChange={handleSelectChange}>
                                    { approvedVendors && approvedVendors.data.map(vendor => (
                                        <option key={vendor.id} value={vendor.name}>
                                            {vendor.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                      
                        <div className='accordion-item'>
                            <div className='title' id='productName' onClick={toggleState}>
                                <p>상품명 <span>*</span></p>
                                <i className={toggle.productName ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>

                            <div className={toggle.productName ? 'content-show' : 'content'} >  
                                <input type="text" 
                                        name="name"
                                        className='form-input'
                                        value={productName}
                                        placeholder='상품명을 입력해 주세요'
                                        onChange={onChangeName}/>    

                                <span className='productNameL'>{ProductNameLenth}</span>
                                <span>/100</span>
                            </div>
                        </div>

                        <div className='accordion-item'>
                            <div className='title' id='categorySelection' onClick={toggleState}>
                                <p>카테고리선택 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            
                            <div className={toggle.categorySelection ? 'content-show' : 'content'} >  
                                <div className='m-btn-wrap-sm btn-left'>    
                                    {/* <button className={CategoryActiveComponent === "CategorySearch" ? "active-button" : "m-sm-btn"}
                                            onClick={() => handleCategoryButtonClick("CategorySearch")}>
                                            카테고리 검색
                                    </button> */}

                                    <button className={CategoryActiveComponent === "SelectCategory" ? "active-button" : "m-sm-btn"}
                                            onClick={() => handleCategoryButtonClick("SelectCategory")}>
                                            카테고리 선택
                                    </button>
                                </div>

                                  {/* 카테고리 검색 */}
                                  {/* {CategoryActiveComponent === "CategorySearch" &&
                                    <CategorySearch
                                        categoryId_arr={categoryId_arr}
                                        setCategoryIdArr={setCategoryIdArr}
                                    />
                                }
                                         */}

                                {/* 카테고리 선택 */}
                                {CategoryActiveComponent === "SelectCategory" && 
                                    <SelectCategory 
                                        categoryId_arr={categoryId_arr}
                                        setCategoryIdArr={setCategoryIdArr}
                                    />
                                }
                            </div>
                        </div>

                        <div className='accordion-item'>
                            <div className='title' id='productFilter' onClick={toggleState}>
                                <p>상품필터 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                           
                            {categoryId_arr.length === 3 && (
                            <div className={toggle.productFilter ? 'content-show' : 'content'}>
                                <ProductFilter 
                                    categoryId_arr={categoryId_arr}  
                                    setCategoryIdArr={setCategoryIdArr}                                
                                    filteringCheck={filteringCheck}
                                    setFilteringCheck={setFilteringCheck}
                                  />
                            </div>
                            )}
                                
                        </div>

                        <div className='accordion-item' id="filterBox">
                            <div className='title' id='productFilter' onClick={toggleState}>
                                <p>용도 <span>*</span></p>
                                <i className={toggle.usage ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            <div className={toggle.usage ? 'content-show' : 'content'} >  
                                <Usage
                                    viewUsage={viewUsage}
                                    usages={usages}
                                    setUsage={setUsage}
                                />
                            </div>
                        </div>



                       <div className='accordion-item'>
                            <div className='title' id='options' onClick={toggleState}>
                                <p>옵션사용여부 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>

                            <div className={toggle.options ? 'content-show' : 'content'} >  
                                <div className='m-btn-wrap-sm btn-left'>    
                                    <button className={UseOptionActiveComponent === "UseOption" ? "active-button" : "m-sm-btn"}
                                            onClick={() => handleUseOptionButtonClick("UseOption")}>
                                            옵션사용
                                    </button>

                                    <button className={UseOptionActiveComponent === "SingleProduct" ? "active-button" : "m-sm-btn"}
                                            onClick={() => handleUseOptionButtonClick("SingleProduct")}>
                                            단일상품
                                    </button>
                                </div>

                               {/* 옵션사용 */}
                               {UseOptionActiveComponent === "UseOption" &&
                                    <UseOption 
                                        option_values={option_values}
                                        setOptionValues={setOptionValues}
                                        option_rows={option_rows}
                                        setOptionRows={setOptionRows} 
                                    />}
                                        
                                {/* 단일상품 */}
                                {UseOptionActiveComponent === "SingleProduct" && 
                                    <SingleProduct  
                                        SingleProductData={SingleProductData} 
                                        setSingleProductData={setSingleProductData}
                                    />}

                            </div>
                             
                        </div>

                        <div className='accordion-item'>
                            <div className='title' id='additionalProduct' onClick={toggleState}>
                                <p>추가상품<span></span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            
                            <p className='notice-text'>
                                추가상품은 등록된 상품과 함께 구성하면 좋은 상품이 있는 경우 추가해주세요.
                                <br/>
                                (예시) 초장, 매운탕 양념, 천사채
                            </p>

                            <div className={toggle.additionalProduct ? 'content-show' : 'content'} >  
                                <div className='m-btn-wrap-sm btn-left'>    
                                    <button className={AvailableProductsActiveComponent === "AvailableProducts" ? "active-button" : "m-sm-btn"}
                                            onClick={() => handleAvailableButtonClick("AvailableProducts")}>
                                            사용함
                                    </button>

                                    <button className={AvailableProductsActiveComponent === "NoAdditionalProducts" ? "active-button" : "m-sm-btn"}
                                            onClick={() => handleAvailableButtonClick("NoAdditionalProducts")}>
                                                사용안함
                                    </button>
                                </div>

                                {/* 사용함 */}
                                {AvailableProductsActiveComponent === "AvailableProducts"
                                     &&  <AvailableProducts availableProductsData={availableProductsData} />}
                                        
                                {/* 사용안함 */}
                                {AvailableProductsActiveComponent === "NoAdditionalProducts" && <NoAdditionalProducts  />}
                            </div>
                        </div>

                        <div className='accordion-item'>
                            <div className='title' id='image' onClick={toggleState}>
                                <p>상품이미지 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            
                            <div className={toggle.image ? 'content-show' : 'content'} >  
                                <ProductImageUpload  
                                    uploadedImages={uploadedImages}
                                    productSubImageFiles={productSubImageFiles}
                                    onImagesUpload={handleImageUpload}
                                    onImagesDelete={handleImageDelete}
                                    />
                            </div>
                        </div>

                        <div className='accordion-item'>
                            <div className='title' id='detailedDescription' onClick={toggleState}>
                                <p>상세설명 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            <div className={toggle.detailedDescription ? 'content-show' : 'content'} >  
                                <DetailedDescription 
                                    content={detailedDescription}
                                    setContent={setDetailedDescription}
                                    type={'product'}
                                />
                            </div>
                            {/* <p onClick={()=>console.log("콘텐츠 html 태그 : "+detailedDescription)}>콘텐츠 미리보기</p> */}
                            <p className="border-hw rounded-2 my-2 py-2 text-center text-hw" onClick={()=>setContentModal(true)}>콘텐츠 미리보기</p>
                        </div>


                        <div className='accordion-item'>
                            <div className='title' id='searchWord' onClick={toggleState}>
                                <p>검색어 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            <div className={toggle.searchWord ? 'content-show' : 'content'} >  
                                <SearchWord 
                                    viewMetaTags={viewMetaTags}
                                    onSearchWord={handleSearchWord}/>
                            </div>
                        </div>
                            
                        <div className='accordion-item'>
                            <div className='title' id='keyInformation' onClick={toggleState}>
                                <p>상품주요정보 <span>*</span></p>
                                <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                            </div>
                            <div className={toggle.keyInformation ? 'content-show' : 'content'} >  
                                <ProductDetails 
                                    inspectInfoData={inspectInfoData}
                                    setInspectInfoData={setInspectInfoData}
                                    onInspectInfo={handleInspectInfoDataChange}/>
                            </div>
                        </div>

                            <div className='accordion-item'>
                                <div className='title' id='notification' onClick={toggleState}>
                                    <p>상품정보제공고시 <span>*</span></p>
                                    <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>
                                <div className={toggle.notification ? 'content-show' : 'content'} >  
                                    <ProductInfoNotice 
                                        prdIfSelected={prdIfSelected}
                                        setprdIfSelected={setprdIfSelected}
                                        onAquaticInfo={handleAquaticInfoDataChange}
                                        onProcessedfoodInfo={handleProcessedfoodInfoDataChange}
                                        viewAquaticInfoData={viewAquaticInfoData}
                                        viewProcessedfoodInfo={viewProcessedfoodInfo}
                                    />
                                </div>
                            </div>

                            <div className='accordion-item'>
                                <div className='title' id='delivery' onClick={toggleState}>
                                    <p>배송정보 <span>*</span></p>
                                    <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>
                                <div className={toggle.delivery ? 'content-show' : 'content'} >  
                                    <DeliveryDetails 
                                        deliveryInfoData={deliveryInfoData}
                                        setDeliveryInfoData={setDeliveryInfoData}
                                        />
                                </div>
                            </div>

                            <div className='accordion-item'>
                                <div className='title' id='exchange' onClick={toggleState}>
                                    <p>반품교환 <span>*</span></p>
                                    <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>
                                <div className={toggle.exchange ? 'content-show' : 'content'} >  
                                    <ReturnExchange 
                                        returnexChangeData={returnexChangeData}
                                        setReturnexChangeData={setReturnexChangeData}
                                    />
                                </div>
                            </div>

                            <div className='accordion-item'>
                                <div className='title' id='documents' onClick={toggleState}>
                                    <p>구비서류</p>
                                    <i className={toggle.select ? 'xi-angle-up' : 'xi-angle-down'}></i>
                                </div>
                                <div className={toggle.documents ? 'content-show' : 'content'} >  
                                    <DocumentUpload 
                                        files={files} 
                                        setFiles={setFiles}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                   
            </section>


            <div className='m-btn-wrap'>
                <button onClick={handleCopy} className='m-btn m-btn-search'>
                    {isLoading ?  <LoadingSpinner /> : '상품복사'} 
                </button>
            </div> 
            <ContentModal show={contentModal} setShow={setContentModal} tag={detailedDescription}/>
            </div>

        </>
    )
}

// 에디터 작업내용 미리보기 모달
const ContentModal = ({show, setShow, tag}) => {
    return(
        <>
        <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                상세페이지 미리보기
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div width="90%">
                    <div className='editor-content'>
                        <div dangerouslySetInnerHTML={{ __html: tag }}/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      </>
    )
}
export default ProductCopy