/***************************************************************************************
* FileName      : CheckOutstanding.js
* Description   : 해와관리자 > 주문배송 > 결제내역 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';

import { useOrderUnpaidListQuery , handleOrderUnpaidSearch ,  } from '../../../api/hwadmin/orderAPI';

import PageSizeSelect from '../common/PageSizeSelect';
import SortComponent from '../common/SortComponent2';

import Pagination from '../common/Pagination'
import SearchPagination from '../common/SearchPagination'

import { OrderRow } from './CheckOutstanding/OrderRow';
import { handleResetClick } from '../../components/Resetpage';
import SelectVendor from '../product/Component/SelectVendor';

function CheckOutstanding()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage} = useOrderUnpaidListQuery(ordering,pageSize);

    // 상태 체크박스
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const checkBoxList = ['전체선택','주문완료', '결제완료', '주문취소', '부분취소'];

    console.log(data);
    console.log("---데이터--")

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    const [searchOrder, setSearchOrder] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
           console.log("검색결과 있음")
        }
    };

    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    useEffect(() => {
        // 체크박스들이 모두 선택되었는지 확인하여 전체 선택 상태 업데이트
        setIsAllChecked(checkedItems.length === checkBoxList.length);
        if(isAllChecked){
            setCheckedItems((prevCheckedItems) =>
                prevCheckedItems.filter((item) => item !== '전체선택')
          );
        }
    }, [checkedItems]);

    const onSearch = () => {
        handleOrderUnpaidSearch({
            searchOrder, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
            checkedItems,
            ordering});
        
        setSearchConfirm(true);

    };
        
    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체선택') {
          // "전체선택" 체크박스의 경우, 모든 체크박스를 선택 또는 해제
          if (checked) {
            setCheckedItems(checkBoxList);
          } else {
            setCheckedItems([]);
          }
        } else {
          // 다른 체크박스의 경우, 개별 체크박스 상태 업데이트
          if (checked) {
            setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
          } else {
            setCheckedItems((prevCheckedItems) =>
              prevCheckedItems.filter((item) => item !== id)
            );
          }
        }
    };
            
    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
      
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>결제내역</h2>
            </div>
        
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6'  value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                            </select>

                            <input type="text"
                                value={searchOrder}
                                onChange={(e) => setSearchOrder(e.target.value)}
                            /> 
                        </div>      
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>결제완료일</b> 
                        </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>

              
                    <div className='search-field word'>
                        <div> 
                            <b>결제현황</b> 
                        </div>
                        <div className='CheckboxWrap w-50'>
                            {checkBoxList.map((item, idx) => (
                                <p className='checkbox' key={idx}>
                                    <input
                                        type='checkbox'
                                        id={item}
                                        checked={checkedItems.includes(item)}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={item}>{item}</label>
                                </p>
                            ))}
                        </div> 
                    </div>

                </div>
          
                <div className='m-btn-wrap'>   
                    <button className='m-btn' 
                        onClick={()=>handleResetClick('/admin/order/outstanding' , setSearchConfirm)}>
                        초기화
                    </button>
                    <button className='m-btn m-btn-search' onClick={onSearch} >검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                            <span>검색결과</span>
                            <span className='result-wrap'>
                                {searchConfirm && searchResults.totalCnt > 0 ? (
                                    <>
                                        <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                    </>
                                    ) : (
                                    <>
                                        <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규주문순"
                                oldLabel="오래된주문순"
                            />
    
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        
                        </div>
                    </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주문번호</th>
                                <th>주문상태</th>
                                <th>결제수단</th>
                                <th>입금계좌</th>
                                <th>결제일시/입금기한</th>
                                <th>
                                    결제금액 <br/>
                                    (배송비 포함)
                                </th>
                                <th>구매자 ID<br/>
                                    구매자 이름
                                </th>
                                <th>
                                    수취인 이름<br/>
                                    수취인 연락처
                                </th>
                                <th>주소</th>
                                <th>적립예정마일리지</th>
                            </tr>
                            
                            </thead>
                            <tbody>
                            {searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults.count > 0 ? (
                                searchResults.data.map((order, index) => (
                                    <OrderRow 
                                        key={order.id} 
                                        order={order} 
                                        index={index}
                                      
                                    />
                                ))
                            ) : data?.data?.length > 0 ? (
                                data?.data.map((order, index) => (
                                    <OrderRow 
                                        key={order.id} 
                                        order={order} 
                                        index={index}
                                   
                                    />
                                ))
                            ) : null}

                        </tbody>

                        </table>
                    </div>
                </section>
        
            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>

        </>
    )
}

export default CheckOutstanding