import {  queryClient , useMutation , useQuery , useQueryClient } from 'react-query';
import { instance } from '../axiosInstance/axiosConfig';


// 대분류 카테고리 조회
export const useMainCategories = () => {
  return useQuery('Maincategory', async () => {
    const response = await instance.get('/category/?depth=1&page_size=all');
    return response.data;
  });
};

// 중분류 카테고리 조회
export const useSubCategories = (id) => {
  return useQuery(['Subcategory', id], async () => {
    if(id !== null){
      const response = await instance.get('/category/', {
        params: {
          parent: id,
          page_size: 'all',
        }
      });
        return response.data;
      }else{
        return null;
      }
  });
};

// 소분류 카테고리 조회
export const useSubSubCategories = (id) => {
  return useQuery(['SubSubcategory', id], async () => {
    if(id !== null){
      const response = await instance.get('/category/', {
        params: {
          parent: id,
          page_size: 'all',
        }
      });
      return response.data;
    }else{
      return null;
    }
  });
};

// 카테고리관리 > 필터링 옵션명 조희
export const useFilteringOptions = (id) => {
  return useQuery(['FilteringOptions', id], async () => {
    if(id !== null){
      const response = await instance.get('/category/', {
        params: {
          parent: id,
          page_size: 'all',
        }
      });
      return response.data;
    }else{
      return null;
    }
  });
};

// 카테고리관리 > 필터링 옵션 조희
export const useFiltering = (id) => {
  return useQuery(['Filtering', id], async () => {
    const response = await instance.get('/category/', {
      params: {
        parent: id,
        page_size: 'all',
      }
    });
    return response.data;
  });
};

// 카테고리 추가
export const useAddCategory = () => {
  const queryClient = useQueryClient();
  
  return useMutation(
    async (category) => {
      const response = await instance.post('/category/', category);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('category');
      },
    }
  );
};

// 카테고리 조회 
export const useGetCategory = () => {
  return useQuery('category', async () => {
    const response = await instance.get('/category/');
    return response.data;
  });
};

// 선택한 카테고리 조회 
export const useSelectedCategory = () => {
  const queryClient = useQueryClient();

  return useQuery('selectedCategory', async () => {
    const selectedId = queryClient.getQueryData('selectedId');
    if (selectedId) {
      const response = await instance.get(`/category/${selectedId}`);
      return response.data;
    }
  });
};

// 선택한 카테고리 수정 
// export const useUpdateCategory = () => {
//   const queryClient = useQueryClient();

//   return useMutation(
//     async (updatedCategory) => {
//       const response = await axios.put(`${BASE_URL}/category/${updatedCategory.id}`, updatedCategory);
//       return response.data;
//     },
//     {
//       onSuccess: (data, variables) => {
//       },
//     }
//   );
// };

// 선택한 카테고리 수정
// export const useUpdateCategory = async (id, newData) => {
//   try {
//     await axios.put(`${BASE_URL}/category/${id}`, newData);
//     alert('데이터 수정이 완료되었습니다.');
//   } catch (error) {
//     throw new Error('데이터 수정 중 오류가 발생했습니다.');
//   }
// };

export const useCategoryUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(async (updatedData) => {
    const selectedId = queryClient.getQueryData('selectedId');
    if (selectedId) {
      await instance.patch(`/category/${selectedId}`, updatedData);
      // 데이터 수정 후 캐시 업데이트
      queryClient.invalidateQueries('selectedCategory');
      // 또는 필요한 다른 쿼리들도 업데이트할 수 있음
    }
  });
};



// 선택한 카테고리 삭제 

export const useCategoryDelete = () => {
  const queryClient = useQueryClient();

  return useMutation( async () => {
    const selectedId = queryClient.getQueryData('selectedId');
    if (selectedId) {
      await instance.delete(`/category/${selectedId}`);
      // 데이터 삭제 후 캐시 업데이트
      queryClient.invalidateQueries('selectedCategory');
      // 또는 필요한 다른 쿼리들도 업데이트할 수 있음
    }
  });
};


// 카테고리 순서 변경
export const updateCategoryPriority = (data) => {
  const priorityString = data?.priority.join(', ');

  if(data?.parent === null){
    return instance.post('/category/priority/',{
      priority: priorityString
    });
  }
  else{
    return instance.post('/category/priority/', {
      parent: data?.parent,
      priority: priorityString
    });
  }
}