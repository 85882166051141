/***************************************************************************************
* FileName      : Review.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 나의 리뷰
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import MypageTabComponent from './MypageTab_v2';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';

import ReviewRow from './Review/ReviewRow';
import MReviewRow from './Review/MReviewRow.js';
import { getMyReview, getMyReviewable , useMyReviewListQuery } from '../../api/services/mypageApi';

import Pagination from '../customer/common/Pagination.js';

function ReviewLayout()
{
    const [reviewable, setReviewable] = useState(); //작성 가능한 리뷰 리스트
    useEffect(()=>{
        getMyReview()
            .then(res => console.log("gg",res?.data))
            .catch(error => alert('리뷰데이터 조회 에러'))

        getMyReviewable()
            .then(res => setReviewable(res?.data?.data));
    },[])


    return(
        <>
        <Container>
            <StatusBar/>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'review'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <MyReview/>
                      
                    </Col>
                </div>
            </Row>

        </Container>
        
        </>
      )
}

function MyReview()
{
    const { data:myReviewData, error, isFetching, page, setPage } = useMyReviewListQuery();

    console.log(myReviewData)

    if (!myReviewData) {
        return <div>Loading...</div>;
    }

    const tabs = [
        {label: '작성 가능한 리뷰' , url : '/mypage/reviewable'},
        {label: '나의 리뷰' ,  url : '/mypage/review' }
    ];

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
          
    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>          
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-5 fw-bold">리뷰관리</h3>
                </Col>
            </Row>

            <MypageTabComponent tabs={tabs} />

            <table className='mypage-table'  id="mypage-order-table">
                <colgroup>
                    <col style={{ width: '50%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '15%' }} />
                </colgroup>
                <thead >
                    <tr>
                        <th>상품</th>
                        <th>리뷰</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody className='history'>   
                    {myReviewData?.totalCnt === 0 ? (
                        <>
                         <td colSpan="2">작성한 리뷰가 없습니다.</td>
                        </>
                    ) : (    
                        myReviewData && myReviewData?.data?.map((orderItem, index) => (
                            <ReviewRow
                                orderItem={orderItem}
                                index={index}
                            />
                        ))
                    )}
                </tbody>
                
            </table>
    

                            
            {/* 모바일 */}
            <div className='mOrderContents' id="mContents">
                <div className=''>
                    {myReviewData &&
                        myReviewData.data.map((orderItem, index ) => (
                            <MReviewRow
                                orderItem={orderItem}
                                index={index}
                            />
                        ))
                    }
                </div>
            </div>

            {/* 페이지내비게이션 */}
            <section className='mt-5'>
              <Pagination
                  count={myReviewData?.totalCnt || 0}
                  page={page}
                  setPage={setPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
              />
          </section>
        </div>
        </>
    )
}


export default ReviewLayout