/***************************************************************************************
* FileName      : useFormatPhoneNumber.js
* Description   : 연락처 포맷 변경 훅
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.01
* Modifide Date : 2024.01.01 : CHM : 휴대폰번호 형식 변환
* Reference     : 
***************************************************************************************/
import { useState, useEffect } from 'react';

export default function useFormatPhoneNumber(phoneNumber) {
  // 휴대폰 번호 형태 변환 커스텀 휵
  // (-)없는 휴대폰번호 -> (-)포함
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  useEffect(() => {
    if (phoneNumber) {
      // 기존 번호에서 모든 "-" 제거
      const strippedPhoneNumber = phoneNumber.replace(/-/g, '');
      
      // "-"가 포함된 형태로 휴대폰 번호 포맷팅 (###-####-####)
      let formatted = '';
      if (strippedPhoneNumber.length >= 4) {
        formatted += strippedPhoneNumber.substr(0, 3) + '-';
        if (strippedPhoneNumber.length >= 7) {
          formatted += strippedPhoneNumber.substr(3, 4) + '-';
          if (strippedPhoneNumber.length >= 11) {
            formatted += strippedPhoneNumber.substr(7, 4);
          } else {
            formatted += strippedPhoneNumber.substr(7);
          }
        } else {
          formatted += strippedPhoneNumber.substr(3);
        }
      } else {
        formatted = strippedPhoneNumber;
      }

      setFormattedPhoneNumber(formatted);
    }
  }, [phoneNumber]);

  return formattedPhoneNumber;
}