/***************************************************************************************
* FileName      : RequestList.js
* Description   : 셀러관리자 > 상품관리 > 요청목록
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useQuery } from 'react-query';
import React, { useState , useEffect } from 'react';
import { ProductRow } from './RequestList/ProductRow';
import { useProductRequestQuery , handleAdjustDocumentSearch } from '../../../api/selleradmin/productApi';

import SearchPagination from '../../admin/common/SearchPagination';
import Pagination from '../../admin/common/Pagination';

import SortComponent from '../../admin/common/SortComponent2';
import PageSizeSelect from '../../admin/common/PageSizeSelect';

function RequestList()
{
    const [ordering, setOrdering] = useState('created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage , totalPages } = useProductRequestQuery(ordering , pageSize);

    const [toggle , setToggle] = useState(false);

    const [searchProduct, setSearchProduct] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');
    
    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

  
    console.log("data", data);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    //요청구분
    const [requestType, setRequestType] = useState('');


    // 요청구분 선택
    const handleRequestTypeChange = (e) => {
        setRequestType(e.target.value);
    };

    
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleAdjustDocumentSearch({
            searchProduct, 
            setSearchResults,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            setSearchUrl
        });

        setSearchConfirm(true);
    };
    
    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false)
        window.location.href = '/selleradmin/product/requestList/'; 
    };
    

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
            setSelectedStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };

    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>요청목록</h2>
            </div>
           
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">상품명</option>
                                <option value="id">상품ID</option>
                            </select>
                            <input type="text"
                              value={searchProduct}
                              onChange={(e) => setSearchProduct(e.target.value)}/> 
     
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="apply">신청일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                    <div className='search-field division'>
                        <div> 
                            <b>상태</b> 
                        </div>
                        <div className='SearchRadio'>
                            <input
                                type='radio'
                                id='requestTypeAll'
                                name='requestType'
                                value=''
                                checked={requestType === ''}
                                onChange={handleRequestTypeChange}
                            />
                            <label htmlFor='requestTypeAll'>전체</label>
                            
                            <input
                                type='radio'
                                id='requestTypeNew'
                                name='requestType'
                                value='판매중'
                                checked={requestType === '판매중'}
                                onChange={handleRequestTypeChange}
                            />
                            <label htmlFor='requestTypeNew'>판매중</label>

                            <input
                                type='radio'
                                id='requestTypeUpdate'
                                name='requestType'
                                value='품절'
                                checked={requestType === '품절'}
                                onChange={handleRequestTypeChange}
                            />
                            <label htmlFor='requestType'>품절</label>

                            <input
                                type='radio'
                                id='requestTypeUpdate'
                                name='requestType'
                                value='판매중지'
                                checked={requestType === '판매중지'}
                                onChange={handleRequestTypeChange}
                            />
                            <label htmlFor='requestType'>판매중지</label>

                            <input
                                type='radio'
                                id='requestTypeUpdate'
                                name='requestType'
                                value='판매종료'
                                checked={requestType === '판매종료'}
                                onChange={handleRequestTypeChange}
                            />
                            <label htmlFor='requestType'>판매종료</label>
                        </div>
                    </div>
     
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={() => handleResetClick()}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={ () => onSearch() }>검색</button>
                </div>
            </section>
        

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>


                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규요청순"
                                oldLabel="오래된요청순"
                            />

                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />                       
                        </div>
                    }
                </div>
           
                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                            {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>
                                <th>구분</th>
                                <th>대표이미지</th>
                                <th>상품명</th>
                                <th>카테고리</th>
                                <th>옵션</th>
                                <th>정가</th>
                                <th>판매가</th>
                                <th>상세설명</th>
                                <th>제조사</th>
                                <th>인당최대구매수량</th>
                                <th>판매시작일<br/>
                                    판매종료일
                                </th>
                                <th>부가세</th>
                                <th>외부 검색어(메타태그)</th>
                                <th>검색필터</th>
                                <th>상품정보제공고시</th>
                                <th>제주/도서산간 배송여부</th>
                                <th>택배사</th>
                                <th>배송방법</th>
                                <th>배송비 종류</th>
                                <th>출고일</th>
                                <th>출고지</th>
                                <th>반품교환지</th>
                                <th>초도배송비</th>
                                <th>반품배송비</th>
                                <th>구비서류</th>
                                <th>추가이미지</th>
                                <th>인증정보</th>
                                <th>미성년자 구매 가능 여부</th>
                            </tr>
                        </thead>
                        <tbody>
                
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : searchResults?.count > 0 ? (
                            searchResults?.data.map((product) => (
                                <ProductRow
                                    key={product?.id}
                                    product={product}
                            
                                />
                            ))
                        ) : data && data?.data && data?.data.length > 0 ? (
                            data?.data.map((product) => (
                                <ProductRow
                                    key={product?.id}
                                    product={product}
                                  
                                />
                            ))
                        ) : null}

                    </tbody>

                    </table>
                </div>
            </section>
  
  
              {/* 페이지내비게이션 */}
              <section>
                {searchResults?.count > 0 ? (
                      <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                ) : (
                  
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>

            </div>

        </>
    )
}


export default RequestList