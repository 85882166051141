import React from 'react';

const MonthCheckboxGroup = ({ checkedState, onChange }) => {
  const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString());

  return (
    <div className="checkbox-group ml-2">
      {months.map((month, index) => (
        <label key={month}>
          <input
                type="checkbox"
                name={month}
                checked={checkedState[month]}
                onChange={onChange}
            />
          {month}월
        </label>
      ))}
    </div>
  );
};

export default MonthCheckboxGroup;

