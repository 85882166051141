import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useTalkTemplate } from '../../../api/hwadmin/notificationApi';

export function EmailModal({show, setShow, mailData, disagreeMailData}){
    return(
        <>
        <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                이메일 발송
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='fw-bold fs-6 mt-3 mb-1'>수신동의 이메일 리스트({mailData?.length})</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <p>{mailData != [] && mailData?.map(data => data+", ")}</p>
                </div>
                
                <p className='fw-bold fs-6 mt-3 mb-1'>수신거부 이메일 리스트({disagreeMailData?.length})</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <p>{disagreeMailData != [] && disagreeMailData?.map(data => data+", ")}</p>
                </div>
                
            </Modal.Body>
        </Modal>
      </>
    )
}


export function SmsModal({show, setShow, users}){
    const stringUsers = users?.map(user => user.toString());
    const [selectedSmsStatus, setSelectedSmsStatus] = useState('즉시발송');
    const [selectedHours, setSelectedHours] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedTemplateContent, setSelectedTemplateContent] = useState('');

    // useTalkTemplate 쿼리 호출
    const { data: templates, isLoading, error } = useTalkTemplate();

    // 만약 로딩 중이면 로딩 상태를 보여줍니다.
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const handleSendSms = () => {
        if(selectedSmsStatus === '즉시발송'){
            console.log('템플릿', selectedTemplate);
            console.log('사용자', stringUsers);
            setShow(false);
        }else{
            console.log('예약');
            console.log('시간', selectedHours);
            console.log('분', selectedMinutes);
            console.log('템플릿', selectedTemplate);
            console.log('사용자', stringUsers);
            setShow(false);
        }
    };

    return(
        <>
        <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                알림톡 발송
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='fw-bold fs-6 mt-3 mb-1'>대상회원<span className='text-hw'>({users?.length})</span></p>
                
                <p className='fw-bold fs-6 mt-3 mb-1'>발송유형</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <input type='radio' id='memberStatus1' className='mrbtn' name='status' value='즉시발송' checked={selectedSmsStatus === '즉시발송'} onChange={()=>setSelectedSmsStatus('즉시발송')} />
                    <label htmlFor='memberStatus1'>즉시발송</label>
                    <input type='radio' id='memberStatus2' className='mrbtn' name='status' value='예약발송' checked={selectedSmsStatus === '예약발송'} onChange={()=>setSelectedSmsStatus('예약발송')} />
                    <label htmlFor='memberStatus2'>예약발송</label>
                    {selectedSmsStatus === '예약발송' && (
                        <>
                            <select className='filter-select me-2 py-2' value={selectedHours} onChange={(e) => setSelectedHours(e.target.value)}>
                                <option value="" disabled>시</option>
                                {[...Array(24).keys()].map(hour => (
                                    <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}시</option>
                                ))}
                            </select>
                            <select className='filter-select me-2 py-2' value={selectedMinutes} onChange={(e) => setSelectedMinutes(e.target.value)}>
                                <option value="" disabled>분</option>
                                {[...Array(6).keys()].map(minute => (
                                    <option key={minute * 10} value={minute * 10}>{(minute * 10).toString().padStart(2, '0')}분</option>
                                ))}
                            </select>
                        </>
                    )}
                </div>

                <p className='fw-bold fs-6 mt-3 mb-1'>템플릿선택</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <select className='filter-select w-100 me-2 py-2'
                        value={selectedTemplate}
                        onChange={(e) => {
                            setSelectedTemplate(e.target.value);
                            setSelectedTemplateContent(templates?.list.find(template => template.templtCode === e.target.value)?.templtContent);
                        }}
                    >
                        <option value="" disabled>선택해주세요</option>
                        {templates?.list.map((template, index) => (
                            <option key={index} value={template?.templtCode}>{template?.templtName}</option>
                        ))}
                        {console.log(templates)}
                    </select>
                    <p className='mt-2 p-2 border bg-white fs-7 text-dark'>
                        {selectedTemplateContent}
                    </p>
                </div>

                <Row>
                    <Col></Col>
                    <Col>
                        <button 
                            className="btn btn-primary mt-2 w-100"
                            onClick={()=>handleSendSms()}
                        >발송</button>
                    </Col>
                    <Col></Col>
                </Row>
            </Modal.Body>
        </Modal>
      </>
    )
}

export function PushModal({show, setShow, users}){
    const stringUsers = users?.map(user => user.toString());
    const [selectedPushStatus, setSelectedPushStatus] = useState('즉시발송');
    const [selectedHours, setSelectedHours] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [pushTitle, setPushTitle] = useState('');
    const [pushBody, setPushBody] = useState('');
    return(
        <>
        <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                푸시 발송
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='fw-bold fs-6 mt-3 mb-1'>대상회원<span className='text-hw'>({users?.length})</span></p>
                
                <p className='fw-bold fs-6 mt-3 mb-1'>발송유형</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <input type='radio' id='memberStatus1' className='mrbtn' name='status' value='즉시발송' checked={selectedPushStatus === '즉시발송'} onChange={()=>setSelectedPushStatus('즉시발송')} />
                    <label htmlFor='memberStatus1'>즉시발송</label>
                    <input type='radio' id='memberStatus2' className='mrbtn' name='status' value='예약발송' checked={selectedPushStatus === '예약발송'} onChange={()=>setSelectedPushStatus('예약발송')} />
                    <label htmlFor='memberStatus2'>예약발송</label>

                    <>
                    {selectedPushStatus === '예약발송' && (
                        <>
                            <select className='filter-select me-2 py-2' value={selectedHours} onChange={(e) => setSelectedHours(e.target.value)}>
                                <option value="" disabled>시</option>
                                {[...Array(24).keys()].map(hour => (
                                    <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}시</option>
                                ))}
                            </select>
                            <select className='filter-select me-2 py-2' value={selectedMinutes} onChange={(e) => setSelectedMinutes(e.target.value)}>
                                <option value="" disabled>분</option>
                                {[...Array(6).keys()].map(minute => (
                                    <option key={minute * 10} value={minute * 10}>{(minute * 10).toString().padStart(2, '0')}분</option>
                                ))}
                            </select>
                        </>
                    )}
                    </>
                </div>

                <p className='fw-bold fs-6 mt-3 mb-1'>푸시제목</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <input type='text' className='w-100' onChange={(e) => setPushTitle(e.target.value)}/>
                </div>

                <p className='fw-bold fs-6 mt-3 mb-1'>푸시 내용</p>
                <div className='border rounded-1 my-1 p-3 bg-light'>
                    <textarea onChange={(e) => setPushBody(e.target.value)}></textarea>
                </div>

                <Row>
                    <Col></Col>
                    <Col>
                        <button 
                            className="btn btn-primary mt-2 w-100"
                            onClick={()=>{
                                console.log(stringUsers);
                                console.log(pushTitle);
                                console.log(pushBody);
                            }}
                        >발송</button>
                    </Col>
                    <Col></Col>
                </Row>
                
            </Modal.Body>
        </Modal>
      </>
    )
}