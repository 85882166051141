/***************************************************************************************
* FileName      : NewRequest.js
* Description   : 해와관리자 > 상품관리 > 신규요청
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 2023.00.00 : hm : 상품 승인 , 반품 
***************************************************************************************/

import {Row, Col, Button , Navbar, Nav, Container, Modal} from 'react-bootstrap';
import { useQuery } from 'react-query';
import React, { useState , useEffect } from 'react';
import { useNewProductsQuery , handleNewProductSearch, setProductState } from '../../../api/hwadmin/productApi';
import { ProductRow } from "./NewRequest/ProductRow";
import SearchPagination from '../common/SearchPagination';
import Pagination from '../common/Pagination'
import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';

function ProductRequest()
{
    const [ordering, setOrdering] = useState('created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useNewProductsQuery(ordering , pageSize);

    const [searchProduct, setSearchProduct] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 선택한 상품 Row
    const [selectedRows, setSelectedRows] = useState({});
    // 선택한 상품 Row의 데이터값
    const [selectedRowsData, setSelectedRowsData] = useState({});
    // 상품 승인 컨펌창 상태
    const [approveProduct, setApproveProduct] = useState(false);
    const [rejectProduct, setRejectProduct] = useState(false);

    // 상품 승인 컨펌이 true면 승인여부 물어보기
    useEffect(()=>{
        if(approveProduct){
            // 선택된 체크박스 id 값
            let selectList = Object.keys(selectedRows).filter(key => selectedRows[key] === true)
            // 승인 요청 컨펌창
            if(window.confirm(selectList.length+"건의 상품 등록을 승인 하시겠습니까?")){
                // 상품 승인 요청할 API 묶음
                const approvePromises = selectList.map(id => setProductState(id, "approve"));
                // 상품 승인 요청
                Promise.all(approvePromises)
                    .then(results => {
                        results.forEach(res => console.log(res.data));
                        alert('상품이 등록되었습니다.');
                    })
                    .catch(error => alert(error?.response?.data?.product))
                    .finally(()=> window.location.reload());
            }else{
                alert('상품등록이 취소되었습니다.');
            }
            setApproveProduct(false);
        }
    }, [approveProduct])

    const [modalIsOpen, setModalIsOpen] = useState(false); //상품거절모달
    const [productUpdateData, setProductUpdateData] = useState({});// 모달에서 수정한 값 담을 Object 생성
    // 상품 거절 컨펌이 true면 거절 사유 입력할 모달 창 출력
    useEffect(()=>{
        if(rejectProduct){
            setModalIsOpen(true);
        }
        setRejectProduct(false);
    },[rejectProduct])

    const postRejectProduct = () =>{
        // 선택된 체크박스 id 값
        let selectList = Object.keys(selectedRows).filter(key => selectedRows[key] === true)
        // 상품 거절 요청할 API 묶음
        const rejectPromises = selectList.map(id => setProductState(id, "reject", productUpdateData[id].rejectValue));
        Promise.all(rejectPromises)
            .then(results => {
                results.forEach(res => console.log(res.data));
                alert('상품 등록이 거절되었습니다.');
            })
            .catch(error => alert(error?.response?.data?.product))
            .finally(()=>window.location.reload())
    }


    const [expandedIds, setExpandedIds] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    //요청구분
    const [requestType, setRequestType] = useState('');

    if (error) return <div>Error loading products</div>;
    if (!data) return <div>Loading products...</div>;

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleNewProductSearch({
            searchProduct, 
            setSearchResults,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            requestType,
            setSearchUrl
        });

        setSearchConfirm(true);
    };
    
    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/admin/product/request';
    };
    

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
            setSelectedStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    // 요청구분 선택
    const handleRequestTypeChange = (e) => {
        setRequestType(e.target.value);
    };

    const openModal = () => {
        console.log("cccc");
        setIsOpen(true);
    }
  
    const closeModal = () => {
      setIsOpen(false);
    }

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    const handleExpandClick = (id) => {
        if (expandedIds.includes(id)) {
          setExpandedIds(expandedIds.filter((prevId) => prevId !== id));
        } else {
          setExpandedIds([...expandedIds, id]);
        }
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };

    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    console.log(data);
    
    return(
        <>
        
        <div className='mainContents'>
            {/* 신규요청(신규 상품 등록 요청, 상품 정보 수정)  */}
            <div className='page-tit'>
                <h2>신규요청(신규 상품 등록 요청, 상품 정보 수정)</h2>
            </div>
           
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">상품명</option>
                                <option value="id">상품ID</option>
                            </select>
                            <input type="text"
                              value={searchProduct}
                              onChange={(e) => setSearchProduct(e.target.value)}/> 
     
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="apply">신청일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                    <div className='search-field division'>
                        <div> 
                            <b>요청구분</b> 
                        </div>
                        <div className='SearchRadio'>
                            <input
                                type='radio'
                                id='requestTypeAll'
                                name='requestType'
                                value=''
                                checked={requestType === ''}
                                onChange={handleRequestTypeChange}
                                />
                            <label htmlFor='requestTypeAll'>전체</label>
                            
                            <input
                                type='radio'
                                id='requestTypeNew'
                                name='requestType'
                                value='신상품등록'
                                checked={requestType === '신상품등록'}
                                onChange={handleRequestTypeChange}
                                />
                            <label htmlFor='requestTypeNew'>신상품등록</label>

                            <input
                                type='radio'
                                id='requestTypeUpdate'
                                name='requestType'
                                value='상품정보수정'
                                checked={requestType === '상품정보수정'}
                                onChange={handleRequestTypeChange}
                            />
                            <label htmlFor='requestTypeUpdate'>상품정보수정</label>
                        </div>
                    </div>
                
        
      
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={() => handleResetClick()}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={ () => onSearch() }>검색</button>
                  
                </div>
            </section>
        

                {/* 검색 결과 */}
                <section className='McontentBox'>
                    <div className='subTitle'>
                        <div className='resultsCount-wrap'>
                            <span>검색결과</span>
                            <span className='result-wrap'>
                                {searchResults.totalCnt > 0 ? (
                                    <>
                                        <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                    </>
                                    ) : (
                                    <>
                                        <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                    </>
                                )}
                            </span>
                        </div>

                        { !searchConfirm && 
                            <div className='selectWrap'>
                                <SortComponent
                                    ordering={ordering}
                                    handleOrderingChange={handleOrderingChange}
                                    newLabel="최근요청순"
                                    oldLabel="오래된요청순"
                                />
        
                                <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                                            
                            </div>
                        }
                    </div>

                    <div className='fixTable_wrap'>
                        <table className='sTable' id='scrollTable'>
                            <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <button className='expand-btn'>
                                             <i className='xi-angle-down'></i>
                                        </button>
                                    </th>
                                    <th>구분</th>
                                    <th>판매자</th>
                                    <th>대표이미지</th>
                                    <th>
                                        상품명 <br/>
                                        상품ID
                                    </th>
                                    <th>카테고리</th>
                                    <th>옵션</th>
                                    <th>정가</th>
                                    <th>판매가</th>
                                    <th>상세설명</th>
                                    <th>제조사</th>
                                    <th>인당최대구매수량</th>
                                    <th>판매시작일<br/>
                                        판매종료일
                                    </th>
                                    <th>부가세</th>
                                    <th>외부 검색어(메타태그)</th>
                                    <th>검색필터</th>
                                    <th>상품정보제공고시</th>
                                    <th>제주/도서산간 배송여부</th>
                                    <th>택배사</th>
                                    <th>배송방법</th>
                                    <th>배송비 종류</th>
                                    <th>출고일</th>
                                    <th>반품교환지</th>
                                    <th>초도배송비</th>
                                    <th>반품배송비</th>
                                    <th>구비서류</th>
                                    <th>추가이미지</th>
                                    <th>인증정보</th>
                                    <th>미성년자 구매 가능 여부</th>
                                    <th>승인/반려</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchConfirm && searchResults?.count === 0 ? (
                                    <tr>
                                        <td colSpan="17">검색 결과가 없습니다.</td>
                                    </tr>
                                ) : searchResults?.count > 0 ? (
                                    searchResults?.data.map((product) => (
                                        <ProductRow
                                            key={product?.id}
                                            product={product}
                                            expandedIds={expandedIds}
                                            handleExpandClick={handleExpandClick}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                            selectedRowsData={selectedRowsData}
                                            setSelectedRowsData={setSelectedRowsData}
                                            setApproveProduct={setApproveProduct}
                                        />
                                    ))
                                ) : data && data?.data && data?.data.length > 0 ? (
                                    data?.data.map((product) => (
                                        <ProductRow
                                            key={product?.id}
                                            product={product}
                                            expandedIds={expandedIds}
                                            handleExpandClick={handleExpandClick}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                            selectedRowsData={selectedRowsData}
                                            setSelectedRowsData={setSelectedRowsData}
                                            setApproveProduct={setApproveProduct}
                                        />
                                    ))
                                ) : null}

                                </tbody>

                           
                           
                        </table>
                    </div>
                </section>
  
              {/* 페이지내비게이션 */}
              <section>
                {searchResults?.count > 0 ? (
                      <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                ) : (
                  
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>

            </div>

            {/* 상품등록 거절 모달 */}
            {modalIsOpen ? 
                <RejectModal 
                    show={modalIsOpen} 
                    onHide={setModalIsOpen} 
                    selectedRows={selectedRows} 
                    selectedRowsData={selectedRowsData} 
                    setProductUpdateData={setProductUpdateData} 
                    postRejectProduct={postRejectProduct}/> : null}

        </>
    )
}

// 상품등록 거절 모달
function RejectModal({show, onHide, selectedRows, selectedRowsData, setProductUpdateData, postRejectProduct}){

    // 선택된 상품을 모두 표시
    const selectedProductIds = Object.keys(selectedRows).filter(key => selectedRows[key] === true);

    return(
        <Modal size={'lg'} show={show} onHide={()=>onHide(false)} centered>
            <Modal.Header>
                <Modal.Title id='sm-modal' className='fs-6'>
                    상품 등록 거절
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedProductIds.length > 0
                ? <span className='text-hw fw-bold'>총 {selectedProductIds?.length}건의 등록을 거절합니다.</span>
                : '선택된 상품이 없습니다.'}

                <div className='fixTable_wrap my-2'>
                    <table className='sTable' id='scrollTable'>
                        <thead>
                            <tr>
                                <th>판매자</th>
                                <th>대표이미지</th>
                                <th>상품명<br/>상품ID</th>
                                <th>거절사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedProductIds.length > 0 ? 
                                selectedProductIds.map(productId => 
                                    <ProductRejectTable 
                                        productId={productId} 
                                        setProductUpdateData={setProductUpdateData} 
                                        selectedRowsData={selectedRowsData[productId]}/>) : <tr>데이터가 없습니다.</tr>}
                            
                        </tbody>
                    </table>
                </div>
                <button className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold" onClick={()=>postRejectProduct()}>확인</button>
            </Modal.Body>
        </Modal>
    )
}

// 상품등록 거절 모달 내 테이블
function ProductRejectTable({productId, setProductUpdateData, selectedRowsData}){
    const [rejectValue, setRejectValue] = useState();
    useEffect(() => {
        setProductUpdateData(prevData => ({
            ...prevData,
            [productId]:{
                rejectValue: rejectValue
            }
        }))
    },[rejectValue]);
    return(
        <tr>
            <td>
                {selectedRowsData.vendor_name}
            </td>
            <td>
                <img src={selectedRowsData.product_img} width="80px"/>
            </td>
            <td>
                {selectedRowsData.product_name}<br/>{selectedRowsData.product_id}
            </td>
            <td>
                <input 
                    type="text" 
                    value = {rejectValue}
                    placeholder='거절사유를 입력해주세요.' 
                    onChange={(e)=>setRejectValue(e.target.value)}
                    className="w-100 p-1"
                />
            </td>
        </tr>
    )
}

export default ProductRequest