/***************************************************************************************
* FileName      : statusModal.js
* Description   : 해와관리자 카테고리 순서 변경
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.03.01
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState } from 'react';

import { useQueryClient } from 'react-query';
import { useMainCategories, useSubCategories, useSubSubCategories, useFilteringOptions, updateCategoryPriority } from '../../../../api/Category';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { TbArrowsMoveVertical } from "react-icons/tb";

// 카테고리 선택 함수 (카테고리 id, 수정할 카테고리의 useState 업데이트 함수, 쿼리클라이언트)
const categoryClickEvent = (category, targetState, queryClient) => {
    targetState(category?.id);
    queryClient.setQueryData('selectedId', category?.id);
    queryClient.setQueryData('selectedName', category?.name);
    queryClient.setQueryData('selectedHidden', category?.hidden);
    queryClient.setQueryData('selectedMaincategory', category?.name);
    queryClient.setQueryData('selectedSubcategory', '');
    queryClient.setQueryData('selectedSubSubcategory', '');
    queryClient.setQueryData('selectedfiltering', '');
    queryClient.setQueryData('selectedfilteringOptions', '');
}

export const EditPriority = () => {
    
    const [selectedMainCategoryId, setSelectedMainCategoryId] = useState(null); //대분류 카테고리
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null); //중분류 카테고리
    const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState(null); //소분류 카테고리
    const [selectedFilteringOption, setSelectedFilteringOption] = useState(null); //필터링 옵션명
    const [selectedFilter, setSelectedFilter] = useState(null); //필터 옵션

    return (
        <div className='p-3 bg-grey border rounded-2'>
            <div className='categbox'>
                <p className='ctit'>대분류</p>
                <div type="대분류" className='kwutOX'>
                    <EditMainPriority
                        selectedCategoryId={selectedMainCategoryId}
                        setSelectedMainCategoryId={setSelectedMainCategoryId}/>
                </div>
            </div>
            {selectedMainCategoryId && (
                <div className='categbox'>
                    <p className='ctit'>중분류</p>
                    <div type="중분류" className='kwutOX'>
                        <EditSubPriority
                            categoryId={selectedMainCategoryId}
                            selectedSubCategoryId={selectedSubCategoryId}
                            setSelectedSubCategoryId={setSelectedSubCategoryId}
                        />
                    </div>
                </div>
            )}
            {selectedMainCategoryId && selectedSubCategoryId && (
                <div className='categbox'>
                    <p className='ctit'>소분류</p>
                    <div type="소분류" className='kwutOX'>
                        <EditSubSubPriority
                            categoryId={selectedSubCategoryId}
                            selectedSubSubCategoryId={selectedSubSubCategoryId}
                            setSelectedSubSubCategoryId={setSelectedSubSubCategoryId}
                        />
                    </div>
                </div>
            )}
            {selectedMainCategoryId && selectedSubCategoryId && selectedSubSubCategoryId && (
                <div className='categbox'>
                    <p className='ctit'>필터링 옵션</p>
                    <div type="필터링옵션" className='kwutOX'>
                        <EditFilteringOptionPriority
                            categoryId={selectedSubSubCategoryId}
                            selectedFilteringOption={selectedFilteringOption}
                            setSelectedFilteringOption={setSelectedFilteringOption}
                        />
                    </div>
                </div>
            )}
            {selectedMainCategoryId && selectedSubCategoryId && selectedSubSubCategoryId && selectedFilteringOption && (
                <div className='categbox'>
                    <p className='ctit'>필터링 옵션명</p>
                    <div type="필터링옵션명" className='kwutOX'>
                        <EditFilterPriority
                            categoryId={selectedFilteringOption}
                            selectedFilter={selectedFilter}
                            setSelectedFilter={setSelectedFilter}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

// 대분류 카테고리 순서변경
function EditMainPriority({selectedCategoryId, setSelectedMainCategoryId}){
    const queryClient = useQueryClient();
    const { isLoading, isError, data: mainCategoriesData, refetch: refetchMainCategories } = useMainCategories();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div>카테고리 조회 에러</div>;
    }

    const handleDragEnd = async(res) => {
        if (!res?.destination) {
            return; // 드롭이 시작한 위치 밖으로 이동된 경우
        }
        // 출발 index, 도착 index
        const startIndex = res?.source?.index;
        const endIndex = res?.destination?.index;
        // 새로운 카테고리 ID 순서 배열 생성
        const newOrderIds = Array.from(mainCategoriesData?.data?.map(category => category?.id));
        const [removed] = newOrderIds.splice(startIndex, 1);
        newOrderIds.splice(endIndex, 0, removed);

        // 카테고리 우선 순위 업데이트 요청
        await updateCategoryPriority({ priority: newOrderIds });
        // mainCategoriesData 다시 불러오기
        await refetchMainCategories();
    }
    return(
        <DragAndDropComponent
            items={mainCategoriesData?.data}
            onDragEnd={handleDragEnd}
            renderItem={(item) => (
                <>
                    <TbArrowsMoveVertical className='text-grey me-2'/>
                    <span
                        className='pointer'
                        style={{ color: selectedCategoryId === item.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                        onClick={() => categoryClickEvent(item, setSelectedMainCategoryId, queryClient)}
                    >
                        {item.name}
                    </span>
                </>
            )}
        />
    )
}

// 중분류 카테고리 순서변경
function EditSubPriority({ categoryId, selectedSubCategoryId, setSelectedSubCategoryId }) {
    const queryClient = useQueryClient();
    const { isLoading, isError, data: subCategoriesData, refetch: refetchSubCategories } = useSubCategories(categoryId);
    
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error occurred while fetching main categories.</div>;
    }

    const handleDragEnd = async(res) => {
        if (!res?.destination) {
            return; // 드롭이 시작한 위치 밖으로 이동된 경우
        }
        // 출발 index, 도착 index
        const startIndex = res?.source?.index;
        const endIndex = res?.destination?.index;
        // 새로운 카테고리 ID 순서 배열 생성
        const newOrderIds = Array.from(subCategoriesData?.data?.map(category => category.id));
        const [removed] = newOrderIds.splice(startIndex, 1);
        newOrderIds.splice(endIndex, 0, removed);

        // 카테고리 우선 순위 업데이트 요청
        await updateCategoryPriority({ 
            parent: categoryId,
            priority: newOrderIds
        });
        // mainCategoriesData 다시 불러오기
        await refetchSubCategories();
    }

    return (
        <DragAndDropComponent
            items={subCategoriesData.data}
            onDragEnd={handleDragEnd}
            renderItem={(item) => (
                <>
                    <TbArrowsMoveVertical className='text-grey me-2'/>
                    <span
                        className='pointer'
                        style={{ color: selectedSubCategoryId === item?.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                        onClick={() => categoryClickEvent(item, setSelectedSubCategoryId, queryClient)}
                    >
                        {item?.name}
                    </span>
                </>
            )}
        />
    );
}

// 소분류 카테고리 순서변경
function EditSubSubPriority({ categoryId, selectedSubSubCategoryId, setSelectedSubSubCategoryId }) {
    const queryClient = useQueryClient();
    const { isLoading, isError, data: subSubCategoriesData, refetch: refetchSubSubCategories } = useSubSubCategories(categoryId);
    
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error occurred while fetching main categories.</div>;
    }

    const handleDragEnd = async(res) => {
        if (!res?.destination) {
            return; // 드롭이 시작한 위치 밖으로 이동된 경우
        }
        // 출발 index, 도착 index
        const startIndex = res?.source?.index;
        const endIndex = res?.destination?.index;
        // 새로운 카테고리 ID 순서 배열 생성
        const newOrderIds = Array.from(subSubCategoriesData?.data?.map(category => category?.id));
        const [removed] = newOrderIds.splice(startIndex, 1);
        newOrderIds.splice(endIndex, 0, removed);

        // 카테고리 우선 순위 업데이트 요청
        await updateCategoryPriority({ 
            parent: categoryId,
            priority: newOrderIds
        });
        // mainCategoriesData 다시 불러오기
        await refetchSubSubCategories();
    }

    return (
        <DragAndDropComponent
            items={subSubCategoriesData?.data}
            onDragEnd={handleDragEnd}
            renderItem={(item) => (
                <>
                    <TbArrowsMoveVertical className='text-grey me-2'/>
                    <span
                        className='pointer'
                        style={{ color: selectedSubSubCategoryId === item?.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                        onClick={() => categoryClickEvent(item, setSelectedSubSubCategoryId, queryClient)}
                    >
                        {item?.name}
                    </span>
                </>
            )}
        />
    );
}

// 필터링 옵션 순서변경
function EditFilteringOptionPriority({ categoryId, selectedFilteringOption, setSelectedFilteringOption }) {
    const queryClient = useQueryClient();
    const { isLoading, isError, data: filteringOptionsData, refetch: refetchSubSubCategories } = useFilteringOptions(categoryId);
    
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error occurred while fetching main categories.</div>;
    }

    const handleDragEnd = async(res) => {
        if (!res?.destination) {
            return; // 드롭이 시작한 위치 밖으로 이동된 경우
        }
        // 출발 index, 도착 index
        const startIndex = res?.source?.index;
        const endIndex = res?.destination?.index;
        // 새로운 카테고리 ID 순서 배열 생성
        const newOrderIds = Array.from(filteringOptionsData?.data?.map(category => category?.id));
        const [removed] = newOrderIds.splice(startIndex, 1);
        newOrderIds.splice(endIndex, 0, removed);

        // 카테고리 우선 순위 업데이트 요청
        await updateCategoryPriority({ 
            parent: categoryId,
            priority: newOrderIds
        });
        // mainCategoriesData 다시 불러오기
        await refetchSubSubCategories();
    }

    return (
        <DragAndDropComponent
            items={filteringOptionsData?.data}
            onDragEnd={handleDragEnd}
            renderItem={(item) => (
                <>
                    <TbArrowsMoveVertical className='text-grey me-2'/>
                    <span
                        className='pointer'
                        style={{ color: selectedFilteringOption === item?.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                        onClick={() => categoryClickEvent(item, setSelectedFilteringOption, queryClient)}
                    >
                        {item?.name}
                    </span>
                </>
            )}
        />
    );
}

// 필터링 옵션 순서변경
function EditFilterPriority({ categoryId, selectedFilter, setSelectedFilter }) {
    const queryClient = useQueryClient();
    const { isLoading, isError, data: filterData, refetch: refetchFilterData } = useFilteringOptions(categoryId);
    
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error occurred while fetching main categories.</div>;
    }

    const handleDragEnd = async(res) => {
        if (!res?.destination) {
            return; // 드롭이 시작한 위치 밖으로 이동된 경우
        }
        // 출발 index, 도착 index
        const startIndex = res?.source?.index;
        const endIndex = res?.destination?.index;
        // 새로운 카테고리 ID 순서 배열 생성
        const newOrderIds = Array.from(filterData?.data?.map(category => category?.id));
        const [removed] = newOrderIds.splice(startIndex, 1);
        newOrderIds.splice(endIndex, 0, removed);

        // 카테고리 우선 순위 업데이트 요청
        await updateCategoryPriority({ 
            parent: categoryId,
            priority: newOrderIds
        });
        // mainCategoriesData 다시 불러오기
        await refetchFilterData();
    }

    return (
        <DragAndDropComponent
            items={filterData?.data}
            onDragEnd={handleDragEnd}
            renderItem={(item) => (
                <>
                    <TbArrowsMoveVertical className='text-grey me-2'/>
                    <span
                        className='pointer'
                        style={{ color: selectedFilter === item?.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                        onClick={() => categoryClickEvent(item, setSelectedFilter, queryClient)}
                    >
                        {item?.name}
                    </span>
                </>
            )}
        />
    );
}

// 드래그앤드롭 컴포넌트
const DragAndDropComponent = ({ items, onDragEnd, renderItem }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                    <Draggable 
                        key={item?.id.toString()} 
                        draggableId={item?.id.toString()} 
                        index={index}>
                        {(provided) => (
                        <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            {renderItem(item)}
                        </li>
                        )}
                    </Draggable>
                    ))}
                </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};
  