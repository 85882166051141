import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useQuery } from 'react-query';
import React, { useState , useEffect } from 'react';

function EditProfile()
{
    const [toggle , setToggle] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        console.log("cccc");
        setIsOpen(true);
    }
  
    const closeModal = () => {
      setIsOpen(false);
    }

    
    return(
        <>
        <div className='mainContents'> 
            <div className='page-tit'>
                <h2>정보수정</h2>
            </div>
           
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6'>
                                <option value="all" selected="selected">전체</option>
                                <option value="">상품명</option>
                                <option value="">상품ID</option>
                            </select>
                            <input type="text"
                                   /> 
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select>
                                <option value="">신청일</option>
                            </select>
                            <input type="date"/>    
                            <input type="date"/>       
                        </div>   
                    </div>
            
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn'>초기화</button>
                    <button className='m-btn m-btn-search'>검색</button>
              
                </div>
            </section>
        

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className=''>
                        <span>검색결과</span>
                        <span className='result-wrap'>(총 <b className='sum-s-b'>0</b>개)</span>
                    </div>
                    <div className=''>
                        <select className='mSelectBox'>
                            <option value="" selected="selected">오래된 요청 순</option>
                            <option value="">오래된 가입일 순</option>
                            <option value="">포인트 많은 순</option>
                            <option value="">포인트 적은 순</option>
                            <option value="">구매금액 많은 순</option>
                            <option value="">구매금액 적은 순</option>
                            <option value="">주문횟수 많은 순</option>
                            <option value="">주문횟수 적은 순</option>
                        </select>
                        <select className='mSelectBox'>
                            <option value="" selected="selected">10개씩</option>
                            <option value="">50개씩</option>
                            <option value="">100개씩</option>
                        </select>
                    </div>
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>이메일 발송</button>
                    <button className='m-sm-btn'>SMS 발송</button>
                    <button className='m-sm-btn'>승인</button>
                    <button className='m-sm-btn'>반려</button>
                </div>
                {/* <button onClick={openModal}>Open Modal</button> */}
                {/* 상세설명 모달창 */}
                    {isOpen && (
                        <div className="modal ">
                            <div className="modal-content ProductDetailModal">
                                <span className="close" onClick={closeModal}>&times;</span>
                                <p>Modal Content Goes Here</p>
                            </div>
                        </div>
                    )}

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                            {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>
                                <th>발행일</th>
                                <th>사업자등록번호</th>
                                <th>발행내용</th>
                                <th>공급가액</th>
                                <th>세액</th>
                                <th>합계</th>
                                <th>이미지</th>
                        
                            </tr>
                            <tr>
                                <td><input type="checkbox" name="Allcheck" value=""/></td>
                               
                            </tr>
                        </thead>
                    </table>
                </div>
            </section>

        </div>

        </>
    )
}

export default EditProfile