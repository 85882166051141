// extractDateUtils.js

export function extractYearMonthDayHourMinute(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    if (year === 9999) return "없음";
  
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
  
    return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
}
  
export function extractYearMonthDay(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    if (year === 9999) return "없음";
    const month = date.getMonth() + 1;
    const day = date.getDate();
  
    return `${year}년 ${month}월 ${day}일`;
}
  
export function extractYearMonthDayHourMinute2(dateString)
{
    const dateObject = new Date(dateString);
    const formattedDate = `${dateObject.getFullYear()}.${(dateObject.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${dateObject
        .getDate()
        .toString()
        .padStart(2, '0')} ${dateObject.getHours()}:${dateObject
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    
      return `${formattedDate}`; 
}

// 년,월,일 
export function extractYearMonthDay2(dateString) 
{
    const dateObject = new Date(dateString);
    const formattedDate = `${dateObject.getFullYear()}.${(dateObject.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${dateObject
        .getDate()
        .toString()
        .padStart(2, '0')}`;
    
    return formattedDate; 
}
