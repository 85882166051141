// 검색 > 기간 > 연도  
const YearSelect = ({ value, onChange }) => (
    <select className='mSelectBox mr6' value={value} onChange={onChange}>
        {Array.from({ length: 5 }, (_, index) => {
            const year = new Date().getFullYear() - index;
            return (
            <option key={year} value={year.toString()}>
                {year}
            </option>
            );
        })}
    </select>
);

export default YearSelect