/***************************************************************************************
* FileName      : SalesHistory.js
* Description   : 셀러관리자 > 정산 > 매출내역 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';

import Purchase from "./Sales/Purchase";
import Sale from "./Sales/Sale";


function SalesHistory()
{
    const [activeComponent, setActiveComponent] = useState("Purchase");

    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
    };
    

    return(
        <>
       <div className='mainContents'>
            {/*  매출내역  */}
            <div className='page-tit'>
                <h2>매출내역</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "Purchase" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Purchase")}>구매확정 기준</button>

                <button className={activeComponent === "Sale" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Sale")}>상품판매 기준</button>
          
           
            </div>
            {/* 구매확정 기준 */}
            {activeComponent === "Purchase" && <Purchase />}
            
            {/* 상품판매 기준 */}
            {activeComponent === "Sale" && <Sale />}


        </div>


        </>
    )
}

export default SalesHistory