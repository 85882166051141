// 상품 데이터 수정 > 
import React, { useState , useEffect } from 'react';

function AquaticInfo({ aquaticInfoData,setAquaticInfoData })
{
    // 1 . 품목 또는 명칭
    const [name, setName] = useState(aquaticInfoData.name || ''); 

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                name: newName
        }));
    };

    // 2. 포장단위별 내용물의 용량(중량)
    const [volume, setVolume] = useState(aquaticInfoData.volume || '');

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                volume: newVolume
        }));
    };

    // 3. 생산자(수입자)
    const [manufacturer, setManufacturer] = useState(aquaticInfoData.manufacturer || '');

    const handleManufacturerChange = (e) => {
        const newManufacturer = e.target.value;
        setManufacturer(newManufacturer);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                manufacturer: newManufacturer
        }));
    };

    // 4. 원산지 
    const [origin, setOrigin] = useState(aquaticInfoData.origin || '');
    const handleOriginChange = (e) => {
        const newOrigin = e.target.value;
        setOrigin(newOrigin);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                origin : newOrigin
        }));
    };

   // 5. 제조연월일 , 소비기한 또는 품질유지기한
    const [manufacturedDate, setManufacturedDate] = useState(aquaticInfoData.manufactured_date || '');

    const handleManufacturedDateChange = (e) => {
        const newManufacturedDate = e.target.value;
        setManufacturedDate(newManufacturedDate);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                manufactured_date : newManufacturedDate
        }));
    };

    // 6. 세부 품목군별 표시사항
    const [markDetail , setMarkDetail] = useState(aquaticInfoData.mark_detail || '');
 
    const handleMarkDetailChange = (e) => {
        const newMarkDetailDate = e.target.value;
        setMarkDetail(newMarkDetailDate);

        setAquaticInfoData((prevAquaticInfoData) => ({
            ...prevAquaticInfoData, 
            mark_detail: newMarkDetailDate
        }));
    }
    
    
    // 7. 수입식품 문구 여부 
    const [importFoodText , setImportFoodText] = useState(aquaticInfoData.imported_food_text || '');

    const handleImportFoodTextChange = (e) => {
        const newImportFoodText = e.target.value ;
        setImportFoodText(newImportFoodText);

        setAquaticInfoData((prevAquaticInfoData) => ({
            ...prevAquaticInfoData, 
            imported_food_text: newImportFoodText
        }));
    }
    

    // 8. 상품구성
    const [packageDate, setPackageDate] = useState(aquaticInfoData.package || '');

    const handlePackageDateChange = (e) => {
        const newPackageDate = e.target.value;
        setPackageDate(newPackageDate);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                package : newPackageDate
        }));
    };

    // 9. 소비자안전을 위한 주의사항
    const [storageMethod, setStorageMethod] = useState(aquaticInfoData.storage_method || '');

    const handlestorageMethodChange = (e) => {
        const newStorageMethod = e.target.value;
        setStorageMethod(newStorageMethod);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                storage_method : newStorageMethod
        }));
    };

    // 10 . 소비자안전을 위한 주의사항 
    const [caution, setCaution] = useState(aquaticInfoData.caution || '');

    const handleCautionChange = (e) => {
        const newCaution = e.target.value;
        setCaution(newCaution);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                caution : newCaution
        }));
    };

        
    // 11 . 소비자상담 관련 전화번호
    const [cs, setCs] = useState(aquaticInfoData.cs || '');

    const handleCsChange = (e) => {
        const newCs = e.target.value;
        setCs(newCs);

        setAquaticInfoData((prevAquaticInfoData) => ({
                ...prevAquaticInfoData,
                cs : newCs
            }));
    };

       
    return (
        <>
          <div className='Table_wrap'>
                <table className="sTable">
                    <colgroup>
                                
                    </colgroup>
                    <thead>
                        <tr>
                            <th>고시정보명</th>
                            <th>내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>품목 또는 명칭</td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        value={name}
                                        onChange={handleNameChange}
                                        placeholder="직접 입력해주세요."
                                ></textarea>
                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                포장단위별 내용물의 용량(중량),
                                <br/>
                                수량, 크기
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={volume}
                                        onChange={handleVolumeChange}>        
                                </textarea>

                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                생산자(수입자)
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        value={manufacturer}
                                        onChange={handleManufacturerChange }   
                                        placeholder="직접 입력해주세요."
                                ></textarea>
                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                원산지
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={origin}
                                        onChange={handleOriginChange}   
                                ></textarea>
                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                제조연월일, 소비기한 또는 품질유지기한
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={manufacturedDate}
                                        onChange={handleManufacturedDateChange}   
                                ></textarea>
                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                세부 품목군별 표시사항
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={markDetail}
                                        onChange={handleMarkDetailChange}  
                                ></textarea>
                                <label>
                                    <input type="checkbox"
                                            name="product_reference"
                                            value="product_1"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    handleMarkDetailChange(e); 
                                                } else {
                                                    handleMarkDetailChange({ target: { value: '' } }); 
                                                }
                                            }}
                                    />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                수입식품 문구 여부
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={importFoodText}
                                        onChange={handleImportFoodTextChange}          
                                ></textarea>
                                <label>
                                    <input type="checkbox" 
                                            name="product_reference"
                                            value="product_1" 
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    handleImportFoodTextChange(e); 
                                                } else {
                                                    handleImportFoodTextChange({ target: { value: '' } }); 
                                                }
                                            }}
                                            />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                상품구성
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={packageDate}
                                        onChange={handlePackageDateChange}   
                                ></textarea>
                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                보관방법, 취급방법
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={storageMethod}
                                        onChange={handlestorageMethodChange}   
                                ></textarea>
                                
                                <label>
                                    <input type="checkbox" 
                                            name="product_reference" 
                                            value="상품 상세페이지 참조" 
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    handlestorageMethodChange(e); 
                                                } else {
                                                    handlestorageMethodChange({ target: { value: '' } }); 
                                                }
                                            }}
                                    />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                소비자안전을 위한 주의사항
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={caution}
                                        onChange={handleCautionChange}  
                                ></textarea>
                                <label>
                                    <input 
                                        type="checkbox" 
                                        name="product_reference"
                                        value="product_1" 
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleCautionChange(e); 
                                            } else {
                                                handleCautionChange({ target: { value: '' } }); 
                                            }
                                        }}
                                        />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                소비자상담 관련 전화번호
                            </td>
                            <td className="td-flex">
                                <textarea className="textarea-box"
                                        placeholder="직접 입력해주세요."
                                        value={cs}
                                        onChange={handleCsChange}   
                                ></textarea>
                                <label>
                                    <input type="checkbox" name="product_reference" value="product_1" />
                                    상품 상세페이지 참조
                                </label>
                            </td>
                        </tr>
                    </tbody>

                </table>     
            </div>
        </>
    );
}

export default AquaticInfo