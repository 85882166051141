/***************************************************************************************
* FileName      : Target.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발송예약
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.27
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect , useState } from 'react';
export default function Target({reservationData ,setReservationData}){

    const updateField = (fieldName, newValue) => {
        setReservationData((prevData) => ({
          ...prevData,
          [fieldName]: newValue
        }));    
    };

    // 대상회원
    const [target, setTarget] = useState('전체');

    const handleTargetMethod = (event) => {
        const Value = event.target.value;
        setTarget(Value);

        updateField("receiver_group", Value);
    };


    return(
       <>
        <div>
            <table>                            
                <tbody>
                    <tr>
                        <td className='radioBtn-wrap'>
                            <label>
                                <input
                                    type="radio"
                                    value="전체"
                                    checked={target === '전체'}
                                    onChange={handleTargetMethod}
                                />
                                전체
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    value="일반회원"
                                    checked={target === '일반회원'}
                                    onChange={handleTargetMethod}
                                />
                                일반회원
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    value="셀러"
                                    checked={target === '셀러'}
                                    onChange={handleTargetMethod}
                                />
                                셀러
                            </label>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
       </>
    )
}