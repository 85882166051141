/***************************************************************************************
* FileName      : chartConfig.js
* Description   : 해와관리자 > 가입통계 > chartConfig
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
export const labelDataObject = {
    total_sales: '총 판매',
    male_sales: '남성 판매',
    female_sales: '여성 판매',
};
  
export const chartBackgroundColor = {
    total_sales: '#999999',
    female_sales: '#F6B6C1',
    male_sales: '#AACFF2',
};

export const chartBorderColor = {
    total_sales: '#555555',
    female_sales: '#ED6E85',
    male_sales: '#57A0E5',
};