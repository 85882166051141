/***************************************************************************************
* FileName      : Time.js
* Description   : 해와관리자 > 통계 > 판매통계 > 시간대별 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Chart from '../../../components/Chart'

import GenderCheckboxGroup from "../Component/ProduceStats/GenderCheckboxGroup.js";
import AgeCheckboxGroup from "../Component/SignupStats/AgeCheckboxGroup";
import YearSelect from "../Component/YearSelect";
import MonthSelect from "../Component/MonthSelect";
import DaySelect from "../Component/DaySelect.js";
import SearchField from "../Component/ProduceStats/SearchField.js";

import { getSalesHourly , handleSearchSalesHourly } from "../../../../api/hwadmin/statisticApi";

import { labelDataObject , chartBackgroundColor , chartBorderColor } from './chartConfig.js';

function Time()
{
    const [chartData,setChartData] = useState([]);

    const [isUpdated, setIsUpdated] = useState(false);
    
    const currentYear = new Date().getFullYear().toString();
    const currentMonth = (new Date().getMonth() + 1).toString();
    const currentDay = new Date().getDate().toString();


    const [searchYear, setSearchYear] = useState(currentYear);
    const [searchMonth, setSearchMonth] = useState(currentMonth);
    const [searchDay, setSearchDay] = useState(currentDay);

    const [searchResults, setSearchResults] = useState([]);


    const { isLoading, error, data } = useQuery(["getSalesHourly", currentYear,currentMonth,currentDay],
                 () => getSalesHourly(currentYear,currentMonth,currentDay));

  
    // 성별 체크박스 
    const [genderCheckedState, setGenderCheckedState] = useState({
        total_sales: true,
        male_sales: true,
        female_sales: true,
    });

    const handleGenderCheckboxChange = (event) => {
        setGenderCheckedState({
            ...genderCheckedState,
            [event.target.name]: event.target.checked,
        });
    };

    // 연령별 체크박스 
    const [ageCheckedState, setAgeCheckedState] = useState({
        "10": false,
        "20": false,
        "30": false,
        "40": false,
        "50": false,
        "60": false,
        // "60대 이상": false,
    });
    
    
    const handleAgeCheckboxChange = (event) => {
        setAgeCheckedState({
            ...ageCheckedState,
            [event.target.name]: event.target.checked,
        });
    };

    // 단위(결제완료기준)
    const [selectedPayment , setSelectedPayment ] = useState('판매금액(원)');


    // 단위(결제완료기준) 값 변화
    const handleCategoryTypeRadioChange = (event) => {
        setSelectedPayment(event.target.value);
    };
   
   
    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/produceStats/';
    };
    
    // 검색버튼 클릭 시 
    const onSearch = async  () => {

        const selectedAges = Object.keys(ageCheckedState).filter((age) => ageCheckedState[age]).join(',');

        await handleSearchSalesHourly({
            setSearchResults,
            searchYear,
            searchMonth,
            searchDay,
            selectedAges
        });
    };

    useEffect(() => {
        if (searchResults && searchResults.labels && searchResults.labels.length > 0) {
            updateChartData(genderCheckedState);
        }
    },[searchResults]);


    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.labels, 
                datasets: [
                    {
                        label: '총 판매 금액',
                        data: data?.total_sales_price,
                        backgroundColor: '#7CCE76',
                        borderColor: '#2F9D27',
                        pointBackgroundColor: '#7CCE76',
                        pointBorderColor: '#2F9D27',
                        pointHoverBackgroundColor: 'red',
                        pointHoverBorderColor: 'red',
                    },
                    {
                        label: '여성 판매 금액',
                        data: data?.female_sales_price,
                        backgroundColor: '#F6B6C1',
                        borderColor: '#ED6E85',
                        pointBackgroundColor: '#F6B6C1',
                        pointBorderColor: '#ED6E85',
                        pointHoverBackgroundColor: 'red',
                        pointHoverBorderColor: 'red',
                    },  {
                        label: '남성 판매 금액',
                        data: data?.male_sales_price,
                        backgroundColor: '#AACFF2',
                        borderColor: '#57A0E5',
                        pointBackgroundColor: '#AACFF2',
                        pointBorderColor: '#57A0E5',
                        pointHoverBackgroundColor: 'red',
                        pointHoverBorderColor: 'red',
                    },
                ],
            };
    
            setChartData(newChartData);
        }
    },[data])

    function updateChartData(genderCheckedState)
    {
        const labels = searchResults?.labels;
        const datasets = [];
        for (const key in genderCheckedState) {
            if (genderCheckedState[key]) {
                const newKey = selectedPayment === '판매금액(원)' ? key + "_price" : key + "_count"; 

                const dataKey = searchResults?.[newKey];
                
                const backgroundColor = chartBackgroundColor[key];
                const borderColor = chartBorderColor[key];

                const label = selectedPayment === '판매금액(원)' ? 
                    labelDataObject[key]+"금액" : labelDataObject[key]+"수";

                console.log("label:",label);

                datasets.push({
                    label: label,
                    data: dataKey,
                    backgroundColor,
                    borderColor,
                    pointBackgroundColor: backgroundColor,
                    pointBorderColor: borderColor,
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                });
            }
        }
        
        const newChartData = {
            labels: labels,
            datasets: datasets,
        };
        
    
        setChartData(newChartData);
        setIsUpdated(true);
    
    }

    return (
        <>
        {/* 검색 */}
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field date'>
                    <div> <b>기간</b> </div>
                    <div>
                        <YearSelect value={searchYear} onChange={(e) => setSearchYear(e.target.value)} />
                        <MonthSelect value={searchMonth} onChange={(e) => setSearchMonth(e.target.value)} />
                        <DaySelect value={searchDay} onChange={(e) => setSearchDay(e.target.value)} />
                    </div>
                </div>
            
                <div className='search-field division'>
                    <div> 
                        <b>성별</b> 
                    </div>
                    <GenderCheckboxGroup
                        checkedState={genderCheckedState}
                        onChange={handleGenderCheckboxChange}
                    />
                </div>

                <div className='search-field division'>
                    <div> 
                        <b>연령대별 필터</b> 
                    </div>
                    <AgeCheckboxGroup
                        checkedState={ageCheckedState}
                        onChange={handleAgeCheckboxChange}
                    />
                </div>

                {/* 단위(결제완료기준) */}
                <SearchField
                    selectedPayment={selectedPayment}
                    handleCategoryTypeRadioChange={handleCategoryTypeRadioChange}
                />
            </div>
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={handleResetClick}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
        </section>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <Chart data={chartData}/>
        </section>
 
    
    </>
    )
}

export default Time