/***************************************************************************************
* FileName      : Manager.js
* Description   : 해와관리자 > 알림 > 알림톡발송관리 > 자동발생 관리 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import PageSizeSelect from "../../common/PageSizeSelect";
import { useNotificationTalkListQuery , getTalkAvailable } from '../../../../api/hwadmin/notificationApi';
import ToggleSwitch from './ToggleSwitch';
import Pagination from '../../common/Pagination';

function Manager()
{
    const [pageSize, setPageSize] = useState(10); 

    const [talkAvailable, setTalkAvailable] = useState();

    useEffect(()=>{
        getTalkAvailable()
            .then(res => setTalkAvailable(res.data.list.ALT_CNT))
            .catch(error => error.response.data)
    },[])


    useEffect(()=>{
        console.log(talkAvailable);
        console.log("-talkAvailable-")
    },[talkAvailable])

    const { data, error, isFetching, page, setPage } = useNotificationTalkListQuery(pageSize);


    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
  
    return (
        <>
        <span className="nt mb-5">알리고 발송 가능 건수 : {talkAvailable && talkAvailable}건</span>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className=''>
                    <span>검색결과</span>
                    <span className='result-wrap'>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</span>
                </div>

                <div className='selectWrap'>
                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                </div>
            </div>
            <div className='m-btn-wrap-sm'>
                <button className='m-sm-btn'>활성 전환</button>
                <button className='m-sm-btn'>비활성 전환</button>
                <button className='m-sm-btn'>다운로드</button>
            </div>

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                            {/* <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/>
                            <col style="width:10%"/> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>조건명</th>
                            <th>상태</th>
                            <th>대상자</th>
                            <th>템플릿</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>     
                                    <ToggleSwitch type="talk" itemKey={item.key} checked={item.is_active}/>
                                </td>
                                <td>{item.receiver_group}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>

        <section>
            {data && data.data.length > 0 && (
                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    />
            )}
        </section>
    </>
    )
}

export default Manager