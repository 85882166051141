/***************************************************************************************
* FileName      : SalesHistory.js
* Description   : 해와관리자 > 정산 > 매출내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';
import { useAdjustSummary2DocumentQuery , handleAdjustSummary2Search} from '../../../api/hwadmin/adjustApi';

import PageSizeSelect from '../common/PageSizeSelect';

import { AdjustRow } from './Sales/AdjustRow';
import Pagination from '../common/Pagination'

function SalesHistory()
{
    const [ordering, setOrdering] = useState('-date');
    const [pageSize, setPageSize] = useState(10); 
    const { data, page, setPage } = useAdjustSummary2DocumentQuery(ordering,pageSize);
    
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
 
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
     
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleResetClick = () => {
        window.location.href = '/admin/settle/SalesHistory'; 
    };
    
     const onSearch = () => {
        handleAdjustSummary2Search({
             setSearchResults,
             setSearchUrl,
             selectedStartDate,
             selectedEndDate, 
         });
 
         setSearchConfirm(true);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    useEffect(()=>{
        console.log(searchResults);

    },[searchResults])
    return (
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>매출내역</h2>
                <span className="nt">해당날짜에 판매된 상품(상품판매일 기준)의 매출 내역입니다.</span>
            </div>
          
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div> 
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />    
                        </div>   
                    </div>
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>


        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className='resultsCount-wrap'>
                    <span>검색결과</span>
                    <span className='result-wrap'>
                        {searchResults.totalCnt > 0 ? (
                            <>
                                <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                            </>
                            ) : (
                            <>
                                <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                            </>
                        )}
                    </span>
                </div>
                { !searchConfirm && 
                    <div className='selectWrap'>
                        <div className='selectWrap'>
                            <select className='mSelectBox' id="ordering" value={ordering} onChange={handleOrderingChange}>
                                <option value="-date">최신순</option>
                                <option value="date">오래된순</option>
                                <option value="-sales">매출높은순</option>
                                <option value="sales">매출낮은순</option>
                            </select>
                        </div>

                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                }
            </div>
            <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>다운로드</button>
            </div>
            
            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>
                    </colgroup>
                    <thead>
                        <tr>
                            <th rowspan="2">
                                <input type="checkbox"/>
                            </th>
                            <th rowspan="2">날짜</th>
                            <th rowspan="2">구분</th>
                            <th colspan="6">결제수단별 매출</th>
                        </tr>
                        <tr>
                            <th>카드</th>  
                            <th>가상계좌</th>
                            <th>간편결제</th>
                            <th>휴대폰</th>
                            <th>계좌이체</th>
                            <th>합계</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    {searchConfirm && searchResults?.count === 0 ? (
                        <tr>
                            <td colSpan="17">검색 결과가 없습니다.</td>
                        </tr>
                    ) : searchResults?.count > 0 ? (
                        searchResults?.data.map((Adjust , index) => (
                            <React.Fragment key={index}>     
                              <AdjustRow 
                                    Adjust={Adjust}/>                      
                                
                            </React.Fragment>
                        ))
                    ) : data && data?.data && data?.data.length > 0 ? (
                        data?.data.map((Adjust , index ) => (
                            <React.Fragment key={index}>     
                              <AdjustRow 
                                    Adjust={Adjust}/>                      
                                
                            </React.Fragment>
                        ))
                    ) : null}

                </tbody>
                </table>
            </div>
        </section>
    
        {/* 페이지내비게이션 */}
        <section>
            {searchConfirm && searchResults?.count === 0 ? (
                <>
                </>
            ) : searchResults?.count > 0 ? (
                    <Pagination
                        count={searchResults?.count}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                />
            ) : (
                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                />
            )}
        </section>
    </div>
    </>
    )
}

export default SalesHistory