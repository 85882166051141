/***************************************************************************************
* FileName      : PurchaseList.js
* Description   : 셀러관리자 > 주문배송 > 구매확정내역 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import React, { useState , useEffect } from 'react';

import SortComponent from '../../admin/common/SortComponent2';
import PageSizeSelect from '../../admin/common/PageSizeSelect.js';
import { useSellerConfirmQuery , handleSelleConfirmrSearch } from '../../../api/selleradmin/productApi.js';

import Pagination from '../common/Pagination'
import SearchPagination from '../../admin/common/SearchPagination.js';
import { OrderRow } from './PurchaseList/OrderRow.js';

function PurchaseList()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage, toggle, setToggle } = useSellerConfirmQuery(ordering,pageSize);

    const [searchProduct, setSearchProduct] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');

    const [selectedRows, setSelectedRows] = useState({});

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
 
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    if (error) return <div>Error loading</div>;
    if (!data) return <div>Loading...</div>;




    const openModal = () => {
        console.log("cccc");
        setIsOpen(true);
    }
  
    const closeModal = () => {
      setIsOpen(false);
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleSelleConfirmrSearch({
            searchProduct, 
            setSearchResults,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
            setSearchUrl,
        });
        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/selleradmin/orderDelivery/purchaseList';
    };
    
    
    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
      
    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };
    
    console.log(data);

    return(
        <>
        <div className='mainContents'> 
            <div className='page-tit'>
                <h2>구매확정내역</h2>
            </div>
           
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="orderNumber">주문번호</option>
                                <option value="id">구매자ID</option>
                            </select>

                            <input type="text"
                                    value={searchProduct}
                                    onChange={(e) => setSearchProduct(e.target.value)}/>
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="paid_at">결제완료일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>
        

             {/* 검색 결과 */}
             <section className='McontentBox'>   
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규등록순"
                                oldLabel="오래된등록순"
                            />
        
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
              
                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                            {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주문번호</th>
                                <th>구매확정일</th>
                                <th>확정처리기준</th>
                                <th>
                                    구매자 ID <br/>
                                    구매자 이름
                                </th>
                                <th>
                                    수취인 이름 <br/>
                                    수취인 연락처
                                </th>
                                <th>배송구분</th>
                                <th>정산일</th>
                            </tr>
                    
                        </thead>
                        <tbody>

                            {searchConfirm && searchResults?.count === 0 ? (
                                    <tr>
                                        <td colSpan="17">검색 결과가 없습니다.</td>
                                    </tr>
                                ) : searchResults?.count > 0 ? (
                                    searchResults?.data.map((order) => (
                                        <OrderRow
                                            key={order?.id}
                                            order={order}
                                           
                                            />
                                    ))
                                ) : data && data.data && data.data.length > 0 ? (
                                    data.data.map((order) => (
                                        <OrderRow
                                            key={order?.id}
                                            order={order}
                                         
                                        />
                                ))
                                ) : null}                           
                        </tbody>
                        </table>
                    </div>
                </section>

                {/* 페이지내비게이션 */}
                <section>
                    {searchResults.count > 0 ? (
                        <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                    ) : (
                    
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    )}
                </section>
  
            </div>

        </>
    )
}

export default PurchaseList