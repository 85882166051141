let data = [
    {
        id : 0,
        title : "[신규입점할인] 목포 생물 바지락",
        content : "Born in France",
        cost: 20000,
        price : 10000,
        discountRate: '50%'
    },
    {
        id : 1, 
        title : "주먹만한 피꼬막",
        content : "Born in France",
        cost: 30000,
        price : 27000,
        discountRate: '10%'
    },
    {
        id : 2,
        title : "보리굴비 10미 특가 할인",
        content : "Born in France",
        cost: 40000,
        price : 30000,
        discountRate: '25%'
    },
    {
        id : 3, 
        title : "먹갈치 2째주 부터 배송 선주문",
        content : "Born in France",
        cost: 22000,
        price : 22000,
        discountRate: '0%'
    },
 
]

export default data;