// 해와관리자 > 통계 > 방문통계 > 일별
import React, { useEffect, useState } from "react";
import Chart from '../../../components/Chart.js'

import { getVisitDaily } from "../../../../api/hwadmin/statisticApi.js";
import { useQuery } from "react-query";

import YearSelect from "../Component/YearSelect.js";
import MonthSelect from "../Component/MonthSelect.js";
import CheckboxGroup from "../Component/CheckboxGroup.js";

import { labelDataObject , chartBackgroundColor , chartBorderColor } from './chartConfig.js';

function Day()
{
    const [chartData,setChartData] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    const [isChecked, setIsChecked] = useState({
        total_visiter: false,
        pc_visiter: false,
        mobile_app_visiter: false,
        mobile_visiter: false,
        app_visiter: false,
    });

    const currentYear = new Date().getFullYear().toString();

    const [year, setYear] = useState(currentYear);
    const [searchYear, setSearchYear] = useState(currentYear);

    const currentMonth = (new Date().getMonth() + 1).toString(); 
    const [month, setMonth] = useState(currentMonth);
    const [searchMonth, setSearchMonth] = useState(currentMonth);

    const { isLoading, error, data } = useQuery(["month", year, month], () => getVisitDaily(year, month));

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/statistics/visitStats/';
    };
    
    if (isLoading) {
        console.log("데이터 로딩 중...");
    }
      
    if (error) {
        console.error("데이터 로딩 중 에러 발생:", error);
    }

    useEffect(()=>{
        if(!isUpdated){
            const newChartData = {
                labels: data?.labels, 
                datasets: [
                  {
                    label: '모바일(앱+웹)',
                    data: data?.mobile_app_visiter,
                    backgroundColor: '#FBE7B4',
                    borderColor: '#F7CF6B',
                    pointBackgroundColor: '#FBE7B4',
                    pointBorderColor: '#F7CF6B',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },  {
                    label: 'PC',
                    data: data?.pc_visiter,
                    backgroundColor: '#AACFF2',
                    borderColor: '#57A0E5',
                    pointBackgroundColor: '#AACFF2',
                    pointBorderColor: '#57A0E5',
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                  },
                ],
            };
    
            setChartData(newChartData);
        }
  
    },[data])

    // 검색버튼 클릭 시 
    const onSearch = () => {
        setYear(searchYear);
        setMonth(searchMonth);
        console.log("검색:",searchMonth);

        updateChartData(isChecked);
    };

    function updateChartData(isChecked)
    {
        const labels = data?.labels;
        const datasets = [];
        for (const key in isChecked) {
            if (isChecked[key]) {
              const dataKey = data?.[key];
              const backgroundColor = chartBackgroundColor[key];
              const borderColor = chartBorderColor[key];
              const label = labelDataObject[key];

              console.log("label:",label);

              datasets.push({
                label: label,
                data: dataKey,
                backgroundColor,
                borderColor,
                pointBackgroundColor: backgroundColor,
                pointBorderColor: borderColor,
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
              });
            }
        }
        
        const newChartData = {
            labels: labels,
            datasets: datasets,
        };
        
        // console.log("newChartData::",newChartData);;
        
    
        setChartData(newChartData);
        setIsUpdated(true);
    }
        




    const handleCheckboxChange = (event) => {
        setIsChecked({
          ...isChecked,
          [event.target.name]: event.target.checked,
        });
    };
    

    return (
        <>
        {/* 검색 */}
        <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field date'>
                    <div> <b>날짜</b> </div>
                    <div>

                        <YearSelect value={searchYear} onChange={(e) => setSearchYear(e.target.value)} />

                        <MonthSelect value={searchMonth} onChange={(e) => setSearchMonth(e.target.value)} />

                    </div>   
                </div>

            
                <div className='search-field division'>
                    <div> 
                        <b>조회</b> 
                    </div>
                    <CheckboxGroup checkedItems={isChecked} onChange={handleCheckboxChange} />
                </div>
            </div>

            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={handleResetClick}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
            </section>

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <Chart data={chartData}/>
            
        </section>
 
    
    </>
    )
}

export default Day