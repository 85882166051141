import React from 'react';

function OptionItem({option , setSelectedOptions}) {

    const handleRemoveOption = (id) => {
      setSelectedOptions((prevOptions) =>
          prevOptions.filter((option) => option.id !== id)
        );
    };

    const handleQuantityChange = (id, change) => {
      setSelectedOptions((prevOptions) =>
          prevOptions.map((option) =>
              option.id === id? { ...option, 
                quantity: Math.max(1, option.quantity + change),
                original_price: option.original_price,
                price: option.original_price * Math.max(1, option.quantity + change),
                // price: option.price * newQuantity,
                }: option
            
          )
        );
    };


  return (
    <div className='prd-add'>
      <div className='prd-amount-txt'>
        <span className='prd-name'>{option.name}</span>
        <span className='prd-remove' onClick={() => handleRemoveOption(option.id)}>x</span>
      </div>
      
      <div className='prd-amount-wrap'>
        <div className='amount-box'>
          <div className='amount'>
            <a className='del_item_quantity cursor amount-down'
                onClick={() => handleQuantityChange(option.id, -1)}>-</a>
            <input type="text" value={option.quantity} />
            <a className='add_item_quantity cursor amount-up'
                 onClick={() => handleQuantityChange(option.id, 1)}
            >+</a>
          </div>
        </div>

        <p className='fs-6 fw-bold'>{parseInt(option.price).toLocaleString()}원</p>
      </div>
    </div>
  );
}

export default OptionItem;
