/***************************************************************************************
* FileName      : MOrderDetailRow.js
* Description   : 고객 > 마이페이지 > 반품/취소 내역 > 반품 상세 내역 > MOrderDetailRow 컴포넌트 (모바일)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.18
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';
import { OrderPurchaseConfirm } from '../../../api/services/mypageApi.js';
import { useMutation } from 'react-query';

function MOrderDetailRow({deliveryItem, orderItem})
{

    const navigate = useNavigate();

    const [totalQuantity, setTotalQuantity] = useState(0);

    // useEffect(() => {
    //     const total = orderItem.orderitem_option_rows.reduce((acc, item) => acc + item.quantity, 0);
    //     setTotalQuantity(total);
    // }, [orderItem]);
    
    console.log("--orderItem")
    console.log(orderItem);
    
    console.log("deliveryItem---");
    console.log(deliveryItem.returns.status);

    

    return (
        <>
        
        <div className='m-order-wrap'>   
            <p className='m-orderStatus'>
                {
                    orderItem?.status === "배송완료" 
                    ? orderItem?.confirmation_status
                    : orderItem?.status
                }
            </p>
            <div className='m-prod-wrap'>  
                <div className='m-prod-thum' >
                    <img
                        src={deliveryItem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </div>
                <div className='m-prod-info'>
                    <div>
                        <p className='pbd'>
                            {deliveryItem?.confirmation_status} 
                            {deliveryItem?.returns?.status}
                        </p>
                        <span className='vendortxt'>[{deliveryItem.vendor_name}] </span>
                        <span className='pdtxt'>{deliveryItem.product_name}</span>
                        <p>{(orderItem?.sale_price ?? 0).toLocaleString()}원</p>
                        <p>주문수량 : {orderItem?.quantity?.toLocaleString() ?? "0"} </p>
                        <p>배송비 :  {deliveryItem?.order?.total_delivery_fee?.toLocaleString() ?? "0"}원 </p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MOrderDetailRow