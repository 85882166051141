import React from 'react';
import { useApprovedVendors } from '../../../../api/hwadmin/productApi';

const SelectVendor = ({ selectedVendor , setSelectedVendor }) => {
     
    // 수산상회 조회
    const { data: approvedVendors }  = useApprovedVendors();

    // 선택한 수산상회 저장
    const handleSelectChange = (event) => {
        const selectedOption = approvedVendors.data.find(
            (vendor) => vendor.name === event.target.value
        );
        setSelectedVendor({ id: selectedOption ? selectedOption.id : null, name: selectedOption ? selectedOption.name : null });
        // setSelectedVendor({ id: selectedOption.id, name: selectedOption.name });
    };
        
    return (
        <select className='mSelectBox' name="vendor" value={selectedVendor.name} onChange={handleSelectChange}>
            <option value="">전체</option>
            { approvedVendors && approvedVendors.data.map(vendor => (
                <option key={vendor.id} value={vendor.name}>
                    {vendor.name}
                </option>
            ))}
        </select>
    );
}

export default SelectVendor;