/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 셀러관리자 > 정산 > 정산현황 > AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <>
        <tr>
          <td>{Adjust.settlement_date}</td>
          <td>{Adjust.total_scheduled_amount.toLocaleString()}</td>
          <td>{Adjust.total_settlement_amount.toLocaleString()}</td>
          <td>{Adjust.cumulative_settlement_amount.toLocaleString()}</td>
         </tr>
      </>
    </>
  );
};
