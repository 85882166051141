import { instance } from '../../axiosInstance/axiosConfig';

// 상품 바로 구매
export const postPurcahse = (product, option_rows, extra_options) => {
    return instance.post('/order/purchase/',{
        items: [
            {   
                product: product,
                option_rows: option_rows,
                extra_options: extra_options
            }
        ]
    })
}