import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import { getAccessFromCookie } from '../cookieUtils'; 



// 입점사 상세 조회
const fetchVendorData = (id) => {
  const access = getAccessFromCookie();
  const headers = {
    'Authorization': `Bearer ${access}`,
    'Content-Type': 'application/json'
  };
  return fetch(`${BASE_URL}/vendor/${id}`, { headers })
    .then(res => res.json())
};

export default fetchVendorData;
