/***************************************************************************************
* FileName      : ScheduledMessage.js
* Description   : 해와관리자 > 알림 > 푸시알림관리 > 발송예약 관리 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import Target from "./ReservationForm/Target";
import Category from "./ReservationForm/Category";


import React, { useEffect , useState } from 'react';
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useNotificationPushSend } from "../../../../api/hwadmin/notificationApi";

function ScheduledMessage()
{
    const { post, isLoading: isPostLoading, error } = useNotificationPushSend();
    

    const [isLoading, setIsLoading] = useState(false);

 
    const [reservationData , setReservationData] = useState({
        receiver_group : "전체" ,
        hour : '',
        minute : '',
        title: '',
        body: ''
    });

    const validateForm = () => {
        let isValid = true;
        return isValid;
    }


    // 푸시제목
    const [title, setTitle] = useState(); 

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);

        setReservationData((prevData) => ({
            ...prevData,
            title: newTitle
        }));

    };
    
    // 푸시내용
    const [body, setBody] = useState(); 

    const handleBodyChange = (e) => {
        const newBody = e.target.value;
        setBody(newBody);

        setReservationData((prevData) => ({
            ...prevData,
            body: newBody
        }));

    };
   
    const handleSubmit = (event) => {
        if (validateForm()) {
            setIsLoading(true); 
            event.preventDefault();

            if (reservationData.hour === '' && reservationData.minute === '') {
                delete reservationData.hour;
                delete reservationData.minute;
            }else{    
                reservationData.hour = parseInt(reservationData.hour);
                reservationData.minute = parseInt(reservationData.minute);
            }

            console.log(reservationData);

            // post(reservationData)
            // .then(() => {
            //     alert("발송예약 성공적으로 완료하였습니다");
            //     setIsLoading(false); // 로딩 상태를 비활성화
            // })
            // .catch(error => {
            //     setIsLoading(false); // 로딩 상태를 비활성화
            // });
        }
    }

    return (
        <>
         
         <section className='product-register-wrap'>
            <div className='product-reguster-inner'>
                <div className='accordion'>
                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>대상회원 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Target 
                                reservationData={reservationData}
                                setReservationData={setReservationData}/>
                        </div>
                    </div>
               

                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>발송유형 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <Category 
                                reservationData={reservationData}
                                setReservationData={setReservationData}/>
                        </div>
                    </div>

                    
                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>푸시제목 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <input 
                                type="text" 
                                className="form-input2"
                                onChange={handleTitleChange}
                                placeholder="제목을 입력해 주세요"
                                />
                        </div>
                    </div>

                    <div className='accordion-item'>
                        <div className='title' id='' >
                            <p>푸시내용 <span>*</span></p>
                        </div>
                        <div className='content-show'>  
                            <textarea 
                                className="textarea-box"
                                onChange={handleBodyChange}
                                placeholder="내용을 입력해 주세요"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <div className='m-btn-wrap'>
            <button onClick={handleSubmit} className='m-btn m-btn-search'>
                {isLoading ?  <LoadingSpinner /> : '등록'} 
            </button>
        </div> 
    
    </>
    )
}

export default ScheduledMessage