import axios from 'axios';
import { BASE_URL } from '../apiConfig';

const fetchProductData = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/product/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

export default fetchProductData;
