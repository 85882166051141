/***************************************************************************************
* FileName      : MileagRow.js
* Description   : 해와관리자 > 통계 > 마케팅 > 마일리지 페이지 > MileagRow 마일리지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
export default function MileagRow({mileag}){
    return(
        <tr>
            <td>{mileag?.data}</td>
            <td>{mileag?.buy_reward_total}</td>
            <td>{mileag?.admin_reward_total}</td>
            <td>{mileag?.reward_total}</td>
            <td>{mileag?.reward_total}</td>      
            <td>{mileag?.use_total}</td>
            <td>{mileag?.remain_total}</td>
            <td>{mileag?.scheduled_mileage_total}</td>
            <td>{mileag?.expiring_scheduled_total}</td>
            <td>{mileag?.destroy_total}</td>
        </tr>
    )
}
