import React, { useState , useEffect} from 'react';
import { addCommasToNumberString } from '../../../common/numberUtils';

function ProductDetails({onInspectInfo})
{      
    const [inspectInfoData, setInspectInfoData] = useState({
        manufacturer : '',
        certification_target : '인증/신고 대상',
        minor_purchase : true,
        max_purchase_per_person : "",
        sales_start_date: "",
        sales_end_date: "",
        tax : "과세"
    });
    

    // 제조사 
    const [manufacturer, setManufacturer] = useState('');

    const handleManufacturerChange = (e) => {
        const newManufacturer = e.target.value;
        setManufacturer(newManufacturer);
        setInspectInfoData((prevInspectInfoData) => ({
          ...prevInspectInfoData,
          manufacturer: newManufacturer
        }));
    };

    // 인증정보
    const [certificationTarget, setCertificationTarget] = useState('인증/신고 대상');

    const handleCertificationTargetChange = (e) => {
        const newCertification = e.target.value;
        setCertificationTarget(newCertification);
        setInspectInfoData((prevInspectInfoData) => ({
            ...prevInspectInfoData,
            certification_target: newCertification
        }));
    };

    // 미성년자 구매 
    const [isMinor, setIsMinor] = useState('true');

    const handleIsminorChange = (e) => {
        const newIsMinor = e.target.value;
        setIsMinor(newIsMinor);
        setInspectInfoData((prevInspectInfoData) => ({
            ...prevInspectInfoData,
            minor_purchase: newIsMinor
        }));
    };


    // 인당 최대 구매수량
    const [maxQuantity, setMaxQuantity] = useState(null);

    const handleMaxQuantityRadioChange = (event) => {
        setMaxQuantity(event.target.value === 'yes' ? '' : null);
    };
      
    const handleMaxQuantityInputChange = (event) => {
        const newmaxQuantity = event.target.value;
        setMaxQuantity(newmaxQuantity);

        setInspectInfoData((prevInspectInfoData) => ({
            ...prevInspectInfoData,
            max_purchase_per_person: newmaxQuantity
        }));
    };
      
    // 판매기간
    const [salesPeriod , setSalesPeriod ] = useState(null);
    const [salesPeriodEnd, setSalesPeriodEnd] = useState('');

    const handleSalesPeriodRadioChange = (event) => {
        setSalesPeriod(event.target.value === 'yes' ? '' : null);
    };

    // 판매 기간 입력 값 변경 시 호출되는 함수
    const handleSalesPeriodInputChange = (e) => {
        const value = e.target.value;
        setSalesPeriod(value);
        setInspectInfoData((prevInspectInfoData) => ({
            ...prevInspectInfoData,
            sales_start_date : value
        }));
    };

    const handleSalesPeriodEndInputChange = (e) => {
        const value = e.target.value;
        setSalesPeriodEnd(value);

        setInspectInfoData((prevInspectInfoData) => ({
            ...prevInspectInfoData,
            sales_end_date : value
        }));
      };
    
    // 부가세 
    const [taxType, setTaxType] = useState('과세'); // 초기값을 '과세'로 설정

    const handleTaxChange = (e) => {
        const newTax = e.target.value;
        setTaxType(newTax);
        setInspectInfoData((prevInspectInfoData) => ({
            ...prevInspectInfoData,
            tax: newTax
        }));
    };


  
    useEffect(() => {
        onInspectInfo(inspectInfoData);
    }, [inspectInfoData]);

    return (
        <>
        <section className="">
            <div className='product-info'>
                <table>
                    <colgroup>
                              
                    </colgroup>
                   
                    <tbody>
                        <tr>
                            <th>제조사</th>  
                            <td>
                                <input type="text" 
                                        placeholder="제조사를 입력해주세요. 제조사가 없는 경우 수산상회 이름을 입력해주세요."
                                        value={manufacturer}
                                        onChange={handleManufacturerChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>인증정보</th>  
                            <td className="radioBtn-wrap">
                                <label>
                                    <input
                                        type="radio"
                                        value="인증/신고 대상"
                                        checked={certificationTarget === '인증/신고 대상'}
                                        onChange={handleCertificationTargetChange}
                                    />
                                    인증/신고 대상
                                </label>

                                {/* <label>
                                    <input
                                        type="radio"
                                        value="상세페이지 별도표시"
                                        checked={certificationTarget === '상세페이지 별도표시'}
                                        onChange={handleCertificationTargetChange}
                                    />
                                    상세페이지 별도 표기
                                </label> */}

                                <label>
                                    <input
                                        type="radio"
                                        value="인증/신고 대상 아님"
                                        checked={certificationTarget === '인증/신고 대상 아님'}
                                        onChange={handleCertificationTargetChange}
                                    />
                                    인증/신고 대상 아님
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>미성년자 구매</th>  
                            <td className='radioBtn-wrap'>
                                <label>
                                    <input
                                    type="radio"
                                    value="true"
                                    checked={isMinor === 'true'} 
                                    onChange={handleIsminorChange}
                                    />
                                    구매 가능
                                </label>
                                <label>
                                    <input
                                    type="radio"
                                    value="false"
                                    checked={isMinor === 'false'}
                                    onChange={handleIsminorChange}
                                    />
                                    구매 불가능
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>인당 최대 구매수량</th>  
                            <td className='radioBtn-wrap'>
                                <label>
                                    <input
                                        type="radio"
                                        value="yes"
                                        checked={maxQuantity !== null}
                                        onChange={handleMaxQuantityRadioChange}
                                    />
                                    인당 최대 구매 수량 설정함
                                </label>
                                <input
                                    type="text"
                                    className='input-sm'
                                    value={addCommasToNumberString(maxQuantity)}
                                    onChange={maxQuantity !== null ? handleMaxQuantityInputChange : undefined}
                                    disabled={maxQuantity === null}
                                    />
                                    개
                                <label>
                                    <input
                                        type="radio"
                                        value="no"
                                        checked={maxQuantity === null}
                                        onChange={handleMaxQuantityRadioChange}
                                    />
                                    인당 최대 구매 수량 설정 안함
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>판매기간</th>  
    
                            <td className='radioBtn-wrap'>
                                <label>
                                    <input
                                        type="radio"
                                        value="yes"
                                        checked={salesPeriod !== null}
                                        onChange={handleSalesPeriodRadioChange}
                                    />
                                    설정함
                                </label>
                                <input
                                    type="datetime-local"
                                    className='input-sm'
                                    value={salesPeriod}
                                    onChange={salesPeriod !== null ? handleSalesPeriodInputChange : undefined}
                                    disabled={salesPeriod === null}
                                />
                                부터
                                <input
                                    type="datetime-local"
                                    className='input-sm'
                                    value={salesPeriodEnd}
                                    onChange={salesPeriod !== null ? handleSalesPeriodEndInputChange : undefined}
                                    disabled={salesPeriod === null}
                                />
                                까지
                                <label>
                                    <input
                                    type="radio"
                                    value="no"
                                    checked={salesPeriod === null}
                                    onChange={handleSalesPeriodRadioChange}
                                    />
                                    설정 안함
                                </label>
                            </td>

                 
                        </tr>
                        <tr>
                            <th>부가세</th>  
                            <td className='radioBtn-wrap'>
                                <label>
                                    <input 
                                    type="radio" 
                                    value="과세" 
                                    checked={taxType === '과세'}
                                    onChange={handleTaxChange}
                                    />
                                    과세
                                </label>

                                <label>
                                    <input 
                                    type="radio" 
                                    value="면세" 
                                    checked={taxType === '면세'}
                                    onChange={handleTaxChange}
                                    />
                                    면세
                                </label>

                                <label>
                                    <input 
                                    type="radio" 
                                    value="간이과세" 
                                    checked={taxType === '간이과세'}
                                    onChange={handleTaxChange}
                                    />
                                    간이과세
                                </label>
                            </td>
                        </tr>
                    </tbody>

                </table>     
            </div>

        </section>
        </>
    )
}

export default ProductDetails