/***************************************************************************************
* FileName      : MOrderDetailRow.js
* Description   : 고객 > 마이페이지 > 주문배송조회 > 상세 내역 > MOrderDetailRow 컴포넌트 (모바일)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.12
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Container, Form } from 'react-bootstrap';

import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';
import { OrderPurchaseConfirm } from '../../../api/services/mypageApi.js';
import { useMutation } from 'react-query';

function MOrderDetailRow({orderItem , deliveryItem})
{

    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const navigate = useNavigate();

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        const total = orderItem.orderitem_option_rows.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(total);
    }, [orderItem]);
    
    console.log("--orderItem")
    console.log(orderItem);
    
    const handleClick = (status , orderId ,cancelStatus) => {
        if(status==="반품" && orderId ){
            navigate(`/mypage/order/${orderId}/return`);
        }
        if(status==="부분취소" && orderId && cancelStatus === 0){
            navigate(`/mypage/order/${orderId}/itemcancel/0`);
        }
        if(status==="부분취소" && orderId && cancelStatus === 1){
            navigate(`/mypage/order/${orderId}/itemcancel/1`);
        }  
    };
    
    // 구매확정
    const createMutaion = useMutation(OrderPurchaseConfirm , {
        onSuccess : (data) => {
            alert("구매확정이 완료 되었습니다");
        },
        onError : (data) => {
            console.log("error", data);
        }
    });


    // 구매확정 버튼 클릭시
    const handlePurchaseConfirmClick = (orderId) => {
        const userConfirmed = window.confirm('상품을 받으셨나요? 구매확정하신 뒤에는 반품/교환 신청하실 수 없습니다.');

            if (userConfirmed) {
            const data = {
                order_item: orderId || "",
            };
    
            createMutaion.mutate(data);
            } 

    }

    return (
        <>
        
        <div className='m-order-wrap'>   
            <p className='m-orderStatus'>
            {
                orderItem?.cancels.status ? "취소요청" 
                :
                    orderItem?.confirmation_status === "취소" ||  orderItem?.confirmation_status === "반품"
                    ?
                        orderItem?.confirmation_status
                    :
                        orderItem?.status === "배송완료" 
                        ? orderItem?.confirmation_status
                        : orderItem?.status
            }
        
            </p>
            <div className='m-prod-wrap'>  
                <div className='m-prod-thum' >
                    <img
                        src={orderItem.main_image}
                        width="100%"
                        className="border mb-2"
                        alt="상품 이미지"
                    /> 
                </div>
                <div className='m-prod-info'>
                    <span className='vendortxt'>[{orderItem.vendor_name}] </span>
                    <span className='pdtxt'>{orderItem.product_name}</span>
            
                    {/* 옵션 */}
                    {orderItem.orderitem_option_rows && orderItem.orderitem_option_rows.length > 0 && (
                    <>
                        <span className='option_list2'>
                            {/* <i className='m-optiontxt'>옵션/수량 </i> */}
                            <div>
                                {orderItem.orderitem_option_rows.map((option, index) => (
                                    <>
                                        <p key={index}>
                                            {option.name} / {option.quantity}개
                                        </p>
                                        <p className='m-o-price'>
                                            {option.total_price.toLocaleString()}원
                                        </p>
                                    </>
                                ))}
                            </div>
                        </span>
                    </>
                    )}

                    {/* 추가상품 */}
                    {orderItem.orderitem_extra_options && orderItem.orderitem_extra_options.length > 0 && (
                    <>
                        <span className='option_list2'> 
                            <i className='m-optiontxt'>추가상품</i>
                            <div>
                            {orderItem.orderitem_extra_options.map((extra_option, index) => (
                                <p key={index}>
                                    {extra_option.name} <br/>
                                    {extra_option.total_price.toLocaleString()}원 / {extra_option.quantity}개
                                </p>
                            ))}
                            </div>
                        </span>
                    </>
                    )}


                </div>
   
        </div>

         
        <div className='m-btn-box'>
            {orderItem?.is_trackable &&
                <button className='mpbtn1 mt-2' onClick={()=>setModalIsOpen(true)}>배송조회</button>
            }

            {orderItem?.is_direct_delivered  && 
                <button className='mpbtn1 mt-2'>직접전달</button>
            }
            {orderItem.is_returnable &&
                <button className='m-mpbtn1 mt-1' 
                    onClick={()=>{handleClick("반품" , orderItem.id)}}>반품신청</button>
            }

            {orderItem?.confirmation_status === "배송완료" && 
                <button className='mpbtn1 mt-1' 
                    onClick={()=>{handlePurchaseConfirmClick(orderItem.id)}}>구매확정</button>
            }
                    
        
            {/* 상품준비중일때 부분취소 */}
            {deliveryItem.cancel_request_available && (
                <button
                    className='mpbtn1 mt-2'
                    onClick={() => { handleClick("부분취소", deliveryItem.id ,1) }}>
                    부분취소
                </button>
            )}

            {/* 결제완료일때 부분취소 */}
            {deliveryItem.is_cancelable && (
                <button
                    className='mpbtn1 mt-2'
                    onClick={() => { handleClick("부분취소", deliveryItem.id ,0) }}>
                    부분취소
                </button>
            )}
        </div>
        {/* <td className='lbe6' >
            {totalQuantity}
        </td>
        <td className='lbe6' >
            {orderItem && orderItem.original_total_price ? 
                orderItem?.original_total_price.toLocaleString(): "0"}원
        </td>
        <td className='status_td lbe6'>
            <p>{orderItem?.confirmation_status}</p>
            <button className='mpbtn1 mt-1'>배송조회</button>
      
        </td> */}
        </div>
        </>
    )
}

export default MOrderDetailRow