/***************************************************************************************
* FileName      : OrderRow.js
* Description   : 고객 > 마이페이지 > 반품내역조회 > OrderRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.15
* Modifide Date : 2024.01.22 : CHM : 배송조회 모달 추가
*               : 2024.01.29 : CHM : 배송조회 모달 API 연동
* Reference     : 
***************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { extractYearMonthDayHourMinute2  } from '../Component/extractDateUtils.js';
import DeliveryModal from '../Component/DeliveryModal.js';


function OrderRow({orderItem}){
    // 주문일자 추출
    // const[orderDate , setOrderDate] = useState(orderItem.paid_at);
    const[orderDate , setOrderDate] = useState(orderItem.paid_at);


    const navigate = useNavigate();

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        const total = orderItem.orderitem_option_rows.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(total);
    }, [orderItem]);
    
    const handleOrderNumberClick = (id) => {
        navigate(`/mypage/return/${id}`);
    };

    console.log(orderItem);
    console.log("----")

    // 2024.01.22 : CHM : 배송조회 모달 추가
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
        <tr key={orderItem.id}>
            <td>
                <p>{extractYearMonthDayHourMinute2(orderDate)}</p>
                <p className='ordernum mpd' 
                    onClick={() => handleOrderNumberClick(orderItem.id)}>
                    {orderItem.order_number}                         
                </p>
            </td>
        
            <td className='lbe6'>
                <div className='myorder_piwrap cursor-pointer'          
                    onClick={()=>window.location.href=`/product/detail/${orderItem.product}`}>
                    <span className='thum' href=''>
                        <img
                            src={orderItem.main_image}
                            width="100%"
                            className="border mb-2"
                            alt="상품 이미지"
                        /> 
                    </span>
                    <div className='pn'>
                        <a>
                            <span className='vendortxt'>{orderItem.vendor_name}</span>
                            <span className='pdtxt'>{orderItem.product_name}</span>
                
                            {/* 옵션 */}
                            {orderItem.orderitem_option_rows && orderItem.orderitem_option_rows.length > 0 && (
                            <>
                                <span className='option_list2'>
                                    <i className='optiontxt'>옵션</i>
                                    <div>
                                        {orderItem.orderitem_option_rows.map((option, index) => (
                                            <p key={index}>
                                                {option.name}
                                            </p>
                                        ))}
                                    </div>
                                </span>
                            </>
                            )}

                            {/* 추가상품 */}
                            {orderItem.orderitem_extra_options && orderItem.orderitem_extra_options.length > 0 && (
                            <>
                                <span className='option_list2'> 
                                    <i className='optiontxt'>추가상품</i>
                                    <div>
                                    {orderItem.orderitem_extra_options.map((extra_option, index) => (
                                        <p key={index}>
                                            {extra_option.name}
                                        </p>
                                    ))}
                                    </div>
                                </span>
                            </>
                            )}
                        </a>
                    </div>
            
                </div>

            </td>
            <td className='lbe6' >
                {totalQuantity}
            </td>
            <td className='lbe6' >
                {orderItem && orderItem.original_total_price ? 
                    orderItem?.original_total_price.toLocaleString(): "0"}원
            </td>
            <td className='status_td lbe6'>
                <p>{orderItem?.confirmation_status}</p>
                <button className='mpbtn1 mt-2' onClick={()=>setModalIsOpen(true)}>배송조회</button>
            </td>
        </tr>
        {/* 2024.01.22 : CHM : 배송조회 모달 추가 */}
        <DeliveryModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} id={orderItem?.id}/>
        </>
    )
}

export default OrderRow