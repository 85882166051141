import React, { useEffect, useState } from 'react';
import { useSubCategories } from '../../../../../api/Category'
import { useRegisterSeasonalProductCategory , DeleteSeasonalProductCategory } from '../../../../../api/marketingApi';

function SubCategories({ categoryId , middleIds }) 
{
    const { isLoading, isError, data: subCategoriesData } = useSubCategories(categoryId);

    const { post, isPostLoading, error } = useRegisterSeasonalProductCategory();

  
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (isError) {
      return <div>Error occurred while fetching main categories.</div>;
    }


    const handleSubCategoryClick = async (CategoryId , isChecked) => {

        if(!isChecked){
            //삭제
           
            if (CategoryId) {
              // 삭제 로직 실행
              DeleteSeasonalProductCategory(CategoryId)
              .then(() => {
                alert('제철 상품이 삭제되었습니다.');
              })        
            }
            
        }else{
            // 등록
            const data = {
              category: CategoryId || ""
            }

            post(data)
            .then(() => {
                alert("제철 상품이 등록되었습니다.");
            })
            .catch(error => {
                console.log("등록 실패:", error.message);
            });
        }

      
    };

    return (
      <>
        <div className='categbox'>
          <p className='ctit'>중분류</p>
          <div type="중분류" className='kwutOX'>
              <ul>
                {subCategoriesData.data ? subCategoriesData.data.map((category) => (
                    <li className='fBSFej mb-2' key={category.id}>
                        <label>
                            <input
                              type="checkbox"
                              className='categoryCheckbox'
                              checked={middleIds.includes(category.id)}
                              // checked={selectedCategories.includes(category.id)}
                              onChange={(e) => handleSubCategoryClick(category.id , e.target.checked)}
                            />
                            {category.name}
                        </label>
                    </li>
                  )): []}
              </ul>
          </div>
        </div>
    
      </>
    );
}

export default SubCategories;