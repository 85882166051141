/***************************************************************************************
* FileName      : success.js
* Description   : 고객 > 주문 > 결제완료페이지
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.05
* Modifide Date : 2024.01.05 : CHM : 실제 결제 금액과 다르게 표시되는 문제 수정
*               : 2024.01.05 : CHM : 가상계좌 주문 시 입금안내 추가
*               : 2024.01.10 : CHM : 페이지 컨셉 수정
*               : 2024.01.11 : CHM : 마일리지 적립 안내 툴팁 추가
* Reference     : 
***************************************************************************************/
import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Overlay, Tooltip } from 'react-bootstrap';
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from 'lottie-react';
import animationData from './lottie/animation_loading.json';
import { TbAlertOctagonFilled } from 'react-icons/tb';
import { setOrderPayment } from '../../api/orderPaymentsApi';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

export default function SuccessPage() {
  const [requestState, setRequestState] = useState('loading');

  const [orderData, setOrderData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  // 서버로 승인 요청

  return (
    <>
      { requestState === 'loading' ? <OnLoading setState={setRequestState} setOrderData={setOrderData} setErrorMessage={setErrorMessage}/> : requestState === 'success' ? <Success2 orderData={orderData}/> : <Fail errorMessage={errorMessage}/> }
    </>
  )
}

function OnLoading({setState, setOrderData, setErrorMessage}){
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const paymentKey = searchParams.get('paymentKey');
  const amount = searchParams.get('amount');
  
  setOrderPayment(orderId, paymentKey, amount)
    .then(res => {
      setOrderData(res?.data)
      setState('success');
    })
    .catch(error => {
      console.log(error?.response?.data?.data);
      setErrorMessage(error?.response?.data?.data);
      setState('fail');
    });

  return(
    <>
      <Container className='py-4 px-1 text-center'>
        <h1>상품의 결제정보를 확인하고있습니다.</h1>
        <p>잠시만 기다려주세요</p>
        <Row>
          <Col xs={3}/>
          <Col xs={6}>
            <Lottie 
              animationData={animationData}
            />
          </Col>
          <Col xs={3}/>
        </Row>
      </Container>
    </>
  )
}

function Fail({errorMessage}){
  const navigate = useNavigate();

  return(
    <Container className="px-3 py-5">
        <Row className='m-0 p-3 rounded-2 bg-white shadow-box text-center'>
          <h1 className='fs-6'>결제 실패</h1>
          <TbAlertOctagonFilled className="fs-1 my-3 text-hw"/>
          <p className="fs-7 fw-bold">결제가 실패되었습니다.</p>

          <hr className="my-3"/>
          <p className="fs-7 text-grey fw-bolder">확인 후 다시 시도하거나 고객센터로 연락주세요.</p>

          <p className="mt-1 fs-7">{`사유: ${errorMessage}`}</p>

          <button type='button' className='mt-5 p-2 bg-hw text-white border-0 rounded-2' onClick={()=>navigate(-1)}>
            결제 페이지로 돌아가기
          </button>
        </Row>
      </Container>
  )
}

function Success2({orderData}){
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [orderAmount, setOrderAmount] = useState(0); //주문금액
  const [couponDiscountAmount, setCouponDiscountAmount] = useState(0); //쿠폰할인금액
  const [couponCounter, setCouponCounter] = useState(0); //쿠폰할인금액
  const [rewardMileage, setRewardMileage] = useState(0); //적립예정마일리지

  useEffect(()=>{
    console.log("orderData 로깅...", orderData);
  })

  useEffect(()=>{
    let totalPrice = 0; // 주문금액
    let deliveryCouponPrice = 0; //배송비쿠폰할인금액
    let deliveryCouponCounter = 0; //사용한 배송비 쿠폰 수
    let itemCouponPrice = 0; //상품할인쿠폰사용금액
    let itemCouponCounter = 0; //사용한 상품 쿠폰 수
    let itemRewardMileage = 0; //상품당 적립예정 마일리지

    orderData?.delivery_items.map(di => {
      totalPrice += di?.original_total_price;
      if(di?.type === '선불'){
        totalPrice += di?.original_delivery_fee;
      }
      // 배송비 쿠폰 확인
      if(di?.coupon_issue){
        deliveryCouponPrice += di?.discount_price;
        deliveryCouponCounter += 1;
      }
      di?.order_items.map(oi => {
        // 상품쿠폰 확인
        if(oi?.coupon_issue){
          itemCouponPrice += oi?.discount_price;
          itemCouponCounter += 1;
        }
        itemRewardMileage += oi?.reward_mileage;
      })
    })
    setOrderAmount(totalPrice);
    setCouponDiscountAmount(deliveryCouponPrice + itemCouponPrice);
    setCouponCounter(deliveryCouponCounter + itemCouponCounter);
    setRewardMileage(itemRewardMileage);
  }, [])

  return(
    <Container className='py-4 px-1 text-center  w-75'>
      {/* 주문정보(주문일시, 주문명, 주문번호) */}
      <Row className='my-1'>
        <h1 className='text-hw fw-bold mb-3'>주문완료</h1>
        <p className='fs-6'>
          <b>{orderData?.name}<br/></b>
          <span className='text-grey fw-bold'>({searchParams.get('orderId')})</span>의 주문이 접수되었습니다.
        </p>
      </Row>

      {/* 가상계좌 정보 - pay_method가 가상계좌인 경우만 출력 */}
      {
        orderData?.pay_method === '가상계좌' && (
          <Row className='border-hw p-4 mt-4 mx-1'>
            <VirtualAccount 
              bank={orderData?.virtual_account?.bank}
              accountNumber={orderData?.virtual_account?.account_number}
              accountHolder={orderData?.virtual_account?.account_holder}
              amount={searchParams.get('amount')}
            />
          </Row>
        )
      }
      
      {/* 결제정보 */}
      <Row className='border p-3 mt-4 mx-1'>
        <OrderAmountBlock 
          amount={searchParams.get('amount')}
          orderAmount={orderAmount} 
          couponDiscountAmount={couponDiscountAmount}
          couponCounter={couponCounter}
          usedMileage={orderData?.mileage}
          rewardMileage={rewardMileage}
        />
      </Row>

      <Row>
        <Col>
          <button type='button' className='mt-5 p-2 bg-hw text-white border-0 w-100' onClick={()=>navigate('/mypage/order/')}>
            주문 상세
          </button>
        </Col>
        <Col>
          <button type='button' className='mt-5 p-2 bg-hw text-white border-0 w-100' onClick={()=>navigate('/')}>
            메인화면으로 돌아가기
          </button>
        </Col>
      </Row>
      
    </Container>
  )
}

function OrderAmountBlock({amount, orderAmount, couponDiscountAmount, couponCounter, usedMileage, rewardMileage}){
  const [showTooltip, setShowTooltip] = useState(false);
  const triggerRef = useRef(null);
  const hoverTooltip = () => setShowTooltip(true);
  const outTooltip = () => setShowTooltip(false);
  return(
    <Row>
      <Col xs={12} className="d-flex justify-content-between p-1">
          <p className="fs-7 text-grey fw-bold">주문금액</p>
          <p className="fs-7 fw-bold">{orderAmount.toLocaleString()}원</p>
        </Col>
        <Col xs={12} className="d-flex justify-content-between p-1">
          <p className="fs-7 text-grey fw-bold">쿠폰할인({couponCounter}건)</p>
          <p className="fs-7 fw-bold">- {couponDiscountAmount.toLocaleString()}원</p>
        </Col>
        <Col xs={12} className="d-flex justify-content-between p-1">
          <p className="fs-7 text-grey fw-bold">마일리지 사용</p>
          <p className="fs-7 fw-bold">- {usedMileage.toLocaleString()}원</p>
        </Col>
        <hr className='my-2 mx-0 dashed'/>
        <Col xs={12} className="d-flex justify-content-between p-1">
          <p className="fs-6 text-grey fw-bold">결제금액</p>
          <p className="fs-5 text-hw fw-bold">{parseInt(amount).toLocaleString()}원</p>
        </Col>
        <Col xs={12} className="d-flex justify-content-between p-1">
          <p className="fs-7 text-grey">┗ 적립예정 마일리지 <span ref={triggerRef} onMouseOver={hoverTooltip} onMouseOut={outTooltip}><BsFillQuestionCircleFill className='fs-6'/></span> : {rewardMileage.toLocaleString()}마일리지</p>
          <Overlay
            show={showTooltip}
            placement="right"
            target={triggerRef.current}
          >
            {/* 우측툴팁 */}
            <Tooltip id="tooltip" className='custom-tooltip tooltip-right'>
              적립예정 금액은<br/>
              (상품가격 - 할인금액) x 적립률<br/>
              로 계산됩니다.
            </Tooltip>
          </Overlay>
        </Col>
    </Row>
  )
}

function VirtualAccount({bank, accountNumber, accountHolder, amount}){
  return(
    <>
      <p className="text-grey mb-3">아래 가상계좌로 7일 이내 입금되어야 결제가 완료됩니다.</p>
      <p className="text-grey">{bank} : <span className="text-hw fw-bold">{accountNumber}</span> ({accountHolder})</p>
      <p className="fs-5 fw-bold text-hw">{parseInt(amount).toLocaleString()}원</p>
    </>
  )
}