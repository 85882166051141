/***************************************************************************************
* FileName      : CouponListRow.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 쿠폰리스트 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils"
export default function CouponListRow({coupons}){
    return(
        <tr>
            <td><input type="checkbox" name="" value="" /></td>
            <td>{coupons?.name}</td>
            <td>{coupons?.id}</td>
            <td>{coupons?.type}</td>
            <td>
                {coupons?.use_condition.toLocaleString()}원 이상 구매시 <br/>
                {coupons?.discount_type === "할인금액형" ? 
                <>{coupons?.discount_amount.toLocaleString()}원 할인</>
                :<>{coupons?.discount_rate}% 할인 (최대 {coupons?.limit_discount_amount.toLocaleString()}원)</>
                }
            </td>
            <td>최초발행일</td>
            <td>    
                {coupons?.issue_period_start && coupons?.issue_period_end ? 
                <>
                  {extractYearMonthDayHourMinute2(coupons?.issue_period_start)} 
                ~ {extractYearMonthDayHourMinute2(coupons?.issue_period_end)}
                </> 
                :
                <>제한없음</>
                }
            </td>
            <td>
                {coupons?.expiration_type === "만료일선택형" ? 
                    <>
                    발급 후{coupons?.expiration_date}일
                    </>
                    :
                    <>
                     {extractYearMonthDayHourMinute2(coupons?.validity_period_start)} 
                    ~ {extractYearMonthDayHourMinute2(coupons?.validity_period_end)}
                    </>
                } 
            </td>
            <td>{coupons?.vendor_name ? coupons?.vendor_name  : <>제한없음</>}</td>
            <td>
                {coupons?.limit_count ? 
                <>{coupons?.issue_count}</>
                :<>제한없음</>}
            </td>
            <td>{coupons?.issued_count}</td>
            <td>{coupons?.use_issued_count}</td>
            <td>{coupons?.issue_count}</td>
            <td>{coupons?.code}</td>
        </tr>
    )
}

