import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';
import { getMe } from '../../api/useLogin.js';
import { useRefreshProtect } from '../../hooks/useProtect.js';
import { updatePassword } from '../../api/services/mypageApi.js';


function MyInfoLayout(){
    useRefreshProtect();
    
    return(
        <>
            <Container>
              <StatusBar/>
              <Row className='m-0'>
                <div className='mypage_wrap'>
                  <Col md={3} lg={3}><Sidemenu nowPage={'info'}/></Col>
                  <Col xs={12} sm={12} md={9} lg={9}>
                      <MyInfo />
                  </Col>
                </div>
              </Row>
            </Container>
        </>
      )
}

function MyInfo(){
    const[userId, setUserId] = useState('');
    const[nowPassword, setNowPassword] = useState('');
    const[newPassword, setNewPassword] = useState('');
    const[confirmPassword, setConfirmPassword] = useState('');
    const [comfirmError, setConfimrError] = useState(false);
    useEffect(()=>{
        getMe()
            .then(res => setUserId(res?.data?.username))
    }, [])
    
    useEffect(()=>{
        confirmPassword !== '' && newPassword !== confirmPassword ? setConfimrError(true) : setConfimrError(false);
    },[confirmPassword, newPassword])

    function changePassword(){
        if(newPassword !== confirmPassword){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }else{
            updatePassword({
                password: nowPassword,
                new_password: newPassword,
                new_password_confirm: confirmPassword
            })
            .then(res => {
                alert(res?.data?.data);
                window.location.reload();
            })
            .catch(err => {
                alert(err?.response?.data?.data || err?.response?.data?.password || err?.response?.data?.new_password || err?.response?.data?.new_password_confirm);
            })
        }
    }
    return(
        <>
            <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                <>
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0'>
                            <h3 class="m-0 p-0 fs-5 fw-bold">비밀번호 변경</h3>
                            <p className='m-0 p-0 fs-7 fw-bolder text-grey'>회원님의 정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 확인해주세요</p>
                        </Col>
                    </Row>
                    <Row className='m-0 p-0 mt-3'>
                        <div className='border p-3'>
                            <Row>
                                <Col xs={12} className='text-center fs-7 pt-3 pb-2'><span className='fw-bold text-hw'>{userId} </span> 회원님의 비밀번호를 변경합니다.</Col>
                            </Row>
                            <Row className='my-3'>
                                <Col xs={1} sm={3}></Col>
                                <Col xs={10} sm={6}>
                                    <p className='fw-bold text-grey'>현재 비밀번호</p>
                                    <input className='w-100 text-center' type="password" placeholder="비밀번호를 입력해주세요" value={nowPassword} onChange={(e) => setNowPassword(e.target.value)}/>
                                </Col>
                                <Col xs={1} sm={3}></Col>
                            </Row>
                            <Row className='my-3'>
                                <Col xs={1} sm={3}></Col>
                                <Col xs={10} sm={6}>
                                    <p className='fw-bold text-grey'>새로운 비밀번호</p>
                                    <input className='w-100 text-center' type="password" placeholder="비밀번호를 입력해주세요" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                                </Col>
                                <Col xs={1} sm={3}></Col>
                            </Row>
                            <Row className='my-3'>
                                <Col xs={1} sm={3}></Col>
                                <Col xs={10} sm={6}>
                                    <p className='fw-bold text-grey'>
                                        비밀번호 확인 
                                        {confirmPassword !== '' && comfirmError && <span className='text-danger fs-8 ps-2'>* 비밀번호가 다릅니다.</span>}
                                        {confirmPassword !== '' && !comfirmError && <span className='text-success fs-8 ps-2'>* 비밀번호가 일치합니다.</span>}
                                    </p>
                                    <input className='w-100 text-center' type="password" placeholder="비밀번호를 입력해주세요" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                                </Col>
                                <Col xs={1} sm={3}></Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={3}></Col>
                                <Col xs={6} className="text-center">
                                    <button className="login_btn btn btn-primary btn-md btn-block w-100 mb-3" type="submit" onClick={()=>changePassword()}>
                                        완료
                                    </button>
                                </Col>
                                <Col xs={3}></Col>
                            </Row>
                        </div>
                    </Row>
                </>
            </div>
        </>
    )
}

export default MyInfoLayout