/***************************************************************************************
* FileName      : Send.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발생내역 관리 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import PageSizeSelect from "../../common/PageSizeSelect"
import { useNotificationTalkHistoryQuery , handleNotificationTalkSearch } from '../../../../api/hwadmin/notificationApi';
import { TalkRow } from './Send/TalkRow';
import Pagination from '../../common/Pagination';
import SearchPagination from '../../common/SearchPagination';

function Send()
{

    const [pageSize, setPageSize] = useState(50); 
    const { data, error, isFetching, page, setPage } = useNotificationTalkHistoryQuery(pageSize);

    const [searchTalk, setSearchTalk] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);

    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');
    
    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    //요청구분
    const [requestType, setRequestType] = useState('');


    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
    }


    const onSearch = () => {
        handleNotificationTalkSearch({
            searchTalk, 
            setSearchResults,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            requestType,
            setSearchUrl
        });

        setSearchConfirm(true);
    };

    // 요청구분 선택
    const handleRequestTypeChange = (e) => {
        setRequestType(e.target.value);
    };


    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/alarm/alimTalkSendingHistory'; 
    };

    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    console.log(data);
    console.log("-데이터-")
    return (
        <>
        {/* 검색 */}
        {/* <section className='McontentBox m-p0'>
            <div className='search-wrap'>
                <div className='search-field word'>
                    <div> 
                        <b>검색어</b> 
                    </div>
                    <div>
                        <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                            <option value="all" selected="selected">전체</option>
                            <option value="content">내용</option>
                            <option value="id">회원ID</option>
                            <option value="id">회원ID</option>
                        </select>
                        <input type="text"
                                value={searchTalk}
                                onChange={(e) => setSearchTalk(e.target.value)}/> 
                    </div>   
                </div>
                <div className='search-field date'>
                    <div> 
                        <b>날짜</b> 
                    </div>
                    <div>
                        <select value={selectedDateType} onChange={handleDateTypeChange}>
                            <option value="apply">신청일</option>
                        </select>
                        <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                        <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />    
                    </div>   
                </div>
                <div className='search-field division'>
                    <div> 
                        <b>전송결과</b> 
                    </div>
                    <div className='SearchRadio'>
                        <input
                            type='radio'
                            id='requestTypeAll'
                            name='requestType'
                            value=''
                            checked={requestType  === ''}
                            onChange={handleRequestTypeChange}
                            />
                        <label htmlFor='requestTypeAll'>전체</label>
                        
                        <input
                            type='radio'
                            id='requestTypeCompleted'
                            name='requestType'
                            value='완료'
                            checked={requestType === '완료'}
                            onChange={handleRequestTypeChange}
                            />
                        <label htmlFor='requestTypeCompleted'>완료</label>

                        <input
                            type='radio'
                            id='requestTypeFailed'
                            name='requestType'
                            value='실패'
                            checked={requestType === '실패'}
                            onChange={handleRequestTypeChange}
                        />
                        <label htmlFor='requestTypeFailed'>실패</label>

                        <input
                            type='radio'
                            id='requestTypeInProgress'
                            name='requestType'
                            value='전송중'
                            checked={requestType === '전송중'}
                            onChange={handleRequestTypeChange}
                        />
                        <label htmlFor='requestTypeInProgress'>전송중</label>
                    </div>
                </div>             
            </div>
           
            <div className='m-btn-wrap'>
                <button className='m-btn' onClick={()=>{handleResetClick()}}>초기화</button>
                <button className='m-btn m-btn-search' onClick={()=>{onSearch()}}>검색</button>
            </div>
        </section> */}

        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className='resultsCount-wrap'>
                    <span>검색결과</span>
                    <span className='result-wrap'>
                        {searchResults.totalCnt > 0 ? (
                            <>
                                <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                            </>
                            ) : (
                            <>
                                <p>(총 <b className='sum-s-b'>{data?.totalCount ? data?.totalCount : 0}</b>개)</p>
                            </>
                        )}
                    </span>
                </div>

                { !searchConfirm && 
                    <div className='selectWrap'>
                        <select className='mSelectBox' value={pageSize} onChange={handlePageSizeChange}>
                            <option value="50">50개씩</option>
                            <option value="100">100개씩</option>
                        </select>
                    </div>
                }

            </div>
           

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>

                    </colgroup>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>상태</th>
                            <th>내용</th>
                            <th>전송요청수</th>
                            <th>등록일시</th>
                            <th>예약일시</th>
                        </tr>
                    </thead>
                    <tbody>
                    {searchConfirm && searchResults?.count === 0 ? (
                        <tr>
                            <td colSpan="17">검색 결과가 없습니다.</td>
                        </tr>
                        ) : searchResults?.count > 0 ? (
                            searchResults?.data.map((talk) => (
                                <TalkRow
                                    key={talk?.id}
                                    talk={talk}
                                />
                            ))
                        ) : data && data?.list && data?.list.length > 0 ? (
                            data?.list.map((talk) => (
                                <TalkRow
                                    key={talk?.id}
                                    talk={talk}
                                />
                            ))
                        ) : null}    
                    </tbody>
                </table>
            </div>
        </section>

        {/* 페이지내비게이션 */}
        <section>
            {searchConfirm ? (
                <SearchPagination
                    count={searchResults.totalCnt}
                    url={searchUrl}
                    pageSize={pageSize}
                    updateSearchResults={updateSearchResults}
                />
            ) : (
                <Pagination
                    count={data?.totalCount || 0}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                />
            )}
        </section>
    
    </>
    )
}

export default Send