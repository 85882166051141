import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Pagination from './common/Pagination.js'
import Card from './List/Card.js';

import { getVendorProduct } from '../../api/product/vendors.js';
import { useProductListContent } from '../../api/services/productApi.js';
import { useSearchParams } from 'react-router-dom';


export default function List()
{
    const [searchParams] = useSearchParams();
    const [vendorName, setVendorName] = useState('');
    const [products, setProducts] = useState([]);
    const [productCnt, setProductCnt] = useState(0);


    useEffect(()=>{
        setVendorName(searchParams.get("name"));
        getVendorProduct(searchParams.get("id"))
            .then(res => {
                setProductCnt(res?.data?.totalCnt);
                setProducts(res?.data?.data);
                console.log(res?.data);
            })
    }, [])

    return(
        <>
        <Container className='p-3'>
            <div className='product-headcategory'>
            </div>
            <Row>
                <Col xs={12} md={12}>
                    <div className='page-tit text-center'>
                        <h2>{vendorName}</h2>
                    </div>
                </Col>

                
                <Col xs={12} md={12}>
                    <span className='pick_list_num'>
                        <strong>{productCnt}개</strong> 상품이 있습니다.
                    </span>
                    <div className='product_ListMenu'>
                        <ul className='product-orderby'>
                            {/* 
                            {['신상품', '상품명', '낮은가격', '높은가격'].map((tab) => (
                            <li key={tab} onClick={() => handleTabChange(tab)}>
                                {tab}
                            </li>
                            ))} 
                            */}
                        </ul>
                    </div>
                </Col>
                
                { 
                products?.length === 0 ? (
                    <div className='goods_no_data'>
                        <strong>상품이 존재하지 않습니다</strong>
                    </div>
                    ) : (

                    <div>
                        <ul>
                            <Row className='prdList'>
                            {
                                products.map((item) => (
                                    <>
                                    <Card 
                                        product={item} 
                                        key={item?.id} 
                                    />
                                    {/* <CardFilter product={product} params={searchParams.get("page")}/> */}
                                    </>
                                ))
                            } 
                            </Row>
                        </ul>
                       
                    </div>
                )
                }
            </Row>

        </Container>
        </>
    )
}