import { useMutation } from 'react-query';
import { instance } from '../../axiosInstance/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { setCookie } from '../cookieUtils';


function useLogin() {
  const navigate = useNavigate();

  const mutation = useMutation(
    async ({ username, password }) => {

      const response = await instance.post(`auth/login/`, { username, password });
      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log(data.access);
        console.log("--로그인됨--");
        if (process.env.REACT_APP_ENV_NAME === 'dev') {
          setCookie("access", data.access);
          setCookie("refresh", data.refresh);
        }
        navigate('/admin/main');
        // 토큰 변경사항 적용 후 초기화를 위한 새로고침 적용
        window.location.reload();  
      },
      onError: (error) => {
        alert("로그인에러 : " + error.response.data.data);
      },
    }
  );

  return mutation;
}
export default useLogin;

