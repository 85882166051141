import { useDeliveryListQuery } from '../../../../api/selleradmin/orderAPI';
import OrderRow from './OrderRow';
import Pagination from '../../common/Pagination'
import SearchPagination from '../../../admin/common/SearchPagination';
import SortComponent from '../../../admin/common/SortComponent2';
import PageSizeSelect from '../../common/PageSizeSelect';
import { useState } from 'react';

function DeliveryStatusView({ searchConfirm, searchResults, setSearchResults, selectedStatus})
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10);

    const [searchUrl, setSearchUrl] = useState('');

    const { data, error, isFetching, page, setPage } =  useDeliveryListQuery(ordering,pageSize,selectedStatus);

    if (error) return <div>Error loading </div>;
    if (!data) return <div>Loading ...</div>;

    
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
           console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    console.log(data);

    console.log(selectedStatus)
    return (
        <>
        <section className='McontentBox'>
            <div className='subTitle'>
                <span className='resultsCount-wrap'>
                    <span>검색결과</span>
                    {searchResults.totalCnt > 0 ? (
                        <>
                            <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                        </>
                        ) : (
                        <>
                            <p>(총 <b className='sum-s-b'>{data?.totalCnt && data?.totalCnt}</b>개)</p>
                        </>
                    )}
                </span>
                { !searchConfirm && 
                <div className='selectWrap'>
                    <SortComponent
                        ordering={ordering}
                        handleOrderingChange={handleOrderingChange}
                        newLabel="신규주문순"
                        oldLabel="오래된주문순"
                        />
 
                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                </div>
                }

            </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주문번호</th>
                                <th>결제일시<br/>
                                    출고일시
                                </th>
                                <th>주문상태</th>
                                <th>배송구분</th>
                                <th>상품번호<br/>
                                    상품명
                                </th>
                                <th>옵션정보</th>
                                <th>택배사</th>
                                <th>송장번호</th>
                                <th>수량</th>
                                <th>주문금액</th>
                                <th>구매자 ID<br/>
                                구매자 이름</th>
                                <th>수취인 이름<br/>
                                수취인 연락처</th>
                                <th>주소</th>
                                <th>배송완료일</th>
                                <th>구매확정 예정일</th>
                            </tr>
                        </thead>
                        <tbody>
                             {searchConfirm && searchResults.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ): searchResults.count > 0 ? (
                                searchResults?.data.map((order, index ) => (
                                    <OrderRow key={order.id} order={order} index={index} />
                                ))
                            ) : data && data.data && data.data.length > 0 ? (
                                data.data.map((order, index ) => (
                                    <OrderRow key={order.id} order={order} index={index} />
                                ))
                            ) : null}
                        </tbody>                    
                        </table>
                    </div>
                </section>
                
                {/* 페이지내비게이션 */}
                <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
    
    
    </>
    )
}

export default DeliveryStatusView