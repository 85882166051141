/***************************************************************************************
* FileName      : UnpaidHistory.js
* Description   : 해와관리자 > 정산 > 미지급내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.14
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import SelectVendor from '../product/Component/SelectVendor';
import React, { useState, useEffect } from 'react';
import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';
import { useAdjustUnpaidDocumentQuery , handleAdjustUnpaidDocumentSearch } from '../../../api/hwadmin/adjustApi';
import { AdjustRow } from './UnpaidHistory/AdjustRow';
import Pagination from '../common/Pagination';

function UnpaidHistory()
{
    const [ordering, setOrdering] = useState('-date_joined');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useAdjustUnpaidDocumentQuery(ordering,pageSize);
 

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    const [searchAdjust, setSearchAdjust] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
        

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');
    
    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
        
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
    


    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleAdjustUnpaidDocumentSearch({
            searchAdjust, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedVendor
        });

        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/settle/unpaid'; 
    };


    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>미지급내역</h2>
            </div>
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="order_number">주문번호</option>
                                <option value="product_name">상품명</option>
                                <option value="orderer_username">구매자ID</option>
                                <option value="orderer_name">구매자이름</option>
                                <option value="recipient_name">수취인이름</option>
                                <option value="recipient_phone_number">수취인 연락처</option>
                                <option value="address">주소</option>
                            </select>
                            <input type="text"  
                                value={searchAdjust}
                                onChange={(e) => setSearchAdjust(e.target.value)}/> 
                         
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>구매확정일</b> 
                        </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                    <div className='search-field division'>
                        <div>
                            <b>수산상회</b> 
                        </div>
                        <div>
                            <SelectVendor 
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                />   
                          
                        </div>   
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={()=>handleResetClick()}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={()=>onSearch()}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="최근정산일순"
                                oldLabel="오래된정산일순"
                                />
        
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>주문번호</th>
                                <th>수산상회</th>
                                <th>구매확정일</th>
                                <th>상품명</th>
                                <th>정산대상금액</th>
                                <th>구매자 ID <br/>
                                    구매자 이름
                                </th>
                                <th>수취인 이름 <br/>
                                    수취인 연락처
                                </th>
                                <th>주소</th>
                            </tr>                        
                        </thead>

                        <tbody>
            
                        
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : searchResults?.count > 0 ? (
                            searchResults?.data.map((Adjust , index) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))
                        ) : data && data?.data && data?.data.length > 0 ? (
                            data?.data.map((Adjust , index ) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))
                        ) : null}

                        </tbody>

                    </table>
                </div>
            </section>

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                        <Pagination
                            count={searchResults?.count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>


        </>
    )
}

export default UnpaidHistory