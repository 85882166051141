import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerOnetooneAnswer} from '../../../../api/hwadmin/customerApi';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import CheckAnswerModal from './CheckAnswerModal';
import { extractYearMonthDayHourMinute2 } from '../../../mypage/Component/extractDateUtils';
export const CenterRow  =  ({ center, index, selectedRows, setSelectedRows }) => {

    const { register, handleSubmit, formState: { errors } , reset  } = useForm();

 
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 로딩 상태를 표시하는 컴포넌트
    const LoadingSpinner = () => {
        return (
          <div className="parent-container">
            <div className="spinner" ></div>
          </div>
        );
  };

    const navigate = useNavigate();
    // 답변 작성 모달
    const [isOpen, setIsOpen] = useState(false);

    // 답변 완료 , 수정 모달
    const [isAOpen, setIsAOpen] = useState(false);


    const handleCellClick = (username) => {
      const searchQuery = encodeURIComponent(username);
      const newUrl = `/admin/member/management/?search=${searchQuery}`;
      navigate(newUrl);
    };

    const openModal = () => {
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
    };

    // 답변확인 모달 
    const openAnswerModal = () =>{
      setIsAOpen(true);
    }


  // const closeAnswerModal = () => {
  //   setIsAOpen(false);
  // };


  const createMutaion = useMutation(registerOnetooneAnswer , {
    onSuccess : (data) => {
        reset();
        alert("등록 완료");
        setIsLoading(false);
        setIsOpen(false);
    },
    onError : (data) => {
        console.log("error", data);
        setIsLoading(false);
    }
  });

  const onSubmit = (data) => {
    console.log(data.answer);
    setIsLoading(true);

    const newData = {
        id: center.id,
        answer_title:"test",
        answer: data.answer,
      }

    createMutaion.mutate(newData);
  };


  return(
    <tr
      key={center.id}
      style={{
        backgroundColor: selectedRows[center.id] ? "#f7f7f6" : "transparent"
      }}
    >
      <td className="sticky-col-1">
        <input
            type="checkbox"
            name=""
            value=""
            onClick={() =>
              setSelectedRows({
                ...selectedRows,
                [center.id]: !selectedRows[center.id]
              })
          }
        />
      </td>
      <td className="sticky-col-2">{index + 1}</td>
      <td className="sticky-col-3">{center.type}</td>
      <td className="sticky-col-4">
        {center.title}
      </td>

      <td className='blueLink' onClick={() => handleCellClick(center.user)}>
        {center.user}
      </td>

      <td>
        {extractYearMonthDayHourMinute2(center.created_at)}
      </td>
        
      <td>{center.status}</td>
      <td>  
          {center.answer ? (
            <>
              <button className='t-s-btn bb' onClick={openAnswerModal}>
              답변완료
              </button>

              {/* {center.answer} */}
            </>
            
          ) : (
            <button className='t-s-btn' onClick={openModal}>
            답변작성
            </button>
          )} 


      </td>
      <td>{extractYearMonthDayHourMinute2(center.answered_at)}</td>
      <td>{center.manager}</td>


      {/* 1:1문의 답변작성 모달창 */}
      
      {isOpen && (
       
          <div className="modal ">
            <div className="modal-content CenterModal" >
                <span className="close" onClick={closeModal}>&times;</span>

                <p className='modal-tit' style={{ color : '#5494f5'}}>
                  [{center.type}]
                </p>
                
                <p className='modal-tit'>{center.title}</p>

                <div className='modal-content-box' style={{marginBottom : '30px' }}>
                  {center.content}
                </div>

                <p className='modal-tit'>문의 답변</p>
                <form onSubmit={handleSubmit(onSubmit)}>   
                  <textarea cols="30" rows="10"   
                    {...register('answer', { required: '답변 입력은 필수입니다.' })}  >
    
                  </textarea>

                  <div className='modal-button-wrap'>
                    <button className='m-btn m-btn-search' type="submit">
                            {isLoading ?  <LoadingSpinner /> : '답변완료'} 
                    </button>
                  </div>
                </form>
            </div>
          </div>
   
      )}


      {/* 답변확인 모달 */}

      {isAOpen && (
        <>
          <CheckAnswerModal 
            setIsAOpen ={setIsAOpen} 
            center={center}
          />
        </>
      )}
   
    
  
  </tr>
  )
}
