import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

// 반품 거절 모달
function RejectModal({show, onHide, selectedRows, selectedRowsData, setProductUpdateData, postRejectProduct})
{

    // 선택된 상품을 모두 표시
    const selectedProductIds = Object.keys(selectedRows).filter(key => selectedRows[key] === true);

    return(
        <Modal size={'lg'} show={show} onHide={()=>onHide(false)} centered>
            <Modal.Header>
                <Modal.Title id='sm-modal' className='fs-6'>
                반품 거절
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedProductIds.length > 0
                ? <span className='text-hw fw-bold'>총 {selectedProductIds?.length}건의 등록을 거절합니다.</span>
                : '선택된 항목이 없습니다.'}

                <div className='fixTable_wrap my-2'>
                    <table className='sTable' id='scrollTable'>
                        <thead>
                            <tr>
                                <th>주문번호</th>
                                <th>판매자</th>
                                <th>상품명<br/>상품ID</th>
                                <th>거절사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedProductIds.length > 0 ?
                             selectedProductIds.map(productId => 
                                <ProductRejectTable 
                                    productId={productId} 
                                    setProductUpdateData={setProductUpdateData} 
                                    selectedRowsData={selectedRowsData[productId]}/>) 
                                : <tr>데이터가 없습니다.</tr>
                            }
                            
                        </tbody>
                    </table>
                </div>
                <button className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold" onClick={()=>postRejectProduct()}>확인</button>
            </Modal.Body>
        </Modal>
    )
}


// 거절 모달 내 테이블
function ProductRejectTable({productId, setProductUpdateData, selectedRowsData}){
    const [rejectValue, setRejectValue] = useState();
    useEffect(() => {
        setProductUpdateData(prevData => ({
            ...prevData,
            [productId]:{
                rejectValue: rejectValue
            }
        }))
    },[rejectValue]);
    return(
        <tr>
            <td>
                {selectedRowsData.vendor_name}
            </td>
            <td>
                <img src={selectedRowsData.product_img} width="80px"/>
            </td>
            <td>
                {selectedRowsData.product_name}<br/>{selectedRowsData.product_id}
            </td>
            <td>
                <input 
                    type="text" 
                    value = {rejectValue}
                    placeholder='거절사유를 입력해주세요.' 
                    onChange={(e)=>setRejectValue(e.target.value)}
                    className="w-100 p-1"
                />
            </td>
        </tr>
    )
}


export default RejectModal
