/***************************************************************************************
* FileName      : ProductInquiryList.js
* Description   : 셀러관리자 > 고객관리 > 상품문의 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { useQuery } from 'react-query';
import React, { useState , useEffect } from 'react';
import { useInquiryListQuery , handleProductInquirySearch } from '../../../api/selleradmin/customerApi';
import { ProductInquiryRow } from './ProductInquiryList/ProductInquiryRow';

import Pagination from '../common/Pagination'
import SearchPagination from '../../admin/common/SearchPagination';

import SortComponent from '../../admin/common/SortComponent2';
import PageSizeSelect from '../../admin/common/PageSizeSelect.js';

function ProductInquiryList()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage} = useInquiryListQuery(ordering,pageSize);

    const [searchProduct, setSearchProduct] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);


    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');

    const [selectedRows, setSelectedRows] = useState({});

    // 분류
    const [selectedCategory, setSelectedCategory] = useState('');

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('apply');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');

    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const [toggle , setToggle] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    //답변 상태 
    const [answerStatus, setAnswerStatus] = useState('');
    
    // 검색 > 분류
    const [checkedItems, setCheckedItems] = useState([]);
    const checkBoxList = ['전체선택', '배송문의', '재고문의', '기타문의'];

    const handleAnswerStatusChange = (event) => {
        setAnswerStatus(event.target.value);
    };
    

    // 검색 상태 체크박스
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (id === '전체선택') {
            // "전체선택" 체크박스의 경우, 모든 체크박스를 선택 또는 해제
            if (checked) {
                setCheckedItems(checkBoxList);
            } else {
                setCheckedItems([]);
            }
        } else {
            // 다른 체크박스의 경우, 개별 체크박스 상태 업데이트
            if (checked) {
                setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
            } else {
                setCheckedItems((prevCheckedItems) =>
                    prevCheckedItems.filter((item) => item !== id)
                );
            }
        }
    };

    // 초기화
    const handleResetClick = () => {
        // 초기 데이터로 되돌립니다.
        setSearchConfirm(false)
        window.location.href = '/selleradmin/customerManagement/productInquiryList';
    }

    const openModal = () => {
        console.log("cccc");
        setIsOpen(true);
    }
  
    const closeModal = () => {
      setIsOpen(false);
    }

    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
    
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
            setSelectedStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };


    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };

    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleProductInquirySearch({
            searchProduct, 
            setSearchResults,
            setSearchUrl,
            searchOption,
            selectedDateType,
            selectedStartDate,
            selectedEndDate, 
            selectedCategory, 
            answerStatus,
            ordering,
            checkedItems
        });

        setSearchConfirm(true);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    
    return(
        <>
        <div className='mainContents'> 
            <div className='page-tit'>
                <h2>상품문의</h2>
            </div>
           
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">상품명</option>
                                <option value="id">상품ID</option>
                            </select>
                            <input type="text"
                              value={searchProduct}
                              onChange={(e) => setSearchProduct(e.target.value)}/> 
     
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="apply">작성일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>

                    <div className='search-field checkbox-wrap' >
                        <div> 
                            <b>분류</b> 
                        </div>
                        <div className='CheckboxWrap m'>
                            {checkBoxList.map((item, idx) => (
                                <div className='checkbox' key={idx}>
                                    <input
                                        type='checkbox'
                                        id={item}
                                        checked={checkedItems.includes(item)}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={item}>{item}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='search-field division'>
                        <div>
                            <b>답변상태</b>
                        </div>
                        <div className='SearchRadio'>
                            <input type='radio' id='g1' name='group1' value='' checked={answerStatus === ''} onChange={handleAnswerStatusChange} />
                            <label htmlFor='g1'>전체</label>
                            <input type='radio' id='g2' name='group1' value='답변대기' checked={answerStatus === '답변대기'} onChange={handleAnswerStatusChange} />
                            <label htmlFor='g2'>답변대기</label>
                            <input type='radio' id='g3' name='group1' value='답변완료' checked={answerStatus === '답변완료'} onChange={handleAnswerStatusChange} />
                            <label htmlFor='g3'>답변완료</label>
                        </div>   
                    </div>
      
                </div>
                 <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>
        

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>

                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규등록순"
                                oldLabel="오래된등록순"
                            />
        
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
          
                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                            {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>
                                <th>번호</th>
                                <th>분류</th>
                                <th>
                                    상품번호<br/>
                                    상품명
                                </th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>등록일시</th>
                                <th>답변상태</th>
                                <th>답변내용</th>
                                <th>처리자</th>
                            </tr>
                        </thead>
            
                        <tbody>
                            {searchConfirm && searchResults?.count === 0 ? (
                                    <tr>
                                        <td colSpan="17">검색 결과가 없습니다.</td>
                                    </tr>
                                ) : searchResults?.count > 0 ? (
                                    searchResults.data.map((productInquiry, index) => (
                                        <ProductInquiryRow
                                            productInquiry={productInquiry}
                                            index={index}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                        />
                                    ))
                                ) : data && data.data && data.data.length > 0 ? (
                                    data.data.map((productInquiry, index) => (
                                        <ProductInquiryRow
                                            productInquiry={productInquiry}
                                            index={index}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                        />
                                    
                                    ))
                                ) : null}                     

                   
                        </tbody>


                        </table>
                    </div>
                </section>

                {/* 페이지내비게이션 */}
                <section>
                    {searchConfirm ? (
                        <SearchPagination
                            count={searchResults.totalCnt}
                            url={searchUrl}
                            pageSize={pageSize}
                            updateSearchResults={updateSearchResults}
                        />
                        
                    ) : (
                        <Pagination
                            count={data?.totalCnt || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                        />
                    )}
                </section>
            </div>

        </>
    )
}

export default ProductInquiryList