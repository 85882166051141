/***************************************************************************************
* FileName      : ActivityRecord.js 
* Description   : 해와관리자 > 상품관리 > 활동기록
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import React, { useRef, useEffect , useState } from 'react';
import ProductRegister from './Record/Register.js'
import Category from './Record/Category.js'

function ActivityRecord()
{
    const [activeComponent, setActiveComponent] = useState("ProductRegister");

    const handleButtonClick = (componentName) => {
        setActiveComponent(componentName);
    };

    let [tab , setTab] = useState(0);

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>활동기록</h2>
            </div>
            <div className='setting-tab-buttons'>
                
                <button className={activeComponent === "ProductRegister" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("ProductRegister")}>상품등록/수정</button>

                <button className={activeComponent === "Category" ? "active-button" : "m-sm-btn w130"}
                        onClick={() => handleButtonClick("Category")}>카테고리관리</button>
          
           
            </div>
            {/* 상품등록/수정 */}
            {activeComponent === "ProductRegister" && <ProductRegister />}
            
            {/* 카테고리관리 */}
            {activeComponent === "Category" && <Category />}


        </div>

        </>
    )

}



export default ActivityRecord