// addCommasToNumberString 함수를 수정하여 숫자 값을 정수로 변환하도록 합니다.
export function addCommasToNumberString(inputString) {
    if (inputString === null || inputString === undefined) {
      return ''; // 빈 문자열 또는 다른 기본 값을 반환
    }
 
    // 입력 문자열에서 숫자와 소수점만 남기고 나머지 문자 제거
    const numericString = inputString.replace(/[^0-9.]/g, '');
  
    // 숫자로 변환 (소수점 이하 값은 제거)
    const number = parseFloat(numericString);
  
    // 숫자가 유효한 경우 세 자리마다 쉼표 추가하여 반환
    if (!isNaN(number)) {
      const formattedNumber = number.toLocaleString();
      return formattedNumber;
    }
  
    // 숫자가 유효하지 않은 경우 입력 문자열 그대로 반환
    return inputString;
  }
  