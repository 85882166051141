
import React, { useState } from 'react';
// 검색
import { handleSearch } from '../../../api/services/productApi.js';

export default function SearchBoardList()
{
    const [searchProduct, setSearchProduct] = useState('');

    const [selectedOption, setSelectedOption] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);


    // 셀렉트 박스 변경 핸들러
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [fetchedData, setFetchedData] = useState(null);

     // 검색버튼 클릭 시 
     const onSearch = async () => {
        try{
            const searchResults = await handleSearch({
                searchProduct	
            });

            setSearchResults(searchResults)
            console.log("searchResults?.count",searchResults?.count)
            console.log(searchResults)

        } catch (error) {
			console.error('검색 결과를 가져오는 동안 오류가 발생했습니다:', error);
		}
    };
      


    return (
        <>
        <body id="popup">
            <div className="header">
                <h1>상품 검색</h1>
            </div>

            <div className="popup-search-wrap">
                <div className="popup-search-box">
                    <select onChange={handleSelectChange} value={selectedOption}>
                        <option value="상품명">상품명</option>
                        <option value="???">???</option>
                    </select>

                    <input type="text" 
                            id="keyword"
                            value={searchProduct}
                            onChange={(e) => setSearchProduct(e.target.value)}
                            />
                    
                    <button className='m-btn m-btn-search b' onClick={onSearch}>검색</button>
                </div>
            </div>
            {/* 검색 결과 */}
            {searchResults?.count > 0 ? (
            <ul>
                {searchResults.data.map((result, index) => (
                    <li key={index}>{result.name}</li>
                ))}
            </ul>
            ) : (
                <p>No results found</p>
            )}

        </body>
        </>
    )
}
