/***************************************************************************************
* FileName      : AdjustRow.js
* Description   : 셀러관리자 > 정산 > 세금계산서내역 AdjustRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const AdjustRow = ({ Adjust }) => {
 
  return (
    <>
      <>
        <tr>
          <td>{Adjust.issue_date}</td>
          <td>{Adjust.vendor}</td>
          <td>{Adjust.bussiness_number}</td>
          <td>{Adjust.detail}</td>
          {/* <td>{Adjust.issue_number}</td> */}
          <td>{Adjust.supply_amount}</td>
          <td>{Adjust.tax_amount}</td>
          <td>{Adjust.total_amount}</td>
          <td><img src={Adjust.invoice_image} /></td>
         </tr>
      </>
    </>
  );
};
