/***************************************************************************************
* FileName      : ProductsList.js
* Description   : 셀러관리자 > 상품관리 > 상품목록 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { useProductsQuery , handleSearch } from '../../../api/selleradmin/productApi.js';
import axios from 'axios';

import { useQuery ,  prefetchQuery} from 'react-query';
import React, { useState } from 'react';

import Pagination from '../common/Pagination'
import SearchPagination from '../../admin/common/SearchPagination.js';

import { ProductRow } from "./List/ProductRow.js";

import SortComponent from '../../admin/common/SortComponent2.js';
import PageSizeSelect from '../../admin/common/PageSizeSelect.js';

function ProductsList()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage, toggle, setToggle } = useProductsQuery(ordering,pageSize);

    const [searchProduct, setSearchProduct] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchUrl, setSearchUrl] = useState('');

    const [expandedIds, setExpandedIds] = useState([]);

    // [hm] 선택한 상품 Row(체크박스) : 2023.09.15
    const [selectedRows, setSelectedRows] = useState({});

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('register');

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
 
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');

    // 상태
    const [status, setStatus] = useState('');

    if (error) return <div>Error loading products</div>;
    if (!data) return <div>Loading products...</div>;

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleSearch({
            searchProduct, 
            setSearchResults,
            searchOption,
            selectedStartDate, 
            selectedEndDate,
            setSearchUrl,
            status,
        });

        setSearchConfirm(true);

    };

    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/selleradmin/product/list/';
    };
    
    
    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
      
    //날짜 옵션 선택
    const handleDateTypeChange = (event) => {
        setSelectedDateType(event.target.value);
    };
        
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
        
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    // 검색 상태 선택
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const totalPages = Math.ceil(data?.totalCnt / data?.count) ;
    
    console.log(searchResults?.data)

    const handleExpandClick = (id) => {
      if (expandedIds.includes(id)) {
        setExpandedIds(expandedIds.filter((prevId) => prevId !== id));
      } else {
        setExpandedIds([...expandedIds, id]);
      }
    };
 
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>상품조회</h2>
            </div>
          
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field word'>
                        <div> 
                            <b>검색어</b> 
                        </div>
                        
                        <div>
                            <select className='mSelectBox mr6' value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="name">상품명</option>
                                <option value="id">상품ID</option>
                            </select>

                            <input type="text"
                                    value={searchProduct}
                                    onChange={(e) => setSearchProduct(e.target.value)}/>
                        </div>   
                    </div>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <select value={selectedDateType} onChange={handleDateTypeChange}>
                                <option value="apply">신청일</option>
                            </select>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>

                    <div className='search-field division'>
                        <div> 
                            <b>상태</b> 
                        </div>
                        <div>
                            <input
                                type='radio'
                                id='statusAll'
                                className='mrbtn'
                                name='status'
                                value=''
                                checked={status === ''}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusAll'>전체</label>
                            
                            <input
                                type='radio'
                                id='statusOnSale'
                                className='mrbtn'
                                name='status'
                                value='판매중'
                                checked={status === '판매중'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusOnSale'>판매중</label>

                            <input
                                type='radio'
                                id='statusSoldOut'
                                className='mrbtn'
                                name='status'
                                value='품절'
                                checked={status === '품절'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusSoldOut'>품절</label>

                            <input
                                type='radio'
                                id='statusStopSale'
                                className='mrbtn'
                                name='status'
                                value='판매중지'
                                checked={status === '판매중지'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusStopSale'>판매중지</label>

                            <input
                                type='radio'
                                id='statusEndSale'
                                className='mrbtn'
                                name='status'
                                value='판매종료'
                                checked={status === '판매종료'}
                                onChange={handleStatusChange}
                            />
                            <label htmlFor='statusEndSale'>판매종료</label>
                        </div>   
                    </div>
                    
                
                    <div className={toggle ? 'Detailed-search-show' : 'Detailed-search'}>
                        <div className='search-field range'>
                            <div> 
                                <b>결제금액</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>
                
                        <div className='search-field range'>
                            <div> 
                                <b>주문횟수</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>건</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>건</span>
                            </div>   
                        </div>
                   
                 
                        <div className='search-field range'>
                            <div> 
                                <b>포인트</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>

                        <div className='search-field range'>
                            <div> 
                                <b>포인트</b> 
                            </div>
                            <div>
                                <input type="text"/>     
                                <span>원</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>원</span>
                            </div>   
                        </div>

                        <div className='search-field select'>
                            <div> 
                                <b>SMS 수신</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>동의</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>거부</label>
                            </div>   
                        </div>
                        
                        <div className='search-field select'>
                            <div> 
                                <b>이메일 수신</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>동의</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>거부</label>
                            </div>   
                        </div>
                        <div className='search-field select'>
                            <div> 
                                <b>성별</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>남성</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>여성</label>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>나이</b> 
                            </div>
                            <div>
                                <span>만</span>
                                <input type="text"/>     
                                <span>세</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>세</span>
                            </div>   
                        </div>
                        <div className='search-field range'>
                            <div> 
                                <b>생일/기념일</b> 
                            </div>
                            <div>
                                <span>만</span>
                                <input type="text"/>     
                                <span>세</span>
                                <span>~</span>  
                                <input type="text"/>       
                                <span>세</span>
                            </div>   
                        </div>
                        <div className='search-field select'>
                            <div> 
                                <b>회원상태</b> 
                            </div>
                            <div>
                                <input type='radio' id='sms1' className='mrbtn' name='group1' value='all' />
                                <label for='sms1'>전체</label>
                                <input type='radio' id='sms2' className='mrbtn' name='group1' value='agr' />
                                <label for='sms2'>정상</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>탈퇴</label>
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>휴먼</label>                               
                                <input type='radio' id='sms3' className='mrbtn' name='group1' value='all' />
                                <label for='sms3'>정지</label>
                            </div>   
                        </div>
                    </div> 
      
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                    <button className='more-btn' onClick={()=>{setToggle((e)=>!e)}}>
                    {
                        toggle
                        ? <i className='xi-angle-up'></i> 
                        : <i className='xi-angle-down'></i>
                    }    
                    </button>
                </div>
            </section>

        

            {/* 검색 결과 */}
            <section className='McontentBox'>   
                <div className='subTitle'>
                    <div className='resultsCount-wrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>

                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="신규등록순"
                                oldLabel="오래된등록순"
                            />
        
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    {/* 
                    [hm] 상태변경 버튼 -> 토글방식으로 변경 : 2023.09.19
                    <button className='m-sm-btn' onClick={()=>console.log(selectedRows)}>상태변경(판매중)</button>
                    <button className='m-sm-btn'>상태변경(판매중지)</button>
                    */}
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th><input type="checkbox" name="Allcheck" value=""/></th>
                                <th>승인상태</th>
                                <th>판매상태</th>
                                <th>수정/복사</th>
                                <th>상품ID</th>
                                <th>대표이미지</th>
                                <th>상품명</th>
                                <th>정상가</th>
                                <th>판매가</th>
                                <th>재고수량</th>
                                <th>추가이미지</th>
                                <th>상세설명</th>
                                <th>제조사</th>
                                <th>카테고리</th>
                                <th>필터</th>
                                <th>미성년자구매</th>
                                <th>인당최대구매수량</th>
                                <th>출고소요일</th>
                                <th>배송방법</th>
                                <th>묶음배송</th>
                                <th>제주/도서산간</th>
                                <th>택배사</th>
                                <th>배송비종류</th>
                                <th>무료배송 조건</th>
                                <th>기본배송비</th>
                                <th>초도배송비</th>
                                <th>반품배송비</th>
                                <th>판매시작일</th>
                                <th>판매종료일</th>
                                <th>등록일시</th>
                                <th>최종수정일시</th>
                                <th>수정이력</th>
                            </tr>
                        </thead>
                        <tbody>

                        {searchConfirm && searchResults?.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((product) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        />
                                ))
                            ) : data && data.data && data.data.length > 0 ? (
                                data.data.map((product) => (
                                    <ProductRow
                                        key={product?.id}
                                        product={product}
                                        expandedIds={expandedIds}
                                        handleExpandClick={handleExpandClick}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                            ))
                            ) : null}

           
                                                
                        </tbody>
                    </table>
                </div>
             
                  
            </section>
            

            {/* 페이지내비게이션 */}
            <section>
                {searchResults.count > 0 ? (
                    <SearchPagination
                        count={searchResults.totalCnt}
                        url={searchUrl}
                        pageSize={pageSize}
                        updateSearchResults={updateSearchResults}
                    />
                ) : (
                  
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>

        </div>

                        
        </>
    )
}

export default ProductsList