/***************************************************************************************
* FileName      : DeliveryModal.js
* Description   : 마이페이지 배송조회 모달
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.22
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState, useEffect } from 'react';
import {Row, Col, Modal } from 'react-bootstrap';
import { FaBox, FaTruck, FaBoxes, FaShippingFast, FaBoxOpen } from "react-icons/fa";
import { getMyOrderDeliveryTracking } from '../../../api/services/mypageApi';

export default function DeliveryModal({modalIsOpen, setModalIsOpen, id}){ // id : orderItem?.id
    const [state, setState] = useState('');
    const [deliveryData, setDeliveryData] = useState();
    useEffect(()=>{
        modalIsOpen && (
            getMyOrderDeliveryTracking(id)
                .then(res => {
                    setDeliveryData(res?.data);
                    setState(res?.data?.status);
                })
                .catch(error => {
                    alert(error?.response?.data?.data);
                    setModalIsOpen(false);
                })
        )
        console.log(deliveryData);
    }, [modalIsOpen])
    return(
        <Modal size={'md'} show={modalIsOpen} onHide={()=>setModalIsOpen(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title id='sm-modal' className='fs-6'>
                    배송조회{deliveryData?.completeYN === 'Y' && '(배송완료)'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='bg-hw-light py-4 px-3 rounded-2'>
                    <p className='fs-5 fw-bold my-1 text-hw'>{deliveryData?.smart_delivery_status?.invoiceNo}</p>
                    <p className='fs-7 text-grey'>{deliveryData?.courier}</p>
                </Row>
                {/* 배송단계 */}
                <Row className='gx-1 my-3 fs-8 text-center fw-bold'>
                    <Col className={state == '집화완료' ? 'p-0 fw-bold text-primary not-allowed' : 'fw-bold text-grey not-allowed'}>
                        <div className='bg-light p-3 rounded-2'>
                            <FaBox className='fs-5'/>
                        </div>
                        <p className='fw-bold py-1'>집화완료</p>
                    </Col>
                    <Col className={state == '배송중' ? 'p-0 fw-bold text-primary not-allowed' : 'fw-bold text-grey not-allowed'}>
                        <div className='bg-light p-3 rounded-2'>
                            <FaTruck className='fs-5'/>
                        </div>
                        <p className='fw-bold py-1'>배송중</p>
                    </Col>
                    <Col className={state == '지점도착' ? 'p-0 fw-bold text-primary not-allowed' : 'fw-bold text-grey not-allowed'}>
                        <div className='bg-light p-3 rounded-2'>
                            <FaBoxes className='fs-5'/>
                        </div>
                        <p className='fw-bold py-1'>지점도착</p>
                    </Col>
                    <Col className={state == '배송출발' ? 'p-0 fw-bold text-primary not-allowed' : 'fw-bold text-grey not-allowed'}>
                        <div className='bg-light p-3 rounded-2'>
                            <FaShippingFast className='fs-5'/>
                        </div>
                        <p className='fw-bold py-1'>배송출발</p>
                    </Col>
                    <Col className={state == '배송완료' ? 'p-0 text-primary not-allowed' : 'fw-bold text-grey not-allowed'}>
                        <div className='bg-light p-3 rounded-2'>
                            <FaBoxOpen className='fs-5'/>
                        </div>
                        <p className='fw-bold py-1'>배송완료</p>
                    </Col>
                </Row>
                {/* 배송상세 */}
                <Row className='gx-2'>
                    <hr className='mb-2 p-0'/>
                    {deliveryData?.smart_delivery_status?.lastStateDetail && <DeliveryDetail lastData={true} data={deliveryData?.smart_delivery_status?.lastStateDetail}/>}
                    <hr className='mb-2 p-0'/>
                    {
                        deliveryData?.smart_delivery_status?.trackingDetails != [] && (
                            deliveryData?.smart_delivery_status?.trackingDetails?.map(data => <DeliveryDetail data={data}/> )
                        )
                    }
                </Row>
            </Modal.Body>
        </Modal>
    )
}

function DeliveryDetail({lastData, data}){
    if(lastData){
        return(
            <Col xs={12} className='my-2 p-3 border-hw rounded-2'>
                <p className='fs-7'>{data?.where} | <b className='text-hw'>{data?.kind}</b></p>
                <p className='fs-8'>{data?.timeString}</p>
            </Col>
        )
    }else{
        return(
            <Col xs={12} className='my-2 p-3 bg-light rounded-2'>
                <p className='fs-7'>{data?.where} | <b>{data?.kind}</b></p>
                <p className='fs-8'>{data?.timeString}</p>
            </Col>
        )
    }
}