/***************************************************************************************
* FileName      : InvoiceHistory.js
* Description   : 셀러관리자 > 정산 > 세금계산서내역 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Button} from 'react-bootstrap';

import { useAdjustInvoiceDocumentQuery , handleSAdjustInvoiceSearch } from '../../../api/selleradmin/adjustApi';

import React, { useState , useEffect } from 'react';
import Pagination from '../common/Pagination'
import { AdjustRow } from './Invoice/AdjustRow';


import SortComponent from '../../admin/common/SortComponent2';
import PageSizeSelect from '../../admin/common/PageSizeSelect';

function InvoiceHistory()
{
    const [ordering, setOrdering] = useState('-created_at');
    const [pageSize, setPageSize] = useState(10); 

    const { data, error, isFetching, page, setPage } = useAdjustInvoiceDocumentQuery(ordering,pageSize);
    

    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedDateType, setSelectedDateType] = useState('join');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');



    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleSAdjustInvoiceSearch({
            setSearchResults,
            setSearchUrl,
            selectedStartDate,
            selectedEndDate, 
        });

        setSearchConfirm(true);
    };


    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
        if(searchResults){
            console.log("검색결과 있음")
        }
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/selleradmin/settlement/invoiceHistory'; 
    };
    
    console.log(data);
    console.log("---data---")
    return(
        <>
        <div className='mainContents'>
            {/* 세금계산서내역 */}
            <div className='page-tit'>
                <h2>세금계산서내역</h2>
                <span className="nt">목포해와로부터 발급받은 세금계산서 내역입니다.</span>
            </div>
   
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field date'>
                        <div> 
                            <b>날짜</b> 
                        </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                        </div>   
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                        <div className='selectWrap'>
                            <SortComponent
                                ordering={ordering}
                                handleOrderingChange={handleOrderingChange}
                                newLabel="최근날짜순"
                                oldLabel="오래된날짜순"
                            />
                            <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                        </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                               <th>발행일</th>
                               <th>수산상회</th>
                               <th>사업자등록번호</th>
                               <th>발행내용</th>
                               <th>공급가액</th>
                               <th>세액</th>
                               <th>합계</th>
                               <th>이미지</th>
                            </tr>
                        </thead>
                        <tbody>
                                
                        {searchConfirm && searchResults?.count === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : searchResults?.count > 0 ? (
                            searchResults?.data.map((Adjust , index) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))
                        ) : data && data?.data && data?.data.length > 0 ? (
                            data?.data.map((Adjust , index ) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))
                        ) : null}
                        
                        </tbody>
                    </table>
                </div>
            </section>

              {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                        <Pagination
                            count={searchResults?.count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                    />
                ) : (
    
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>

        </>
    )
}

export default InvoiceHistory