// import Slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMainBanner } from '../api/bannerApi';
import { useState, useEffect } from 'react';

const Carousel = () => {
    const [bannerList, setBannerList] = useState([]);

    useEffect(()=>{
        // 화면사이즈에 맞춰 배너 조회
        let bannerType = window.innerWidth < 760 ? 'mobile' : 'pc';
        getMainBanner(bannerType)
            .then(res => {
                setBannerList(res?.data?.data);
                console.log(res?.data?.data);
            })
            .catch(error => console.log(error?.response?.data))
    },[])

    // 옵션
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: '0px',
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div className='banner-area'>
            <div className="carousel">
                <Slider { ...settings }>
                    {bannerList.length !== 0 && bannerList.map(banner =>
                        <div key={banner.id} className='simg'>
                            <a href={banner.link}>
                            <img
                                src={banner.image}
                                width="100%"
                            />
                            </a>
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    );
}

export default Carousel;