import { BASE_URL } from '../apiConfig.js';
import { useState } from 'react';
import { instance } from '../../axiosInstance/axiosConfig';

// export const getCheckedUsername = (username) => {
//     return instance.get(`/auth/check-username/${username}/`, {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
// }

// 사용자페이지 > 회원가입 > ID 중복 및 아이디 형식 확인
export function getCheckedUsername(username) {  
    return instance.post('/auth/check-username/', {
        username: username,
      })
      .then((response) => {

        return response.data;
      })
      .catch((error) => {
        alert(error.response.data.data);
        throw error;
      });
  }


// 사용자 페이지 > 회원가입 > 등록
export function useRegistration() {
    const [isPostLoading, setIsPostLoading] = useState(false);
    const [error, setError] = useState(null);
  
    
    const post = async (data) => {
      setIsPostLoading(true);
      setError(null);
  
      try {
        const response = await fetch(`${BASE_URL}/auth/signup/`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        if (!response.ok) {
          const errorResponseData = await response.json();
          
          if(errorResponseData.email) alert(errorResponseData.email)
          if(errorResponseData.data) alert(errorResponseData.data)

          throw new Error(`Failed to post: ${errorResponseData.message}`);
        }
  
        const responseData = await response.json();
        return responseData;
      } catch (error) {
        setError("Failed to post");
        throw new Error("Failed to post");
      } finally {
        setIsPostLoading(false);
      }
    };
  
    return {
      post,
      isPostLoading,
      error,
    };
  }


// 셀러 > 회원가입 > 등록
export function useSellerRegistration() {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(null);

  
  const post = async (data) => {
    setIsPostLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/auth/seller-signup/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponseData = await response.json();
        
        if(errorResponseData.email) alert(errorResponseData.email)
        if(errorResponseData.data) alert(errorResponseData.data)

        throw new Error(`Failed to post: ${errorResponseData.message}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      setError("Failed to post");
      throw new Error("Failed to post");
    } finally {
      setIsPostLoading(false);
    }
  };

  return {
    post,
    isPostLoading,
    error,
  };
}

// 사용자 페이지 > 아이디 찾기 > 이메일인증요청
export function useFindUsername() {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(null);

  
  const post = async (data) => {
    if (isPostLoading) {
      // 이미 로딩 중인 경우 무시
      return;
    }
    
    setIsPostLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/auth/find-username/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponseData = await response.json();
        
        if(errorResponseData.data) alert(errorResponseData.data)

        throw new Error(`Failed to post: ${errorResponseData.message}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      setError("Failed to post");
      throw new Error("Failed to post");
    } finally {
      setIsPostLoading(false);
    }
  };

  return {
    post,
    isPostLoading,
    error,
  };
}

// 사용자 페이지 > 아이디 찾기 > 결과
export function useResultUsername() {
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(null);

  
  const post = async (data) => {
    setIsPostLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/auth/verify-email-username/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponseData = await response.json();
        
        if(errorResponseData.data) alert(errorResponseData.data)

        throw new Error(`Failed to post: ${errorResponseData.message}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      setError("Failed to post");
      throw new Error("Failed to post");
    } finally {
      setIsPostLoading(false);
    }
  };

  return {
    post,
    isPostLoading,
    error,
  };
}

export function findPasswd(id, email) {
  return instance.post('/auth/find-password/', {
    username: id,
    email: email,
  })
}

// 이메일 인증 ID 확인 (new)
export function findIdVerifyEmail(email, verification_code) {
  return instance.post('/auth/verify-email-username/', {
    email: email,
    verification_code: verification_code,
  })
}

// 이메일 인증 PW 변경
export function changePassword(email, id, verification_code, password, password2) {
  return instance.post('/auth/verify-email-password/', {
    email: email,
    username: id,
    verification_code: verification_code,
    password: password,
    password2: password,
  })
}