import { SliderCard } from "../home/Card"
// import Slick
import Slider from 'react-slick';

export function SeasonalSlider({seasonalProduct}){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        centerPadding: '0px',
        initialSlide: 0,
        arrows: false,
        fade: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return(
        <Slider { ...settings }>
            {
                seasonalProduct && seasonalProduct.length > 0 && (
                    seasonalProduct.map((product, i)=>(                        
                        <SliderCard product={product} i={i} key={i} />
                    ))
                )
            }
        </Slider>
    )
}