/***************************************************************************************
* FileName      : Settlement.js
* Description   : 해와관리자 > 정산 > 정산현황 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';

import { useAdjustDocumentQuery , handleAdjustDocumentSearch } from '../../../api/hwadmin/adjustApi';
import { useApprovedVendors } from '../../../api/hwadmin/productApi';

import React, { useState , useEffect } from 'react';
import { AdjustRow } from './Settlement/AdjustRow';
import SelectVendor from '../product/Component/SelectVendor';
function Settlement()
{
    const [ordering, setOrdering] = useState('-last_settlement_date');

    const { data, error, isFetching } = useAdjustDocumentQuery(ordering);
     
   
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);

    const [searchUrl, setSearchUrl] = useState('');

    //날짜 옵션 선택
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });
    // 정산일
    const [settlementDate, setSettlementDate] = useState('1주일');
   
    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };



    const onSearch = () => {
        handleAdjustDocumentSearch({
            setSearchResults,
            setSearchUrl,
            selectedStartDate,
            selectedEndDate, 
            selectedVendor
        });

        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        setSearchConfirm(false);
        window.location.href = '/admin/settle/settlement';
    };
    
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    
    // 정산일 선택
    const handleSettlementDateChange = (e) => {
        setSettlementDate(e.target.value); 
        if(e.target.value !== "직접선택"){
            // 오늘 날짜
            const today = new Date();
            let endDate = new Date(today);

            if (e.target.value === "1주일") {
                endDate.setDate(today.getDate() + 7);
            } else if (e.target.value === "1달") {
                endDate.setMonth(today.getMonth() + 1);
            } else if (e.target.value === "1년") {
                endDate.setFullYear(today.getFullYear() + 1);
            }

             // API에 적합한 형식으로 날짜 포맷
            const formattedStartDate = formatDate(today);
            const formattedEndDate = formatDate(endDate);
            
            setSelectedStartDate(formattedStartDate);
            setSelectedEndDate(formattedEndDate);
        } else {
            setSelectedStartDate('');
            setSelectedEndDate('');
        }
    };
    const [scheduledAmountTotal, setScheduledAmountTotal] = useState(0);
    const [completeAmountTotal , setCompleteAmountTotal] = useState(0);
    const [totalSettlementTotal , setTotalSettlementTotal] = useState(0);

    useEffect(() => {
        if( data?.data.length > 0 ){ 
            calculateTotal(data);
        }
    }, [data]);


    useEffect(() => {
        if( searchResults.data?.length > 0 ){ 
            calculateTotal(searchResults);
        }
    }, [searchResults]);

    //  합계 
    const calculateTotal = (data) => {
        let total = 0 , total2=0, total3=0;

        data.data.forEach((Adjust) => {
            total += Adjust.scheduled_amount;
            total2 += Adjust.complete_amount;
            total3 +=  Adjust.total_settlement_amount;
        });
        setScheduledAmountTotal(total);
        setCompleteAmountTotal(total2);
        setTotalSettlementTotal(total3);

    };

    console.log(data);
    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>정산현황</h2>
            </div>

            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>
                    <div className='search-field date'>
                        <div> 
                            <b>정산일</b> 
                        </div>
                        <div className='SearchRadio'>
                            <input
                                type='radio'
                                id='settlementDateWeek'
                                name='settlementDate'
                                value='1주일'
                                checked={settlementDate === '1주일'}
                                onChange={handleSettlementDateChange}
                                />
                            <label htmlFor='settlementDateWeek'>1주일</label>
                            
                            <input
                                type='radio'
                                id='settlementDateMonth'
                                name='settlementDate'
                                value='1달'
                                checked={settlementDate === '1달'}
                                onChange={handleSettlementDateChange}
                                />
                            <label htmlFor='settlementDateMonth'>1달</label>

                            <input
                                type='radio'
                                id='settlementDateYear'
                                name='settlementDate'
                                value='1년'
                                checked={settlementDate === '1년'}
                                onChange={handleSettlementDateChange}
                            />
                            <label htmlFor='settlementDateYear'>1년</label>

                            <input
                                type='radio'
                                id='settlementDateInput'
                                name='settlementDate'
                                value='직접선택'
                                checked={settlementDate === '직접선택'}
                                onChange={handleSettlementDateChange}
                            />
                            <label htmlFor='settlementDateInput'>직접선택</label>
                        <div>
                        
                         {settlementDate === '직접선택' &&
                            <>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <span> ~ </span>
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
                            </>
                            }
                        </div>    
                        </div>
                    </div>
                    <div className='search-field division'>
                        <div> 
                            <b>수산상회</b> 
                        </div>
                        <div>
                            <SelectVendor 
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                />   
                        </div>   
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={handleResetClick}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={onSearch}>검색</button>
                </div>
            </section>


            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.data?.length > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.data?.length}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.data?.length ? data?.data?.length : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                    <div className='selectWrap'>
                        <select className='mSelectBox' id="ordering" value={ordering} onChange={handleOrderingChange}>
                            <option value="total_settlement_amount">누적정산액 내림차순</option>
                            <option value="-total_settlement_amount">누적정산액 오름차순</option>
                            <option value="-last_settlement_date">최종정산일 내림차순</option>
                            <option value="last_settlement_date">최종정산일 오름차순</option>
                        </select>
                    </div>
                    }

                </div>
                <div className='m-btn-wrap-sm'>
                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>
                                {/* <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/>
                                <col style="width:10%"/> */}
                        </colgroup>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="Allcheck" value=""/></th>  
                                <th>수산상회</th>
                                <th>정산예정금액</th>
                                <th>정산완료금액<br/>
                                    (2023.00.00~2023.00.00)
                                </th>
                                <th>누적 정산액</th>
                                <th>최종 정산일</th>
                            </tr>
                        </thead>
                        <tbody>
            
                        
                        {searchConfirm && searchResults.data?.length === 0 ? (
                            <tr>
                                <td colSpan="17">검색 결과가 없습니다.</td>
                            </tr>
                        ) : searchResults.data?.length > 0 ? (
                            <>
                            {searchResults?.data.map((Adjust , index) => (
                                <AdjustRow 
                                    index={index} 
                                    Adjust={Adjust}/>
                            
                            ))}
                            <tr>
                                <td colSpan="2"><strong>합계</strong></td>
                                <td>
                                    {scheduledAmountTotal ? scheduledAmountTotal.toLocaleString() : "0"}원
                                </td>
                                <td>
                                    {completeAmountTotal ? completeAmountTotal.toLocaleString() : "0"}원
                                </td>
                                <td>
                                    {totalSettlementTotal ? totalSettlementTotal.toLocaleString() : "0"}원
                                </td>
                            </tr>
                            </>
                        ) : data && data?.data && data?.data.length > 0 ? (
                            <>
                            {
                                data?.data.map((Adjust , index ) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            }
                            <tr>
                                <td colSpan="2"><strong>합계</strong></td>
                                <td>
                                    {scheduledAmountTotal ? scheduledAmountTotal.toLocaleString() : "0"}원
                                </td>
                                <td>
                                    {completeAmountTotal ? completeAmountTotal.toLocaleString() : "0"}원
                                </td>
                                <td>
                                    {totalSettlementTotal ? totalSettlementTotal.toLocaleString() : "0"}원
                                </td>
                            </tr>
                            </>
                        ) : null}

                        </tbody>
                    </table>
                </div>
            </section>

        
            </div>

        </>
    )
}

export default Settlement