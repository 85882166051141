/***************************************************************************************
* FileName      : Template.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 발송예약 > 발송유형 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.27
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect , useState } from 'react';
export default function Template(
    {TalkTemplate, selectedTemplate , handleSelectChange , reservationData ,setReservationData}){

    const updateField = (fieldName, newValue) => {
        setReservationData((prevData) => ({
          ...prevData,
          [fieldName]: newValue
        }));    
    };

   
    console.log(TalkTemplate);
    console.log("-TalkTemplate")


    return(
       <>
        <div>
            <select className='mSelectBox' name="vendor"  value={selectedTemplate.name} onChange={handleSelectChange}>
                { TalkTemplate && TalkTemplate.list.map(template => (
                    <option value={template.templtName}>
                        {template.templtName}
                    </option>
                ))}
            </select>
        </div>
       </>
    )
}