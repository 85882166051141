/***************************************************************************************
* FileName      : ViewReviewModal.js
* Description   : 고객 > 마이페이지 > 리뷰관리 > 나의 리뷰 > 리뷰 상세보기 모달창
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.20
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ViewStarRating from './ViewStarRating';

import { fetchMyReviewDetails } from '../../../api/services/mypageApi';

function ViewReviewModal({ reviewId, setIsOpen })
{

    const [reviewDetails , setReviewDetails] = useState();

    useEffect(() => {
        async function fetchReviewDetails() {
          try {
            const details = await fetchMyReviewDetails(reviewId);
            setReviewDetails(details);
          } catch (error) {
            console.error('제품 세부 정보를 가져오는 중 오류가 발생했습니다:', error);
          }
        }
    
        fetchReviewDetails();
      }, [reviewId]);


    const closeModal = () => {
        setIsOpen(false);
    };


    
    return (
        <div className="modal ">
            <div className="modal-content ReviewWModal" >
                <header className='rmheader'>
                    <h3>리뷰 상세보기</h3>
                    <span className="close" onClick={closeModal}>&times;</span>
                </header>
            
                {/* 상품정보 */}
                <Row className='pb-2 border-bottom border-1 border-gray'>
                    <Col sm={3} md={2} className=''>
                        <div className='rpi-wrap'>
                            <img src={reviewDetails?.orderitem.main_image} />
                        </div>
                    </Col>
                    <Col sm={9} md={10} className='text-left'>
                        <p>{reviewDetails?.orderitem.vendor_name}</p>
                        <p>{reviewDetails?.orderitem.product_name}</p>
                    </Col>
                </Row>
               
               {/* 적입 예상 마일리지 ? */}
               <Row>

               </Row>
               
               {/* 상품 평가 */}
               <Row className='p-2 border-bottom border-1 border-gray'>
                    <Col sm={6} md={6} className="pd-rwr">
                        <span>상품은 만족하셨나요?</span>
                    </Col>
                    <Col sm={6} md={6}>
                        <ViewStarRating 
                            rating={reviewDetails?.rating}
                        />
                    </Col>
               </Row>

               
                {/* 후기 */}
                <Row className='p-2 border-bottom border-1 border-gray'>
                    <p>{reviewDetails?.content}</p>
                   
                </Row>

                {/* 후기 이미지 */}
                <Row>
                    {reviewDetails?.review_images.map(image => (
                        <div className='mrpi-wrap'>
                            <img key={image.id} src={image.path} alt={`Review Image ${image.id}`} />
                        </div>
                    ))}
                </Row>

            </div>
    </div>
    )
}

export default ViewReviewModal