import { instance } from '../axiosInstance/axiosConfig.js';

// 주문자 기본정보 전체 조회
export const getUserAddress = () => {
    return instance.get(`/auth/me/`)
}

// 주문페이지 기본정보 전체 조회
export const getOrderDetail = () => {
    return instance.get(`/order/detail/`)
}

// 사용 가능한 마일리지 조회
export const getMileage = () => {
    return instance.get(`/mileage/available/`)
}

// 마일리지 사용 요청
export const sendUseMileage = (id, inputMileage) => {
    return instance.patch(`/order/mileage/use/${id}/`, {
        mileage: inputMileage
    })
}

// 사용 가능한 쿠폰 조회
export const getAvailableCoupon = (type, vendor, price) => {
    return instance.post(`/coupon/available/`,
    {
        coupon_type: type,
        vendor: vendor,
        price: price,
    })
}

// 쿠폰(상품할인) 사용 요청
export const sendUseItemCoupon = (id, coupon_issue) => {
    return instance.patch(`/order/item/coupon/${id}/`,
    {
        coupon_issue : coupon_issue 
    })
}

// 쿠폰(배송비 할인) 사용 요청
export const sendUseDeliveryCoupon = (id, coupon_issue) => {
    return instance.patch(`/order/delivery/coupon/${id}/`,
    {
        coupon_issue : coupon_issue 
    })
}

// 사용자 정보 조회
export const updateOderUserInfo = (pk, name, phone, email) => {
    return instance.patch(`/order/change/orderer/${pk}/`,
    {
        orderer_name: name,
        orderer_phone_number: phone,
        orderer_email: email
    })
}

// 사용자 주소 리스트 조회
export const getAddressList = () => {
    return instance.get(`/auth/address/`)
}

export const postAddressList = (data) => {
    return instance.post(`/auth/address/`,
    {
        user: data.user,
        name: data.name,
        recipient: data.recipient,
        phone_number: data.phone_number,
        address: {
            address: data.address,
            detail: data.detail,
            postal_code: data.postal_code,
            address_type: "지번"
        }
    })
}

export const updateUseAddress = (pk, addressData) => {
    return instance.patch(`/order/delivery/info/${pk}/`,
    {
        address_name: addressData.name,
        recipient_name: addressData.recipient,
        recipient_phone_number: addressData.phone_number,
        postal_code: addressData.address.postal_code,
        address: addressData.address.address,
        detail_address: addressData.address.detail
    })
}

export const updateDeliveryType = (deliveryItemId, selectedItem) => {
    return instance.patch(`/order/delivery/type/${deliveryItemId}/`,{
        type : selectedItem
    })
}

export const setOrderPayment = (orderId, paymentKey, amount) => {
    return instance.post(`/order/payment/`,{
        orderId: orderId,
        paymentKey: paymentKey,
        amount: amount,
    })
}