import React, { useEffect } from "react";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MemoModal from "./MemoModal";
import ToggleSwitch from "./ToggleSwitch";
import PenaltyModal from "./PenaltyModal";

export const VendorRow = ({ vendor , selectedRows ,setSelectedRows }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    const vendorId = vendor.id;
    navigate(`/admin/store/edit/${vendorId}`); // 정보 수정 페이지로 이동
  };
  
  const handleCheckboxChange = (event) => {
    const vendorId = vendor?.id;
    if (event.target.checked) {
      if (!selectedRows.includes(vendorId)) {
        setSelectedRows([...selectedRows, vendorId]);
      }
    } else {
      setSelectedRows(selectedRows.filter(id => id !== vendorId));
    }
  };

  // 메모 모달
  const [isOpen, setIsOpen] = useState(false);
  const handleMemoClick = () => {
    setIsOpen(true);
  };

  // 패널티 점수 모달
  const [penaltyScoreModalState, setPenaltyScoreModalState] = useState(false);
  // 패널티 이용정지 모달
  const [activityPenaltyModalState, setActivityPenaltyModalState] = useState(false);

  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/member/management/?search=${searchQuery}`;
    navigate(newUrl);
  };

  return (
    <tr key={vendor?.id}>
      <td className="sticky-col-1">
        <input 
          type="checkbox" 
          name="" 
          value=""
          onClick={handleCheckboxChange}
        />
      </td>
      <td className="sticky-col-2">{vendor?.id}</td>
      <td className="sticky-col-3 cursor-pointer" onClick={handleEditClick}>{vendor?.name}</td>
      <td className='blueLink' onClick={() => handleCellClick(vendor.owner?.username)}>
        {vendor?.owner?.username}
      </td>
      <td>{vendor?.business_type}</td>
      <td className="px-4">
        <ToggleSwitch pk={vendor?.id} checked={vendor?.hidden}/>
      </td>
      <td><img src={vendor?.business_license} width="80px" onClick={()=>window.open(vendor?.business_license)}/></td>
      <td><img src={vendor?.sales_registration} width="80px" onClick={()=>window.open(vendor?.sales_registration)}/></td>
      <td>{vendor?.business_number}<br/>{vendor?.sales_registration_number}</td>
      {/* 주소지 수정 예정 : PKH */}
      {/* <td>{vendor.address && vendor.address.address}</td> */}
      <td>({vendor?.address?.postal_code}) {vendor?.address?.address} {vendor?.address?.detail}</td>
      <td>({vendor?.dispatch_location?.postal_code}) {vendor?.dispatch_location?.address} {vendor?.dispatch_location?.detail}</td>
      <td>({vendor?.return_address?.postal_code}) {vendor?.return_address?.address} {vendor?.return_address?.detail}</td>
      <td><img src={vendor?.owner_image} width="80px" onClick={()=>window.open(vendor?.owner_image)}/></td>
      <td><img src={vendor?.account_image} width="80px" onClick={()=>window.open(vendor?.account_image)}/></td>
      <td>{vendor?.bank}<br/>{vendor?.account_holder}</td>
      <td>{vendor?.tel}<br/>{vendor?.fax}</td>
      <td>{vendor?.hidden ? <span className="text-grey">비공개</span> : <span className="text-hw">공개</span> }</td>
      <td>{vendor?.tax}</td>
      {/* employee_list 조회 오류 발생 일단 주석처리함 : PKH */}
      {/* <td>{vendor?.employee_list?.length >= 1 ? vendor.employee_list.map(x => x) : null}</td> */}
      {/* employee_list 조회 오류 수정 : hm */}

      <td>
      {vendor?.employee_list !== null && vendor?.employee_list.length > 0 ? 
        vendor?.employee_list.map((employee, index) => (
          <span 
            key={index} 
            className='blueLink' 
            onClick={() => handleCellClick(employee.username)}
          >
            {employee.username}
          </span>
        )) 
        : 
        null
      }
    </td>

      <td>{vendor?.co_representative ? "공동대표 있음" : "-"}</td>
      <td>{vendor?.pending_approval_count}</td>
      <td>{vendor?.approved_count}</td>
      <td>{vendor?.rejected_count}</td>
      <td>{vendor?.awaiting_sale_count}</td>
      <td>{vendor?.on_sale_count}</td>
      <td>{vendor?.sold_out_count}<br/>{vendor?.near_sold_out_count}</td>
      <td>{vendor?.sale_paused_count}</td>
      <td>{vendor?.sale_ended_count}</td>
      <td>{vendor?.total_sales_amount}<br/>{vendor?.total_sales_transactions}</td>
      <td>
        패널티점수<br/>
        <button className='m-sm-btn' onClick={()=>setPenaltyScoreModalState(true)}>패널티 점수 관리</button>
      </td>
      <td>입점신청일<br/>입점승인일</td>
      <td>마지막방문일</td>
      <td>
        <button className='m-sm-btn' onClick={()=>handleMemoClick()}>메모</button>
      </td>
      <td>이메일/sms</td>
      <td>
        패널티 및 정지<br/>
        <button className='m-sm-btn' onClick={()=>setActivityPenaltyModalState(true)}>이용제한 패널티</button>
      </td>
      <td><button className='m-sm-btn'  onClick={handleEditClick}>정보수정</button></td>

      <MemoModal 
        show={isOpen} 
        setShow={setIsOpen} 
        vendorId={vendor?.id}
        vendorName={vendor?.name}
        />

      <PenaltyModal
        show={penaltyScoreModalState}
        setShow={setPenaltyScoreModalState}
        type={'패널티점수관리'}
        id={vendor?.id}
        />

      <PenaltyModal
        show={activityPenaltyModalState}
        setShow={setActivityPenaltyModalState}
        type={'이용제한패널티'}
        id={vendor?.id}
        />
    </tr>
  );
};
