import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const OnetooneRow  =  ({ index, oto }) => {

    const navigate = useNavigate();

    const handleOrderNumberClick = (NoticeId) => {
        navigate(`./${NoticeId}`);
    };

    return(
        <>
        <Row key={index} 
            className="border-bottom bg-white m-0 p-0 py-2"
            onClick={() => handleOrderNumberClick(oto.id)}
            >
            {/* 번호 */}
            <Col sm={1} md={1} className="text-center p-2 fs-7 fw-bold text-secondary d-none d-md-flex justify-content-center">
                {index} 
            </Col>

            {/* 제목 */}
            <Col xs={3} md={5} className="text-start p-2 fs-7 fw-bold">
                {oto.title}   
            </Col>

            {/* 작성자 */}
            <Col xs={5} md={1} className="text-start p-2 fs-7 fw-bold">
                {oto.user}
            </Col>

            {/* 작성일 */}
            <Col xs={2} md={2} className="text-center p-2 fs-7 fw-bold text-secondary">
                {oto.created_at.substring(0, 10)}

            </Col>
            {/* 답변 상태*/}
            <Col xs={1} md={3} className="text-center p-2 fs-7 fw-bold text-secondary">
                {oto.status}
            </Col>
        </Row>
        {/* {oto.answer && 
            <Row>
                {oto.answer}
            </Row>  
        } */}
                                            

        </>
    )
}