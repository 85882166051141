/***************************************************************************************
* FileName      : Orderinquiry.js
* Description   : 고객 > 마이페이지 > 주문배송조회 > 주문배송조회
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MypageTabComponent from './MypageTab.js';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';
import DatePicker from 'react-datepicker'; // 날짜 선택용 인풋박스
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko'; // 날짜 선택필드 한국어로 변경

import { useMyOrderQuery } from '../../api/services/mypageApi.js';
import Pagination from '../customer/common/Pagination.js';
import OrderRow from './Orderinquiry/OrderRow.js';
import MOrderRow from './Orderinquiry/MOrderRow.js';
import { extractYearMonthDayHourMinute2 , extractYearMonthDay2 } from './Component/extractDateUtils.js';

function OrderInquiryLayout(){
    return(
        <>
            <Container>
                <StatusBar/>
                <Row className='m-0'>
                    <div className='mypage_wrap'>
                        <Col md={3} lg={3}><Sidemenu nowPage={'order'}/></Col>
                        <Col xs={12} sm={12} md={9} lg={9}>
                            <Orderinquiry />
                        </Col>
                    </div>
                </Row>
            </Container>
        </>
    )
}

function Orderinquiry()
{
      
    const navigate = useNavigate();

    // const [selectTab, setTab] = useState();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { data:myOrderData, error, isFetching, page, setPage } = useMyOrderQuery();
    
    // 검색
    const [searchConfirm, setSearchConfirm] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const [orderItemsLengthArray, setOrderItemsLengthArray] = useState([]);

    // if (!myOrderData) {
    //   return <div>Loading...</div>;
    // }

    console.log("---myOrderData---")
    console.log(myOrderData);

    // myOrderData.delivery_items > 배송비 
    // myOrderData.delivery_items  부분취소 


    const handleStartDate = date => setStartDate(date);
    const handleEndDate = date => setEndDate(date);

    const handlePrevPage = () => {
      setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
    
    const handleOrderNumberClick = (orderId) => {
        navigate(`/mypage/order/${orderId}`);
    };

    const handleClick = (status , orderNum , orderId ) => {
        if(status==="취소" && orderNum ){
            navigate(`/mypage/order/${orderNum}/cancel/${orderId}`);
        }
        if(status=="부분취소"){
            navigate(`/mypage/order/${orderId}/itemcancel/`);
        }
    };

    useEffect(() => {
        if (myOrderData && myOrderData.data && myOrderData.data.length > 0) {
          const lengthsArray = myOrderData.data.map((orderData) =>
            orderData.delivery_items.reduce(
              (total, deliveryItem) => total + deliveryItem.order_items.length,
              0
            )
          );
          // 합산된 값을 상태로 설정
          setOrderItemsLengthArray(lengthsArray);
        }

    }, [myOrderData]);
      

    useEffect(()=>{
        console.log(orderItemsLengthArray)
          
    },[orderItemsLengthArray])

    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <Row className='m-0 p-0 mb-3'>
                <Col xs={12} sm={5} className='m-0 p-0'>
                      <h3 class="m-0 p-0 fs-5 fw-bold">주문배송조회 
                      <span className='fs-6 fw-bold text-hw'> 
                      &nbsp;총 { myOrderData?.totalCnt }건</span>
                      </h3>
                </Col>
                {/* <Col xs={12} sm={7} className=' d-none d-sm-flex justify-content-end align-items-center m-0 p-0'>
                    <p className='me-2 fs-7 fw-bold text-grey'>조회기간</p>
                    <div className="date-picker-wrapper m-0 p-0">
                          <DatePicker 
                            selected={startDate} 
                            onChange={handleStartDate} 
                            laceholderText={'시작일'} 
                            dateFormat="yyyy-MM-dd"
                            locale={ko}
                          />
                    </div>
                    <p className="ms-1 me-1"> ~ </p>
                    <div className="date-picker-wrapper m-0 p-0">
                          <DatePicker 
                            selected={endDate} 
                            onChange={handleEndDate}
                            placeholderText={'종료일'} 
                            dateFormat="yyyy-MM-dd" locale={ko}
                          />
                    </div>
                </Col> */}
            </Row>

      
            {/* 검색 */}
            {/* <ul className='mypage-step mb-3'>
                <li>
                    <em></em>
                    <spen>주문접수</spen>
                </li>
            </ul>

            
            <div className='mp-search-area mb-4'>
                <div className='select-area'>
                    <p>구매기간</p>
                </div>
            </div> */}

            <table className='mypage-table' id="mypage-order-table">
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '15%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>주문번호</th>
                        <th>상품</th>
                        <th>수량</th>
                        <th>주문금액</th>
                        <th>진행현황</th>
                    </tr>
                </thead>
                <tbody className='history'>
                {searchConfirm && searchResults.count === 0 ? (
                    <tr>
                        <td colSpan="17">검색 결과가 없습니다.</td>
                    </tr>
                ) : searchResults.count > 0 ? (
                    searchResults?.data.map((orderItem) => (
                        <OrderRow
                            orderItem={orderItem}
                        />
                    ))
                    ) : myOrderData && myOrderData.data && myOrderData.data.length > 0 ? (
                        myOrderData.data.map((orderData, outerIndex) => (
                            orderData.delivery_items.map((deliveryItem, innerindex) => (
                                deliveryItem.order_items.map((orderItem, index) => (
                                    <tr key={orderItem.id}>
                                        {innerindex === 0 && index === 0 && (
                                            <td rowSpan={orderItemsLengthArray[outerIndex]}>
                                                <p>{extractYearMonthDayHourMinute2(orderData?.created_at)}</p>
                                                <p className='ordernum mpd' onClick={() => handleOrderNumberClick(orderData.id)}>
                                                    {orderData.order_number}
                                                </p>
            
                                                {orderData.is_cancelable && (
                                                    <button
                                                        className='mpbtn1 mt-2'
                                                        onClick={() => { handleClick("취소", orderData.order_number, orderData.id) }}>
                                                        전체취소
                                                    </button>
                                                )}
                                               
                                            </td>
                                        )}
                                        
                                        <OrderRow
                                            key={orderItem.id}
                                            orderItem={orderItem}
                                            deliveryItem={deliveryItem}
                                        />

                                    </tr>
                                ))
                            ))
                        ))
                    ) : null}
                </tbody>
            </table>





        {/* 모바일 */}
        <div className='mOrderContents' id="mContents">
            <div className=''>
                {myOrderData &&
                    myOrderData.data.map((orderData, outerIndex) => (
                    <div className='mb-5' key={orderData.id} >
                        <div className='orderData'>
                            <p>{orderData.paid_at ? 
                                extractYearMonthDay2(orderData.paid_at) : '주문일자'}</p>
                            <span onClick={() => handleOrderNumberClick(orderData.id)}> 
                                상세보기 &gt;
                            </span>

                        </div>
                        {orderData.delivery_items.map((deliveryItem, innerIndex) => (
                            deliveryItem.order_items.map((orderItem, index) => (
                                <MOrderRow
                                    key={orderItem.id}
                                    orderItem={orderItem}
                                    deliveryItem={deliveryItem}
                                />
                            ))
                        ))}
                    </div>
                    ))
                }
            </div>
        </div>

        {/* 페이지내비게이션 */}
        <section className='mt-5'>
            <Pagination
                count={myOrderData?.totalCnt || 0}
                page={page}
                setPage={setPage}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
            />
        </section>

        </div>  
        </>
    )
}


export default OrderInquiryLayout