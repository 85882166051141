/***************************************************************************************
* FileName      : Info.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 > 쿠폰등록 > 발급조건 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.15
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useEffect , useState } from 'react';
export default function Info({couponInfoData ,setCouponInfoData}){

    const updateCouponInfoField = (fieldName, newValue) => {
        setCouponInfoData((prevData) => ({
          ...prevData,
          [fieldName]: newValue
        }));    
    };

    // 혜택구분
    const [type, setType] = useState('상품');

    const handleTypeMethod = (event) => {
        const Value = event.target.value;
        setType(Value);

        updateCouponInfoField("type", Value);
    };

    // 쿠폰유형
    const [format, setFormat] = useState('회원선택형');

    const handleFormatMethod = (event) => {
        const Value = event.target.value;
        setFormat(Value);

        if(Value != '번호입력형'){
            updateCouponInfoField("code", "");
        }

        updateCouponInfoField("format", Value);
    };

    // 쿠폰유형이 번호입력형 일 때 관리자가 직접 코드 입력
    const [code , setCode] = useState('');
    const onChangeCode = (e) => {
        const codeData = e.target.value;
        setCode(codeData);

        updateCouponInfoField("code" , codeData)
    };

    // 쿠폰이름 
    const [name, setName] = useState('');

    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);

        updateCouponInfoField("name" , name)
    };
      
    return(
       <>
        <div className='product-info'>
            <table>                            
                <tbody>
                    <tr>
                        <th>혜택구분</th>  
                        <td className='radioBtn-wrap'>
                            <label>
                                <input
                                    type="radio"
                                    value="상품"
                                    checked={type === '상품'}
                                    onChange={handleTypeMethod}
                                />
                                상품할인
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    value="배송비"
                                    checked={type === '배송비'}
                                    onChange={handleTypeMethod}
                                />
                                무료배송
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <th>쿠폰유형</th>  
                        <td className="radioBtn-wrap">
                            <label>
                                <input
                                    type="radio"
                                    value="회원선택형"
                                    checked={format === '회원선택형'}
                                    onChange={handleFormatMethod}
                                />
                                회원선택형
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    value="다운로드형"
                                    checked={format === '다운로드형'}
                                    onChange={handleFormatMethod}
                                />
                                다운로드형
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="번호입력형"
                                    checked={format === '번호입력형'}
                                    onChange={handleFormatMethod}
                                />
                                번호입력형
                            </label>
                        </td>
                    </tr>

                    {format === '번호입력형' && 
                        <>
                            <tr>
                                <th>쿠폰코드</th>  
                                <td className="radioBtn-wrap w-50">
                                    <input
                                        type="text"   
                                        onChange={onChangeCode}/>  
                                </td>
                            </tr>
                        </>
                    }
                 
                    <tr>
                        <th>쿠폰이름</th>  
                        <td className="radioBtn-wrap w-50">
                            <input
                                type="text"   
                                onChange={onChangeName}/>  
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
       </>
    )
}