import React, { useState } from 'react';
import { useEffect } from 'react';

import { useQueryClient } from 'react-query';
import { useMainCategories } from '../../../../../api/Category';
import SubCategories from './SubCategories';


function MainCategories({ viewCategoryId , viewCategoryNames }) {
    const queryClient = useQueryClient();
    const { isLoading, isError, data: mainCategoriesData } = useMainCategories();
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    useEffect(() => {
        // SelectedCategoryId 값이 변경될 때마다 실행되는 코드
    }, [selectedCategoryId]);
    

    // 조회 데이터 
    useEffect(() => {
      handleViewCategory(viewCategoryId, viewCategoryNames);
    }, []); 


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error occurred while fetching main categories.</div>;
    }

    // 조회용
    function handleViewCategory(viewCategoryId, viewCategoryNames)
    {
        setSelectedCategoryId(viewCategoryId[0]);

        queryClient.setQueryData('selectedMaincategory', viewCategoryNames[0]);
        queryClient.setQueryData('selectedSubcategory', viewCategoryNames[1]);
        queryClient.setQueryData('selectedSubSubcategory', viewCategoryNames[2]);

        queryClient.setQueryData('selectedMainIdcategory', viewCategoryId[0]);
        queryClient.setQueryData('selectedSubIdcategory', viewCategoryId[1]);
        queryClient.setQueryData('selectedSubSubIdcategory', viewCategoryId[2]);
  
    }

    // 사용자 선택용
    function handleMainCategoryClick(id,name) {
        setSelectedCategoryId(id);
        queryClient.setQueryData('selectedId', id);
        queryClient.setQueryData('selectedName', name);

        queryClient.setQueryData('selectedMaincategory', name);
        queryClient.setQueryData('selectedSubcategory', '');
        queryClient.setQueryData('selectedSubSubcategory', '');

        queryClient.setQueryData('selectedMainIdcategory', id);
        queryClient.setQueryData('selectedSubIdcategory', '');
        queryClient.setQueryData('selectedSubSubIdcategory', '');

    }

    return (
    <>
      <div className='categbox'>
          <p className='ctit'>대분류</p>
          <div type="대분류" className='kwutOX'>
              <ul>
                  {mainCategoriesData && mainCategoriesData.data.map((category) => (
                    <li className='fBSFej' key={category.id}>
                      <button 
                        // onClick={() => handleMainCategoryClick(category.id , category.name)}
                        style={{ color: selectedCategoryId === category.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                        >{category.name}</button>
                    </li>
                  ))}
              </ul> 
          </div>
      </div>


      {selectedCategoryId && (
          <SubCategories 
            viewCategoryNames={viewCategoryNames}
            viewCategoryId={ viewCategoryId }
            categoryId={selectedCategoryId} 
            key={selectedCategoryId} />
      )}
    
   </>
  );
}

export default MainCategories;