/***************************************************************************************
* FileName      : ProductRow.js
* Description   : 해와관리자 > 상품관리 > 신규요청 > ProductRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     :  2023.09.19 : hm : 단독 상품 반려 모달
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNewProductsQuery, setProductState } from '../../../../api/hwadmin/productApi.js';
import { Modal } from 'react-bootstrap';
import InfoNoticeModal from './InfoNoticeModal.js';

export const ProductRow = ({ product, expandedIds, handleExpandClick, selectedRows, setSelectedRows, selectedRowsData, setSelectedRowsData, setApproveProduct}) => {
  const navigate = useNavigate();

  const { fetchNewProductDetailsById } = useNewProductsQuery();

  const [productDetails, setProductDetails] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  // 상품정보제공고시 모달 
  const [isInfoNoticeOpen , setIsInfoNoticeOpen] = useState(false);
  

  // [hm] 단독 상품 반려 모달 : 2023.09.19
  const [rejectIsOpen, setRejectIsOpen] = useState(false);
  const [rejectMessage, setRejectMessage] = useState(''); // 거절사유 
  const handleTextareaChange = (event) => {
    setRejectMessage(event.target.value);
  };
  const handleRejectClick = (id, message) => {
    setProductState(id, "reject", message)
      .then(res => {
        console.log(res?.data);
        alert('입점이 거절되었습니다.');
      })
      .catch(error => alert("반려 실패 : ",error?.response?.data))
      .finally(()=>window.location.reload())
  }


  useEffect(() => {
    async function fetchProductDetails() {
      try {
        const details = await fetchNewProductDetailsById(product?.id);
        setProductDetails(details);
      } catch (error) {
        console.error('제품 세부 정보를 가져오는 중 오류가 발생했습니다:', error);
      }
    }

    fetchProductDetails();
  }, [product?.id]);

  if (!productDetails) {
    return <div>Loading...</div>;
  }

  // console.log(productDetails.processedfood_info);
  // console.log(productDetails.aquatic_info);

  // console.log(productDetails);

  // vendor 검색 페이지 이동
  const handleCellClick = (username) => {
    const searchQuery = encodeURIComponent(username);
    const newUrl = `/admin/store/inquiry/?search=${searchQuery}`;
    navigate(newUrl);
  };


  const openModal = () => {
    setIsOpen(true);
  };

  
  const openInfoNoticeModal = () => {
    setIsInfoNoticeOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // 날짜변환
  const dateFormat = (date) => {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const hours = formattedDate.getHours().toString().padStart(2, '0');
    const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
};

  return (
    <React.Fragment key={product.id}>
      <tr>
        <td>
          <button
            className='expand-btn'
            onClick={() => handleExpandClick(product?.id)}
          >
            <i
              className={
                expandedIds.includes(product?.id) ? 'xi-angle-up' : 'xi-angle-down'
              }
            ></i>
          </button>
        </td>
        {/* <td>
          <input 
            type="checkbox" 
            onClick={() =>
              {
                setSelectedRows({
                  ...selectedRows,
                  [product?.id]: !selectedRows[product?.id]
                })
                setSelectedRowsData({
                  ...selectedRowsData,
                  [product?.id]: {
                    vendor_name: product?.vendor?.name,
                    product_img: productDetails?.main_image,
                    product_name: product?.name,
                    product_id: product?.product_id,
                  }
                })
              }
            }
          />
        </td> */}
        <td>  
          <span
            className={`statusBadge ${
              product?.approve_status === "승인대기" ? "request" : "modify"
            }`}
          >
          {product?.approve_status}
          </span>
        </td>

        <td className='blueLink' onClick={() => handleCellClick(product?.vendor?.name)}>
          {product?.vendor?.name}
        </td>
        <td className='t-product-image'>
          <img src={productDetails?.main_image} alt="Product" />
        </td>
        <td>
          {product?.name} <br/>
          {product?.product_id}
        </td>
        <td>{product?.category?.[0]?.name} / {product?.category?.[1]?.name} / {product?.category?.[2]?.name}</td>
        <td>  
          {product?.option_rows.length > 0 ? 
            product?.option_rows?.map(x => x.name).join('\n') : "단일상품"}
        </td>
        <td>
          <span className="text-grey">
            {product?.original_price.toLocaleString()}
          </span>
        </td>
        <td>{product?.price.toLocaleString()}</td>
        <td>
          <button className='t-s-btn' onClick={openModal}>상세설명</button> 
        </td>
        <td>{productDetails?.inspect_info?.manufacturer}</td>
        <td>{productDetails?.inspect_info?.max_purchase_per_person == 99999 ?
            '제한없음' : productDetails?.inspect_info?.max_purchase_per_person.toLocaleString()}
        </td>
        <td>
          {dateFormat(productDetails?.inspect_info?.sales_start_date)}<br/>
          {dateFormat(productDetails?.inspect_info?.sales_end_date)}
        </td>
        <td>{productDetails?.inspect_info?.tax}</td>
        <td>{productDetails?.meta_tag}</td>
        <td>
          <button className='t-s-btn'>자세히</button> 
        </td>
        <td>  
          <button className='t-s-btn' onClick={openInfoNoticeModal}>자세히</button> 
        </td>
        {/* <td>{productDetails.vendor.name} </td>
        <td>{productDetails.inspect_info.tax} </td> */}
        <td>{productDetails?.delivery_info?.island_delivery_available ? '가능' : '불가'}</td>
        <td>{productDetails?.delivery_info?.courier}</td>
        <td>{productDetails?.delivery_info?.shipping_method}</td>
        <td>{productDetails?.delivery_info?.shipping_cost_type}</td>
        <td>{productDetails?.delivery_info?.days_required_for_shipping}</td>
        <td>({productDetails?.return_exchange_info?.return_address?.postal_code}) {productDetails?.return_exchange_info?.return_address?.address}, {productDetails?.return_exchange_info?.return_address?.detail}</td>
        {/* 금액 StringType으로 나오면 오류남 */}
        <td>{productDetails?.return_exchange_info?.initial_delivery_fee.toLocaleString()}</td>
        <td>{productDetails?.return_exchange_info?.return_delivery_fee.toLocaleString()}</td>
        <td>
          {productDetails?.files?.length > 0 ? 
            productDetails.files.map((x, index) => (
            <span key={index} className='pointer' onClick={() => window.open(x.path)}>
              <img src={x.path} width="80px" alt={`Image ${index + 1}`} />
            </span>
            )) 
            : 
            '없음'
          } 
        </td>
        <td>
          {productDetails?.sub_images?.length > 0 ? 
            productDetails?.sub_images.map(x => <img src={x.path} width="80px"/> ) : '없음'
          }
        </td>
        <td>{productDetails?.inspect_info?.certification_target}</td>
        <td>{productDetails?.inspect_info?.minor_purchase ? "구매가능" : "구매불가"}</td>
        <td>
          {/* [hm] 상품 개별 승인/거절 기능 추가 : 202.09.18 */}
          <button className='t-s-btn me-2'
            onClick={() => {
              setSelectedRows({[product.id]: true});
              setApproveProduct(true);
            }}
          >승인</button> 
          <button className='t-s-btn' onClick={()=>setRejectIsOpen(true)}>반려</button> 
        </td>
       
        {/* 상세설명 모달창 */}
        <DetailModal show={isOpen} setShow={setIsOpen} tag={productDetails?.detail}/>

        {/* 상품정보제공고시 모달창 */}
        <InfoNoticeModal 
          show={isInfoNoticeOpen} 
          setShow={setIsInfoNoticeOpen} 
          productDetails={productDetails}          
          />

      </tr>
      {expandedIds.includes(product?.id) && (
        <tr className='sub-table'>
          <td colSpan={13}>
            <table>
              <thead>
                <tr>
                  <th>상태</th>
                  <th>옵션아이디</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>재고수량</th>
                </tr>
              </thead>
              <tbody>
                {/* sub-table 내용 */}
                {product.option_rows.map((optionRow, index) => (
                  <tr key={index}>
                    <td></td>
                    <td>{optionRow?.id}</td>
                    <td>{optionRow?.name}</td>
                    {/* {optionRow.option_value.map((optionValue) => (
                      <td key={optionValue.id}>{optionValue.name}</td>
                    ))} */}
                    <td>{optionRow?.original_price.toLocaleString()}</td>
                    <td>{optionRow?.price.toLocaleString()}</td>
                    <td>{optionRow?.stock.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
      {/* 반려 모달 추가 */}
      <RejectModal rejectIsOpen={rejectIsOpen} setRejectIsOpen={setRejectIsOpen} rejectMessage={rejectMessage} handleTextareaChange={handleTextareaChange} handleRejectClick={handleRejectClick} id={product?.id}/>
  </React.Fragment>
  );
};

function DetailModal({show, setShow, tag}){
  return(
    <>
    <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            상세페이지 미리보기
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='detailModal-wrap'>
              <div className='editor-content'>
                <div dangerouslySetInnerHTML={{ __html: tag }}/>
              </div>
            </div>
        </Modal.Body>
    </Modal>
  </>
  )
}

// [hm] 상품반려용 모달 컴포넌트 : 2023.09.19
function RejectModal({rejectIsOpen, setRejectIsOpen, rejectMessage, handleTextareaChange, handleRejectClick, id}){
  return(
    <>
    <Modal
        size="lg"
        show={rejectIsOpen}
        onHide={() => setRejectIsOpen(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          반려사유 입력
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <textarea cols="30" rows="10" value={rejectMessage} onChange={handleTextareaChange} />
        <div className='modal-button-wrap'>
          <button className='m-btn m-btn-search'
                onClick={() => {
                  handleRejectClick(id, rejectMessage);
                }}>
                완료</button>
        </div>
        </Modal.Body>
    </Modal>
  </>
  )
}