/***************************************************************************************
* FileName      : Payment.js
* Description   : 해와관리자 > 정산 > 지급내역
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import SelectVendor from '../product/Component/SelectVendor';
import React, { useState, useEffect } from 'react';
import SortComponent from '../common/SortComponent2';
import PageSizeSelect from '../common/PageSizeSelect';
import AddModal from './Payment/AddModal';
import { useAdjustPaymentQuery , handleAdjustPaymentSearch } from '../../../api/hwadmin/adjustApi';
import AdjustRow from './Payment/AdjustRow';
import Pagination from '../common/Pagination'
function Payment()
{
    const [ordering, setOrdering] = useState('created_at');
    const [pageSize, setPageSize] = useState(10); 
   
    const { data, error, isFetching, page, setPage } = useAdjustPaymentQuery(ordering,pageSize);
     
    // 선택한 수산상회 
    const [selectedVendor, setSelectedVendor] = useState({ id: null, name: "" });

    const [searchResults, setSearchResults] = useState([]);
    const [searchUrl, setSearchUrl] = useState('');

    // 검색중 인지 확인
    const [searchConfirm, setSearchConfirm] = useState(false);
        

    //날짜 시작일
    const [selectedStartDate, setSelectedStartDate] = useState('');
        
    //날짜 종료일
    const [selectedEndDate, setSelectedEndDate] = useState('');
    
    //
    const [isOpen, setIsOpen] = useState(false);


    // 날짜 값 변화
    const handleStartDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };
    
    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };
    
    // 가입순 정렬 데이터 변화
    const handleOrderingChange = (event) => {
        setOrdering(event.target.value);
    };
    
    // 10개씩 50개씩 100개씩
    const handlePageSizeChange = (event) => {
        console.log(event.target.value)
        setPageSize(parseInt(event.target.value));
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const onSearch = () => {
        handleAdjustPaymentSearch({
            setSearchResults,
            setSearchUrl,
            selectedStartDate,
            selectedEndDate, 
            selectedVendor
        });

        setSearchConfirm(true);
    };

    // 초기화
    const handleResetClick = () => {
        window.location.href = '/admin/settle/payment'; 
    };


    console.log(data);
    console.log("--data")

    return(
        <>
        <div className='mainContents'>
            <div className='page-tit'>
                <h2>지급내역</h2>
            </div>
            {/* 검색 */}
            <section className='McontentBox m-p0'>
                <div className='search-wrap'>     
                    <div className='search-field date'>
                        <div> 
                            <b>정산일</b> 
                        </div>
                        <div>
                            <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
                            <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />   
                        </div>   
                    </div>
                    <div className='search-field division'>
                        <div>
                            <b>수산상회</b> 
                        </div>
                        <div>
                            <SelectVendor 
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                />  
                        </div>   
                    </div>
                
                </div>
                <div className='m-btn-wrap'>
                    <button className='m-btn' onClick={()=>handleResetClick()}>초기화</button>
                    <button className='m-btn m-btn-search' onClick={()=>onSearch()}>검색</button>
                </div>
            </section>

            {/* 검색 결과 */}
            <section className='McontentBox'>
                <div className='subTitle'>
                    <div className='selectWrap'>
                        <span>검색결과</span>
                        <span className='result-wrap'>
                            {searchConfirm && searchResults.totalCnt > 0 ? (
                                <>
                                    <p>(총 <b className='sum-s-b'>{searchResults.totalCnt}</b>개)</p>
                                </>
                                ) : (
                                <>
                                    <p>(총 <b className='sum-s-b'>{data?.totalCnt ? data?.totalCnt : 0}</b>개)</p>
                                </>
                            )}
                        </span>
                    </div>
                    { !searchConfirm && 
                    <div className='selectWrap'>
                        <SortComponent
                            ordering={ordering}
                            handleOrderingChange={handleOrderingChange}
                            newLabel="최근정산일순"
                            oldLabel="오래된정산일순"
                            />
    
                        <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                    </div>
                    }
                </div>
                <div className='m-btn-wrap-sm'>
                    <Button className='startbtn' variant="primary" onClick={openModal}>지급내역추가</Button>

                    <button className='m-sm-btn'>다운로드</button>
                </div>

                <div className='fixTable_wrap'>
                    <table className='sTable' id='scrollTable'>
                        <colgroup>

                        </colgroup>
                        <thead>
                            <tr>
                                <th>정산일</th>
                                <th>수산상회</th>
                                <th>
                                    정산금액<br/>
                                    (결제금액 - 수수료)
                                </th>
                                <th>결제금액</th>
                                <th>수수료(9.9%)</th>
                                <th>지급계좌</th>
                                <th>지급상태</th>
                            </tr>                        
                        </thead>
                        <tbody>
                            {searchConfirm && searchResults?.count === 0 ? (
                                <tr>
                                    <td colSpan="17">검색 결과가 없습니다.</td>
                                </tr>
                            ) : searchResults?.count > 0 ? (
                                searchResults?.data.map((Adjust , index) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            ) : data && data?.data && data?.data.length > 0 ? (
                                data?.data.map((Adjust , index ) => (
                                    <AdjustRow 
                                        index={index} 
                                        Adjust={Adjust}/>
                                
                                ))
                            ) : null}
                        </tbody>
                    </table>
                </div>
            </section>

            {/* 페이지내비게이션 */}
            <section>
                {searchConfirm && searchResults?.count === 0 ? (
                    <>
                    </>
                ) : searchResults?.count > 0 ? (
                        <Pagination
                            count={searchResults?.count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                    />
                ) : (
                    <Pagination
                        count={data?.totalCnt || 0}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                    />
                )}
            </section>
            </div>

            <AddModal show={isOpen} setShow={setIsOpen}/>

        </>
    )
}

export default Payment