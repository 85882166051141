/***************************************************************************************
* FileName      : NoticeRow.js
* Description   : 고객센터 > 공지사항 조회 ROW 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/

import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const NoticeRow  =  ({ index, notice }) => {

    const navigate = useNavigate();

    const handleOrderNumberClick = (NoticeId) => {
        navigate(`./${NoticeId}`);
    };

    return(
        <Row key={notice.id} 
            className="border-bottom bg-white m-0 p-0 py-2"
            onClick={() => handleOrderNumberClick(notice.id)}>

            <Col sm={2} md={1} className="text-center p-2 fs-7 fw-bold text-secondary d-none d-md-flex justify-content-center">
                {index} 
            </Col>

            <Col xs={8} md={8} className="text-start p-2 fs-7 fw-bold cursor-pointer">
                <span className="text-hw fw-bold">[{notice.type}] </span> {notice.title}
            </Col>

            <Col xs={3} md={2} className="text-center p-2 fs-7 fw-bold text-secondary">
                {notice.created_at.substring(0, 10)}
            </Col>

            <Col xs={1} md={1} className="text-center p-2 fs-7 fw-bold text-secondary">
                {notice.view_count}
            </Col>
        </Row>  
    )
}