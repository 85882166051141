import { instance } from '../../axiosInstance/axiosConfig';

// 해와관리자 > 통계 > 요약통계 > 방문자 
export const getVisit = () =>
    instance.get(`statistic/visit/`).then(response => response.data)

// 해와관리자 > 통계 > 요약통계 > 가입통계 
export const getSignup = () =>
instance.get(`statistic/signup/`).then(response => response.data)

// 해와관리자 > 통계 > 요약통계 > 판매통계 
export const getSales = () =>
instance.get(`statistic/sales/`).then(response => response.data)

// 해와관리자 > 통계 > 방문통계 > 월별 
export const getVisitMonthly = (year) =>
    instance.get(`statistic/visit-monthly/?year=${year}`).then(response => response.data)

// 해와관리자 > 통계 > 방문통계 > 일별
export const getVisitDaily = (year , month) =>
    instance.get(`statistic/visit-daily/?year=${year}&month=${month}`).then(response => response.data)

// 해와관리자 > 통계 > 방문통계 > 일별
export const getVisitHourly = (year , month, day) =>
    instance.get(`statistic/visit-hourly/?year=${year}&month=${month}&day=${day}`).then(response => response.data)


// 해와관리자 > 통계 > 방문통계 > 월별 검색
// export const handleSearchStatisticVisit = (year) =>
//     instance.get(`statistic/visit-monthly/?year=${year}`).then(response => response.data)


// 해와관리자 > 통계 > 가입통계 > 월별 > 연령별 검색
export const handleSearchSignupMonthly  = async ({
    setSearchResults,
    year, 
    selectedAges,  
    }) => {
    try {
        let url = `statistic/signup-monthly/?year=${year}`;
        if (selectedAges) {
            // age가 존재하면 &age=10,30과 같이 추가
            url += `&age=${selectedAges}`;
        }
    
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};
  


// 해와관리자 > 통계 > 가입통계 > 월별 
export const getSignupMonthly = (year) =>
    instance.get(`statistic/signup-monthly/?year=${year}`).then(response => response.data)

// 해와관리자 > 통계 > 가입통계 > 일별 
export const getSignupDaily = (year,month) =>
    instance.get(`statistic/signup-daily/?year=${year}&month=${month}`).then(response => response.data)


// 해와관리자 > 통계 > 가입통계 > 일별 > 연령별 검색
export const handleSearchSignupDaily  = async ({
    setSearchResults,
    year, 
    selectedAges,  
    }) => {
    try {
        let url = `statistic/signup-daily/?year=${year}`;
        if (selectedAges) {
            url += `&age=${selectedAges}`;
        }
    
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};
  
// 해와관리자 > 통계 > 가입통계 > 시간대별 
export const getSignupHourly = (year,searchMonth,searchDay) =>
    instance.get(`statistic/signup-hourly/?year=${year}&month=${searchMonth}&day=${searchDay}`)
    .then(response => response.data)


// 해와관리자 > 통계 > 가입통계 > 일별 > 시간대별 검색
export const handleSearchHourlyDaily  = async ({
    setSearchResults,
    year, 
    searchMonth,
    searchDay,
    selectedAges,  
    }) => {
    try {
        let url = `statistic/signup-hourly/?year=${year}`;
        if (selectedAges) {
            url += `&age=${selectedAges}`;
        }
        if(searchMonth && searchDay){   
            url += `&month=${searchMonth}&day=${searchDay}`
        }

        console.log(url)
        console.log('---url--')
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};


// 해와관리자 > 통계 > 판매통계 > 월별 
export const getSalesMonthly = (year) =>
instance.get(`statistic/sales-monthly/?year=${year}`).then(response => response.data)

// 해와관리자 > 통계 > 판매통계 > 월별 > 검색
export const handleSearchSalesMonthly  = async ({
    setSearchResults,
    year, 
    selectedAges,  
    }) => {
    try {
        let url = `statistic/sales-monthly/?year=${year}`;
        if (selectedAges) {
            // age가 존재하면 &age=10,30과 같이 추가
            url += `&age=${selectedAges}`;
        }
    
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 해와관리자 > 통계 > 판매통계 > 일별 
export const getSalesDaily = (year,month) =>
    instance.get(`statistic/sales-daily/?year=${year}&month=${month}`).then(response => response.data)
  
// 해와관리자 > 통계 > 판매통계 > 일별 > 검색
export const handleSearchSalesDaily  = async ({
    setSearchResults,
    year, 
    searchMonth,
    selectedAges,  
    }) => {
    try {
        let url = `statistic/sales-daily/?year=${year}&month=${searchMonth}`;
        if (selectedAges) {
            url += `&age=${selectedAges}`;
        }
    
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 해와관리자 > 통계 > 판매통계 > 시간대별 
export const getSalesHourly = (currentYear,currentMonth,currentDay) =>
    instance.get(`statistic/sales-hourly/?year=${currentYear}&month=${currentMonth}&day=${currentDay}`)
            .then(response => response.data)
  
// 해와관리자 > 통계 > 판매통계 > 일별 > 시간대별
export const handleSearchSalesHourly  = async ({
    setSearchResults,
    searchYear, 
    searchMonth,
    searchDay,
    selectedAges,  
    }) => {
    try {
        let url = `statistic/sales-hourly/?year=${searchYear}&month=${searchMonth}&day=${searchDay}`;

        if (selectedAges) {
            url += `&age=${selectedAges}`;
        }
    
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const responseData = JSON.parse(error.response.data);
            alert(responseData.data);
        } 
    }
};

// 해와관리자 > 통계 > 상품통계 > 판매자별 
export const getVendorSales = () =>
    instance.get(`statistic/vendor-sales/`).then(response => response.data)

// 해와관리자 > 통계 > 상품통계 > 판매자별 > 검색
export const handleSearchVendorSales  = async ({
    setSearchResults,
    selectedStartDate,
    selectedEndDate
    }) => {
    try {
        let url = `statistic/vendor-sales/`;
      
        if(selectedStartDate && selectedEndDate ){
            url += `?paid_at__range=${selectedStartDate}__${selectedEndDate}`;
        }
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};
  
// 해와관리자 > 통계 > 상품통계 > 카테고리별 
export const getCategorySales = () =>
    instance.get(`statistic/category-sales/`).then(response => response.data)


// 해와관리자 > 통계 > 상품통계 > 카테고리별 > 검색
export const handleSearchCategorySales   = async ({
    setSearchResults,
    selectedStartDate,
    selectedEndDate
    }) => {
    try {
        let url = `statistic/category-sales/`;
      
        if(selectedStartDate && selectedEndDate ){
            url += `?paid_at__range=${selectedStartDate}__${selectedEndDate}`;
        }
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);
        return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 해와관리자 > 통계 > 검색통계  
export const getStatisticSearch = () =>
    instance.get(`statistic/search/`).then(response => response.data)

// 해와관리자 > 통계 > 검색통계
export const handleSearchStatisticSearch   = async ({
    setSearchResults,
    selectedStartDate,
    selectedEndDate
    }) => {
    try {
        let url = `statistic/search/`;
      
        if(selectedStartDate && selectedEndDate ){
            url += `?created_at__range=${selectedStartDate}__${selectedEndDate}`;
        }
        console.log(url)
    
        const response = await instance.get(url);
        setSearchResults(response.data);

        return response.data;
    } catch (error) {
      console.error(error);
    }
};
