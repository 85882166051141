import { instance } from "../axiosInstance/axiosConfig";

// export const getProductList = () => {
//     return instance.get(`/product/document/`)
// }

export const getUserProductList = () => {
    return instance.get(`/product/customer/document/?page_size=12`)
}

// 제철상품 리스트 [pkh]
export const getUserSeasonalProductList = () => {
    return instance.get('/ad/seasonal/product/?page_size=all')
}

// [hm] 제철 카테고리 리스트
export const getSeasonalCategory = () => {
    return instance.get(`/ad/seasonal/`)
}

// [hm] 승인된 입점사 조회
export const getSellerList = () => {
    return instance.get(`/vendor/customer`)
}

// [hm] 고객 리뷰 조회
export const getReviewList = () => {
    return instance.get('/product/customer/review-document')
}

// 상품 좋아요 (고객용)
export const toggleProductLike = (data) =>
  instance.post(`/like/product/`, data).then(response => response.data);

// 상회 좋아요 (고객용)
export const toggleVendorLike = (data) =>
instance.post(`/like/vendor/`, data).then(response => response.data);
