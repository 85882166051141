import { instance } from '../axiosInstance/axiosConfig';

// [hm] 판매/배송 조회
export const getSalesDelivery = () => instance.get(`/statistic/sales-delivery/`);

// [hm] 오늘 할 일 조회
export const getTodos = () => instance.get(`/statistic/todos/`);

// [hm] 상품통계 조회
export const getProduct = () => instance.get(`/statistic/product/`);

// [hm] 매장통계 조회
export const getVendor = () => instance.get(`/statistic/vendor/`);

// [hm] 신규등록 조회
export const getNews = () => instance.get(`/statistic/news/`);

// [hm] 미답변 문의 조회
export const getInquiry = () => instance.get(`/statistic/inquiry/`);

// [hm] 판매현황 조회
export const getSales = () => instance.get(`/statistic/sale/`);