/***************************************************************************************
* FileName      : Reservation.js
* Description   : 해와관리자 > 알림 > 알림톡 발송관리 > 예약목록 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.02.27
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import PageSizeSelect from "../../common/PageSizeSelect"
import { useNotificationTalkReservationQuery , deleteNotificationTalkReservation } from '../../../../api/hwadmin/notificationApi';
import Pagination from '../../common/Pagination';

function Reservation()
{

    const [pageSize, setPageSize] = useState(10); 
    const { data, error, isFetching, page, setPage } = useNotificationTalkReservationQuery(pageSize);


    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
    }

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
  
    const handleCancelClick = (id) => {
        let deleteMessage = prompt('예약을 취소하시겠습니까?\n"예약을 취소합니다"를 입력해주세요.');
        if(deleteMessage == "예약을 취소합니다"){
            deleteNotificationTalkReservation(id)
            .then(res => {
            console.log(res.data);
            alert("예약이 취소되었습니다.");
            window.location.href = '/admin/alarm/alimTalkSendingHistory'; 
          })
          .catch(error => alert(error.message))
        }
    }

    return (
        <>
        {/* 검색 결과 */}
        <section className='McontentBox'>
            <div className='subTitle'>
                <div className=''>
                    <span>검색결과</span>
                    <span className='result-wrap'>(총 <b className='sum-s-b'>{data?.list?.length}</b>개)</span>
                </div>
                
                <div className='selectWrap'>
                    <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
                </div>
            </div>

            <div className='fixTable_wrap'>
                <table className='sTable' id='scrollTable'>
                    <colgroup>

                    </colgroup>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>메시지 상태</th>
                            <th>내용</th>
                            <th>전송요청 수</th>
                            <th>등록일시</th>
                            <th>발송(예약)일</th>
                            <th>예약취소</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.list.map((item, index) => (
                            <tr key={index}>
                                <td>{item.mid}</td>
                                <td>{item.reserve_state}</td>
                                <td>{item.mbody}</td>
                                <td>{item.msg_count}</td>
                                <td>{item.regdate}</td>
                                <td>{item.regdate}</td>
                                <td>       
                                    {item.reserve_state === "전송대기중" && 
                                        <button 
                                            className='t-s-btn'  
                                            onClick={() => handleCancelClick(item.mid)}>
                                            예약취소
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))} 
                    </tbody>
                </table>
            </div>
        </section>
  
        <section>
            {data && data.list.length > 0 && (
                <Pagination
                    count={data?.totalCnt || 0}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    />
            )}
        </section>
    </>
    )
}

export default Reservation