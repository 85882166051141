import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useProductInquiryListQuery } from '../../../api/product/product';

import Pagination from '../common/Pagination'

export default function ProductInquiry({id})
{
    const { data, error, isFetching, page, setPage , pageSize , setPageSize} = useProductInquiryListQuery(id);

    const navigate = useNavigate();

    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (index) => {
      const newExpandedRows = [...expandedRows];
      if (newExpandedRows.includes(index)) {
        newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
      } else {
        newExpandedRows.push(index);
      }
      setExpandedRows(newExpandedRows);
    };

    const handleWriteBtnClick = (id) => {
        const Url = `/customer/qna/write/${id}`;
        window.location.href = Url;
    };


    const handleQnaBtnClick = () => {
        navigate(`/customer/qna/`);
    };

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    console.log(data)

    return (
        <>
        <div className='ec-base-tab'>
            <h3>
                Q&A 
                <span>{data?.totalCnt}</span>
            </h3>
            <p className='notice-p-txt'>구매하시려는 상품에 대해 궁금한 점이 있으신 경우 문의해주세요.</p>
            <section className="d-flex gap-1">
                <button className='btnSubmitFix m' onClick={() => handleQnaBtnClick()}>전체보기</button>
                <button className='btnSubmitFix m' onClick={() => handleWriteBtnClick(id)}>문의하기</button>
                <button className='btnSubmitFix' onClick={() => handleQnaBtnClick()}>나의 Q&A</button>
            </section>

        </div>
        
        
        {data && data.data && data.data.length > 0 ? (
            <div className='inquiry-table-box'>
                <table className='ec-table'>
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '60%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <td>답변상태</td>
                            <td>제목</td>
                            <td>작성자</td>
                            <td>작성일</td>
                        </tr>
                    </thead>

                    <tbody>
                    {data.data.map((productInquiry, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{productInquiry.status}</td>
                                <td className='tit-box' onClick={() => toggleRow(index)}>
                                    {productInquiry.title}
                                </td>
                                <td>
                                    <span>{productInquiry.user.username}</span>
                                </td>
                                        
                                <td><span>{productInquiry.updated_at.split("T")[0]}</span></td> 
                            </tr>

                            {expandedRows.includes(index) && productInquiry.content && (
                            <tr> 
                                <td className='p-0 inquiry-table-question-detail' colSpan="4" >
                                    <div className='detail-view board-detail editor-content' dangerouslySetInnerHTML={{ __html: productInquiry.content }}/>
                                    
                                    {productInquiry.answer && (
                                        <div className='view-answer'>
                                            <span>답변</span>
                                            <p dangerouslySetInnerHTML={{ __html: productInquiry.answer }}></p>
                                            <p style={{ marginTop: '10px' }}>{productInquiry.answered_at.split("T")[0]}</p>
                                        </div>
                                    )}
                                </td>
                            </tr>
                            )}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <p className="fs-6 fw-bold text-center mb-5">상품문의 데이터가 없습니다.</p>
        )}

        <section className='py-5'>
            <Pagination
                count={data?.totalCnt || 0}
                page={page}
                setPage={setPage}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                />
        </section>
        

        </>


    )
   

}

