import { useEffect, useState, useRef } from 'react';
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import { getClientKey } from '../../api/myOrderListApi';

const clientKey = "test_ck_7DLJOpm5QrlvbdXXY0A3PNdxbWnY";
// const clientKey = "live_ck_jkYG57Eba3GLkbZEqjjVpWDOxmA1";

export default function Payment({data, getPrice}) {
	const [customerKey, setCustomerKey] = useState('test'); // 커스터머 키 회원별로 무작위 고유값으로 업데이트해줘야함
	const paymentWidgetRef = useRef(null);
  	const price = getPrice();
	
	useEffect(()=>{
		getClientKey()
		.then(res => {
			setCustomerKey(res?.data?.customer_key);
			console.log('ck : ', res?.data?.customer_key);
		})
		.catch(error => alert(error?.response?.data?.data))
	}, [customerKey === 'test'])
	useEffect(() => {
		(async () => {
		const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

		paymentWidget.renderPaymentMethods("#payment-widget", price);
		paymentWidgetRef.current = paymentWidget;
		})()
  	}, [price])

  return (
	<>
		<div id="payment-widget" className="m-0 p-0"/>
		<button
			className="btn btn-hw"
			onClick={async() => {
				const paymentWidget = paymentWidgetRef.current;

				try {
					await paymentWidget?.requestPayment({
						orderId: data?.orderId, // 주문ID(6~64글자 고유값)
						orderName: data?.orderName, // 주문명(ex. 생수 외 1건)
						customerName: data?.user,
						customerEmail: data?.email,
						successUrl: `${window.location.origin}/order/success`,
						failUrl: `${window.location.origin}/order/fail`,
						useEscrow: true, // 에스크로 사용할지 여부(없으면 고객이 선택)
						escrowProducts: data?.escrowProducts,
						taxFreeAmount: data?.taxFreeAmount, // 면세금액
						customerMobilePhone: data?.phone,
					}) 
				} catch (err) {
					alert(err);
					console.log(err)
				}
			}}
		>
			결제하기
		</button>
	</>
  );
}
