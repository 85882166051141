import React, { useState , useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSubCategories } from '../../../../../api/Category'
import SubSubCategories from './SubSubCategories';


function SubCategories({ categoryId }) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data: subCategoriesData } = useSubCategories(categoryId);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching main categories.</div>;
  }
  function handleSubCategoryClick(id,name) 
  {
    queryClient.setQueryData('selectedId', id);
    queryClient.setQueryData('selectedName', name);

    queryClient.setQueryData('selectedSubcategory', name);
    queryClient.setQueryData('selectedSubSubcategory', '');
  
    queryClient.setQueryData('selectedSubIdcategory', id);
    queryClient.setQueryData('selectedSubSubIdcategory', '');


    setSelectedCategoryId(id);
  }

  console.log(subCategoriesData)
  return (
    <>
      <div className='categbox'>
        <p className='ctit'>중분류</p>
        <div type="중분류" className='kwutOX'>
          <ul>
            {subCategoriesData.data && subCategoriesData.data.map((category) => (
              <li className='fBSFej' key={category.id}>
                <button 
                  onClick={() => handleSubCategoryClick(category.id,category.name)}
                  style={{ color: selectedCategoryId === category.id ? '#0073e9' : 'rgb(0, 0, 0)' }}
                  >{category.name}</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {selectedCategoryId && (
        <SubSubCategories 
          categoryId={selectedCategoryId} 
          key={selectedCategoryId} />
      )}
    </>
  );
}

export default SubCategories;