/***************************************************************************************
* FileName      : CouponHistoryRow.js
* Description   : 해와관리자 > 마케팅 > 할인쿠폰 발급/사용내역 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.01.24
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import { extractYearMonthDayHourMinute2 } from "../../../mypage/Component/extractDateUtils"

export default function CouponHistoryRow({coupons}){
    return(
        <tr>
            <td><input type="checkbox" name="" value="" /></td>
            <td>회원</td>
            <td>{coupons?.name}</td>
            <td>
                {coupons?.use_condition.toLocaleString()}원 이상 구매시 <br/>
                {coupons?.discount_type === "할인금액형" ? 
                <>{coupons?.discount_amount.toLocaleString()}원 할인</>
                :<>{coupons?.discount_rate}% 할인 (최대 {coupons?.limit_discount_amount.toLocaleString()}원)</>
                }
            </td>
            <td>{coupons?.vendor_name ? coupons?.vendor_name  : <>제한없음</>}</td>
        </tr>
    )
}

