/***************************************************************************************
* FileName      : ProductRow.js
* Description   : 셀러관리자 > 상품관리 > 요청목록 ProductRow 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.00.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { getProductRequestDetail , } from '../../../../api/selleradmin/productApi.js';

export const ProductRow = ({ product }) => {

    const [isOpen, setIsOpen] = useState(false);


    if (!product) {
      return <div>Loading...</div>;
    }

    const openModal = () => {
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
    };


    return (
    <tr>
        <td><input type="checkbox" name="Allcheck" value=""/></td>
        <td>
            <span className='statusBadge finish'>{product?.approve_status}</span>
        </td>
        <td>
            <img src={product?.main_image} width="80px"/>
        </td>
        <td>{product?.name}</td>
        <td>
          {product?.category.length !== 0 && (
            <>
              {product?.category[0]?.name}/{product?.category[1]?.name}/{product?.category[2]?.name}
            </>
          )}
        </td>
        <td>
            {product?.option_rows.length !== 0 ? '0개' : product?.option_row}
        </td>
        <td>
          {typeof product?.original_price === 'number' ? product.original_price.toLocaleString() : product.original_price}원
        </td>
        <td>
          {typeof product?.price === 'number' ? product.price.toLocaleString() : product.price}원
        </td>

        <td>
            <button className='m-sm-btn' onClick={openModal}>상세설명</button>
        </td>
      <td>{product?.inspect_info?.manufacturer}</td>
      <td>{product?.inspect_info?.max_purchase_per_person === 99999 ? "설정안함" : product?.inspect_info?.max_purchase_per_person}</td>
      <td>{product?.inspect_info?.sales_start_date}<br/>{product?.inspect_info?.sales_end_date}</td>
      <td>{product?.inspect_info?.tax}</td>
     
      <td>
      {product?.product_filter?.length !== 0 && product?.product_filter !== undefined && (
        <>
          {product?.product_filter.map((tag, index) => (
            <React.Fragment key={index}>
              {tag.name}/
            </React.Fragment>
          ))}
        </>
      )}
      </td>

      <td>{product?.meta_tag}</td>
      <td><button className='m-sm-btn' onClick={()=>alert("현재 사용 불가능한 기능입니다.")}>자세히</button></td>
      <td>{product?.delivery_info?.island_delivery_available ? "배송 가능" : "배송 불가"}</td>
      <td>{product?.delivery_info?.courier}</td>
      <td>{product?.delivery_info?.shipping_method}</td>
      <td>{product?.delivery_info?.shipping_cost_type}</td>
      <td>{product?.delivery_info?.days_required_for_shipping}</td>
      <td>({product?.delivery_info?.dispatch_location?.postal_code}) {product?.delivery_info?.dispatch_location?.address}, {product?.delivery_info?.dispatch_location?.detail}</td>
      <td>({product?.return_exchange_info?.return_address?.postal_code}) {product?.return_exchange_info?.return_address?.address}, {product?.return_exchange_info?.return_address?.detail}</td>
      <td>{product?.return_exchange_info?.initial_delivery_fee}</td>
      <td>{product?.return_exchange_info?.return_delivery_fee}</td>
      <td>구비서류</td>
      <td>추가이미지</td>
      <td>
          <button className='m-sm-btn' onClick={()=>alert("현재 사용 불가능한 기능입니다.")}>자세히</button>
      </td>
      <td>{product?.inspect_info?.rminor_purchase ? "구매가능" : "구매불가"}</td>
      <DetailModal show={isOpen} setShow={setIsOpen} tag={product?.detail}/> 
</tr>
  );
};


function DetailModal({show, setShow, tag}){
  return(
    <>
    <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            상세페이지 미리보기
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div width="90%">
              <div className='editor-content'>
                <div dangerouslySetInnerHTML={{ __html: tag }}/>
              </div>
            </div>
        </Modal.Body>
    </Modal>
  </>
  )
}
