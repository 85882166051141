import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
// import Pagination from './common/Pagination.js'

import { getVendorApproved } from '../../api/product/vendors.js';


export default function List()
{
    const [vendors, setVendors] = useState([]);
    const [vendorCounter, setVendorCounter] = useState(0);
    
    useEffect(()=>{
        getVendorApproved()
            .then(res => {
                setVendors(res?.data?.data);
                setVendorCounter(res?.data?.totalCnt);
            })
            .catch(error => alert(error?.response?.data))
    }, [])

    return(
        <>
        <Container className='p-3'>
            <div className='product-headcategory'>
                {/* <ol>
                    <li><a>홈</a></li>
                    <li><a>상품리스트</a></li>

                </ol> */}
            </div>
            <Row>
                <Col xs={12} md={12}>
                    <div className='page-tit text-center'>
                        <h2>수산상회</h2>
                    </div>
                </Col>

                
                <Col xs={12} md={12}>
                    <span className='pick_list_num'>
                        <strong>{vendorCounter}개</strong> 수산상회가 있습니다.
                    </span>
                    <div className='product_ListMenu'>
                    </div>
                </Col>
                
                { vendors.length === 0 ? (
                    <div className='goods_no_data'>
                        <strong>입점상회가 존재하지 않습니다</strong>
                    </div>
                    ) : (

                    <div>
                        <ul>
                            <Row className='prdList'>
                            {
                                vendors.map((product) => (
                                    <Col xs={6} sm={4} md={3}>
                                        <SellerCard data={product}/>
                                    </Col>
                                ))
                            } 
                            </Row>
                        </ul>
                       
                    </div>
                )}
            </Row>

        </Container>
        </>
    )
}

function SellerCard({data}){
    let locationTarget = `/product/vendor/items?name=${data?.name}&id=${data?.id}`
    return(
        <div className="item p-2 pb-2">
            <div className="rounded-1 bg-white overflow-hidden shadow-sm">
                <div className='thumb'>
                    <a href={locationTarget}> <img src={data?.owner_image}/> </a>
                    {/* <LikeButton />  */}
                </div>
                <div className='prd-info text-center mt-3 pb-3'>
                    <div className='prd-name-wrap'><span className='prd-name fs-6'>{data?.name}</span></div>
                    <p className="my-3 mx-4 border text-secondary fs-7 pointer" onClick={()=>window.location.href=locationTarget}>제품 보러가기 › </p>
                </div>
            </div>
        </div>
      )
}
