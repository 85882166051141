import {Row, Col, Button , Navbar, Nav, Container, Tab , InputGroup, Form, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidemenu from '../Sidemenu.js'

import { fetchOnetooneDetails } from '../../../api/services/customerApi.js';
export default function OtoView()
{
    const { id } = useParams();
    const [otoDetails, setOtoDetails] = useState(null);

    useEffect(() => {
        fetchOnetooneDetails(id)
          .then(details => {
            setOtoDetails(details);
          })
          .catch(error => {
            console.error('상세 조회 오류:', error);
          });
    }, [id]);

    useEffect(()=>{
        console.log(otoDetails);
    },[otoDetails])
    return(
        <>
         <Container>
            <Row className='m-0'>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'oto'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <Qna otoDetails={otoDetails} />
                    </Col>
                </div>
            </Row>
        </Container>
        </>
    )

}

function Qna({ otoDetails })
{
    const navigate = useNavigate();
    const datePart = otoDetails?.created_at.split('T')[0];

    return (
        <>
         <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-4 fw-bold">1:1 문의</h3>
                </Col>
            </Row>

             {/* 콘텐츠 내용 */}
             <div className='board-wrap'>
                <div className='board-tit'>
                    <h2>{otoDetails?.title}</h2>
                </div>
                <div className='board-sub'>
                    <span>{otoDetails?.user.username}</span>
                    <span>{datePart}</span>
                    <span className='hit'>조회수 {otoDetails?.view_count}</span>
                </div>
                <div className='board-detail editor-content'>
                    <div dangerouslySetInnerHTML={{ __html: otoDetails?.content }} />    
                </div>
                <div className='board-btns'>
                    <button className='btnNormalFix' onClick={()=>navigate('/customer/oto')} >목록</button>
                </div>
            </div>
        </div>
        
        </>
    )
}