import React, { useState, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown, Badge } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getBanner, setBannerOrder, uploadImage, createBanner, setBanner, deleteBanner } from "../../../../api/bannerApi";


function BannerManager({type}) {

  const [uploadImg, setUploadImg] = useState();
  const [uploadUrl, setUploadUrl] = useState();
  const [initialBanners, setInitialBanners] = useState([]);

  useEffect(()=>{
    getBanner(type)
      .then(res=>setInitialBanners(res?.data?.data))
      .catch(error=>console.log(error?.response?.data))

    console.log(initialBanners)
  },[])


    // 배너 비공개용 함수
    const disableBanner = (id, state) => {
      setBanner(id, state)
        .then(res => {
          console.log(res.data);
          // 배너 상태 업데이트
          const updatedBanners = banners.map(banner => {
            if (banner.id === id) {
              return { ...banner, hidden: state };
            }
            return banner;
          });
          setBanners(updatedBanners);
        })
        .catch(error => console.log(error.response.data))
    }

    // 배너 삭제용 함수
    const deleteThisBanner = id =>{
      deleteBanner(id, type)
        .then(()=>window.location.reload())
        .catch(error => console.log(error.response.data))
    }
    
    const [banners, setBanners] = useState(initialBanners);
    
    useEffect(()=>{
      setBanners(initialBanners)
    },[initialBanners])
  
    // 배너 순서 변경 함수
    const handleDragEnd = (result) => {
      if (!result.destination) return;
  
      const reorderedBanners = Array.from(banners);
      const [movedBanner] = reorderedBanners.splice(result.source.index, 1);
      reorderedBanners.splice(result.destination.index, 0, movedBanner);
  
      setBanners(reorderedBanners);
      setBannerOrder((reorderedBanners.map(x => x.id)).join(","), type)
        .then(res=> console.log(res.data))
        .catch(error=> console.log(error.rsponse.data))
    };
  
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Row>
          <Col xs={6} className="p-2">
            <p className="text-center fs-6 mb-2">배너 설정</p>
            <Droppable droppableId="banners" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {banners.map((banner, index) => (
                    <Draggable key={banner.id} draggableId={String(banner.id)}  index={index}>
                      {(provided) => (
                        <div
                          className="border rounded-2 p-2 mb-3 bg-white"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                                {banner.hidden ? <Badge bg="secondary" className="me-2 rounded-1">비공개</Badge> : <Badge bg="primary" className="me-2 rounded-1">공개</Badge>}
                                {banner.link}
                            </div>
                            <div>
                                <DropdownButton variant="white" drop="down" alignRight className="custom-dropdown" title={<BsThreeDotsVertical />}>
                                    <Dropdown.Item eventKey="1" onClick={() => disableBanner(banner.id, !banner.hidden)}>{banner.hidden ? "공개" : "비공개" }</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item eventKey="2" onClick={() => deleteThisBanner(banner.id)} className="text-danger">삭제</Dropdown.Item>
                                </DropdownButton>
                            </div>
                          </div>
                          <div>
                            <img src={banner.image} width="100%"/>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
          <Col xs={6} className="p-2 ps-4">
            <BannerUploader uploadImg={uploadImg} setUploadImg={setUploadImg} uploadUrl={uploadUrl} setUploadUrl={setUploadUrl} type={type}/>
          </Col>
        </Row>
      </DragDropContext>
    );
}

function BannerUploader({uploadImg, setUploadImg, uploadUrl, setUploadUrl, type}) {
  const [imageFile, setImageFile] = useState(null); // 업로드된 이미지 파일

  // 이미지 업로드 처리 함수
  const handleImageUpload = async (file) => {
    if (file) {
      const uploadedImageUrl = await uploadImage(file); // 이미지 업로드 함수 호출
      if (uploadedImageUrl) {
        console.log('이미지 업로드 성공:', uploadedImageUrl);
        setImageFile(file); // 업로드된 이미지 설정
        setUploadImg(uploadedImageUrl);
      } else {
        console.log('이미지 업로드 실패');
      }
    }
  };

  const bannerUplodClick = (img, url, type) => {
    if(!img){
      alert("이미지를 등록해주세요.");
      return;
    }else if(!url){
      alert("링크를 입력해주세요.");
      return;
    }else{
      createBanner(img, url, type)
      .then(res => {
        console.log(res.data);
      })
      .catch(error => alert(error.response.data))
      .finally(window.location.reload())
    }
  }

  return (
    <>
      <p className="text-center fs-6 mb-2">배너 등록</p>
      <div className="border rounded-2 p-2">
        <div className="m-2">
          <p>URL</p>
          <input type="text" placeholder="클릭 시 이동할 링크를 입력해주세요(https://부터)" value={uploadUrl} onChange={(e)=>setUploadUrl(e.target.value)} className="w-100 my-2 p-1" />
        </div>
        <div className="m-2">
          <p>이미지</p>
          <div
            className="border-dashed rounded-2 py-5 text-center"
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0]; // 드롭된 파일 정보 가져오기
              handleImageUpload(file);
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            {imageFile ? (
              <img src={URL.createObjectURL(imageFile)} alt="업로드된 이미지" style={{ maxWidth: "100%", maxHeight: "100px" }} />
            ) : (
              <span>이미지를 드래그 앤 드롭하세요</span>
            )}
          </div>
        </div>
        <div className="m-2">
          <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e.target.files[0])} className="w-100 mt-2" />
        </div>
        <div className="m-2">
          <button className="mt-2 w-100 bg-hw rounded-2 py-2 text-white fw-bold border-hw" onClick={() => {
            bannerUplodClick(uploadImg, uploadUrl, type);
          }}>
            확인
          </button>
        </div>
      </div>
    </>
  );
}
  

export default BannerManager