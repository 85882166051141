import {Row, Col, Button , Navbar, Nav, Container, Tab , InputGroup, Form, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidemenu from '../Sidemenu.js'
import { fetchQnaDetails } from '../../../api/services/customerApi.js'

export default function QAnswer()
{
    const { id } = useParams();
    const [QnaDetails, setQnaDetails] = useState(null);

    // 답변
    const [Answer , setAnswer] = useState(null);

    useEffect(() => {
        fetchQnaDetails(id)
          .then(details => {
            setQnaDetails(details);
            
            if(details.answer){
                setAnswer(details.answer)
            }

          })
          .catch(error => {
            console.error('상세 조회 오류:', error);
          });

          
    }, [id]);


    return(
        <>
         <Container>
            <Row>
                <div className='mypage_wrap'>
                    <Col md={3} lg={3}><Sidemenu nowPage={'qna'}/></Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <Qna QnaDetails={QnaDetails} Answer={Answer}/>
                    </Col>
                </div>
            </Row>
        </Container>
        </>
    )

}

function Qna({ QnaDetails , Answer })
{
    const navigate = useNavigate();
    const datePart = QnaDetails?.created_at.split('T')[0];

    return (
        <>
         <div className='mypage_area_sub p-2 p-sm-3 p-md-4 mt-2'>
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-4 fw-bold">상품 Q&A</h3>
                </Col>
            </Row>

             {/* 콘텐츠 내용 */}
             <div className='board-wrap'>
                <div className='board-tit'>
                    <h2>{QnaDetails?.title}</h2>
                </div>
                <div className='board-sub'>
                    <span>{QnaDetails?.author}</span>
                    <span>{datePart}</span>
                    <span className='hit'>조회수 {QnaDetails?.view_count}</span>
                </div>
                {/* <div className='board-detail' dangerouslySetInnerHTML={{ __html: QnaDetails?.content }} /> */}
                <div className='board-detail editor-content'>
                    <div dangerouslySetInnerHTML={{ __html: Answer }} />
                </div>
                <div className='board-btns'>
                    <button className='btnNormalFix' onClick={()=>navigate('/customer/qna')} >목록</button>
                </div>


            </div>
        </div>
        
        </>
    )
}