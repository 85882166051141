/***************************************************************************************
* FileName      : Cart.js
* Description   : 고객 > 장바구니 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.11
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import {Row, Col, Button , Navbar, Nav, Container, InputGroup, Form} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Helmet }from 'react-helmet';
import { BsArrowReturnRight } from 'react-icons/bs';
import { fetchCartDetails, useMyCartQuery , useOrderPurchase , useCartDelete , useCartItemDelete} from '../../api/services/cartApi.js'
import { useNavigate } from 'react-router-dom';
import { OrderBox } from './Cart/OrderBox.js'

export default function Cart()
{
    // const { data, isFetching, page, setPage, toggle, setToggle } = useMyCartQuery();

    const { cartItemDelete } = useCartItemDelete();

    const navigate = useNavigate();   

    const [cartDeliveryItems, setCartDeliveryItems] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);

    const { post, isPostLoading, error } = useOrderPurchase();
    const { cartDelete , isDeleteLoading } = useCartDelete();
    
    const [purchaseData, setPurchaseData] = useState({ cart_items: [] });

    // 최종 총 가격
    const [finalPrice, setFinalPrice] = useState(0);

    // 총 배송비 
    const [totalShippingCost, setTotalShippingCost] = useState(0);

    // 체크박스 관리     
    // cartDeliveryItems와 같은 길이의 빈 배열로 selectedItems 초기화

    const initialSelectAll = cartDeliveryItems.map(() => []);
    const [selectedItems, setSelectedItems] = useState(initialSelectAll);

  
    // 전체선택 버튼 상태 관리 
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        let totalPrice = 0;
        let totalDeliveryFee = 0;

        selectedItems.forEach((itemArray) => {
            let maxDeliveryFee = 0;
            itemArray.forEach((item) => {
                totalPrice += item.total_price;
                if (item.delivery_fee > maxDeliveryFee) {
                    maxDeliveryFee = item.delivery_fee;
                }
            });

            totalDeliveryFee = totalDeliveryFee + maxDeliveryFee;
        });

        setFinalPrice(totalPrice);
        setTotalShippingCost(totalDeliveryFee);

      }, [selectedItems]);


   // 로딩 상태
   const [isLoading, setIsLoading] = useState(false);

   // 로딩 상태를 표시하는 컴포넌트
   const LoadingSpinner = () => {
       return (
           <div className="parent-container">
                <div className="spinner" ></div>
           </div>
       );
   };

//    useEffect(() => {
//         // setPurchaseData(selectedOrders );
//         console.log("selectedItems:::::",selectedItems)
//     }, [selectedItems]);

    useEffect(() => {
        fetchCartDetails()
          .then(details => {
        
            setCartDeliveryItems(details.cart.cart_delivery_items);
            // console.log("현재상태")
            // console.log(cartDeliveryItems)
          })
          .catch(error => {
            console.error('상품 상세 조회 오류:', error);
        });
     
    }, []);    
    

    
    // 계속 쇼핑하기 버튼 클릭 시
    const handleContinueShopping = () => {
        // 이전 페이지로 이동
        navigate(-1);
    };
      
    // 주문하기 버튼 클릭시 실행될 함수
    const handleOrder = () => {
        console.log(selectedOrders)
        if(selectedOrders.length === 0 ){
            alert("주문하실 상품을 선택해 주세요.");
            return;
        }

        const data = {
            cart_items: selectedOrders || "",
        };

        post(data)
        .then((response) => {
            setIsLoading(false); 
            window.location.href = '/order/order/'; 
            // 응답 데이터를 변수에 저장
            // const responseData = response.data;
        })
        .catch(error => {
            // console.log("등록 실패:", error.message);
            setIsLoading(false);
            // alert('장바구니 상품을 확인해주세요.');
        });

    };
   

    // 전체선택 버튼 클릭 시
    const handleSelectAll = () => {
        setSelectAll(!selectAll); // 현재 상태를 토글
    }

    // 선택삭제 버튼 클릭시 
    const handleSelectedItemsDelete = async () =>{
        console.log("선택삭제클릭",selectedOrders)
        
        const data = {
            ids: selectedOrders,
        };

        cartItemDelete(data)
        .then(() => {
            fetchCartDetails()
            .then(details => {
              setCartDeliveryItems(details.cart.cart_delivery_items);
            })
            .catch(error => {
              console.error('상품 상세 조회 오류:', error);
          });
        })
        .catch(error => {
            console.log("삭제 실패:", error.message);
        });
    }

    // 비우기 버튼 클릭시 실행될 함수
    const handleCartDelete = async () => {
        const result = window.confirm('장바구니를 비우시겠습니까');
        // 삭제 API 호출
        if (result) {
            cartDelete()
            .then((response) => {
                setIsLoading(false); // 로딩 상태를 비활성화
                console.log(response)
            //     fetchCartDetails()
            //     .then(details => {
            //       setCartDeliveryItems(details.cart.cart_delivery_items);
            //       console.log("비우기 후 상태",details.cart.cart_delivery_items)
            //     })
            //     .catch(error => {
            //       console.error('상품 상세 조회 오류:', error);
            //   });
              setCartDeliveryItems([])
              console.log("비우기 후 상태")
              console.log(cartDeliveryItems)
            })
            .catch(error => {
                console.log("삭제 실패:", error.message);
                setIsLoading(false); // 로딩 상태를 비활성화
            });
        }
    };
      

    // useEffect (()=>{
    //     console.log("비우기 후 상태")
    //     console.log(cartDeliveryItems)
    // },[cartDeliveryItems])


    return(
        <div className='m-0 p-0 bg-grey'>
        <Helmet>
            <title>목포해와 - 장바구니페이지</title>
        </Helmet>

        <Container className='p-3'>
            <div className='product-headcategory'>
                <ol>
                    {/* <li><a>장바구니</a></li>
                    <li><a>주문/결제</a></li>
                    <li><a>완료</a></li> */}
                </ol>
            </div>

            <Row>
                <Col xs={12} md={12}>
                    <div className='page-tit d-flex justify-content-between align-items-center'>
                        <h2>장바구니</h2>
                        <div className='tbl_wrap'>
                            <div className='btn_cart_del'>
                                <button type='button' onClick={()=>{handleSelectAll()}}>
                                    {selectAll ? '전체선택' : '전체해제'}
                                </button>
                                <button type='' onClick={()=>{handleSelectedItemsDelete()}}>선택삭제</button>
                                <button type='' onClick={()=>{handleCartDelete()}}>비우기</button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>


            <OrderBox 
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                cartDeliveryItems={cartDeliveryItems}
                setCartDeliveryItems={setCartDeliveryItems}
                selectAll={selectAll}
            />
         
            <Row>
                <div className='od_prd_list mt-4'>
                    <div id='od_bsk_tot' className='bg-white rounded-2 shadow-box'>
                        <ul>
                            <li className='od_bsk_pt'>
                                <span>총 주문금액</span>
                                <p>
                                {finalPrice !== undefined ? (
                                    <strong>{finalPrice.toLocaleString()}원</strong>
                                ) : (
                                    <strong>0원</strong>
                                )}
                                </p>
                            </li>
                            
                            <li className='od_bsk_dvr'>
                                <span>총 배송비</span>
                                <p>
                                    {totalShippingCost !== undefined ? (
                                        <strong>{totalShippingCost.toLocaleString()}원</strong>
                                    ) : (
                                        <strong>0원</strong>
                                    )}
                                </p>
                            </li>

                            <li className='od_bsk_cnt'>
                                <span>총 결제금액</span>
                                <p>
                                    <strong>{(totalShippingCost+finalPrice).toLocaleString()}원</strong>
                                </p>
                                
                            </li>
                            
                        </ul>
                    </div>
                    <div className='order-buttons'>
                        <button type='button' className='goShopping' onClick={()=>{handleContinueShopping()}}>
                            계속 쇼핑하기
                        </button>
                         <button type='button' className='goPayment' onClick={()=>{handleOrder()}}  >
                            {isLoading ?  <LoadingSpinner /> : '주문하기'} 
                        </button>
                    </div>
                </div>
            </Row>
        </Container>
        </div>
    )
}

