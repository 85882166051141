import { instance } from '../../axiosInstance/axiosConfig';
import { useQuery } from 'react-query';
import { useState , useEffect } from 'react';

// 해와관리자 마케팅 > 할인쿠폰 등록
export function useDiscountCoupon() {
    const post = async (data) => {
      try {
          const response = await instance.post(`coupon/`, data );
          const responseData = await response.data;
          return responseData;
      } catch (error) {
        throw new Error("Failed to post");
      }
    };
    
    return {
      post,
    };
}


// 해와관리자 마케팅 > 할인쿠폰 > 쿠폰리스트
const fetchCouponDocument = (page , pageSize , ordering ) => {
    return instance.get(`coupon/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
      .then(res => res.data)
      .catch(error => error.data)
};
  
  export const useCouponListQuery = (ordering , pageSize)  => {
    const [page, setPage] = useState(1);
  
    useEffect(() => {
      setPage(1);
    }, [pageSize]);
  
    const { data, error, isFetching } = useQuery(
      ['couponDocument', page, pageSize, ordering ],
      () => fetchCouponDocument(page , pageSize , ordering)  
    );
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,
  
    };
};

// 할인쿠폰 > 쿠폰리스트 > 검색 
export const handleCouponSearch = async ({
    searchCoupon,
    setSearchResults, 
    setSearchUrl,
    searchOption,
    selectedStartDate, 
    selectedEndDate,
    ordering,
    types
    }) => {
    try {
      let url = `coupon/`;
  
      if (searchCoupon) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchCoupon}`;
            break;
          case 'name':
            url += `?name=${searchCoupon}`;
            break;
          case 'vendor_name':
            url += `?vendor_name=${searchCoupon}`;
            break;
    
          default:
            break;
        }
      }else{
        url += `?`;
      }
  
      if (selectedStartDate && selectedEndDate) {
          url += `&created_at_after=${selectedStartDate}__${selectedEndDate}`;
      }

     
      if(ordering){
        url += `&ordering=${ordering}` 
      }

      if(types.length === 1){
        url += `&type=${types[0]}` 
      }
      console.log(url)
      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};

// 해와관리자 마케팅 > 할인쿠폰 > 발행/사용내역
const fetchCouponIssueDocument = (page , pageSize , ordering ) => {
  return instance.get(`coupon/issue/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
    .then(res => res.data)
    .catch(error => error.data)
};

export const useCouponIssueQuery = (ordering , pageSize)  => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { data, error, isFetching } = useQuery(
    ['couponIssueDocument', page, pageSize, ordering ],
    () => fetchCouponIssueDocument(page , pageSize , ordering)  
  );

  return {
    data,
    error,
    isFetching,
    page,
    setPage,

  };
};

// 해와관리자 할인쿠폰 > 발행/사용내역 > 검색 
export const handleCouponIssueSearch = async ({
  searchCoupon,
  setSearchResults, 
  setSearchUrl,
  searchOption,
  selectedDateType,
  selectedStartDate, 
  selectedEndDate,
  ordering,
  types,
  isUsed
  }) => {
  try {
    let url = `coupon/issue/`;

    if(searchCoupon && searchOption==='all' ){
      url += `?search=${searchCoupon}`;
    }else if (searchCoupon && searchOption) {
        url += `?${searchOption}=${searchCoupon}`;
    }else{
      url += `?`;
    }

   
    if (selectedDateType && selectedStartDate && selectedEndDate) {
      url += `&${selectedDateType}=${selectedStartDate}__${selectedEndDate}`;
    }

   
    if(ordering){
      url += `&ordering=${ordering}` 
    }

    if(types.length === 1){
      url += `&type=${types[0]}` 
    }

    if(isUsed.length === 1){
      if(isUsed[0] === '사용완료'){
        url += `&is_used=true` 
      }else{
        url += `&is_used=false` 
      }
    }
    
    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// 해와관리자 마케팅 > 마일리지
const fetchMileagehistoryDocument = (page , pageSize , ordering ) => {
    return instance.get(`mileage/log/?page=${page}&page_size=${pageSize}&ordering=${ordering}`)
      .then(res => res.data)
      .catch(error => error.data)
};
  
  export const useMileagehistoryQuery = (ordering , pageSize)  => {
    const [page, setPage] = useState(1);
  
    useEffect(() => {
      setPage(1);
    }, [pageSize]);
  
    const { data, error, isFetching } = useQuery(
      ['mileagehistoryDocument', page, pageSize, ordering ],
      () => fetchMileagehistoryDocument(page , pageSize , ordering)  
    );
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,
  
    };
};

// 마케팅 > 마일리지 > 검색 
export const handleMileagehistorySearch = async ({
    searchMileage,
    setSearchResults, 
    setSearchUrl,
    searchOption,
    selectedDateType,
    selectedStartDate, 
    selectedEndDate,
    ordering,
   
    }) => {
    try {
      let url = `mileage/history/`;
  
      if (searchMileage) {
        switch(searchOption) {
          case 'all':
            url += `?search=${searchMileage}`;
            break;
          // case 'id':
          //   url += `?username__contains=${searchAdjust}`;
          //   break;
          
          default:
            break;
        }
      }else{
        url += `?`;
      }
  
      if (selectedStartDate && selectedEndDate) {
        switch(selectedDateType) {
          case 'join':
            url += `&date_joined__range=${selectedStartDate}__${selectedEndDate}`;
            break;
          case 'last':
            url += `&last_login__range=${selectedStartDate}__${selectedEndDate}`;
            break;
          default:
            break;
        }
      }

    
      if(ordering){
        url += `&ordering=${ordering}` 
      }

      console.log(url)
      setSearchUrl(url)
      const response = await instance.get(url);
      setSearchResults(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
};
  


// export const getCouponList = (page) => {
//     return instance.get(`${BASE_URL}/coupon/issue/?page=${page}`, {
//         headers: {
//             'Authorization': `Bearer ${access}`,
//             'Content-Type': 'application/json',
//         },
//     })
// };

// export const getMileageList = (page) => {
//     return instance.get(`${BASE_URL}/mileage/history/?page=${page}`, {
//         headers: {
//             'Authorization': `Bearer ${access}`,
//             'Content-Type': 'application/json',
//         },
//     })
// }