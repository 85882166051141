/***************************************************************************************
* FileName      : CouponModal.js
* Description   : 고객 > 상품 결제페이지 > 상품쿠폰, 배송비 쿠폰 적용 모달
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.01.05
* Modifide Date : 2024.01.05 : CHM : 상품쿠폰 및 배송비 쿠폰 적용 모달
*               : 
* Reference     : 
***************************************************************************************/
import { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { getAvailableCoupon, sendUseItemCoupon, sendUseDeliveryCoupon } from '../../../api/orderPaymentsApi';

// 쿠폰모달(상품, 배송)
export function SelectCouponModal({id, type, vendor, price, setModalState, modalState, setOrderData}){
    // type : 상품 or 배송
    
    const [selectedItem, setSelectedItem] = useState(''); //라디오버튼 값 지정
    const handleRadioChange = (e) => {
        setSelectedItem(e.target.value); // 값 변경 시 selectedItem 변경
    };
    const [couponList, setCouponList] = useState([{name: '선택안함', key: ''}]); //사용 가능한 쿠폰리스트
    
    // 모달이 열릴 때마다 해당 상품에 사용 가능한 쿠폰 조회 API 요청 실행
    useEffect(() => {
        if (modalState) {
            getAvailableCoupon(type, vendor, price)
                .then((res) => {
                    console.log("쿠폰이..."+res?.data);
                    if(res?.data != []){
                        let items = res?.data.map((item) => ({
                            name: item?.coupon?.name, // 쿠폰명
                            discount_rate: item?.coupon?.discount_rate, // 할인율
                            discount_amount: item?.coupon?.discount_amount, // 할인금액
                            is_active: item?.coupon?.is_active, // 사용가능여부
                            key: item?.key, // 쿠폰 고유번호
                            is_used: item?.is_used, // 사용여부확인
                        }));
                        setCouponList([{name: '선택안함', key: ""}, ...items]);
                    }
                })
                .catch((error) => {
                    setCouponList([{name: '선택안함', key: ""}]);
                    console.log('오류 응답 : ', error);
                });
        }else{
            setCouponList([{name: '선택안함', key: ""}]);
        }
    }, [modalState, vendor, price]);


    const handleSubmit = (e, type) => {
        e.preventDefault(); // 쿠폰사용API요청
        console.log('선택된 항목 : ', selectedItem);
        if(type === '상품'){
            sendUseItemCoupon(id, selectedItem)
                .then(res => setOrderData(res?.data))
                .catch(error => alert(error?.response?.data?.data))
                .finally(setModalState(false))
        }else{
            sendUseDeliveryCoupon(id, selectedItem)
                .then(res => setOrderData(res?.data))
                .catch(error => alert(error?.response?.data?.data))
                .finally(setModalState(false))
        }
    };
    return(
        <>
            <Modal size={'md'} show={modalState} onHide={()=>setModalState(false)} aria-labelledby="sm-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title id='sm-modal' className='fs-6'>
                        사용할 쿠폰을 선택해주세요.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        // couponList == [] ? <p>사용가능한 쿠폰이 없습니다.</p> : (
                        <Form onSubmit={handleSubmit}>
                            {
                                couponList.map((item, index) => (
                                    <Form.Check
                                        key={index}
                                        type='radio'
                                        id={`radio-${index}`}
                                        label={
                                            <span className='m-0 p-0 fs-7 text-grey fw-bold'>
                                                {item?.name}
                                                <span className='text-hw fw-bold'>
                                                    { !item.key ?  '' : (
                                                        type !== '배송비' ? item?.discount_rate > 0 ? '('+item?.discount_rate+'% 할인)' : '('+item?.discount_amount+'원 할인)' : '(무료배송)'
                                                    )}
                                                </span>
                                            </span>
                                        }
                                        value={item?.key}
                                        checked={selectedItem === item?.key}
                                        onChange={handleRadioChange}
                                        className='mt-2 mb-2'
                                    />
                                ))
                            }
                            <div className='d-grid gap-2 mt-4'>
                                <button type='submit' className='btn btn-primary' onClick={(e) => handleSubmit(e, type)}>선택</button>
                            </div>
                        </Form>
                        // )
                    }
                    
                </Modal.Body>
            </Modal>
        </>
    )
}