import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';



function Editortest()
{
    return(
        <>
        {/* 콘텐츠 부분 */}
            <div className='mainContents'>
                {/* 관리자 메인 */}
          
                    <div className='page-tit'>
                        <h2>회원관리</h2>
                    </div>
                 
                
                </div>
             

        
  

        </>
    )
}

export default Editortest