import { Row, Col } from 'react-bootstrap';
import { extractYearMonthDayHourMinute2 } from '../Component/extractDateUtils';
import ViewStarRating from '../Reviewable/ViewStarRating';
import { useState , useEffect } from 'react';
import ViewReviewModal from './ViewReviewModal';

function ReviewRow({orderItem})
{
    // 후기 보기 모달 
    const [isOpen, setIsOpen] = useState(false);

    const handleReviewView = () => {
        setIsOpen(true);
    };


    
    function TruncatedText({ text }) {
        const maxLength = 15;
        const displayText = text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
        return <p className='rcontent'>{displayText}</p>;
    }

    const [rating, setRating] = useState(0);

    useEffect(() => {
        const numericRating = parseFloat(orderItem.rating);
        setRating(numericRating);
    }, [orderItem.rating]); 

    return(
      <>
      <tr key={orderItem?.id}>
        <td className='subject'>
            <div className='area'>
                <a className='thum'>
                    <img src={orderItem?.orderitem.main_image} alt="Product" />
                </a>
                <div className='textus'>
                    <ul className='data review-data'>
                        <li>주문일자</li>
                        <li>{extractYearMonthDayHourMinute2(orderItem?.orderitem.ordered_at)}</li>
                    </ul>
                    <a>
                        <span className='tit'>
                            {orderItem?.orderitem.vendor_name}
                        </span>
                        <span className='txt'>
                            {orderItem?.orderitem.product_name}
                        </span>
                    </a>
                </div>
            </div>
        </td>

        <td>
            <div className='textus'>
                <ul className='data review-data'>
                    <li>작성일자</li>
                    <li>{extractYearMonthDayHourMinute2(orderItem?.updated_at)}</li>
                </ul>
                <div className='rating'>
                    <ViewStarRating 
                        rating={rating}
                    />
                </div>
                <TruncatedText text={orderItem?.content} />
                
            </div>
        </td>

        <td className='d-flex flex-column'>
            <button className='mpbtn1' onClick={() => handleReviewView()}>리뷰 보기</button>
        </td>
                          
        {isOpen && (
            <ViewReviewModal
                reviewId={orderItem.id}
                setIsOpen={setIsOpen}/>
        )}
            
    </tr>

      </>
    )
}

export default ReviewRow