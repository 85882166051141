import { useState, useEffect } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
import Sidemenu from './Sidemenu.js';
import StatusBar from './StatusBar.js';
import { getMyAddress, createMyAddress, changeDefaultAddress, updateMyAddress, deleteMyAddress } from '../../api/services/mypageApi.js';

// 배송지 추가용 컴포넌트
import { Row, Col, Container, Modal, Form } from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';

// 회원 정보 조회용 api
import { getLoginUser } from '../../api/myOrderListApi.js';

function AddressLayout(){
    const [newData, setNewData] = useState({});
    const [modalState, setModalState] = useState(false);

    return(
        <>
            <Container>
              <StatusBar/>
              <Row className='m-0'>
                <div className='mypage_wrap'>
                  <Col md={3} lg={3}><Sidemenu nowPage={'address'}/></Col>
                  <Col xs={12} sm={12} md={9} lg={9}>
                      <Address setModalState={setModalState}/>
                  </Col>
                </div>
              </Row>
            </Container>
            <AddressModal modalState={modalState} setModalState={setModalState}/>
        </>
      )
}

function Address({setModalState}){
    
    return(
        <>
            <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
                <>
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0'>
                            <h3 class="m-0 p-0 fs-5 fw-bold">배송지 관리</h3>
                        </Col>
                        <Col className='d-flex justify-content-end align-items-center m-0 p-0 fs-7 text-hw fw-bold pointer'
                            onClick={() => {setModalState(true);}}
                        >
                            + 새 배송지 추가
                        </Col>
                    </Row>
                    <Row className='m-0 p-0'>
                        <p className='m-0 mt-3 p-0 fs-7 fw-bolder text-grey'>
                            배송지에 따라 상품정보 및 배송유형이 달라질 수 있습니다.
                        </p>
                    </Row>
                    <AddressList/>
                </>
            </div>
        </>
    )
}

function AddressList(){
    const [userAddressList, setUserAddressList] = useState([]);
    useEffect(()=>{
        getMyAddress()
        .then(res => {
            setUserAddressList(res?.data?.data);
        })
        .catch(error => alert('주소 조회 에러'))
    }, [])
    return(
        <>
            {/* 컬럼명 */}
            <Row className='m-0 p-2 border bg-grey d-none d-sm-flex'>
                <Col xs={2} className='fs-7 fw-bolder text-center'>기본배송지</Col>
                <Col xs={4} className='fs-7 fw-bolder text-center'>주소</Col>
                <Col xs={2} className='fs-7 fw-bolder text-center'>받으실 분</Col>
                <Col xs={2} className='fs-7 fw-bolder text-center'>연락처</Col>
                <Col xs={1} className='fs-7 fw-bolder text-center'>수정</Col>
                <Col xs={1} className='fs-7 fw-bolder text-center'>삭제</Col>
            </Row>
            {/* 주소리스트 */}
            {
                userAddressList.length > 0 ? userAddressList.map(x => <AddressBlock data={x}/>) : <p className="text-center m-5">등록된 배송지가 없습니다.</p>
            }
            
        </>
    )
}

const AddressBlock = ({data}) => {
    const [updateModalState, setUpdateModalState] = useState(false);
    const [isDefault, setIsDefault] = useState(data?.is_default || false);

    const handleDefaultChange = async () => {
        try {
            // 여기서 API 요청을 보내고 성공하면 상태를 업데이트합니다.
            await changeDefaultAddress(data?.id, {
                is_default: true,
                user: data?.user?.id,
                name: data?.name,
                recipient: data?.recipient,
                phone_number: data?.phoneNumber,
                address: data?.address,
            });

            setIsDefault(true); // 성공적으로 변경된 경우 상태를 업데이트
            alert('기본배송지 변경?');
            window.location.reload();
        } catch (error) {
            console.error('배송지 변경 오류:', error);
        }
    };

    return(
        <>
            <Row className='m-0 p-2 border bg-white d-flex align-items-center'>
                <Col xs={2} sm={2} className='fs-7 fw-bolder text-center pointer'>
                    {isDefault && <p className='text-hw fs-8 fw-bold'>기본배송지</p>}
                    <input type='radio' checked={isDefault} onChange={handleDefaultChange} />
                </Col>
                <Col xs={9} sm={4} className='fs-7 text-left'>
                    <p className='text-grey fs-7 fw-bold'>{data?.name}</p>
                    <p className='fs-7 fw-bolder'><b>[{data?.address?.postal_code}]</b> {data?.address?.address}</p>
                    <p className='fs-7 fw-bolder'>{data?.address?.detail}</p>
                    <p className='fs-7 fw-bolder text-secondary d-sm-none'>{data?.recipient} {data?.phone_number}</p>
                </Col>
                <Col xs={12} sm={2} className='fs-7 fw-bolder text-center d-none d-sm-flex justify-content-center'>{data?.recipient}</Col>
                <Col xs={12} sm={2} className='fs-7 fw-bolder text-center d-none d-sm-flex justify-content-center'>{data?.phone_number}</Col>
                <Col xs={6} sm={1} className='fs-7 fw-bolder text-center pointer' onClick={()=>{
                    console.log(data?.id);
                    setUpdateModalState(true);
                }}>수정</Col>
                <Col xs={6} sm={1} className='fs-7 fw-bolder text-danger text-center pointer' onClick={()=>{
                    if(window.confirm('배송지 목록에서 삭제하시겠습니까?')){
                        deleteMyAddress(data?.id)
                            .then(() => {
                                alert('삭제되었습니다.');
                                window.location.reload();
                            })
                            .catch(error => console.log(error?.response?.data))
                    }else{
                        console.log('ㄴㄴㄴㄴ');
                    }
                }}>
                    삭제
                </Col>
            </Row>
            <UpdateAddressModal updateModalState={updateModalState} setUpdateModalState={setUpdateModalState} data={data}/>
        </>
    )
}

function AddressModal({modalState, setModalState}){
    const [userPk, setUserPk] = useState();
    useEffect(()=>{
        getLoginUser()
            .then(res => setUserPk(res?.data?.id));
    }, [])
    return(
        <>
            <Modal size={'lg'} show={modalState} onHide={()=>{setModalState(false)}} aria-labelledby="sm-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title id='sm-modal' className='fs-6'>
                        배송지 정보를 입력해주세요.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppendAddress userPk={userPk}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

function AppendAddress({userPk}){
    const [addressName, setAddressName] = useState(null);
    const [recipientName, setRecipientName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [addressDetail, setAddressDetail] = useState(null);
    const [postCodeModalState, setPostCodeModalState] = useState(false);

    // 사용자 배송지 목록에 신규 배송지 추가
    const appendUserAddress = () =>{
        if(addressName && recipientName && phoneNumber && postalCode && address && addressDetail){
            const data = {
                user: userPk,
                name: addressName,
                recipient: recipientName,
                phone_number: phoneNumber,
                address: address,
                detail: addressDetail,
                postal_code: postalCode
            }
            createMyAddress(data)
                .then(() => alert('배송지가 추가되었습니다.'))
                .then(() => window.location.reload());
        }else{
            alert('누락된 정보가 존재합니다.');
        }
    }

    return (
        <>
            {/* 주소입력 상태인 경우 배송지정보 입력 모달을 none으로 변경 */}
            <Form className={postCodeModalState ? 'd-none' : ''}>
                <Form.Group className="mb-3" controlId="AddressName">
                    <Form.Label>배송지 이름</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="배송지 이름을 입력해주세요. (ex : 집, 사무실)" value={addressName} onChange={(e) => setAddressName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="RecipientName">
                    <Form.Label>수령인</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="수령할 사람의 이름을 입력해주세요." value={recipientName} onChange={(e) => setRecipientName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PhoneNumber">
                    <Form.Label>휴대폰 번호</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="수령할 사람의 휴대폰 번호를 입력해주세요." value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>우편번호</Form.Label>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form.Control size="sm" type="text" placeholder="우편번호" value={postalCode} disabled/>
                        <p className='ms-2 p-1 fs-7 text-hw fw-bold border-hw w-50 text-center rounded-2 pointer' onClick={()=>setPostCodeModalState(true)}>우편번호 찾기</p>
                    </div>                    
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>주소</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="우편번호" value={address} onChange={(e) => setAddress(e.target.value)} disabled/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="PostalCode">
                    <Form.Label>상세주소</Form.Label>
                    <Form.Control size="sm" type="text" placeholder="상세주소" value={addressDetail} onChange={(e) => setAddressDetail(e.target.value)}/>
                </Form.Group>
                
                <div className='d-grid gap-2 mt-4'>
                    <p type='submit' className='btn btn-primary' onClick={()=>{appendUserAddress()}}>적용</p>
                </div>
            </Form>
            {/* 주소입력 상태인 경우 postCodeModal 표시 */}
            <PostCodeModal modalState={postCodeModalState} setModalState={setPostCodeModalState} setPostalCode={setPostalCode} setAddress={setAddress}/>
        </>
    )
}


function PostCodeModal({modalState, setModalState, setPostalCode, setAddress}){
    // 카카오 주소입력 스타일
    const postCodeStyle = {
        display: 'block',
        position: 'relative',
        top: '0%',
        width: '400px',
        height: '400px',
        padding: '7px',
        margin: '0 auto',
    };

    const onCompletePost = (data) => {
        console.table(data);
        //우편번호 업데이트
        setPostalCode(data.zonecode);
        //주소 업데이트
        if(data.addressType === 'R' && data.jibunAddress !== ''){
            setAddress(data.jibunAddress);
        }else if(data.addressType === 'R' && data.autoJibunAddress !== ''){
            setAddress(data.autoJibunAddress);
        }else{
            setAddress(data.address);
        }
        //모달창 닫기
        setModalState(false);
    };
    return(
        <div className={modalState ? "" : "d-none"}>
            <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompletePost} />
        </div>
    )
}

function UpdateAddressModal({updateModalState, setUpdateModalState, data}){
    const [addressId, setAddressId] = useState(data?.id);
    const [userPk, setUserPk] = useState();
    const [addressName, setAddressName] = useState(data?.name);
    const [recipientName, setRecipientName] = useState(data?.recipient);
    const [phoneNumber, setPhoneNumber] = useState(data?.phone_number);
    const [postalCode, setPostalCode] = useState(data?.address?.postal_code);
    const [address, setAddress] = useState(data?.address?.address);
    const [addressDetail, setAddressDetail] = useState(data?.address?.detail);
    const [postCodeModalState, setPostCodeModalState] = useState(false);

    useEffect(()=>{
        getLoginUser()
            .then(res => setUserPk(res?.data?.id));
    }, [])

    // 사용자 배송지 목록에 신규 배송지 추가
    const updateUserAddress = () =>{
        if(addressName && recipientName && phoneNumber && postalCode && address && addressDetail){
            const data = {
                user: userPk,
                name: addressName,
                recipient: recipientName,
                phone_number: phoneNumber,
                address: {
                    address: address,
                    detail: addressDetail,
                    postal_code: postalCode
                }
            }
            updateMyAddress(addressId, data)
                .then(() => {
                    alert('배송지가 수정되었습니다.');
                    window.location.reload();
                })
        }else{
            alert('누락된 정보가 존재합니다.');
        }
    }
    
    return(
        <>
            <Modal size={'lg'} show={updateModalState} onHide={()=>{setUpdateModalState(false)}} aria-labelledby="sm-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title id='sm-modal' className='fs-6'>
                        배송지 정보를 입력해주세요.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={postCodeModalState ? 'd-none' : ''}>
                        <Form.Group className="mb-3" controlId="AddressName">
                            <Form.Label>배송지 이름</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="배송지 이름을 입력해주세요. (ex : 집, 사무실)" value={addressName} onChange={(e) => setAddressName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="RecipientName">
                            <Form.Label>수령인</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="수령할 사람의 이름을 입력해주세요." value={recipientName} onChange={(e) => setRecipientName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="PhoneNumber">
                            <Form.Label>휴대폰 번호</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="수령할 사람의 휴대폰 번호를 입력해주세요." value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="PostalCode">
                            <Form.Label>우편번호</Form.Label>
                            <div className='d-flex justify-content-between align-items-center'>
                                <Form.Control size="sm" type="text" placeholder="우편번호" value={postalCode} disabled/>
                                <p className='ms-2 p-1 fs-7 text-hw fw-bold border-hw w-50 text-center rounded-2 pointer' onClick={()=>setPostCodeModalState(true)}>우편번호 찾기</p>
                            </div>                    
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="PostalCode">
                            <Form.Label>주소</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="우편번호" value={address} onChange={(e) => setAddress(e.target.value)} disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="PostalCode">
                            <Form.Label>상세주소</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="상세주소" value={addressDetail} onChange={(e) => setAddressDetail(e.target.value)}/>
                        </Form.Group>
                        
                        <div className='d-grid gap-2 mt-4'>
                            <p type='submit' className='btn btn-primary' onClick={()=>{updateUserAddress()}}>적용</p>
                        </div>
                    </Form>
                    {/* 주소입력 상태인 경우 postCodeModal 표시 */}
                    <PostCodeModal modalState={postCodeModalState} setModalState={setPostCodeModalState} setPostalCode={setPostalCode} setAddress={setAddress}/>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AddressLayout