/***************************************************************************************
* FileName      : ReturnExchangeCancel.js
* Description   : 셀러관리자 > 주문배송 > 반품/취소 >  반품,취소 리스트 조회 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2023.12.00
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import {Row, Col, Button , Navbar, Nav, Container} from 'react-bootstrap';
import { fetchSellerOrderAbortStatus } from '../../../api/selleradmin/orderAPI';
import AbortCancelView from './ReturnExchangeCancel/AbortCancelView';
import CancelStatusView from './ReturnExchangeCancel/CancelStatusView';
import AbortReturnView from './ReturnExchangeCancel/AbortReturnView';

function ReturnExchangeCancel()
{
    // 현황 데이터
    const [statusData , setStatusData] = useState();

    useEffect(() => {
        fetchSellerOrderAbortStatus()
            .then(data => {
            setStatusData(data);
            })
            .catch(error => {
            console.error('상품 상세 조회 오류:', error);
            });
    }, []);

    useEffect(()=>{
        console.log(statusData);

    },[statusData])

    const [selectedStatus , setSelectStatus] = useState('');

    const handleButtonClick = (status) => {
        setSelectStatus(status);
    };

    return(
        <>
        <div className='mainContents'>
            {/* 반품/취소 */}
            <div className='page-tit'>
                <h2>반품/취소</h2>
            </div>

               {/* 반품/취소 현황 */}
               <section className='state-wrap'>
               <div className={`state-box ${selectedStatus === "" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("")}>
                    <p className='tit'>전체</p>
                    {statusData ? (
                        <p className='dec'>{statusData.total_count && statusData.total_count}</p>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
 
                <div className={`state-box ${selectedStatus === "반품" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("반품")}>
                    <p className='tit'>반품</p>
                    {statusData ? (
                        <p className='dec'>{statusData.return_count  && statusData.return_count }</p>
                    ) : (
                        <p>Loading...</p>
                    )}
       
                </div>
        
                <div className={`state-box ${selectedStatus === "취소" ? "selected" : ''}`}
                    onClick={() => handleButtonClick("취소")}>
                    <p className='tit'>취소</p>
                    {statusData ? (
                        <p className='dec'>{statusData.cancel_count && statusData.cancel_count}</p>
                    ) : (
                        <p>Loading...</p>
                    )}
 
                </div>
            </section>

   
            {/* 전체 */}
            {selectedStatus === "" && 
                <CancelStatusView 
                    selectedStatus={selectedStatus}/>}
            
            {/* 반품 */}
            {selectedStatus === "반품" && 
                <AbortReturnView 
                    selectedStatus={selectedStatus}/>}

     
            {/* 취소 */}
            {selectedStatus === "취소" && 
                 <AbortCancelView 
                    selectedStatus={selectedStatus}/>}

                
            </div>
        </>
    )
}

export default ReturnExchangeCancel