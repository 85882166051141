import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MypageTabComponent from './MypageTab_v2.js';
import Sidemenu from './Sidemenu.js'
import StatusBar from './StatusBar.js';
import { useMyLikeVendorListQuery } from '../../api/services/mypageApi';
import WishVendorBox from './VendorWishList/WishVendorBox.js';
import Pagination from '../customer/common/Pagination.js';

function WishListLayout(){
    return(
        <>
        <Container>
            <StatusBar/>
            <Row className='m-0'>
            <div className='mypage_wrap'>
                <Col md={3} lg={3}><Sidemenu nowPage={'wish'}/></Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <VendorWishList />
                </Col>
            </div>
            </Row>
        </Container>
        </>
      )
}

function VendorWishList()
{
    const { data:myLikeVendorData, error, isFetching, page, setPage } = useMyLikeVendorListQuery();


    console.log(myLikeVendorData);
    const tabs = [
        {label: '상품' , url : '/mypage/wish'},
        {label: '상회' ,  url : '/mypage/vendorWish' }
    ];

    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
      
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };
          
    
    return(
        <>
        <div className='mypage_area_sub p-xs-1 p-md-4 mt-2'>
            <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                    <h3 class="m-0 p-0 fs-5 fw-bold">찜한 상회</h3>
                </Col>
            </Row>
        
            <MypageTabComponent tabs={tabs}/>

            <span className='mypage_w_total'>
                <strong>전체 <em>{myLikeVendorData?.totalCnt}</em>개</strong>
            </span>

      
            <div className='vendor_like_list mt-3'>
                <Row className='mt-3'>
                    {myLikeVendorData?.totalCnt === 0 ? (  
                        <p>찜한 상회가 없습니다.</p>
                    ) : (
                        myLikeVendorData?.data?.map((data) => (
                        
                                <WishVendorBox
                                    key={data.id}  
                                    data={data}
                                />
                    
                        ))
                    )}
                </Row>
            </div>

            {/* 페이지내비게이션 */}
            <section className='mt-5'>
              <Pagination
                  count={myLikeVendorData?.totalCnt || 0}
                  page={page}
                  setPage={setPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
              />
          </section>
        </div>
        </>
    );
}

// function ItemBox(props){
//   return(
//     <Col xs={6} sm={4} md={4} lg={3} className="item m-0 p-1 pb-2">
//         <div className='border pointer p-2'>
//             <div className='thumb'>
//                 <a href="/product/detail2"> <img src="../img/p_1.jpg"/> </a>
//             </div>
//             <div className='prd-info'>
//                 <span className='prd-seller mt-1 mb-1'>수수수산상회</span>
//                 <div className='prd-name-wrap'><span className='prd-name'>{props.product.title}</span></div>
                
//                 <Row className="m-0 p-0 pt-1">
//                     <Col className="m-0 p-0"><span className='prd-price text-start'>{props.product.discountRate}</span></Col>
//                     <Col xs="auto" className="m-0 p-0">
//                         <span className='prd-price-sub d-inline'>{props.product.cost.toLocaleString('ko-KR')}원</span>
//                         <span className='prd-price d-inline me-1'>{props.product.price.toLocaleString('ko-KR')}원</span>
//                     </Col>
                    
//                 </Row>
//             </div>
//         </div>
//     </Col>
//   )
// }

export default WishListLayout